import React, { Component, CSSProperties } from "react";
import { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import Popup from "reactjs-popup";
import customSelectStyles from "common/SelectStyles";
import dataScopes, { DataScopeOption } from "common/DataScopes";
import { Permission } from "common/Permissions";
import GroupsPermissionsSelector from "common/GroupsPermissionsSelector";
import { getDataTablePermissionsApi } from "common/UserGroupsApi";
import CreatableSelect from "react-select/creatable";
import { observer } from "mobx-react";
import Alert from "common/Alert";
import { GroupExtendedPermission } from "common/GroupPermissions";
import Tables, { TableOption } from "common/Tables";
import { copyTableApi } from "common/DataApi";
import Variables from "common/Variables";
import OutsideAlerter from "common/OutsideAlerter";
import Draggable from "react-draggable";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    inputTable: TableOption;
    onClose: () => void;
    onUpdate: (dataScopeId: string | number) => void;
    overlayStyle?: CSSProperties;
    currentModuleId?: number;
}

enum UpdateStatus {
    success = 1,
    loading = 2,
    error = 3,
    notUploaded = 4,
}

interface State {
    left: number;
    top: number;
    status: UpdateStatus;
    errorMessage: string;
    newDataScope: DataScopeOption | null;
    dataScope: DataScopeOption | undefined;
    permissions: (GroupExtendedPermission | null)[];
}

@observer
class CopyTablePopup extends Component<Props, State> {
    drag: boolean = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            dataScope: undefined,
            newDataScope: null,
            permissions: [],
            status: UpdateStatus.notUploaded,
            errorMessage: "",
        };
    }

    private getPermissions(value: string | number): void {
        getDataTablePermissionsApi(value)
            .then((permissions) => {
                this.setState({ permissions: permissions });
            })
            .catch((_error) => {
                this.setState({ permissions: [] });
            });
    }

    private buildAlert(): JSX.Element {
        let alert: JSX.Element = <div style={{ minHeight: 38 }} />;
        let alertStyle = {
            marginBottom: 0,
            minHeight: 38,
            paddingTop: 10,
            paddingBottom: 10,
        };
        if (this.state.status === UpdateStatus.success) {
            alert = (
                <Alert
                    style={alertStyle}
                    text="Successully copied."
                    className="alert alert-success alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.error) {
            alert = (
                <Alert
                    style={alertStyle}
                    text={"Error: ".concat(this.state.errorMessage)}
                    className="alert alert-danger alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.loading) {
            alert = (
                <Alert
                    style={alertStyle}
                    text={"Saving"}
                    className="alert alert-warning alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        }
        return alert;
    }

    private buildInnerView(height: number): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    overflow: "visible",
                    marginLeft: "38px",
                    paddingTop: "20px",
                    height: height,
                }}
            >
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                        marginLeft: "19px",
                        marginBottom: "10px",
                    }}
                >
                    <span className="popup-regular-text">
                        Select or create data
                    </span>
                    <CreatableSelect
                        inputId="select-data-scope"
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        onCreateOption={(option) => {
                            this.setState({
                                newDataScope: {
                                    label: option,
                                    value: NaN,
                                    permissionType: Permission.ReadWrite,
                                },
                                dataScope: {
                                    label: option,
                                    value: NaN,
                                    permissionType: Permission.ReadWrite,
                                },
                                permissions: [],
                            });
                        }}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                marginLeft: "5px",
                                width: "25em",
                                height: "38px",
                                marginRight: "10px",
                            }),
                        }}
                        options={dataScopes.dataScopesOptions
                            .filter(
                                (option) =>
                                    option.permissionType ===
                                    Permission.ReadWrite
                            )
                            .concat(
                                this.state.newDataScope != null
                                    ? [this.state.newDataScope]
                                    : []
                            )}
                        onChange={(newValue) => {
                            let stateDiff: any = {
                                dataScope: newValue as DataScopeOption,
                                permissions: [],
                            };
                            if (stateDiff.dataScope?.value != null) {
                                this.getPermissions(stateDiff.dataScope.value);
                            }

                            this.setState(stateDiff);
                        }}
                        value={this.state.dataScope}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "19px",
                        marginBottom: "10px",
                    }}
                >
                    <span
                        className="popup-regular-text"
                        style={{
                            marginBottom: "5px",
                        }}
                    >
                        Access control
                    </span>
                    <GroupsPermissionsSelector
                        selectedUserGroups={this.state.permissions}
                        onChange={(permissions) => {
                            this.setState({
                                permissions: permissions,
                            });
                        }}
                    />
                </div>
                <div style={{ flexGrow: 1 }} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        marginBottom: "15px",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            width: 150,
                            height: "38px",
                            paddingTop: 0,
                            marginRight: "10px",
                            paddingBottom: 0,
                        }}
                        disabled={this.state.dataScope == null}
                        onClick={() => {
                            if (this.state.dataScope) {
                                this.setState({
                                    status: UpdateStatus.loading,
                                });
                                copyTableApi(
                                    this.props.inputTable,
                                    this.state.dataScope,
                                    this.state.permissions,
                                    this.props.currentModuleId ?? remoteModuleId
                                )
                                    .then(() => {
                                        this.setState({
                                            status: UpdateStatus.success,
                                        });
                                        dataScopes.update();
                                        let value = this.state.dataScope!.value;
                                        let alreadyExists = !Number.isNaN(
                                            value
                                        );
                                        if (alreadyExists) {
                                            Variables(value).update(
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            );
                                            Tables(value).update(
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            );
                                            this.props.onUpdate(value);
                                        }
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            status: UpdateStatus.error,
                                            errorMessage: String(error),
                                        });
                                    });
                            }
                        }}
                    >
                        SAVE
                    </Button>
                    {this.buildAlert()}
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        const height: number = 300;
        const width: number = 950;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                overlayStyle={this.props.overlayStyle}
                open={true}
                onClose={() => {
                    this.props.onClose();
                }}
                closeOnDocumentClick
            >
                <Draggable
                    cancel="input"
                    position={{
                        x: this.state.left,
                        y: this.state.top,
                    }}
                    onDrag={() => {
                        this.drag = true;
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            this.drag = false;
                            this.setState({
                                left: data.x,
                                top: data.y,
                            });
                        }
                    }}
                >
                    <div
                        className="dashboard-rect element cancel-drag"
                        style={{
                            overflowX: "visible",
                            overflowY: "auto",
                            boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                            borderRadius: 0,
                            alignItems: "center",
                            cursor: "pointer",
                            minHeight: height,
                            maxHeight: "100vh",
                            width: width,
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt: any) => {
                            evt.stopPropagation();
                        }}
                    >
                        <OutsideAlerter
                            onReject={() => {
                                this.props.onClose();
                            }}
                        >
                            {this.buildInnerView(height)}
                        </OutsideAlerter>
                    </div>
                </Draggable>
            </Popup>
        );
    }
}

export default CopyTablePopup;
