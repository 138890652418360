import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { reaction } from "mobx";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { DataScopeOption } from "common/DataScopes";
import { getRawDataApi } from "common/DataApi";
import remoteModuleId from "common/remoteModuleId";
import { strftime } from "common/utilities/TimeFormatUtils";
import Variables, { Variable } from "common/Variables";
import styles from "./TablePreview.module.css";
import { useDrag } from "react-dnd";
import CanvasTreeStore from "../../../CanvasTreeStore";
import Tables, { TableOption } from "common/Tables";
import { Condition } from "common/Conditions";
import { ReactComponent as PlusIcon } from "icons/canvas/exploration/plus.svg";
import { ReactComponent as EqualsIcon } from "icons/canvas/exploration/equals.svg";
import { ReactComponent as DragPointsIcon } from "icons/canvas/exploration/drag_points.svg";

import cx from "classnames";
import Finding, { formatValues, isLinePlot } from "common/Finding";
import CurrentModulesStore from "common/CurrentModulesStore";

interface Props {
    finding: Finding;
    canvasTreeStore: CanvasTreeStore;
    conditions?: Condition[];
    dataScope: DataScopeOption | null;
    selectedTable: TableOption;
    currentModuleId?: number;
    onDragActive: (active: boolean) => void;
    onClose: () => void;
    onMinimize?: () => void;
    selectedColumns?: {
        value: number;
        variableIndex: number;
    }[];
    operationVariable?: string;
}

interface DraggableProps {
    variableIndex: number;
    variableName: string;
    variableType: string;
    className: string;
    index: number;
    hover: boolean;
    onDragActive: (active: boolean) => void;
    onSetHoverColumn: (index: number | undefined) => void;
}

const DraggableHeader: React.FunctionComponent<DraggableProps> = (props) => {
    const [collected, drag, preview] = useDrag({
        item: {
            content: {
                ...props,
            },
        },
        end: (item, monitor) => {},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        type: "variable_column",
    });
    // if (collected.isDragging && props.hover) {
    // 	props.onSetHoverColumn(undefined);
    // }
    return (
        <div
            ref={drag}
            style={{ position: "relative" }}
            onDragStart={() => {
                props.onDragActive(true);
            }}
            onDragEnd={() => {
                props.onDragActive(false);
            }}
            onMouseEnter={() => {
                props.onSetHoverColumn(props.index);
            }}
            onMouseLeave={() => {
                props.onSetHoverColumn(undefined);
            }}
        >
            <div ref={preview}>
                <div
                    className={props.className}
                    style={{
                        position: "relative",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                            fontSize: "10px",
                            color: "black",
                        }}
                    >
                        {props.variableName}
                    </span>
                </div>
            </div>
            {props.hover && !collected.isDragging && (
                <div className={styles.dragHandle}>
                    <DragPointsIcon />
                    <span
                        style={{
                            marginLeft: 10,
                        }}
                    >
                        Drag
                    </span>
                </div>
            )}
        </div>
    );
};

function TablePreview({
    dataScope,
    currentModuleId,
    onDragActive,
    finding,
    conditions,
    selectedTable,
    selectedColumns,
    operationVariable,
    onMinimize,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [tableCount, setTableCount] = useState<number | undefined>(undefined);

    const [tableHeader, setTableHeader] = useState<string[]>([]);
    const [tableContent, setTableContent] = useState<
        (string | number | null)[][]
    >([]);
    const [tableColumnTypes, setTableColumnTypes] = useState<string[]>([]);
    const [hoverColumn, setHoverColumn] = useState<number | undefined>(
        undefined
    );
    const [variableInfo, setVariableInfo] = useState<Variable[]>(
        Variables(dataScope?.value, currentModuleId ?? remoteModuleId)
            .dataVariables
    );

    useEffect(() => {
        const dispose = reaction(
            () =>
                Variables(dataScope?.value, currentModuleId ?? remoteModuleId)
                    .dataVariables,
            setVariableInfo
        );
        return dispose;
    }, [currentModuleId, dataScope?.value]);

    const [error, setError] = useState();

    useEffect(() => {
        if (!dataScope) {
            setTableHeader([]);
            setTableContent([]);
            setTableColumnTypes([]);
            return;
        }

        const table =
            selectedTable ??
            Tables(
                dataScope!.value,
                currentModuleId ?? remoteModuleId
            ).tableToOption();

        const tableHeader = variableInfo.map((item) => item.name);
        const tableColumnTypes = variableInfo.map((item) => item.type);

        async function getData() {
            setError(undefined);
            if (!dataScope) return;

            setLoading(true);

            try {
                const data = await getRawDataApi(
                    table ?? {
                        label: "",
                        value: [],
                        optimized: false,
                        data_table_idx: dataScope.value,
                    },
                    10,
                    conditions,
                    undefined,
                    undefined,
                    currentModuleId ?? remoteModuleId,
                    undefined,
                    true
                );
                let allCount = data.count!;
                let tableContent: (string | number | null)[][] = [];
                let rowCount = data.rowId.length;
                for (let i = 0; i < rowCount + 1; ++i) {
                    let row: (string | number | null)[] = [];
                    for (let [j, varName] of tableHeader.entries()) {
                        if (i === rowCount) {
                            if (j === 0)
                                row.push(`+${allCount - rowCount} rows`);
                            else row.push("...");
                            continue;
                        }

                        if (typeof data.currentLevels[varName] === "undefined")
                            break;

                        row.push(
                            variableInfo[j]!.type === "datetime" &&
                                typeof data.currentLevels[varName][i] ===
                                    "number"
                                ? strftime(
                                      variableInfo[j]!.format!,
                                      new Date(
                                          (data.currentLevels[varName][
                                              i
                                          ] as number) * 1000
                                      )
                                  )
                                : data.currentLevels[varName][i] ||
                                      (typeof data.currentLevels[varName][i] ===
                                      "number"
                                          ? data.currentLevels[varName][i]
                                          : "-")
                        );
                    }
                    tableContent.push(row);
                }

                setTableHeader(tableHeader);
                setTableContent(tableContent);
                setTableCount(allCount);
                setTableColumnTypes(tableColumnTypes);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [currentModuleId, dataScope, selectedTable, variableInfo, conditions]);

    const currentModule = CurrentModulesStore.getModule(
        currentModuleId as number
    );

    return (
        <div
            className="cancel"
            style={{
                marginLeft: "5px",
                marginRight: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: error ? "center" : undefined,
                border: "1px solid #e1e1e1",
                height: "calc(100% - 30px - 5px)",
            }}
        >
            {loading && <h1>Loading...</h1>}
            {error === "Permission denied" &&
                `Dataset "${
                    dataScope?.label || "unknow"
                }" is not shared, please ask "${(
                    currentModule?.user_data?.first_name +
                    " " +
                    currentModule?.user_data?.last_name
                ).trim()}" to share it`}
            {!!tableHeader.length && !loading && (
                <Element
                    name="scrollable"
                    style={{
                        padding: "20px 0 0 0",
                        overflow: "auto",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "8px",
                    }}
                >
                    <AdminTableWithFullFeatures
                        headerRenderer={(item, index) => {
                            let selected = selectedColumns?.find(
                                (item: {
                                    variableIndex: number;
                                    value: number | number[];
                                }) => item.variableIndex === index
                            );
                            return (
                                <DraggableHeader
                                    onDragActive={onDragActive}
                                    hover={index === hoverColumn}
                                    variableIndex={index}
                                    variableName={item}
                                    variableType={tableColumnTypes[index]}
                                    key={index}
                                    index={index}
                                    onSetHoverColumn={setHoverColumn}
                                    className={cx(styles.tableCell, {
                                        [styles.tableHeaderSelected]: selected,
                                        [styles.tableHeaderSelectedWithOperation]:
                                            selected && operationVariable,
                                        [styles.tableHeaderHover]:
                                            !selected && hoverColumn === index,
                                    })}
                                ></DraggableHeader>
                            );
                        }}
                        className="import-table import-table-nopadding"
                        paging={false}
                        small={false}
                        tableName="Data"
                        tableHeader={tableHeader}
                        tableContent={tableContent}
                        cellRenderer={(item, columnIndex, rowIndex) => {
                            let selected = selectedColumns?.find(
                                (item: {
                                    variableIndex: number;
                                    value: number | number[];
                                }) => item.variableIndex === columnIndex
                            );
                            let additionalStyles: React.CSSProperties = {};

                            return (
                                <div
                                    className={cx(styles.tableCell, {
                                        [styles.tableCellSelected]: selected,
                                        [styles.tableCellSelectedWithOperation]:
                                            selected && operationVariable,
                                        [styles.tableCellHover]:
                                            !selected &&
                                            hoverColumn === columnIndex,
                                    })}
                                    style={{
                                        ...additionalStyles,
                                        position: "relative",
                                    }}
                                    onMouseEnter={() => {
                                        setHoverColumn(columnIndex);
                                    }}
                                    onMouseLeave={() => {
                                        setHoverColumn(undefined);
                                    }}
                                >
                                    {item}
                                    {selected &&
                                        !isLinePlot(finding) &&
                                        operationVariable &&
                                        rowIndex < tableContent.length - 1 && (
                                            <div
                                                style={{
                                                    zIndex: 1,
                                                    left: 0,
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    position: "absolute",
                                                    top: "100%",
                                                    transform:
                                                        "translate(0, -50%)",
                                                }}
                                            >
                                                <PlusIcon />
                                            </div>
                                        )}
                                    {!isLinePlot(finding) &&
                                        selected &&
                                        operationVariable &&
                                        rowIndex ===
                                            tableContent.length - 1 && (
                                            <div
                                                style={{
                                                    left: 0,
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    position: "absolute",
                                                    top: "150%",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {selected &&
                                                    operationVariable ===
                                                        "mean" &&
                                                    tableCount != null && (
                                                        <div
                                                            className="flex-simple-column"
                                                            style={{
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <span
                                                                className={
                                                                    styles.averageLabel
                                                                }
                                                            >
                                                                {formatValues(
                                                                    selected.value ==
                                                                        null
                                                                        ? selected.value
                                                                        : typeof selected.value ===
                                                                          "number"
                                                                        ? selected.value *
                                                                          tableCount
                                                                        : (selected.value as number[]).map(
                                                                              (
                                                                                  item: number
                                                                              ) =>
                                                                                  item *
                                                                                  tableCount
                                                                          )
                                                                )}
                                                            </span>
                                                            <div
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    height: 1,
                                                                    backgroundColor:
                                                                        "rgba(36, 33, 36, 0.8)",
                                                                }}
                                                            />
                                                            <span
                                                                className={
                                                                    styles.averageLabel
                                                                }
                                                            >
                                                                {tableCount}
                                                            </span>
                                                        </div>
                                                    )}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <EqualsIcon />
                                                    <div
                                                        className={
                                                            styles.equalsLabel
                                                        }
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                    >
                                                        {formatValues(
                                                            selected.value
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            );
                        }}
                    />
                </Element>
            )}
        </div>
    );
}

export default TablePreview;
