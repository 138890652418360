import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props) {
        let { data, config } = props;
        let item = {
            type: "20",
            content: {
                data: data,
            },
            config: config,
        };
        return item;
    }
    static getData(config, findingOptions, moduleId) {
        let {
            operationVariable,
            dataScope,
            selectedTargetVariablesIndices,
            conditions,
            selectedTable,
            selectedSummaryVariablesIndices,
            categoryVariableIndex,
            titleVariableIndex,
        } = config;

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let requestJson = {
            table: selectedTable.value,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            condition_id: selectedTable.condition_id,
            conditions: conditions,
            title_variable_index: titleVariableIndex,
            variable_indices: selectedTargetVariablesIndices,
            category_variable_index: categoryVariableIndex,
            summary_operation: operationVariable,
            summary_variable_indices: selectedSummaryVariablesIndices,
            module_id: moduleId,
        };
        return axios
            .post("/api/e/kanban_board", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let finding = Api.getFinding({
                        data: {
                            lanes: response.data.lanes,
                            metadata: response.data.metadata,
                        },
                        config: config,
                    });
                    return Promise.resolve(finding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
