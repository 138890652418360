import axios from "./ServerConnection";
import { Feedback, FeedbackResponse, toFeedback } from "common/Feedback";

export async function addFeedbackApi(
    feedback: string,
    cardId: number,
    type: number
): Promise<number> {
    let requestJson = {
        feedback: feedback,
        card_id: cardId,
        type: type,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_feedback", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function editFeedbackApi(
    feedback: string,
    feedbackId: number,
    type: number
): Promise<number> {
    let requestJson = {
        feedback: feedback,
        id: feedbackId,
        type: type,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/edit_feedback", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function deleteFeedbackApi(
    feedbackId: number,
    type: number
): Promise<void> {
    let requestJson = {
        id: feedbackId,
        type: type,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/remove_feedback", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getFeedbacksApi(
    cardId: number,
    type: number
): Promise<Feedback[]> {
    let requestJson = {
        card_id: cardId,
        type: type,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            feedbacks?: FeedbackResponse[];
        }>("/api/get_feedbacks", requestJson)
        .then((response) => {
            if (response.data.success && response.data.feedbacks != null) {
                return Promise.resolve(
                    response.data.feedbacks.map(
                        (item: FeedbackResponse): Feedback => toFeedback(item)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}
export async function sendMessageNotification(
    cardId: number,
    url: string,
    message: string,
    users: string[]
): Promise<void> {
    let requestJson = {
        id: cardId,
        message: message,
        recipient_user_names: users,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>(url, requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}
