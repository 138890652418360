import React, { Component, CSSProperties } from "react";
import { TableOption } from "common/Tables";
import { exportTableToCSVApi } from "common/DataApi";
import remoteModuleId from "common/remoteModuleId";
import ExportPopup from "common/canvas/ExportPopup";

interface Props {
    inputTable: TableOption;
    onClose: () => void;
    overlayStyle?: CSSProperties;
    currentModuleId?: number;
}

class ExportCsvPopup extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.exportCsv = this.exportCsv.bind(this);
    }
    private downloadExportedFile(text: string, title: string): void {
        const element = document.createElement("a");
        const file = new Blob([text], {
            type: "text/csv;charset=utf-8",
        });
        element.href = URL.createObjectURL(file);
        element.download = `${title}`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    private async exportCsv(title: string) {
        let data = await exportTableToCSVApi(
            this.props.inputTable,
            this.props.currentModuleId ?? remoteModuleId
        );
        
        this.downloadExportedFile(data, title);
    }

    public render(): JSX.Element {
        return (
            <ExportPopup
                overlayStyle={this.props.overlayStyle}
                onClose={this.props.onClose}
                exportFunction={this.exportCsv}
            />
        );
    }
}
export default ExportCsvPopup;