import React, { Component, CSSProperties } from "react";
import { observer } from "mobx-react";
import { VariableSizeGrid as Grid } from "react-window";
import {
    CanvasSpreadSheetGrid,
    CanvasNode,
    CanvasElement,
    CanvasGridType,
    SelectionArea,
    GridColorOptions,
    getTargetValue,
    NumberFormat,
    NumberFormatType,
    SpreadSheetColumnType,
} from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import {
    gridSpreadSheetDefaultWidth,
    gridSpreadSheetDefaultHeight,
    fullGridSpreadSheetDefaultHeight,
    fullGridSpreadSheetDefaultWidth,
    defaultNumberLetterTitleSize,
    // let defaultHeaderColor = "#000000";
    // let defaultHeaderFillColor = "#CCCCCC";
} from "../Constants";
import { mainStyle } from "common/MainStyle";

import { CanvasEditedElement } from "./FlowChartProps";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import "common/styles/rotate.css";
import DataScopes, {
    DataScopeOption,
} from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
// import SpreadSheetElementRow from "./SpreadSheetElementRow";
import SpreadSheetElementCell from "./SpreadSheetElementCell";
import CanvasExtendedGridHeader from "./CanvasExtendedGridHeader";
import SpreadSheetState, { MouseMultiSelection } from "./SpreadSheetState";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { geocodeAddresses } from "common/google_api/Geocoder";
import _ from "lodash";
import DatasetFilterToolbar from "../DatasetFilterToolbar";
import DatasetEditToolbar from "../DatasetEditToolbar";
import {
    filterNonEmptyConditions,
    ConditionsSelector,
    Condition,
} from "common/Conditions";
import tables, { TableOption } from "common/Tables";
import { VariableOption } from "common/Variables";
import Portal from "common/Portal";
import OutsideAlerter from "common/OutsideAlerter";


export type Direction = "scrollTop" | "scrollBottom" | "scrollLeft" | "scrollRight";

const scrollDirections: Record<string, Direction> = {
    top: "scrollTop",
    bottom: "scrollBottom",
    left: "scrollLeft",
    right: "scrollRight",
}

interface Props {
    canvasTreeStore: CanvasTreeStore;
    hovered: boolean;
    showStreamButtonsAdvancedOptions: boolean;
    currentModuleId?: number;
    rootDataTestId: string;
    colorOptions: GridColorOptions;
    selectionArea: SelectionArea | undefined;
    onSelectArea: (
        area: SelectionArea | undefined,
        allowFontColorChange: boolean
    ) => void;
    moduleTitle: string;
    currentSimpleEdit: React.RefObject<any>;
    scale: number;
    spreadSheetGrid: CanvasSpreadSheetGrid;
    nodes: CanvasElement[];
    editedNode: CanvasEditedElement | undefined;
    live: boolean;
    canWrite: boolean;
    sharedPolicy: CanvasSharedPolicy;
    editedGridHeader: CanvasExtendedGridHeader | undefined;
    moveSelection: Function;
    onClearEditing: () => void;
    onKeepSimpleEditChanges: () => void;
    onChangeEditedNode: (node: CanvasElement) => void;
    onStartEditNode: (
        node: CanvasElement,
        focus?: boolean,
        internalEdit?: boolean
    ) => void;
    onChangeEditedHeader: (header: CanvasExtendedGridHeader) => void;
    onStartEditHeader: (header: CanvasExtendedGridHeader) => void;
    onSimpleEditInsertOuterId: (node: CanvasNode) => void;
    onStartAdvancedEditing: (node: CanvasNode) => void;
    onNodeClick: (evt: any, node: CanvasElement) => void;
    onHeaderContextMenu: (evt: any, row: number, col: number) => void;
    onItemContextMenu: (evt: any, node: CanvasElement) => void;
    onHideContextMenu: () => void;
    onChange: (props: Partial<CanvasSpreadSheetGrid>) => void;
    onSort: (column: number) => void;
    onResize: () => void;
    onApplyFormat: () => void;
}

function innerSheetHeight(spreadSheetGrid: CanvasSpreadSheetGrid) {
    let defaultSize = getDefaultSize(spreadSheetGrid);

    let height = 0;
    for (let i = 0; i < spreadSheetGrid.rows; ++i) {
        height += defaultSize.height * (spreadSheetGrid.rowScales?.[i] ?? 1);
    }
    if (spreadSheetGrid.headersEnabled) {
        height += (spreadSheetGrid.titleRowScale ?? 1) * defaultSize.height;
    }
    return height;
}

function innerSheetWidth(spreadSheetGrid: CanvasSpreadSheetGrid) {
    let defaultSize = getDefaultSize(spreadSheetGrid);

    let width = 0;
    for (let i = 0; i < spreadSheetGrid.cols; ++i) {
        width += defaultSize.width * (spreadSheetGrid.columnScales?.[i] ?? 1);
    }
    if (spreadSheetGrid.leftHeadersEnabled) {
        width +=
            (spreadSheetGrid.leftTitleColumnScale ?? 1) * defaultSize.width;
    }
    return width;
}

const advancedOptionsAdditionalScale = 0.5;

// Currently unused
// function UpdateDataButton(props: {
//     onUpdateDataClick: () => void;
//     onPullDataClick: () => void;
// }) {
//     let [open, setOpen] = React.useState(false);
//     let buttonStyle = {
//         height: "28px",
//         border: "none",
//         backgroundColor: "#F0F2F5",
//         color: "#4A4047",
//     };
//
//     const menuClass = `dropdown-menu${open ? " show" : ""}`;
//     return (
//         <div className="btn-group" style={{ marginRight: "5px" }}>
//             <Button
//                 className="btn btn-sm btn-primary my-primary"
//                 style={{ ...buttonStyle, width: "125px" }}
//                 type="button"
//                 onClick={props.onUpdateDataClick}
//             >
//                 Write Data to Cloud
//             </Button>
//             <Button
//                 onClick={() => {
//                     setOpen(!open);
//                 }}
//                 style={{
//                     ...buttonStyle,
//                     display: "flex",
//                     alignItems: "center",
//                 }}
//                 type="button"
//                 className={"btn dropdown-toggle"}
//                 data-toggle="dropdown"
//                 aria-haspopup="true"
//                 aria-expanded="false"
//             >
//                 <span className="caret"></span>
//             </Button>
//             <div
//                 className={menuClass}
//                 style={{ border: "none", top: 28, padding: 0 }}
//             >
//                 <Button
//                     className="btn btn-sm btn-primary my-primary"
//                     style={{ ...buttonStyle, width: "125px" }}
//                     type="button"
//                     onClick={props.onPullDataClick}
//                 >
//                     Pull Data
//                 </Button>
//             </div>
//         </div>
//     );
// }

export function calculateSpreadSheetGridSize(
    spreadSheetGrid: CanvasSpreadSheetGrid,
    canvasTreeStore: CanvasTreeStore,
) {
    let width = 370;
    let height = 170;
    // let width = 20 + defaultNumberLetterTitleSize;
    // let height = 50;
    // 30, 80 - extra numbers to remove scroll bars
    // height += innerSheetHeight(spreadSheetGrid) + 30;
    // width += innerSheetWidth(spreadSheetGrid) + 80;

    return {
        desktop: { width: 0, height: 0, },
        mobile: { width: 0, height: 0, },
        ...spreadSheetGrid.containerSize,
        [canvasTreeStore.canvasViewMode]: {
            width: width,
            height: height,
        }
    };
}

function getDefaultSize(grid: CanvasSpreadSheetGrid) {
    let isSimpleSpreadSheet = grid.type === CanvasGridType.SimpleSpreadSheet;
    if (!isSimpleSpreadSheet) {
        return {
            width: gridSpreadSheetDefaultWidth,
            height: gridSpreadSheetDefaultHeight,
        };
    } else {
        return {
            width: fullGridSpreadSheetDefaultWidth,
            height: fullGridSpreadSheetDefaultHeight,
        };
    }
}

@observer
class SpreadSheetElement extends Component<Props, SpreadSheetState> {
    private currentSimpleEditParentItem = React.createRef<HTMLDivElement>();
    private datasetSelectorRef = React.createRef<any>();
    private gridRef = React.createRef<Grid>();
    private gridHeaderRef = React.createRef<Grid>();

    constructor(props: Props) {
        super(props);
        this.state = {
            resizeHeader: false,
            autoFillArea: undefined,
            streamMessage: null,
            streamStatus: null,
            initPullingIsActive: false,
            syncingIsActive: false,
            newDataScopeOption: null,
            dataScopeOptionToSave: null,
            showAdvancedOptions: false,
            datasetDropdownHovered: false,
            showRewriteDatasetWarning: false,
            switchedDataScopeOption: null,
            openedFormatDropdownCol: -1,
            mouseMultiSelection: {
                started: false,
                pos: {
                    startRow: 0,
                    startCol: 0,
                }
            },
            cellHoverId: undefined,
        };
        this.renderDivOnContextMenu = this.renderDivOnContextMenu.bind(this);
        this.renderDivOnClick = this.renderDivOnClick.bind(this);
        // this.sliceRows = this.sliceRows.bind(this);
        this.renderCell = this.renderCell.bind(this);
        this.renderHeaderCell = this.renderHeaderCell.bind(this);
        this.setSpreadSheetState = this.setSpreadSheetState.bind(this);
        this.generateLocation = this.generateLocation.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.enableMultiSelection = this.enableMultiSelection.bind(this);
    }
    componentDidUpdate(prevProps: Props) {
        let prevStream =
            prevProps.spreadSheetGrid.fullSpreadSheetBackendOutputOptions !=
            null;
        let currentStream =
            this.props.spreadSheetGrid.fullSpreadSheetBackendOutputOptions !=
            null;
        if (prevStream !== currentStream) {
            this.setState({ streamStatus: null, streamMessage: null });
        }
        if (!prevProps.live && this.props.live) {
            this.setState({ showAdvancedOptions: false });
        }

        if (this.props.scale !== prevProps.scale) {
            this.gridRef.current?.resetAfterIndices({
                rowIndex: 0,
                columnIndex: 0,
            });
            this.gridHeaderRef.current?.resetAfterIndices({
                rowIndex: 0,
                columnIndex: 0,
            });
        } else {
            if (
                this.props.spreadSheetGrid.leftHeadersEnabled !==
                    prevProps.spreadSheetGrid.leftHeadersEnabled ||
                this.props.spreadSheetGrid.cols !==
                    prevProps.spreadSheetGrid.cols
            ) {
                this.gridRef.current?.resetAfterColumnIndex(0);
                this.gridHeaderRef.current?.resetAfterColumnIndex(0);
            }
            if (
                this.props.spreadSheetGrid.rows !==
                prevProps.spreadSheetGrid.rows
            ) {
                this.gridRef.current?.resetAfterRowIndex(0);
            }
        }
    }
    componentDidMount() {
        setTimeout(() => {
            if (
                this.props.spreadSheetGrid
                    .fullSpreadSheetBackendOutputOptions == null
            )
                this.datasetSelectorRef.current?.focus({ preventScroll: true });
        }, 0);
    }

    private setSpreadSheetState<T extends keyof SpreadSheetState>(
        state:
            | Pick<SpreadSheetState, T>
            | ((prevState: SpreadSheetState) => Pick<SpreadSheetState, T>)
    ): void {
        this.setState(state);
    }

    private initData(
        dataScopeOption: DataScopeOption,
        tableOption: TableOption | null,
        conditions: Condition[],
        columnFilter: VariableOption[],
        limit: number,
        bottomRows: boolean
    ) {
        if (this.state.syncingIsActive || this.state.initPullingIsActive)
            return;
        this.setState(
            {
                initPullingIsActive: true,
                streamStatus: null,
                streamMessage: null,
            },
            () => {
                this.props.canvasTreeStore
                    .readDataIntoSpreadSheet(
                        this.props.spreadSheetGrid.id,
                        dataScopeOption.value,
                        tableOption ?? null,
                        limit,
                        true,
                        false,
                        conditions,
                        columnFilter,
                        bottomRows
                    )
                    .then(() => {
                        this.props.canvasTreeStore.updateAllAsyncAction.bothParts(
                            false,
                            this.props.canvasTreeStore.canvasTreeState
                        );

                        this.setState({
                            initPullingIsActive: false,

                            streamStatus: PopupStatus.Success,
                            streamMessage: `Data pulled from ${dataScopeOption.label} successfully`,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            initPullingIsActive: false,
                            streamStatus: PopupStatus.Error,
                            streamMessage: String(error),
                        });
                    });
            }
        );
    }

    private async generateLocation(col: number) {
        let column = this.props.nodes.filter((node) => node.nodePosition[this.props.canvasTreeStore.canvasViewMode].x === col);
        let addresses: string[] = column.map((node) => {
            let targetValue = getTargetValue(node);
            if (typeof targetValue === "string") return targetValue as string;

            return "";
        });

        try {
            let result = await geocodeAddresses(addresses);
            let cols = this.props.spreadSheetGrid.cols;
            let newColNames: ("latitude" | "longitude")[] = [
                "latitude",
                "longitude",
            ];

            let format: NumberFormat = {
                type: SpreadSheetColumnType.Number,
                numberType: NumberFormatType.Number,
                decimalPoints: 2,
                useCommaSeparator: false,
            };
            let startIndexToInsert = col + 1;
            let latHeader =
                this.props.spreadSheetGrid.headers?.[startIndexToInsert];
            let lngHeader =
                this.props.spreadSheetGrid.headers?.[startIndexToInsert + 1];
            let newColCount = cols;
            let rewriteCols =
                latHeader?.text === newColNames[0] &&
                lngHeader?.text === newColNames[1] &&
                latHeader?.columnFormat?.type ===
                    SpreadSheetColumnType.Number &&
                lngHeader?.columnFormat?.type === SpreadSheetColumnType.Number;
            if (!rewriteCols) {
                for (let j = 0; j < newColNames.length; ++j) {
                    this.props.canvasTreeStore.insertGridColumnAction(
                        this.props.spreadSheetGrid.id,
                        startIndexToInsert + j,
                        newColNames[j],
                        format
                    );
                }
                newColCount += newColNames.length;
            }
            let nodes = this.props.canvasTreeStore.getSortedGridNodes(
                this.props.spreadSheetGrid.id
            );
            let changes: { [nodeId: number]: Partial<CanvasElement> } = {};
            for (let i = 0; i < result.length; ++i)
                for (
                    let j = startIndexToInsert;
                    j < startIndexToInsert + newColNames.length;
                    ++j
                ) {
                    let coordinate =
                        result[i][newColNames[j - startIndexToInsert]];

                    let node = nodes[i * newColCount + j];
                    changes[node.id] = {
                        metric: coordinate ? String(coordinate) : "",
                    };
                }
            if (Object.keys(changes).length > 0) {
                this.props.canvasTreeStore.updateNodesAction(changes, true);
            }
            this.setState({
                streamStatus: PopupStatus.Success,
                streamMessage: `Location is generated`,
            });
        } catch (error) {
            this.setState({
                streamStatus: PopupStatus.Error,
                streamMessage: String(error),
            });
        }
    }

    private getSpreadSheetHeaderHeight(): number {
        let defaultSize = getDefaultSize(this.props.spreadSheetGrid);
        if (!this.props.spreadSheetGrid.headersEnabled) return 0;
        return (
            this.props.scale *
            defaultSize.height *
            (this.props.spreadSheetGrid.titleRowScale ?? 1)
        );
    }

    private getAdvancedOptionHeight(): number {
        if (!this.props.showStreamButtonsAdvancedOptions) return 0;

        let fullSpreadSheetBackendOutputOptions =
            this.props.spreadSheetGrid.fullSpreadSheetBackendOutputOptions;
        if (fullSpreadSheetBackendOutputOptions == null) return 0;
        let conditionsLength =
            fullSpreadSheetBackendOutputOptions!.conditions?.length;
        return (
            (117 + 41 * (conditionsLength ? conditionsLength - 1 : 0)) *
            advancedOptionsAdditionalScale *
            this.props.scale
        ) + 30; // 20 is an extra value
    }

    private buildAdvancedOptions(
        dataScopeOption: DataScopeOption | null | undefined
    ): JSX.Element | null {
        if (dataScopeOption == null) return null;
        if (!this.props.showStreamButtonsAdvancedOptions) return null;
        let fullSpreadSheetBackendOutputOptions =
            this.props.spreadSheetGrid.fullSpreadSheetBackendOutputOptions;
        if (fullSpreadSheetBackendOutputOptions == null) return null;

        return (
            <div
                style={{
                    zIndex: 1,
                    height: this.getAdvancedOptionHeight(),
                    width: `calc(100%/${
                        advancedOptionsAdditionalScale * this.props.scale
                    } - ${10 / this.props.scale}px)`,
                    paddingLeft: 5,
                    transformOrigin: "left top",
                    transform: `scale(${
                        advancedOptionsAdditionalScale * this.props.scale
                    })`,
                }}
            >
                <DatasetEditToolbar
                    table={
                        fullSpreadSheetBackendOutputOptions.tableOption ?? null
                    }
                    dataScopeId={
                        fullSpreadSheetBackendOutputOptions.dataScopeId
                    }
                    currentModuleId={this.props.currentModuleId}
                    onUpdate={() => {}}
                />
                <DatasetFilterToolbar
                    hideTitles
                    table={
                        fullSpreadSheetBackendOutputOptions.tableOption ??
                        tables(
                            dataScopeOption.value,
                            this.props.currentModuleId
                        ).tableToOption()
                    }
                    dataScopeId={
                        fullSpreadSheetBackendOutputOptions.dataScopeId
                    }
                    currentModuleId={this.props.currentModuleId}
                    conditions={
                        fullSpreadSheetBackendOutputOptions.conditions &&
                        fullSpreadSheetBackendOutputOptions.conditions.length >
                            0
                            ? fullSpreadSheetBackendOutputOptions.conditions
                            : ConditionsSelector.defaultValue
                    }
                    canvasTreeStore={this.props.canvasTreeStore}
                    bottomRows={
                        fullSpreadSheetBackendOutputOptions.bottomRows ?? false
                    }
                    limitText={String(
                        fullSpreadSheetBackendOutputOptions.limit ?? 10
                    )}
                    columnFilter={
                        fullSpreadSheetBackendOutputOptions.variables ?? []
                    }
                    spreadsheetId={this.props.spreadSheetGrid.id}
                    onChange={(changes) => {
                        if (
                            this.state.syncingIsActive ||
                            this.state.initPullingIsActive
                        )
                            return;
                        let initData: boolean = true;
                        if (changes.conditions != null) {
                            let filteredConditions = filterNonEmptyConditions(
                                changes.conditions
                            );
                            let currentConditions = filterNonEmptyConditions(
                                fullSpreadSheetBackendOutputOptions!
                                    .conditions ?? []
                            );
                            if (
                                _.isEqual(filteredConditions, currentConditions)
                            ) {
                                initData = false;
                            }
                        }
                        let fullSpreadSheetBackendOutputOptionsChanges = {
                            tableOption:
                                changes.table ??
                                fullSpreadSheetBackendOutputOptions!
                                    .tableOption ??
                                null,
                            conditions:
                                changes.conditions ??
                                fullSpreadSheetBackendOutputOptions!
                                    .conditions ??
                                ConditionsSelector.defaultValue,
                            variables:
                                changes.columnFilter ??
                                fullSpreadSheetBackendOutputOptions!
                                    .variables ??
                                [],
                            limit: changes.limitText
                                ? Number(changes.limitText)
                                : fullSpreadSheetBackendOutputOptions!.limit ??
                                  10,
                            bottomRows:
                                changes.bottomRows ??
                                fullSpreadSheetBackendOutputOptions!
                                    .bottomRows ??
                                false,
                        };

                        this.props.canvasTreeStore.updateGridAction(
                            this.props.spreadSheetGrid.id,
                            {
                                fullSpreadSheetBackendOutputOptions: {
                                    ...fullSpreadSheetBackendOutputOptions!,
                                    ...fullSpreadSheetBackendOutputOptionsChanges,
                                },
                            }
                        );
                        if (!initData) return;

                        this.initData(
                            dataScopeOption,
                            fullSpreadSheetBackendOutputOptionsChanges.tableOption,
                            fullSpreadSheetBackendOutputOptionsChanges.conditions,
                            fullSpreadSheetBackendOutputOptionsChanges.variables,
                            fullSpreadSheetBackendOutputOptionsChanges.limit,
                            fullSpreadSheetBackendOutputOptionsChanges.bottomRows
                        );
                    }}
                />
            </div>
        );
    }

    private renderDivOnContextMenu(): void {
        this.props.onHideContextMenu();
    }

    private renderDivOnClick(): void {
        this.props.onHideContextMenu();
        //    this.props.onClearEditing();
        // this.props.onSelectArea(undefined, false);
    }

    private onKeyDown(
        evt: React.KeyboardEvent<HTMLDivElement>,
    ): void {
        if ((evt.key === "Enter" || evt.key.includes("Arrow")) && !evt.shiftKey) {
            const topDir = evt.key === "ArrowUp";
            const leftDir = evt.key === "ArrowLeft";
            const rightDir = evt.key === "ArrowRight";
            const isReversed = topDir || leftDir;
            const XAxisScroll = leftDir || rightDir;

            let defaultSize = getDefaultSize(this.props.spreadSheetGrid);
            const { editedNode, canvasTreeStore } = this.props;
            if (!editedNode) return;
            const { containerSize, rows, cols, columnScales, rowScales } = this.props.spreadSheetGrid;
            const grid = this.gridRef.current;
            if (!containerSize || !grid) return;
            const { height, width } = containerSize[canvasTreeStore.canvasViewMode];

            const direction = XAxisScroll ? "scrollLeft" : "scrollTop";

            let currentCol = editedNode.nodePosition[this.props.canvasTreeStore.canvasViewMode].x;
            let currentRow = editedNode.nodePosition[this.props.canvasTreeStore.canvasViewMode].y;
            let nextRow = currentRow;
            let nextCol = currentCol;

            if (XAxisScroll) {
                nextCol += isReversed ? -1 : 1;
            } else {
                nextRow += isReversed ? -1 : 1;
            }

            const cellHeight = defaultSize.height * (rowScales?.[nextRow] ?? 1) * this.props.scale;
            const cellWidth = defaultSize.width * (columnScales?.[nextCol] ?? 1) * this.props.scale;

            if (nextRow === rows || nextCol === cols) {
                grid.scrollTo({
                    [direction]: -(grid.state as any)[direction],
                });
            } else {
                let shift = XAxisScroll ? cellWidth : cellHeight;
                if (isReversed) shift *= -1;
                const nextRowPos = cellHeight * nextRow;
                const nextColPos = cellWidth * nextCol;

                const gridHeight = height + (grid.state as any)["scrollTop"];
                const reversedGridHeight = (grid.state as any)["scrollTop"];

                const gridWidth = width + (grid.state as any)["scrollLeft"];
                const reversedGridWidth = (grid.state as any)["scrollLeft"];

                let condition = nextRowPos > gridHeight;
                if (XAxisScroll) condition = nextColPos > gridWidth;
                if (isReversed && XAxisScroll) condition = nextColPos < reversedGridWidth;
                if (isReversed && !XAxisScroll) condition = nextRowPos < reversedGridHeight;

                if (condition && this.gridRef.current) {
                    grid.scrollTo({
                        [direction]: (grid.state as any)[direction] + shift,
                    });
                }
            }
        }
    }

    // private sliceRows(args: {
    //     index: number;
    //     style: CSSProperties;
    // }): JSX.Element {
    //     let index = args.index ?? 0;
    //     if (this.props.spreadSheetGrid.headersEnabled) {
    //         index -= 1;
    //     }
    //     let isSimpleSpreadSheet =
    //         this.props.spreadSheetGrid.type ===
    //         CanvasGridType.SimpleSpreadSheet;
    //     let showOptions = !this.props.live;
    //     const gridStyle: CSSProperties = {
    //         gridRowGap: 0,
    //         gridColumnGap: 0,
    //         gridTemplateColumns: `repeat(${
    //             this.props.spreadSheetGrid.cols +
    //             (this.props.spreadSheetGrid.leftHeadersEnabled ? 1 : 0) +
    //             +(showOptions ? 1 : 0)
    //         }, max-content)`,
    //         display: "grid",
    //     };
    //     let defaultSize = getDefaultSize(this.props.spreadSheetGrid);
    //     let defaultLineBorder = mainStyle.getPropertyValue(
    //         "--slide-spreadsheet-divider"
    //     );
    //     let defaultDivider = defaultLineBorder.split(" ");
    //     defaultDivider[
    //         defaultDivider.length - 1
    //     ] = this.props.colorOptions.borderColor;
    //     defaultLineBorder = defaultDivider.join(" ");
    //     let rows: JSX.Element[] = [];
    //     for (let i = index; i < index + 1; ++i) {
    //         rows.push(
    //             <SpreadSheetElementRow
    //                 key={`row_content_${i}`}
    //                 canvasTreeStore={this.props.canvasTreeStore}
    //                 rowIndex={i}
    //                 showOptions={showOptions}
    //                 isSimpleSpreadSheet={isSimpleSpreadSheet}
    //                 currentSimpleEditParentItem={
    //                     this.currentSimpleEditParentItem
    //                 }
    //                 autoFillArea={this.state.autoFillArea}
    //                 defaultSize={defaultSize}
    //                 defaultLineBorder={defaultLineBorder}
    //                 hovered={this.props.hovered}
    //                 currentModuleId={this.props.currentModuleId}
    //                 colorOptions={this.props.colorOptions}
    //                 selectionArea={this.props.selectionArea}
    //                 onSelectArea={this.props.onSelectArea}
    //                 moduleTitle={this.props.moduleTitle}
    //                 currentSimpleEdit={this.props.currentSimpleEdit}
    //                 scale={this.props.scale}
    //                 spreadSheetGrid={this.props.spreadSheetGrid}
    //                 nodes={this.props.nodes}
    //                 editedNode={this.props.editedNode}
    //                 live={this.props.live}
    //                 canWrite={
    //                     this.props.canWrite &&
    //                     !this.state.syncingIsActive &&
    //                     !this.state.initPullingIsActive
    //                 }
    //                 onChangeEditedNode={this.props.onChangeEditedNode}
    //                 onChangeEditedHeader={this.props.onChangeEditedHeader}
    //                 onStartEditHeader={this.props.onStartEditHeader}
    //                 onSimpleEditInsertOuterId={
    //                     this.props.onSimpleEditInsertOuterId
    //                 }
    //                 onStartAdvancedEditing={this.props.onStartAdvancedEditing}
    //                 editedGridHeader={this.props.editedGridHeader}
    //                 onClearEditing={this.props.onClearEditing}
    //                 onKeepSimpleEditChanges={this.props.onKeepSimpleEditChanges}
    //                 onStartEditNode={this.props.onStartEditNode}
    //                 onNodeClick={this.props.onNodeClick}
    //                 onHeaderContextMenu={this.props.onHeaderContextMenu}
    //                 onItemContextMenu={this.props.onItemContextMenu}
    //                 onHideContextMenu={this.props.onHideContextMenu}
    //                 onChange={this.props.onChange}
    //                 onSort={this.props.onSort}
    //                 onResize={this.props.onResize}
    //                 onApplyFormat={this.props.onApplyFormat}
    //                 onGenerateLocation={this.generateLocation}
    //                 setSpreadSheetState={this.setSpreadSheetState}
    //             />
    //         );
    //     }
    //     return (
    //         <div
    //             style={{
    //                 ...gridStyle,
    //                 ...args.style,
    //             }}
    //         >
    //             {rows}
    //         </div>
    //     );
    // }

    private renderHeaderCell(args: {
        rowIndex: number;
        columnIndex: number;
        style: CSSProperties;
    }) {
        return this.renderCell({
            ...args,
            rowIndex: args.rowIndex - 1,
            isHeader: true,
        });
    }

    private renderCell(args: {
        rowIndex: number;
        columnIndex: number;
        style: CSSProperties;
        isHeader?: boolean;
    }): JSX.Element {
        let colIndex = args.columnIndex;
        let rowIndex = args.rowIndex;
        if (this.props.spreadSheetGrid.leftHeadersEnabled) {
            colIndex -= 1;
        }
        let isSimpleSpreadSheet =
            this.props.spreadSheetGrid.type ===
            CanvasGridType.SimpleSpreadSheet;
        let showOptions = !this.props.live;
        const gridStyle: CSSProperties = {
            gridRowGap: 0,
            gridColumnGap: 0,
            gridTemplateColumns: `repeat(${
                this.props.spreadSheetGrid.cols +
                (this.props.spreadSheetGrid.leftHeadersEnabled ? 1 : 0) +
                +(showOptions ? 1 : 0)
            }, max-content)`,
            display: "grid",
        };
        let defaultSize = getDefaultSize(this.props.spreadSheetGrid);
        let defaultLineBorder = mainStyle.getPropertyValue(
            "--slide-spreadsheet-divider"
        );
        let defaultDivider = defaultLineBorder.split(" ");
        defaultDivider[defaultDivider.length - 1] =
            this.props.colorOptions.borderColor;
        defaultLineBorder = defaultDivider.join(" ");
        return (
            <div
                style={{
                    ...gridStyle,
                    ...args.style,
                }}
            >
                <SpreadSheetElementCell
                    rootDataTestId={`${this.props.rootDataTestId}-cell-${
                        rowIndex + 1
                    }-${colIndex + 1}`}
                    cellHoverId={this.state.cellHoverId}
                    setCellHoverId={(cellId: number | undefined) => {
                        this.setState({
                            cellHoverId: cellId,
                        })
                    }}
                    openedFormatDropdownCol={this.state.openedFormatDropdownCol}
                    setOpenedFormatDropdownCol={(col) => {
                        this.setState({
                            openedFormatDropdownCol: col,
                        })
                    }}
                    enableMultiSelection={this.enableMultiSelection}
                    mouseMultiSelection={this.state.mouseMultiSelection}
                    setMouseMultiSelection={(selecting: MouseMultiSelection) => {
                        this.setState({
                            mouseMultiSelection: {
                                ...this.state.mouseMultiSelection,
                                ...selecting,
                            }
                        })
                    }}
                    key={`cell_content_${rowIndex}_${colIndex}`}
                    canvasTreeStore={this.props.canvasTreeStore}
                    rowIndex={rowIndex}
                    moveSelection={this.props.moveSelection}
                    colIndex={colIndex}
                    showOptions={showOptions}
                    isSimpleSpreadSheet={isSimpleSpreadSheet}
                    currentSimpleEditParentItem={
                        this.currentSimpleEditParentItem
                    }
                    autoFillArea={this.state.autoFillArea}
                    defaultSize={defaultSize}
                    defaultLineBorder={defaultLineBorder}
                    hovered={this.props.hovered}
                    currentModuleId={this.props.currentModuleId}
                    colorOptions={this.props.colorOptions}
                    selectionArea={this.props.selectionArea}
                    onSelectArea={this.props.onSelectArea}
                    moduleTitle={this.props.moduleTitle}
                    currentSimpleEdit={this.props.currentSimpleEdit}
                    scale={this.props.scale}
                    spreadSheetGrid={this.props.spreadSheetGrid}
                    nodes={this.props.nodes}
                    editedNode={this.props.editedNode}
                    live={this.props.live}
                    canWrite={
                        this.props.canWrite &&
                        !this.state.syncingIsActive &&
                        !this.state.initPullingIsActive
                    }
                    onChangeEditedNode={this.props.onChangeEditedNode}
                    onChangeEditedHeader={this.props.onChangeEditedHeader}
                    onStartEditHeader={this.props.onStartEditHeader}
                    onSimpleEditInsertOuterId={
                        this.props.onSimpleEditInsertOuterId
                    }
                    onStartAdvancedEditing={this.props.onStartAdvancedEditing}
                    editedGridHeader={this.props.editedGridHeader}
                    onClearEditing={this.props.onClearEditing}
                    onKeepSimpleEditChanges={this.props.onKeepSimpleEditChanges}
                    onStartEditNode={this.props.onStartEditNode}
                    onNodeClick={this.props.onNodeClick}
                    onHeaderContextMenu={this.props.onHeaderContextMenu}
                    onItemContextMenu={this.props.onItemContextMenu}
                    onHideContextMenu={this.props.onHideContextMenu}
                    onChange={this.props.onChange}
                    onSort={this.props.onSort}
                    onResize={this.props.onResize}
                    onApplyFormat={this.props.onApplyFormat}
                    onGenerateLocation={this.generateLocation}
                    setSpreadSheetState={this.setSpreadSheetState}
                    resizeHeader={this.state.resizeHeader}
                    gridHeaderRef={this.gridHeaderRef}
                    gridRef={this.gridRef}
                />
            </div>
        );
    }

    private enableMultiSelection(enable: boolean) {
        this.setState({
            mouseMultiSelection: {
                ...this.state.mouseMultiSelection,
                started: enable,
            },
        })
    }

    public render(): JSX.Element {
        let defaultSize = getDefaultSize(this.props.spreadSheetGrid);
        const rowCount =
            this.props.spreadSheetGrid.rows +
            (this.props.canWrite && !this.props.live ? 1 : 0);
        const colCount =
            this.props.spreadSheetGrid.cols +
            (this.props.spreadSheetGrid.leftHeadersEnabled ? 1 : 0) +
            (this.props.canWrite && !this.props.live ? 1 : 0);
        let streamingOff =
            this.props.spreadSheetGrid.fullSpreadSheetBackendOutputOptions
                ?.dataScopeId == null;
        let dataScopeOption = undefined;
        if (!streamingOff) {
            let dataScopes =
                this.props.currentModuleId == null
                    ? DataScopes
                    : DataScopesForModules(this.props.currentModuleId);
            dataScopeOption =
                dataScopes.dataScopesOptions.find(
                    (option) =>
                        option.value ===
                        this.props.spreadSheetGrid
                            .fullSpreadSheetBackendOutputOptions?.dataScopeId
                ) ?? null;
        }
        const { canvasViewMode } = this.props.canvasTreeStore
        return (
            <OutsideAlerter
                onReject={() => {
                    this.setState({
                        cellHoverId: undefined,
                    })
                    this.enableMultiSelection(false);
                }}
                listenMouseUp={true}
            >
                <div
                    className="dashboard-rect-spreadsheet flex-simple-column"
                    style={{
                        // Background color is already set for an
                        // element above, do not set it here, or
                        // transparent colors would look odd
                        backgroundColor: "transparent",
                        justifyContent: this.props.live ? "center" : "normal",
                        width: "100%",
                    }}
                    onContextMenu={this.renderDivOnContextMenu}
                    onClick={this.renderDivOnClick}
                    onKeyDown={this.onKeyDown}
                >
                    {this.buildAdvancedOptions(dataScopeOption)}
                    <div>
                        <div
                            style={{
                                paddingTop: 10,
                                paddingLeft: 20,
                            }}
                        >
                            {/*<List
                                overscanCount={10}
                                width={
                                    (this.props.spreadSheetGrid.containerSize
                                        ?.width ?? 0) *
                                        this.props.scale -
                                    25 // padding
                                }
                                height={
                                    (this.props.spreadSheetGrid.containerSize
                                        ?.height ?? 0) *
                                        this.props.scale -
                                    15 - // padding
                                    this.getHeaderHeight()
                                }
                                itemSize={(index) => {
                                    index = index ?? 0;
                                    if (this.props.spreadSheetGrid.headersEnabled) {
                                        index -= 1;
                                    }
                                    if (index === -1) {
                                        return (
                                            this.props.scale *
                                            defaultSize.height *
                                            (this.props.spreadSheetGrid
                                                .titleRowScale ?? 1)
                                        );
                                    }
                                    return (
                                        this.props.scale *
                                        defaultSize.height *
                                        (this.props.spreadSheetGrid.rowScales?.[
                                            index
                                        ] ?? 1)
                                    );
                                }}
                                itemCount={rowCount}
                            >
                                {this.sliceRows}
                            </List>*/}

                            {this.props.spreadSheetGrid.headersEnabled && (
                                <Grid
                                    ref={this.gridHeaderRef}
                                    style={{ overflow: "hidden" }}
                                    overscanRowCount={0}
                                    overscanColumnCount={5}
                                    width={
                                        (this.props.spreadSheetGrid.containerSize ? this.props.spreadSheetGrid.containerSize[canvasViewMode]
                                            .width : 0) *
                                            this.props.scale -
                                        20 // padding
                                    }
                                    height={this.getSpreadSheetHeaderHeight()}
                                    rowHeight={(_index) => {
                                        return this.getSpreadSheetHeaderHeight();
                                    }}
                                    columnWidth={(index) => {
                                        const additionalGap = this.props.live ? 0 : defaultNumberLetterTitleSize
                                        if (
                                            this.props.spreadSheetGrid
                                                .leftHeadersEnabled
                                        ) {
                                            index -= 1;
                                            if (index === -1) {
                                                return (
                                                    this.props.scale *
                                                    (defaultSize.width *
                                                        (this.props.spreadSheetGrid
                                                            .leftTitleColumnScale ??
                                                            1) +
                                                        additionalGap)
                                                );
                                            }
                                        } else if (index === 0) {
                                            return (
                                                this.props.scale *
                                                (defaultSize.width *
                                                    (this.props.spreadSheetGrid
                                                        .columnScales?.[index] ??
                                                        1) +
                                                    additionalGap)
                                            );
                                        }
                                        return (
                                            this.props.scale *
                                            defaultSize.width *
                                            (this.props.spreadSheetGrid
                                                .columnScales?.[index] ?? 1)
                                        );
                                    }}
                                    rowCount={1}
                                    columnCount={colCount}
                                >
                                    {this.renderHeaderCell}
                                </Grid>
                            )}
                            <Grid
                                ref={this.gridRef}
                                overscanRowCount={5}
                                overscanColumnCount={5}
                                width={
                                    (this.props.spreadSheetGrid.containerSize ? this.props.spreadSheetGrid.containerSize[canvasViewMode]
                                        .width : 0) *
                                        this.props.scale -
                                    20 // padding
                                }
                                onScroll={(props) => {
                                    if (this.gridHeaderRef.current)
                                        this.gridHeaderRef.current.scrollTo({
                                            scrollLeft: props.scrollLeft,
                                        });
                                }}
                                height={
                                    (this.props.spreadSheetGrid.containerSize ? this.props.spreadSheetGrid.containerSize[canvasViewMode]
                                        .height : 0) *
                                        this.props.scale -
                                    10 - // padding
                                    // this.getHeaderHeight() -
                                    this.getAdvancedOptionHeight() -
                                    this.getSpreadSheetHeaderHeight()
                                }
                                rowHeight={(index) => {
                                    return (
                                        this.props.scale *
                                        defaultSize.height *
                                        (this.props.spreadSheetGrid.rowScales?.[
                                            index
                                        ] ?? 1)
                                    );
                                }}
                                columnWidth={(index) => {
                                    const additionalGap = this.props.live ? 0 : defaultNumberLetterTitleSize
                                    if (
                                        this.props.spreadSheetGrid
                                            .leftHeadersEnabled
                                    ) {
                                        index -= 1;
                                        if (index === -1) {
                                            return (
                                                this.props.scale *
                                                (defaultSize.width *
                                                    (this.props.spreadSheetGrid
                                                        .leftTitleColumnScale ??
                                                        1) +
                                                    additionalGap)
                                            );
                                        }
                                    } else if (index === 0) {
                                        return (
                                            this.props.scale *
                                            (defaultSize.width *
                                                (this.props.spreadSheetGrid
                                                    .columnScales?.[index] ?? 1) +
                                                additionalGap)
                                        );
                                    }
                                    return (
                                        this.props.scale *
                                        defaultSize.width *
                                        (this.props.spreadSheetGrid.columnScales?.[
                                            index
                                        ] ?? 1)
                                    );
                                }}
                                rowCount={rowCount}
                                columnCount={colCount}
                            >
                                {this.renderCell}
                            </Grid>
                        </div>
                    </div>
                    {this.state.streamStatus != null && (
                        <Portal rootNode={document.body}>
                            <StatusPopup
                                status={this.state.streamStatus}
                                message={this.state.streamMessage!}
                                onClose={() => {
                                    this.setState({
                                        streamStatus: null,
                                        streamMessage: null,
                                    });
                                }}
                            />
                        </Portal>
                    )}
                </div>
            </OutsideAlerter>
        );
    }
}

export default SpreadSheetElement;
