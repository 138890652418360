import React from "react";
import ScatterExplorationChart from "common/graphics/ScatterExplorationChart";
import PointsSelector from "common/graphics/PointsSelector";

function MainComponent(props) {
	let finding = props.finding;
	let onNewFinding = props.onNewFinding;
	return (
		<div style={{ width: "100%", height: "100%" }}>
			{(finding.config.addLineOrDot ?? true) && onNewFinding && (
				<PointsSelector finding={finding} onNewFinding={onNewFinding} />
			)}
			<ScatterExplorationChart
				config={finding.config}
				data={finding.content.data}
				type={finding.content.type}
				label={finding.content.label}
				hoverVariables={finding.content.hoverVariables}
				variables={finding.content.variables}
				units={finding.content.units}
				additionalData={finding.content.additionalData}
			/>
		</div>
	);
}

export { MainComponent };