import React, { Component } from "react";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { mainStyle } from "common/MainStyle";
import IconContainer from "common/IconContainer";
import { HorizontalBarsAxisTick } from "common/graphics/AxesTicks";
import sections from "sections.json";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import { TooltipStyles } from "./TooltipStyles";

class RegressionVariablesBarChart extends Component {
    createStarsScale = ({ index, payload, x, y, width, height, value }) => {
        if (
            !isNaN(this.props.data[index].significance1) &&
            !isNaN(x) &&
            !isNaN(y) &&
            !isNaN(width)
        ) {
            let sig = 100 - this.props.data[index].significance1 * 100;
            if (sig <= 100 && sig >= 99) {
                sig = 3;
            } else if (sig < 99 && sig >= 95) {
                sig = 2;
            } else if (sig < 95 && sig >= 90) {
                sig = 1;
            } else {
                sig = 0;
            }
            let stars = [];
            while (sig >= 1) {
                stars.push(
                    <svg
                        key={index + "-" + sig}
                        x={
                            value > 0
                                ? x + width - (sig + 1) * 20
                                : x + 7 + width + sig * 20
                        }
                        y={y + 2}
                        style={{
                            fill: mainStyle.getPropertyValue(
                                "--graphs-legends-text-color"
                            ),
                        }}
                        width="10"
                        height="10"
                        viewBox="0 0 24 24"
                    >
                        <IconContainer type="Star" />
                    </svg>
                );
                sig -= 1;
            }
            return <>{stars}</>;
        } else {
            return <span>&nbsp;</span>;
        }
    };

    render() {
        var titleSize = "12px";
        let maxYaxisWidth = 60;
        if (this.props.data) {
            let lengths = this.props.data.map((item) => item.name.length);
            maxYaxisWidth = Math.max(maxYaxisWidth, Math.max(...lengths) * 10);
        }
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <span
                    style={{
                        fontFamily: "Arial",
                        fontSize: titleSize,
                        color: mainStyle.getPropertyValue(
                            "--secondary-text-color"
                        ),
                        display: "block",
                        textAlign: "center",
                    }}
                >
                    {this.props.title}
                </span>
                <ResponsiveContainer
                    height={
                        mobileBreakpoint()
                            ? 100 * this.props.data.length
                            : undefined
                    }
                >
                    <BarChart
                        barCategoryGap={mobileBreakpoint ? "30%" : undefined}
                        data={this.props.data}
                        barSize={15}
                        layout="vertical"
                        margin={{
                            top: 5,
                            bottom: 5,
                        }}
                    >
                        {sections._Global.watermark != null && (
                            <text
                                x="50%"
                                y="150"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={"4rem"}
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {sections._Global.watermark}
                            </text>
                        )}
                        <XAxis
                            orientation="top"
                            type="number"
                            tickLine={false}
                            axisLine={false}
                            tick={<HorizontalBarsAxisTick dx={0} dy={0} />}
                        />
                        <YAxis
                            type="category"
                            width={maxYaxisWidth}
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            tick={<HorizontalBarsAxisTick dx={0} dy={-5} />}
                        />
                        <CartesianGrid
                            stroke={mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )}
                            horizontal={false}
                            strokeWidth={1}
                        />
                        <Tooltip
                            formatter={(value, name, props) => {
                                let formattedValue = value.toFixed(3);
                                return formattedValue;
                            }}
                            cursor={false}
                            {...TooltipStyles()}
                        />
                        <Bar
                            dataKey="coefficient1"
                            label={this.createStarsScale}
                        >
                            {this.props.data.map((entry, index) => {
                                var color =
                                    entry.color1 ?? this.props.colorItems[0][1];

                                return (
                                    <Cell
                                        onClick={() => {
                                            if (
                                                this.props
                                                    .onRegressionBarsChanged !=
                                                null
                                            ) {
                                                let newData = Array.from(
                                                    this.props.data
                                                );
                                                let newIndex =
                                                    (this.props.colorItems[0].indexOf(
                                                        color
                                                    ) +
                                                        1) %
                                                    this.props.colorItems[0]
                                                        .length;
                                                newData[
                                                    index
                                                ].color1 = this.props.colorItems[0][
                                                    newIndex
                                                ];
                                                this.props.onRegressionBarsChanged(
                                                    newData
                                                );
                                            }
                                        }}
                                        key={`cell-${index}`}
                                        fill={color}
                                    />
                                );
                            })}
                        </Bar>
                        <Bar
                            dataKey="coefficient2"
                            label={this.createStarsScale}
                        >
                            {this.props.data.map((entry, index) => {
                                var color =
                                    entry.color2 ?? this.props.colorItems[1][1];

                                return (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={color}
                                        onClick={() => {
                                            if (
                                                this.props
                                                    .onRegressionBarsChanged !=
                                                null
                                            ) {
                                                let newData = Array.from(
                                                    this.props.data
                                                );
                                                let newIndex =
                                                    (this.props.colorItems[1].indexOf(
                                                        color
                                                    ) +
                                                        1) %
                                                    this.props.colorItems[1]
                                                        .length;
                                                newData[
                                                    index
                                                ].color2 = this.props.colorItems[1][
                                                    newIndex
                                                ];
                                                this.props.onRegressionBarsChanged(
                                                    newData
                                                );
                                            }
                                        }}
                                    />
                                );
                            })}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default RegressionVariablesBarChart;
