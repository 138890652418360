import React, { useEffect, useContext, useState, useMemo } from "react";
import { observer } from "mobx-react";

import Button from "common/Button";
import PresentationCard from "common/home_components/PresentationCard";
import { goToInternalLink } from "common/InternalLinksHelper";
import StoreContext from "state/StoreContext";
import CreateNewButton from "../CreateNewButton";
import styles from "./UserOnboarding.module.css";
import GlobalContext from "GlobalContext";
import { PresentationType } from "state/models/Presentation";

interface Props {
    onClickTemplate: (id: number) => void;
    onDeleteTemplate: (id: number) => void;
}

function UserOnboarding(props: Props) {
    const globalContext = useContext(GlobalContext);
    const store = useContext(StoreContext);
    const viewAll = useState(false)[0];
    async function createPresentation() {
        const presentationId = await store.presentationStore.createBlankPresentation();
        goToInternalLink(`canvas.html?current_module_id=${presentationId}`);
    }

    // function onClickViewAll() {
    // 	setViewAll(true);
    // }

    useEffect(() => {
        if (!store.presentationStore.createNewTemplates.length) {
            store.presentationStore.fetchTemplates();
        }
    }, [store.presentationStore]);

    const visibleCards = useMemo(
        () =>
            viewAll
                ? store.presentationStore.createNewTemplates
                : store.presentationStore.createNewTemplates.slice(0, 3),
        [viewAll, store.presentationStore.createNewTemplates]
    );

    return (
        <div className={styles.root}>
            <div className={styles.innerContainer}>
                <div>
                    <h3 className={styles.title}>Welcome to Eisengard</h3>
                    <p className={styles.description}>
                        Get started with one of our templates or
                    </p>
                    <div>
                        <CreateNewButton
                            onClickNew={createPresentation}
                            className={styles.createButton}
                        />

                        <Button variant="text" onClick={() => {}}>
                            Don't show again
                        </Button>
                    </div>
                </div>
                <div className={styles.cardsContainer}>
                    {visibleCards?.map((template) => (
                        <PresentationCard
                            inOnboarding
                            type={template.type}
                            key={template.id}
                            id={template.id}
                            title={template.title}
                            lastUpdatedAt={template.lastUpdated}
                            thumbnail={template.thumbnail}
                            owner={template.owner}
                            onClickCard={props.onClickTemplate}
                            onClickDelete={
                                template.isShared &&
                                !(
                                    template.type ===
                                        PresentationType.Template &&
                                    "ModifyAnyModule" in
                                        globalContext.permissions
                                )
                                    ? undefined
                                    : props.onDeleteTemplate
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default observer(UserOnboarding);
