import React, { Component } from "react";
import Switch from "react-switch";
import { mainStyle } from "common/MainStyle";
import { Button } from "react-bootstrap";
import {
    ColumnFormatType,
    columnFormatOptions,
    ColumnFormatOption,
    ColumnFormat,
    isNumberFormat,
    isGeographyFormat,
    isListFormat,
    isDateFormat,
    NumberFormat,
    ListFormat,
    DateFormat,
    GeographyFormat,
    NumberFormatType,
    numberFormatOptions,
    NumberFormatOption,
} from "common/Canvas";
import {
    geographyLevelsOptions,
    geographyLevelsSelectOptions,
} from "common/InputData";
import Select, { createFilter } from "react-select";
import { getSmallSelectStyle, leftAlignAndScrollBarCSS } from "common/SelectStyles";
import StringUtils from "common/utilities/StringUtils";
import DateTimeFormatSelect, {
    OptionType as DateTimeOptionType,
} from "common/DateTimeFormatSelect";
import TextareaAutosize from "react-autosize-textarea";
import StringOption from "common/StringOption";

interface Props {
    rootDataTestId?: string;
    allowLocationGeneration?: boolean;
    onGenerateLocation?: () => void;
    columnFormat: ColumnFormat | undefined;
    options?: ReadonlyArray<ColumnFormatOption>;
    onClose: (apply: boolean, options?: ColumnFormat) => void;
}
interface State {
    columnFormat: ColumnFormat | undefined;
    formatOptions: DateTimeOptionType[];
}

class SpreadSheetFormatOptionsPopup extends Component<Props, State> {
    drag: boolean = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            columnFormat: this.props.columnFormat,
            formatOptions: DateTimeFormatSelect.defaultOptions(),
        };
    }

    private buildInnerView(): JSX.Element {
        let fontSize = 10;
        let customSelectStylesSmall = getSmallSelectStyle(fontSize);
        let textStyle = {
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: 14,
            // fontSize: fontSize,
            // color: mainStyle.getPropertyValue("--popup-primary-text-color"),
            color: "#7d7d7d",
            marginBottom:5,
        };
        let buttonStyle = {
            width: "102px",
            height: "17px",
            fontSize: fontSize,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };
        return (
            <div
                data-test-id={
                    this.props.rootDataTestId
                        ? `${this.props.rootDataTestId}-columnTypeSelector`
                        : undefined
                }
                prevent-mouse-leave="true"
                className="flex-simple-column"
                style={{
                    paddingTop: 11,
                    paddingLeft: 11,
                    paddingRight: 11,
                    paddingBottom: 3,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    // className="my-row"
                    style={{
                        marginTop: "10px",
                        alignItems: "center",
                        alignSelf: "flex-start",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                    data-test-id={
                        this.props.rootDataTestId
                            ? `${this.props.rootDataTestId}-geographyLevelSelector`
                            : undefined
                    }
                >
                    <div style={{
                        ...textStyle,
                        fontSize: 14
                    }}>{"Type"}</div>

                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={"Column type"}
                        styles={{
                            ...customSelectStylesSmall,
                            ...leftAlignAndScrollBarCSS,
                            container: (base) => ({
                                ...base,
                                width: "100%",
                            }),
                            singleValue: (base) => ({
                                ...base,
                                fontWeight: 400,
                                fontSize: 14,
                                padding: '2px 8px',
                            }),
                            option: (base, state) => ({
                                ...base,
                                fontWeight: 400,
                                fontSize: 14,
                                color: '#000',
                                backgroundColor: state.isSelected ? "var(--greyColor)" : "#fff",
                                "&:hover": {
                                    backgroundColor: "var(--greyColor)"
                                }
                            }),
                            input: (base) => ({
                                ...base,
                                fontSize: 14,
                                paddingLeft: 9
                            })
                        }}
                        options={this.props.options ?? columnFormatOptions}
                        onChange={(newValue) => {
                            let type = (newValue as ColumnFormatOption).value;
                            switch (type) {
                                case ColumnFormatType.Enum: {
                                    let columnFormat: ListFormat = {
                                        type: type,
                                        allowMultipleSelection: false,
                                        options: [],
                                    };
                                    this.setState({
                                        columnFormat: columnFormat,
                                    });
                                    break;
                                }
                                case ColumnFormatType.Number: {
                                    let columnFormat: NumberFormat = {
                                        type: type,
                                        decimalPoints: 2,
                                        useCommaSeparator: true,
                                        useAbbreviation: false,
                                        numberType: NumberFormatType.Number,
                                    };
                                    this.setState({
                                        columnFormat: columnFormat,
                                    });
                                    break;
                                }
                                case ColumnFormatType.Text:
                                case ColumnFormatType.Month:
                                    {
                                        let columnFormat: ColumnFormat = {
                                            type: type,
                                        };
                                        this.setState({
                                            columnFormat: columnFormat,
                                        });
                                    }
                                    break;
                                case ColumnFormatType.Date:
                                    {
                                        let columnFormat: DateFormat = {
                                            type: type,
                                            format: this.state.formatOptions[0]
                                                .value,
                                        };
                                        this.setState({
                                            columnFormat: columnFormat,
                                        });
                                    }

                                    break;
                                case ColumnFormatType.Geography:
                                    {
                                        let columnFormat: GeographyFormat = {
                                            type: type,
                                            level: geographyLevelsOptions[0],
                                        };
                                        this.setState({
                                            columnFormat: columnFormat,
                                        });
                                    }
                                    break;
                                default:
                                    // code...
                                    break;
                            }
                        }}
                        value={
                            this.state.columnFormat != null
                                ? columnFormatOptions.find(
                                    (item) =>
                                        item.value ===
                                        this.state.columnFormat!.type
                                )
                                : null
                        }
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
                {
                    this.state.columnFormat &&
                    isListFormat(this.state.columnFormat) && (
                        <>
                            <span
                                style={{
                                    ...textStyle,
                                    alignSelf: "flex-start",
                                    marginTop: "13px",
                                }}
                            >
                                {"Selectable items"}
                            </span>
                            <TextareaAutosize
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-selectableItemsTextArea`
                                        : undefined
                                }
                                className="like-select"
                                style={{
                                    ...textStyle,
                                    fontWeight: 400,
                                    paddingTop: "11px",
                                    width: "107px",
                                    marginTop: "5px",
                                    minHeight: "51px",
                                    resize: "none",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    fontSize: "10px",
                                }}
                                rows={
                                    this.state.columnFormat.options.length + 1
                                }
                                placeholder="Enter options, delimited by comma"
                                onChange={(e) => {
                                    let value = e.currentTarget.value;
                                    this.setState((state) => ({
                                        columnFormat: {
                                            ...(state.columnFormat as ListFormat),
                                            options: value
                                                .split(",")
                                                .map((item) => item.trim())
                                                .filter((item) => item),
                                        },
                                    }));
                                }}
                                defaultValue={(this.state
                                    .columnFormat as ListFormat).options.join(
                                        ",\n"
                                    )}
                                onKeyUp={(e) => {
                                    let key = e.key;
                                    if (key === ",") {
                                        let last =
                                            e.currentTarget.selectionStart ===
                                            e.currentTarget.selectionEnd &&
                                            e.currentTarget.selectionStart ===
                                            e.currentTarget.value.length;
                                        if (last) {
                                            e.currentTarget.value =
                                                e.currentTarget.value + "\n";
                                        }
                                    }
                                }}
                            />
                            <div
                                className="my-row"
                                style={{
                                    marginTop: "5px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-allowMultipleSelectionSwitch`
                                        : undefined
                                }
                            >
                                <span style={textStyle}>
                                    {"Allow multiple selection"}
                                </span>
                                <div style={{ marginLeft: 5 }}>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as ListFormat),
                                                    allowMultipleSelection: checked,
                                                },
                                            }));
                                        }}
                                        checked={
                                            (this.state
                                                .columnFormat as ListFormat)
                                                .allowMultipleSelection
                                        }
                                        width={16}
                                        height={7}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    this.state.columnFormat &&
                    isNumberFormat(this.state.columnFormat) && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div
                                    // className="my-row"
                                    style={{
                                        marginTop: "10px",
                                        alignItems: "center",
                                        alignSelf: "flex-start",
                                        justifyContent: "space-between",
                                        width: "60%",
                                    }}
                                    data-test-id={
                                        this.props.rootDataTestId
                                            ? `${this.props.rootDataTestId}-numberFormatSelector`
                                            : undefined
                                    }
                                >
                                    <div style={{
                                        ...textStyle,
                                        fontSize: 14
                                    }}>{"Format"}</div>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={"Number format"}
                                        styles={{
                                            ...customSelectStylesSmall,
                                            container: (base) => ({
                                                ...base,
                                                width: "100%",
                                                // marginLeft: 13
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                fontWeight: 400,
                                                fontSize: 14,
                                                padding: '2px 8px'
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: '#000',
                                                backgroundColor: state.isSelected ? "var(--greyColor)" : "#fff",
                                                "&:hover": {
                                                    backgroundColor: "var(--greyColor)"
                                                }
                                            }),
                                            input: (base) => ({
                                                ...base,
                                                fontSize: 14,
                                                paddingLeft: 9
                                            })
                                        }}
                                        options={numberFormatOptions}
                                        onChange={(newValue) => {
                                            let type = (newValue as NumberFormatOption)
                                                .value;
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as NumberFormat),
                                                    numberType: type,
                                                },
                                            }));
                                        }}
                                        value={numberFormatOptions.find(
                                            (option) =>
                                                option.value ===
                                                (this.state
                                                    .columnFormat as NumberFormat)
                                                    .numberType
                                        )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                                <div
                                    // className="my-row"
                                    style={{
                                        marginTop: "10px",
                                        alignItems: "center",
                                        alignSelf: "flex-start",
                                        justifyContent: "space-between",
                                        width: "40%",
                                        marginLeft: 10
                                    }}
                                >
                                    <div style={{
                                        ...textStyle,
                                        marginRight: 5,
                                        textAlign: 'center',
                                        fontSize: 14,
                                    }}>{"Decimals"}</div>
                                    <input
                                        data-test-id={
                                            this.props.rootDataTestId
                                                ? `${this.props.rootDataTestId}-decimalsInput`
                                                : undefined
                                        }
                                        className="like-select"
                                        style={{
                                            ...textStyle,
                                            fontWeight: 400,
                                            minHeight: "18px",
                                            width: "100%",
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                            fontFamily: "monospace",
                                            textAlign: "center",
                                            height: 34
                                        }}
                                        placeholder="Decimal places"
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            let decimalPoints: number = 0;
                                            if (StringUtils.isNumber(value)) {
                                                decimalPoints = Math.min(
                                                    Math.max(
                                                        0,
                                                        Math.trunc(Number(value))
                                                    ),
                                                    20
                                                );
                                            }
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as NumberFormat),
                                                    decimalPoints: decimalPoints,
                                                },
                                            }));
                                        }}
                                        defaultValue={String(
                                            (this.state
                                                .columnFormat as NumberFormat)
                                                .decimalPoints
                                        )}
                                    />
                                </div>
                            </div>

                            <div
                                className="my-row"
                                style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    // justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-useAbbreviationSwitch`
                                        : undefined
                                }
                            >
                                <span>
                                    <input
                                        type={"checkbox"}
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as NumberFormat),
                                                    useAbbreviation: checked,
                                                },
                                            }));
                                        }}
                                    />
                                </span>
                                <span
                                    style={{
                                        ...textStyle,
                                        fontSize: "12px !important",
                                        marginLeft: 8,
                                        color: '#333'
                                    }}
                                    id={"textstyleforContextMenu"}
                                >
                                    {"Use Abbreviation"}
                                </span>
                                {/* <div style={{ marginLeft: 5 }}>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as NumberFormat),
                                                    useAbbreviation: checked,
                                                },
                                            }));
                                        }}
                                        checked={
                                            (this.state
                                                .columnFormat as NumberFormat)
                                                .useAbbreviation ?? false
                                        }
                                        width={20}
                                        height={10}
                                        offColor="#99999"
                                        onColor="#3b82c9"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#fff"
                                        onHandleColor="#fff"
                                        borderRadius={20}
                                        handleDiameter={8}
                                    />
                                </div> */}
                            </div>
                            <div
                                className="my-row"
                                style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    // justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-useThousandSeparatorSwitch`
                                        : undefined
                                }
                            >
                                <span>
                                    <input
                                        type={"checkbox"}
                                        // checked={
                                        //     (this.state
                                        //         .columnFormat as NumberFormat)
                                        //         .useCommaSeparator ?? true
                                        // }
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as NumberFormat),
                                                    useCommaSeparator: checked,
                                                },
                                            }));
                                        }}
                                    />
                                </span>
                                <span
                                    style={{
                                        ...textStyle,
                                        marginLeft: 8,
                                        fontSize: 12,
                                        color: '#333',
                                    }}
                                    id={"textstyleforContextMenu"}
                                >
                                    <p style={{ margin: 0 }}>Use 1000 separator(,)</p>
                                </span>
                                <div style={{ marginLeft: 5 }}>
                                    {/* <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as NumberFormat),
                                                    useCommaSeparator: checked,
                                                },
                                            }));
                                        }}
                                        checked={
                                            (this.state
                                                .columnFormat as NumberFormat)
                                                .useCommaSeparator ?? true
                                        }
                                        width={20}
                                        height={10}
                                        offColor="#99999"
                                        onColor="#3b82c9"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#fff"
                                        onHandleColor="#fff"
                                        borderRadius={20}
                                        handleDiameter={8}
                                    /> */}
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    this.state.columnFormat &&
                    isGeographyFormat(this.state.columnFormat) && (
                        <>
                            <div
                                // className="my-row"
                                style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-geographyLevelSelector`
                                        : undefined
                                }
                            >
                                <div style={{
                                    ...textStyle,
                                    marginRight: 5
                                }}>{"Level"}</div>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Geography level"}
                                    styles={{
                                        ...customSelectStylesSmall,
                                        container: (base) => ({
                                            ...base,
                                            width: "100%",
                                        }),
                                        singleValue: (base) => ({
                                            ...base,
                                            fontWeight: 400,
                                            fontSize: 14,
                                            padding: '2px 8px'
                                        }),
                                        option: (base, state) => ({
                                            ...base,
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: '#000',
                                            backgroundColor: state.isSelected ? "var(--greyColor)" : "#fff",
                                            "&:hover": {
                                                backgroundColor: "var(--greyColor)"
                                            }
                                        }),
                                        input: (base) => ({
                                            ...base,
                                            fontSize: 14,
                                            paddingLeft: 9
                                        })
                                    }}
                                    options={geographyLevelsSelectOptions}
                                    onChange={(newValue) => {
                                        let level = (newValue as StringOption)
                                            .value;
                                        this.setState((state) => ({
                                            columnFormat: {
                                                ...(state.columnFormat as GeographyFormat),
                                                level: level,
                                            },
                                        }));
                                    }}
                                    value={geographyLevelsSelectOptions.find(
                                        (option) =>
                                            option.value ===
                                            (this.state
                                                .columnFormat as GeographyFormat)
                                                .level
                                    )}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                        </>
                    )
                }
                {
                    this.state.columnFormat &&
                    isDateFormat(this.state.columnFormat) && (
                        <>
                            <div
                                style={{
                                    ...textStyle,
                                    alignSelf: "flex-start",
                                    marginTop: "13px",
                                }}
                            >
                                {"Date format"}
                            </div>
                            <DateTimeFormatSelect
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                onCreateOption={(option) => {
                                    this.setState((state) => ({
                                        columnFormat: {
                                            ...(state.columnFormat as NumberFormat),
                                            format: option.value,
                                        },
                                        formatOptions: [
                                            option,
                                            ...state.formatOptions,
                                        ],
                                    }));
                                }}
                                styles={{
                                    ...customSelectStylesSmall,
                                    ...leftAlignAndScrollBarCSS,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                    }),
                                    singleValue: (base) => ({
                                        ...base,
                                        fontWeight: 400,
                                        fontSize: 14,
                                        padding: "2px 8px"
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        fontWeight: 400,
                                        fontSize: 14,
                                        color: '#000',
                                        backgroundColor: state.isSelected ? "var(--greyColor)" : "#fff",
                                        "&:hover": {
                                            backgroundColor: "var(--greyColor)"
                                        }
                                    }),
                                    input:(base=>({
                                        ...base,
                                        fontSize:14,
                                        paddingLeft:9
                                    }))
                                }}
                                options={this.state.formatOptions}
                                onChange={(newValue) => {
                                    this.setState((state) => ({
                                        columnFormat: {
                                            ...(state.columnFormat as NumberFormat),
                                            format: (newValue as DateTimeOptionType)
                                                .value,
                                        },
                                    }));
                                }}
                                value={this.state.columnFormat.format ?? ""}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </>
                    )
                }
                {
                    this.props.allowLocationGeneration && (
                        <Button
                            data-test-id={
                                this.props.rootDataTestId
                                    ? `${this.props.rootDataTestId}-generateCoordinatesButton`
                                    : undefined
                            }
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                ...buttonStyle,
                                marginTop: "16px",
                                fontSize: "9px",
                            }}
                            onClick={this.props.onGenerateLocation}
                        >
                            Generate Coordinates
                        </Button>
                    )
                }
                <button
                    type="button"
                    data-test-id={
                        this.props.rootDataTestId
                            ? `${this.props.rootDataTestId}-saveButton`
                            : undefined
                    }
                    className="acceptBtn"
                    style={{ marginTop: "16px", width: "100%" }}
                    onClick={() => {
                        this.props.onClose(true, this.state.columnFormat);
                    }}
                >
                    SAVE
                </button>
                <Button
                    prevent-mouse-leave="true"
                    type="button"
                    data-test-id={
                        this.props.rootDataTestId
                            ? `${this.props.rootDataTestId}-closeButton`
                            : undefined
                    }
                    className="btn btn-sm btn-primary btn-small-formats my-primary"
                    style={{
                        ...buttonStyle,
                        marginTop: "4px",
                        backgroundColor: "transparent",
                        border: "none",
                    }}
                    onClick={() => {
                        this.props.onClose(false);
                    }}
                >
                    CANCEL
                </Button>
            </div >
        );
    }

    render() {
        return (
            <div
                prevent-mouse-leave="true"
                className="dashboard-rect flex-simple-column"
                style={{
                    width: "250px",
                    alignItems: "center",
                    boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                    borderRadius: 6,
                    cursor: "pointer",
                    border: "2px solid #cccccc",
                }}
                onContextMenu={(evt) => {
                    evt.stopPropagation();
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                onMouseDown={(evt: any) => {
                    evt.stopPropagation();
                }}
            >
                {this.buildInnerView()}
            </div>
        );
    }
}
export default SpreadSheetFormatOptionsPopup;
