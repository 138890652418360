import React from "react";
import { ReactComponent as Options } from "icons/dots-three-vertical.svg";
import styles from "./TableOptions.module.css";
import OutsideAlerter from "common/OutsideAlerter";

interface Action<Row extends object> {
    Icon: (row: Row, rowIndex: number) => JSX.Element;
    optLabel: string | ((row: Row, rowIndex: number) => string);
    optKey: string;
    callback: (row: Row, rowIndex: number) => void;
}

interface IProps<Row extends object> {
    actions: Action<Row>[];
    row: Row;
    rowIndex: number;
}

function TableActions<Row extends object>({
    actions,
    row,
    rowIndex,
}: IProps<Row>) {
    const [showOptions, setShowOptions] = React.useState<boolean>(false);

    return (
        <OutsideAlerter
            onReject={() => {
                setShowOptions(false);
            }}
        >
            <div
                className={styles.actionsWrapper}
                onBlur={() => setShowOptions(false)}
            >
                {showOptions && (
                    <div className={styles.optionsWrapper}>
                        {actions.map((action: Action<Row>) => (
                            <div
                                className={styles.option}
                                onClick={() => action.callback(row, rowIndex)}
                            >
                                {action.Icon(row, rowIndex)}
                                <p className={styles.optValue}>
                                    {typeof action.optLabel === "function"
                                        ? action.optLabel(row, rowIndex)
                                        : action.optLabel}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
                <div
                    className={styles.iconWrapper}
                    onClick={() => {
                        setShowOptions(!showOptions);
                    }}
                >
                    {React.createElement(Options, { width: 15, height: 15 })}
                </div>
            </div>
        </OutsideAlerter>
    );
}

export default TableActions;
export type { Action };
