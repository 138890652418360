import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props) {
        let { data, config } = props;
        let item = {
            type: "17",
            content: {
                data: data,
            },
            config: config,
        };
        return item;
    }
    static getData(config, findingOptions, moduleId) {
        let {
            dataScope,
            conditions,
            count,
            selectedVariable,
            labelVariable,
            selectedVariableIndex,
            labelVariableIndex,
        } = config;

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );

        conditions = conditionsToJson(conditions);
        let requestJson = {
            variable_index: selectedVariableIndex,
            label_index: labelVariableIndex,
            table: [],
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            conditions: conditions,
            limit: count || 100,
            module_id: moduleId,
        };
        return axios
            .post("/api/e/funnel_plot", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = response.data.data;
                    let modifiedData = [];
                    for (
                        let i = 0;
                        i < data[selectedVariableIndex].length;
                        ++i
                    ) {
                        modifiedData.push({
                            [selectedVariable]: data[selectedVariableIndex][i],
                            [labelVariable]: data[labelVariableIndex][i],
                            "%percentage": data["%percentage"][i],
                        });
                    }
                    let finalItem = Api.getFinding({
                        data: modifiedData,
                        config: config,
                    });
                    return Promise.resolve(finalItem);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
