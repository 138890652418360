import React from "react";
import ReactWordcloud, { Word } from "react-wordcloud";
import { useDrop } from "react-dnd";
import { WordCloudFinding, defaultWordCloudOptions } from "common/Finding";

interface Props {
    data: Word[];
    config: WordCloudFinding["config"];
    preview?: boolean;
    editable?: boolean;
    dataSetMenuIsOpen?: boolean;
    columnDragActive?: boolean;
    onChangeData?: (data: any[], updateFinding?: boolean) => void;
    onChangeConfig?: (config: any, updateFinding?: boolean) => void;
}

export default function WordCloud(props: Props): JSX.Element | null {
    const ref = React.useRef(null);
    const drop = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            if (props.onChangeConfig != null) {
                let config = {
                    ...props.config,
                    selectedVariableIndex: otherItem.content.variableIndex,
                };
                props.onChangeConfig(config, true);
            }
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    })[1];
    if (props.editable) {
        drop(ref);
    }
    return (
        <div
            ref={ref}
            style={{ position: "relative", width: "100%", height: "100%" }}
        >
            <div
                style={{
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        minHeight: 100,
                        minWidth: 100,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <ReactWordcloud
                        options={
                            props.config.wordCloudOptions ??
                            defaultWordCloudOptions
                        }
                        words={props.data}
                    />
                </div>
            </div>
            {props.columnDragActive && (
                <div
                    style={{
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        display:
                            props.editable &&
                            props.dataSetMenuIsOpen &&
                            !props.preview
                                ? "flex"
                                : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "dashed 3px #8DB8E3",
                        backgroundColor: "#EBF2F9EE",
                    }}
                >
                    <span
                        className="no-selection"
                        style={{
                            color: "#333",
                            fontSize: "14px",
                            fontWeight: 500,
                        }}
                    >
                        Drop variable here
                    </span>
                </div>
            )}
        </div>
    );
}
