import React, { Component } from "react";
import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import Alert from "common/Alert";
import { passwordResetApi } from "common/EmailApi";
import { goToInternalLink } from "common/InternalLinksHelper";
import cx from "classnames";
import mds from "./RegisterPageV1/MaterialDashboard.module.css";
import styles from "./RegisterPageV1/RegisterPage.module.css";
import { Field } from "modules/settings_page/common/FieldEditor";

enum Status {
    Running = "running",
    Finished = "finished",
    Error = "error",
}

const statusToClassName: Readonly<{ [key in Status]: string }> = {
    [Status.Running]: "alert-warning",
    [Status.Finished]: "alert-success",
    [Status.Error]: "alert-danger",
};
interface Props { }
interface State {
    value: Field[];
    status: Status | null;
    error: string | null;
    focusField?: string;
}

function toMdsModuleClassName(className: string) {
    let mdsStyles = className.split(" ").map((item) => mds[item]);
    return cx(mdsStyles);
}
export default class ResetPasswordPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let value: Field[] = [
            {
                name: "httpd_username",
                label: "New Password",
                value: "",
                editable: true,
                required: true,
                hidden: false,
                add_mock_after: true,
            },
            {
                name: "httpd_username",
                label: "Confirm Password",
                value: "",
                editable: true,
                required: true,
                hidden: false,
                add_mock_after: true,
            },
        ];
        this.state = {
            status: null,
            error: null,
            value: value,
            focusField: undefined,

        };
    }

    render() {
        let statusText: string = "";
        if (this.state.status === Status.Running) {
            statusText = "Running";
        } else if (this.state.status === Status.Finished) {
            statusText = "Success: password changed";
        } else {
            statusText = `Error: ${this.state.error}`;
        }

        let backgroundStyle: React.CSSProperties = {
            backgroundImage: "url(/dist/img/register/splash-3.png)",
        };

        return (
            <>
                <main className={toMdsModuleClassName("main-content  mt-0")}>
                    <div
                        className={toMdsModuleClassName(
                            "page-header align-items-start min-vh-100"
                        )}
                        style={backgroundStyle}
                    >
                        <span className={styles.bgGradient} />
                        <div className={toMdsModuleClassName("container my-auto")}>
                            <div className={toMdsModuleClassName("row")}>
                                <div
                                    className={toMdsModuleClassName(
                                        "col-lg-4 col-md-8 mx-auto"
                                    )}
                                >
                                    <div
                                        className={toMdsModuleClassName(
                                            "card z-index-0 fadeIn3 fadeInBottom"
                                        )}
                                    >
                                        <div
                                            className={cx(
                                                toMdsModuleClassName(
                                                    "border-radius-lg"
                                                ),
                                                styles.logoContainer
                                            )}
                                        >
                                            <span className={styles.title}>
                                                Reset Password
                                            </span>
                                        </div>
                                        <div
                                            className={toMdsModuleClassName(
                                                "card-body"
                                            )}
                                        >
                                            <div>
                                                {this.state.value.map(
                                                    (item, index) => (
                                                        <div
                                                            key={index}
                                                            className={
                                                                styles.fieldsContainer
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.fieldLabel
                                                                }
                                                            >
                                                                {item.label}
                                                            </span>
                                                            <input
                                                                disabled={
                                                                    !item.editable
                                                                }
                                                                type={
                                                                    item.hidden
                                                                        ? "password"
                                                                        : "text"
                                                                }
                                                                className={
                                                                    styles.field
                                                                }
                                                                placeholder={`Enter ${item.label.toLowerCase()}`}
                                                                onFocus={() => {
                                                                    this.setState({
                                                                        focusField:
                                                                            item.name,
                                                                    });
                                                                }}
                                                                onBlur={() => {
                                                                    this.setState({
                                                                        focusField: undefined,
                                                                    });
                                                                }}
                                                                value={item.value}
                                                                onChange={(e) => {
                                                                    let currentValue = Array.from(
                                                                        this.state
                                                                            .value
                                                                    );
                                                                    currentValue[
                                                                        index
                                                                    ].value =
                                                                        e.target.value;
                                                                    this.setState({
                                                                        value: currentValue,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                                <div
                                                    className={toMdsModuleClassName(
                                                        "text-center"
                                                    )}
                                                >
                                                    <button
                                                        onClick={() => {
                                                            if (
                                                                this.state.value[0].value !==
                                                                this.state.value[1].value 
                                                            ) {
                                                                this.setState({
                                                                    status: Status.Error,
                                                                    error: "Password didn't match",
                                                                });
                    
                                                                return;
                                                            }
                                                            this.setState({ status: Status.Running });
                                                            const params: URLSearchParams = new URLSearchParams(
                                                                window.location.search
                                                            );
                                                            passwordResetApi(
                                                                params.get("id") ?? "",
                                                                this.state.value[0].value
                                                            )
                                                                .then(() => {
                                                                    this.setState({
                                                                        status: Status.Finished,
                                                                    });
                                                                    setInterval(() => {
                                                                        goToInternalLink("/login.html");
                                                                    }, 2000);
                                                                })
                                                                .catch((error) => {
                                                                    this.setState({
                                                                        status: Status.Error,
                                                                        error: String(error),
                                                                    });
                                                                });
                                                        }}
                                                        className={cx(
                                                            toMdsModuleClassName(
                                                                "btn w-100"
                                                            ),
                                                            styles.btnBlue
                                                        )}
                                                        disabled={
                                                            false
                                                        }
                                                    >
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "absolute",
                                                        top: "-72px",
                                                        width: 235,
                                                    }}
                                                    src="/dist/img/logo/logo_with_name_blue.svg"
                                                    alt="Logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.status !== null && (
                                        <Alert
                                            text={statusText}
                                            className={
                                                "alert " +
                                                statusToClassName[this.state.status]
                                            }
                                            onClosed={() => {
                                                this.setState({ status: null });
                                            }}
                                            style={{
                                                marginTop: "20px",
                                                marginBottom: "0px",
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}
