import React from "react";
import HistogramD3 from "common/graphics/v2/D3Charts/HistogramD3";
import { HistogramFinding } from "common/Finding";

function MainComponent(props: {
    finding: HistogramFinding;
    onNewFinding?: (finding: HistogramFinding, updateData?: boolean) => void;
	preview?: boolean;
	editable?: boolean;
	columnDragActive?: boolean;
    width: number;
    height: number;
    scale: number;
    selected: boolean;
}) {
    let finding = props.finding;

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <HistogramD3
                editable={props.editable}
                selected={props.selected}
                preview={props.preview}
                config={finding.config}
                columnDragActive={props.columnDragActive}
                data={finding.content.data}
                isDateTime={finding.content.isDateTime ?? false}
                onChangeData={
                    props.onNewFinding
                        ? (data: any, updateData?: boolean) => {
                                let finding = { ...props.finding };
                                finding.content.data = data;
                                props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (config: any, updateData?: boolean) => {
                                let finding = { ...props.finding };
                                finding.config = config;
                                props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeContent={
                    props.onNewFinding
                        ? (content: any, updateData?: boolean) => {
                                let finding = { ...props.finding };
                                finding.content = content;
                                props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                width={props.width}
                height={props.height}
                scale={props.scale}
            />
        </div>
    );
}

export { MainComponent };
