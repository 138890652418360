import React from "react";
import {HoldOutPredictionFinding} from "common/Finding";
import HoldOutPredictionChart from "common/graphics/v2/HoldOutPredictionChart";

function MainComponent(props: {
    finding: HoldOutPredictionFinding;
    onNewFinding?: (finding: HoldOutPredictionFinding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
}) {
    let finding = props.finding;
    return (
        <HoldOutPredictionChart
            editable={props.editable}
            preview={props.preview}
            columnDragActive={props.columnDragActive}
            config={finding.config}
            data={finding.content.data}
            groupNames={finding.content.groupNames}
            regressionInfo={finding.content.regressionInfo}
            onChangeData={
                props.onNewFinding
                    ? (data: any, updateData?: boolean) => {
                          let finding = { ...props.finding };
                          finding.content.data = data;
                          props.onNewFinding!(finding, updateData);
                      }
                    : undefined
            }
            onChangeConfig={
                props.onNewFinding
                    ? (config: any, updateData?: boolean) => {
                          let finding = { ...props.finding };
                          finding.config = config;
                          props.onNewFinding!(finding, updateData);
                      }
                    : undefined
            }
        />
    );
}

export { MainComponent };
