import React from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";
import { AxiosResponse } from "axios";

import "common/styles/App.css";
import "common/styles/div_span.css";
import Alert from "common/Alert";
import CustomBarChart from "common/graphics/CustomBarChart";
import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import BaseSubmodule from "../../common/BaseSubmodule";
import TimePlot, { formatTimePlot } from "../../common/TimePlot";
import ParameterSelector from "../../common/ParameterSelector";
import {
    TypeParameter,
    SectionParameter,
    ElementParameter,
    VariableParameter,
} from "../../common/Parameters";


interface Props {
    visible: boolean;
}

interface State {
    currentPlottedVariable: string | null;
    error: string | null;
    userPlot: Array<{ [key: string]: number } & { name: string }> | null;
    userPlotMax: number | null;
    timePlot: Array<{ [key: string]: number } & { name: string }> | null;
    serverDown: boolean[] | null;

    type: TypeParameter | null;
    section: SectionParameter | null;
    element: ElementParameter | null;
    variable: VariableParameter | null;
}

@observer
class MainComponent extends BaseSubmodule<Props, State> {
    private performance: Date | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            currentPlottedVariable: null,
            error: null,
            userPlot: null,
            userPlotMax: null,
            timePlot: null,
            serverDown: null,

            type: null,
            section: null,
            element: null,
            variable: null,
        };

        this.performance = null;
    }

    public componentDidUpdate(): void {
        if (this.performance != null) {
            let timeMs = new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Usage", timeMs);
        }
    }

    public static getTitle(): string {
        return "Mean Across Users And Across Time";
    }

    private async instrumentationMeanPlots(): Promise<void> {
        if (this.state.type == null || this.state.variable == null) return;
        const type = this.state.type?.value;
        const variable = this.state.variable?.value;
        const section = this.state.type.hasSection
            ? this.state.section?.value
            : undefined;
        const element = this.state.type.hasElement
            ? this.state.element?.value
            : undefined;

        // Get mean plots
        let response1: AxiosResponse<{
            success: boolean;
            error_msg: string;
            user_plot?: Array<[string, number]>;
            time_plot?: Array<[number, number]>;
        }>;

        try {
            response1 = await axios.post("/api/instrumentation_mean_plots", {
                type: type,
                section: section,
                element: element,
                variable: variable,
            });
        } catch (error) {
            console.log(error);
            this.setState({
                error: error.response.status.toString(),
            });
            return;
        }
        if (
            !response1.data.success ||
            response1.data.time_plot == null ||
            response1.data.user_plot == null
        ) {
            this.setState({ error: response1.data.error_msg });
            return;
        }
        if (
            response1.data.user_plot.length === 0 &&
            response1.data.time_plot.length === 0
        ) {
            this.setState({
                error: "No data for selected filters",
                currentPlottedVariable: null,
                userPlot: null,
                userPlotMax: null,
                timePlot: null,
                serverDown: null,
            });
            return;
        }

        // Get server uptime
        let response2: AxiosResponse<{
            success: boolean;
            error_msg: string;
            uptime?: number[];
        }>;
        try {
            response2 = await axios.post("/api/get_server_uptime", {
                start_time: response1.data.time_plot[0][0],
                end_time:
                    response1.data.time_plot[
                        response1.data.time_plot.length - 1
                    ][0],
            });
        } catch (error) {
            console.log(error);
            this.setState({
                error: error.response.status.toString(),
            });
            return;
        }
        if (!response2.data.success || response2.data.uptime == null) {
            this.setState({
                error: response2.data.error_msg,
            });
            return;
        }

        // Format user plot
        let userPlot: Array<{ [key: string]: number } & { name: string }> = [];
        let userPlotMax: number = 0;
        for (let [user, value] of response1.data.user_plot) {
            userPlotMax = Math.max(value, userPlotMax) || 0;
            userPlot.push({
                [variable]: value,
                name: user,
            } as { [key: string]: number } & { name: string });
        }
        // Format time plot
        let { timePlot, serverDown } = formatTimePlot(
            response1.data.time_plot,
            response2.data.uptime,
            [variable]
        );
        this.setState({
            error: null,
            currentPlottedVariable: variable,
            userPlot: userPlot,
            userPlotMax: userPlotMax,
            timePlot: timePlot,
            serverDown: serverDown,
        });
    }

    private renderUserPlot(): JSX.Element | null {
        if (
            this.state.userPlotMax == null ||
            this.state.currentPlottedVariable == null ||
            this.state.userPlot == null
        ) {
            return null;
        }
        return (
            <div
                style={{
                    height: 300,
                    overflowX: "scroll",
                    overflowY: "hidden",
                }}
            >
                <CustomBarChart
                    maxChartValue={this.state.userPlotMax}
                    barColors={["#FFAB4F"]}
                    barKeys={[this.state.currentPlottedVariable]}
                    data={this.state.userPlot}
                    title="Mean Across Users"
                />
            </div>
        );
    }

    private renderTimePlot(): JSX.Element | null {
        if (
            this.state.serverDown == null ||
            this.state.currentPlottedVariable == null ||
            this.state.timePlot == null
        ) {
            return null;
        }
        return (
            <div
                style={{
                    height: 320,
                    overflowX: "scroll",
                    overflowY: "hidden",
                }}
            >
                <TimePlot
                    lineColors={["#FFAB4F"]}
                    lineKeys={[this.state.currentPlottedVariable]}
                    areaColor="#EE4344"
                    serverDown={this.state.serverDown}
                    data={this.state.timePlot}
                    title="Mean Across Time"
                />
            </div>
        );
    }

    public render(): JSX.Element | null {
        if (!this.props.visible) return null;
        return (
            <div style={{ marginTop: 20 }}>
                <div style={{ marginTop: 10 }} className="flex-simple-column">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                        }}
                    >
                        <span
                            className="regular-text"
                            style={{
                                marginRight: "15px",
                            }}
                        >
                            Plot mean
                        </span>
                        <ParameterSelector
                            type={this.state.type}
                            section={this.state.section}
                            element={this.state.element}
                            variable={this.state.variable}
                            onChange={(type, section, element, variable) => {
                                this.setState({
                                    type: type,
                                    section: section,
                                    element: element,
                                    variable: variable,
                                });
                            }}
                        />
                        <span
                            className="regular-text"
                            style={{
                                marginLeft: "15px",
                                marginRight: "15px",
                            }}
                        >
                            across users and across time
                        </span>
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                width: 150,
                                height: "38px",
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                            onClick={() => {
                                this.performance = new Date();
                                this.instrumentationMeanPlots();
                            }}
                        >
                            UPDATE
                        </Button>
                    </div>
                    {this.state.error != null && (
                        <Alert
                            text={`Error: ${this.state.error}`}
                            className="alert alert-danger alert-dismissible"
                            onClosed={() => this.setState({ error: null })}
                            style={{
                                width: "100%",
                            }}
                        />
                    )}
                    {this.state.userPlot != null && this.renderUserPlot()}
                    {this.state.timePlot != null && this.renderTimePlot()}
                </div>
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "MeanAcrossUsersAndAcrossTime";
