import React, { Component } from "react";
import { Element } from "react-scroll";
import { mainStyle } from "common/MainStyle";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";

import RegressionVariablesBarChart from "./RegressionVariablesBarChart";

class RegressionResultsComponent extends Component {
	renderLegendText(value, entry) {
		return (
			<span
				style={{
					fontFamily: "Arial",
					fontSize: mainStyle.getPropertyValue(
						"--graphs-legends-size"
					),
					color: mainStyle.getPropertyValue(
						"--graphs-legends-text-color"
					),
				}}
			>
				{value}
			</span>
		);
	}

	render() {
		if (this.props.lastCustomRegressionResults == null) return null;
		let items = [
			["#09e398", "#FFAB4F", "#fb5858"],
			["#057e55", "#d6760c", "#a11313"],
		];

		let expressions;
		if (this.props.lastCustomRegressionResults.where?.values != null) {
			expressions = this.props.lastCustomRegressionResults.where.values.map(
				(value) => ({
					operation: "=",
					value: value,
				})
			);
		} else {
			expressions = this.props.lastCustomRegressionResults.where
				?.expressions;
		}
		return (
			<Element
				name="scrollable"
				className="element"
				style={{
					overflowX: mobileBreakpoint() ? "visible" : "hidden",
					backgroundColor: "transparent",
					position: "relative",
					height: "100%",
					paddingTop: 10,
					overflowY: mobileBreakpoint() ? "visible" : "auto",
				}}
			>
				<div
					className="flex-column"
					style={{ height: "calc(100% - 15px)" }}
				>
					{this.props.lastCustomRegressionResults.where &&
						expressions.map((expression, index) => (
							<div className="center-container" key={index}>
								<span
									style={{
										color: "#FFF",
										fontFamily: "Arial",
										fontSize: "10px",
										lineHeight: "11px",
									}}
								>
									{`${
										this.props.lastCustomRegressionResults
											.where.group
									} ${expression.operation.label} ${
										expression.value.label
									} (${index === 0 ? "Top" : "Bottom"})`}
								</span>
							</div>
						))}
					<RegressionVariablesBarChart
						colorItems={items}
						data={
							this.props.lastCustomRegressionResults
								.regressionBars
						}
						onRegressionBarsChanged={
							this.props.onRegressionBarsChanged
								? (regressionBars) => {
										this.props.onRegressionBarsChanged(
											regressionBars
										);
								  }
								: undefined
						}
					/>
				</div>
				{this.props.showLegend ? (
					<div
						style={{
							color: mainStyle.getPropertyValue(
								"--graphs-axes-text-color"
							),
							width: "100%",
							height: "10px",
							fontSize: "11px",
							fontFamily: "Arial",
							textAlign: "left",
							marginLeft: "5%",
							backgroundColor: "transparent",
						}}
					>
						Significance Levels: ★★★ 99%, ★★ 95%, ★ 90%
					</div>
				) : null}
			</Element>
		);
	}
}

export default RegressionResultsComponent;