import { formatDate } from "common/utilities/FormatDate";

import { Insight } from "./Insight";
import { Permission } from "common/Permissions";


export interface Story {
    id: number;
    title: string;
    tab_id: number;

    insights: Array<{
        contents: any
    }>;

    user_data?: { // TODO Check if ? is necessary here
        user_name: string,
        first_name: string,
        last_name: string,
        icon_url: string,
        is_admin: boolean,
        is_owner: boolean
    };

    creation_time: string;
    has_new_feedbacks: boolean;
    has_updates: boolean;
    raw_timestamp: number;
    raw_update_timestamp: number;
    permission_type: Permission;
}

// Story as it is sent by the server
export interface StoryResponse {
    id: number;
    title: string;
    tab_id: number;

    cards: string[]; // cards are encoded as strings

    user_data: {
        user_name: string,
        first_name: string,
        last_name: string,
        icon_url: string,
        is_admin: boolean,
        is_owner: boolean
    };
    creation_time: number;
    update_time: number;
    has_new_feedbacks: boolean;
    has_updates: boolean;
    permission_type: Permission;
}

export function toStory(storyResponse: StoryResponse): Story {
    return {
        id: storyResponse.id,
        title: storyResponse.title,
        tab_id: storyResponse.tab_id,
        insights: storyResponse.cards.map((card: string) => ({
            contents: JSON.parse(card) as Insight
        })),
        user_data: storyResponse.user_data,
        creation_time: formatDate(
            storyResponse.creation_time,
            "created"
        ),
        permission_type: storyResponse.permission_type,
        has_new_feedbacks: storyResponse.has_new_feedbacks,
        has_updates: storyResponse.has_updates,
        raw_timestamp: storyResponse.creation_time,
        raw_update_timestamp: storyResponse.update_time
    };
}
