import React from "react";
import { formatValue } from "common/utilities/FormatValue";
import styles from "./BarChart.module.css";

interface Props {
    leftValue: number;
    leftLabel: string;
    rightValue: number;
    rightLabel: string;
    segment: string;
}

function BarChart(props: Props): JSX.Element {
    const [leftPercentage, rightPercentage] = React.useMemo(() => {
        let maxValue = Math.max(props.leftValue, props.rightValue);
        return [
            (props.leftValue / maxValue) * 100,
            (props.rightValue / maxValue) * 100,
        ];
    }, [props.leftValue, props.rightValue]);

    return (
        <div className={styles.container}>
            <span className={styles.title}>{props.segment}</span>
            <div className={styles.chartContainer}>
                <div className={styles.barAndTitleContainer}>
                    <span className={styles.barTitle}>
                        {`$${formatValue(props.leftValue, false, 1).join("")}`}
                    </span>
                    <div className={styles.barContainer}>
                        <div
                            className={styles.leftBar}
                            style={{
                                height: `${leftPercentage}%`,
                            }}
                        />
                        <span className={styles.barLabel}>
                            {props.leftLabel}
                        </span>
                    </div>
                </div>
                <div className={styles.barAndTitleContainer}>
                    <span className={styles.barTitle}>
                        {`$${formatValue(props.rightValue, false, 1).join("")}`}
                    </span>
                    <div className={styles.barContainer}>
                        <div
                            className={styles.rightBar}
                            style={{
                                height: `${rightPercentage}%`,
                            }}
                        />
                        <span className={styles.barLabel}>
                            {props.rightLabel}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BarChart;
