import React from "react";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import { QuestionnaireElement } from "common/Canvas";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Variables, { VariableOption } from "common/Variables";
import { dataScienceSelectStyles } from "common/SelectStyles";
import { Permission } from "common/Permissions";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    questionnaireElement: QuestionnaireElement;
    maxHeight: number;
    onChange: (question: QuestionnaireElement) => void;
    currentModuleId?: number;
}

const BackendOptionsView = observer((props: Props) => {
    let dataScopes = props.currentModuleId
        ? DataScopesForModules(props.currentModuleId)
        : DataScopes;
    return (
        <div
            className="flex-simple-column"
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
                marginLeft: "90px",
            }}
        >
            <div
                className="flex-simple-column element"
                style={{ overflow: "auto" }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                onMouseDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                <Select
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Write to backend data & column"}
                    styles={{
                        ...dataScienceSelectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                            height: "35px",
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 51 }),
                    }}
                    options={dataScopes.dataScopesOptions.filter(
                        (option) =>
                            option.permissionType === Permission.ReadWrite
                    )}
                    onChange={(newValue) => {
                        let newQuestionnaireElement = Object.assign(
                            {},
                            props.questionnaireElement
                        );
                        newQuestionnaireElement.backendOutput = {
                            dataScopeOption: newValue as DataScopeOption,
                            tableOption: Tables(
                                (newValue as DataScopeOption).value,
                                props.currentModuleId ?? remoteModuleId
                            ).tableToOption(),
                            variableOptions: {},
                        };

                        props.onChange(newQuestionnaireElement);
                    }}
                    value={
                        props.questionnaireElement.backendOutput
                            ?.dataScopeOption ?? null
                    }
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                <div
                    style={{
                        minHeight: "2px",
                        backgroundColor:
                            dataScienceElementsStyle.secondaryTextColor,
                    }}
                />
                {props.questionnaireElement.backendOutput?.dataScopeOption && (
                    <>
                        <div
                            onMouseDown={(evt) => {
                                evt.stopPropagation();
                            }}
                        >
                            <Select
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"Select table"}
                                styles={{
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginTop: "20px",
                                        width: "100%",
                                        height: "35px",
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 51 }),
                                }}
                                options={
                                    Tables(
                                        props.questionnaireElement.backendOutput
                                            .dataScopeOption.value,
                                        props.currentModuleId ?? remoteModuleId
                                    ).rawAndAggregateTableOptions
                                }
                                onChange={(newValue) => {
                                    let newQuestionnaireElement = Object.assign(
                                        {},
                                        props.questionnaireElement
                                    );
                                    newQuestionnaireElement.backendOutput!.tableOption = newValue as TableOption;
                                    newQuestionnaireElement.backendOutput!.variableOptions = {};
                                    props.onChange(newQuestionnaireElement);
                                }}
                                value={
                                    props.questionnaireElement.backendOutput!
                                        .tableOption
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                minHeight: "2px",
                                backgroundColor:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                        />
                    </>
                )}
                {props.questionnaireElement.backendOutput?.dataScopeOption &&
                    props.questionnaireElement.questions.map(
                        (_, index: number) => {
                            let variableOption =
                                props.questionnaireElement.backendOutput!
                                    .variableOptions[index] ?? null;

                            return (
                                <React.Fragment key={index}>
                                    <div
                                        onMouseDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Select
                                            menuPortalTarget={document.body}
                                            menuShouldBlockScroll={true}
                                            onKeyDown={(evt) => {
                                                evt.stopPropagation();
                                            }}
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={`Select column for question ${
                                                index + 1
                                            }`}
                                            styles={{
                                                ...dataScienceSelectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    marginTop: "20px",
                                                    width: "100%",
                                                    height: "35px",
                                                }),
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 51,
                                                }),
                                            }}
                                            isClearable={true}
                                            options={Variables(
                                                props.questionnaireElement
                                                    .backendOutput!.dataScopeOption!
                                                    .value,
                                                props.currentModuleId ??
                                                    remoteModuleId
                                            )
                                                .dataVariables.filter(
                                                    (variable) =>
                                                        variable.derived_from ==
                                                        null
                                                )
                                                .map((variable) => ({
                                                    label: variable.name,
                                                    value: variable.index ?? -1,
                                                    type: variable.type,
                                                    panel: variable.panel,
                                                }))}
                                            onChange={(newValue) => {
                                                let newQuestionnaireElement = Object.assign(
                                                    {},
                                                    props.questionnaireElement
                                                );
                                                if (newValue != null) {
                                                    newQuestionnaireElement.backendOutput!.variableOptions[
                                                        index
                                                    ] = newValue as VariableOption;
                                                } else {
                                                    delete newQuestionnaireElement.backendOutput!
                                                        .variableOptions[index];
                                                }
                                                props.onChange(
                                                    newQuestionnaireElement
                                                );
                                            }}
                                            value={variableOption}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            minHeight: "2px",
                                            backgroundColor:
                                                dataScienceElementsStyle.secondaryTextColor,
                                        }}
                                    />
                                </React.Fragment>
                            );
                        }
                    )}
            </div>
        </div>
    );
});

export default BackendOptionsView;
