import React, { Component } from "react";
import {
	addUserPageTabApi,
	editUserPageTabApi,
	getUserPageTabsApi,
	deleteUserPageTabApi,
} from "./UserApi";
import MessagePopup from "./MessagePopup";

export default class TabPage extends Component {
	onUpdateTab(value, tabId) {
		if (value.length > 0) {
			this.performance = new Date();
			if (!tabId) {
				addUserPageTabApi(value, this.state.pageId, this.tabType)
					.then((id) => {
						this.setState({ editedTab: "" });
						this.getPageTabs(this.state.pageId);
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.setState({ editedTabId: undefined });
				let index = this.state.pageTabs.findIndex(
					(element, index, array) => {
						return String(element.id) === String(tabId);
					}
				);
				if (index < 0) return;
				let item = Object.assign({}, this.state.pageTabs[index]);
				editUserPageTabApi(item)
					.then(() => {
						this.getPageTabs(this.state.pageId);
					})
					.catch((error) => {
						console.log(error);
						this.getPageTabs(this.state.pageId);
					});
			}
		} else {
			this.getPageTabs(this.state.pageId);
		}
	}

	getPageTabs(pageId, onLoad = () => {}) {
		getUserPageTabsApi(pageId, this.tabType).then((pageTabs) => {
			this.setState(state => {
				let sections = Object.assign({}, state.sections);
				pageTabs.forEach((pageTab) => {
					sections[pageTab.id] =
						typeof sections[pageTab.id] === "undefined"
							? false
							: sections[pageTab.id];
				});
				return {
					pageTabs: pageTabs,
					sections: sections
				};
			}, onLoad);
		});
	}

	onStartEditTab(tabId) {
		this.setState({ editedTabId: tabId });
	}
	onEditTab(value, tabId) {
		if (!tabId) this.setState({ editedTab: value });
		else {
			this.setState(state => {
				let pageTabs = Array.from(state.pageTabs);
				let index = pageTabs.findIndex((element, index, array) => {
					return String(element.id) === String(tabId);
				});
				if(index < 0)
					return {};
				pageTabs[index].title = value;
				return { pageTabs: pageTabs };
			});
		}
	}
	onRemoveTab(tabId) {
		this.setState({ removingPopupShowed: true, removingTabId: tabId });
	}

	onExpandTab(tabId, forceOpen = false) {
		this.performance = new Date();
		this.setState(state => {
			let sections = Object.assign({}, state.sections);
			sections[tabId] = !sections[tabId] || forceOpen;
			return { sections: sections };
		}, () => {
			this.onTabOpen(tabId);
		});
	}
	buildTabRemovePopup() {
		if (!this.state.removingPopupShowed) return null;
		return (
			<MessagePopup
				danger
				title={"Delete tab"}
				message={"Do you want to delete this tab?"}
				acceptButtonTitle={"Delete"}
				onAccept={() => {
					this.performance = new Date();
					deleteUserPageTabApi(this.state.removingTabId)
						.then(() => {
							this.setState(state => {
								let sections = Object.assign(
									{},
									state.sections
								);
								delete sections[state.removingTabId];
								let pageTabs = state.pageTabs.filter((item) =>
									String(item.id) !== String(state.removingTabId)
								);
								return {
									pageTabs: pageTabs,
									sections: sections,
									removingPopupShowed: false,
									removingTabId: undefined,
								};
							});
						})
						.catch((error) => {
							console.log(error);
							this.dismissTabRemovePopup();
						});
				}}
				onReject={() => {
					this.dismissTabRemovePopup();
				}}
			/>
		);
	}

	dismissTabRemovePopup() {
		this.setState(state => {
			if (state.removingPopupShowed)
				return {
					removingPopupShowed: false,
					removingTabId: undefined,
				};
			else
				return {};
		});
	}
}
