import React, { useState } from "react";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import cx from "classnames";

import { PrintExpression } from "common/Canvas";
import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import StatusSubExpressionSelector from "common/RevampedStatusSubExpressionsSelector";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./RevampedPrintExpressionsSelector.module.css";

interface Props {
  nodeLinkOptions: NodeLinkOption[];
  printExpressions: PrintExpression[];
  node: CanvasElement | CanvasTextBox;
  onChange: (changes: PrintExpression[], commit?: boolean) => void;
}

interface OutputIndexOption {
  label: string;
  value: number;
}

function PrintExpressionsSelector({ node, nodeLinkOptions, printExpressions, onChange }: Props) {
  const [collapsedRows, setCollapsedRows] = useState<any>({});

  function getOutputValue(node: CanvasElement | CanvasTextBox, printExpression: PrintExpression): OutputIndexOption | null {
    if (printExpression.outputIndex === 1)
      return {
        label: "Output#1",
        value: 1,
      };
    if (printExpression.outputIndex > 1) {
      if (node.additionalOutputs.length > printExpression.outputIndex - 2)
        return {
          label: `Output#${printExpression.outputIndex}`,
          value: printExpression.outputIndex,
        };
    }
    return null;
  }

  const outputOptions = [
    { label: "Output#1", value: 1 },
  ].concat(
    node.additionalOutputs?.map((_output, outputIndex) => ({
      label: `Output#${outputIndex + 2}`,
      value: outputIndex + 2,
    }))
  );

  return (
      <div>
        {printExpressions.map(
          (printExpression, expressionIndex) => (
            <div key={expressionIndex} className={styles.root}>
              <div className={styles.addRemoveButtonsContainer}>
                <button
                  type="button"
                  onClick={() => {
                    setCollapsedRows((prev: any) => {
                      const collapsedRowsCopy = { ...prev };

                      const key = String(expressionIndex);
                      if (key in collapsedRowsCopy) {
                        delete collapsedRowsCopy[key];
                      } else {
                        collapsedRowsCopy[key] = true;
                      }

                      return collapsedRowsCopy;
                    });
                  }}
                  className={styles.expressionCollapseButton}
                >
                  <ChevronIcon
                    className={cx(styles.expressionCollapseButtonIcon, {
                      [styles.chevronOpen]: !(String(expressionIndex) in collapsedRows)
                    })}
                  />
                  Output {expressionIndex + 1}
                </button>
                {expressionIndex === 0 ? (
                  <button
                    className={styles.addRemoveButton}
                    type="button"
                    onClick={() => {
                      let printExpressionsCopy = Array.from(printExpressions);
                      printExpressionsCopy.push({
                          subexpressions: [
                              { operation: "", value: "" },
                          ],
                          text: "",
                          outputIndex: 1,
                      });

                      onChange(printExpressionsCopy);
                    }}
                  >
                      {"\uFF0B"} Add
                  </button>
                ) : (
                    <button
                        className={styles.addRemoveButton}
                        type="button"
                        onClick={() => {
                          let printExpressionsCopy = Array.from(printExpressions);
                          printExpressionsCopy.splice(
                              expressionIndex,
                              1
                          );
                          if (printExpressionsCopy.length === 0) {
                              printExpressionsCopy.push({
                                  subexpressions: [
                                      {
                                          operation: "",
                                          value: "",
                                      },
                                  ],
                                  text: "",
                                  outputIndex: 1,
                              });
                          }
                          onChange(printExpressionsCopy);
                        }}
                    >
                        {"\u2715"}
                    </button>
                )}
              </div>
              <Collapse in={!(String(expressionIndex) in collapsedRows)}>
                <div className={styles.expressionExpanded}>
                  <div className={styles.expressionRow}>
                    <input
                      className={styles.input}
                      placeholder="Enter output"
                      value={printExpression.text}
                      onChange={(evt) => {
                        const printExpressionsCopy = Array.from(printExpressions);
                        printExpressionsCopy[expressionIndex].text = evt.currentTarget.value;
                        onChange(printExpressionsCopy, false);
                      }}
                      onBlur={() => {
                        onChange(printExpressions, true);
                      }}
                    />
                    <span className={styles.expressionLabel}>
                      for
                    </span>
                    <Select
                        placeholder=""
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            flex: "1 1 auto"
                          }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "4px",
                            minWidth: "140px"
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                        }}
                        options={outputOptions}
                        onChange={(newValue) => {
                            const newPrintExpressions = Array.from(printExpressions);
                            newPrintExpressions[expressionIndex].outputIndex = (newValue as OutputIndexOption).value;

                            onChange(newPrintExpressions);
                        }}
                        value={getOutputValue(node, printExpression)}
                    />
                  </div>
                  <StatusSubExpressionSelector
                    node={node}
                    nodeLinkOptions={nodeLinkOptions}
                    subexpressions={printExpression.subexpressions}
                    onChange={(subexpressions) => {
                      const printExpressionsCopy = Array.from(printExpressions);
                      printExpressionsCopy[expressionIndex].subexpressions = subexpressions;
                      onChange(printExpressionsCopy);
                    }}
                  />
                </div>
              </Collapse>
            </div>
          )
        )}
      </div>
  );
}

export default PrintExpressionsSelector;
