import React, { Component } from "react";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";

import { Field } from "modules/settings_page/common/FieldEditor";
import UpdateStatus from "modules/settings_page/common/UpdateStatus";
import { PermanentLinkType, SelfSignupInfo } from "common/ModulesApi";
import cx from "classnames";
import mds from "../RegisterPageV1/MaterialDashboard.module.css";
import styles from "../RegisterPageV1/RegisterPage.module.css";
import auth0 from "auth0-js";
import auth0Scope from "common/Auth0Scope";
import axios from "common/ServerConnection";
import { detectIncognito } from "detect-incognito";
import Alert from "common/Alert";
import { isPWA } from "common/utilities/UIResponsiveManager";

interface FieldWithId extends Field {
    id: string;
}

function toMdsModuleClassName(className: string) {
    let mdsStyles = className.split(" ").map((item) => mds[item]);
    return cx(mdsStyles);
}

interface Props {
    linkId: string;
    permanentLinkType?: PermanentLinkType;
}
interface State {
    focusField?: string;
    verified: boolean | undefined;
    email: string;
    status: UpdateStatus;
    error: string;
    value: FieldWithId[];
    signUpLink: string;
    selfSignupInfo: SelfSignupInfo | null;    
    isPrivate: boolean;
}

export default class RegisterPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const PWARedirectUrl = localStorage.getItem("PWARedirectUrl");
        window.localStorage.clear();
        window.sessionStorage.clear();
        if (PWARedirectUrl) window.localStorage.setItem("PWARedirectUrl", PWARedirectUrl)
        if (window.navigator && navigator.serviceWorker) {
            navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                    for (let registration of registrations) {
                        registration.unregister();
                    }
                });
        }
        document.children[0]!.setAttribute("style", "font-size:20px");
        let value: FieldWithId[] = [
            {
                name: "httpd_username", // todo: rename
                label: "Email or username",
                value: "",
                editable: true,
                required: true,
                hidden: false,
                add_mock_after: true,
                id: "email",
            },
            {
                name: "httpd_password", // todo: rename
                label: "Password",
                value: "",
                editable: true,
                required: true,
                hidden: true,
                passwordStrength: true,
                id: "password",
            },
        ];
        this.state = {
            selfSignupInfo: null,
            status: UpdateStatus.NotUploaded,
            error: "",
            email: "",
            signUpLink: "",
            verified: undefined,
            focusField: undefined,
            value: value,
            isPrivate: false,
        };
    }

    componentDidMount() {
        detectIncognito().then((result) => {
            this.setState({ isPrivate: result.isPrivate });
        });
        const urlParams = new URLSearchParams(window.location.search);
        const afterSearchParams = urlParams.get('after') ?? ""        
        if (afterSearchParams) {
            try
            {
                const parsedAfterSearchParams = JSON.parse(urlParams.get('after') ?? "").search;
                const remote_module_id = new URLSearchParams(parsedAfterSearchParams).get('remote_moduleid');
                if (remote_module_id) {
                    this.setState({ signUpLink: "/register.html?s=" + remote_module_id });
                } 
                return;   
            }
            catch(SyntaxError){
                console.log('Invalid register URL.');
                this.setState({ signUpLink: "/register.html"});
            }
        }
        axios.get<any>("/api/e/request_module_shared_self_signup_get_default_id").then((response) => {
                let m = response.data['shared_module_id'];
                if (m) {
                    this.setState({ signUpLink: "/register.html?m=" + m });
                } else {
                    this.setState({ signUpLink: "/register.html" });
                }                
            });
    }

    private async login(user_name: string, password: string) {
        const urlParams = new URLSearchParams(window.location.search);
        const path = urlParams.get("req") ?? "";
        const after = urlParams.get("after") ?? "";
        let redirectUri = `${window.location.origin}${path}`;
        if (after) {
            redirectUri = `${redirectUri}?after=${encodeURIComponent(after)}`;
        }
        this.setState({
            status: UpdateStatus.NotUploaded,
            error: "",
        });

        if (isPWA()) {
            const PWARedirectUrl = localStorage.getItem("PWARedirectUrl");
            if (!PWARedirectUrl) {
                window.localStorage.setItem("PWARedirectUrl", redirectUri)
            } else {
                if (JSON.parse(after).pathname === "/redirect.html") {
                    redirectUri = PWARedirectUrl;
                }
            }
        }

        axios.get<any>("/api/auth0_config").then((response) => {
            let webAuth = new auth0.WebAuth({
                domain: response.data.AUTH0_DOMAIN,
                clientID: response.data.AUTH0_CLIENT_ID,
                redirectUri: redirectUri,
                audience: response.data.AUTH0_API_IDENTIFIER,
                scope: auth0Scope,
            });
            webAuth.login(
                {
                    realm: response.data.AUTH0_REALM,
                    username: user_name,
                    password: password,
                    responseType: "code",
                },
                (error) => {
                    if (error != null) {
                        this.setState({
                            status: UpdateStatus.Error,
                            error: error.description ?? error.error,
                        });
                    }
                }
            );
        });
    }
    render() {
        let backgroundStyle: React.CSSProperties = {
            backgroundImage: "url(/dist/img/register/splash-3.png)",
            backgroundSize:"cover",
            backgroundPosition:"bottom"
        };
        if (this.state.selfSignupInfo?.options?.backgroundColor) {
            backgroundStyle = {
                background: this.state.selfSignupInfo?.options?.backgroundColor,
            };
        }
        if (this.state.selfSignupInfo?.options?.backgroundImage) {
            backgroundStyle = {
                backgroundImage: `url(${this.state.selfSignupInfo?.options?.backgroundImage})`,
            };
        }
        const urlParams = new URLSearchParams(window.location.search);
        const errorParam = urlParams.get("error");
        return (
            <main className={toMdsModuleClassName("main-content  mt-0")}>
                {(errorParam === "1" || this.state.isPrivate) && (
                    <Alert
                        text={
                            errorParam === "1"
                                ? "You were logged out for inactivity or your session expired"
                                : "You are in incognito mode. To continue please check that 3rd party cookies are enabled"
                        }
                        className="alert alert-warning alert-dismissible"
                        style={{
                            position: "absolute",
                            top: 10,
                            left: "50%",
                            transform: "translate(-50%, 0)",
                            zIndex: 100,
                        }}
                    />
                )}
                <div
                    className={toMdsModuleClassName(
                        "page-header align-items-start min-vh-100"
                    )}
                    style={backgroundStyle}
                >
                  <span className={styles.bgGradient} />
                    <div className={toMdsModuleClassName("container my-auto")}>
                        <div className={toMdsModuleClassName("row")}>
                            <div
                                className={toMdsModuleClassName(
                                    "col-lg-4 col-md-8 mx-auto"
                                )}
                            >
                                <div
                                    className={toMdsModuleClassName(
                                        "card fadeIn3 fadeInBottom"
                                    )}
                                    style={{ 
                                        zIndex: 2,
                                        paddingTop:"40px"
                                     }}
                                >
                                    <form
                                        onSubmit={(evt) => {
                                            evt.preventDefault();
                                            this.login(
                                                this.state.value[0].value.toLowerCase(),
                                                this.state.value[1].value
                                            );
                                        }}
                                    >
                                        <div
                                            className={toMdsModuleClassName(
                                                "card-body"
                                            )}
                                        >
                                            <div>
                                                {this.state.value.map(
                                                    (item, index) => (
                                                        <div
                                                            key={index}
                                                            className={
                                                                styles.fieldsContainer
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.fieldLabel
                                                                }
                                                            >
                                                                {item.label}
                                                            </span>
                                                            <input
                                                                disabled={
                                                                    !item.editable
                                                                }
                                                                type={
                                                                    item.hidden
                                                                        ? "password"
                                                                        : "text"
                                                                }
                                                                id={item.id}
                                                                className={
                                                                    styles.field
                                                                }
                                                                placeholder={`Enter ${item.label.toLowerCase()}`}
                                                                onFocus={() => {
                                                                    this.setState(
                                                                        {
                                                                            focusField:
                                                                                item.name,
                                                                        }
                                                                    );
                                                                }}
                                                                onBlur={() => {
                                                                    this.setState(
                                                                        {
                                                                            focusField: undefined,
                                                                        }
                                                                    );
                                                                }}
                                                                value={
                                                                    item.value
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    let currentValue = Array.from(
                                                                        this
                                                                            .state
                                                                            .value
                                                                    );
                                                                    currentValue[
                                                                        index
                                                                    ].value =
                                                                        e.target.value;
                                                                    this.setState(
                                                                        {
                                                                            value: currentValue,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                                <p
                                                    className={toMdsModuleClassName(
                                                        "p text-sm mt-3 mb-0"
                                                    )}
                                                    style={{ color: "#7b809a" }}
                                                >
                                                    <a
                                                        href="forgot_password.html"
                                                        className={
                                                            styles.forgotPassword
                                                        }
                                                    >
                                                        Forgot password?
                                                    </a>
                                                </p>
                                                <div
                                                    className={toMdsModuleClassName(
                                                        "text-center"
                                                    )}
                                                >
                                                    <button
                                                        id="sign_in_button"
                                                        type="submit"
                                                        className={cx(
                                                            toMdsModuleClassName(
                                                                "btn w-100"
                                                            ),
                                                            styles.btnBlue
                                                        )}
                                                        onClick={() => {
                                                            this.login(
                                                                this.state.value[0].value.toLowerCase(),
                                                                this.state
                                                                    .value[1]
                                                                    .value
                                                            );
                                                        }}
                                                        style={{
                                                            background:
                                                                this.state
                                                                    .selfSignupInfo
                                                                    ?.options
                                                                    ?.createButtonColor ||
                                                                undefined,
                                                        }}
                                                    >
                                                        Log In
                                                    </button>
                                                </div>
                                                {this.state.status ===
                                                    UpdateStatus.Error && (
                                                    <p
                                                        className={cx(
                                                            toMdsModuleClassName(
                                                                "p text-sm mt-3"
                                                            ),
                                                            styles.errorMessage
                                                        )}
                                                        style={{
                                                            color: "#cc0000",
                                                        }}
                                                    >
                                                        {this.state.error}
                                                    </p>
                                                )}
                                            </div>                                      
                                        </div>
                                    </form>
                                    <div className={styles.footer}>                                    
                                        <p className={styles.footerText}>
                                            Don’t have an account? 
                                            <a className={styles.footerLink} href={this.state.signUpLink}>
                                                Sign Up!
                                            </a>
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "absolute",
                                                top: "-72px",
                                                width: 235,
                                            }}
                                            src="/dist/img/logo/logo_with_name_blue.svg"
                                            alt="Logo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
