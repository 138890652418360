import React from "react";
import cx from "classnames";
import { Accordion, Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { useDrop } from "react-dnd";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import { NetworkFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import ribbonStyles from "../../ChartsRibbon.module.css";
import styles from "./NetworkSection.module.css";
import EditInput from "../EditInput";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import Variables, { VariableOption } from "common/Variables";
import {
    MapTooltipDisplayMode,
    MapTooltipDisplayModeOption,
} from "common/Canvas";

const options: Array<{
    label: string;
    value: MapTooltipDisplayMode | undefined;
}> = [
    { label: "click", value: MapTooltipDisplayMode.onClick },
    { label: "hover", value: MapTooltipDisplayMode.onHover },
    { label: "always", value: MapTooltipDisplayMode.always },
    { label: "never", value: undefined },
];

interface Props {
    columnDragActive: boolean;
    finding: NetworkFinding;
    onChange: (finding: NetworkFinding, updateData?: boolean) => void;
    currentModuleId?: number;
}

enum NetworkMenuOptions {
    None = 0,
    Popup = 1,
    Time = 2,
}

const nodeLabelPositionOptions: {
    label: string;
    value: "top" | "bottom" | "left" | "right";
}[] = [
    {
        label: "top",
        value: "top",
    },
    {
        label: "bottom",
        value: "bottom",
    },
    {
        label: "left",
        value: "left",
    },
    {
        label: "right",
        value: "right",
    },
];

export default function NetworkSection(props: Props) {
    const ref1 = React.useRef(null);
    const ref2 = React.useRef(null);
    const refWeight = React.useRef(null);
    const [collected1, drop1] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            let finding = {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    edgeSourceVariable: otherItem.content.variableName,
                    edgeSourceVariableIndex: otherItem.content.variableIndex,
                },
            };
            if (!finding.config.edgeTargetVariable) {
                finding.config.edgeTargetVariable = otherItem.content.variableName;
                finding.config.edgeTargetVariableIndex = otherItem.content.variableIndex;
            }
            props.onChange(finding, true);
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    const [collected2, drop2] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            let finding = {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    edgeTargetVariable: otherItem.content.variableName,
                    edgeTargetVariableIndex: otherItem.content.variableIndex,
                },
            };
            if (!finding.config.edgeSourceVariable) {
                finding.config.edgeSourceVariable = otherItem.content.variableName;
                finding.config.edgeSourceVariableIndex = otherItem.content.variableIndex;
            }
            props.onChange(finding, true);
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    const [collectedWeight, dropWeight] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            let finding = {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    edgeWeightVariable: otherItem.content.variableName,
                    edgeWeightVariableIndex: otherItem.content.variableIndex,
                },
            };
            props.onChange(finding, true);
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    drop1(ref1);
    drop2(ref2);
    dropWeight(refWeight);

    let [menuOptionSelected, setMenuOptionSelected] = React.useState(
        NetworkMenuOptions.None
    );

    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    let variableOptions: VariableOption[] = [];
    if (props.finding.config.dataScope != null) {
        variableOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
    }

    let data = props.finding.content.data;
    let edgeSourceVariable =
        props.finding.config.edgeSourceVariable ?? "Node 1";
    let edgeTargetVariable =
        props.finding.config.edgeTargetVariable ?? "Node 2";
    let edgeWeightVariable =
        props.finding.config.edgeWeightVariable ?? "Weight";
    let datasetIsConnected = props.finding.config.dataScope != null;
    let maxRowCount = 10;
    let restCount = data.length - maxRowCount;

    let metrics: ({
        name: string;
        variableIndex: number;
        originalName?: string;
    } | null)[] = [null];
    metrics = props.finding.config.metrics ?? [null];
    if (metrics.length === 0) {
        metrics = [null];
    }

    return (
        <div>
            <div
                style={{
                    position: "relative",
                    height:
                        48 +
                        24 *
                            (Math.min(data.length, maxRowCount) +
                                Number(datasetIsConnected && restCount > 0)),
                }}
            >
                <div className={styles.dataContainer}>
                    <div className={commonStyles.header}></div>
                    <div className={commonStyles.header}>
                        {edgeSourceVariable}
                    </div>
                    <div className={commonStyles.header}>
                        {edgeTargetVariable}
                    </div>
                    <div className={commonStyles.header}>
                        {edgeWeightVariable}
                    </div>
                    {(datasetIsConnected
                        ? data.slice(0, maxRowCount)
                        : data
                    ).map((_, index, array) => (
                        <React.Fragment key={index}>
                            <EditInput
                                showDeleteButton={
                                    props.finding.config.dataScope == null
                                }
                                disabled={true}
                                value={`Edge ${index + 1}`}
                                onDelete={() => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data.splice(index, 1);
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[index].source}
                                onChange={(value) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data[index] = {
                                        ...finding.content.data[index],
                                        source: Number(value),
                                    };
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[index].target}
                                onChange={(value) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data[index] = {
                                        ...finding.content.data[index],
                                        target: Number(value),
                                    };
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[index].weight}
                                onChange={(value) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data[index] = {
                                        ...finding.content.data[index],
                                        weight: Number(value),
                                    };
                                    props.onChange(finding);
                                }}
                            />
                        </React.Fragment>
                    ))}
                    {datasetIsConnected && restCount > 0 && (
                        <React.Fragment>
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={`+${restCount} values`}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div
                    className={styles.dataContainerOverlay}
                    style={{
                        display: props.columnDragActive ? "grid" : "none",
                        height:
                            48 +
                            24 *
                                (Math.min(data.length, maxRowCount) +
                                    Number(
                                        datasetIsConnected && restCount > 0
                                    )),
                    }}
                >
                    <div />
                    <div
                        ref={ref1}
                        style={{
                            opacity: 0.7,
                            width: "100%",
                            height: "100%",
                            borderStyle: "dashed",
                            borderWidth: 1,
                            borderColor: collected1.hover
                                ? "#36B743"
                                : "#8DB8E3",
                            display: "flex",
                            background: collected1.hover
                                ? "#F4FBF5"
                                : "#EBF2F9",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <span
                            className="no-selection"
                            style={{
                                backgroundColor: "transparent",
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                fontWeight: 500,
                                textAlign: "center",
                                color: "#333333",
                            }}
                        >
                            Drop
                        </span>
                    </div>
                    <div
                        ref={ref2}
                        style={{
                            opacity: 0.7,
                            width: "100%",
                            height: "100%",
                            borderStyle: "dashed",
                            borderWidth: 1,
                            borderColor: collected2.hover
                                ? "#36B743"
                                : "#8DB8E3",
                            display: "flex",
                            background: collected2.hover
                                ? "#F4FBF5"
                                : "#EBF2F9",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <span
                            className="no-selection"
                            style={{
                                backgroundColor: "transparent",
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                fontWeight: 500,
                                textAlign: "center",
                                color: "#333333",
                            }}
                        >
                            Drop
                        </span>
                    </div>
                    <div
                        ref={refWeight}
                        style={{
                            opacity: 0.7,
                            width: "100%",
                            height: "100%",
                            borderStyle: "dashed",
                            borderWidth: 1,
                            borderColor: collectedWeight.hover
                                ? "#36B743"
                                : "#8DB8E3",
                            display: "flex",
                            background: collectedWeight.hover
                                ? "#F4FBF5"
                                : "#EBF2F9",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <span
                            className="no-selection"
                            style={{
                                backgroundColor: "transparent",
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                fontWeight: 500,
                                textAlign: "center",
                                color: "#333333",
                            }}
                        >
                            Drop
                        </span>
                    </div>
                </div>
            </div>
            {props.finding.config.dataScope == null && (
                <Button
                    style={{ marginTop: 10 }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let finding: NetworkFinding = {
                            ...props.finding!,
                            content: {
                                ...props.finding!.content,
                                data: Array.from(props.finding!.content.data),
                            },
                        };
                        finding.content.data.push({
                            source: 1,
                            target: 2,
                            weight: 1,
                        });
                        props.onChange(finding);
                    }}
                >
                    {"\uFF0B Add Item"}
                </Button>
            )}
            <div style={{ marginTop: 10 }}>
                <Accordion
                    activeKey={String(menuOptionSelected)}
                    onSelect={(event: any) => {
                        setMenuOptionSelected(Number(event));
                    }}
                >
                    <Accordion.Toggle
                        eventKey={String(NetworkMenuOptions.Popup)}
                        className={ribbonStyles.editMenuAdvancedOption}
                    >
                        Node Labels
                        <ChevronIcon
                            className={cx(
                                ribbonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [ribbonStyles.chevronOpen]:
                                        menuOptionSelected ===
                                        NetworkMenuOptions.Popup,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(NetworkMenuOptions.Popup)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 10,
                            }}
                        >
                            <span className={styles.title}>Show label on</span>
                            <Select
                                isClearable
                                options={options}
                                value={options.find(
                                    (option) =>
                                        option.value ===
                                        props.finding.config
                                            .nodeLabelsDisplayMode
                                )}
                                isSearchable={false}
                                onChange={(newValue) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        config: {
                                            ...props.finding!.config,
                                            nodeLabelsDisplayMode: (newValue as MapTooltipDisplayModeOption)
                                                .value,
                                        },
                                    };
                                    props.onChange(finding);
                                }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: 60,
                                        marginLeft: 5,
                                        marginRight: 5,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: 25,
                                    }),
                                    indicatorsContainer: (_provided) => ({
                                        display: "none",
                                    }),
                                }}
                            />
                            <span className={styles.title}>on</span>
                            <Select
                                isClearable
                                options={nodeLabelPositionOptions}
                                value={nodeLabelPositionOptions.find(
                                    (option) =>
                                        option.value ===
                                            props.finding.config
                                                .nodeLabelsDisplayPosition ??
                                        nodeLabelPositionOptions[0]
                                )}
                                isSearchable={false}
                                onChange={(newValue) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        config: {
                                            ...props.finding!.config,
                                            nodeLabelsDisplayPosition: (newValue as typeof nodeLabelPositionOptions[0])
                                                .value,
                                        },
                                    };
                                    props.onChange(finding);
                                }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: 60,
                                        marginLeft: 5,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: 25,
                                    }),
                                    indicatorsContainer: (_provided) => ({
                                        display: "none",
                                    }),
                                }}
                            />
                        </div>
                    </Accordion.Collapse>
                </Accordion>
            </div>
            <div style={{ marginTop: 10 }}>
                <Accordion
                    activeKey={String(menuOptionSelected)}
                    onSelect={(event: any) => {
                        setMenuOptionSelected(Number(event));
                    }}
                >
                    <Accordion.Toggle
                        eventKey={String(NetworkMenuOptions.Time)}
                        className={ribbonStyles.editMenuAdvancedOption}
                    >
                        Animation
                        <ChevronIcon
                            className={cx(
                                ribbonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [ribbonStyles.chevronOpen]:
                                        menuOptionSelected ===
                                        NetworkMenuOptions.Time,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(NetworkMenuOptions.Time)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Select
                                isClearable
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder="Select variable"
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                        height: "38px",
                                        marginTop: "8px",
                                    }),
                                }}
                                options={variableOptions}
                                onChange={(newValue) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        config: {
                                            ...props.finding!.config,
                                        },
                                    };
                                    if (newValue == null) {
                                        finding.config.timeVariable = null;
                                        finding.config.timeVariableIndex = null;
                                    } else {
                                        finding.config.timeVariable = (newValue as VariableOption).label;
                                        finding.config.timeVariableIndex = (newValue as VariableOption).value;
                                    }
                                    props.onChange(finding, true);
                                }}
                                value={
                                    props.finding.config.timeVariableIndex !=
                                    null
                                        ? {
                                              label:
                                                  props.finding.config
                                                      .timeVariable,
                                              value:
                                                  props.finding.config
                                                      .timeVariableIndex,
                                          }
                                        : null
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                    </Accordion.Collapse>
                </Accordion>
            </div>
        </div>
    );
}
