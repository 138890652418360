enum CleanOperation {
    Uppercase = 1,
    Lowercase = 2,
    Capitalize = 3,
    SplitByDelimiter = 4,
    AddPrefix = 5,
    AddSuffix = 6,
}

export interface options {
    label: string,
    value: number
}

export type CleanOption = {
    label: string;
    value: CleanOperation;
};

export const cleanOptions: ReadonlyArray<CleanOption> = [
    {
        label: "Uppercase",
        value: CleanOperation.Uppercase,
    },
    {
        label: "Lowercase",
        value: CleanOperation.Lowercase,
    },
    {
        label: "Capitalize",
        value: CleanOperation.Capitalize,
    },
    {
        label: "Split By Delimiter",
        value: CleanOperation.SplitByDelimiter,
    },
    {
        label: "Add Prefix",
        value: CleanOperation.AddPrefix,
    },
    {
        label: "Add Suffix",
        value: CleanOperation.AddSuffix,
    },
];

export const delimeters: ReadonlyArray<options> = [
    {
        label: ",",
        value: 1,
    },
    {
        label: ".",
        value: 2,
    },
    {
        label: "/",
        value: 3,
    },
    {
        label: "<",
        value: 4,
    },
    {
        label: ">",
        value: 5,
    },
    {
        label: "Other",
        value: 6,
    },
]


const uppercaseSample = (variable: string) =>
    `data['${variable}'] = data['${variable}'].str.upper()\n`;

const lowercaseSample = (variable: string) =>
    `data['${variable}'] = data['${variable}'].str.lower()\n`;
const capitalizeSample = (variable: string) =>
    `data['${variable}'] = data['${variable}'].str.capitalize()\n`;
const addPrefixSample = (variable: string, prefix: string) =>
    `data['${variable}'] = '${prefix}' + data['${variable}']\n`;
const addSuffixSample = (variable: string, suffix: string) =>
    `data['${variable}'] = data['${variable}'] + '${suffix}'\n`;
const splitByDelimiterSample = (variable: string, delimiter: string) =>
    `data['${variable}'] = data['${variable}'].str.split('${delimiter}').str.join(' ')\n`;

export const presets = {
    [CleanOperation.Lowercase]: lowercaseSample,
    [CleanOperation.Uppercase]: uppercaseSample,
    [CleanOperation.AddPrefix]: addPrefixSample,
    [CleanOperation.AddSuffix]: addSuffixSample,
    [CleanOperation.SplitByDelimiter]: splitByDelimiterSample,
    [CleanOperation.Capitalize]: capitalizeSample,
};
