import { ContentState } from "draft-js";

export default function getSelectedBlocks(
    contentState: ContentState,
    anchorKey: string,
    focusKey: string,
    anchorOffset: number,
    focusOffset: number,
    isBackward: boolean
) {
    if (isBackward) {
        [anchorKey, focusKey] = [focusKey, anchorKey];
        [anchorOffset, focusOffset] = [focusOffset, anchorOffset];
    }
    const isSameBlock = anchorKey === focusKey;
    const startingBlock = contentState.getBlockForKey(anchorKey);
    const selectedBlocks = [startingBlock];

    if (!isSameBlock) {
        let blockKey = anchorKey;

        while (blockKey !== focusKey) {
            const nextBlock = contentState.getBlockAfter(blockKey);
            if (nextBlock == null) break;
            blockKey = nextBlock.getKey();
            if (blockKey !== focusKey || focusOffset > 0)
                selectedBlocks.push(nextBlock);
        }
    }

    return selectedBlocks;
}
