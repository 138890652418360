import axios from "common/ServerConnection";
import { Notification } from "./Notifications";

export async function getCanvasNotifications(
	offset?: number,
	limit?: number
): Promise<Notification[]> {
	let requestJson = {
		offset: offset,
		limit: limit,
	};
	return axios
		.post<{
			success: boolean;
			error_msg: string;
			mentions?: Notification[];
		}>("/api/canvas_get_mentions", requestJson)
		.then((response) => {
			if (response.data.success && response.data.mentions != null) {
				return Promise.resolve(response.data.mentions);
			} else {
				return Promise.reject(response.data.error_msg);
			}
		})
		.catch((error) => {
			console.log(error);
			return Promise.reject(error);
		});
}
