import React from "react";
import {useEffect, useState} from "react";
import { CustomMessageProps } from "./ChatbotTypes";
import CursorSVG from "./components/icons/CursorSVG";


import { defaultAiCopilotBotMsgBgColor } from "common/Finding";

const BotIcon = () => {
    return (
        <div
            style={{
                width: 32,
                height: 32,
                minWidth: 32,
                minHeight: 32,
                backgroundColor: "#3B82C9",
                borderRadius: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                font: "Roboto",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
            }}
        >
            <img alt="" src="logo_chatbot.png" />
        </div>
    );
};

const CustomBotMessage = (props: CustomMessageProps): JSX.Element => {
    const message = props.payload?.message ?? "";
    const chatHistory = props.state.messages
    const [displayResponse, setDisplayResponse] = useState("");
    const [completedTyping, setCompletedTyping] = useState(false);

    useEffect(() => {
        if (!chatHistory?.length) {
          return;
        }
    
        setCompletedTyping(false);
    
        let i = 0;
        const stringResponse = message;
        

        const intervalId = setInterval(() => {
          setDisplayResponse(stringResponse.slice(0, i));
    
          i++;
    
          if (i > stringResponse.length) {
            clearInterval(intervalId);
            setCompletedTyping(true);
          }
        }, 20);
    
        return () => clearInterval(intervalId);
      }, [message]);



    return (
        <div
            style={{
                minHeight: 83,
                paddingLeft: 29,
                paddingRight: 29,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: props.finding.config.botMsgBgColor ?? defaultAiCopilotBotMsgBgColor,
                borderWidth: "1px 0px",
                borderStyle: "solid",
                borderColor: "rgba(164, 198, 229, 0.5)",
            }}
        >
            <div
                style={{
                    alignSelf: "flex-start",
                    marginTop: 26,
                }}
            >
                <BotIcon />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 21,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <span
                    style={{
                        color: "#333333",
                    }}
                >
                    {displayResponse}
                    {displayResponse === "" && <CursorSVG/>}
                    {!completedTyping && <CursorSVG />}
                </span>
            </div>
        </div>
    );
};

export default CustomBotMessage;
