import React, { Component } from "react";
import { Navbar, Button } from "react-bootstrap";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import { AppModuleProps } from "AppModule";
import MessagePopup from "common/MessagePopup";
import ScaledPage from "common/ScaledPage";
import {
    Token,
    API,
    getTokenListApi,
    getApiListApi,
    getAuthorizationUrlApi,
    postAPIConnection,
    completeAuthorizationApi,
    deleteTokenApi,
    deleteApi
} from "./api";
import { goToInternalLink } from "common/InternalLinksHelper";
import StatusPopup, { PopupStatus } from "common/StatusPopup";

interface State {
    status: PopupStatus | null;
    message: string;
    tokenList: Token[];
    apiList: API[];
    stripeConnected: boolean;
    reconnectPopupOpen: boolean;
    apiPopupOpen: boolean;
    currentApi: API;
}

class MainComponent extends Component<AppModuleProps, State> {
    constructor(props: AppModuleProps) {
        super(props);
        this.state = {
            status: null,
            message: "",
            tokenList: [],
            apiList: [],
            stripeConnected: false,
            reconnectPopupOpen: false,
            apiPopupOpen: false,
            currentApi: Object()
        };
        this.listTokens = this.listTokens.bind(this);
    }

    public componentDidMount(): void {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("code") || urlParams.has("state")) {
            completeAuthorizationApi(window.location.href)
                .then(() => {
                    goToInternalLink("connected_accounts.html");
                })
                .catch((message) => {
                    console.log(message);
                    this.setState(
                        {
                            status: PopupStatus.Error,
                            message: message.toString(),
                        },
                        () => {
                            setTimeout(() => {
                                goToInternalLink("connected_accounts.html");
                            }, 3000);
                        }
                    );
                });
        } else {
            this.listTokens();
        }
    }

    private listTokens(): void {
        getTokenListApi()
            .then((tokenList) => {
                this.setState({ tokenList: tokenList });
            })
            .catch((message) => {
                console.log(message);
                this.setState({
                    status: PopupStatus.Error,
                    message: message.toString(),
                });
            });
        getApiListApi()
            .then((apiList) => {
                this.setState({ apiList: apiList });
            })
            .catch((message) => {
                console.log(message);
                this.setState({
                    status: PopupStatus.Error,
                    message: message.toString(),
                });
            });
    }

    private renderTokens(): JSX.Element {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "min-content",
                }}
            >
                {this.state.tokenList.map((token, index) => (
                    <>
                        {index !== 0 && 
                            <div
                                style={{
                                    width: "100%",
                                    height: "1px",
                                    backgroundColor: "#d1d1d1",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                        }
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <span
                                className="regular-text"
                                style={{ width: "30em", flex: 1 }}
                            >
                                {token.display_name}
                            </span>
                            {token.connected && (
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    style={{
                                        marginLeft: 5,
                                        width: "8em",
                                        backgroundColor: '#3b82c9',
                                        fontSize: 14,
                                    }}
                                    onClick={() => {
                                        deleteTokenApi(token.name)
                                            .then(() => {
                                                this.setState((state) => {
                                                    let tokenList = Array.from(
                                                        state.tokenList
                                                    );
                                                    tokenList[index] = {
                                                        ...tokenList[index],
                                                        connected: false,
                                                    };
                                                    return {
                                                        tokenList: tokenList,
                                                    };
                                                });
                                            })
                                            .catch((message) => {
                                                console.log(message);
                                                this.setState({
                                                    status: PopupStatus.Error,
                                                    message: message.toString(),
                                                });
                                            });
                                    }}
                                >
                                    Disconnect
                                </Button>
                            )}
                            {!token.connected && (
                                <div style={{ marginLeft: 5, width: "8em" }} />
                            )}
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: 30,
                                    width: "8em",
                                    backgroundColor: '#3b82c9',
                                    fontSize: 14,
                                }}
                                onClick={() => {
                                    getAuthorizationUrlApi(token.name)
                                        .then((uri) => {
                                            window.location.href = uri;
                                        })
                                        .catch((error) => {
                                            this.setState({
                                                status: PopupStatus.Error,
                                                message: String(error),
                                            });
                                        });
                                }}
                            >
                                {token.connected ? "Reconnect" : "Connect"}
                            </Button>
                        </div>
                    </>
                ))}
                {this.state.apiList.map((api, index) => (
                    <>
                        <div
                            style={{
                                width: "100%",
                                height: "1px",
                                backgroundColor: '#d1d1d1',
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <span
                                className="regular-text"
                                style={{ width: "30em", flex: 1 }}
                            >
                                {api.display_name}
                            </span>
                            {api.connected && (
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    style={{
                                        marginLeft: 5,
                                        width: "8em",
                                        backgroundColor: '#3b82c9',
                                        fontSize: 14,
                                    }}
                                    onClick={() => {
                                        deleteApi(api.key)
                                            .then(() => {
                                                this.setState((state) => {
                                                    let apiList = Array.from(
                                                        state.apiList
                                                    );
                                                    apiList[index] = {
                                                        ...apiList[index],
                                                        connected: false,
                                                    };
                                                    return {
                                                        apiList: apiList,
                                                    };
                                                });
                                            })
                                            .catch((message) => {
                                                console.log(message);
                                                this.setState({
                                                    status: PopupStatus.Error,
                                                    message: message.toString(),
                                                });
                                            });
                                    }}
                                >
                                    Disconnect
                                </Button>
                            )}
                            {!api.connected && (
                                <div style={{ marginLeft: 5, width: "8em" }} />
                            )}
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: 30,
                                    width: "8em",
                                    backgroundColor: '#3b82c9',
                                    fontSize: 14,
                                }}
                                onClick={() => {
                                    this.setState({
                                        apiPopupOpen: true,
                                        currentApi: api
                                    });
                                }}
                            >
                                {api.connected ? "Reconnect" : "Connect"}
                            </Button>
                        </div>
                    </>
                ))}
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <ScaledPage>
                <div className="content-wrapper hide-scroll">
                    <section className="content-header">
                        <h3>Connected Accounts</h3>
                    </section>
                    <section
                        className="content"
                        style={this.props.positionStyle}
                    >
                        <Navbar className="bg-light justify-content-between">
                            {this.renderTokens()}
                            {this.state.status != null && (
                                <StatusPopup
                                    status={this.state.status}
                                    message={this.state.message}
                                    onClose={() => {
                                        this.setState({
                                            status: null,
                                            message: "",
                                        });
                                    }}
                                />
                            )}
                            {this.state.apiPopupOpen && (
                                <MessagePopup
                                    fields={this.state.currentApi.fields}
                                    title={"Set API Details"}
                                    danger
                                    message={`Please set API values below. These values can be found by logging into ${this.state.currentApi?.key} and visintg your account page.`}
                                    acceptButtonTitle={"(re)Connect to API"}
                                    onAccept={(apiKeys = {}) => {
                                        const obj = JSON.parse(JSON.stringify(apiKeys))
                                        if (this.state.currentApi?.key === "qualtrics") {
                                            obj["token_name"] = "qualtrics";
                                        }
                                        postAPIConnection(obj)
                                            .then((valid?) => {
                                                this.setState({
                                                    apiPopupOpen: false,
                                                });
                                                this.listTokens();
                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    status: PopupStatus.Error,
                                                    message: String(error),
                                                });
                                            });
                                    }}
                                    onReject={() => {
                                        this.setState({
                                            apiPopupOpen: false,
                                        });
                                    }}
                                />
                            )}
                        </Navbar>
                    </section>
                </div>
            </ScaledPage>
        );
    }
}

export { MainComponent };
export let route = "/connected_accounts.html";
