import React, { Component } from "react";
import { calcScale } from "common/utilities/UIResponsiveManager";

class ScaledPage extends Component {
    constructor(props: {}) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    private updateDimensions(): void {
        calcScale(false);
    }

    componentDidMount(): void {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        return React.Children.only(this.props.children);
    }
}

export default ScaledPage;
