import React from "react";
import styles from "./BottomBar.module.css";
import cx from "classnames";
import mobileBreakpoint, {
    isFullScreen,
    toggleFullScreen,
} from "common/utilities/UIResponsiveManager";
import { ReactComponent as Logo } from "icons/logo/logo.svg";

interface Props {
    time: string;
    live: boolean;
    viewCount?: string;
}

export default function BottomBar(props: Props) {
    let left = !props.live ? 125 : 95;
    const isMobile = mobileBreakpoint();
    const logoSize = !isMobile ? 40 : 8;
    const expanderSize = !isMobile ? 40 : 16;
    return (
        <>
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    position: "absolute",
                    zIndex: 1,
                    bottom: 20,
                    left: left,
                }}
            >
                {!props.live && (
                    <>
                        <div
                            className="flex-simple-column"
                            style={{
                                marginLeft: !props.live ? 5 : 15,
                            }}
                        >
                            <span className={cx(styles.text, "unselectable")}>
                                {props.time}
                            </span>
                            {props.viewCount && (
                                <span
                                    className={cx(styles.text, "unselectable")}
                                    style={{
                                        marginTop: "5px",
                                    }}
                                >
                                    {props.viewCount}
                                </span>
                            )}
                        </div>
                    </>    
                )}
                <div style={{ flexGrow: 1 }} />
                {props.live && (
                    <>
                    <div
                            className={styles.watermark}
                            style={{
                                background: "transparent",
                                right: 5,
                                bottom: 0,
                                border: "none",
                                width: "initial",
                            }}
                        >
                            <Logo
                                className={styles.liveModeLogo}
                                style={{
                                    width: logoSize,
                                    height: logoSize,
                                }}
                            />
                            <h3
                                className={styles.liveModeTitle}
                                style={{
                                    fontSize: !isMobile ? 20 : 4,
                                    letterSpacing: !isMobile ? 12 : 1,
                                    marginLeft: !isMobile ? 10 : 2,
                                }}
                            >
                                EISENGARD AI
                            </h3>
                            <button
                                onClick={toggleFullScreen}
                                className={
                                    isFullScreen()
                                        ? cx(
                                            styles.fullScreenButton,
                                            styles.fullScreenActive
                                        )
                                        : cx(
                                            styles.fullScreenButton,
                                            styles.fullScreenNotActive
                                        )
                                }
                                aria-label="Toggle full screen"
                                style={{
                                    marginRight: !isMobile ? 20 : 10,
                                    width: expanderSize,
                                    height: expanderSize,
                                    padding: "0 2px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <svg viewBox="0 0 512 512">
                                    <path
                                        fill={"#CECECE"}
                                        d={
                                            isFullScreen()
                                                ? "M64 371.2h76.795V448H192V320H64v51.2zm76.795-230.4H64V192h128V64h-51.205v76.8zM320 448h51.2v-76.8H448V320H320v128zm51.2-307.2V64H320v128h128v-51.2h-76.8z"
                                                : "M396.795 396.8H320V448h128V320h-51.205zM396.8 115.205V192H448V64H320v51.205zM115.205 115.2H192V64H64v128h51.205zM115.2 396.795V320H64v128h128v-51.205z"
                                        }
                                    />
                                </svg>
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
