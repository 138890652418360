import React from "react";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import { CanvasNode, CanvasRadioButtonsGroup } from "common/Canvas";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Variables from "common/Variables";
import Tables from "common/Tables";
import selectStyles from "../../common/SelectStyles";
import remoteModuleId from "common/remoteModuleId";

import styles from "./Settings.module.css";


interface Props {
  node: CanvasRadioButtonsGroup;
  currentModuleId: number | undefined;
  onChange: (changes: Partial<CanvasRadioButtonsGroup | CanvasNode>, commit?: boolean) => void;
}

function Settings({ node, currentModuleId, onChange }: Props) {
	let dataScopes =
            currentModuleId != null
                ? DataScopesForModules(currentModuleId)
                : DataScopes;

	const variableOptions = Variables(
		node?.dataScopeOption?.value,
		currentModuleId ?? remoteModuleId
	).dataVariables.map((variable) => ({
		label: variable.name,
		value: variable.index ?? -1,
		type: variable.type,
		panel: variable.panel,
	}))

  return (
    <div className={styles.root}>
		<div className={styles.field}>
			<span className={styles.label}>Dataset</span>
			<Select
				filterOption={createFilter({
					ignoreAccents: false,
				})}
				placeholder={"Select dataset"}
				styles={selectStyles}
				options={dataScopes.dataScopesOptions}
				onChange={(newValue) => {
					onChange({
						dataScopeOption: newValue as DataScopeOption,
						tableOption: Tables(
							(
								newValue as DataScopeOption
							).value,
							currentModuleId ?? remoteModuleId
						).tableToOption(),
						variableOption: null,
						columns: undefined,
						imageVariableOption: null,
						metric: "",
						value: NaN,
					})
				}}
				value={node.dataScopeOption}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						text: "white",
						primary25:
							"var(--selectors-background-hover-color)",
					},
				})}
			/>
		</div>
		{node.dataScopeOption != null && (
			<>
				<div className={styles.field}>
					<span className={styles.label}>Label</span>
					<Select
						filterOption={createFilter({
							ignoreAccents: false,
						})}
						placeholder={""}
                        styles={selectStyles}
						options={variableOptions}
						onChange={async (column) => {
							onChange({
								...node,
								variableOption: column,
								columns: {
									...(node?.columns ?? {}),
									label: column,
								},
								metric: "",
								value: NaN,
							} as CanvasRadioButtonsGroup)
						}}
						value={
							node.variableOption
						}
						theme={(theme) => ({
							...theme,
							borderRadius: 0,
							colors: {
								...theme.colors,
								text: "white",
								primary25:
									"var(--selectors-background-hover-color)",
							},
						})}
					/>
				</div>
				<div className={styles.field}>
					<span className={styles.label}>Icon</span>
					<Select
						filterOption={createFilter({
							ignoreAccents: false,
						})}
						placeholder={""}
                        styles={selectStyles}
						options={variableOptions}
						onChange={async (column) => {
							onChange({
								...node,
								imageVariableOption: column,
								columns: {
									...(node?.columns ?? {}),
									icon: column,
								},
								metric: "",
								value: NaN,
							} as CanvasRadioButtonsGroup)
						}}
						value={
							node.imageVariableOption
						}
						theme={(theme) => ({
							...theme,
							borderRadius: 0,
							colors: {
								...theme.colors,
								text: "white",
								primary25:
									"var(--selectors-background-hover-color)",
							},
						})}
					/>
				</div>
			</>
		)}
    </div>
  );
}

export default observer(Settings);
