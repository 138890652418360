import React from "react";
import { Observer } from "mobx-react";

export interface GlobalContextContents {
    permissions: { [key: string]: { display_name?: string, access_type: number } };
    ShowTabSwitchConfermation?:boolean;
}

interface ObserverConsumerProps {
    children: (globalContext: GlobalContextContents) => any;
}

interface GlobalContextType extends React.Context<GlobalContextContents> {
    ObserverConsumer: (props: ObserverConsumerProps) => JSX.Element;
}

const GlobalContext: GlobalContextType = React.createContext<GlobalContextContents>({
    permissions: {},
}) as GlobalContextType;

GlobalContext.ObserverConsumer = (props: ObserverConsumerProps) => {
    return (
        <GlobalContext.Consumer>
            {(globalContext) => (
                <Observer>
                    {() => props.children(globalContext)}
                </Observer>
            )}
        </GlobalContext.Consumer>
    );
};

export default GlobalContext;
