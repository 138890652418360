import React from "react";
import {
    NotificationExpression,
    getDefaultNotificationExpression,
} from "common/Canvas";
import { Button } from "react-bootstrap";
import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import StatusSubExpressionSelector from "common/StatusSubExpressionsSelector";
import UserInfo from "common/UserInfo";
import UsersSelector from "./UsersSelector";

interface Props {
    neighborsUsers: UserInfo[];
    nodeLinkOptions: NodeLinkOption[];
    notificationExpressions: NotificationExpression[];
    node: CanvasElement | CanvasTextBox;
    onChange: (changes: NotificationExpression[]) => void;
}

export default class NotificationSelector extends React.Component<Props> {
    render() {
        return (
            <div className="flex-simple-column">
                {this.props.notificationExpressions.map(
                    (notificationExpression, expressionIndex) => (
                        <div
                            className="my-row"
                            key={expressionIndex}
                            style={{
                                marginLeft: "10px",
                            }}
                        >
                            <UsersSelector
                                neighborsUsers={this.props.neighborsUsers}
                                users={notificationExpression.users}
                                onChange={(users) => {
                                    let notificationExpressions = Array.from(
                                        this.props.notificationExpressions
                                    );
                                    notificationExpressions[
                                        expressionIndex
                                    ].users = users;
                                    notificationExpressions[
                                        expressionIndex
                                    ].lastNotificationExpression = null;
                                    this.props.onChange(
                                        notificationExpressions
                                    );
                                }}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: "5px" }}
                            >
                                <input
                                    className="like-select"
                                    style={{
                                        resize: "none",
                                    }}
                                    placeholder="Enter title"
                                    value={notificationExpression.title}
                                    onChange={(evt) => {
                                        let notificationExpressions = Array.from(
                                            this.props.notificationExpressions
                                        );
                                        notificationExpressions[
                                            expressionIndex
                                        ].title = evt.currentTarget.value;
                                        notificationExpressions[
                                            expressionIndex
                                        ].lastNotificationExpression = null;
                                        this.props.onChange(
                                            notificationExpressions
                                        );
                                    }}
                                />
                                <textarea
                                    className="like-select"
                                    style={{
                                        marginTop: "5px",
                                        marginBottom: "5px",
                                        resize: "none",
                                    }}
                                    placeholder="Enter body"
                                    value={notificationExpression.message}
                                    onChange={(evt) => {
                                        let notificationExpressions = Array.from(
                                            this.props.notificationExpressions
                                        );
                                        notificationExpressions[
                                            expressionIndex
                                        ].message = evt.currentTarget.value;
                                        notificationExpressions[
                                            expressionIndex
                                        ].lastNotificationExpression = null;
                                        this.props.onChange(
                                            notificationExpressions
                                        );
                                    }}
                                />
                            </div>
                            <StatusSubExpressionSelector
                                node={this.props.node}
                                nodeLinkOptions={this.props.nodeLinkOptions}
                                subexpressions={
                                    notificationExpression.subexpressions
                                }
                                onChange={(subexpressions) => {
                                    let notificationExpressions = Array.from(
                                        this.props.notificationExpressions
                                    );
                                    notificationExpressions[
                                        expressionIndex
                                    ].subexpressions = subexpressions;
                                    notificationExpressions[
                                        expressionIndex
                                    ].lastNotificationExpression = null;
                                    this.props.onChange(
                                        notificationExpressions
                                    );
                                }}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 20 }}
                            >
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let notificationExpressions = Array.from(
                                            this.props.notificationExpressions
                                        );
                                        notificationExpressions.push(
                                            getDefaultNotificationExpression()
                                        );

                                        this.props.onChange(
                                            notificationExpressions
                                        );
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let notificationExpressions = Array.from(
                                            this.props.notificationExpressions
                                        );
                                        notificationExpressions.splice(
                                            expressionIndex,
                                            1
                                        );
                                        if (
                                            notificationExpressions.length === 0
                                        ) {
                                            notificationExpressions.push(
                                                getDefaultNotificationExpression()
                                            );
                                        }

                                        this.props.onChange(
                                            notificationExpressions
                                        );
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}