import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Element } from "react-scroll";
import { mainStyle } from "common/MainStyle";

interface Props {
	rows: { [key: string]: string | JSX.Element }[];
	columns: {
		label: string;
		field: string;
		minimal?: string;
	}[];
}

function RunRegressionTable(props: Props) {
	return (
		<div
			className="flex-simple-column"
			style={{ marginLeft: 20, width: "100%" }}
		>
			<div
				style={{
					width: "100%",
					height: 1,
					backgroundColor: "#20293c",
				}}
			/>
			<div className="my-row" style={{ marginTop: 10, marginBottom: 10 }}>
				<span
					className="text-center"
					style={{
						color: mainStyle.getPropertyValue(
							"--exploration-primary-text-color"
						),
						fontFamily: "Arial",
						fontSize: "12px",
					}}
				>
					Independent Variable
				</span>

				<span
					className="text-center"
					style={{
						marginLeft: 30,
						color: mainStyle.getPropertyValue(
							"--exploration-tertiary-text-color"
						),
						fontFamily: "Arial",
						fontSize: "12px",
					}}
				>
					Specify independent variables to explore marketing-mix
					specifications
				</span>
			</div>
			<Element
				name="scrollable"
				className="element"
				style={{
					overflowX: "hidden",
					backgroundColor: "transparent",
					position: "relative",
					height: 220,
					width: "100%",
					overflowY: "hidden",
				}}
			>
				<MDBTable
					style={{ wordBreak: "break-word" }}
					className="customized-table customized-link-table no-border scrollable-body-table"
				>
					<MDBTableHead columns={props.columns} />
					<MDBTableBody rows={props.rows} />
				</MDBTable>
			</Element>
			<div
				style={{
					marginTop: 10,
					width: "100%",
					height: 1,
					backgroundColor: "#20293c",
				}}
			/>
		</div>
	);
}

export default RunRegressionTable;