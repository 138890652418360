import React, { Component } from "react";
import { Button } from "react-bootstrap";

import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import { setGeodataKeyApi, checkGeodataKeyApi } from "./api";

enum KeyCheckStatus {
    NotChecked = 0,
    KeyExists = 1,
    KeyDoesNotExist = 2,
    ChangingKeys = 3,
}

interface State {
    status: UpdateStatus;
    errorMessage: string;
    keyCheckStatus: KeyCheckStatus;
    secretKey: string;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            keyCheckStatus: KeyCheckStatus.NotChecked,
            secretKey: "",
        };
        this.performance = null;
        this.setKeys = this.setKeys.bind(this);
    }

    public componentDidMount(): void {
        checkGeodataKeyApi()
            .then((keyExists) => {
                this.setState({
                    keyCheckStatus: keyExists
                        ? KeyCheckStatus.KeyExists
                        : KeyCheckStatus.KeyDoesNotExist,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    keyCheckStatus: KeyCheckStatus.ChangingKeys,
                    status: UpdateStatus.Error,
                    errorMessage: error.toString(),
                });
            });
    }

    public componentDidUpdate(): void {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    private setKeys(): void {
        this.performance = new Date();
        if (
            this.state.secretKey.length === 0
        ) {
            this.setState({
                status: UpdateStatus.Error,
                errorMessage: "Please enter the key",
            });
        } else {
            setGeodataKeyApi(this.state.secretKey)
                .then(() => {
                    this.setState({
                        keyCheckStatus: KeyCheckStatus.KeyExists,
                        status: UpdateStatus.Success,
                        errorMessage: "",
                        secretKey: "",
                    });
                })
                .catch((error) => {
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: error.toString(),
                    });
                });
        }
    }

    public render(): JSX.Element {
        return (
            <div style={{ marginTop: 20 }}>
                <span className="big-title-span">Google API key</span>
                <div
                    style={{ marginTop: 10, width: 500 }}
                    className="flex-simple-column"
                >
                    {this.state.keyCheckStatus === KeyCheckStatus.KeyExists && (
                        <>
                            <span
                                className="regular-text"
                                style={{
                                    marginLeft: 19,
                                    marginBottom: 10,
                                }}
                            >
                                Google API key is set
                            </span>
                            <Button
                                type="button"
                                className="btn btn-lg btn-primary my-primary"
                                style={{
                                    marginLeft: 19,
                                    marginBottom: 10,
                                    width: 200,
                                }}
                                onClick={() => {
                                    this.setState({
                                        keyCheckStatus:
                                            KeyCheckStatus.ChangingKeys,
                                    });
                                }}
                            >
                                Change
                            </Button>
                        </>
                    )}
                    {this.state.keyCheckStatus ===
                        KeyCheckStatus.KeyDoesNotExist && (
                        <span
                            className="regular-text"
                            style={{
                                marginLeft: 19,
                                marginBottom: 10,
                            }}
                        >
                            Google API keys is NOT set
                        </span>
                    )}
                    {(this.state.keyCheckStatus ===
                        KeyCheckStatus.KeyDoesNotExist ||
                        this.state.keyCheckStatus ===
                            KeyCheckStatus.ChangingKeys) && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingLeft: 15,
                                    marginLeft: 19,
                                    marginBottom: 10,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: 500,
                                }}
                            >
                                <div className="regular-text">Key</div>
                                <input
                                    className="like-select"
                                    style={{
                                        width: 400,
                                    }}
                                    value={this.state.secretKey}
                                    placeholder="NONE"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        this.setState({
                                            secretKey: value,
                                        });
                                    }}
                                />
                            </div>
                            <Button
                                type="button"
                                className="btn btn-lg btn-primary my-primary"
                                style={{
                                    marginLeft: "19px",
                                    marginBottom: 10,
                                    width: 200,
                                }}
                                onClick={this.setKeys}
                            >
                                Set
                            </Button>
                        </>
                    )}
                    <UpdateStatusAlert
                        value={this.state.status}
                        onChange={(status) => {
                            this.setState({ status: status });
                        }}
                        errorMessage={this.state.errorMessage}
                    />
                </div>
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "ChangeGeodataAPIKeys";
