import { observable, computed, makeObservable, action } from "mobx";
import LeftSideSection, { Option } from "./LeftSideSection";




class SelectedOption {
    seletedOption: Option = Option.Import;
    option: Option = 0;
    canSwitchTab: boolean = true;
    askSwitchTab: boolean = false;
    showOptions: boolean = false;

    constructor() {
        makeObservable(this, {
            showOptions: observable,
            seletedOption: observable,
            option: observable,
            canSwitchTab: observable,
            askSwitchTab: observable,
            getshowOptions: computed,
            seletedOptionValue: computed,
            getSwitchTab: computed,
            getAskSwitchTab: computed,
            clickedOption: computed,
            changeshowOptions: action,
            setSelectedOption: action,
            UpdateSwitchTab: action,
            UpdateaskSwitchTab: action,
            UpdateOption: action,
        });
    }
    get clickedOption() {
        return this.option
    }

    get getshowOptions() {
        return this.showOptions
    }

    get seletedOptionValue() {
        return this.seletedOption;
    }

    get getSwitchTab() {
        return this.canSwitchTab;
    }
    get getAskSwitchTab() {
        return this.askSwitchTab;
    }
    changeshowOptions(status: boolean) {
        this.showOptions = status;
    }

    UpdateSwitchTab(status: boolean) {
        this.canSwitchTab = status;
    }

    UpdateaskSwitchTab(status: boolean) {
        this.askSwitchTab = status;
    }

    UpdateOption(option: Option) {
        this.option = option;
    }

    setSelectedOption() {
        this.seletedOption = this.option!;
    }


}

export default new SelectedOption();