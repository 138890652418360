import React from "react";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";
import { BarChartTheme } from "common/graphics/BarChartTheme";

export interface BarChartThemeOption {
	label: string;
	value: BarChartTheme;
}
export const Options: BarChartThemeOption[] = [
	{
		label: "Default",
		value: BarChartTheme.Default,
	},
	{
		label: "Monochrome",
		value: BarChartTheme.Monochrome,
	},
	// {
	// 	label: "Scientific",
	// 	value: BarChartTheme.Scientific,
	// },
];

interface Props {
	value: BarChartTheme | null;
	onChange: (value: BarChartTheme | null) => void;
	style?: React.CSSProperties;
}



export default function ThemeSelector(props: Props) {
	return (
		<div
			className="my-row"
			style={{ alignItems: "center", ...props.style }}
		>
			<span
				style={{
					whiteSpace: "nowrap",
					fontFamily: "Roboto",
					fontSize: "10px",
					color: mainStyle.getPropertyValue(
						"--exploration-tertiary-text-color"
					),
					fontWeight: 300,
				}}
			>
				{"Theme:"}
			</span>
			<Select
				filterOption={createFilter({
					ignoreAccents: false,
				})}
				styles={{
					...customSelectStyles,
					container: (base) => ({
						...base,
						width: "150px",
						marginLeft: "5px",
						height: "38px",
					}),
				}}
				options={Options}
				value={Options.find((option) => option.value === props.value)}
				onChange={(newValue) => {
					props.onChange((newValue as BarChartThemeOption).value);
				}}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						text: "white",
						primary25: "var(--selectors-background-hover-color)",
					},
				})}
			/>
		</div>
	);
}