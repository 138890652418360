import { Handle, SliderTooltip } from "rc-slider";

export const renderHandle = (props: {
    className: string;
    prefixCls?: string;
    vertical?: boolean;
    offset: number;
    value: number;
    dragging?: boolean;
    disabled?: boolean;
    min?: number;
    max?: number;
    reverse?: boolean;
    index: number;
    tabIndex?: number;
    ariaLabel: string;
    ariaLabelledBy: string;
    ariaValueTextFormatter: string;
    style?: React.CSSProperties;
    ref?: React.Ref<Handle>;
}): React.ReactElement => {
    const {value, index, ariaValueTextFormatter, ...restProps } = props;
    let sliderFormatedValue = value;

    return (
        <div style={{color:"#3399FF", position: "relative", backgroundColor:"pink"}}>
            <Handle
                style={{
                    position: "relative"
                }}
                value={value}
                {...restProps}
            >
                <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}
                >
                    <p
                        style={{
                            fontSize: 10,
                            position:"absolute",
                            bottom: 3
                        }}
                    >{sliderFormatedValue}</p>
                </div>
            </Handle>            
        </div>
    );
}
