import React, { CSSProperties } from "react";
import { stopPropagation } from "common/utilities/EventFunctions";

interface Props {
    children: React.ReactNode;
    style?: CSSProperties;
}

export default function CanvasPreventPropagationButton(props: Props) {
    return (
        <div
            style={props.style}
            onMouseDown={stopPropagation}
            onClick={stopPropagation}
        >
            {React.Children.only(props.children)}
        </div>
    );
}
