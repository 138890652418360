import React from "react";
import Select, { createFilter } from "react-select";
import {
    BoxPlotFinding,
    ViolinPlotFinding,
    RidgelineFinding,
    isBoxPlot,
    isViolinPlot,
    isRidgeline,
    defaultBoxPlotOutlierCount,
    defaultViolinPlotBins,
} from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./BoxViolinRidgelineSection.module.css";
import ribbonStyles from "../../ChartsRibbon.module.css";
import { Accordion, Button } from "react-bootstrap";
import EditInput from "../EditInput";
import cx from "classnames";
import StringUtils from "common/utilities/StringUtils";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import MagicWand from "../MagicWand";
import { DynamicOptionType } from "common/DynamicOptions";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import Variables, { VariableOption } from "common/Variables";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

enum ScatterPlotMenuOptions {
    None = 0,
    Time = 1,
}

interface Props {
    columnDragActive: boolean;
    finding: BoxPlotFinding | ViolinPlotFinding | RidgelineFinding;
    onChange: (
        finding: BoxPlotFinding | ViolinPlotFinding | RidgelineFinding,
        updateData?: boolean
    ) => void;
    onClearEditing: () => void;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
}

export default function BoxViolinRidgelineSection(props: Props) {
    let [menuOptionSelected, setMenuOptionSelected] = React.useState(
        ScatterPlotMenuOptions.None
    );
    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    let variableOptions: VariableOption[] = [];
    if (props.finding.config.dataScope != null) {
        variableOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
    }

    let data = props.finding.content.data;
    let currentEditVariableIndex: number | undefined = undefined;
    if (props.finding.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = data.findIndex(
            (item) => item.variableIndex == null
        );
    }
    let xAxisName = props.finding.content.data[0].name;
    let yAxisName = props.finding.content.data[1].name;
    let datasetIsConnected = props.finding.config.dataScope != null;
    let maxRowCount = 3;
    let restCount = data[0].value.length - maxRowCount;
    return (
        <div>
            <div className="my-row">
                <MagicWand
                    minCount={2}
                    maxCount={2}
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    allowMultiple={true}
                    title={"Variables"}
                    optionName="data"
                    canvasTreeStore={props.canvasTreeStore}
                    type={DynamicOptionType.DataVariable}
                />
                <div className={styles.dataContainer}>
                    <div className={commonStyles.header}></div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 0,
                        })}
                    >
                        {xAxisName}
                    </div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 1,
                        })}
                    >
                        {yAxisName}
                    </div>
                    {(datasetIsConnected
                        ? data[0].value.slice(0, maxRowCount)
                        : data[0].value
                    ).map((_, index, array) => (
                        <React.Fragment key={index}>
                            <EditInput
                                showDeleteButton={
                                    props.finding.config.dataScope == null
                                }
                                disabled={true}
                                value={`Value ${index + 1}`}
                                onDelete={() => {
                                    let finding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: props.finding!.content.data.map(
                                                (item) => ({
                                                    ...item,
                                                    value: [...item.value],
                                                })
                                            ),
                                        },
                                    };
                                    finding.content.data.forEach((item) =>
                                        item.value.splice(index, 1)
                                    );
                                    props.onChange(
                                        finding as
                                            | BoxPlotFinding
                                            | ViolinPlotFinding
                                            | RidgelineFinding
                                    );
                                }}
                            ></EditInput>
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 0,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 0 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                })}
                                value={data[0].value[index]}
                                onChange={(value) => {
                                    let finding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: props.finding!.content.data.map(
                                                (item) => ({
                                                    ...item,
                                                    value: [...item.value],
                                                })
                                            ),
                                        },
                                    };
                                    if (isRidgeline(props.finding)) {
                                        finding.content.data[0].value[
                                            index
                                        ] = Number(value);
                                    } else {
                                        finding.content.data[0].value[
                                            index
                                        ] = String(value);
                                    }
                                    props.onChange(
                                        finding as
                                            | BoxPlotFinding
                                            | ViolinPlotFinding
                                            | RidgelineFinding
                                    );
                                }}
                            ></EditInput>
                            <EditInput
                                showDeleteButton={false}
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 1,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 1 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                })}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[1].value[index]}
                                onChange={(value) => {
                                    let finding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: props.finding!.content.data.map(
                                                (item) => ({
                                                    ...item,
                                                    value: [...item.value],
                                                })
                                            ),
                                        },
                                    };
                                    if (isRidgeline(props.finding)) {
                                        finding.content.data[1].value[
                                            index
                                        ] = String(value);
                                    } else {
                                        finding.content.data[1].value[
                                            index
                                        ] = Number(value);
                                    }
                                    props.onChange(
                                        finding as
                                            | BoxPlotFinding
                                            | ViolinPlotFinding
                                            | RidgelineFinding
                                    );
                                }}
                            ></EditInput>
                        </React.Fragment>
                    ))}
                    {datasetIsConnected && restCount > 0 && (
                        <React.Fragment>
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={`+${restCount} values`}
                            ></EditInput>
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 0,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            ></EditInput>
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 1,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            ></EditInput>
                        </React.Fragment>
                    )}
                </div>
            </div>
            {props.finding.config.dataScope == null && (
                <Button
                    style={{ marginTop: 10 }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let finding = {
                            ...props.finding!,
                            content: {
                                ...props.finding!.content,
                                data: props.finding!.content.data.map(
                                    (item) => ({
                                        ...item,
                                        value: [...item.value],
                                    })
                                ),
                            },
                        };
                        if (isRidgeline(props.finding)) {
                            finding.content.data[0].value.push(0);
                            finding.content.data[1].value.push("B");
                        } else {
                            finding.content.data[0].value.push("B");
                            finding.content.data[1].value.push(0);
                        }
                        props.onChange(
                            finding as
                                | BoxPlotFinding
                                | ViolinPlotFinding
                                | RidgelineFinding
                        );
                    }}
                >
                    {"\uFF0B Add Item"}
                </Button>
            )}
            {datasetIsConnected && isBoxPlot(props.finding) && (
                <div className={styles.optionContainer}>
                    <span className={styles.optionName}>Show at most</span>
                    <EditInput
                        optionalStyles={{
                            marginLeft: 10,
                            minWidth: 60,
                        }}
                        optionalClassName={styles.input}
                        showDeleteButton={false}
                        disabled={false}
                        value={
                            props.finding.config.outlierCount ??
                            defaultBoxPlotOutlierCount
                        }
                        onChange={(value) => {
                            let finding: BoxPlotFinding = {
                                ...(props.finding as BoxPlotFinding),
                            };
                            if (StringUtils.isNumber(value)) {
                                let count = Number(value);
                                if (count !== finding.config.outlierCount) {
                                    finding.config.outlierCount = count;
                                    props.onChange(finding);
                                }
                            }
                        }}
                    ></EditInput>
                    <span
                        style={{ marginLeft: 10 }}
                        className={styles.optionName}
                    >
                        outliers
                    </span>
                </div>
            )}
            {(isViolinPlot(props.finding) || isRidgeline(props.finding)) && (
                <div className={styles.optionContainer}>
                    <span className={styles.optionName}>
                        {isRidgeline(props.finding) ? "Thresholds" : "Bins"}
                    </span>
                    <EditInput
                        optionalStyles={{ marginLeft: 10 }}
                        optionalClassName={styles.input}
                        showDeleteButton={false}
                        disabled={false}
                        value={
                            props.finding.config.bins ?? defaultViolinPlotBins
                        }
                        onChange={(value) => {
                            let finding:
                                | ViolinPlotFinding
                                | RidgelineFinding = {
                                ...props.finding!,
                                config: {
                                    ...props.finding!.config,
                                },
                            };
                            if (StringUtils.isNumber(value)) {
                                let bins = Number(value);
                                if (bins !== finding.config.bins) {
                                    finding.config.bins = bins;
                                    props.onChange(finding, true);
                                }
                            }
                        }}
                    />
                </div>
            )}
            <div style={{ marginTop: 10 }}>
                <Accordion
                    activeKey={String(menuOptionSelected)}
                    onSelect={(event: any) => {
                        setMenuOptionSelected(Number(event));
                    }}
                >
                    <Accordion.Toggle
                        eventKey={String(ScatterPlotMenuOptions.Time)}
                        className={ribbonStyles.editMenuAdvancedOption}
                    >
                        Animation
                        <ChevronIcon
                            className={cx(
                                ribbonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [ribbonStyles.chevronOpen]:
                                        menuOptionSelected ===
                                        ScatterPlotMenuOptions.Time,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(ScatterPlotMenuOptions.Time)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Select
                                isClearable
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder="Select variable"
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                        height: "38px",
                                        marginTop: "8px",
                                    }),
                                }}
                                options={variableOptions}
                                onChange={(newValue) => {
                                    let finding = {
                                        ...props.finding,
                                        content: {
                                            ...props.finding.content,
                                        },
                                    };
                                    if (
                                        props.finding.content.time != null &&
                                        newValue != null
                                    ) {
                                        finding.content.time = {
                                            ...props.finding.content.time!,
                                            variableIndex: (newValue as VariableOption)
                                                .value,
                                            originalName: (newValue as VariableOption)
                                                .label,
                                            name: (newValue as VariableOption)
                                                .label,
                                        };
                                    } else {
                                        finding.content.time = {
                                            name: "time",
                                            value: [],
                                            uniqueValues: [],
                                            variableIndex: null,
                                            originalName: null,
                                        };
                                    }
                                    props.onChange(
                                        finding as
                                            | BoxPlotFinding
                                            | ViolinPlotFinding
                                            | RidgelineFinding,
                                        true
                                    );
                                }}
                                value={
                                    props.finding.content.time?.variableIndex !=
                                    null
                                        ? {
                                              label:
                                                  props.finding.content.time
                                                      .name,
                                              value:
                                                  props.finding.content.time
                                                      .variableIndex,
                                          }
                                        : null
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                    </Accordion.Collapse>
                </Accordion>
            </div>
        </div>
    );
}
