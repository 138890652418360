import React from "react";
import { DynamicOptionType } from "common/DynamicOptions";
import { ReactComponent as MagicWandIcon } from "icons/canvas/exploration/magic_wand.svg";
import { ReactComponent as MagicWandIconActive } from "icons/canvas/exploration/magic_wand_active.svg";
import styles from "./MagicWand.module.css";
import {
    CanvasDropdownSelector,
    isDropdownSelector,
} from "common/Canvas";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { observer } from "mobx-react";
import { getTextSize } from "common/utilities/MeasureText";

interface Props {
    onClearEditing: () => void;
    canvasTreeStore: CanvasTreeStore;
    dashboardId: string;
    allowMultiple: boolean;
    type: DynamicOptionType;
    title: string;
    optionName: string;
    minCount?: number;
    maxCount?: number;
}

function MagicWand(props: Props) {
    let referenceNode: CanvasDropdownSelector | undefined = undefined;
    props.canvasTreeStore.canvasTreeState.forEach((node) => {
        if (
            isDropdownSelector(node) &&
            node.dynamicOption?.dashboardId === props.dashboardId &&
            node.dynamicOption?.key === props.optionName
        ) {
            referenceNode = node;
        }
    });
    let active = referenceNode != null;
    return (
        <div
            className={styles.magicWand}
            onClick={() => {
                let dashboard = props.canvasTreeStore.dashboardsState.get(
                    props.dashboardId
                )!;

                // 120 - extra width to count some spaces icons width, etc...
                let selectorWidth = 120;
                const heightMultiplier = props.type === DynamicOptionType.Filter
                    ? dashboard.finding?.config.conditions.length
                    : props.type === DynamicOptionType.DataVariable
                        ? 1.5
                        : 1;
                let selectorHeight = 50 * heightMultiplier + 25;
                
                dashboard.finding?.content.data.forEach((item: any) => {
                    selectorWidth += getTextSize(
                        item.name,
                        "Roboto",
                        10,
                        "600"
                    ).width;
                })

                if (!active) {
                    props.canvasTreeStore.addDropdownSelectorInputAction({
                        dynamicOption: {
                            maxCount: props.maxCount,
                            minCount: props.minCount,
                            key: props.optionName,
                            dashboardId: props.dashboardId,
                            title: props.title,
                            type: props.type,
                            allowMultiple: props.allowMultiple,
                        },
                        nodeSize: {
                            desktop: { width: selectorWidth, height: selectorHeight },
                            mobile: { width: 100, height: 100 },
                        },
                    });
                } else {
                    props.onClearEditing();
                    props.canvasTreeStore.deleteNodeAction(referenceNode!.id);
                }
            }}
        >
            {active ? <MagicWandIconActive /> : <MagicWandIcon />}
        </div>
    );
}

export default observer(MagicWand);
