import React, { Component } from "react";

import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import UpdateStatus from "../../common/UpdateStatus";
import FieldEditor, { Field } from "../../common/FieldEditor";

interface State {
    status: UpdateStatus;
    errorMessage: string;
    value: Field[];
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            value: [
                {
                    name: "shared_story_expiration_time",
                    label: "Shared Story Expiration Time (hours)",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "shared_dashboard_expiration_time",
                    label: "Shared Dashboard Expiration Time (hours)",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "shared_insight_expiration_time",
                    label: "Shared Insight Expiration Time (hours)",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "logout_timeout",
                    label: "Idle Timeout (minutes)",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "password_expiration",
                    label: "Password Expiration Time (months)",
                    value: "",
                    editable: true,
                    hidden: false,
                },
            ],
        };
        this.performance = null;
    }

    componentDidMount() {
        axios
            .post<
                {
                    success: boolean;
                    error_msg: string;
                } & { [key: string]: string }
            >("/api/get_server_settings", {})
            .then((response) => {
                if (response.data.success) {
                    this.setState((state) => {
                        let value = Array.from(state.value);
                        for (let i in value) {
                            value[i] = {
                                ...value[i],
                                value: response.data[value[i].name],
                            };
                        }
                        return {
                            value: value,
                        };
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    private updateServerSettings(): void {
        let localPerformance: Date = new Date();
        this.setState({
            status: UpdateStatus.Loading,
            errorMessage: "",
        });
        let config: { [key: string]: string } = {};
        this.state.value.forEach((field: Field) => {
            config[field.name] = field.value;
        });
        // Numeric limit for logout timeout
        if (Number(config.logout_timeout) > 35791) {
            this.setState({
                status: UpdateStatus.Error,
                errorMessage:
                    "Idle timeout can't be greater than 35791 minutes",
            });
            return;
        }
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/update_server_settings", config)
            .then((response) => {
                this.performance = localPerformance;
                if (response.data.success) {
                    this.setState({ status: UpdateStatus.Success });
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ status: UpdateStatus.Error });
            });
    }

    render() {
        return (
            <FieldEditor
                title="Timeout/Expiration Settings"
                value={this.state.value}
                onChange={(value) => this.setState({ value: value })}
                onUpdate={() => this.updateServerSettings()}
                status={this.state.status}
                onStatusChange={(status: UpdateStatus) =>
                    this.setState({ status: status })
                }
                errorMessage={this.state.errorMessage}
            />
        );
    }
}

export { MainComponent };
export let requirePermission = "ChangeServerSettings";
