import React from "react";
import { PrintExpression } from "common/Canvas";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";
import { Button } from "react-bootstrap";
import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import StatusSubExpressionSelector from "common/StatusSubExpressionsSelector";

interface Props {
    nodeLinkOptions: NodeLinkOption[];
    printExpressions: PrintExpression[];
    node: CanvasElement | CanvasTextBox;
    onChange: (changes: PrintExpression[]) => void;
}

interface OutputIndexOption {
    label: string;
    value: number;
}

export default class PrintExpressionsSelector extends React.Component<Props> {
    static getOutputValue(
        node: CanvasElement | CanvasTextBox,
        printExpression: PrintExpression
    ): OutputIndexOption | null {
        if (printExpression.outputIndex === 1)
            return {
                label: "Output#1",
                value: 1,
            };
        if (printExpression.outputIndex > 1) {
            if (node.additionalOutputs.length > printExpression.outputIndex - 2)
                return {
                    label: `Output#${printExpression.outputIndex}`,
                    value: printExpression.outputIndex,
                };
        }
        return null;
    }
    render() {
        let outputOptions = [
            {
                label: "Output#1",
                value: 1,
            },
        ].concat(
            this.props.node.additionalOutputs?.map((_output, outputIndex) => ({
                label: `Output#${outputIndex + 2}`,
                value: outputIndex + 2,
            }))
        );
        return (
            <div className="flex-simple-column">
                {this.props.printExpressions.map(
                    (printExpression, expressionIndex) => (
                        <div
                            key={expressionIndex}
                            className="my-row"
                            style={{
                                marginLeft: "10px",
                            }}
                        >
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    height: "38px",
                                }}
                            >
                                <input
                                    className="like-select"
                                    style={{
                                        resize: "none",
                                    }}
                                    placeholder="Enter output"
                                    value={printExpression.text}
                                    onChange={(evt) => {
                                        let printExpressions = Array.from(
                                            this.props.printExpressions
                                        );
                                        printExpressions[expressionIndex].text =
                                            evt.currentTarget.value;
                                        this.props.onChange(printExpressions);
                                    }}
                                />
                                <span
                                    style={{
                                        marginLeft: "5px",
                                        textAlign: "right",
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                    }}
                                >
                                    {"For"}
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            height: "38px",
                                            width: "120px",
                                            marginLeft: "5px",
                                        }),
                                    }}
                                    options={outputOptions}
                                    onChange={(newValue) => {
                                        let newPrintExpressions = Array.from(
                                            this.props.printExpressions
                                        );
                                        newPrintExpressions[
                                            expressionIndex
                                        ].outputIndex = (newValue as OutputIndexOption).value;

                                        this.props.onChange(
                                            newPrintExpressions
                                        );
                                    }}
                                    value={PrintExpressionsSelector.getOutputValue(
                                        this.props.node,
                                        printExpression
                                    )}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <StatusSubExpressionSelector
                                node={this.props.node}
                                nodeLinkOptions={this.props.nodeLinkOptions}
                                subexpressions={printExpression.subexpressions}
                                onChange={(subexpressions) => {
                                    let printExpressions = Array.from(
                                        this.props.printExpressions
                                    );
                                    printExpressions[
                                        expressionIndex
                                    ].subexpressions = subexpressions;
                                    this.props.onChange(printExpressions);
                                }}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 20 }}
                            >
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let printExpressions = Array.from(
                                            this.props.printExpressions
                                        );
                                        printExpressions.push({
                                            subexpressions: [
                                                { operation: "", value: "" },
                                            ],
                                            text: "",
                                            outputIndex: 1,
                                        });

                                        this.props.onChange(printExpressions);
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let printExpressions = Array.from(
                                            this.props.printExpressions
                                        );
                                        printExpressions.splice(
                                            expressionIndex,
                                            1
                                        );
                                        if (printExpressions.length === 0) {
                                            printExpressions.push({
                                                subexpressions: [
                                                    {
                                                        operation: "",
                                                        value: "",
                                                    },
                                                ],
                                                text: "",
                                                outputIndex: 1,
                                            });
                                        }
                                        this.props.onChange(printExpressions);
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}