import React, { Component } from "react";
import Alert from "common/Alert";
import UpdateStatus from "./UpdateStatus";

interface Props {
    errorMessage: string;
    value: UpdateStatus;
    onChange: (status: UpdateStatus) => void;
}

class UpdateStatusAlert extends Component<Props> {
    render() {
        let alert = <div style={{ height: 72, margin: 10 }} />;
        if (this.props.value === UpdateStatus.Success) {
            alert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text="Successully updated."
                        className="alert alert-success alert-dismissible"
                        onClosed={() =>
                            this.props.onChange(UpdateStatus.NotUploaded)
                        }
                    />
                </div>
            );
        } else if (this.props.value === UpdateStatus.Error) {
            alert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text={"Update error: ".concat(
                            this.props.errorMessage
                        )}
                        className="alert alert-danger alert-dismissible"
                        onClosed={() =>
                            this.props.onChange(UpdateStatus.NotUploaded)
                        }
                    />
                </div>
            );
        } else if (this.props.value === UpdateStatus.Loading) {
            alert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text={"Updating settings"}
                        className="alert alert-warning alert-dismissible"
                        onClosed={() =>
                            this.props.onChange(UpdateStatus.NotUploaded)
                        }
                    />
                </div>
            );
        }
        return alert;
    }
}

export default UpdateStatusAlert;
