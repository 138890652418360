import { Styles } from "common/SelectStyles";

const selectStyles: Styles = {
    container: (provided) => ({
        ...provided,
        flex: "1 1 auto",
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: "4px",
        minWidth: "140px",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
};


export default selectStyles;