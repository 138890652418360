import React, { useRef } from "react";

import { JourneyRecipesCard } from "common/JourneyCard";
import { useDrag, useDrop } from "react-dnd";

export default function DraggableJourneyCard(props) {
	const item = props.item;
	const onCardsSwapped = props.onCardsSwapped;
	const ref = useRef(null);

	const drag = useDrag({
		type: "recipes_config",
		item: {
			content: item,
		},
		end: (item, monitor) => {},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})[1];

	// [{ canDrop, isOver }, drop]
	const drop = useDrop({
		accept: "recipes_config",
		drop(otherItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = otherItem.content.id;
			const hoverIndex = item.id;
			if (dragIndex === hoverIndex) {
				return;
			}
			onCardsSwapped(item, otherItem.content);
		},
	})[1];
	drag(drop(ref));
	return (
		<div ref={ref}>
			<JourneyRecipesCard {...props} />
		</div>
	);
}
