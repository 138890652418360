import PresentationStore from "state/store/PresentationStore";

class RootStore {
	presentationStore: PresentationStore;

	constructor() {
		this.presentationStore = new PresentationStore();
	}
}

export default RootStore;
