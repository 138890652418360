import React from "react";
import { ShapeType } from "react-archer";
import Point from "./Point";

import { AnchorPositionType } from "./Types";

type Props = {
    scale: number;
    startingPoint: Point;
    startingAnchorOrientation: AnchorPositionType;
    endingPoint: Point;
    endingAnchorOrientation: AnchorPositionType;
    strokeColor: string;
    strokeWidth: number;
    strokeDasharray?: string;
    arrowLabel?: React.ReactNode;
    arrowMarkerId: string;
    lineStyle: string;
    offset?: number;
    enableStartMarker?: boolean;
    disableEndMarker?: boolean;
    endShape: ShapeType;
    deleteArrow: () => void
};

function computeArrowDirectionVector(anchorOrientation: AnchorPositionType) {
    switch (anchorOrientation) {
        case "left":
            return { arrowX: -1, arrowY: 0 };
        case "right":
            return { arrowX: 1, arrowY: 0 };
        case "top":
            return { arrowX: 0, arrowY: -1 };
        case "bottom":
            return { arrowX: 0, arrowY: 1 };
        default:
            return { arrowX: 0, arrowY: 0 };
    }
}

export function computeArrowPointAccordingToArrowHead(
    xArrowHeadPoint: number,
    yArrowHeadPoint: number,
    arrowLength: number,
    strokeWidth: number,
    endingAnchorOrientation: AnchorPositionType,
    lineStyle?: string,
    xArrowStart?: number,
    yArrowStart?: number
) {
    let { arrowX, arrowY } = computeArrowDirectionVector(
        endingAnchorOrientation
    );

    if (
        lineStyle === "straight" &&
        xArrowStart !== undefined &&
        yArrowStart !== undefined
    ) {
        const angle = Math.atan2(
            yArrowStart - yArrowHeadPoint,
            xArrowStart - xArrowHeadPoint
        );

        arrowX = Math.cos(angle);
        arrowY = Math.sin(angle);
    }

    const xPoint = xArrowHeadPoint + (arrowX * arrowLength * strokeWidth) / 2;
    const yPoint = yArrowHeadPoint + (arrowY * arrowLength * strokeWidth) / 2;

    return { xPoint, yPoint };
}

export function computeStartingAnchorPosition(
    xStart: number,
    yStart: number,
    xEnd: number,
    yEnd: number,
    startingAnchorOrientation: AnchorPositionType
): { xAnchor1: number; yAnchor1: number } {
    if (
        startingAnchorOrientation === "top" ||
        startingAnchorOrientation === "bottom"
    ) {
        return {
            xAnchor1: xStart,
            yAnchor1: yStart + (yEnd - yStart) / 2,
        };
    }
    if (
        startingAnchorOrientation === "left" ||
        startingAnchorOrientation === "right"
    ) {
        return {
            xAnchor1: xStart + (xEnd - xStart) / 2,
            yAnchor1: yStart,
        };
    }

    return { xAnchor1: xStart, yAnchor1: yStart };
}

export function computeStartingAnchorPositionRightAngle(
    xStart: number,
    yStart: number,
    xEnd: number,
    yEnd: number,
    startingAnchorOrientation: AnchorPositionType
): { xAnchor1: number; yAnchor1: number } {
    if (
        startingAnchorOrientation === "top" ||
        startingAnchorOrientation === "bottom"
    ) {
        return {
            xAnchor1: xStart,
            yAnchor1: yEnd,
        };
    }
    if (
        startingAnchorOrientation === "left" ||
        startingAnchorOrientation === "right"
    ) {
        return {
            xAnchor1: xEnd,
            yAnchor1: yStart,
        };
    }

    return { xAnchor1: xStart, yAnchor1: yStart };
}

export function computeEndingAnchorPosition(
    xStart: number,
    yStart: number,
    xEnd: number,
    yEnd: number,
    endingAnchorOrientation: AnchorPositionType
): { xAnchor2: number; yAnchor2: number } {
    if (
        endingAnchorOrientation === "top" ||
        endingAnchorOrientation === "bottom"
    ) {
        return {
            xAnchor2: xEnd,
            yAnchor2: yEnd - (yEnd - yStart) / 2,
        };
    }
    if (
        endingAnchorOrientation === "left" ||
        endingAnchorOrientation === "right"
    ) {
        return {
            xAnchor2: xEnd - (xEnd - xStart) / 2,
            yAnchor2: yEnd,
        };
    }

    return { xAnchor2: xEnd, yAnchor2: yEnd };
}

export function computeEndingAnchorPositionRightAngle(
    xStart: number,
    yStart: number,
    xEnd: number,
    yEnd: number,
    endingAnchorOrientation: AnchorPositionType
): { xAnchor2: number; yAnchor2: number } {
    if (
        endingAnchorOrientation === "top" ||
        endingAnchorOrientation === "bottom"
    ) {
        return {
            xAnchor2: xEnd,
            yAnchor2: yStart,
        };
    }
    if (
        endingAnchorOrientation === "left" ||
        endingAnchorOrientation === "right"
    ) {
        return {
            xAnchor2: xStart,
            yAnchor2: yEnd,
        };
    }

    return { xAnchor2: xEnd, yAnchor2: yEnd };
}

export function computeLabelDimensions(
    xStart: number,
    yStart: number,
    xEnd: number,
    yEnd: number
): { xLabel: number; yLabel: number; labelWidth: number; labelHeight: number } {
    const labelWidth = Math.max(Math.abs(xEnd - xStart), 1);
    const labelHeight = Math.max(Math.abs(yEnd - yStart), 1);

    const xLabel = xEnd > xStart ? xStart : xEnd;
    const yLabel = yEnd > yStart ? yStart : yEnd;

    return {
        xLabel,
        yLabel,
        labelWidth,
        labelHeight,
    };
}

function computePathString({
    scale,
    xStart,
    yStart,
    xAnchor1,
    yAnchor1,
    xAnchor2,
    yAnchor2,
    xEnd,
    yEnd,
    startingAnchorOrientation,
    endingAnchorOrientation,
    lineStyle,
    offset,
    curveRadius,
}: {
    scale: number;
    xStart: number;
    yStart: number;
    xAnchor1: number;
    yAnchor1: number;
    xAnchor2: number;
    yAnchor2: number;
    xEnd: number;
    yEnd: number;
    startingAnchorOrientation: AnchorPositionType;
    endingAnchorOrientation: AnchorPositionType;
    lineStyle: string;
    offset?: number;
    curveRadius?: number;
}): string {
    if (offset && offset > 0) {
        const angle =
            lineStyle === "straight"
                ? Math.atan2(yEnd - yStart, xEnd - xStart)
                : Math.atan2(yAnchor1 - yStart, xAnchor1 - xStart);

        const xOffset = offset * Math.cos(angle);
        const yOffset = offset * Math.sin(angle);

        if (lineStyle !== "straight") {
            xStart = xStart + xOffset;
            yStart = yStart + yOffset;
        }

        xEnd = xEnd - xOffset;
        yEnd = yEnd - yOffset;
    }

    let linePath = `M${xStart} ${yStart} `;
    let signY = Math.sign(yEnd - yStart);
    let signX = Math.sign(xEnd - xStart);
    let xShiftOffset = Math.abs((xEnd) - (xStart))/2; 

    if (["curve", "angle"].includes(lineStyle)) {
        linePath += `${lineStyle === "curve" ? "C" : ""}`;
        if (curveRadius === 0 || curveRadius == null) {
            linePath += `L ${xAnchor1} ${yAnchor1} `;
            if (xAnchor2 !== xAnchor1 || yAnchor2 !== yAnchor1) {
                linePath += `L ${xAnchor2} ${yAnchor2} `;
            }
        } else {
            if (xAnchor2 !== xAnchor1 || yAnchor2 !== yAnchor1) {
                let curve1 = { x: xAnchor1, y: yAnchor1 };
                let curve2 = { x: xAnchor1, y: yAnchor1 };
                if (
                    startingAnchorOrientation === "top" ||
                    startingAnchorOrientation === "bottom"
                ) {
                    curve1.y += curveRadius * Math.sign(yStart - curve1.y);
                    if (xAnchor2 > curve2.x) {
                        curve2.x = Math.min(curve2.x + curveRadius, xAnchor2);
                    } else {
                        curve2.x = Math.max(curve2.x - curveRadius, xAnchor2);
                    }
                } else {
                    curve1.x += curveRadius * Math.sign(xStart - curve1.x);
                    if (yAnchor2 > curve2.y) {
                        curve2.y = Math.min(curve2.y + curveRadius, yAnchor2);
                    } else {
                        curve2.y = Math.max(curve2.y - curveRadius, yAnchor2);
                    }
                }
                if(startingAnchorOrientation === "right" && endingAnchorOrientation === "right") {
                    if(xShiftOffset > 10)
                        linePath += `L ${curve1.x + 15/xShiftOffset * scale + xShiftOffset} ${curve1.y}`;
                    linePath += `Q ${xAnchor1 + 25 * scale  + xShiftOffset} ${yAnchor1}, 
                                   ${curve2.x + 25 * scale  + xShiftOffset} ${curve2.y  + signY * 15 * scale} `;
                } else if(startingAnchorOrientation === "left" && endingAnchorOrientation === "left") {
                    if(xShiftOffset > 10)
                        linePath += `L ${curve1.x - 15/xShiftOffset * scale - xShiftOffset} ${curve1.y}`;
                    linePath += `Q ${xAnchor1 - 25 * scale - xShiftOffset} ${yAnchor1}, 
                                   ${curve2.x - 25 * scale - xShiftOffset} ${curve2.y  + signY * 15 * scale} `;
                } else if(startingAnchorOrientation === "top" && endingAnchorOrientation === "top") {
                } else if(startingAnchorOrientation === "bottom" && endingAnchorOrientation === "bottom") {
                } else if(startingAnchorOrientation === "bottom" && endingAnchorOrientation === "top" && (signY < 0)) { 
                } else if(startingAnchorOrientation === "top" && endingAnchorOrientation === "bottom" && (signY > 0)) { 
                } 
                else if(startingAnchorOrientation === "left" && endingAnchorOrientation === "right" && (signX > 0)) {
                    linePath += `L ${xStart - 25*scale} ${yStart}`;
                    linePath += `L ${xStart - 25*scale} ${yStart + 60*scale}`;
                    linePath += `L ${xEnd + 25*scale} ${yStart + 60*scale}`;
                    linePath += `L ${xEnd + 25*scale} ${yEnd}`;
                } else {
                    linePath += `L ${curve1.x} ${curve1.y} Q ${xAnchor1} ${yAnchor1}, ${curve2.x} ${curve2.y} `;
                }

                curve1 = { x: xAnchor2, y: yAnchor2 };
                curve2 = { x: xAnchor2, y: yAnchor2 };
                if (
                    endingAnchorOrientation === "top" ||
                    endingAnchorOrientation === "bottom"
                ) {
                    if (xAnchor1 > curve1.x) {
                        curve1.x = Math.min(curve1.x + curveRadius, xAnchor1);
                    } else {
                        curve1.x = Math.max(curve1.x - curveRadius, xAnchor1);
                    }
                    curve2.y += curveRadius * Math.sign(yEnd - curve2.y);
                } else {
                    if (yAnchor1 > curve1.y) {
                        curve1.y = Math.min(curve1.y + curveRadius, yAnchor1);
                    } else {
                        curve1.y = Math.max(curve1.y - curveRadius, yAnchor1);
                    }
                    curve2.x += curveRadius * Math.sign(xEnd - curve2.x);
                }
                if(startingAnchorOrientation === "right" && endingAnchorOrientation === "right") {
                    linePath += `L ${curve1.x  + 25 * scale  + xShiftOffset} ${curve1.y - signY * 10 * scale}`
                    linePath += ` Q ${xAnchor2  + 25 * scale  + xShiftOffset} ${yAnchor2}, ${curve2.x  + xShiftOffset} ${curve2.y} `;

                } else if(startingAnchorOrientation === "left" && endingAnchorOrientation === "left") {
                    linePath += `L ${curve1.x  - 25 * scale - xShiftOffset} ${curve1.y  - signY * 10 * scale}`
                    linePath += ` Q ${xAnchor2  - 25 * scale - xShiftOffset} ${yAnchor2}, ${curve2.x - xShiftOffset} ${curve2.y} `;
                } else if(startingAnchorOrientation === "top" && endingAnchorOrientation === "top") {
                    if(signY > 0) {
                        if(xShiftOffset > 80) {
                            linePath += `L ${xStart} ${yStart - 20 * scale} `;
                            linePath += `L ${xEnd} ${yStart - 20 * scale} `;
                        } else {
                            linePath += `L ${xStart} ${yStart - 20 * scale} `;
                            linePath += `L ${xStart - 100 * scale} ${yStart - 20 * scale} `;
                            linePath += `L ${xStart - 100 * scale} ${yStart + 70 * scale} `;
                            linePath += `L ${xEnd} ${yStart + 70 * scale} `;
                        }
                       
                    } else {
                        if(xShiftOffset > 80) {
                            linePath += `L ${xStart} ${yEnd -20 * scale} `;
                            linePath += `L ${xEnd} ${yEnd  - 20 * scale} `;                        
                        } else {
                            linePath += `L ${xStart} ${yEnd  + 80 * scale} `;
                            linePath += `L ${xEnd - 100 * scale} ${yEnd  + 80 * scale} `;
                            linePath += `L ${xEnd - 100 * scale} ${yEnd  + 80 * scale} `;
                            linePath += `L ${xEnd - 100 * scale} ${yEnd  - 20 * scale} `;
                            linePath += `L ${xEnd} ${yEnd  - 20 * scale} `;
                        }
                    }
                } else if(startingAnchorOrientation === "bottom" && endingAnchorOrientation === "bottom") {
                    if(signY < 0) {
                        if(xShiftOffset > 80) {
                            linePath += `L ${xStart} ${yStart + 20 * scale} `;
                            linePath += `L ${xEnd} ${yStart + 20 * scale} `;
                        } else {
                            linePath += `L ${xStart} ${yStart + 20 * scale} `;
                            linePath += `L ${xStart - 100 * scale} ${yStart + 20 * scale} `;
                            linePath += `L ${xStart - 100 * scale} ${yStart - 80 * scale} `;
                            linePath += `L ${xEnd} ${yStart - 80 * scale} `;
                        }
                    } else {
                        if(xShiftOffset > 80) {
                            linePath += `L ${xStart} ${yEnd  + 20 * scale} `;
                            linePath += `L ${xEnd} ${yEnd  + 20 * scale} `;
                        } else {
                            linePath += `L ${xStart} ${yEnd  - 80 * scale} `;
                            linePath += `L ${xEnd - 100 * scale} ${yEnd  - 80 * scale} `;
                            linePath += `L ${xEnd - 100 * scale} ${yEnd  + 20 * scale} `;
                            linePath += `L ${xEnd} ${yEnd  + 20 * scale} `;
                        }
                    }
                } else if(startingAnchorOrientation === "bottom" && endingAnchorOrientation === "top" && signY < 0) { 

                    if(signX > 0) {
                        linePath += `L ${xStart} ${yStart  + 30 * scale} `;
                        linePath += `L ${xStart - 100 * scale} ${yStart  + 30 * scale} `;
                        linePath += `L ${xStart - 100 * scale} ${yEnd - 30 * scale} `;
                        linePath += `L ${xEnd} ${yEnd - 30 * scale} `;
                    } else {
                        linePath += `L ${xStart} ${yStart  + 30 * scale} `;
                        linePath += `L ${xStart + 100 * scale} ${yStart  + 30 * scale} `;
                        linePath += `L ${xStart + 100 * scale} ${yEnd - 30 * scale} `;
                        linePath += `L ${xEnd} ${yEnd - 30 * scale} `;
                    }
                } else if(startingAnchorOrientation === "top" && endingAnchorOrientation === "bottom" && signY > 0) { 
                        linePath += `L ${xStart} ${yStart  - 30 * scale} `;
                        linePath += `L ${xEnd + 80 * scale} ${yStart - 30 * scale} `;
                        linePath += `L ${xEnd + 80 * scale} ${yEnd + 30 * scale} `;
                        linePath += `L ${xEnd} ${yEnd + 30 * scale} `;
                } else if(startingAnchorOrientation === "left" && endingAnchorOrientation === "right" && (signX > 0)) {
                } else {
                    linePath += `L ${curve1.x} ${curve1.y} Q ${xAnchor2} ${yAnchor2}, ${curve2.x} ${curve2.y} `;
                }

            } else {
                let curve1 = { x: xAnchor1, y: yAnchor1 };
                let curve2 = { x: xAnchor1, y: yAnchor1 };
                if (
                    startingAnchorOrientation === "top" ||
                    startingAnchorOrientation === "bottom"
                ) {
                    curve1.y +=
                        (curveRadius / 2) * Math.sign(yStart - curve1.y);
                } else {
                    curve1.x +=
                        (curveRadius / 2) * Math.sign(xStart - curve1.x);
                }
                if (
                    endingAnchorOrientation === "top" ||
                    endingAnchorOrientation === "bottom"
                ) {
                    curve2.y += (curveRadius / 2) * Math.sign(yEnd - curve2.y);
                } else {
                    curve2.x += (curveRadius / 2) * Math.sign(xEnd - curve1.x);
                }
                
                if(startingAnchorOrientation === "bottom" && endingAnchorOrientation === "bottom") {
                    if(signY < 0) {
                        linePath += `L ${xStart} ${yStart + 20 * scale} `;
                        linePath += `L ${xStart - 100 * scale} ${yStart + 20 * scale} `;
                        linePath += `L ${xStart - 100 * scale} ${yStart - 80 * scale} `;
                        linePath += `L ${xStart} ${yStart - 80 * scale} `;
                
                    } else {
                        linePath += `L ${xEnd} ${yEnd  - 80 * scale} `;
                        linePath += `L ${xEnd - 100 * scale} ${yEnd  - 80 * scale} `;
                        linePath += `L ${xEnd - 100 * scale} ${yEnd  + 20 * scale} `;
                        linePath += `L ${xEnd} ${yEnd  + 20 * scale} `;
                    }
                   
                } else if(startingAnchorOrientation === "top" && endingAnchorOrientation === "top") {
                    if(signY > 0) {
                        linePath += `L ${xStart} ${yStart - 20 * scale} `;
                        linePath += `L ${xStart - 100 * scale} ${yStart - 20 * scale} `;
                        linePath += `L ${xStart - 100 * scale} ${yStart + 70 * scale} `;
                        linePath += `L ${xStart} ${yStart + 70 * scale} `;
                    } else {
                        linePath += `L ${xEnd} ${yEnd  + 80 * scale} `;
                        linePath += `L ${xEnd - 100 * scale} ${yEnd  + 80 * scale} `;
                        linePath += `L ${xEnd - 100 * scale} ${yEnd  + 80 * scale} `;
                        linePath += `L ${xEnd - 100 * scale} ${yEnd  - 20 * scale} `;
                        linePath += `L ${xEnd} ${yEnd  - 20 * scale} `;

                    }
                } else if(startingAnchorOrientation === "bottom" && endingAnchorOrientation === "top" &&  signY < 0) { 
                    linePath += `L ${xStart} ${yStart  + 30 * scale} `;
                    linePath += `L ${xStart - 100 * scale} ${yStart  + 30 * scale} `;
                    linePath += `L ${xStart - 100 * scale} ${yEnd - 30 * scale} `;
                    linePath += `L ${xEnd} ${yEnd - 30 * scale} `;
                } else if(startingAnchorOrientation === "top" && endingAnchorOrientation === "right") { 
                    if(signY < 0) {
                        if(signX < 0 ) {
                            linePath += `L ${xStart} ${yEnd} `;
                        } else {
                            linePath += `L ${xStart} ${yEnd  - 60 * scale} `;
                            linePath += `L ${xEnd + 20*scale} ${yEnd  - 60 * scale} `;
                            linePath += `L ${xEnd + 20*scale} ${yEnd} `;
                        }
                    } else {
                          if(signX < 0 ) {
                            linePath += `L ${xStart} ${yStart - 30 * scale} `;
                            linePath += `L ${xEnd + 20 * scale} ${yStart - 30 * scale} `;
                            linePath += `L ${xEnd +20 * scale} ${yEnd} `;
                        } else {
                            linePath += `L ${xStart} ${yStart - 30 * scale} `;
                            linePath += `L ${xEnd + 20 * scale} ${yStart - 30 * scale} `;
                            linePath += `L ${xEnd + 20 * scale} ${yEnd} `;

                        }
                    }
                } else if(startingAnchorOrientation === "top" && endingAnchorOrientation === "left") { 
                    if(signX > 0 && xShiftOffset > 150) {
                        linePath += `L ${xStart} ${yEnd} `;
                    } else {
                        linePath += `L ${xStart} ${yEnd  - 60 * scale} `;
                        linePath += `L ${xEnd - 20*scale} ${yEnd  - 60 * scale} `;
                        linePath += `L ${xEnd - 20*scale} ${yEnd} `;
                    }
                }
                else {
                    linePath += `L ${curve1.x} ${curve1.y} Q ${xAnchor1} ${yAnchor1}, ${curve2.x} ${curve2.y} `;
                }
            }
        }
    }
    
    if(
        endingAnchorOrientation === "left" ||
        endingAnchorOrientation === "right"
    ) {
        if(xShiftOffset < 4*scale) {
            linePath += `L ${xEnd + signX*10} ${yEnd }`
        } else {
            linePath += `L ${xEnd} ${yEnd}`;
        }

    }  else {
        linePath += `L ${xEnd} ${yEnd}`;
    }

    return linePath;
}

type Orientation = "horizontal" | "vertical" | null;

const anchorToOrientationMap: Readonly<
    { [key in AnchorPositionType]: Orientation }
> = {
    top: "vertical",
    bottom: "vertical",
    left: "horizontal",
    right: "horizontal",
    middle: null,
};

const SvgArrow = ({
    scale,
    startingPoint,
    startingAnchorOrientation,
    endingPoint,
    endingAnchorOrientation,
    strokeColor,
    strokeWidth,
    strokeDasharray,
    arrowLabel,
    arrowMarkerId,
    lineStyle,
    offset,
    enableStartMarker,
    disableEndMarker,
    endShape,
    deleteArrow
}: Props) => {
    const actualArrowLength =
        endShape.circle != null
            ? endShape.circle!.radius! * 2
            : endShape.arrow!.arrowLength! * 2;

    if (
        endingAnchorOrientation === "top" ||
        endingAnchorOrientation === "bottom"
    ) {
        if (Math.abs(endingPoint.y - startingPoint.y) < actualArrowLength * 2.2) {
            startingPoint = new Point(
                startingPoint.x,
                endingPoint.y -
                    actualArrowLength * 2.2 *
                        Math.sign(endingPoint.y - startingPoint.y)
            );
        }
    } else {
        if (Math.abs(endingPoint.x - startingPoint.x) < actualArrowLength * 2.2) {
            startingPoint = new Point(
                endingPoint.x -
                    actualArrowLength * 2.2 *
                        Math.sign(endingPoint.x - startingPoint.x),
                startingPoint.y
            );
        }
    }

    // Starting point with arrow
    const {
        xPoint: xStart,
        yPoint: yStart,
    } = computeArrowPointAccordingToArrowHead(
        startingPoint.x,
        startingPoint.y,
        enableStartMarker ? actualArrowLength : 0,
        strokeWidth,
        startingAnchorOrientation,
        lineStyle,
        endingPoint.x,
        endingPoint.y
    );

    // Ending point with arrow
    const {
        xPoint: xEnd,
        yPoint: yEnd,
    } = computeArrowPointAccordingToArrowHead(
        endingPoint.x,
        endingPoint.y,
        disableEndMarker ? 0 : actualArrowLength,
        strokeWidth,
        endingAnchorOrientation,
        lineStyle,
        startingPoint.x,
        startingPoint.y
    );

    let xAnchor1: number;
    let yAnchor1: number;
    let xAnchor2: number;
    let yAnchor2: number;

    let startingOrientation = anchorToOrientationMap[startingAnchorOrientation];
    let endingOrientation = anchorToOrientationMap[endingAnchorOrientation];

    if (
        startingOrientation === endingOrientation ||
        startingOrientation == null ||
        endingOrientation == null
    ) {
        // Starting position
        const anchor1 = computeStartingAnchorPosition(
            xStart,
            yStart,
            xEnd,
            yEnd,
            startingAnchorOrientation
        );
        xAnchor1 = anchor1.xAnchor1;
        yAnchor1 = anchor1.yAnchor1;

        // Ending position
        const anchor2 = computeEndingAnchorPosition(
            xStart,
            yStart,
            xEnd,
            yEnd,
            endingAnchorOrientation
        );
        xAnchor2 = anchor2.xAnchor2;
        yAnchor2 = anchor2.yAnchor2;
    } else {
        // Starting position
        const anchor1 = computeStartingAnchorPositionRightAngle(
            xStart,
            yStart,
            xEnd,
            yEnd,
            startingAnchorOrientation
        );
        xAnchor1 = anchor1.xAnchor1;
        yAnchor1 = anchor1.yAnchor1;

        // Ending position
        const anchor2 = computeEndingAnchorPositionRightAngle(
            xStart,
            yStart,
            xEnd,
            yEnd,
            endingAnchorOrientation
        );
        xAnchor2 = anchor2.xAnchor2;
        yAnchor2 = anchor2.yAnchor2;
    }

    const pathString = computePathString({
        scale,
        xStart,
        yStart,
        xAnchor1,
        yAnchor1,
        xAnchor2,
        yAnchor2,
        xEnd,
        yEnd,
        startingAnchorOrientation,
        endingAnchorOrientation,
        lineStyle,
        offset,
        curveRadius: 12,
    });

    const { xLabel, yLabel, labelWidth, labelHeight } = computeLabelDimensions(
        xStart,
        yStart,
        xEnd,
        yEnd
    );

    const markerUrl = `url(${
        window.location.href.split("#")[0]
    }#${arrowMarkerId})`;
    
    return (
        <g 
        onLoad={
            (evt) => {
                var svg = evt.target;
                svg.addEventListener('mousedown', startDrag);
                svg.addEventListener('mousemove', drag);                
                function startDrag(evt: any) {
                    evt.preventDefault();
                    var x = parseFloat(evt.target.getAttributeNS(null, "x"));
                    evt.target.setAttributeNS(null, "x", x + 0.1);
                }
                function drag(evt: any) {
                    evt.preventDefault();
                    var x = parseFloat(evt.target.getAttributeNS(null, "x"));
                    evt.target.setAttributeNS(null, "x", x + 0.1);
                }
            }
        }
        style={{ pointerEvents: 'all' }}>
            <path
                d={pathString}
                style={{
                    fill: "none",
                    stroke: strokeColor,
                    strokeWidth,
                    strokeDasharray,
                }}
                markerStart={enableStartMarker ? markerUrl : undefined}
                markerEnd={disableEndMarker ? undefined : markerUrl}
            />

            {/* This a thicker fake path to grab DOM events - makes clicking on the arrow more usable */}
            <path
                onDoubleClick={() => deleteArrow()}
                // onClick
                d={pathString}
                style={{
                fill: 'none',
                stroke: 'rgba(0, 0, 0, 0)',
                strokeWidth: strokeWidth * 10,
                cursor: 'pointer',
                }}
            />
            {arrowLabel && (
                <foreignObject
                    x={xLabel}
                    y={yLabel}
                    width={labelWidth}
                    height={labelHeight}
                    style={{ overflow: "visible", pointerEvents: "none" }}
                >
                    <div
                        style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            pointerEvents: "all",
                        }}
                    >
                        <div>{arrowLabel}</div>
                    </div>
                </foreignObject>
            )}
        </g>
    );
};

export default SvgArrow;
