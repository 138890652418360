import React from "react";
import { Button } from "react-bootstrap";
import { Element } from "react-scroll";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import VariableCreator from "common/VariableCreator";
import customSelectStyles from "common/SelectStyles";
import axios from "common/ServerConnection";
import tables from "common/Tables";
import variables from "common/Variables";
import dataScopes from "common/DataScopes";
import processTab from "common/utilities/processTab";
import { Permission } from "common/Permissions";
import { Type, Panel } from "common/InputData";
import {stringSessionId} from "common/SessionId";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                genericVariableCreatorTable: null,
                dataScope: null,
                genericVariables: [
                    {
                        name: null,
                        unit: null,
                        type: Type.Float,
                        level: null,
                        panel: Panel.Regular,
                        format: null,
                    },
                ],
                genericVariableCreatorCode: "",
                genericVariableCreatorTableHeader: null,
                genericVariableCreatorTablePreview: null,
                warning: null,
            };
            this.dataScopeValue = this.dataScopeValue.bind(this);
        }
        dataScopeValue() {
            if (this.state.dataScope) return this.state.dataScope.value;
            return undefined;
        }

        onGenericVariableCreatorCodeChange(e) {
            let value = e.target.value;
            this.setState({ genericVariableCreatorCode: value });
        }

        checkLiveStreamMode(dataScopeId) {
            axios
                .post("/api/get_live_stream_mode", {
                    data_table_idx: dataScopeId,
                })
                .then((response) => {
                    if (response.data.success) {
                        if (response.data.mode === "append") {
                            if (typeof dataScopeId === "number") {
                                this.setState({
                                    warning:
                                        "Live stream is enabled in append mode. Do not change existing columns in the code.",
                                });
                            } else {
                                this.setState({
                                    warning:
                                        "Data is regularly appended to this table. Do not change existing columns in the code.",
                                });
                            }
                        } else {
                            this.setState({
                                warning:
                                    "This code will be execute every time you update the data. Do not change existing columns in the code.",
                            });
                        }
                    } else {
                        console.log(response.data.error_msg);
                    }
                })
                .catch((error) => {
                    console.log(error.response.status.toString());
                });
        }

        generateNewVariablesSchema(preview = false) {
            if (!this.state.genericVariableCreatorTable) {
                this.setStepState(StepState.error, "Table is not selected");
                return;
            }
            let jsonRequest = {
                table: this.state.genericVariableCreatorTable.value,
                data_table_idx: this.state.genericVariableCreatorTable
                    .data_table_idx,
                condition_id: this.state.genericVariableCreatorTable
                    .condition_id,
                generic_variables: this.state.genericVariables
                    .filter((variable) => variable.name && variable.type)
                    .map((variable) => ({
                        ...variable,
                        panel: "regular",
                        level: null,
                    })),
                code: this.state.genericVariableCreatorCode,
                update_id: stringSessionId(),
            };
            if (preview) jsonRequest.preview = 5;
            this.setStepState(StepState.running);

            axios
                .post("/api/generate_new_variables_schema", jsonRequest, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        if (preview) {
                            this.setStepState(StepState.finished, null, {
                                genericVariableCreatorTableHeader:
                                    response.data.table_header,
                                genericVariableCreatorTablePreview:
                                    response.data.table_preview,
                            });
                        } else {
                            this.setStepState(StepState.finished);
                            variables(
                                this.state.genericVariableCreatorTable
                                    .data_table_idx
                            ).update();
                        }
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        render() {
            let tablePreview;
            if (this.state.genericVariableCreatorTablePreview !== null) {
                let tableContent = this.state.genericVariableCreatorTablePreview.map(
                    (row) =>
                        row.map(
                            (element) =>
                                element ||
                                (typeof element === "number" ? element : "-")
                        )
                );

                tablePreview = (
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            overflowX: "scroll",
                            backgroundColor: "transparent",
                            position: "relative",
                            overflowY: "scroll",
                        }}
                    >
                        <AdminTableWithFullFeatures
                            className="processing-page-table"
                            paging={false}
                            tableName="Data"
                            tableHeader={
                                this.state.genericVariableCreatorTableHeader
                            }
                            tableContent={tableContent}
                        />
                    </Element>
                );
            } else {
                tablePreview = null;
            }
            let rendered = [
                <div className="circle-letter" key={0}>
                    {this.props.index}
                </div>,
                <Button
                    key={1}
                    disabled={this.props.disabled}
                    className="btn-processing-tab"
                    style={{
                        height: "25px",
                    }}
                    onClick={() => {
                        this.setState((state) => ({
                            expanded: !state.expanded,
                        }));
                    }}
                >
                    <span className="content-regular-text">
                        Create dataframe
                    </span>
                    <span className="content-regular-text">
                        {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                    </span>
                </Button>,
                this.state.expanded ? (
                    <Button
                        key={2}
                        className="btn btn-lg btn-primary my-primary"
                        onClick={() => this.generateNewVariablesSchema()}
                        disabled={this.state.stepState === StepState.running}
                        style={{
                            fontFamily: "Roboto",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "25px",
                            width: "100%",
                            minWidth: "110px",
                        }}
                    >
                        CREATE
                    </Button>
                ) : (
                    <div key={2} style={{ minWidth: "110px" }} />
                ),
                <div key={3}>
                    {this.state.expanded && this.renderStepState()}
                </div>,
            ];
            if (this.state.expanded) {
                rendered = rendered.concat([
                    <div />,
                    <div
                        style={{
                            gridColumnStart: 2,
                            gridColumnEnd: 4,
                            minWidth: 0,
                            background: "#3f4b62",
                            padding: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                Select data
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions.filter(
                                    (option) =>
                                        option.permissionType ===
                                        Permission.ReadWrite
                                )}
                                onChange={(newValue) => {
                                    this.checkLiveStreamMode(newValue.value);
                                    this.setState({
                                        genericVariableCreatorTable: tables(
                                            newValue.value
                                        ).rawAndAggregateTableOptions[0],
                                        dataScope: newValue,
                                    });
                                }}
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                Using
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                    }),
                                }}
                                options={
                                    tables(this.dataScopeValue())
                                        .rawAndAggregateTableOptions
                                }
                                onChange={(newValue) => {
                                    this.setState({
                                        genericVariableCreatorTable: newValue,
                                    });
                                }}
                                value={this.state.genericVariableCreatorTable}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            className="content-regular-text"
                            style={{
                                marginBottom: "10px",
                            }}
                        >
                            Write python code to create new dataframe ('data' is
                            your dataset):
                        </div>
                        {this.state.warning != null && (
                            <div
                                style={{
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src="/dist/img/warning.png"
                                    alt=""
                                    style={{
                                        marginRight: 5,
                                    }}
                                />
                                <span className="content-regular-text">
                                    <b>WARNING:</b>
                                    {` ${this.state.warning}`}
                                </span>
                            </div>
                        )}
                        <div
                            style={{
                                padding: "10px",
                            }}
                        >
                            <textarea
                                className="like-select element"
                                style={{
                                    minHeight: "460px",
                                    width: "100%",
                                    fontFamily: "monospace",
                                    resize: "vertical",
                                    whiteSpace: "pre",
                                    marginBottom: "10px",
                                }}
                                spellCheck={false}
                                placeholder=""
                                onChange={(e) =>
                                    this.onGenericVariableCreatorCodeChange(e)
                                }
                                ref="genericVariableCreatorTextArea"
                                onKeyDown={(e) =>
                                    processTab(
                                        e,
                                        (
                                            newSelectionStart,
                                            newSelectionEnd,
                                            newValue
                                        ) => {
                                            this.setState(
                                                {
                                                    genericVariableCreatorCode: newValue,
                                                },
                                                () => {
                                                    this.refs.genericVariableCreatorTextArea.selectionStart = newSelectionStart;
                                                    this.refs.genericVariableCreatorTextArea.selectionEnd = newSelectionEnd;
                                                }
                                            );
                                        }
                                    )
                                }
                                value={this.state.genericVariableCreatorCode}
                            />
                        </div>
                        <VariableCreator
                            dataScopeId={this.dataScopeValue()}
                            value={this.state.genericVariables}
                            onChange={(newVariables) =>
                                this.setState({
                                    genericVariables: newVariables,
                                })
                            }
                        />
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginBottom: "10px",
                                marginTop: "10px",
                            }}
                            onClick={() => {
                                this.generateNewVariablesSchema(true);
                            }}
                        >
                            <span className="content-regular-text">
                                {this.state
                                    .genericVariableCreatorTablePreview === null
                                    ? "PREVIEW"
                                    : "UPDATE PREVIEW"}
                            </span>
                        </Button>
                        {tablePreview}
                    </div>,
                    <div />,
                ]);
            }

            return rendered;
        }
    }
);

export { MainComponent };
export let requirePermission = "GenerateNewVariablesSchema";