import React, { useState } from "react";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import cx from "classnames";

import { MapElement } from "common/Canvas";
import { DataScopeOption } from "common/DataScopes";
import remoteModuleId from "common/remoteModuleId";
import Tables, { TableOption } from "common/Tables";
import DataScopeAndTableSelectorView from "modules/canvas_page/question_views/RevampedDataScopeAndTableSelectorView";
import ConditionsConfig from "../ConditionsConfig";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./DataConfig.module.css";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

interface Props {
    mapElement: MapElement;
    height: number;
    onChange: (changes: Partial<MapElement>) => void;
    currentModuleId?: number;
    canvasTreeStore: CanvasTreeStore;
}

function DataConfig({ mapElement, height, currentModuleId, canvasTreeStore, onChange }: Props) {
    const [advancedOptionsIsOpen, setAdvancedOptionsIsOpen] = useState(false);

    function toggleOpenAdvancedOptions() {
        setAdvancedOptionsIsOpen((prev) => !prev);
    }

    return (
        <div className={styles.root}>
            <DataScopeAndTableSelectorView
                needWritePermissions={false}
                dataScopeOption={mapElement.dataScope}
                tableOption={mapElement.tableOption}
                maxHeight={height * 0.5}
                onChange={(
                    dataScopeOption: DataScopeOption | null,
                    tableOption: TableOption | null
                ) => {
                    const changes = {
                        dataScope: dataScopeOption,
                        usesCoordinates: false,
                        tableOption: tableOption,
                        tooltipVariables: undefined,
                        coordinates: null,
                        location: null,
                        selectOrderBy: null,
                        heatMap: null,
                        conditions: null,
                        center: null,
                        zoom: null,
                    };
                    if (mapElement.colorOptions != null) {
                        mapElement.colorOptions.lockMap = false;
                        mapElement.colorOptions.minZoomLevel = null;
                    }
                    onChange(changes);
                }}
                currentModuleId={currentModuleId}
            />
            <button
                type="button"
                className={styles.editMenuAdvancedCollapseButton}
                onClick={toggleOpenAdvancedOptions}
            >
                Advanced options
                <ChevronIcon
                    className={cx(styles.editMenuAdvancedCollapseButtonIcon, {
                        [styles.chevronOpen]: advancedOptionsIsOpen,
                    })}
                />
            </button>
            <Collapse in={advancedOptionsIsOpen}>
                <div className={styles.advancedMenu}>
                    {mapElement.dataScope && (
                        <>
                            <Select
                                placeholder="Select table"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: "4px",
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                    }),
                                }}
                                options={
                                    Tables(
                                        mapElement.dataScope!.value,
                                        currentModuleId ?? remoteModuleId
                                    ).rawAndAggregateTableOptions
                                }
                                onChange={(newValue) => {
                                    const changes = {
                                        dataScope: mapElement.dataScope,
                                        tableOption: newValue as TableOption,
                                    };
                                    onChange(changes);
                                }}
                                value={mapElement.tableOption}
                            />
                            <ConditionsConfig
                                canvasTreeStore={canvasTreeStore}
                                mapElement={mapElement}
                                onChange={onChange}
                                currentModuleId={currentModuleId}
                            />
                        </>
                    )}
                </div>
            </Collapse>
        </div>
    );
}

export default DataConfig;
