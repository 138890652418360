import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import styles from "./MediaCollectionsComponent.module.css";
import cx from "classnames";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import MessagePopup from "common/MessagePopup";
import {
    addNewCollectionApi,
    AdminCollection,
    collectionAddFileApi,
    collectionHash,
    CollectionType,
    CollectionTypeNames,
    deleteCollectionApi,
    deleteFileFromCollectionApi,
    deletePresetApi,
    editCollectionApi,
    getAllCollectionsApi,
    getFilesFromCollection,
    getPresetsApi,
    MediaFile,
    Preset,
    typeOptions,
} from "common/MediaCollectionsApi";
import {
    getCustomSelectStyleForDataSection,
    Styles,
} from "common/SelectStyles";
import Select, { createFilter } from "react-select";
import Dropzone from "react-dropzone";
import { ReactComponent as CrossIcon } from "icons/canvas/themes/cross.svg";
import { imageUrlPrefix } from "common/ServerConnection";
import { toBase64 } from "common/utilities/retrieveImageFromClipboard";
import opentype from "opentype.js";
import { FontLoaderAdmin } from "common/FontLoader";
import { Editor } from "common/react_draft_wysiwyg/src/index";

interface FileRemove {
    id: number;
    name: string;
    collectionId: number;
    type: CollectionType;
}

function MediaCollectionsComponent() {
    async function addCollection() {
        try {
            let newCollectionId = await addNewCollectionApi(
                newCollectionText,
                newCollectionType
            );
            setCollections((collections) => [
                ...collections,
                {
                    id: newCollectionId,
                    title: newCollectionText,
                    hidden: false,
                    type: newCollectionType,
                    hash: collectionHash(newCollectionId, newCollectionType),
                },
            ]);
            setCollectionFiles((collectionFiles) => ({
                ...collectionFiles,
                [collectionHash(newCollectionId, newCollectionType)]: [],
            }));
            setNewCollectionText("");

            setStatusPopup({
                status: PopupStatus.Success,
                message: "New collection is added",
            });
        } catch (error) {
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }

    async function uploadFiles(
        collectionId: number,
        acceptedFiles: File[],
        type: CollectionType
    ) {
        setUploading(true);
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Adding files",
        });
        for (let acceptedFile of acceptedFiles) {
            if (
                type === CollectionType.Image &&
                !acceptedFile.type.startsWith("image/")
            ) {
                setStatusPopup({
                    status: PopupStatus.Error,
                    message: `${acceptedFile.name} is not an image`,
                });
                setUploading(false);
                return;
            }
            let fontInfo: opentype.Font | null = null;
            if (type === CollectionType.Font) {
                try {
                    let url = await toBase64(acceptedFile);
                    fontInfo = await opentype.load(url);
                } catch (error) {
                    console.log(error);
                    setStatusPopup({
                        status: PopupStatus.Error,
                        message: `${acceptedFile.name} is not a font`,
                    });
                    setUploading(false);
                    return;
                }
            }

            try {
                let newFileId = await collectionAddFileApi(
                    collectionId,
                    acceptedFile,
                    acceptedFile.name
                );
                setCollectionFiles((collectionFiles) => {
                    let newCollectionFiles = { ...collectionFiles };
                    newCollectionFiles[collectionHash(collectionId, type)].push(
                        {
                            filename: acceptedFile.name,
                            id: newFileId,
                        }
                    );
                    return newCollectionFiles;
                });
                if (fontInfo != null) {
                    FontLoaderAdmin.addFont(fontInfo, newFileId);
                }
            } catch (error) {
                setStatusPopup({
                    status: PopupStatus.Error,
                    message: String(error),
                });
                setUploading(false);
                return;
            }
        }
        setUploading(false);
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Files are uploaded",
        });
    }

    async function renameCollection(
        collectionId: number,
        type: CollectionType,
        newTitle: string
    ) {
        try {
            await editCollectionApi(collectionId, type, newTitle);
            setCollections((collections) => {
                let newCollections = Array.from(collections);
                let index = collections.findIndex(
                    (item) => item.id === collectionId && item.type === type
                );
                if (index !== -1) {
                    newCollections[index].title = newTitle;
                }
                return newCollections;
            });
            setCollectionRename(null);
            setStatusPopup({
                status: PopupStatus.Success,
                message: "Changes are applied",
            });
        } catch (error) {
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }
    async function hideCollection(
        collectionId: number,
        type: CollectionType,
        hidden: boolean
    ) {
        try {
            await editCollectionApi(collectionId, type, undefined, hidden);
            setCollections((collections) => {
                let newCollections = Array.from(collections);
                let index = collections.findIndex(
                    (item) => item.id === collectionId && item.type === type
                );
                if (index !== -1) {
                    newCollections[index].hidden = hidden;
                }
                return newCollections;
            });
            setStatusPopup({
                status: PopupStatus.Success,
                message: "Changes are applied",
            });
        } catch (error) {
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }
    async function deleteCollection(
        collectionId: number,
        type: CollectionType
    ) {
        try {
            await deleteCollectionApi(collectionId, type);
            setCollectionRemove(null);
            setCollections((collections) =>
                collections.filter(
                    (item) => item.id !== collectionId || item.type !== type
                )
            );

            setStatusPopup({
                status: PopupStatus.Success,
                message: "Collection is deleted",
            });
        } catch (error) {
            setCollectionRemove(null);
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }
    async function deleteFile(
        fileId: number,
        collectionId: number,
        type: CollectionType
    ) {
        try {
            if (type === CollectionType.Text) {
                await deletePresetApi(fileId);
            } else await deleteFileFromCollectionApi(fileId);

            setFileRemove(null);
            setCollectionFiles((collectionFiles) => {
                let newCollectionFiles = { ...collectionFiles };
                newCollectionFiles[collectionHash(collectionId, type)] =
                    newCollectionFiles[
                        collectionHash(collectionId, type)
                    ].filter((file) => file.id !== fileId);
                return newCollectionFiles;
            });

            setStatusPopup({
                status: PopupStatus.Success,
                message: "File is deleted",
            });
        } catch (error) {
            setFileRemove(null);
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }
    let mediaCollectionsSelectorStyles: Styles = {
        ...getCustomSelectStyleForDataSection(14, false),
        container: (base) => ({
            ...base,
            marginLeft: "10px",
            width: "120px",
            height: "35px",
        }),
    };
    async function getCollections() {
        try {
            let collections = await getAllCollectionsApi();
            setCollections(collections);
            for (let collection of collections) {
                if (collection.type === CollectionType.Text) {
                    let presets = await getPresetsApi(collection.id, true);
                    setCollectionFiles((collectionFiles) => ({
                        ...collectionFiles,
                        [collection.hash]: presets,
                    }));
                } else {
                    let files = await getFilesFromCollection(
                        collection.id,
                        true
                    );
                    setCollectionFiles((collectionFiles) => ({
                        ...collectionFiles,
                        [collection.hash]: files,
                    }));
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    let [collections, setCollections] = useState<AdminCollection[]>([]);
    useEffect(() => {
        getCollections();
    }, []);
    let [newCollectionText, setNewCollectionText] = useState<string>("");
    let [newCollectionType, setNewCollectionType] = useState<CollectionType>(
        CollectionType.Image
    );
    let [hoverFile, setHoverFile] = useState<number | null>(null);
    let [expandedCollection, setExpandedCollection] =
        useState<AdminCollection | null>(null);
    let [collectionFiles, setCollectionFiles] = useState<{
        [key: number]: (MediaFile | Preset)[];
    }>({});
    let [uploading, setUploading] = useState<boolean>(false);

    let [collectionRemove, setCollectionRemove] =
        useState<AdminCollection | null>(null);
    let [fileRemove, setFileRemove] = useState<FileRemove | null>(null);
    let [collectionRename, setCollectionRename] = React.useState<{
        id: number;
        type: CollectionType;
        hash: number;
        newTitle: string;
    } | null>(null);

    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);

    let editInputRef = React.useRef<HTMLInputElement>(null);
    const makeDefault = () => {
        setCollectionRename(null);
        setCollectionRemove(null);
        setFileRemove(null);
        setStatusPopup(null);
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <div className={styles.toolbarContainer}>
                <div className={styles.searchContainer}>
                    <input
                        placeholder="Add new collection"
                        type="text"
                        value={newCollectionText}
                        onChange={(evt) => {
                            let value = evt.target.value;
                            setNewCollectionText(value);
                        }}
                        className={styles.searchInput}
                    />
                </div>
                <Select
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Variable"}
                    styles={mediaCollectionsSelectorStyles}
                    options={typeOptions}
                    onChange={(newValue) => {
                        setNewCollectionType(
                            (
                                newValue as {
                                    label: string;
                                    value: CollectionType;
                                }
                            ).value
                        );
                    }}
                    value={{
                        label: CollectionTypeNames[newCollectionType],
                        value: newCollectionType,
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                <button
                    disabled={!newCollectionText}
                    className={styles.manageButton}
                    style={{
                        marginLeft: 10,
                    }}
                    onClick={(evt) => {
                        addCollection();
                    }}
                >
                    Create
                </button>

                <div style={{ flexGrow: 1 }}></div>
            </div>
            <div className={styles.borderLine}></div>
            <div className={styles.grid}>
                <div className={styles.gridRow} style={{ paddingLeft: 53 }}>
                    <div style={{ width: "20%" }} className={styles.gridTitle}>
                        Name
                    </div>
                    <div style={{ width: "10%" }} className={styles.gridTitle}>
                        Available
                    </div>
                    <div style={{ width: "10%" }} className={styles.gridTitle}>
                        Type
                    </div>
                </div>
                {collections.map((collection, index) => (
                    <React.Fragment key={index}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div
                                style={{
                                    width: 53,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            ></div>
                            <div className={styles.gridRow}>
                                <div
                                    style={{ width: "20%" }}
                                    className={styles.gridItem}
                                >
                                    {collectionRename?.hash ===
                                    collection.hash ? (
                                        <input
                                            ref={editInputRef}
                                            className={styles.editInput}
                                            onChange={(evt) => {
                                                evt.stopPropagation();
                                                let newTitle = evt.target.value;
                                                setCollectionRename(
                                                    (collectionRename) => ({
                                                        ...collectionRename!,
                                                        newTitle: newTitle,
                                                    })
                                                );
                                            }}
                                            value={collectionRename.newTitle}
                                            onKeyDown={(evt) => {
                                                if (evt.key === "Enter") {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    renameCollection(
                                                        collectionRename!.id,
                                                        collectionRename!.type,
                                                        collectionRename!
                                                            .newTitle
                                                    );
                                                }
                                                if (evt.key === "Escape") {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    makeDefault();
                                                }
                                            }}
                                        ></input>
                                    ) : (
                                        collection.title
                                    )}
                                </div>

                                <div
                                    style={{ width: "10%" }}
                                    className={styles.gridItem}
                                >
                                    <div
                                        className="pretty p-default"
                                        contentEditable={false}
                                        style={{ justifySelf: "center" }}
                                    >
                                        <input
                                            disabled={
                                                collectionRemove != null ||
                                                collectionRename != null
                                            }
                                            type="checkbox"
                                            checked={!collection.hidden}
                                            onChange={() => {
                                                hideCollection(
                                                    collection.id,
                                                    collection.type,
                                                    !collection.hidden
                                                );
                                            }}
                                        />
                                        <div className="state p-primary">
                                            <label></label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{ width: "10%" }}
                                    className={styles.gridItem}
                                >
                                    {CollectionTypeNames[collection.type]}
                                </div>
                                <div className={styles.manageButtonContainer}>
                                    <button
                                        disabled={
                                            collectionRename != null ||
                                            collectionRemove != null
                                        }
                                        className={styles.manageButton}
                                        onClick={(evt) => {
                                            setCollectionRemove(collection);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                                <div className={styles.manageButtonContainer}>
                                    <button
                                        disabled={
                                            collectionRemove != null ||
                                            (collectionRename != null &&
                                                collectionRename.hash !==
                                                    collection.hash)
                                        }
                                        className={styles.manageButton}
                                        onClick={(evt) => {
                                            if (
                                                collectionRename != null &&
                                                collectionRename.hash ===
                                                    collection.hash
                                            ) {
                                                renameCollection(
                                                    collectionRename!.id,
                                                    collectionRename!.type,
                                                    collectionRename!.newTitle
                                                );
                                            } else {
                                                makeDefault();
                                                setCollectionRename({
                                                    id: collection.id,
                                                    type: collection.type,
                                                    newTitle: collection.title,
                                                    hash: collection.hash,
                                                });
                                                setTimeout(() => {
                                                    editInputRef.current?.focus();
                                                }, 0);
                                            }
                                        }}
                                    >
                                        {collectionRename?.hash ===
                                        collection.hash
                                            ? "Apply"
                                            : "Rename"}
                                    </button>
                                </div>
                                <div className={styles.manageButtonContainer}>
                                    <button
                                        className={styles.manageButton}
                                        onClick={(evt) => {
                                            if (
                                                expandedCollection?.hash ===
                                                collection.hash
                                            ) {
                                                setExpandedCollection(null);
                                            } else {
                                                setExpandedCollection(
                                                    collection
                                                );
                                            }
                                        }}
                                    >
                                        {expandedCollection?.hash ===
                                        collection.hash
                                            ? "Collapse"
                                            : "Expand"}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {collection.hash === expandedCollection?.hash && (
                            <div className={styles.collectionContainer}>
                                {collectionFiles[collection.hash].map(
                                    (file, fileIndex) => {
                                        return (
                                            <div
                                                className={cx(
                                                    "unselectable",
                                                    expandedCollection?.type ===
                                                        CollectionType.Image
                                                        ? styles.fileContainer :
                                                        expandedCollection?.type ===
                                                        CollectionType.Text ? styles.textContainer
                                                        : styles.fontContainer
                                                )}
                                                style={{
                                                    position: "relative",
                                                    marginRight: "10px",
                                                    marginBottom: "10px",
                                                }}
                                                key={fileIndex}
                                                onMouseEnter={() => {
                                                    setHoverFile(file.id);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverFile(null);
                                                }}
                                            >
                                                {expandedCollection?.type ===
                                                    CollectionType.Image && (
                                                    <img
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                        alt=""
                                                        src={`${imageUrlPrefix()}/media/${
                                                            file.id
                                                        }/content`}
                                                    ></img>
                                                )}
                                                {expandedCollection?.type ===
                                                    CollectionType.Text && (
                                                    <Editor
                                                        editorStyle={{
                                                            cursor: "pointer",
                                                        }}
                                                        toolbarHidden
                                                        readOnly
                                                        contentState={
                                                            (file as Preset)
                                                                .preset
                                                                .rawMetric
                                                        }
                                                    />
                                                )}
                                                {expandedCollection?.type ===
                                                    CollectionType.Font && (
                                                    <div
                                                        className="unselectable"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                            fontSize: "25px",
                                                            fontStyle: "normal",
                                                            fontWeight:
                                                                "normal",
                                                            fontFamily:
                                                                FontLoaderAdmin.fontsMap.get(
                                                                    file.id
                                                                ),
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                    >
                                                        {FontLoaderAdmin.fontsMap.get(
                                                            file.id
                                                        )}
                                                    </div>
                                                )}
                                                {hoverFile === file.id && (
                                                    <div
                                                        className="unselectable"
                                                        style={{
                                                            zIndex: 100,
                                                            position:
                                                                "absolute",
                                                            right: "10px",
                                                            top: "10px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={(evt) => {
                                                            evt.preventDefault();
                                                            evt.stopPropagation();
                                                            setFileRemove({
                                                                id: file.id,
                                                                name:
                                                                    collection.type ===
                                                                    CollectionType.Text
                                                                        ? "preset"
                                                                        : (
                                                                              file as MediaFile
                                                                          )
                                                                              .filename,
                                                                collectionId:
                                                                    collection.id,
                                                                type: collection.type,
                                                            });
                                                        }}
                                                    >
                                                        <CrossIcon />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                                {collection.type !== CollectionType.Text ? (
                                    <Dropzone
                                        disabled={uploading}
                                        onDrop={(acceptedFiles) => {
                                            uploadFiles(
                                                collection.id,
                                                acceptedFiles,
                                                collection.type
                                            );
                                        }}
                                    >
                                        {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                        }) => (
                                            <div
                                                {...getRootProps()}
                                                className={cx(
                                                    styles.fileContainer,
                                                    styles.fileContainerDragArea
                                                )}
                                            >
                                                <div
                                                    className="flex-simple-column"
                                                    style={{
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {!isDragActive && (
                                                        <>
                                                            <span
                                                                style={{
                                                                    cursor: "default",
                                                                    color: "#a3a3a3",
                                                                    fontSize:
                                                                        "20px",
                                                                    marginBottom: 10,
                                                                }}
                                                            >
                                                                Drop files
                                                            </span>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "row",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: 1,
                                                                        width: 40,
                                                                        backgroundColor:
                                                                            "#a3a3a3",
                                                                    }}
                                                                />
                                                                <span
                                                                    style={{
                                                                        margin: "0 15px",
                                                                        color: "#a3a3a3",
                                                                    }}
                                                                >
                                                                    or
                                                                </span>
                                                                <div
                                                                    style={{
                                                                        height: 1,
                                                                        width: 40,
                                                                        backgroundColor:
                                                                            "#a3a3a3",
                                                                    }}
                                                                />
                                                            </div>
                                                            <span
                                                                style={{
                                                                    margin: "0 15px",
                                                                    color: "#a3a3a3",
                                                                }}
                                                            >
                                                                click
                                                            </span>
                                                        </>
                                                    )}
                                                    {isDragActive && (
                                                        <span
                                                            style={{
                                                                cursor: "pointer",
                                                                marginTop:
                                                                    "6px",
                                                                color: "#869AAC",
                                                                fontSize:
                                                                    "15px",
                                                            }}
                                                        >
                                                            Drop File Here
                                                        </span>
                                                    )}
                                                </div>
                                                <input {...getInputProps()} />
                                            </div>
                                        )}
                                    </Dropzone>
                                ) : (
                                    <div
                                        className={cx(
                                            styles.fileContainer,
                                            styles.fileContainerDragArea
                                        )}
                                    >
                                        <div
                                            className="flex-simple-column"
                                            style={{
                                                alignItems: "center",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    cursor: "default",
                                                    color: "#a3a3a3",
                                                    fontSize: "20px",
                                                    margin: 10,
                                                }}
                                            >
                                                You can create text preset from
                                                slide
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {index < collections.length - 1 && (
                            <div className={styles.borderLine}></div>
                        )}
                    </React.Fragment>
                ))}
            </div>
            {collectionRemove != null && (
                <MessagePopup
                    title="Remove collection"
                    message={`Do you want to remove ${collectionRemove.title}?`}
                    onAccept={() => {
                        deleteCollection(
                            collectionRemove!.id,
                            collectionRemove!.type
                        );
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}
            {fileRemove != null && (
                <MessagePopup
                    title="Remove file"
                    message={`Do you want to remove ${fileRemove.name}?`}
                    onAccept={() => {
                        deleteFile(
                            fileRemove!.id,
                            fileRemove!.collectionId,
                            fileRemove!.type
                        );
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}
            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </div>
    );
}

export default observer(MediaCollectionsComponent);
