import React from "react";
import Select from "react-select";

import { NetworkFinding } from "common/Finding";
import { defaultNodeColor, defaultEdgeColor } from "common/Network";
import ColorPicker from "common/ColorPicker";
import remoteModuleId from "common/remoteModuleId";
import Variables, { VariableOption } from "common/Variables";

import styles from "./ColorConfig.module.css";
import { getCustomSelectStyleLight } from "common/SelectStyles";

enum ColoringType {
    Fixed = 0,
    Varied = 1,
}

interface ColoringOption {
    label: string;
    value: ColoringType;
}

const coloringOptions: ReadonlyArray<ColoringOption> = [
    { label: "Use fixed color", value: ColoringType.Fixed },
    { label: "Vary color by variable", value: ColoringType.Varied },
];

const nodeOptions: { label: string; value: boolean }[] = [
    {
        label: "Attach To Source Node",
        value: false,
    },
    {
        label: "Attach To Target Node",
        value: true,
    },
];

interface Props {
    type: "nodes" | "edges";
    config: NetworkFinding["config"];
    onChange: (config: NetworkFinding["config"], updateData?: boolean) => void;
    currentModuleId?: number;
}

function ColorConfig({ type, config, onChange, currentModuleId }: Props) {
    let color: string;
    let varyColorByVariable: string;
    let colorVariableIndex: string;
    let attachColorToTarget: string | null;
    let defaultColor: string;

    if (type === "nodes") {
        color = "nodeColor";
        varyColorByVariable = "varyNodeColorByVariable";
        colorVariableIndex = "nodeColorVariableIndex";
        attachColorToTarget = "attachNodeColorToTarget";
        defaultColor = defaultNodeColor;
    } else {
        color = "edgeColor";
        varyColorByVariable = "varyEdgeColorByVariable";
        colorVariableIndex = "edgeColorVariableIndex";
        attachColorToTarget = null;
        defaultColor = defaultEdgeColor;
    }

    const selectStyles = getCustomSelectStyleLight(
        14,
        false
    );

    return (
        <div className={styles.colorMenu}>
            <div className={styles.colorMenuFirstRow}>
                <div className={styles.markerMenu}>
                    <div className={styles.markerArea}>
                        <div
                            className={styles.markerIcon}
                            style={{
                                backgroundColor: config[color] ?? defaultColor,
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                            }}
                        />
                    </div>
                </div>
                <div className={styles.colorOptionsMenu}>
                    <div className={styles.colorSelect}>
                        <Select
                            placeholder=""
                            styles={{
                                ...selectStyles,
                                container: (provided) => ({
                                    ...provided,
                                    minWidth: 60,
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: 25,
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                }),
                            }}
                            options={coloringOptions}
                            onChange={(newValue) => {
                                onChange(
                                    {
                                        ...config,
                                        [varyColorByVariable]:
                                            (newValue as ColoringOption)
                                                .value === ColoringType.Varied,
                                    },
                                    true
                                );
                            }}
                            value={
                                config[varyColorByVariable]
                                    ? coloringOptions[1]
                                    : coloringOptions[0]
                            }
                        />
                    </div>
                    {!config[varyColorByVariable] && (
                        <ColorPicker
                            enableAlpha
                            value={config[color] ?? defaultColor}
                            onChange={(newValue) => {
                                onChange(
                                    {
                                        ...config,
                                        [color]: newValue,
                                    },
                                    true
                                );
                            }}
                            style={{
                                marginLeft: "8px",
                                borderRadius: "4px",
                                width: "32px",
                                height: "32px",
                            }}
                            inPopup
                        />
                    )}
                </div>
            </div>
            <div className={styles.colorMenuSecondRow}>
                {config[varyColorByVariable] && (
                    <div
                        className="flex-simple-column"
                        style={{
                            marginTop: "15px",
                        }}
                    >
                        {attachColorToTarget != null && (
                            <Select
                                placeholder=""
                                styles={{
                                    ...selectStyles,
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: 60,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: 25,
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                    }),
                                }}
                                options={nodeOptions}
                                onChange={(newValue) => {
                                    let newConfig: NetworkFinding["config"] = {
                                        ...config,
                                        [attachColorToTarget!]: (newValue as {
                                            value: boolean;
                                        }).value,
                                    };
                                    onChange(newConfig, true);
                                }}
                                value={
                                    config[attachColorToTarget]
                                        ? nodeOptions[1]
                                        : nodeOptions[0]
                                }
                            />
                        )}
                        <Select
                            isClearable={true}
                            placeholder=""
                            styles={{
                                ...selectStyles,
                                container: (provided) => ({
                                    ...provided,
                                    minWidth: 60,
                                    marginTop: "15px",
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: 25,
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                }),
                            }}
                            options={
                                Variables(
                                    config.dataScope?.value,
                                    currentModuleId ?? remoteModuleId
                                ).variableOptions
                            }
                            onChange={(newValue) => {
                                onChange(
                                    {
                                        ...config,
                                        [colorVariableIndex]:
                                            (newValue as VariableOption)
                                                ?.value ?? null,
                                    },
                                    true
                                );
                            }}
                            value={
                                config[colorVariableIndex] != null
                                    ? Variables(
                                          config.dataScope?.value,
                                          currentModuleId ?? remoteModuleId
                                      ).variableOptions[
                                          config[colorVariableIndex]
                                      ]
                                    : null
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ColorConfig;
