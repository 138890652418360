export const statsmodelsRegressions = ["ols", "gls", "glm"];

const sklearnRegressions = [
    "random_forest_regressor",
    "random_forest_classifier",
    "lasso",
    "elastic_net",
    "decision_tree_regressor",
    "ada_boost",
    "gradient_boosting_regressor"
];
export function isSklearn(model: string) {
    return sklearnRegressions.includes(model);
}

export const regressions: string[] = statsmodelsRegressions.concat(
    sklearnRegressions
);

export const families: { [key: string]: string[] } = {
    ols: [],
    gls: [],
    glm: ["gaussian", "binomial", "gamma", "inverse_gaussian", "poisson"],
    random_forest_regressor: [],
    random_forest_classifier: [],
    lasso: [],
    elastic_net: [],
    decision_tree_regressor: [],
    ada_boost: [],
    gradient_boosting_regressor: []
};
export const links: { [key: string]: string[] } = {
    binomial: ["logit", "probit", "cauchy", "log", "cloglog"],
    gamma: ["inverse", "log", "identity"],
    gaussian: ["identity", "log", "inverse"],
    inverse_gaussian: ["inverse_squared", "log", "identity", "inverse"],
    poisson: ["log", "identity", "sqrt"],
};