import "common/styles/App.css";
import modules from "../data_exploration_page/modules/module_list.json";

import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import DraggableJourneyCard from "./DraggableJourneyCard";
import MessagePopup from "common/MessagePopup";
import {
	addRecipesTabApi,
	getRecipesTabsApi,
	editRecipesTabApi,
	addRecipesConfigApi,
	getRecipesConfigsApi,
	editRecipesConfigApi,
	deleteRecipesTabApi,
	deleteRecipesConfigApi,
} from "common/UserApi";
import ExpandableTab from "common/insights_components/ExpandableTab";
import ScaledPage from "common/ScaledPage";
import Instrumentation from "common/Instrumentation";
import { ReactComponent as AssignmentIcon } from "icons/left_nav_menu/assignment.svg";

import styles from "./main.module.css";

class MainComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editedTab: "",
			recipesTabs: [],
			recipesConfigsMap: {},
			sections: {},
			expandedConfigsMap: {},
			removingPopupShowed: false,
			removingTabId: undefined,
			editedTabId: undefined,
		};
		this.onStartEditTab = this.onStartEditTab.bind(this);
		this.onEditTab = this.onEditTab.bind(this);
		this.onUpdateTab = this.onUpdateTab.bind(this);
		this.onTabOpen = this.onTabOpen.bind(this);
		this.getRecipesTabs = this.getRecipesTabs.bind(this);
		this.getRecipesConfigs = this.getRecipesConfigs.bind(this);
		this.onConfigExpanded = this.onConfigExpanded.bind(this);
		this.onCardsSwapped = this.onCardsSwapped.bind(this);
		this.onRemoveTab = this.onRemoveTab.bind(this);
		this.onEditConfig = this.onEditConfig.bind(this);
		this.onDeleteConfig = this.onDeleteConfig.bind(this);
		this.onCopyConfig = this.onCopyConfig.bind(this);
		this.onExpandTab = this.onExpandTab.bind(this);
		this.onDropItem = this.onDropItem.bind(this);
		this.submodules = {};
		modules.filter(module => !module.v2Only).forEach((module, index) => {
			module.submodules.filter(submodule => !submodule.v2Only).forEach((submodule) => {
				let mod = require(`../data_exploration_page/modules/${
					submodule.dir
				}/main.js`);
				submodule.mod = mod.MainComponent;
				this.submodules[submodule.name] = submodule;
			});
		});

		this.performance = null;
		this.mounted = false;
	}

	onExpandTab(tabId) {
		this.performance = new Date();
		this.setState(state => {
			let sections = Object.assign({}, state.sections);
			Object.keys(sections)
				.filter((key) => String(key) !== String(tabId))
				.forEach((key) => {
					sections[key] = false;
				});
			sections[tabId] = !sections[tabId];
			return { sections: sections };
			}, () => {
				this.onTabOpen(tabId);
			}
		);
	}

	onDropItem(item, tabId) {
		this.performance = new Date();
		let oldTabId = item.tab_id;
		item.tab_id = tabId;
		delete item.position;
		editRecipesConfigApi(item)
			.then(() => {
				this.setState(state => {
						let sections = Object.assign({}, state.sections);
						Object.keys(sections)
							.filter((key) => String(key) !== String(tabId))
							.forEach((key) => {
								sections[key] = false;
							});
						sections[tabId] = true;
						return { sections: sections };
					}, () => {
						this.getRecipesConfigs(tabId);
						this.getRecipesConfigs(oldTabId);
					}
			);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	buildRecipesTab(title, tabId) {
		return (
			<ExpandableTab
				acceptDrop={"recipes_config"}
				title={title}
				tabId={tabId}
				tabComponent={this}
			/>
		);
	}
	getRecipesTabs() {
		getRecipesTabsApi().then((recipesTabs) => {
			this.setState(state => {
				let sections = Object.assign({}, state.sections);
				recipesTabs.forEach((recipesTab) => {
					sections[recipesTab.id] =
						typeof sections[recipesTab.id] === "undefined"
							? false
							: sections[recipesTab.id];
				});
				return { recipesTabs: recipesTabs, sections: sections };
			});
		});
	}
	onRemoveTab(tabId) {
		this.setState({ removingPopupShowed: true, removingTabId: tabId });
	}
	onEditConfig(item, update) {
		if (update) {
			editRecipesConfigApi(item)
				.then(() => {
					this.getRecipesConfigs(item.tab_id);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			let recipesConfigsMap = Object.assign(
				{},
				this.state.recipesConfigsMap
			);
			let recipesConfigs = Array.from(recipesConfigsMap[item.tab_id]);
			let index = recipesConfigs.findIndex((element, index, array) => {
				return String(element.id) === String(item.id);
			});
			if (index < 0) return;
			recipesConfigs[index] = item;
			recipesConfigsMap[item.tab_id] = recipesConfigs;
			this.setState({ recipesConfigsMap: recipesConfigsMap });
		}
	}
	onDeleteConfig(item) {
		deleteRecipesConfigApi(item)
			.then(() => {
				this.getRecipesConfigs(item.tab_id);
			})
			.catch((error) => {
				console.log(error);
			});
	}
	onCopyConfig(item) {
		let newItem = Object.assign({}, item);
		addRecipesConfigApi(newItem.title, newItem.tab_id, newItem.config)
			.then((id) => {
				this.getRecipesConfigs(item.tab_id);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	onCardsSwapped(item, otherItem) {
		let copyItem = Object.assign({}, item);
		let copyOtherItem = Object.assign({}, otherItem);
		copyItem.position = otherItem.position;
		copyItem.tabId = otherItem.tabId;
		copyOtherItem.position = item.position;
		copyOtherItem.tabId = item.tabId;
		editRecipesConfigApi(copyItem)
			.then(() => {
				editRecipesConfigApi(copyOtherItem)
					.then(() => {
						this.setState(state => {
							let recipesConfigsMap = Object.assign(
								{},
								state.recipesConfigsMap
							);
							let recipesConfigs = Array.from(
								recipesConfigsMap[item.tab_id]
							);
							let firstIndex = recipesConfigs.indexOf(item);
							let secondIndex = recipesConfigs.indexOf(otherItem);
							recipesConfigs[firstIndex] = copyOtherItem;
							recipesConfigs[secondIndex] = copyItem;
							recipesConfigsMap[item.tab_id] = recipesConfigs;
							return { recipesConfigsMap: recipesConfigsMap };
						});
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	}
	getRecipesConfigs(tabId) {
		getRecipesConfigsApi(tabId)
			.then((recipesConfigs) => {
				this.setState(state => {
					let recipesConfigsMap = Object.assign(
						{},
						state.recipesConfigsMap
					);
					recipesConfigsMap[tabId] = recipesConfigs;
					return { recipesConfigsMap: recipesConfigsMap };
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	onTabOpen(tabId, force = false) {
		if (
			(this.state.sections[tabId] &&
				!this.state.recipesConfigsMap[tabId]) ||
			force
		) {
			this.getRecipesConfigs(tabId);
		}
	}

	componentDidMount() {
		if(this.performance != null) {
			let timeMs = new Date() - this.performance;
			this.performance = null;
			Instrumentation.addInteraction("Recipes", timeMs);
		}
		this.getRecipesTabs();
	}

	componentDidUpdate() {
		if(this.performance != null) {
			let timeMs = new Date() - this.performance;
			this.performance = null;
			Instrumentation.addInteraction("Recipes", timeMs);
		}
	}

	onEditTab(value, tabId) {
		if (!tabId) this.setState({ editedTab: value });
		else {
			this.setState(state => {
				let newRecipesTabs = Array.from(state.recipesTabs);
				let index = newRecipesTabs.findIndex((element, index, array) => {
					return String(element.id) === String(tabId);
				});
				if (index < 0) return;
				newRecipesTabs[index].title = value;
				return { recipesTabs: newRecipesTabs };
			});
		}
	}

	onStartEditTab(tabId) {
		this.setState({ editedTabId: tabId });
	}

	onUpdateTab(value, tabId) {
		if (value.length > 0) {
			this.performance = new Date();
			if (!tabId) {
				addRecipesTabApi(value)
					.then((id) => {
						this.setState({ editedTab: "" });
						this.getRecipesTabs();
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.setState({ editedTabId: undefined });
				editRecipesTabApi(value, tabId)
					.then(() => {
						this.getRecipesTabs();
					})
					.catch((error) => {
						console.log(error);
						this.getRecipesTabs();
					});
			}
		} else {
			this.getRecipesTabs();
		}
	}

	onConfigExpanded(configId, expanded) {
		this.setState(state => {
			let expandedConfigsMap = Object.assign(
				{},
				state.expandedConfigsMap
			);
			expandedConfigsMap[configId] = expanded;
			return { expandedConfigsMap: expandedConfigsMap };
		});
	}

	buildRecipesConfigItem(item) {
		if (!item) return null;
		let submodule = this.submodules[item.name];
		let expanded = this.state.expandedConfigsMap[item.id];
		return (
			<>
				{!expanded ? (
					<DraggableJourneyCard
						onCardsSwapped={(card1, card2) => {
							this.performance = new Date();
							this.onCardsSwapped(card1, card2);
						}}
						onCopy={card => {
							this.performance = new Date();
							this.onCopyConfig(card);
						}}
						onEdit={(card, update) => {
							this.performance = new Date();
							this.onEditConfig(card, update);
						}}
						onDelete={card => {
							this.performance = new Date();
							this.onDeleteConfig(card);
						}}
						item={item}
						imageSource={submodule.image_source}
						title={item.title}
						subtitle={submodule.subtitle}
						onClick={() => {
							this.onConfigExpanded(item.id, true);
						}}
					/>
				) : null}

				<submodule.mod
					{...submodule.additional_props}
					section="Recipes"
					title={item.title}
					config={item.config}
					imageSource={submodule.image_source}
					journeyName={item.name}
					key={
						expanded
							? String(item.id).concat("Expanded")
							: String(item.id).concat("Collapsed")
					}
					collapsed={!expanded}
					onNewFinding={this.props.onNewFinding}
					onClose={() => {
						this.onConfigExpanded(item.id, false);
					}}
				/>
			</>
		);
	}

	buildConfigsColumn(tabId) {
		return (
			<div
				className="journey-column flex-simple-column"
				key={tabId}
				style={{
					display: this.state.sections[tabId] ? "block" : "none",
				}}
			>
				{this.state.recipesConfigsMap[tabId] &&
					this.state.recipesConfigsMap[tabId].map((item) => {
						return (
							<div key={item.id}>
								{this.buildRecipesConfigItem(item)}
							</div>
						);
					})}
			</div>
		);
	}
	render() {
		if(!this.mounted) {
			this.performance = new Date();
			this.mounted = true;
		}
		return (
			<ScaledPage>
				<div className="content-wrapper hide-scroll">
					<section
						className="content"
						style={this.props.positionStyle}
					>
						<div>
							{this.state.recipesTabs.map((recipesTab) => (
								<div key={recipesTab.id}>
									{this.buildRecipesTab(
										recipesTab.title,
										recipesTab.id
									)}
									<CSSTransition
										key={recipesTab.id}
										timeout={500}
										in={this.state.sections[recipesTab.id]}
										classNames={"journey-column"}
									>
										{this.buildConfigsColumn(recipesTab.id)}
									</CSSTransition>
								</div>
							))}
							{this.buildRecipesTab(this.state.editedTab)}
						</div>
						{this.state.removingPopupShowed && (
							<MessagePopup
								danger
								title={"Delete tab"}
								acceptButtonTitle={"Delete"}
								message={"Do you want to delete this tab?"}
								onAccept={() => {
									this.performance = new Date();
									deleteRecipesTabApi(
										this.state.removingTabId
									)
										.then(() => {
											this.setState(state => {
												let sections = Object.assign({}, state.sections);
												delete sections[state.removingTabId];
												let recipesTabs = state.recipesTabs.filter(
													(item) =>
														String(item.id) !==
														String(
															state.removingTabId
														)
												);
												let recipesConfigsMap = Object.assign(
													{},
													state.recipesConfigsMap
												);
												let recipesConfigs =
													recipesConfigsMap[
														state.removingTabId
													];
												let expandedConfigsMap = Object.assign(
													{},
													state.expandedConfigsMap
												);
												if (recipesConfigs)
													recipesConfigs.forEach(
														(key) => {
															delete expandedConfigsMap[
																key.id
															];
														}
													);
												delete recipesConfigsMap[
													state.removingTabId
												];
												return {
													recipesTabs: recipesTabs,
													sections: sections,
													recipesConfigsMap: recipesConfigsMap,
													expandedConfigsMap: expandedConfigsMap,
													removingPopupShowed: false,
													removingTabId: undefined,
												};
											});
										})
										.catch((error) => {
											console.log(error);
											this.setState({
												removingPopupShowed: false,
												removingTabId: undefined,
											});
										});
								}}
								onReject={() => {
									this.setState({
										removingPopupShowed: false,
										removingTabId: undefined,
									});
								}}
							/>
						)}
						<div style={{ height: 500 }}> </div>
					</section>
				</div>
			</ScaledPage>
		);
	}
}

export { MainComponent };
export let route = "/recipes.html",
	section = "Recipes",
	leftPanelItem = {
		section: "Recipes",
		href: "recipes.html",
		icon: <AssignmentIcon className={styles.icon} />,
	},
	requirePermission = "Recipes";
