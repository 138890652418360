import React, { Component } from "react";
import { observer } from "mobx-react";
import Select, { createFilter, StylesConfig, GroupBase } from "react-select";
import { Button } from "react-bootstrap";

import customSelectStyles, {
    dataScienceSelectStyles,
} from "common/SelectStyles";
import UserGroupsStore, { UserGroupOption } from "common/UserGroupsStore";
import { GroupExtendedPermission } from "common/GroupPermissions";
import {
    Permission,
    PermissionNames,
    PermissionOptionType,
    permissionOptions,
} from "common/Permissions";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";


interface Props {
    selectedUserGroups: (GroupExtendedPermission | null)[];
    onChange: (selectedUserGroups: (GroupExtendedPermission | null)[]) => void;
    readOnly?: boolean;
    questionnaireStyle?: boolean;
}

@observer
class GroupsPermissionsSelector extends Component<Props> {
    render() {
        const selectStyles: StylesConfig<any, false, GroupBase<any>> = this.props.questionnaireStyle
            ? dataScienceSelectStyles
            : customSelectStyles;
        let options = UserGroupsStore.userGroupsOptions.filter(
            (item) =>
                this.props.selectedUserGroups.findIndex(
                    (selectedItem) => selectedItem?.group_id === item.id
                ) === -1
        );
        const selectedUserGroups: (GroupExtendedPermission | null)[] =
            this.props.selectedUserGroups.length > 0
                ? this.props.selectedUserGroups
                : [null];
        return (
            <div className="datasetGroup">
                {selectedUserGroups.map((userGroup, index) => (
                    <div
                        className="my-row"
                        key={index}
                        style={{ marginTop: "5px" }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <Select
                                isDisabled={this.props.readOnly}
                                placeholder={"Enter group"}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                menuPosition="absolute"
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        width: this.props.questionnaireStyle
                                            ? "210px"
                                            : "100%",
                                    }),
                                    option: (base) => ({
                                        ...base,
                                        "&:hover": {
                                            backgroundColor: "#d3d3d3",
                                            borderRadius: 6,
                                        }
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        zIndex: 1000000,
                                        position: 'absolute',
                                        width: '100%'
                                    })

                                }}
                                options={options}
                                value={
                                    userGroup
                                        ? {
                                            label: userGroup.group_name,
                                            value: userGroup.group_name,
                                            id: userGroup.group_id,
                                        }
                                        : null
                                }
                                onChange={(newValue) => {
                                    let newSelectedUserGroups = Array.from(
                                        selectedUserGroups
                                    );
                                    if (newSelectedUserGroups[index] == null) {
                                        newSelectedUserGroups[index] = {
                                            group_id: (newValue as UserGroupOption)
                                                .id,
                                            group_name: (newValue as UserGroupOption)
                                                .value,
                                            permission_type:
                                                //Permission.ReadOnly,
                                                Permission.ReadWrite,
                                        };
                                    } else {
                                        newSelectedUserGroups[index] = {
                                            ...(selectedUserGroups[
                                                index
                                            ] as GroupExtendedPermission),
                                            group_id: (newValue as UserGroupOption)
                                                .id,
                                            group_name: (newValue as UserGroupOption)
                                                .value,
                                        };
                                    }
                                    this.props.onChange(newSelectedUserGroups);
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            {this.props.questionnaireStyle && (
                                <div
                                    style={{
                                        height: "2px",
                                        backgroundColor:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                />
                            )}
                        </div>
                        {userGroup && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Select
                                    isDisabled={this.props.readOnly}
                                    placeholder={"Enter permissions"}
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    styles={{
                                        ...selectStyles,
                                        container: (base) => ({
                                            ...base,
                                            marginLeft: "5px",
                                            height: "38px",
                                            width: this.props.questionnaireStyle
                                                ? "210px"
                                                : "140px",
                                        }),
                                    }}
                                    options={permissionOptions}
                                    value={{
                                        value: userGroup.permission_type,
                                        label:
                                            PermissionNames[
                                            userGroup.permission_type as Permission
                                            ],
                                    }}
                                    onChange={(newValue) => {
                                        let newSelectedUserGroups = Array.from(
                                            selectedUserGroups
                                        );
                                        newSelectedUserGroups[index] = {
                                            ...(newSelectedUserGroups[
                                                index
                                            ] as GroupExtendedPermission),
                                            permission_type: (newValue as PermissionOptionType)
                                                .value,
                                        };

                                        this.props.onChange(
                                            newSelectedUserGroups
                                        );
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                {this.props.questionnaireStyle && (
                                    <div
                                        style={{
                                            height: "2px",
                                            backgroundColor: "#39F",
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        {!this.props.readOnly && (
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 5 }}
                            >
                                <Button
                                    className={
                                        this.props.questionnaireStyle
                                            ? "btn-small-questionnaire"
                                            : "btn-small-like-select"
                                    }
                                    title={"Add new group"}
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let newSelectedUserGroups = Array.from(
                                            selectedUserGroups
                                        );
                                        newSelectedUserGroups.splice(
                                            index + 1,
                                            0,
                                            null
                                        );
                                        this.props.onChange(
                                            newSelectedUserGroups
                                        );
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className={
                                        this.props.questionnaireStyle
                                            ? "btn-small-questionnaire"
                                            : "btn-small-like-select"
                                    }
                                    title={"Remove group"}
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let newSelectedUserGroups = Array.from(
                                            selectedUserGroups
                                        );
                                        newSelectedUserGroups.splice(index, 1);
                                        if (
                                            newSelectedUserGroups.length === 0
                                        ) {
                                            newSelectedUserGroups.push(null);
                                        }
                                        this.props.onChange(
                                            newSelectedUserGroups
                                        );
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    }
}

export default GroupsPermissionsSelector;
