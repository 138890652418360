
/**
 * Custom input component that can toggle
 * between voice input and text input
 */

import React from 'react';
import styles from './customInput.module.css';
import VoiceInput from '../VoiceInput';
import FloatingButton from '../FloatingButton';
import PromptTemplateBar from '../PromptTemplateBar';

interface ICustomInput {
  value: string;
  onChange: any;
  onKeyDown: any;
  handleSubmit: (value: string) => void;
  waitingResponse: boolean;
  micOn: boolean;
  setMicOn: (state: boolean) => void
}

const CustomInput: React.FC<ICustomInput> = ({value, onChange, onKeyDown, handleSubmit, waitingResponse, micOn, setMicOn}) => {
  const [initialHeight, setInitialHeight] = React.useState('');

  const toggleMic = () => {setMicOn(!micOn)};
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (textareaRef.current) {
      setInitialHeight(`${textareaRef.current.scrollHeight}px`);
      document.documentElement.style.setProperty('--textarea-height', `${textareaRef.current.scrollHeight}px`);
    }
  }, []);

  // QQQ: I don't understand how this connects to deepgram?
  React.useEffect(() => {
    //open connection to deepgram
  }, [micOn])

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const onKeyDownEvt = (e:React.KeyboardEvent<HTMLTextAreaElement>) => {
    if(e.key === 'Enter' && textareaRef.current) {
      textareaRef.current.style.height = initialHeight;
      textareaRef.current.blur();
    }
    onKeyDown?.(e)
  }

  return(
    <>
      <PromptTemplateBar 
            onOptSelected={(opt:string) => {handleSubmit(opt)}}
      />
      <div className={styles.contentWrapper}>        
        {(micOn)? 
          <VoiceInput waitingResponse={waitingResponse} handleSubmit={handleSubmit} />     
        :
          <div className={styles.textAreaWrapper}>
            <textarea 
              {...({ enterKeyHint: 'send' } as 
                React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
              ref={textareaRef}
              className={styles.slickPromptMobile}
              placeholder="Remind me of my routine"              
              value={value}
              onChange={onChange}
              onKeyDown={onKeyDownEvt} // This triggers when we submit or Enter              
              autoFocus
            />          
          </div>
        }
      </div>
      <div className={styles.floatingWrapper}>
        <FloatingButton waitingResponse={waitingResponse} onSubmit={(value.length)? () => {handleSubmit(value)}: undefined}  toggle={toggleMic} micOn={micOn}/>
      </div>
    </>
  )
}

export default CustomInput;