import React from "react";
import moment from "moment";

import { SingleLineAxisTick } from "./AxesTicks";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { colorList } from "./LineColors";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { LegendElement } from "./LegendElement";
import { LegendEditElement } from "./LegendEditElement";
import { formatValue } from "common/utilities/FormatValue";
import LinesSelector from "./LinesSelector";
import sections from "sections.json";
import { TooltipStyles } from "./TooltipStyles";

import {
    ComposedChart,
    Area,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceLine,
    Tooltip as RechartsTooltip,
    Label,
} from "recharts";
import { mainStyle } from "common/MainStyle";
import { timeLabelFormatter } from "./TimeChartUtils";

function TimeChart(props) {
    let {
        axesColor,
        data,
        additionalData,
        timeKey,
        variablesKeys,
        units,
        displayMode,
        dateFormat,
        colorMapping,
        nameMapping,
        editable,
        maxYRange,
        minYRange,
        linesCount,
        onFinishEdit,
        isTimeSeries,
    } = props;
    if (displayMode == null) displayMode = "Date";
    if (dateFormat == null) {
        dateFormat = {
            year: true,
            month: true,
            day: true,
        };
    } else {
        dateFormat = {
            year: dateFormat.indexOf("Year") >= 0,
            month: dateFormat.indexOf("Month") >= 0,
            day: dateFormat.indexOf("Day") >= 0,
        };
    }

    let firstRawDate = data?.[0]?.[timeKey];
    const bgDarkColor = mainStyle.getPropertyValue("--graphs-stroke-color");
    let lines = null; // Plot lines
    let type = "category";
    if (data.length > 0 && isTimeSeries != null) {
        if (typeof data[0][timeKey] === "number") type = "number";
    }

    if (variablesKeys.length !== 0) {
        lines = [
            <XAxis
                key={0}
                ticks={data.map((item) => item[timeKey])}
                domain={["dataMin", "dataMax"]}
                type={type}
                tickLine={false}
                axisLine={false}
                dataKey={timeKey}
                tick={
                    <SingleLineAxisTick
                        formatValue={(value) => {
                            return timeLabelFormatter(
                                value,
                                isTimeSeries,
                                dateFormat,
                                displayMode,
                                firstRawDate
                            );
                        }}
                        axesColor={axesColor}
                        dx={16}
                        dy={8}
                        angle={-15}
                    />
                }
            />,
            <YAxis
                domain={[minYRange ?? "dataMin", maxYRange ?? "dataMax"]}
                interval={0}
                key={1}
                tickCount={linesCount ?? undefined}
                allowDataOverflow={minYRange != null || maxYRange != null}
                tick={
                    <CustomizedAxisTick
                        axesColor={axesColor}
                        unit={units}
                        formatValues={true}
                        truncValues={false}
                        fontSize={mainStyle.getPropertyValue(
                            "--graphs-axes-size"
                        )}
                        dx={0}
                        dy={0}
                        angle={0}
                    />
                }
                tickLine={false}
                axisLine={false}
            />,
        ];
    }

    let dot = (color) => false;
    if (data.length === 1) {
        dot = (color) => ({
            fill: color,
            strokeWidth: 0,
            r: 5,
        });
        variablesKeys.forEach((variableKey, index) => {
            lines.push(
                <Area
                    isAnimationActive={false}
                    key={index + 2}
                    type="monotone"
                    dataKey={variableKey}
                    dot={dot(
                        colorMapping?.[variableKey] ??
                            colorList[index % colorList.length]
                    )}
                    stroke={
                        colorMapping?.[variableKey] ??
                        colorList[index % colorList.length]
                    }
                    strokeWidth={3}
                    fillOpacity={1}
                    fill={`url(#color_variable)`}
                />
            );
        });
    } else {
        variablesKeys.forEach((variableKey, index) => {
            lines.push(
                <Line
                    key={index + 2}
                    strokeWidth={2}
                    isAnimationActive={false}
                    dataKey={variableKey}
                    dot={false}
                    stroke={
                        colorMapping?.[variableKey] ??
                        colorList[index % colorList.length]
                    }
                />
            );
        });
    }
    let plot = (
        <div
            className="flex-column"
            style={{ alignItems: "center", height: "100%", width: "100%" }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                }}
            >
                <div style={{ height: "100%", overflow: "hidden" }}>
                    <ResponsiveContainer height="99%">
                        <ComposedChart
                            margin={{
                                top: 10,
                            }}
                            data={data}
                        >
                            {sections._Global.watermark != null && (
                                <text
                                    x="50%"
                                    y="30%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fill={mainStyle.getPropertyValue(
                                        "--content-secondary-text-color"
                                    )}
                                    fontSize={"4rem"}
                                    style={{
                                        opacity: 0.1,
                                    }}
                                >
                                    {sections._Global.watermark}
                                </text>
                            )}
                            <CartesianGrid
                                strokeDasharray="3 3"
                                stroke={bgDarkColor}
                                strokeWidth="2"
                            />
                            <RechartsTooltip
                                labelFormatter={(value) => {
                                    return timeLabelFormatter(
                                        value,
                                        isTimeSeries,
                                        dateFormat,
                                        displayMode,
                                        firstRawDate
                                    );
                                }}
                                formatter={(value, name, props) => {
                                    let formattedValue = formatValue(
                                        value,
                                        false
                                    );
                                    return [
                                        formattedValue[0].concat(
                                            formattedValue[1]
                                        ),
                                        nameMapping?.[name] ?? name,
                                    ];
                                }}
                                {...TooltipStyles()}
                            />
                            {lines}
                            {variablesKeys.length === 0 && (
                                <text
                                    x="50%"
                                    y="50%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fill="white"
                                    fontSize="2rem"
                                >
                                    NO DATA FOR SELECTED FILTERS
                                </text>
                            )}
                            {additionalData?.map((line, index) => {
                                let props = {
                                    key: variablesKeys.length + index + 2,
                                    label: (
                                        <Label
                                            dy={-8}
                                            value={String(line.value)}
                                            position="insideLeft"
                                            fontSize={mainStyle.getPropertyValue(
                                                "--graphs-axes-text-size"
                                            )}
                                            fill={
                                                axesColor ??
                                                mainStyle.getPropertyValue(
                                                    "--graphs-axes-text-color"
                                                )
                                            }
                                        />
                                    ),
                                    stroke: line.color,
                                    strokeWidth: 2,
                                };
                                if (line.horizontal) {
                                    props.y = line.value;
                                } else {
                                    if (isTimeSeries) {
                                        props.x = moment.utc(line.value).unix();
                                    } else props.x = line.value;
                                }
                                return <ReferenceLine {...props} />;
                            })}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {editable ? (
                        <input
                            defaultValue={nameMapping?.[timeKey] ?? timeKey}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            placeholder="Enter X-Axis Name"
                            onBlur={(e) => {
                                let label = e.target.value;
                                e.preventDefault();
                                onFinishEdit(timeKey, label);
                            }}
                            style={{
                                marginTop: "5px",
                                width: "110px",
                                alignSelf: "center",
                                backgroundColor: "transparent",
                                outline: "none",
                                color:
                                    axesColor ??
                                    mainStyle.getPropertyValue(
                                        "--graphs-axes-text-color"
                                    ),
                                fontFamily: "Arial",
                                fontSize:
                                    mainStyle.getPropertyValue(
                                        "--graphs-axes-size"
                                    ),
                            }}
                        ></input>
                    ) : (
                        <span
                            style={{
                                alignSelf: "center",
                                textAlign: "right",
                                color:
                                    axesColor ??
                                    mainStyle.getPropertyValue(
                                        "--graphs-axes-text-color"
                                    ),
                                fontFamily: "Arial",
                                fontSize:
                                    mainStyle.getPropertyValue(
                                        "--graphs-axes-size"
                                    ),
                            }}
                        >
                            {nameMapping?.[timeKey] ?? timeKey}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
    return plot;
}

export default function TimePatternsChart(props) {
    let {
        editable,
        data,
        additionalData,
        timeKey,
        displayMode,
        dateFormat,
        config,
        isTimeSeries,
    } = props;
    let {
        nameMapping,
        labelMapping,
        colorMapping,
        legendsColor,
        titlesColor,
        axesColor,
        maxYRange,
        minYRange,
        linesCount,
        yAxisName,
    } = config ?? {};
    if (data.length === 0) return null;
    return (
        <div
            className="my-row"
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            {editable && (
                <LinesSelector
                    onNewLine={props.onNewLine}
                    onClearLines={props.onClearLines}
                />
            )}
            {data.map((series, serieIndex) => {
                let key = "";
                if (series.where) {
                    key = `${series.where.group || series.where.group_name} ${
                        series.where.operation ?? "="
                    } ${series.where.value}`;
                }
                let curves = series["curves"];
                let units = series["units"];

                //if (curves.length === 0) return null;
                let variablesKeys;
                if (curves.length !== 0) {
                    variablesKeys = Object.keys(curves[0]).filter(
                        (item) => item !== timeKey
                    );
                } else {
                    variablesKeys = [];
                }
                let contentWidth = data.length === 1 ? "100%" : "50%";
                return (
                    <div
                        className="flex-simple-column"
                        key={serieIndex}
                        style={{ width: contentWidth, height: "100%" }}
                    >
                        <div
                            className="my-row"
                            style={{ justifyContent: "center" }}
                        >
                            {serieIndex === 0 && editable && (
                                <LegendEditElement
                                    allowColorChange
                                    disallowTextChange
                                    onFinishColor={(color) => {
                                        props.onConfigChange(
                                            "titlesColor",
                                            color
                                        );
                                    }}
                                    key={variablesKeys.length + 2}
                                    color={
                                        titlesColor ??
                                        mainStyle
                                            .getPropertyValue(
                                                "--graphs-legends-text-color"
                                            )
                                            .trim()
                                    }
                                    text={"Titles Color"}
                                />
                            )}
                            {serieIndex === 0 && editable && (
                                <LegendEditElement
                                    allowColorChange
                                    disallowTextChange
                                    onFinishColor={(color) => {
                                        props.onConfigChange(
                                            "legendsColor",
                                            color
                                        );
                                    }}
                                    key={variablesKeys.length + 1}
                                    color={
                                        legendsColor ??
                                        mainStyle
                                            .getPropertyValue(
                                                "--graphs-legends-text-color"
                                            )
                                            .trim()
                                    }
                                    text={"Legends Color"}
                                />
                            )}
                            {editable && serieIndex === 0 && (
                                <LegendEditElement
                                    allowColorChange
                                    disallowTextChange
                                    onFinishColor={(color) => {
                                        props.onConfigChange(
                                            "axesColor",
                                            color
                                        );
                                    }}
                                    key={variablesKeys.length + 3}
                                    color={
                                        axesColor ??
                                        mainStyle
                                            .getPropertyValue(
                                                "--graphs-axes-text-color"
                                            )
                                            .trim()
                                    }
                                    text={"Axes Color"}
                                />
                            )}
                            {editable ? (
                                <input
                                    defaultValue={labelMapping?.[key] ?? key}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                    placeholder=""
                                    onBlur={(e) => {
                                        e.preventDefault();
                                        props.onFinishEditLabel(
                                            key,
                                            e.target.value
                                        );
                                    }}
                                    style={{
                                        marginLeft: "10px",
                                        width: "100px",
                                        alignSelf: "center",
                                        backgroundColor: "transparent",
                                        outline: "none",
                                        color:
                                            titlesColor ??
                                            mainStyle.getPropertyValue(
                                                "--exploration-tertiary-text-color"
                                            ),
                                        fontFamily: "Arial",
                                        fontSize: mainStyle.getPropertyValue(
                                            "--graphs-legends-size"
                                        ),
                                    }}
                                ></input>
                            ) : (
                                <span
                                    style={{
                                        color:
                                            titlesColor ??
                                            mainStyle.getPropertyValue(
                                                "--exploration-tertiary-text-color"
                                            ),
                                        fontFamily: "Arial",
                                        fontSize: mainStyle.getPropertyValue(
                                            "--graphs-legends-size"
                                        ),
                                    }}
                                >
                                    {labelMapping?.[key] ?? key}
                                </span>
                            )}
                        </div>

                        <div
                            className="my-row"
                            style={{
                                justifyContent: "center",
                                marginTop: "10px",
                                marginLeft: 20,
                                marginRight: 20,
                                flexWrap: "wrap",
                            }}
                        >
                            {variablesKeys.map((barKey, itemIndex) => {
                                return editable ? (
                                    <LegendEditElement
                                        inputStyle={{ width: "100px" }}
                                        allowColorChange
                                        onFinishEdit={(label) => {
                                            props.onFinishEdit(
                                                serieIndex,
                                                barKey,
                                                label
                                            );
                                        }}
                                        onFinishColor={(color) => {
                                            props.onFinishColor(
                                                serieIndex,
                                                barKey,
                                                color
                                            );
                                        }}
                                        key={itemIndex}
                                        color={
                                            colorMapping?.[serieIndex]?.[
                                                barKey
                                            ] ??
                                            colorList[
                                                itemIndex % colorList.length
                                            ]
                                        }
                                        text={
                                            nameMapping?.[serieIndex]?.[
                                                barKey
                                            ] ?? barKey
                                        }
                                        textColor={legendsColor}
                                    />
                                ) : (
                                    <LegendElement
                                        key={itemIndex}
                                        textColor={legendsColor}
                                        color={
                                            colorMapping?.[serieIndex]?.[
                                                barKey
                                            ] ??
                                            colorList[
                                                itemIndex % colorList.length
                                            ]
                                        }
                                        text={
                                            nameMapping?.[serieIndex]?.[
                                                barKey
                                            ] ?? barKey
                                        }
                                    />
                                );
                            })}
                        </div>
                        <div
                            className="my-row"
                            style={{ width: "100%", height: "100%" }}
                        >
                            {serieIndex === 0 ? (
                                editable ? (
                                    <input
                                        defaultValue={yAxisName}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        placeholder="Enter Y-Axis Name"
                                        onBlur={(e) => {
                                            e.preventDefault();
                                            props.onConfigChange(
                                                "yAxisName",
                                                e.target.value
                                            );
                                        }}
                                        style={{
                                            width: "110px",
                                            alignSelf: "center",
                                            backgroundColor: "transparent",
                                            outline: "none",
                                            color:
                                                axesColor ??
                                                mainStyle.getPropertyValue(
                                                    "--graphs-axes-text-color"
                                                ),
                                            fontFamily: "Arial",
                                            fontSize:
                                                mainStyle.getPropertyValue(
                                                    "--graphs-axes-size"
                                                ),
                                        }}
                                    ></input>
                                ) : yAxisName != null ? (
                                    <span
                                        style={{
                                            alignSelf: "center",
                                            textAlign: "right",
                                            color:
                                                axesColor ??
                                                mainStyle.getPropertyValue(
                                                    "--graphs-axes-text-color"
                                                ),
                                            fontFamily: "Arial",
                                            fontSize:
                                                mainStyle.getPropertyValue(
                                                    "--graphs-axes-size"
                                                ),
                                        }}
                                    >
                                        {yAxisName}
                                    </span>
                                ) : null
                            ) : null}
                            <TimeChart
                                isTimeSeries={isTimeSeries}
                                additionalData={additionalData}
                                maxYRange={maxYRange}
                                minYRange={minYRange}
                                linesCount={linesCount ?? undefined}
                                units={units}
                                timeKey={timeKey}
                                data={curves}
                                variablesKeys={variablesKeys}
                                displayMode={displayMode}
                                dateFormat={dateFormat}
                                colorMapping={colorMapping?.[serieIndex]}
                                nameMapping={nameMapping?.[serieIndex]}
                                editable={editable}
                                axesColor={axesColor}
                                onFinishEdit={(barKey, label) => {
                                    props.onFinishEdit(
                                        serieIndex,
                                        barKey,
                                        label
                                    );
                                }}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
