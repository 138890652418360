import React, { Component } from "react";
import Portal from "common/Portal";
import CanvasTreeStore from "../CanvasTreeStore";
import { observer } from "mobx-react";
import StringUtils from "common/utilities/StringUtils";
import ColorPicker from "common/ColorPicker";
import CanvasIconsContainer from "../CanvasIconsContainer";
import { mainStyle } from "common/MainStyle";
import LastNodeOptionsStorage from "../LastNodeOptionsStorage";
import styles from "./PlacementToolbar.module.css";
import {
    buttonStyleOptions,
    dropdownStyleOptions,
    ButtonStyleOption,
    DropdownStyleOption,
    PageBarStyleOption,
    pageBarStyleOptions,
    GridColorOptions,
    ShapeElement,
    ShapeStyle,
    ShapeType,
    getDefaultShapeColor,
    ItemMetadata,
    CanvasNode,
    CanvasType,
    CanvasSubmitButton,
    CanvasElement,
    isTextBox,
    isBox,
    isInput,
    isSubmitButton,
    isDropdownSelector,
    isBarcodeReader,
    isProgressElement,
    MapElement,
    MapColorOptions,
    CanvasDashboard,
    ColorOptions,
    CanvasDropdownSelector,
    SlideRect,
    isDateFormat,
    ColumnFormat,
    CanvasSpreadSheetGrid,
    getPrettyPrintFormatValue,
    CanvasBackground,
    getNodeTranslation,
    getNodeTranslationFromBackground,
    defaultColorOptions,
    CanvasToggle,
    CanvasInput,
    inputFieldStyleOptions,
    InputFieldStyleOption,
    RadioButtonsGroupStyleOption,
    radioButtonStyleOption,
    CanvasRadioButtonsGroup,
    isRadioButtonsGroup,
    ButtonStyle,
    CanvasSlider,
    InputFieldStyle,
    CanvasTextBox,
} from "common/Canvas";
import CurrentModulesStore from "common/CurrentModulesStore";
import Select, { createFilter } from "react-select";
import customSelectStyles, {
    customSelectStylesSmall,
    customSelectStylesFontFace,
} from "common/SelectStyles";
import LinkOptionsPopup from "../link_components/LinkOptionsPopup";
import LinkDropup from "../link_components/LinkDropup";
import AutosizeInput from "react-input-autosize";
import cx from "classnames";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import { defaultLabelSize, fontFamilies } from "../Constants";
import FontLoader from "common/FontLoader";
import StringOption from "common/StringOption";
import Draggable from "react-draggable";
import { ReactComponent as BorderRadiusIcon } from "icons/toolbar/border_radius.svg";
import { CanvasEditedElement } from "../canvas_elements/FlowChartProps";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    currentModuleId: number;
    spreadSheetGridId?: string;
    shapeElementId?: string;
    nodeId?: number;
    backgroundId?: number;
    mapElementId?: string;
    dashboardId?: string;
    onUpdateSelectionBounds: () => void;
    metadata?: ItemMetadata[];
    slideRect: SlideRect;
    fontOptions?: {
        fontColor: string;
        fontSize: number;
        fontForLabel: boolean;
    };
    onUpdateSelection: (value: {
        fontColor?: string;
        fontSize?: number;
        labelColor?: string;
        fontFamily?: string;
        fillColor?: string;
        borderShadow?: boolean;
        labelSize?: number;
        inputFieldStyle?: InputFieldStyle;
        buttonStyle?: ButtonStyle;
    }) => void;
    onClearEditing: () => void;
    onKeepSimpleEditChanges: () => void;
    placementToolbarSimpleEdit: any;
    editedNode: CanvasEditedElement | undefined;
    onChangeEditedNode: (node: CanvasEditedElement) => void;
}

interface State {
    showLinkPopupOptions: {
        x: number;
    } | null;
    color: string | undefined | null;
    showColorPicker: {
        gradient: boolean;
        border: boolean;
        fill: boolean;
        font: boolean;
        label: boolean;
        labelBorder: boolean;
        labelFill: boolean;
    };
}

interface ItemProps {
    disabled?: boolean;
    label: string;
    value: number | undefined;
    style?: React.CSSProperties;
    onChange: (value: number) => void;
}

interface ItemState {
    value: string;
}

class EditOptionItem extends Component<ItemProps, ItemState> {
    constructor(props: ItemProps) {
        super(props);
        this.state = {
            value: props.value?.toFixed() ?? "Mixed",
        };
    }

    public componentDidUpdate(prevProps: ItemProps): void {
        if (!prevProps) return;
        let oldValue = prevProps.value?.toFixed();
        let newValue = this.props.value?.toFixed();
        if (oldValue !== newValue) {
            this.setState({
                value: newValue ?? "Mixed",
            });
        }
    }

    public render(): JSX.Element {
        let disabled = this.props.disabled ?? false;
        return (
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    width: this.props.label ? 65 : 30,
                    height: 23,
                    ...this.props.style,
                }}
            >
                {this.props.label && (
                    <span
                        className={styles.labelCanvasAlignment}
                        style={{ minWidth: 12, marginLeft: 3 }}
                    >
                        {this.props.label}
                    </span>
                )}
                <input
                    disabled={disabled}
                    onChange={(evt) => {
                        let newValue = evt.target.value;
                        this.setState({
                            value: newValue,
                        });
                        if (StringUtils.isNumber(newValue)) {
                            let numValue = Number(newValue);
                            this.props.onChange(numValue);
                        }
                    }}
                    className={cx(styles.inputCanvasAlignment, "cancel-drag")}
                    style={{
                        overflow: "hidden",
                        marginLeft: 3,
                    }}
                    value={this.state.value}
                />
            </div>
        );
    }
}

@observer
class PlacementToolbar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showLinkPopupOptions: null,
            color: props.fontOptions?.fontColor,
            showColorPicker: {
                gradient: false,
                font: false,
                label: false,
                border: false,
                fill: false,
                labelBorder: false,
                labelFill: false,
            },
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (
            prevProps.fontOptions?.fontColor !==
            this.props.fontOptions?.fontColor
        ) {
            this.setState({
                color: this.props.fontOptions?.fontColor,
            });
        }
    }

    public closeColorPickers(openedPopup: string, isOpened: boolean = true) {
        this.setState({
            showColorPicker: {
                gradient: false,
                border: false,
                fill: false,
                font: false,
                label: false,
                labelBorder: false,
                labelFill: false,
                [openedPopup]: isOpened,
            },
        });
    }

    private renderSpreadSheetColorPickers(
        colorOptions: GridColorOptions | undefined
    ): JSX.Element | null {
        if (this.props.spreadSheetGridId == null || colorOptions == null) {
            return null;
        }
        return (
            <>
                <ColorPicker
                    enableAlpha
                    topAppearance
                    customView={
                        <CanvasIconsContainer
                            type="FillColor"
                            options={{
                                stroke: colorOptions.fillColor,
                                fill: colorOptions.fillColor,
                                width: 20,
                                height: 20,
                            }}
                        />
                    }
                    tooltip="Fill Color"
                    value={colorOptions.fillColor}
                    onChange={(newValue) => {
                        this.props.canvasTreeStore.updateGridAction(
                            this.props.spreadSheetGridId!,
                            {
                                colorOptions: {
                                    ...colorOptions,
                                    fillColor: newValue,
                                } as GridColorOptions,
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                    onOpen={(isOpened) => {
                        this.closeColorPickers("fill", isOpened);
                    }}
                    onClickOutside={() => {
                        this.closeColorPickers("fill", false);
                    }}
                    opened={this.state.showColorPicker.fill}
                    offset={{
                        bottom: 34,
                    }}
                />
                <ColorPicker
                    enableAlpha
                    topAppearance
                    customView={
                        <CanvasIconsContainer
                            type={"BorderColor"}
                            options={{
                                stroke: colorOptions.borderColor,
                                fill: colorOptions.borderColor,
                            }}
                        />
                    }
                    tooltip="Divider Color"
                    value={colorOptions.borderColor}
                    onChange={(newValue) => {
                        this.props.canvasTreeStore.updateGridAction(
                            this.props.spreadSheetGridId!,
                            {
                                colorOptions: {
                                    ...colorOptions,
                                    borderColor: newValue,
                                } as GridColorOptions,
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                    onOpen={(isOpened) => {
                        this.closeColorPickers("border", isOpened);
                    }}
                    onClickOutside={() => {
                        this.closeColorPickers("border", false);
                    }}
                    opened={this.state.showColorPicker.border}
                    offset={{
                        bottom: 34,
                    }}
                />
                <button
                    title={"Border Shadow"}
                    style={{
                        marginLeft: 12,
                        padding: "0 0 0 0",
                        width: "24px",
                        height: "24px",
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    onClick={() => {
                        this.props.canvasTreeStore.updateGridAction(
                            this.props.spreadSheetGridId!,
                            {
                                colorOptions: {
                                    ...colorOptions,
                                    borderShadow: !colorOptions!.borderShadow,
                                } as GridColorOptions,
                            }
                        );
                    }}
                >
                    <CanvasIconsContainer
                        type={"BorderShadow"}
                        options={undefined}
                    />
                </button>
            </>
        );
    }

    private renderShapeElementColorPickers(
        shapeElement: ShapeElement | undefined
    ): JSX.Element | null {
        if (shapeElement == null) {
            return null;
        }
        let fillColor: string =
            shapeElement.shapeStyle?.fillColor ??
            getDefaultShapeColor(shapeElement.shapeType);
        let borderColor: string =
            shapeElement.shapeStyle?.borderColor ?? fillColor;
        const borderWidth = shapeElement.shapeStyle?.borderWidth ?? 0;
        return (
            <div className={styles.itemContainer}>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    customView={
                        <CanvasIconsContainer
                            type="FillColor"
                            options={{
                                stroke: fillColor,
                                fill: fillColor,
                            }}
                        />
                    }
                    tooltip="Fill Color"
                    value={fillColor}
                    onChange={(newValue) => {
                        this.props.canvasTreeStore.updateShapeElementAction(
                            this.props.shapeElementId!,
                            {
                                shapeStyle: {
                                    ...shapeElement.shapeStyle,
                                    fillColor: newValue,
                                } as ShapeStyle,
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                    onOpen={(isOpened) => {
                        this.closeColorPickers("fill", isOpened);
                    }}
                    onClickOutside={() => {
                        this.closeColorPickers("fill", false);
                    }}
                    opened={this.state.showColorPicker.fill}
                    offset={{
                        bottom: 34,
                    }}
                />
                {shapeElement.shapeType !== ShapeType.Arrow && (
                    <ColorPicker
                        isGradient
                        topAppearance
                        enableAlpha
                        customView={
                            <CanvasIconsContainer
                                type="FillColor"
                                options={{
                                    stroke: fillColor,
                                    fill: fillColor,
                                }}
                            />
                        }
                        tooltip="Gradient Color"
                        value={fillColor}
                        onChange={(newValue) => {
                            this.props.canvasTreeStore.updateShapeElementAction(
                                this.props.shapeElementId!,
                                {
                                    shapeStyle: {
                                        ...shapeElement.shapeStyle,
                                        fillColor: newValue,
                                    } as ShapeStyle,
                                }
                            );
                        }}
                        style={{
                            marginLeft: 12,
                        }}
                        onOpen={(isOpened) => {
                            this.closeColorPickers("gradient", isOpened);
                        }}
                        onClickOutside={() => {
                            this.closeColorPickers("gradient", false);
                        }}
                        opened={this.state.showColorPicker.gradient}
                        offset={{
                            bottom: 34,
                        }}
                    />
                )}
                <ColorPicker
                    topAppearance
                    enableAlpha
                    customView={
                        <CanvasIconsContainer
                            type={"BorderColor"}
                            options={{
                                stroke: borderColor,
                                fill: borderColor,
                            }}
                        />
                    }
                    tooltip="Divider Color"
                    value={borderColor}
                    onChange={(newValue) => {
                        this.props.canvasTreeStore.updateShapeElementAction(
                            this.props.shapeElementId!,
                            {
                                shapeStyle: {
                                    ...shapeElement.shapeStyle,
                                    borderColor: newValue,
                                    borderWidth: shapeElement.shapeStyle
                                        ?.borderColor
                                        ? borderWidth
                                        : 1,
                                } as ShapeStyle,
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                    onOpen={(isOpened) => {
                        this.closeColorPickers("border", isOpened);
                    }}
                    onClickOutside={() => {
                        this.closeColorPickers("border", false);
                    }}
                    opened={this.state.showColorPicker.border}
                    offset={{
                        bottom: 34,
                    }}
                />
                <div style={{ marginLeft: 12 }}>
                    <img
                        alt=""
                        src="/dist/img/canvas/toolbar_icons/thickness.png"
                    />
                </div>
                <EditOptionItem
                    style={{ marginLeft: 6 }}
                    label=""
                    value={borderWidth}
                    onChange={(width) => {
                        this.props.canvasTreeStore.updateShapeElementAction(
                            this.props.shapeElementId!,
                            {
                                shapeStyle: {
                                    ...shapeElement.shapeStyle,
                                    borderWidth: width,
                                },
                            }
                        );
                    }}
                />
                {shapeElement.shapeType !== ShapeType.Circle && (
                    <>
                        <div
                            style={{
                                marginLeft: 12,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <BorderRadiusIcon width={20} height={20} />
                        </div>
                        <EditOptionItem
                            style={{ marginLeft: 6 }}
                            label=""
                            value={shapeElement.shapeStyle?.borderRadius ?? 0}
                            onChange={(borderRadius) => {
                                this.props.canvasTreeStore.updateShapeElementAction(
                                    this.props.shapeElementId!,
                                    {
                                        shapeStyle: {
                                            ...shapeElement.shapeStyle,
                                            borderRadius: borderRadius,
                                        },
                                    }
                                );
                            }}
                        />
                    </>
                )}
                <button
                    title="Border Shadow"
                    style={{
                        marginLeft: 12,
                        padding: "0 0 0 0",
                        width: "24px",
                        height: "24px",
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    onClick={() => {
                        this.props.canvasTreeStore.updateShapeElementAction(
                            this.props.shapeElementId!,
                            {
                                shapeStyle: {
                                    ...shapeElement.shapeStyle,
                                    borderShadow: !shapeElement.shapeStyle
                                        ?.borderShadow,
                                } as ShapeStyle,
                            }
                        );
                    }}
                >
                    <CanvasIconsContainer
                        type="BorderShadow"
                        options={undefined}
                    />
                </button>
            </div>
        );
    }

    private renderBackroundElementColorPickers(
        backgroundElement: CanvasBackground | undefined
    ): JSX.Element | null {
        if (backgroundElement == null) {
            return null;
        }
        let backgroundStyleOptions = backgroundElement.style_options;

        let fillColor: string =
            backgroundStyleOptions?.fillColor ?? "rgba(0, 0, 0, 100)";
        let borderColor: string =
            backgroundStyleOptions?.borderColor ?? "rgba(0, 0, 0, 100)";
        const borderWidth = backgroundStyleOptions?.borderWidth ?? 0;
        return (
            <div className={styles.itemContainer}>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    customView={
                        <CanvasIconsContainer
                            type="FillColor"
                            options={{
                                stroke: fillColor,
                                fill: fillColor,
                            }}
                        />
                    }
                    tooltip="Fill Color"
                    value={fillColor}
                    onChange={(newValue) => {
                        this.props.canvasTreeStore.updateBackgroundAction(
                            this.props.backgroundId!,
                            {
                                style_options: {
                                    ...backgroundStyleOptions,
                                    fillColor: newValue,
                                } as ShapeStyle,
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                    onOpen={(isOpened) => {
                        this.closeColorPickers("fill", isOpened);
                    }}
                    onClickOutside={() => {
                        this.closeColorPickers("fill", false);
                    }}
                    opened={this.state.showColorPicker.fill}
                    offset={{
                        bottom: 34,
                    }}
                />
                <ColorPicker
                    isGradient
                    topAppearance
                    enableAlpha
                    customView={
                        <CanvasIconsContainer
                            type="FillColor"
                            options={{
                                stroke: fillColor,
                                fill: fillColor,
                            }}
                        />
                    }
                    tooltip="Gradient Color"
                    value={fillColor}
                    onChange={(newValue) => {
                        this.props.canvasTreeStore.updateBackgroundAction(
                            this.props.backgroundId!,
                            {
                                style_options: {
                                    ...backgroundStyleOptions,
                                    fillColor: newValue,
                                } as ShapeStyle,
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                    onOpen={(isOpened) => {
                        this.closeColorPickers("gradient", isOpened);
                    }}
                    onClickOutside={() => {
                        this.closeColorPickers("gradient", false);
                    }}
                    opened={this.state.showColorPicker.gradient}
                    offset={{
                        bottom: 34,
                    }}
                />
                <ColorPicker
                    topAppearance
                    enableAlpha
                    customView={
                        <CanvasIconsContainer
                            type={"BorderColor"}
                            options={{
                                stroke: borderColor,
                                fill: borderColor,
                            }}
                        />
                    }
                    tooltip="Divider Color"
                    value={borderColor}
                    onChange={(newValue) => {
                        this.props.canvasTreeStore.updateBackgroundAction(
                            this.props.backgroundId!,
                            {
                                style_options: {
                                    ...backgroundStyleOptions,
                                    borderColor: newValue,
                                    borderWidth: backgroundStyleOptions?.borderColor
                                        ? borderWidth
                                        : 1,
                                },
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                    onOpen={(isOpened) => {
                        this.closeColorPickers("border", isOpened);
                    }}
                    onClickOutside={() => {
                        this.closeColorPickers("border", false);
                    }}
                    opened={this.state.showColorPicker.border}
                    offset={{
                        bottom: 34,
                    }}
                />
                <div style={{ marginLeft: 12 }}>
                    <img
                        alt=""
                        src="/dist/img/canvas/toolbar_icons/thickness.png"
                    />
                </div>
                <EditOptionItem
                    style={{ marginLeft: 6 }}
                    label=""
                    value={borderWidth}
                    onChange={(width) => {
                        this.props.canvasTreeStore.updateBackgroundAction(
                            this.props.backgroundId!,
                            {
                                style_options: {
                                    ...backgroundStyleOptions,
                                    borderWidth: width,
                                },
                            }
                        );
                    }}
                />

                {/* <div
                    style={{
                        marginLeft: 12,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <BorderRadiusIcon width={20} height={20} />
                </div>
                <EditOptionItem
                    style={{ marginLeft: 6 }}
                    label=""
                    value={backgroundStyleOptions?.borderRadius ?? 0}
                    onChange={(borderRadius) => {
                        this.props.canvasTreeStore.updateBackgroundAction(
                            this.props.backgroundId!,
                            {
                                style_options: {
                                    ...backgroundStyleOptions,
                                    borderRadius: borderRadius,
                                },
                            }
                        );
                    }}
                />

                <button
                    title="Border Shadow"
                    style={{
                        marginLeft: 12,
                        padding: "0 0 0 0",
                        width: "24px",
                        height: "24px",
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    onClick={() => {
                        this.props.canvasTreeStore.updateBackgroundAction(
                            this.props.backgroundId!,
                            {
                                style_options: {
                                    ...backgroundStyleOptions,
                                    borderShadow: !backgroundStyleOptions?.borderShadow,
                                },
                            }
                        );
                    }}
                >
                    <CanvasIconsContainer
                        type="BorderShadow"
                        options={undefined}
                    />
                </button> */}
            </div>
        );
    }

    private renderPageBarInfoColorPickers(
        innerHeight: number
    ): JSX.Element | null {
        let pageBarInfo = CurrentModulesStore.getModule(
            this.props.currentModuleId
        )?.page_bar_info;

        if (pageBarInfo == null) {
            return null;
        }
        let fillColor: string = pageBarInfo.fillColor;
        let borderShadow: boolean = pageBarInfo.borderShadow;
        let dividerColor: string =
            pageBarInfo.dividerColor ??
            mainStyle
                .getPropertyValue("--canvas-page-bar-divider-color")
                .trim();
        return (
            <>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    customView={
                        <CanvasIconsContainer
                            type="FillColor"
                            options={{
                                stroke: fillColor,
                                fill: fillColor,
                            }}
                        />
                    }
                    tooltip="Fill Color"
                    value={fillColor}
                    onChange={(newValue) => {
                        CurrentModulesStore.updatePageBar(
                            this.props.currentModuleId,
                            {
                                fillColor: newValue,
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                />
                <ColorPicker
                    topAppearance
                    enableAlpha
                    customView={
                        <CanvasIconsContainer
                            type="BorderColor"
                            options={{
                                stroke: dividerColor,
                                fill: dividerColor,
                            }}
                        />
                    }
                    tooltip="Divider Color"
                    value={dividerColor}
                    onChange={(newValue) => {
                        CurrentModulesStore.updatePageBar(
                            this.props.currentModuleId,
                            {
                                dividerColor: newValue,
                            }
                        );
                    }}
                    style={{
                        marginLeft: 12,
                    }}
                />
                <button
                    title="Border Shadow"
                    style={{
                        marginLeft: 12,
                        padding: "0 0 0 0",
                        width: "24px",
                        height: "24px",
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    onClick={() => {
                        CurrentModulesStore.updatePageBar(
                            this.props.currentModuleId,
                            {
                                borderShadow: !borderShadow,
                            }
                        );
                    }}
                >
                    <CanvasIconsContainer
                        type="BorderShadow"
                        options={undefined}
                    />
                </button>
            </>
        );
    }

    private renderThemeOptions(
        innerHeight: number,
        node: CanvasSubmitButton
    ): JSX.Element | null {
        return (
            <>
                <span className={styles.labelCanvasAlignment}>{"Theme"}</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    menuPlacement={"top"}
                    placeholder={"THEME"}
                    styles={{
                        ...customSelectStylesFontFace,
                        container: (base) => ({
                            ...base,
                            width: "100px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            fontSize: "8px",
                        }),
                        menuList: (base) => ({
                            ...base,
                            width: "210px",
                            height: "150px",
                        }),
                        menu: (base) => ({
                            ...base,
                            width: "210px",
                            height: "150px",
                            borderRadius: 6,
                        }),
                        input: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                        placeholder: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                        singleValue: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                    }}
                    options={buttonStyleOptions}
                    value={buttonStyleOptions.find(
                        (option) => option.value === node.buttonStyle
                    )}
                    onChange={(newValue) => {
                        let newStyle = (newValue as ButtonStyleOption).value;
                        LastNodeOptionsStorage.setLastButtonStyle(newStyle);
                        this.props.onUpdateSelection({
                            buttonStyle: newStyle,
                            fillColor:
                                newStyle === ButtonStyle.Refine
                                    ? "rgba(255, 255, 255, 1)"
                                    : "rgba(31, 142, 250, 1)",
                            borderShadow: false,
                            fontColor:
                                newStyle === ButtonStyle.Refine
                                    ? "rgba(31, 142, 250, 1)"
                                    : "rgba(255, 255, 255, 1)",
                            fontSize: 10,
                        });
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </>
        );
    }

    private renderFontSelectorOptions(
        node: CanvasDropdownSelector | CanvasRadioButtonsGroup | CanvasInput
    ): JSX.Element | null {
        let fonts = fontFamilies.concat(FontLoader.fonts).map((font) => ({
            label: font,
            value: font,
        }));

        return (
            <>
                <span className={styles.labelCanvasAlignment}>{"Font"}</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    menuPlacement={"top"}
                    styles={{
                        ...customSelectStylesFontFace,
                        container: (base) => ({
                            ...base,
                            width: "100px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            fontSize: "8px",
                        }),
                        menuList: (base) => ({
                            ...base,
                            width: "210px",
                            height: "250px",
                        }),
                        menu: (base) => ({
                            ...base,
                            width: "210px",
                            height: "250px",
                            borderRadius: 6,
                        }),
                        input: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                        placeholder: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                        singleValue: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                    }}
                    options={fonts}
                    value={
                        fonts.find((font) => font.label === node.fontFamily)
                            ? fonts.find(
                                  (font) => font.label === node.fontFamily
                              )
                            : {
                                  label: "Roboto",
                                  value: "Roboto",
                              }
                    }
                    onChange={(newValue) => {
                        this.props.onUpdateSelection({
                            fontFamily: (newValue as StringOption).value,
                        });
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </>
        );
    }

    private renderDropdownThemeOptions(
        innerHeight: number,
        node: CanvasDropdownSelector
    ): JSX.Element | null {
        return (
            <>
                <span className={styles.labelCanvasAlignment}>{"Theme"}</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    menuPlacement={"top"}
                    placeholder={"THEME"}
                    styles={{
                        ...customSelectStylesSmall,
                        container: (base) => ({
                            ...base,

                            width: "100px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }),
                    }}
                    options={dropdownStyleOptions}
                    value={dropdownStyleOptions.find(
                        (option) => option.value === node.dropdownStyle
                    )}
                    onChange={(newValue) => {
                        let newStyle = (newValue as DropdownStyleOption).value;
                        LastNodeOptionsStorage.setLastDropdownStyle(newStyle);
                        this.props.canvasTreeStore.updateNodeAction<
                            CanvasDropdownSelector,
                            "dropdownStyle"
                        >(node.id, { dropdownStyle: newStyle });
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </>
        );
    }

    private renderInputFieldThemeOptions(
        node: CanvasInput
    ): JSX.Element | null {
        return (
            <>
                <span className={styles.labelCanvasAlignment}>{"Theme"}</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    menuPlacement={"top"}
                    placeholder={"THEME"}
                    styles={{
                        ...customSelectStylesFontFace,
                        container: (base) => ({
                            ...base,
                            width: "100px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            fontSize: "8px",
                        }),
                        menuList: (base) => ({
                            ...base,
                            width: "210px",
                            height: "150px",
                        }),
                        menu: (base) => ({
                            ...base,
                            width: "210px",
                            height: "150px",
                            borderRadius: 6,
                        }),
                        input: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                        placeholder: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                        singleValue: (base) => ({
                            ...base,
                            fontSize: "8px",
                        }),
                    }}
                    options={inputFieldStyleOptions}
                    value={inputFieldStyleOptions.find(
                        (option) => option.value === node.inputFieldStyle
                    )}
                    onChange={(newValue) => {
                        let newStyle = (newValue as InputFieldStyleOption)
                            .value;
                        LastNodeOptionsStorage.setLastInputFieldStyle(newStyle);
                        this.props.onUpdateSelection({
                            inputFieldStyle: newStyle,
                        });
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </>
        );
    }

    private renderRadioButtonsGroupThemeOptions(
        node: CanvasRadioButtonsGroup
    ): JSX.Element | null {
        return (
            <>
                <span className={styles.labelCanvasAlignment}>{"Theme"}</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    menuPlacement={"top"}
                    placeholder={"THEME"}
                    styles={{
                        ...customSelectStylesSmall,
                        container: (base) => ({
                            ...base,
                            width: "100px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }),
                    }}
                    options={radioButtonStyleOption}
                    value={radioButtonStyleOption.find(
                        (option) => option.value === node.radioButtonsGroupStyle
                    )}
                    onChange={(newValue) => {
                        let newStyle = (newValue as RadioButtonsGroupStyleOption)
                            .value;
                        LastNodeOptionsStorage.setLastRadioButtonsGroupStyle(
                            newStyle
                        );
                        this.props.canvasTreeStore.updateNodeAction<
                            CanvasRadioButtonsGroup,
                            "radioButtonsGroupStyle"
                        >(node.id, { radioButtonsGroupStyle: newStyle });
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </>
        );
    }

    private renderPageBarInfoThemePicker(
        innerHeight: number
    ): JSX.Element | null {
        let pageBarInfo = CurrentModulesStore.getModule(
            this.props.currentModuleId
        )?.page_bar_info;

        if (pageBarInfo == null) {
            return null;
        }
        return (
            <div
                className="my-row"
                style={{
                    height: innerHeight,
                    alignItems: "center",
                    backgroundColor: "#242E42",
                    marginRight: "3px",
                }}
            >
                <span
                    style={{
                        marginLeft: "20px",
                        fontFamily: "Roboto",
                        fontSize: "9px",
                        color: mainStyle.getPropertyValue(
                            "--content-primary-text-color"
                        ),
                    }}
                >
                    {"Theme"}
                </span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"STYLE"}
                    menuPlacement={"top"}
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({
                            ...base,
                            marginLeft: "5px",
                            height: "38px",
                            width: "100px",
                            marginRight: "5px",
                        }),
                    }}
                    options={pageBarStyleOptions}
                    value={pageBarStyleOptions.find(
                        (option) => option.value === pageBarInfo!.style
                    )}
                    onChange={(newValue) => {
                        CurrentModulesStore.updatePageBar(
                            this.props.currentModuleId,
                            {
                                style: (newValue as PageBarStyleOption).value,
                            }
                        );
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
        );
    }
    buildSpreadSheetItemFormulaEditor() {
        const editedNode = this.props.editedNode;
        if (editedNode == null) return null;
        let format: ColumnFormat | undefined = undefined;

        let grid =
            editedNode?.gridId != null
                ? this.props.canvasTreeStore.gridsState.get(editedNode?.gridId)
                : null;
        if (grid != null)
            format = (grid as CanvasSpreadSheetGrid).headers?.[editedNode.x!]
                ?.columnFormat;
        let sourceNode = this.props.canvasTreeStore.canvasTreeState.get(
            editedNode.id
        );
        if (sourceNode == null) return null;
        return (
            <AutosizeInput
                style={{ marginLeft: "12px" }}
                className="cancel-drag"
                inputClassName={cx(
                    styles.inputCanvasAlignment,
                    styles.formulaEditor
                )}
                ref={this.props.placementToolbarSimpleEdit}
                placeholder={"f(x)"}
                disabled={isDateFormat(format)}
                minWidth={300}
                value={
                    isDateFormat(format)
                        ? getPrettyPrintFormatValue(sourceNode, format)
                        : editedNode!.metric
                }
                onChange={(evt) => {
                    let value = evt.target.value;
                    let node = {
                        ...editedNode!,
                        metric: value,
                    };
                    this.props.onChangeEditedNode(node);
                }}
                onFocus={(evt) => {
                    let node = {
                        ...editedNode!,
                        internalEdit: false,
                        focus: true,
                    };
                    this.props.onChangeEditedNode(node);
                }}
                onBlur={(evt) => {
                    this.props.onKeepSimpleEditChanges();
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (
                        (evt.key === "Enter" || evt.key === "Escape") &&
                        !evt.shiftKey
                    ) {
                        evt.preventDefault();
                        this.props.onKeepSimpleEditChanges();
                        this.props.onClearEditing();
                    }
                }}
            />
        );
    }

    public render(): JSX.Element | null {
        let rects: {
            x: number;
            y: number;
            width: number;
            height: number;
            allowResize: boolean;
        }[] = [];
        let maxRect: {
            x: number;
            y: number;
            width: number;
            height: number;
        };
        let colorOptions: GridColorOptions | undefined;
        const { placementToolbar } = this.props.canvasTreeStore;
        if (this.props.spreadSheetGridId != null) {
            let gridElement = this.props.canvasTreeStore.gridsState.get(
                this.props.spreadSheetGridId
            );
            if (gridElement == null) return null;
            let defaultDivider = mainStyle
                .getPropertyValue("--slide-spreadsheet-divider")
                .trim()
                .split(" ");
            colorOptions = gridElement.colorOptions ?? {
                borderShadow: false,
                fillColor: mainStyle
                    .getPropertyValue("--slide-spreadsheet-content-color")
                    .trim(),
                textColor: mainStyle
                    .getPropertyValue("--slide-spreadsheet-default-text-color")
                    .trim(),
                borderColor: defaultDivider[defaultDivider.length - 1],
            };
        }
        const canvasViewMode =
            this.props.canvasTreeStore.canvasViewMode ?? "desktop";
        let shapeElement: ShapeElement | undefined;
        if (this.props.shapeElementId != null) {
            shapeElement = this.props.canvasTreeStore.shapeElementsState.get(
                this.props.shapeElementId
            );
            if (shapeElement == null) return null;
        }
        let backgroundElement: CanvasBackground | undefined;
        if (this.props.backgroundId != null) {
            backgroundElement = this.props.canvasTreeStore.backgroundsState.find(
                (background) => background.id === this.props.backgroundId
            );
            if (backgroundElement == null) return null;
        }
        if (this.props.metadata != null) {
            rects = this.props.metadata.map((item) =>
                this.props.canvasTreeStore.getRectByMetadataAction(item).get()
            );
            maxRect = this.props.slideRect;
            if (rects.length > 1) {
                let minX = rects[0].x;
                let minY = rects[0].y;
                let maxX = rects[0].x + rects[0].width;
                let maxY = rects[0].y + rects[0].height;
                for (let i = 1; i < rects.length; ++i) {
                    let rect = rects[i];
                    minX = Math.min(rect.x, minX);
                    minY = Math.min(rect.y, minY);
                    maxX = Math.max(rect.width + rect.x, maxX);
                    maxY = Math.max(rect.height + rect.y, maxY);
                }
                maxRect = {
                    x: minX,
                    y: minY,
                    width: maxX - minX,
                    height: maxY - minY,
                };
            }
        }
        let labelOptionsShown: boolean = false;
        let fillColorShown: boolean = false;
        let textBoxBar: boolean = false;
        let borderShadowShown: boolean = false;
        let borderColorShown: boolean = false;
        let borderRadiusShown: boolean = false;
        let node: CanvasNode | null | undefined = null;
        let showThemeOptions: boolean = false;
        let showDropdownThemeOptions: boolean = false;
        let showInputFieldThemeOptions: boolean = false;
        let showRadioButtonsGroupThemeOptions: boolean = false;
        let showLinkOptions: boolean = false;
        let showFontSelectorOptions: boolean = false;
        let showRadioButtonsGroupOptions: boolean = false;

        // let borderShadow: boolean | undefined;
        let fillColor: string | null | undefined;
        let labelColor: string | null | undefined;
        let borderColor: string | null | undefined;
        let borderRadius: number = 0;
        let radioButtonsGroupOptions: {
            fillColor: string;
            borderColor: string;
        } = {
            fillColor: "",
            borderColor: "",
        };

        // Toggle element
        let toggleBackgroundColor: string = "";
        let toggleCheckedHandleColor: string = "";
        let toggleUncheckedHandleColor: string = "";

        // Slider element
        let sliderHandleColor: string = "";
        let sliderRailColor: string = "";

        if (this.props.nodeId != null) {
            node = this.props.canvasTreeStore.canvasTreeState.get(
                this.props.nodeId
            );
            if (node == null) return null;
            if (node != null) {
                // borderShadow = (node as CanvasElement).borderShadow;
                const defaultFlowchartBackgroundColor = mainStyle.getPropertyValue(
                    "--slide-flowchart-background-color"
                );
                fillColor =
                    (node as CanvasElement).fillColor ??
                    (isBox(node)
                        ? defaultFlowchartBackgroundColor
                        : isSubmitButton(node)
                        ? "#1F8EFA"
                        : defaultFlowchartBackgroundColor);

                if (isTextBox(node)) {
                    textBoxBar = true;
                }
                if (
                    isTextBox(node) ||
                    isBox(node) ||
                    isInput(node) ||
                    isRadioButtonsGroup(node) ||
                    isSubmitButton(node) ||
                    isDropdownSelector(node)
                ) {
                    fillColorShown = true;
                    borderShadowShown = true;
                }
                if (isRadioButtonsGroup(node)) {
                    borderColorShown = true;
                    showRadioButtonsGroupOptions = true;
                    radioButtonsGroupOptions = {
                        fillColor:
                            (node as CanvasRadioButtonsGroup).keyLabelOptions
                                ?.fillColor ?? "#fff",
                        borderColor:
                            (node as CanvasRadioButtonsGroup).keyLabelOptions
                                ?.borderColor ??
                            mainStyle.getPropertyValue(
                                "--secondary-content-color"
                            ),
                    };
                }
                if (isBarcodeReader(node)) {
                    borderShadowShown = true;
                }
                if (isProgressElement(node)) {
                    fillColorShown = true;
                    borderShadowShown = true;
                    labelOptionsShown = true;
                    labelColor =
                        node.labelColor ??
                        mainStyle.getPropertyValue("--secondary-text-color");
                }
                showLinkOptions = isSubmitButton(node) || isBox(node);
                showThemeOptions = isSubmitButton(node);
                showDropdownThemeOptions = isDropdownSelector(node);
                showInputFieldThemeOptions = isInput(node);
                showRadioButtonsGroupThemeOptions = isRadioButtonsGroup(node);
                showFontSelectorOptions =
                    isDropdownSelector(node) ||
                    isRadioButtonsGroup(node) ||
                    isInput(node) ||
                    isSubmitButton(node);
            }
        }

        let mapElement: MapElement | undefined;
        if (this.props.mapElementId) {
            mapElement = this.props.canvasTreeStore.mapElementsState.get(
                this.props.mapElementId
            );
            if (mapElement == null) return null;
            fillColorShown = true;
            borderShadowShown = true;
            borderColorShown = true;

            // borderShadow = mapElement.colorOptions?.borderShadow ?? true;
            fillColor =
                mapElement.colorOptions?.fillColor ??
                dataScienceElementsStyle.contentColor;
            borderColor =
                mapElement.colorOptions?.borderColor ??
                dataScienceElementsStyle.borderColor;
        }

        let dashboard: CanvasDashboard | undefined;
        if (this.props.dashboardId) {
            dashboard = this.props.canvasTreeStore.dashboardsState.get(
                this.props.dashboardId
            );
            if (dashboard == null) return null;
            fillColorShown = true;
            borderShadowShown = true;
            borderColorShown = true;

            // borderShadow = dashboard.colorOptions?.borderShadow ?? true;
            fillColor =
                dashboard.colorOptions?.fillColor ??
                dataScienceElementsStyle.contentColor;
            borderColor =
                dashboard.colorOptions?.borderColor ??
                dataScienceElementsStyle.borderColor;
        }

        if (node?.canvasType === CanvasType.DropdownSelector) {
            borderColorShown = true;
            borderColor = (node as CanvasDropdownSelector).borderColor;
        }

        if (node?.canvasType === CanvasType.RadioButtonsGroup) {
            borderColorShown = true;
            borderColor = (node as CanvasRadioButtonsGroup).borderColor;
        }

        if (node?.canvasType === CanvasType.TextBox) {
            borderColorShown = true;
            borderRadiusShown = true;
            borderColor = (node as CanvasTextBox).borderColor;
            borderRadius = (node as CanvasTextBox).borderRadius ?? 0;
        }

        if (node?.canvasType === CanvasType.Toggle) {
            toggleBackgroundColor =
                (node as CanvasToggle).colorOptions?.background ??
                mainStyle.getPropertyValue("--section-portal-switch-on-color");
            toggleCheckedHandleColor =
                (node as CanvasToggle).colorOptions?.handle?.checked ??
                mainStyle.getPropertyValue(
                    "--section-portal-switch-on-handle-color"
                );
            toggleUncheckedHandleColor =
                (node as CanvasToggle).colorOptions?.handle?.unchecked ??
                mainStyle.getPropertyValue(
                    "--section-portal-switch-off-handle-color"
                );
        }

        if (node?.canvasType === CanvasType.Slider) {
            sliderHandleColor =
                (node as CanvasSlider).colorOptions?.handle ??
                mainStyle.getPropertyValue("--slider-handle-color");
            sliderRailColor =
                (node as CanvasSlider).colorOptions?.rail ??
                mainStyle.getPropertyValue("--slider-rail-color");
        }

        let showPageBarColorPickers =
            this.props.metadata != null &&
            this.props.metadata.length === 1 &&
            this.props.metadata[0].type === "pageBar";

        let innerSize = {
            height: 32,
            width: "100%",
        };

        return node?.canvasType === CanvasType.Survey ? null : (
            <Portal rootNode={document.body}>
                <Draggable
                    position={placementToolbar?.position ?? { x: 0, y: 0 }}
                    positionOffset={{ x: "-50%", y: "0%" }}
                    cancel=".sketch-picker, .popup_tabs, .cancel-drag"
                    disabled={false}
                    onStop={(_evt, data) => {
                        this.props.canvasTreeStore.updatePlacementToolbar({
                            position: {
                                x: data.x,
                                y: data.y,
                            },
                        });
                    }}
                >
                    <div className={styles.container}>
                        {false &&
                            this.renderPageBarInfoThemePicker(innerSize.height)}

                        {node?.canvasType === CanvasType.Toggle && (
                            <>
                                <ColorPicker
                                    topAppearance
                                    customView={
                                        <CanvasIconsContainer
                                            type="FillColor"
                                            options={{
                                                stroke: toggleBackgroundColor,
                                                fill: toggleBackgroundColor,
                                            }}
                                        />
                                    }
                                    tooltip="Fill Color"
                                    value={toggleBackgroundColor}
                                    onChange={(newValue) => {
                                        this.props.canvasTreeStore.updateNodeAction(
                                            this.props.nodeId!,
                                            {
                                                colorOptions: {
                                                    ...((node as CanvasToggle)
                                                        .colorOptions ?? {}),
                                                    background: newValue,
                                                },
                                            } as CanvasToggle
                                        );
                                    }}
                                    style={{
                                        marginLeft: 12,
                                    }}
                                    onOpen={(isOpened) => {
                                        this.closeColorPickers(
                                            "fill",
                                            isOpened
                                        );
                                    }}
                                    onClickOutside={() => {
                                        this.closeColorPickers("fill", false);
                                    }}
                                    opened={this.state.showColorPicker.fill}
                                    offset={{
                                        bottom: 34,
                                    }}
                                />
                                <ColorPicker
                                    topAppearance
                                    customView={
                                        <CanvasIconsContainer
                                            type="FillColor"
                                            options={{
                                                stroke: toggleCheckedHandleColor,
                                                fill: toggleCheckedHandleColor,
                                            }}
                                        />
                                    }
                                    tooltip="Fill Color"
                                    value={toggleCheckedHandleColor}
                                    onChange={(newValue) => {
                                        this.props.canvasTreeStore.updateNodeAction(
                                            this.props.nodeId!,
                                            {
                                                colorOptions: {
                                                    ...((node as CanvasToggle)
                                                        .colorOptions ?? {}),
                                                    handle: {
                                                        ...((node as CanvasToggle)
                                                            .colorOptions
                                                            ?.handle ?? {}),
                                                        checked: newValue,
                                                    },
                                                },
                                            } as CanvasToggle
                                        );
                                    }}
                                    style={{
                                        marginLeft: 12,
                                    }}
                                    onOpen={(isOpened) => {
                                        this.closeColorPickers(
                                            "border",
                                            isOpened
                                        );
                                    }}
                                    onClickOutside={() => {
                                        this.closeColorPickers("border", false);
                                    }}
                                    opened={this.state.showColorPicker.border}
                                    offset={{
                                        bottom: 34,
                                    }}
                                />
                                <ColorPicker
                                    topAppearance
                                    customView={
                                        <CanvasIconsContainer
                                            type="FillColor"
                                            options={{
                                                stroke: toggleUncheckedHandleColor,
                                                fill: toggleUncheckedHandleColor,
                                            }}
                                        />
                                    }
                                    tooltip="Fill Color"
                                    value={toggleUncheckedHandleColor}
                                    onChange={(newValue) => {
                                        this.props.canvasTreeStore.updateNodeAction(
                                            this.props.nodeId!,
                                            {
                                                colorOptions: {
                                                    ...((node as CanvasToggle)
                                                        .colorOptions ?? {}),
                                                    handle: {
                                                        ...((node as CanvasToggle)
                                                            .colorOptions
                                                            ?.handle ?? {}),
                                                        unchecked: newValue,
                                                    },
                                                },
                                            } as CanvasToggle
                                        );
                                    }}
                                    style={{
                                        marginLeft: 12,
                                    }}
                                    onOpen={(isOpened) => {
                                        this.closeColorPickers(
                                            "gradient",
                                            isOpened
                                        );
                                    }}
                                    onClickOutside={() => {
                                        this.closeColorPickers(
                                            "gradient",
                                            false
                                        );
                                    }}
                                    opened={this.state.showColorPicker.gradient}
                                    offset={{
                                        bottom: 34,
                                    }}
                                />
                            </>
                        )}
                        {node?.canvasType === CanvasType.Slider && (
                            <>
                                <ColorPicker
                                    topAppearance
                                    customView={
                                        <CanvasIconsContainer
                                            type="FillColor"
                                            options={{
                                                stroke: sliderHandleColor,
                                                fill: sliderHandleColor,
                                            }}
                                        />
                                    }
                                    tooltip="Fill Color"
                                    value={sliderHandleColor}
                                    onChange={(color) => {
                                        this.props.canvasTreeStore.updateNodeAction(
                                            this.props.nodeId!,
                                            {
                                                colorOptions: {
                                                    ...((node as CanvasSlider)
                                                        .colorOptions ?? {}),
                                                    handle: color,
                                                },
                                            } as CanvasSlider
                                        );
                                    }}
                                    style={{
                                        marginLeft: 12,
                                    }}
                                    onOpen={(isOpened) => {
                                        this.closeColorPickers(
                                            "fill",
                                            isOpened
                                        );
                                    }}
                                    onClickOutside={() => {
                                        this.closeColorPickers("fill", false);
                                    }}
                                    opened={this.state.showColorPicker.fill}
                                    offset={{
                                        bottom: 34,
                                    }}
                                />
                                <ColorPicker
                                    topAppearance
                                    customView={
                                        <CanvasIconsContainer
                                            type="FillColor"
                                            options={{
                                                stroke: sliderRailColor,
                                                fill: sliderRailColor,
                                            }}
                                        />
                                    }
                                    tooltip="Fill Color"
                                    value={sliderRailColor}
                                    onChange={(color) => {
                                        this.props.canvasTreeStore.updateNodeAction(
                                            this.props.nodeId!,
                                            {
                                                colorOptions: {
                                                    ...((node as CanvasSlider)
                                                        .colorOptions ?? {}),
                                                    rail: color,
                                                },
                                            } as CanvasSlider
                                        );
                                    }}
                                    style={{
                                        marginLeft: 12,
                                    }}
                                    onOpen={(isOpened) => {
                                        this.closeColorPickers(
                                            "border",
                                            isOpened
                                        );
                                    }}
                                    onClickOutside={() => {
                                        this.closeColorPickers("border", false);
                                    }}
                                    opened={this.state.showColorPicker.border}
                                    offset={{
                                        bottom: 34,
                                    }}
                                />
                            </>
                        )}

                        {(fillColorShown ||
                            borderShadowShown ||
                            borderShadowShown) && (
                            <div className={styles.itemContainer}>
                                {fillColorShown && (
                                    <ColorPicker
                                        topAppearance
                                        enableAlpha
                                        customView={
                                            <CanvasIconsContainer
                                                type="FillColor"
                                                options={{
                                                    stroke: fillColor,
                                                    fill: fillColor,
                                                }}
                                            />
                                        }
                                        tooltip="Fill Color"
                                        value={
                                            fillColor ??
                                            mainStyle.getPropertyValue(
                                                "--primary-content-color"
                                            )
                                        }
                                        onChange={(newValue) => {
                                            if (this.props.nodeId) {
                                                this.props.onUpdateSelection({
                                                    fillColor: newValue,
                                                });
                                            }

                                            if (
                                                this.props.mapElementId &&
                                                mapElement
                                            ) {
                                                let changes: MapColorOptions = defaultColorOptions;

                                                if (mapElement.colorOptions) {
                                                    changes = {
                                                        ...mapElement.colorOptions,
                                                    };
                                                }

                                                changes.fillColor = newValue;

                                                this.props.canvasTreeStore.updateMapElementAction(
                                                    this.props.mapElementId,
                                                    {
                                                        colorOptions: changes,
                                                    }
                                                );
                                            }
                                            if (
                                                this.props.dashboardId &&
                                                dashboard
                                            ) {
                                                let changes: ColorOptions = {
                                                    borderShadow: false,
                                                    fillColor:
                                                        dataScienceElementsStyle.contentColor,
                                                    borderColor: "",
                                                };

                                                if (dashboard.colorOptions) {
                                                    changes = {
                                                        ...dashboard.colorOptions,
                                                    };
                                                }

                                                changes.fillColor = newValue;

                                                this.props.canvasTreeStore.updateDashboardAction(
                                                    this.props.dashboardId,
                                                    {
                                                        colorOptions: changes,
                                                    }
                                                );
                                            }
                                        }}
                                        style={{
                                            marginLeft: 12,
                                        }}
                                        onOpen={(isOpened) => {
                                            this.closeColorPickers(
                                                "fill",
                                                isOpened
                                            );
                                        }}
                                        onClickOutside={() => {
                                            this.closeColorPickers(
                                                "fill",
                                                false
                                            );
                                        }}
                                        opened={this.state.showColorPicker.fill}
                                        offset={{
                                            bottom: 35,
                                        }}
                                    />
                                )}
                                {borderColorShown && (
                                    <ColorPicker
                                        topAppearance
                                        enableAlpha
                                        customView={
                                            <CanvasIconsContainer
                                                type="BorderColor"
                                                options={{
                                                    stroke: borderColor,
                                                    fill: borderColor,
                                                }}
                                            />
                                        }
                                        tooltip="Border Color"
                                        value={
                                            borderColor ??
                                            mainStyle.getPropertyValue(
                                                "--primary-content-color"
                                            )
                                        }
                                        onChange={(newValue) => {
                                            if (
                                                node?.canvasType ===
                                                    CanvasType.DropdownSelector ||
                                                node?.canvasType ===
                                                    CanvasType.RadioButtonsGroup ||
                                                node?.canvasType ===
                                                    CanvasType.TextBox
                                            ) {
                                                this.props.canvasTreeStore.updateNodeAction<
                                                    | CanvasDropdownSelector
                                                    | CanvasRadioButtonsGroup,
                                                    "borderColor"
                                                >(this.props.nodeId!, {
                                                    borderColor: newValue,
                                                });
                                            }

                                            if (
                                                this.props.mapElementId &&
                                                mapElement
                                            ) {
                                                let changes: MapColorOptions = defaultColorOptions;

                                                if (mapElement.colorOptions) {
                                                    changes = {
                                                        ...mapElement.colorOptions,
                                                    };
                                                }

                                                changes.borderColor = newValue;

                                                this.props.canvasTreeStore.updateMapElementAction(
                                                    this.props.mapElementId,
                                                    {
                                                        colorOptions: changes,
                                                    }
                                                );
                                            }
                                            if (
                                                this.props.dashboardId &&
                                                dashboard
                                            ) {
                                                let changes: ColorOptions = {
                                                    borderShadow: false,
                                                    fillColor:
                                                        dataScienceElementsStyle.contentColor,
                                                    borderColor: "",
                                                };

                                                if (dashboard.colorOptions) {
                                                    changes = {
                                                        ...dashboard.colorOptions,
                                                    };
                                                }

                                                changes.borderColor = newValue;

                                                this.props.canvasTreeStore.updateDashboardAction(
                                                    this.props.dashboardId,
                                                    {
                                                        colorOptions: changes,
                                                    }
                                                );
                                            }
                                        }}
                                        style={{
                                            marginLeft: 12,
                                        }}
                                        onOpen={(isOpened) => {
                                            this.closeColorPickers(
                                                "border",
                                                isOpened
                                            );
                                        }}
                                        onClickOutside={() => {
                                            this.closeColorPickers(
                                                "border",
                                                false
                                            );
                                        }}
                                        opened={
                                            this.state.showColorPicker.border
                                        }
                                        offset={{
                                            bottom: 35,
                                        }}
                                    />
                                )}
                                {showRadioButtonsGroupOptions && (
                                    <>
                                        <ColorPicker
                                            topAppearance
                                            enableAlpha
                                            customView={
                                                <CanvasIconsContainer
                                                    type="FillColor"
                                                    options={{
                                                        stroke:
                                                            radioButtonsGroupOptions.fillColor,
                                                        fill:
                                                            radioButtonsGroupOptions.fillColor,
                                                    }}
                                                />
                                            }
                                            tooltip="Label fill Color"
                                            value={
                                                radioButtonsGroupOptions.fillColor
                                            }
                                            onChange={(newValue) => {
                                                if (this.props.nodeId) {
                                                    this.props.canvasTreeStore.updateNodeAction<
                                                        CanvasRadioButtonsGroup,
                                                        "keyLabelOptions"
                                                    >(this.props.nodeId!, {
                                                        keyLabelOptions: {
                                                            ...((node as CanvasRadioButtonsGroup)
                                                                ?.keyLabelOptions ??
                                                                {}),
                                                            fillColor: newValue,
                                                        },
                                                    });
                                                }
                                            }}
                                            style={{
                                                marginLeft: 12,
                                            }}
                                            onOpen={(isOpened) => {
                                                this.closeColorPickers(
                                                    "labelFill",
                                                    isOpened
                                                );
                                            }}
                                            onClickOutside={() => {
                                                this.closeColorPickers(
                                                    "labelFill",
                                                    false
                                                );
                                            }}
                                            opened={
                                                this.state.showColorPicker
                                                    .labelFill
                                            }
                                            offset={{
                                                bottom: 35,
                                            }}
                                        />
                                        <ColorPicker
                                            topAppearance
                                            enableAlpha
                                            customView={
                                                <CanvasIconsContainer
                                                    type="BorderColor"
                                                    options={{
                                                        stroke:
                                                            radioButtonsGroupOptions.borderColor,
                                                        fill:
                                                            radioButtonsGroupOptions.borderColor,
                                                    }}
                                                />
                                            }
                                            tooltip="Label border Color"
                                            value={
                                                radioButtonsGroupOptions.borderColor
                                            }
                                            onChange={(newValue) => {
                                                this.props.canvasTreeStore.updateNodeAction<
                                                    CanvasRadioButtonsGroup,
                                                    "keyLabelOptions"
                                                >(this.props.nodeId!, {
                                                    keyLabelOptions: {
                                                        ...((node as CanvasRadioButtonsGroup)
                                                            ?.keyLabelOptions ??
                                                            {}),
                                                        borderColor: newValue,
                                                    },
                                                });
                                            }}
                                            style={{
                                                marginLeft: 12,
                                            }}
                                            onOpen={(isOpened) => {
                                                this.closeColorPickers(
                                                    "labelBorder",
                                                    isOpened
                                                );
                                            }}
                                            onClickOutside={() => {
                                                this.closeColorPickers(
                                                    "labelBorder",
                                                    false
                                                );
                                            }}
                                            opened={
                                                this.state.showColorPicker
                                                    .labelBorder
                                            }
                                            offset={{
                                                bottom: 35,
                                            }}
                                        />
                                    </>
                                )}
                                {this.props.dashboardId && (
                                    <>
                                        <div
                                            style={{
                                                marginLeft: 12,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <BorderRadiusIcon
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                        <EditOptionItem
                                            style={{ marginLeft: 6 }}
                                            label=""
                                            value={
                                                dashboard?.colorOptions
                                                    ?.borderRadius ?? 0
                                            }
                                            onChange={(borderRadius) => {
                                                if (
                                                    !this.props.dashboardId ||
                                                    !dashboard
                                                )
                                                    return;

                                                let changes: ColorOptions = defaultColorOptions;

                                                if (dashboard.colorOptions) {
                                                    changes = {
                                                        ...dashboard.colorOptions,
                                                    };
                                                }

                                                this.props.canvasTreeStore.updateDashboardAction(
                                                    this.props.dashboardId,
                                                    {
                                                        colorOptions: {
                                                            ...(dashboard?.colorOptions ??
                                                                changes),
                                                            borderRadius,
                                                        },
                                                    }
                                                );
                                            }}
                                        />
                                    </>
                                )}

                                {borderShadowShown && (
                                    <button
                                        title="Border Shadow"
                                        style={{
                                            marginLeft: 12,
                                            padding: "0 0 0 0",
                                            width: "24px",
                                            height: "24px",
                                            border: "none",
                                            backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                            if (this.props.nodeId) {
                                                this.props.onUpdateSelection({
                                                    borderShadow: !(node as CanvasElement)
                                                        .borderShadow,
                                                });
                                            }

                                            if (
                                                this.props.mapElementId &&
                                                mapElement
                                            ) {
                                                let changes: MapColorOptions = defaultColorOptions;

                                                if (mapElement.colorOptions) {
                                                    changes = {
                                                        ...mapElement.colorOptions,
                                                    };
                                                }

                                                changes.borderShadow = !changes.borderShadow;

                                                this.props.canvasTreeStore.updateMapElementAction(
                                                    this.props.mapElementId,
                                                    {
                                                        colorOptions: changes,
                                                    }
                                                );
                                            }
                                            if (
                                                this.props.dashboardId &&
                                                dashboard
                                            ) {
                                                let changes: ColorOptions = {
                                                    borderShadow: false,
                                                    fillColor:
                                                        dataScienceElementsStyle.contentColor,
                                                    borderColor: "",
                                                    borderRadius: 0,
                                                };

                                                this.props.canvasTreeStore.updateDashboardAction(
                                                    this.props.dashboardId,
                                                    {
                                                        colorOptions: {
                                                            ...(dashboard?.colorOptions ??
                                                                changes),
                                                            borderShadow: !dashboard
                                                                .colorOptions
                                                                ?.borderShadow,
                                                        },
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        <CanvasIconsContainer
                                            type="BorderShadow"
                                            options={undefined}
                                        />
                                    </button>
                                )}
                                {borderRadiusShown && (
                                    <>
                                        <div
                                            style={{
                                                marginLeft: 12,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <BorderRadiusIcon
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                        <EditOptionItem
                                            style={{ marginLeft: 6 }}
                                            label=""
                                            value={borderRadius}
                                            onChange={(val) => {
                                                if (
                                                    node?.canvasType ===
                                                    CanvasType.TextBox
                                                ) {
                                                    this.props.canvasTreeStore.updateNodeAction<
                                                        CanvasTextBox,
                                                        "borderRadius"
                                                    >(this.props.nodeId!, {
                                                        borderRadius: val,
                                                    });
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        )}

                        {this.props.fontOptions && (
                            <div
                                className={styles.itemContainer}
                                style={{
                                    border:
                                        node == null &&
                                        this.props.spreadSheetGridId == null &&
                                        !showPageBarColorPickers
                                            ? "none"
                                            : undefined,
                                }}
                            >
                                {showThemeOptions &&
                                    this.renderThemeOptions(
                                        innerSize.height,
                                        node as CanvasSubmitButton
                                    )}
                                {showDropdownThemeOptions &&
                                    this.renderDropdownThemeOptions(
                                        innerSize.height,
                                        node as CanvasDropdownSelector
                                    )}
                                {showInputFieldThemeOptions &&
                                    this.renderInputFieldThemeOptions(
                                        node as CanvasInput
                                    )}
                                {showFontSelectorOptions &&
                                    this.renderFontSelectorOptions(
                                        node as CanvasDropdownSelector
                                    )}
                                {showRadioButtonsGroupThemeOptions &&
                                    this.renderRadioButtonsGroupThemeOptions(
                                        node as CanvasRadioButtonsGroup
                                    )}
                                {!textBoxBar && (
                                    <EditOptionItem
                                        label="Aa"
                                        value={this.props.fontOptions.fontSize}
                                        onChange={(value) => {
                                            if (
                                                this.props.fontOptions!
                                                    .fontForLabel
                                            )
                                                this.props.onUpdateSelection({
                                                    labelSize: value,
                                                });
                                            else {
                                                this.props.onUpdateSelection({
                                                    fontSize: value,
                                                });
                                            }
                                            if (
                                                this.props.spreadSheetGridId !=
                                                null
                                            ) {
                                                this.props.canvasTreeStore.updateGridAction(
                                                    this.props
                                                        .spreadSheetGridId!,
                                                    {
                                                        colorOptions: {
                                                            ...colorOptions,
                                                            fontSize: value,
                                                        } as GridColorOptions,
                                                    }
                                                );
                                            }
                                        }}
                                    />
                                )}
                                {!textBoxBar && (
                                    <ColorPicker
                                        topAppearance
                                        enableAlpha
                                        customView={
                                            <CanvasIconsContainer
                                                type={"FontColor"}
                                                options={{
                                                    stroke: this.state.color,
                                                    fill: this.state.color,
                                                }}
                                            />
                                        }
                                        tooltip="Font Color"
                                        value={this.state.color ?? "#000000"}
                                        onChange={(newValue) => {
                                            this.setState({
                                                color: newValue,
                                            });
                                            if (
                                                this.props.fontOptions!
                                                    .fontForLabel
                                            )
                                                this.props.onUpdateSelection({
                                                    labelColor: newValue,
                                                });
                                            else {
                                                this.props.onUpdateSelection({
                                                    fontColor: newValue,
                                                });
                                            }
                                            if (
                                                this.props.spreadSheetGridId !=
                                                null
                                            ) {
                                                this.props.canvasTreeStore.updateGridAction(
                                                    this.props
                                                        .spreadSheetGridId!,
                                                    {
                                                        colorOptions: {
                                                            ...colorOptions,
                                                            textColor: newValue,
                                                        } as GridColorOptions,
                                                    }
                                                );
                                            }
                                        }}
                                        style={{
                                            marginLeft: 12,
                                        }}
                                        onOpen={(isOpened) => {
                                            this.closeColorPickers(
                                                "font",
                                                isOpened
                                            );
                                        }}
                                        onClickOutside={() => {
                                            this.closeColorPickers(
                                                "font",
                                                false
                                            );
                                        }}
                                        opened={this.state.showColorPicker.font}
                                        offset={{
                                            bottom: 34,
                                        }}
                                    />
                                )}
                                {labelOptionsShown &&
                                    node != null &&
                                    isProgressElement(node) && (
                                        <>
                                            <EditOptionItem
                                                style={{ marginLeft: 12 }}
                                                label="Lb"
                                                value={
                                                    node.labelSize ??
                                                    defaultLabelSize
                                                }
                                                onChange={(value) => {
                                                    this.props.canvasTreeStore.updateNodeAction<
                                                        CanvasElement,
                                                        "labelSize"
                                                    >(this.props.nodeId!, {
                                                        labelSize: value,
                                                    });
                                                }}
                                            />
                                            <ColorPicker
                                                topAppearance
                                                enableAlpha
                                                customView={
                                                    <CanvasIconsContainer
                                                        type={"FontColor"}
                                                        options={{
                                                            stroke: labelColor,
                                                            fill: labelColor,
                                                        }}
                                                    />
                                                }
                                                tooltip="Label Color"
                                                value={labelColor!}
                                                onChange={(newValue) => {
                                                    this.props.canvasTreeStore.updateNodeAction<
                                                        CanvasElement,
                                                        "labelColor"
                                                    >(this.props.nodeId!, {
                                                        labelColor: newValue,
                                                    });
                                                }}
                                                style={{
                                                    marginLeft: 12,
                                                }}
                                                onOpen={(isOpened) => {
                                                    this.closeColorPickers(
                                                        "label",
                                                        isOpened
                                                    );
                                                }}
                                                onClickOutside={() => {
                                                    this.closeColorPickers(
                                                        "label",
                                                        false
                                                    );
                                                }}
                                                opened={
                                                    this.state.showColorPicker
                                                        .label
                                                }
                                                offset={{
                                                    bottom: 34,
                                                }}
                                            />
                                        </>
                                    )}
                                {showLinkOptions && (
                                    <LinkDropup
                                        onOpen={(evt, external) => {
                                            let x = evt.clientX;
                                            if (
                                                external === true ||
                                                external === false
                                            ) {
                                                if (
                                                    (node as
                                                        | CanvasElement
                                                        | CanvasSubmitButton)
                                                        .external !== external
                                                ) {
                                                    this.props.canvasTreeStore.updateNodeAction<
                                                        | CanvasElement
                                                        | CanvasSubmitButton,
                                                        "external"
                                                    >(node!.id, {
                                                        external: external,
                                                    });
                                                    LastNodeOptionsStorage.setLastExternalLink(
                                                        external
                                                    );
                                                }
                                                setTimeout(() => {
                                                    this.setState({
                                                        showLinkPopupOptions: {
                                                            x: x,
                                                        },
                                                    });
                                                }, 0);
                                            } else
                                                this.setState({
                                                    showLinkPopupOptions: {
                                                        x: x,
                                                    },
                                                });
                                        }}
                                    />
                                )}
                                {this.props.editedNode != null &&
                                    this.buildSpreadSheetItemFormulaEditor()}
                                {this.renderSpreadSheetColorPickers(
                                    colorOptions
                                )}
                                {showPageBarColorPickers &&
                                    this.renderPageBarInfoColorPickers(
                                        innerSize.height
                                    )}
                            </div>
                        )}
                        {this.renderBackroundElementColorPickers(
                            backgroundElement
                        )}
                        {this.renderShapeElementColorPickers(shapeElement)}
                        {this.props.metadata != null &&
                            this.props.metadata.length > 0 && (
                                <>
                                    <div className={styles.itemContainer}>
                                        <div
                                            className={
                                                styles.btnCanvasAlignment
                                            }
                                            style={
                                                !textBoxBar
                                                    ? { marginLeft: 12 }
                                                    : { marginLeft: -50 }
                                            }
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.props.canvasTreeStore.moveByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => ({
                                                        x: maxRect.x,
                                                    }))
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/canvas/align_icons/align_left.png"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.btnCanvasAlignment
                                            }
                                            style={{ marginLeft: 12 }}
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.props.canvasTreeStore.moveByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => ({
                                                        x:
                                                            maxRect.x +
                                                            (maxRect.width -
                                                                rect.width) /
                                                                2,
                                                    }))
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/canvas/align_icons/align_horizontal_center.png"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.btnCanvasAlignment
                                            }
                                            style={{ marginLeft: 12 }}
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.props.canvasTreeStore.moveByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => ({
                                                        x:
                                                            maxRect.x +
                                                            maxRect.width -
                                                            rect.width,
                                                    }))
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/canvas/align_icons/align_right.png"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.btnCanvasAlignment
                                            }
                                            style={{ marginLeft: 12 }}
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.props.canvasTreeStore.moveByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => ({
                                                        y: maxRect.y,
                                                    }))
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/canvas/align_icons/align_top.png"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.btnCanvasAlignment
                                            }
                                            style={{ marginLeft: 12 }}
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.props.canvasTreeStore.moveByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => ({
                                                        y:
                                                            maxRect.y +
                                                            (maxRect.height -
                                                                rect.height) /
                                                                2,
                                                    }))
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/canvas/align_icons/align_vertical_center.png"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.btnCanvasAlignment
                                            }
                                            style={{
                                                marginLeft: 12,
                                            }}
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.props.canvasTreeStore.moveByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => ({
                                                        y:
                                                            maxRect.y +
                                                            maxRect.height -
                                                            rect.height,
                                                    }))
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/canvas/align_icons/align_bottom.png"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={styles.itemContainer}
                                        style={{ border: "none" }}
                                    >
                                        <EditOptionItem
                                            style={{ marginLeft: 15 }}
                                            label="X"
                                            value={
                                                rects.length === 1
                                                    ? rects[0].x
                                                    : undefined
                                            }
                                            onChange={(x) => {
                                                const changes =
                                                    canvasViewMode === "desktop"
                                                        ? {
                                                              x,
                                                          }
                                                        : {
                                                              mobile_x: x,
                                                          };
                                                this.props.canvasTreeStore.moveByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => changes)
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        />
                                        <EditOptionItem
                                            style={{ marginLeft: 10 }}
                                            label="Y"
                                            value={
                                                rects.length === 1
                                                    ? rects[0].y
                                                    : undefined
                                            }
                                            onChange={(y) => {
                                                const changes =
                                                    canvasViewMode === "desktop"
                                                        ? {
                                                              y,
                                                          }
                                                        : {
                                                              mobile_y: y,
                                                          };
                                                this.props.canvasTreeStore.moveByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => changes)
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        />

                                        <EditOptionItem
                                            disabled={
                                                this.props.metadata.length >
                                                    1 || !rects[0].allowResize
                                            }
                                            style={{ marginLeft: 10 }}
                                            label="W"
                                            value={
                                                rects.length === 1
                                                    ? rects[0].width
                                                    : undefined
                                            }
                                            onChange={(width) => {
                                                this.props.canvasTreeStore.resizeByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => ({
                                                        oldHeight: rect.height,
                                                        newHeight: rect.height,
                                                        oldWidth: rect.width,
                                                        newWidth: width,
                                                    }))
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        />
                                        <EditOptionItem
                                            style={{ marginLeft: 10 }}
                                            disabled={
                                                (node != null &&
                                                    isTextBox(node)) ||
                                                this.props.metadata.length >
                                                    1 ||
                                                !rects[0].allowResize
                                            }
                                            label="H"
                                            value={
                                                rects.length === 1
                                                    ? rects[0].height
                                                    : undefined
                                            }
                                            onChange={(height) => {
                                                this.props.canvasTreeStore.resizeByMetadataAction(
                                                    this.props.metadata!,
                                                    rects.map((rect) => ({
                                                        oldHeight: rect.height,
                                                        newHeight: height,
                                                        oldWidth: rect.width,
                                                        newWidth: rect.width,
                                                    }))
                                                );
                                                this.props.onUpdateSelectionBounds();
                                            }}
                                        />
                                        {shapeElement != null && (
                                            <>
                                                <EditOptionItem
                                                    style={{ marginLeft: 10 }}
                                                    label="&Delta;X"
                                                    value={
                                                        getNodeTranslation(
                                                            shapeElement
                                                        )[canvasViewMode].x
                                                    }
                                                    onChange={(x) => {
                                                        let translation = getNodeTranslation(
                                                            shapeElement!
                                                        );

                                                        this.props.canvasTreeStore.updateShapeElementAction(
                                                            this.props
                                                                .shapeElementId!,
                                                            {
                                                                nodeTranslation: {
                                                                    ...translation,
                                                                    [canvasViewMode]: {
                                                                        ...translation[
                                                                            canvasViewMode
                                                                        ],
                                                                        x: x,
                                                                    },
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                                <EditOptionItem
                                                    style={{ marginLeft: 10 }}
                                                    label="&Delta;Y"
                                                    value={
                                                        getNodeTranslation(
                                                            shapeElement
                                                        )[canvasViewMode].y
                                                    }
                                                    onChange={(y) => {
                                                        let translation = getNodeTranslation(
                                                            shapeElement!
                                                        );

                                                        this.props.canvasTreeStore.updateShapeElementAction(
                                                            this.props
                                                                .shapeElementId!,
                                                            {
                                                                nodeTranslation: {
                                                                    ...translation,
                                                                    [canvasViewMode]: {
                                                                        ...translation[
                                                                            canvasViewMode
                                                                        ],
                                                                        y: y,
                                                                    },
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                                <EditOptionItem
                                                    style={{ marginLeft: 10 }}
                                                    label="&deg;"
                                                    value={
                                                        getNodeTranslation(
                                                            shapeElement
                                                        )[canvasViewMode].angle
                                                    }
                                                    onChange={(angle) => {
                                                        let translation = getNodeTranslation(
                                                            shapeElement!
                                                        );
                                                        // let nodePosition =
                                                        //     shapeElement?.nodePosition;
                                                        // let additionalChanges: {
                                                        //     nodePosition?: NodePosition;
                                                        // } = {};
                                                        // if (
                                                        //     angle === 0 &&
                                                        //     nodePosition != null
                                                        // ) {
                                                        //     nodePosition[
                                                        //         this.props.canvasTreeStore.canvasViewMode
                                                        //     ].x +=
                                                        //         translation[
                                                        //             this.props.canvasTreeStore.canvasViewMode
                                                        //         ].x;
                                                        //     nodePosition[
                                                        //         this.props.canvasTreeStore.canvasViewMode
                                                        //     ].y +=
                                                        //         translation[
                                                        //             this.props.canvasTreeStore.canvasViewMode
                                                        //         ].y;
                                                        //     additionalChanges.nodePosition =
                                                        //         nodePosition;
                                                        // }
                                                        this.props.canvasTreeStore.updateShapeElementAction(
                                                            this.props
                                                                .shapeElementId!,
                                                            {
                                                                // ...(additionalChanges as any),
                                                                nodeTranslation: {
                                                                    ...translation,
                                                                    [canvasViewMode]: {
                                                                        ...translation[
                                                                            canvasViewMode
                                                                        ],
                                                                        angle: angle,
                                                                        // x:
                                                                        //     angle ===
                                                                        //     0
                                                                        //         ? 0
                                                                        //         : translation[
                                                                        //               this
                                                                        //                   .props
                                                                        //                   .canvasTreeStore
                                                                        //                   .canvasViewMode
                                                                        //           ]
                                                                        //               .x,
                                                                        // y:
                                                                        //     angle ===
                                                                        //     0
                                                                        //         ? 0
                                                                        //         : translation[
                                                                        //               this
                                                                        //                   .props
                                                                        //                   .canvasTreeStore
                                                                        //                   .canvasViewMode
                                                                        //           ]
                                                                        //               .y,
                                                                    },
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                            </>
                                        )}
                                        {backgroundElement != null && (
                                            <>
                                                <EditOptionItem
                                                    style={{ marginLeft: 10 }}
                                                    label="&Delta;X"
                                                    value={
                                                        getNodeTranslationFromBackground(
                                                            backgroundElement
                                                        )[canvasViewMode].x
                                                    }
                                                    onChange={(x) => {
                                                        let translation = getNodeTranslationFromBackground(
                                                            backgroundElement!
                                                        );

                                                        this.props.canvasTreeStore.updateBackgroundAction(
                                                            backgroundElement!
                                                                .id,
                                                            {
                                                                style_options: {
                                                                    ...backgroundElement!
                                                                        .style_options,
                                                                    nodeTranslation: {
                                                                        ...translation,
                                                                        [canvasViewMode]: {
                                                                            ...translation[
                                                                                canvasViewMode
                                                                            ],
                                                                            x: x,
                                                                        },
                                                                    },
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                                <EditOptionItem
                                                    style={{ marginLeft: 10 }}
                                                    label="&Delta;Y"
                                                    value={
                                                        getNodeTranslationFromBackground(
                                                            backgroundElement
                                                        )[canvasViewMode].y
                                                    }
                                                    onChange={(y) => {
                                                        let translation = getNodeTranslationFromBackground(
                                                            backgroundElement!
                                                        );

                                                        this.props.canvasTreeStore.updateBackgroundAction(
                                                            backgroundElement!
                                                                .id,
                                                            {
                                                                style_options: {
                                                                    ...backgroundElement!
                                                                        .style_options,
                                                                    nodeTranslation: {
                                                                        ...translation,
                                                                        [canvasViewMode]: {
                                                                            ...translation[
                                                                                canvasViewMode
                                                                            ],
                                                                            y: y,
                                                                        },
                                                                    },
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                                <EditOptionItem
                                                    style={{ marginLeft: 10 }}
                                                    label="&deg;"
                                                    value={
                                                        getNodeTranslationFromBackground(
                                                            backgroundElement
                                                        )[canvasViewMode].angle
                                                    }
                                                    onChange={(angle) => {
                                                        let nodeTranslation = getNodeTranslationFromBackground(
                                                            backgroundElement!
                                                        );
                                                        // let changes: Partial<CanvasBackground> =
                                                        //     {};
                                                        // if (angle === 0) {
                                                        //     changes =
                                                        //         this.props
                                                        //             .canvasTreeStore
                                                        //             .canvasViewMode ===
                                                        //         "desktop"
                                                        //             ? {
                                                        //                   x:
                                                        //                       backgroundElement!
                                                        //                           .x +
                                                        //                       nodeTranslation
                                                        //                           .desktop
                                                        //                           .x,
                                                        //                   y:
                                                        //                       backgroundElement!
                                                        //                           .y +
                                                        //                       nodeTranslation
                                                        //                           .desktop
                                                        //                           .y,
                                                        //               }
                                                        //             : {
                                                        //                   mobile_x:
                                                        //                       backgroundElement!
                                                        //                           .mobile_x +
                                                        //                       nodeTranslation
                                                        //                           .mobile
                                                        //                           .x,
                                                        //                   mobile_y:
                                                        //                       backgroundElement!
                                                        //                           .mobile_y +
                                                        //                       nodeTranslation
                                                        //                           .mobile
                                                        //                           .y,
                                                        //               };
                                                        // }
                                                        let newTranslation = {
                                                            ...nodeTranslation,
                                                            [canvasViewMode]: {
                                                                ...nodeTranslation[
                                                                    canvasViewMode
                                                                ],
                                                                angle: angle,

                                                                // x:
                                                                //     angle === 0
                                                                //         ? 0
                                                                //         : nodeTranslation[
                                                                //               this
                                                                //                   .props
                                                                //                   .canvasTreeStore
                                                                //                   .canvasViewMode
                                                                //           ].x,
                                                                // y:
                                                                //     angle === 0
                                                                //         ? 0
                                                                //         : nodeTranslation[
                                                                //               this
                                                                //                   .props
                                                                //                   .canvasTreeStore
                                                                //                   .canvasViewMode
                                                                //           ].y,
                                                            },
                                                        };

                                                        this.props.canvasTreeStore.updateBackgroundAction(
                                                            backgroundElement!
                                                                .id,
                                                            {
                                                                // ...(changes as any),
                                                                style_options: {
                                                                    ...backgroundElement!
                                                                        .style_options,
                                                                    nodeTranslation: newTranslation,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                    </div>
                </Draggable>

                {this.state.showLinkPopupOptions && (
                    <LinkOptionsPopup
                        canvasTreeStore={this.props.canvasTreeStore}
                        currentModuleId={this.props.currentModuleId}
                        node={node as CanvasElement | CanvasSubmitButton}
                        x={this.state.showLinkPopupOptions.x}
                        onClose={() => {
                            this.setState({ showLinkPopupOptions: null });
                        }}
                    />
                )}
            </Portal>
        );
    }
}

export default PlacementToolbar;
