import React, { useState, useEffect } from "react";
import { Navbar, Button } from "react-bootstrap";

import StatusPopup, { PopupStatus } from "common/StatusPopup";
import MessagePopup from "common/MessagePopup";
import { goToInternalLink } from "common/InternalLinksHelper";
import { stripeAccountConnect, stripeAccountCheckConnected } from "./StripeApi";
import { completeAuthorizationApi } from "../../connected_accounts_page/api";

const StripeConnect = () => {
    const [status, setStatus] = useState<PopupStatus | null>(null);
    const [message, setMessage] = useState("");
    const [stripeConnected, setStripeConnected] = useState(false);
    const [reconnectPopupOpen, setReconnectPopupOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("code") || urlParams.has("state")) {
            completeAuthorizationApi(window.location.href)
                .then(() => {
                    goToInternalLink("subscriptions.html");
                })
                .catch((message) => {
                    console.log(message);
                    setStatus(PopupStatus.Error);
                    setMessage(message.toString());
                    setTimeout(() => {
                        goToInternalLink("subscriptions.html");
                    }, 3000);
                });
        } else {
            stripeAccountCheckConnected()
                .then((stripeConnected) => {
                    setStripeConnected(stripeConnected);
                    setLoading(false);
                })
                .catch((message) => {
                    console.log(message);
                    setLoading(false);
                });
        }
    }, []);

    const renderTokens = (): React.ReactElement => (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <span
                className="regular-text"
                style={{
                    fontSize: "18px",
                    fontStyle: "italic",
                    color: "#333333B2",
                    fontWeight: 100,
                    marginBottom: 10,
                }}
            >
                {`Status: ${
                    loading
                        ? "loading"
                        : stripeConnected
                        ? "connected"
                        : "not connected"
                }`}
            </span>
            {!loading && (
                <Button
                    type="button"
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        width: 112,
                        height: 38,
                        background:
                            "linear-gradient(94.78deg, #1A60B3 0%, #13529B 100%)",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontSize: 16,
                        padding: "0 10px",
                    }}
                    onClick={() => {
                        if (!stripeConnected) {
                            stripeAccountConnect();
                        } else {
                            setReconnectPopupOpen(true);
                        }
                    }}
                >
                    {stripeConnected ? "Reconnect" : "Connect"}
                </Button>
            )}
            {loading && <div style={{ width: 112, height: 38 }} />}
        </div>
    );

    return (
        <Navbar className="bg-light justify-content-between">
            {renderTokens()}
            {status != null && (
                <StatusPopup
                    status={status}
                    message={message}
                    onClose={() => {
                        setStatus(null);
                        setMessage("");
                    }}
                />
            )}
            {reconnectPopupOpen && (
                <MessagePopup
                    title={"Reconnect Stripe account"}
                    danger
                    message={
                        "After reconnecting your Stripe account " +
                        "(or connecting a different account) all " +
                        "subscriptions to your apps and kits will " +
                        "be set to cancel at period end, and users " +
                        "will need to resubscribe. Do you want to continue?"
                    }
                    acceptButtonTitle={"Reconnect"}
                    onAccept={() => {
                        stripeAccountConnect();
                    }}
                    onReject={() => setReconnectPopupOpen(false)}
                />
            )}
        </Navbar>
    );
};

export default StripeConnect;
