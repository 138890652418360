import React, { Component } from "react";
import { Resizable } from "re-resizable";
import { Button } from "react-bootstrap";

import { mainStyle } from "common/MainStyle";
import ImageIFrame from "common/graphics/ImageIFrame";

class MainComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            base64Image: "",
            imageSize: undefined,
        };
    }

    componentDidMount() {
        if (this.props.imageBlob != null) {
            let reader = new FileReader();
            reader.readAsDataURL(this.props.imageBlob);
            reader.onloadend = () => {
                let base64Image = reader.result;
                this.setState({ base64Image: base64Image });
            };
        }
    }

    render() {
        let {
            imageBlob,
            insightId,
            storyId,
            slideIndex,
            finding,
            edited,
            onEdit,
        } = this.props;
        let imageSrc;
        // imageBlob exists specifically for the situation, when the user just
        // pasted a screenshot, but dit not save the card yet.
        if (imageBlob != null) {
            imageSrc = this.state.base64Image;
        } else {
            imageSrc = finding.content.imageSrc;
            if (imageSrc == null) {
                if (storyId != null) {
                    if (isNaN(storyId))
                        // storyId can be a string even
                        imageSrc = `/stories_shared/${encodeURIComponent(
                            storyId
                        )}/${slideIndex}/image`;
                    else imageSrc = `/stories/${storyId}/${slideIndex}/image`;
                } else {
                    if (typeof insightId === "string")
                        imageSrc = `/insights_shared/${encodeURIComponent(
                            insightId
                        )}/image`;
                    else imageSrc = `/insights/${insightId}/image`;
                }
            }
        }
        let widthCoefficient =
            parseFloat(finding.content.relativeWidth || "100%") / 100;
        let heightCoefficient =
            parseFloat(finding.content.relativeHeight || "100%") / 100;

        if (!edited) {
            return this.props.caller === "renderIcon" ? (
                <div
                    className="center-container"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            width: finding.content.relativeWidth || "100%",
                            height: finding.content.relativeHeight || "100%",
                            border: "1px solid transparent",
                        }}
                    >
                        <img
                            alt=""
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                            src={imageSrc}
                        />
                    </div>
                </div>
            ) : (
                <div
                    className="center-container"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <ImageIFrame
                        inFinding
                        inStory={storyId != null}
                        src={imageSrc}
                        childSize={
                            this.state.imageSize
                                ? {
                                      width:
                                          widthCoefficient *
                                          this.state.imageSize.width,
                                      height:
                                          heightCoefficient *
                                          this.state.imageSize.height,
                                  }
                                : undefined
                        }
                        onSizeObtained={(size) => {
                            this.setState((state) => ({
                                imageSize: this.state.imageSize ?? size,
                            }));
                        }}
                    />
                </div>
            );
        } else {
            return (
                <Resizable
                    enable={
                        this.state.imageSize == null
                            ? {
                                  top: false,
                                  right: false,
                                  bottom: false,
                                  left: false,
                                  topRight: false,
                                  bottomRight: false,
                                  bottomLeft: false,
                                  topLeft: false,
                              }
                            : {
                                  top: true,
                                  right: true,
                                  bottom: true,
                                  left: true,
                                  topRight: true,
                                  bottomRight: true,
                                  bottomLeft: true,
                                  topLeft: true,
                              }
                    }
                    size={{
                        height: this.state.imageSize
                            ? this.state.imageSize.height * heightCoefficient
                            : "100%",
                        width: this.state.imageSize
                            ? this.state.imageSize.width * widthCoefficient
                            : "100%",
                    }}
                    onResizeStop={(evt, direction, ref, d) => {
                        evt.preventDefault();
                        let newfinding = Object.assign({}, finding);
                        let newWidthCoefficient =
                            (this.state.imageSize.width * widthCoefficient +
                                d.width) /
                            this.state.imageSize.width;
                        let newHeightCoefficient =
                            (this.state.imageSize.height * heightCoefficient +
                                d.height) /
                            this.state.imageSize.height;
                        newfinding.content.relativeHeight = `${
                            newHeightCoefficient * 100
                        }%`;
                        newfinding.content.relativeWidth = `${
                            newWidthCoefficient * 100
                        }%`;

                        onEdit(newfinding);
                    }}
                    maxWidth="100%"
                    maxHeight="100%"
                    style={{
                        border: `1px dashed ${mainStyle.getPropertyValue(
                            "--secondary-content-color"
                        )}`,
                    }}
                >
                    <Button
                        className="close"
                        onClick={() => {
                            onEdit(undefined);
                        }}
                    >
                        <span
                            style={{
                                color: "white",
                                height: "26px",
                            }}
                        >
                            &times;
                        </span>
                    </Button>
                    <ImageIFrame
                        inFinding
                        inEdit
                        childSize={
                            this.state.imageSize
                                ? {
                                      width:
                                          widthCoefficient *
                                          this.state.imageSize.width,
                                      height:
                                          heightCoefficient *
                                          this.state.imageSize.height,
                                  }
                                : undefined
                        }
                        src={imageSrc}
                        onSizeObtained={(size) => {
                            this.setState((state) => ({
                                imageSize: this.state.imageSize ?? size,
                            }));
                        }}
                    />
                </Resizable>
            );
        }
    }
}

export { MainComponent };