import React, { Component } from "react";
import Board, { widgets } from "react-trello";
import cx from "classnames";
import styles from "./KanbanBoard.module.css";
import LaneMenu from "react-trello/dist/components/Lane/LaneHeader/LaneMenu";
import { Button } from "react-bootstrap";
import { v1 as uuidv1 } from "uuid";
import castToType from "common/utilities/castToType";
import { AggregateFunctions } from "common/AggregateFunctionsAliases";
import "common/styles/MultilineInput.css";
import "common/styles/MovableCardWrapper.css";
import { useDrop } from "react-dnd";

const boardStyle = {
    backgroundColor: "transparent",
    height: "100%",
    overflowY: undefined,
    overflow: "auto",
};

const cardStyle = {
    borderRadius: 8,
    border: "1px solid #dfe3ed",
    backgroundColor: "white",
};

const laneStyle = {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#eeedef",
    backgroundColor: "#f7f8fb",
};

const NewLaneSection = ({ t, onClick }) => (
    <section
        style={{
            width: 182,
            margin: 5,
            position: "relative",
            padding: 5,
            display: "inline-flex",
            height: "auto",
            flexDirection: "column",
        }}
    >
        <Button
            className={cx(
                "btn btn-sm btn-primary my-primary",
                styles.addLaneButton
            )}
            onClick={onClick}
        >
            {"\uFF0B Add lane"}
        </Button>
    </section>
);

const DropArea = (props) => {
    const ref = React.useRef(null);
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(otherItem, monitor) {
            if (props.onConfigChanged != null) {
                let config = {
                    ...props.config,
                };
                if (props.dragType === DragType.lane) {
                    config.categoryVariable = otherItem.content.variableName;
                    config.categoryVariableIndex =
                        otherItem.content.variableIndex;
                }
                if (props.dragType === DragType.title) {
                    config.titleVariable = otherItem.content.variableName;
                    config.titleVariableIndex = otherItem.content.variableIndex;
                }
                if (props.dragType === DragType.variable) {
                    if (config.selectedTargetVariablesIndices == null) {
                        config.selectedTargetVariablesIndices = [];
                    }
                    config.selectedTargetVariablesIndices.push(
                        otherItem.content.variableIndex
                    );
                }
                props.onConfigChanged(config, true);
            }
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    drop(ref);
    let size = {
        width: 0,
        height: 0,
    };
    switch (props.dragType) {
        case DragType.lane:
            size = {
                height: 290,
                width: 280,
            };
            break;
        case DragType.title:
            size = {
                height: 100,
                width: 250,
            };
            break;
        case DragType.variable:
            size = {
                height: 70,
                width: 230,
            };
            break;
        default:
            break;
    }

    return (
        <div
            ref={ref}
            style={{
                ...size,
                display: "flex",
                justifyContent: "center",
                border: `dashed 3px ${collected.hover ? "#36B743" : "#8DB8E3"}`,
                backgroundColor: collected.hover ? "#F4FBF5" : "#EBF2F9EE",
            }}
        >
            <span
                className="no-selection"
                style={{
                    marginTop: "22px",
                    color: collected.hover ? "#36B743" : "#3B82C9",
                    fontSize: "14px",
                    fontWeight: 500,
                }}
            >
                Drop variable here
            </span>
        </div>
    );
};

class NewLaneForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
        };
    }

    handleSubmit = () => {
        let value = this.getValue();
        if (value !== undefined) {
            this.props.onAdd({
                id: uuidv1(),
                title: this.getValue(),
            });
        } else {
            this.setState({ errorMessage: "title has invalid type" });
        }
    };

    getValue = () =>
        castToType(
            this.refInput.getValue(),
            this.props.metadata?.laneTitleType,
            this.props.metadata?.laneTitleFormat
        );

    onClickOutside = (a, b, c) => {
        let value = this.getValue();
        if (value !== undefined) {
            this.handleSubmit();
        } else {
            this.props.onCancel();
        }
    };

    render() {
        const { onCancel, t } = this.props;
        return (
            <section
                style={{
                    backgroundColor: "#f7f8fb",
                    borderRadius: 0,
                    margin: 5,
                    position: "relative",
                    padding: 10,
                    display: "inline-flex",
                    height: "auto",
                    maxHeight: "90%",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        fontSize: 15,
                        width: 268,
                        height: "auto",
                    }}
                >
                    <widgets.NewLaneTitleEditor
                        ref={(ref) => (this.refInput = ref)}
                        placeholder={t("placeholder.title")}
                        onCancel={this.props.onCancel}
                        onSave={this.handleSubmit}
                        resize="vertical"
                        border
                        autoFocus
                    />
                </div>
                {this.state.errorMessage != null && (
                    <span
                        style={{
                            fontWeight: "bold",
                            color: "red",
                            lineHeight: "18px",
                            cursor: "auto",
                            fontSize: 14,
                            marginRight: 5,
                        }}
                    >
                        {this.state.errorMessage}
                    </span>
                )}
                <div style={{ marginTop: 10 }}>
                    <Button
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            background: "#5aac44",
                            color: "#fff",
                            transition: "background 0.3s ease",
                            minHeight: 32,
                            padding: "4px 16px",
                            verticalAlign: "top",
                            marginTop: 0,
                            marginRight: 8,
                            fontWeight: "bold",
                            borderRadius: 3,
                            fontSize: 14,
                            cursor: "pointer",
                            marginBottom: 0,
                        }}
                        onClick={this.handleSubmit}
                    >
                        {t("button.Add lane")}
                    </Button>
                    <Button
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            background: "#999999",
                            color: "#fff",
                            transition: "background 0.3s ease",
                            minHeight: 32,
                            padding: "4px 16px",
                            verticalAlign: "top",
                            marginTop: 0,
                            marginRight: 8,
                            fontWeight: "bold",
                            borderRadius: 3,
                            fontSize: 14,
                            cursor: "pointer",
                            marginBottom: 0,
                        }}
                        onClick={onCancel}
                    >
                        {t("button.Cancel")}
                    </Button>
                </div>
            </section>
        );
    }
}

class NewCardForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            variables: {},
        };
    }

    updateField = (field, value) => {
        this.setState({ errorMessage: null, [field]: value });
    };

    updateVariableValue = (name, value) => {
        this.setState((state) => ({
            variables: {
                ...state.variables,
                [name]: value,
            },
        }));
    };

    handleAdd = () => {
        for (let field of ["title", "label", "description"]) {
            if (
                castToType(
                    this.state[field] ?? "",
                    this.props.metadata?.[`${field}Type`],
                    this.props.metadata?.[`${field}Format`]
                ) === undefined
            ) {
                this.setState({
                    errorMessage: `${field} has invalid type`,
                });
                return;
            }
        }
        for (let [name, type] of Object.entries(
            this.props.metadata?.variableTypes ?? {}
        )) {
            if (
                castToType(
                    this.state.variables[name] ?? "",
                    type,
                    this.props.metadata?.variableFormats[name]
                ) === undefined
            ) {
                this.setState({
                    errorMessage: `${name} has invalid type`,
                });
                return;
            }
        }

        this.props.onAdd(this.state);
    };

    render() {
        const { onCancel, t } = this.props;
        return (
            <div style={{ backgroundColor: "#f7f8fb" }}>
                <article
                    style={{
                        borderRadius: 0,
                        borderWidth: 1,
                        borderColor: "#dfe3ed",
                        backgroundColor: "#ecedf4",
                        position: "relative",
                        padding: 10,
                        cursor: "pointer",
                        maxWidth: 250,
                        marginBottom: 7,
                        minWidth: 230,
                    }}
                >
                    <header
                        style={{
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            borderBottom: "1px solid #eee",
                            paddingBottom: 6,
                            color: "#000",
                        }}
                    >
                        <span
                            style={{
                                fontWeight: "bold",
                                lineHeight: "18px",
                                cursor: this.props.draggable ? "grab" : "auto",
                                width: "70%",
                                fontSize: 14,
                            }}
                        >
                            <widgets.InlineInput
                                border
                                resize="vertical"
                                value={this.state?.title ?? "title"}
                                placeholder={t("placeholder.title")}
                                onSave={(val) => this.updateField("title", val)}
                                autoFocus
                            />
                        </span>
                        <span
                            style={{
                                width: "38%",
                                textAlign: "right",
                                paddingRight: 10,
                                fontSize: 10,
                            }}
                        >
                            <widgets.InlineInput
                                border
                                resize="vertical"
                                value={this.state?.label ?? ""}
                                placeholder={t("placeholder.label")}
                                onSave={(val) => this.updateField("label", val)}
                            />
                        </span>
                    </header>
                    <div
                        style={{
                            fontSize: 12,
                            color: "#4d4d4d",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        <widgets.InlineInput
                            border
                            resize="vertical"
                            value={this.state?.description ?? "description"}
                            placeholder={t("placeholder.description")}
                            onSave={(val) =>
                                this.updateField("description", val)
                            }
                        />
                    </div>
                    {Object.keys(this.props.metadata?.variableTypes ?? {}).map(
                        (name) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "70%",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            lineHeight: "18px",
                                            cursor: "auto",
                                            fontSize: 14,
                                            marginRight: 5,
                                        }}
                                    >
                                        {name}
                                    </span>
                                    <widgets.InlineInput
                                        value={this.state.variables[name] ?? ""}
                                        border
                                        placeholder={t("placeholder.label")}
                                        resize="vertical"
                                        onSave={(value) =>
                                            this.updateVariableValue(
                                                name,
                                                value
                                            )
                                        }
                                    />
                                </div>
                            );
                        }
                    )}
                    {this.state.errorMessage != null && (
                        <span
                            style={{
                                fontWeight: "bold",
                                color: "red",
                                lineHeight: "18px",
                                cursor: "auto",
                                fontSize: 14,
                                marginRight: 5,
                            }}
                        >
                            {this.state.errorMessage}
                        </span>
                    )}
                </article>
                <Button
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        background: "#5aac44",
                        color: "#fff",
                        transition: "background 0.3s ease",
                        minHeight: 32,
                        padding: "4px 16px",
                        verticalAlign: "top",
                        marginTop: 0,
                        marginRight: 8,
                        fontWeight: "bold",
                        borderRadius: 3,
                        fontSize: 14,
                        cursor: "pointer",
                        marginBottom: 0,
                    }}
                    onClick={this.handleAdd}
                >
                    {t("button.Add card")}
                </Button>
                <Button
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        background: "#999999",
                        color: "#fff",
                        transition: "background 0.3s ease",
                        minHeight: 32,
                        padding: "4px 16px",
                        verticalAlign: "top",
                        marginTop: 0,
                        marginRight: 8,
                        fontWeight: "bold",
                        borderRadius: 3,
                        fontSize: 14,
                        cursor: "pointer",
                        marginBottom: 0,
                    }}
                    onClick={onCancel}
                >
                    {t("button.Cancel")}
                </Button>
            </div>
        );
    }
}

const LaneHeader = (props) => {
    const {
        updateTitle,
        canAddLanes,
        onDelete,
        onDoubleClick,
        editLaneTitle,
        label,
        title,
        metadata,
        titleStyle,
        labelStyle,
        t,
        laneDraggable,
    } = props;
    let descriptions = [];
    if (
        props.config.operationVariable != null &&
        props.config.selectedSummaryVariables != null &&
        props.config.selectedSummaryVariables.length > 0
    ) {
        let operation = AggregateFunctions[props.config.operationVariable];
        for (let summaryVariable of props.config.selectedSummaryVariables) {
            let values = [];
            for (let card of props.cards) {
                let value = card.variables[summaryVariable];
                if (value == null && card.summary_variables != null) {
                    value = card.summary_variables[summaryVariable];
                }
                value = Number(value);
                if (!isNaN(value)) {
                    values.push(value);
                }
            }
            descriptions.push(`${summaryVariable} : ${operation(values)}`);
        }
    }
    let description = descriptions.join("\n");
    return (
        <header
            style={{
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: editLaneTitle ? 0 : "0px 5px",
                lineHeight: editLaneTitle ? "30px" : undefined,
            }}
            onDoubleClick={onDoubleClick}
        >
            <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                    style={{
                        fontWeight: "bold",
                        fontSize: 15,
                        lineHeight: "18px",
                        cursor: laneDraggable ? "grab" : "auto",
                        width: "70%",
                        ...titleStyle,
                    }}
                >
                    {editLaneTitle ? (
                        <widgets.InlineInput
                            value={title ?? ""}
                            border
                            placeholder={t("placeholder.title")}
                            resize="vertical"
                            onSave={(value) => {
                                if (
                                    castToType(
                                        value,
                                        metadata.titleType,
                                        metadata.titleFormat
                                    ) !== undefined
                                ) {
                                    updateTitle(value);
                                }
                            }}
                        />
                    ) : (
                        title
                    )}
                </span>
                {label && (
                    <span
                        style={{
                            width: "38%",
                            textAlign: "right",
                            paddingRight: 10,
                            fontSize: 13,
                        }}
                    >
                        <span style={labelStyle}>{label}</span>
                    </span>
                )}
                {canAddLanes && <LaneMenu t={t} onDelete={onDelete} />}
            </div>
            <span
                style={{
                    fontSize: 15,
                    lineHeight: "18px",
                    cursor: laneDraggable ? "grab" : "auto",
                    width: "90%",
                    ...titleStyle,
                }}
            >
                {description}
            </span>
        </header>
    );
};

const EmptyComponent = (props) => {
    return null;
};

class Tag extends Component {
    render() {
        const { title, color, bgcolor, tagStyle, ...otherProps } = this.props;
        return (
            <span
                style={{
                    padding: "2px 3px",
                    borderRadius: 3,
                    margin: "2px 5px",
                    fontSize: "70%",
                    color: color || "white",
                    backgroundColor: bgcolor || "orange",
                    ...tagStyle,
                }}
                {...otherProps}
            >
                {title}
            </span>
        );
    }
}

class Card extends Component {
    onDelete = (e) => {
        this.props.onDelete();
        e.stopPropagation();
    };

    updateCard = (field, value) => {
        if (
            castToType(
                value,
                this.props.metadata?.[`${field}Type`],
                this.props.metadata?.[`${field}Format`]
            ) !== undefined
        ) {
            this.props.onChange({ [field]: value, id: this.props.id });
        }
    };

    updateVariableValue = (name, value) => {
        if (
            castToType(
                value,
                this.props.metadata?.variableTypes[name],
                this.props.metadata?.variableFormats[name]
            ) !== undefined
        ) {
            this.props.onChange({
                variables: {
                    ...this.props.variables,
                    [name]: value,
                },
                id: this.props.id,
            });
        }
    };

    render() {
        const {
            showDeleteButton,
            style,
            tagStyle,
            onClick,
            className,
            id,
            title,
            label,
            description,
            variables,
            tags,
            cardDraggable,
            editable,
            t,
        } = this.props;

        return (
            <article
                className={cx(`movable-card-wrapper ${className}`, styles.card)}
                data-id={id}
                onClick={onClick}
                style={style}
            >
                <header
                    style={{
                        marginBottom: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        borderBottom: "1px solid #eee",
                        paddingBottom: 6,
                        color: "#000",
                    }}
                >
                    <span
                        style={{
                            fontWeight: "bold",
                            lineHeight: "18px",
                            cursor: cardDraggable ? "grab" : "auto",
                            width: "70%",
                            fontSize: 14,
                        }}
                    >
                        {editable ? (
                            <widgets.InlineInput
                                value={title ?? ""}
                                border
                                placeholder={t("placeholder.title")}
                                resize="vertical"
                                onSave={(value) =>
                                    this.updateCard("title", value)
                                }
                            />
                        ) : (
                            title
                        )}
                    </span>
                    <span
                        style={{
                            width: "38%",
                            textAlign: "right",
                            paddingRight: 10,
                            fontSize: 10,
                        }}
                    >
                        {editable ? (
                            <widgets.InlineInput
                                value={label ?? ""}
                                border
                                placeholder={t("placeholder.label")}
                                resize="vertical"
                                onSave={(value) =>
                                    this.updateCard("label", value)
                                }
                            />
                        ) : (
                            label
                        )}
                    </span>
                    {showDeleteButton && (
                        <DeleteButton onClick={this.onDelete} />
                    )}
                </header>
                <div
                    style={{
                        fontSize: 12,
                        color: "#4d4d4d",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {editable ? (
                        <widgets.InlineInput
                            value={description ?? ""}
                            border
                            placeholder={t("placeholder.description")}
                            resize="vertical"
                            onSave={(value) =>
                                this.updateCard("description", value)
                            }
                        />
                    ) : (
                        description
                    )}
                </div>
                {this.props.dragType === DragType.variable && (
                    <DropArea {...this.props} />
                )}
                {this.props.dragType !== DragType.variable && (
                    <>
                        {variables &&
                            Object.entries(variables).map(([name, value]) => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "70%",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                lineHeight: "18px",
                                                cursor: cardDraggable
                                                    ? "grab"
                                                    : "auto",
                                                fontSize: 14,
                                                marginRight: 5,
                                            }}
                                        >
                                            {name}
                                        </span>
                                        <widgets.InlineInput
                                            value={value ?? ""}
                                            border
                                            placeholder={t("placeholder.label")}
                                            resize="vertical"
                                            onSave={(value) =>
                                                this.updateVariableValue(
                                                    name,
                                                    value
                                                )
                                            }
                                        />
                                    </div>
                                );
                            })}
                        {tags && tags.length > 0 && (
                            <div
                                style={{
                                    borderTop: "1px solid #eee",
                                    paddingTop: 6,
                                    textAlign: "right",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                }}
                            >
                                {tags.map((tag) => (
                                    <Tag
                                        key={tag.title}
                                        {...tag}
                                        tagStyle={tagStyle}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}
            </article>
        );
    }
}

// Currently unused
// class MultilineInput extends Component {
//     onFocus = (e) => e.target.select();
//
//     onMouseDown = (e) => {
//         if (document.activeElement !== e.target) {
//             e.preventDefault();
//             this.refInput.focus();
//         }
//     };
//
//     onBlur = () => {
//         this.updateValue();
//     };
//
//     onKeyDown = (e) => {
//         if (e.keyCode === 27) {
//             this.setValue(this.props.value);
//             this.refInput.blur();
//             e.preventDefault();
//         }
//         if (e.keyCode === 9) {
//             if ((this.getValue()?.length ?? 0) === 0) {
//                 this.props.onCancel();
//             }
//             this.refInput.blur();
//             e.preventDefault();
//         }
//     };
//
//     getValue = () => this.refInput.value;
//     setValue = (value) => (this.refInput.value = value);
//
//     updateValue = () => {
//         if (this.getValue() !== this.props.value) {
//             this.props.onSave(this.getValue());
//         }
//     };
//
//     setRef = (ref) => {
//         this.refInput = ref;
//         if (this.props.resize !== "none") {
//             autosize(this.refInput);
//         }
//     };
//
//     UNSAFE_componentWillReceiveProps(nextProps) {
//         this.setValue(nextProps.value);
//     }
//
//     render() {
//         const { autoFocus, border, value, placeholder } = this.props;
//
//         return (
//             <textarea
//                 className={`multiline-input ${
//                     border ? "focus-border" : "focus-no-border"
//                 }`}
//                 ref={this.setRef}
//                 border={border}
//                 onMouseDown={this.onMouseDown}
//                 onFocus={this.onFocus}
//                 onBlur={this.onBlur}
//                 onKeyDown={this.onKeyDown}
//                 placeholder={
//                     (value != null && value.length === 0
//                         ? undefined
//                         : placeholder) ?? ""
//                 }
//                 defaultValue={value ?? ""}
//                 autoComplete="off"
//                 autoCorrect="off"
//                 autoCapitalize="off"
//                 spellCheck="false"
//                 dataGramm="false"
//                 autoFocus={autoFocus}
//             />
//         );
//     }
// }

const DeleteButton = (props) => {
    return (
        <div
            {...props}
            style={{
                textAlign: "center",
                position: "absolute",
                top: -1,
                right: 2,
                cursor: "pointer",
            }}
        >
            <button
                style={{
                    transition: "all 0.5s ease",
                    display: "inline-block",
                    border: "none",
                    fontSize: 8,
                    height: 15,
                    lineHeight: 1,
                    margin: "0 0 8px",
                    padding: 0,
                    textAlign: "center",
                    width: 15,
                    background: "inherit",
                    cursor: "pointer",
                }}
            >
                &#10006;
            </button>
        </div>
    );
};

const DragType = Object.freeze({ lane: 1, title: 2, variable: 3 });

export default function KanbanBoard(props) {
    let dragType = undefined;
    if (props.columnDragActive) {
        if (props.config.categoryVariableIndex == null)
            dragType = DragType.lane;
        else if (props.config.titleVariableIndex == null)
            dragType = DragType.title;
        else dragType = DragType.variable;
    }
    const addMetadata = (Component) => {
        return (componentProps) => (
            <Component
                {...componentProps}
                metadata={props.data?.metadata}
                dragType={dragType}
                config={props.config}
                onConfigChanged={props.onConfigChanged}
            />
        );
    };
    const components = {
        NewLaneSection: addMetadata(NewLaneSection),
        NewLaneForm: addMetadata(NewLaneForm),
        NewCardForm: addMetadata(NewCardForm),
        LaneHeader: addMetadata(LaneHeader),
        Card: addMetadata(Card),
    };
    if (dragType === DragType.lane) {
        components.LaneHeader = EmptyComponent;

        components.ScrollableLane = addMetadata(DropArea);
    }
    if (dragType === DragType.title) {
        components.Card = addMetadata(DropArea);
    }
    return (
        <div
            className="cancel-drag"
            style={{ height: "100%", ...props.containerStyle }}
            onKeyDown={(evt) => evt.stopPropagation()}
        >
            <Board
                style={boardStyle}
                cardStyle={cardStyle}
                laneStyle={laneStyle}
                components={components}
                cardDragClass="transform-none"
                laneDragClass="transform-none"
                editable={props.editable}
                draggable={props.editable}
                canAddLanes={props.editable}
                editLaneTitle={props.editable}
                onDataChange={props.onDataChanged}
                data={props.data}
            />
        </div>
    );
}
