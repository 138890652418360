


/*!
 * function TooltipContent renders content of tooltip according to selected
 * options as an array of TooltipItemContent components
 *
 */

import { formatNumber, isNumberFormat, MapVariableOption } from "common/Canvas";
import { VariableOption } from "common/Variables";
import { useMemo } from "react";
import { tooltipItemRect } from "../helpers";
import { TooltipProps } from "../types";
import { TooltipItemContent } from "./TooltipItenContent";


export function TooltipContent(props: TooltipProps) {
  const hiddenMaps = props?.hiddenMaps ?? [];
  const backActionBtn = props.actionButton?.buttonText === "BACK";
  const isParentFinding = props.isParentFinding ?? false;
  function isNullOrEmpty(value: any) {
      return value == null || value === "";
  }
  function getValue(option: { variable: VariableOption }, index: number) {
      if (props.choroplethData != null) {
          if (index === 0) return props.choroplethData.metric;
          if (index === 1) return props.choroplethData.country;
      }

      return props.data?.[option.variable.value.toString()]?.[
          props.dataIndex!
      ];
  }
  const tooltipVariables = useMemo(() => {
      return (props.variables ?? []).filter(
          (item): item is MapVariableOption => item != null
      );
  }, [props.variables]);
  let editable = props.editable ?? false;
  let scale = props.scale ?? 1;
  let widthOffsets: {
      x: number;
      y: number;
      width: number;
      height: number;
  }[] = [];
  let tooltipSize = {
      width: 0,
      height: 0,
  };
  let widthOffsetsMap: {
      [index: string]: number;
  } = {};
  if (!editable) {
      tooltipVariables.forEach((option, index) => {
          let value = getValue(option, index);
          let linkValue = undefined;
          if (option.linkVariable != null) {
              linkValue = getValue({ variable: option.linkVariable }, index);
          }
          let defaultY = tooltipSize.height / scale;
          let defaultTooltipRect = tooltipItemRect({
              scale: scale,
              editable: false,
              widthOffset: 0,
              defaultY: defaultY,
              index: index,
              onChange: props.onChange,
              mapFinding: props.mapFinding,
              option: option,
              value: isNullOrEmpty(value) ? "value" : value!,
              linkValue: linkValue ?? undefined,
              mapId: props.mapId,
          });
          if (isNullOrEmpty(value)) {
              let width = defaultTooltipRect.width / scale;
              let x = defaultTooltipRect.x / scale;
              let height = defaultTooltipRect.height / scale;
              let y = defaultTooltipRect.y / scale;
              widthOffsets.push({
                  width: width,
                  x: x,
                  height: height,
                  y: y,
              });
              return;
          }
          tooltipSize.width = Math.max(
              tooltipSize.width,
              defaultTooltipRect.x + defaultTooltipRect.width
          );
          tooltipSize.height = Math.max(
              tooltipSize.height,
              defaultTooltipRect.y + defaultTooltipRect.height
          );
      });
      tooltipSize = {
          width: 0,
          height: 0,
      };

      tooltipVariables.forEach((option, index) => {
          if (option == null) return;
          let value = getValue(option, index);
          let linkValue = undefined;
          if (option.linkVariable != null) {
              linkValue = getValue({ variable: option.linkVariable }, index);
          }

          if (isNullOrEmpty(value)) return;
          let defaultY = tooltipSize.height / scale;
          let defaultTooltipRect = tooltipItemRect({
              scale: scale,
              editable: false,
              widthOffset: 0,
              defaultY: defaultY,
              index: index,
              onChange: props.onChange,
              mapFinding: props.mapFinding,
              option: option,
              value: value ?? "",
              linkValue: linkValue ?? undefined,
              mapId: props.mapId,
          });
          let xPosition = defaultTooltipRect.x / scale;
          let yPosition = defaultTooltipRect.y / scale;
          let height = defaultTooltipRect.height / scale;
          widthOffsetsMap[index] = widthOffsets
              .filter(
                  (item) =>
                      item.x + item.width < xPosition &&
                      Math.abs(item.y - yPosition) < 5 &&
                      Math.abs(item.height + item.y - (height + yPosition)) <
                          5
              )
              .map((item) => item.width)
              .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
              );
          tooltipSize.width = Math.max(
              tooltipSize.width,
              defaultTooltipRect.x + defaultTooltipRect.width
          );
          tooltipSize.height = Math.max(
              tooltipSize.height,
              defaultTooltipRect.y + defaultTooltipRect.height
          );
      });
  }
  tooltipSize = {
      width: 0,
      height: 0,
  };
  let items = tooltipVariables.map((option, index) => {
      let linkValue = undefined;
      let value = getValue(option, index);
      if (option.linkVariable != null) {
          linkValue = getValue({ variable: option.linkVariable }, index);
      }
      if (option.linkVariable != null && editable && isNullOrEmpty(linkValue))
          linkValue = "link";
      if (editable && isNullOrEmpty(value)) value = "value";
      if (!editable && isNullOrEmpty(value)) return null;
      if (
          !editable &&
          option.linkVariable != null &&
          isNullOrEmpty(linkValue)
      )
          return null;

      if (isNumberFormat(option.options.format)) {
          value = formatNumber(value as number, option.options.format);
      }

      let defaultY = tooltipSize.height / scale;
      let defaultTooltipRect = tooltipItemRect({
          scale: scale,
          editable: false,
          widthOffset: widthOffsetsMap[index] ?? 0,
          defaultY: defaultY,
          index: index,
          onChange: props.onChange,
          mapFinding: props.mapFinding,
          option: option,
          value: value ?? "",
          linkValue: linkValue ?? undefined,
          mapId: props.mapId,
      });
      let defaultHeight = defaultTooltipRect.height / scale;
      let defaultWidth = defaultTooltipRect.width / scale;
      let tooltipRect = tooltipItemRect({
          defaultHeight: defaultHeight,
          defaultWidth: defaultWidth,
          scale: scale,
          editable,
          widthOffset: widthOffsetsMap[index] ?? 0,
          defaultY: defaultY,
          index: index,
          onChange: props.onChange,
          mapFinding: props.mapFinding,
          option: option,
          value: value ?? "",
          linkValue: linkValue ?? undefined,
          mapId: props.mapId,
      });
      // already scaled;
      tooltipSize.width = Math.max(
          tooltipSize.width,
          tooltipRect.x + tooltipRect.width
      );
      tooltipSize.height = Math.max(
          tooltipSize.height,
          tooltipRect.y + tooltipRect.height
      );
      return (
          <TooltipItemContent
              defaultHeight={defaultHeight}
              defaultWidth={defaultWidth}
              defaultY={defaultY}
              scale={scale}
              key={index}
              editable={editable}
              widthOffset={widthOffsetsMap[index] ?? 0}
              index={index}
              onChange={props.onChange}
              mapFinding={props.mapFinding}
              option={option}
              value={value ?? ""}
              linkValue={linkValue ?? undefined}
              mapId={props.mapId}
          />
      );
  });
  tooltipSize.width += 5;
  tooltipSize.height += 5;

  if (props.actionButton?.isShown) {
      if (tooltipSize.width < 120) {
          tooltipSize.width += 45;
      }
      if (!backActionBtn && isParentFinding) {
          tooltipSize.height += 70 * hiddenMaps.length;
          switch (hiddenMaps.length) {
              case 2:
                  tooltipSize.height -= 15;
                  break;
              case 3:
                  tooltipSize.height -= 30;
                  break;
          }
      } else if (backActionBtn) {
          tooltipSize.height += 70;
      }
  }

  const actionBtnStyle = {
      position: "absolute",
      bottom: 10,
      left: tooltipSize.width / 2 - 62,
      width: 124,
      height: 45,
      backgroundColor: "rgb(31, 142, 250)",
      borderRadius: 5,
      color: "#fff",
      border: "none",
  };

  const tooltipTopMargin = props.mapFinding?.type === "maps_choropleth" ? 3 : 4;

  return (
      <div
          style={{
              minWidth: tooltipSize.width,
              minHeight: tooltipSize.height,
              maxWidth: tooltipSize.width,
              maxHeight: tooltipSize.height,
              position: "relative",
              marginTop: tooltipTopMargin * scale,
          }}
          className={props.className}
      >
          {items}
          {!backActionBtn && isParentFinding && (
              <>
                  {hiddenMaps.map((hiddenMap, idx) => {
                      let btnOffset = 10;
                      btnOffset += 50 * idx;
                      const mapName = hiddenMap.finding.type
                          .replace("maps_", "")
                          .toUpperCase();

                      return (
                          <button
                              style={{
                                  ...(actionBtnStyle as any),
                                  bottom: btnOffset,
                              }}
                              onClick={() => {
                                  // +1 because this index goes to maps array that has a parent finding with index = 0
                                  // but we need a child map index so we do +1
                                  const mapIdx = (
                                      props?.maps ?? []
                                  ).findIndex(
                                      (map) => map.id === hiddenMap.id
                                  );
                                  props.actionButton?.onClick?.(
                                      props.dataIndex!,
                                      mapIdx
                                  );
                              }}
                          >
                              {`${
                                  props.actionButton?.buttonText ?? "ENHANCE"
                              } ${mapName}`}
                          </button>
                      );
                  })}
              </>
          )}
          {backActionBtn && (
              <button
                  style={{
                      ...(actionBtnStyle as any),
                  }}
                  onClick={() => {
                      props.actionButton?.onClick?.(props.dataIndex!, 0); // 0 - index of the main(parent) map
                  }}
              >
                  BACK
              </button>
          )}
      </div>
  );
}
