import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { ReactComponent as GlobeIcon } from "icons/globe.svg";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import styles from "./styles.module.css";
import { Folder } from "common/FoldersApi";
import { ReactComponent as FolderIcon } from "icons/folder.svg";

interface Props {
    folder: Folder;
    onClick: () => void;
    onRename: () => void;
    onDelete: () => void;
}

function FolderCard({ folder, onClick, onRename, onDelete }: Props) {
    const menuId = `folder_${folder.id}_${folder.type}`;
    const { show } = useContextMenu({
        id: menuId,
    });

    function handleContextMenu(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        show(event);
    }

    function deleteCallback(event: React.MouseEvent) {
        event.stopPropagation();

        onDelete();
    }

    function cardClick() {
        onClick();
    }

    const renderTooltip = (props: any) => {
        return (
            <Tooltip id={`tooltip-${menuId}`} placement="top" {...props}>
                {folder.title}
            </Tooltip>
        );
    };

    return (
        <>
            <div
                className={styles.root}
                onClick={cardClick}
                onContextMenu={handleContextMenu}
            >
                {/* {thumbnail ? (
                    <img
                        src={thumbnail}
                        alt="presentation front page"
                        className={styles.image}
                    />
                ) : (
                    <div className={styles.thumbnailPlaceholder}>
                        <h3 className={styles.titleThumbnail}>{title}</h3>
                    </div>
                )} */}
                <div
                    style={{
                        backgroundColor: "rgb(234, 236, 240)",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <FolderIcon />
                </div>
                <div className={styles.content}>
                    <div className={styles.leftSide}>
                        <GlobeIcon className={styles.icon} />
                        <div>
                            <OverlayTrigger
                                delay={{ show: 200, hide: 300 }}
                                overlay={renderTooltip}
                            >
                                <h5 className={styles.title}>{folder.title}</h5>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    onClick={deleteCallback}
                    className={styles.deleteButton}
                >
                    <img
                        src="/dist/img/insights/cross_big.png"
                        alt="delete"
                        className={styles.deleteIcon}
                    />
                </button>
            </div>
            <Menu id={menuId}>
                <Item
                    onClick={() => {
                        onRename();
                    }}
                >
                    Rename
                </Item>
            </Menu>
        </>
    );
}

export default FolderCard;
