import React, { Component } from "react";

import TrainModelStep from "../../../../common/TrainModelStep";

class MainComponent extends Component {
    render() {
        return (
            <TrainModelStep
                disabled={this.props.disabled}
                tag="roe"
                caption="Train a model for roe"
                index={this.props.index}
            />
        );
    }
}

export { MainComponent };
export let requirePermission = "ModelTraining";
