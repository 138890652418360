// import React, { Component, FunctionComponent } from 'react';
// import config from "sections.json";
//
import "common/styles/App.css";
// import { AppModuleProps, LeftPanelItem } from 'AppModule';
// import ScaledPage from 'common/ScaledPage';
// import { APIUpdateEndpoint } from './APIInterface';
// import { Alert, AlertProps, Table } from 'react-bootstrap';
// import axios from 'common/ServerConnection';
//
// import COVID from './components/COVID';
// import { APIButtonProps } from './components/APIButton';
// import Plaid from './components/Plaid';
// import Facebook from './components/Facebook';
// import Google from './components/Google';
// import { observer } from 'mobx-react';
// import { mainStyle } from 'common/MainStyle';


// 2.4.21 Update:  After the changes to the backend permissions, I was getting the following error when compiling typscript:
// 2.6.21 modified the module_list.json.  So I believe the following error should be fixed.
/*

/repo/turtle_pt_frontend/src/modules/api_page/main.tsx
TypeScript error in /repo/turtle_pt_frontend/src/modules/api_page/main.tsx(200,9):
Type '{ name: string; href: string; icon: string; }' is not assignable to type 'LeftPanelItem'.
  Object literal may only specify known properties, and 'name' does not exist in type 'LeftPanelItem'.  TS2322

    198 |     section: string = "API",
    199 |     leftPanelItem: LeftPanelItem = {
  > 200 |         name: apiConfig.name,
        |         ^
    201 |         href: "api.html",
    202 |         icon: "API"
    203 |     },

*/
// Need to ask V how the updated permissions would work.

/*
const APIConversion: Record<string, any> = {
    'COVID': COVID,
    'plaid': Plaid,
    'fb': Facebook,
    'googleads': Google,
}

const apiConfig = config['API'];

interface State {
    endpoints: APIUpdateEndpoint[],
    selected: APIUpdateEndpoint[],
    alerts: Message[],
    error?: string
}

interface Message {
    text: string,
    show: boolean,
    setShow: (show: boolean) => void,
    variant?: AlertProps["variant"],
}

const FlashAlert: FunctionComponent<Message> = ({ text, variant, show, setShow }) => {
    // setTimeout(() => {
    //     setShow(false);
    // }, 5000);

    return (
        <Alert
            variant={variant}
            show={show}
            onClose={() => { setShow(false) }}
            // @ts-ignore
            transition={false}
            dismissible
        >
            {text}
        </Alert>
    );
}

const MainComponent = observer(
    class MainComponent extends Component<AppModuleProps, State> {
        constructor(props: AppModuleProps) {
            super(props);

            this.state = {
                endpoints: [],
                selected: [],
                alerts: [],
            }

            this.createMessage = this.createMessage.bind(this);
        }

        componentDidMount() {
            // Get the list of APIs that can be updated
            axios
                .get("/api/list_apis")
                .then((response) => {
                    if (!response.data.success) {
                        this.setState({
                            error: response.data.error_msg
                        })
                    } else {
                        this.setState({
                            endpoints: response.data.apis
                        })
                    }
                })
        }

        setMessageShow(i: number, show: boolean) {
            let newAlerts = [...this.state.alerts];
            let alert = {...newAlerts[i]};
            alert.show = show;
            newAlerts[i] = alert;
            this.setState({ alerts: newAlerts })
        }

        createMessage(text: string, variant?: AlertProps["variant"], show: boolean = true) {
            const id = this.state.alerts.length;
            let newAlerts: Message[] = [...this.state.alerts];
            const newAlert: Message = {
                text: text,
                variant: variant,
                show: show,
                setShow: this.setMessageShow.bind(this, id)
            };

            newAlerts.push(newAlert);

            this.setState({
                alerts: newAlerts
            })
        }

        render() {
            const components: JSX.Element[] = this.state.endpoints.map(
                (endpoint, index) => {
                    const eProps: APIButtonProps = {...endpoint, createMessage: this.createMessage, key: index};
                    return React.createElement(
                        APIConversion[endpoint.slug],
                        eProps
                    );
                }
            )

            return (
                <ScaledPage>
                    <div className="content-wrapper hide-scroll">
                        <section className="content-header">
                            <h3>{apiConfig.name}</h3>
                        </section>
                        <section
                            className="content"
                            style={this.props.positionStyle}
                        >
                            <div className="flex-column">
                                <div
                                    className="dashboard-rect"
                                    style={{
                                        width: "100%",
                                        padding: 30,
                                        minHeight: 717,
                                    }}
                                >
                                    <Table
                                        borderless
                                        className="api-table"
                                        style={{
                                            borderColor: "transparent",
                                        }}
                                    >
                                        <thead
                                            style={{
                                                color: mainStyle.getPropertyValue("--sections-title-color"),
                                                fontSize: "17px",
                                                textAlign: "center"
                                            }}
                                        >
                                            <tr>
                                                <th>API</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Latest Pull</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            style={{
                                                fontSize: "17px",
                                                color: mainStyle.getPropertyValue("--content-primary-text-color")
                                            }}
                                        >
                                            {components}
                                        </tbody>
                                    </Table>


                                    {
                                        this.state.alerts.slice().reverse().map(
                                            (alert, index) => (<FlashAlert key={index} {...alert} />)
                                        )
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </ScaledPage>
            );
        }
    }
);

export { MainComponent };


export let route: string = "/api.html",
    section: string = "API",
    leftPanelItem: LeftPanelItem = {
        name: apiConfig.name,  // Need to ask V about how the old apiConfig.name works?
        href: "api.html",
        icon: "API"
    },
    requireGroup: string[] = Object.entries(apiConfig).filter(
        ([_key, value]) => value
    ).map(
        ([key, _value]) => key
    );
*/
