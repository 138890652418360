import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M2 20H22V24H2V20Z" {...props} />
			<path
				d="M5.49 17H7.91L9.18 13.42H14.83L16.09 17H18.51L13.25 3H10.75L5.49 17ZM9.91 11.39L11.94 5.6H12.06L14.09 11.39H9.91Z"
				fill="#D8D8D8"
			/>
		</svg>
	);
}