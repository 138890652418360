import React, { Component, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import Select, { createFilter, StylesConfig, GroupBase } from "react-select";
import { customFilterStyles } from "common/SelectStyles";
import SearchComponent, { SearchComponentOption } from "common/SearchComponent";
import { Variable, VariableOption } from "common/Variables";
import StringOption from "common/StringOption";
import { mainStyle } from "common/MainStyle";
import { NodeValue } from "./Canvas";

export interface NodeLinkOption {
    label: string;
    value: number;
    target: string | number | undefined | (NodeValue | undefined)[];
    isCloneInput: boolean;
    isGlobalInput?: boolean;
}

type NullableOption =
    | StringOption
    | {
          label: string;
          value: null;
      };

const comparisonOperations: ReadonlyArray<StringOption> = [
    {
        label: ">",
        value: ">",
    },
    {
        label: "<",
        value: "<",
    },
    {
        label: ">=",
        value: ">=",
    },
    {
        label: "<=",
        value: "<=",
    },
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

const comparisonPanelOperations: ReadonlyArray<StringOption> = [
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

const linkOptions: ReadonlyArray<StringOption> = [
    {
        label: "Value",
        value: "value",
    },
    {
        label: "Link",
        value: "link",
    },
];

const logicalOperations: ReadonlyArray<StringOption> = [
    {
        label: "OR",
        value: "or",
    },
    {
        label: "AND",
        value: "and",
    },
];

interface SwitchProps {
    offColor: string;
    onColor: string;
    offHandleColor: string;
    onHandleColor: string;
}

export interface Condition {
    variable: VariableOption | null;
    operation: StringOption | null;
    value:
        | SearchComponentOption
        | NodeLinkOption
        | null
        | SearchComponentOption[]
        | NodeLinkOption[];
    logical: StringOption | null;
    leftBracket: StringOption | null;
    rightBracket: StringOption | null;
    isInput?: boolean;
}

export function getSharedIdsList(condition: Condition): number[] {
    let newSharedBoxIds: number[] = [];
    if (
        !isMultiCondition(condition) &&
        condition.isInput &&
        condition.value != null &&
        (condition.value as NodeLinkOption).isCloneInput
    ) {
        newSharedBoxIds.push((condition.value as NodeLinkOption).value);
    }
    if (
        isMultiCondition(condition) &&
        condition.isInput &&
        Array.isArray(condition.value) &&
        condition.value.length > 0
    ) {
        for (let subValue of condition.value) {
            if (subValue != null && (subValue as NodeLinkOption).isCloneInput) {
                newSharedBoxIds.push((subValue as NodeLinkOption).value);
            }
        }
    }
    return newSharedBoxIds;
}

export function filterNonEmptyConditions(conditions: Condition[]) {
    return conditions.filter(
        (condition) =>
            condition.variable != null &&
            condition.operation != null &&
            condition.value != null &&
            (!Array.isArray(condition.value) || condition.value.length > 0)
    );
}

export function isEmptyVariableCondition(condition: Condition) {
    return condition.variable == null;
}

export function isEmptyConditions(conditions?: Condition[]) {
    let filteredConditions: Condition[] | undefined = undefined;
    if (conditions != null) {
        filteredConditions = conditions.filter(
            (condition) =>
                condition.variable != null &&
                condition.operation != null &&
                condition.value != null &&
                (!Array.isArray(condition.value) || condition.value.length > 0)
        );
        return filteredConditions.length === 0;
    }
    return true;
}

export function isMultiCondition(condition: Condition): boolean {
    return (
        condition.operation != null &&
        ["in", "not in"].includes(condition.operation.value)
    );
}

export function getConditionValue(condition: Condition): any {
    if (isMultiCondition(condition)) {
        return condition.isInput
            ? (condition.value as NodeLinkOption[])
                  ?.flatMap((value) => value.target)
                  .flat()
            : (condition.value as SearchComponentOption[])?.flatMap(
                  (value) => value.value
              );
    } else {
        return condition.isInput
            ? (condition.value as NodeLinkOption)?.target
            : (condition.value as SearchComponentOption)?.value;
    }
}

export function getConditionLabel(condition: Condition): any {
    if (isMultiCondition(condition)) {
        return condition.isInput
            ? (condition.value as NodeLinkOption[])?.map((value) => value.label)
            : (condition.value as SearchComponentOption[])?.map(
                  (value) => value.label
              );
    } else {
        return condition.isInput
            ? (condition.value as NodeLinkOption)?.label
            : (condition.value as SearchComponentOption)?.label;
    }
}

function conditionsToJson(conditions: Condition[]) {
    return conditions.map((condition: Condition) => ({
        operation: condition.operation?.value,
        logical: condition.logical?.value,
        variable: condition.variable?.value,
        value: getConditionValue(condition),
        leftBracket: condition.leftBracket?.value,
        rightBracket: condition.rightBracket?.value,
    }));
}

function conditionsToString(
    conditions: Condition[],
    truncate: boolean = true
): string {
    conditions = conditions.filter(
        (condition) =>
            condition.variable != null &&
            condition.value != null &&
            condition.operation != null
    );
    let str = conditions.reduce((accumulator, currentValue) => {
        let currentString = `${
            currentValue.logical != null
                ? " ".concat(currentValue.logical.value)
                : ""
        } ${currentValue.leftBracket?.value ?? ""}${getQuotedVariable(
            currentValue.variable?.label
        )} ${currentValue.operation?.value ?? ""} ${
            prepareValue(
                currentValue.variable?.type,
                getConditionLabel(currentValue)
            ) ?? ""
        }${currentValue.rightBracket?.value ?? ""}`;
        return accumulator.concat(currentString);
    }, "");
    if (str.length > 20 && truncate) return str.slice(0, 20) + "...";
    return str;
}

function getQuotedVariable(variable: string | undefined): string {
    if (variable) {
        return `\`${variable}\``;
    }
    return "";
}

function prepareValue(typevar: string | undefined, valuevar: any): string {
    if (typevar !== "integer") {
        if (valuevar instanceof Array) {
            return `('${(valuevar as Array<string>).join("', '")}')`;
        }

        return `'${valuevar}'`;
    }
    return valuevar;
}

interface Props {
    single?: boolean;
    dataScopeId: number | string | undefined;
    currentModuleId?: number;
    allVariables: Variable[];
    onChange: (conditions: Condition[]) => void;
    title: string;
    value?: Condition[];
    style?: CSSProperties;
    small?: boolean;
    titleStyle?: CSSProperties;
    linkElementStyle?: CSSProperties;
    allowLinks?: boolean;
    nodeLinkOptions?: NodeLinkOption[];
    rowStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    rowStyleWithoutPlusMinus?:
        | CSSProperties
        | ((rowIndex: number) => CSSProperties);
    inputStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    selectStyle?: StylesConfig | ((rowIndex: number) => StylesConfig);
    auxiliarySelectorsStyle?:
        | StylesConfig
        | ((rowIndex: number) => StylesConfig);
    buttonsStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    linkSwitchStyle?: Partial<SwitchProps>;
    rowWithoutPlusMinusClassName?: string;
    asWizard?: boolean;
    switch?: boolean;
}

interface FocusProps {
    onNext: () => void;
    onPrevious: () => void;
    children: JSX.Element;
}

function FocusIterable(props: FocusProps) {
    return React.cloneElement(React.Children.only(props.children), {
        onKeyDown: (evt: any) => {
            evt.stopPropagation();
            let value = (evt.target as any).value;
            if (evt.key === "Enter") {
                props.onNext();
            }
            if (evt.key === "Backspace" && value.length === 0) {
                props.onPrevious();
            }
        },
    });
}

interface State {
    emptyFocused: Set<number>;
}

class ConditionsSelector extends Component<Props, State> {
    selectGrid: {
        [key: string]: {
            [key: string]: any;
        };
    };
    public static get defaultValue() {
        return [
            {
                variable: null,
                operation: null,
                value: null,
                logical: logicalOperations[0],
                leftBracket: null,
                rightBracket: null,
            },
        ];
    }

    constructor(props: Props) {
        super(props);
        this.selectGrid = {};
        this.state = {
            emptyFocused: new Set(),
        };
    }
    private deleteCondition(index: number) {
        this.setState((state) => {
            state.emptyFocused.delete(index);
            return state;
        });
        let lastIndex = (this.props.value ?? []).length - 1;
        this.changeConditions((conditions) => {
            let newConditions = Array.from(conditions);
            newConditions.splice(index, 1);
            if (newConditions.length === 0)
                newConditions.push({
                    variable: null,
                    operation: null,
                    value: null,
                    logical: logicalOperations[0],
                    leftBracket: null,
                    rightBracket: null,
                });
            return newConditions;
        });
        setTimeout(() => {
            delete this.selectGrid[lastIndex];
        }, 0);
    }

    private addCondition(index: number) {
        this.changeConditions((conditions) => {
            let newConditions = Array.from(conditions);
            newConditions.splice(index + 1, 0, {
                variable: null,
                operation: null,
                value: null,
                leftBracket: null,
                rightBracket: null,
                logical: logicalOperations[0],
            });
            return newConditions;
        });
    }

    private changeConditions(func: (conditions: Condition[]) => Condition[]) {
        const conditions: Condition[] = func(this.props.value ?? []);
        this.props.onChange(conditions);
    }

    private selectNextFocusItem(index: number, key: string): void {
        let keys = Object.keys(this.selectGrid[index] ?? {}).sort();
        let currentSubIndex = keys.findIndex((value) => value === key);
        if (currentSubIndex === -1) return;
        if (currentSubIndex < keys.length - 1) {
            this.selectGrid[index][keys[currentSubIndex + 1]]?.focus();
        } else {
            if (index < Object.keys(this.selectGrid).length - 1) {
                let newIndex = index + 1;
                let keys = Object.keys(this.selectGrid[newIndex]).sort();
                this.selectGrid[newIndex][keys[0]]?.focus();
            } else if (this.props.single) {
                this.addCondition(index);
                setTimeout(() => {
                    this.selectNextFocusItem(index, key);
                }, 0);
            }
        }
    }
    private showPlaceholder(condition: Condition, index: number) {
        if (
            isEmptyVariableCondition(condition) &&
            !this.state.emptyFocused.has(index) &&
            this.props.asWizard
        )
            return true;
        return false;
    }

    private selectPreviousFocusItem(index: number, key: string): void {
        let keys = Object.keys(this.selectGrid[index] ?? {}).sort();
        let currentSubIndex = keys.findIndex((value) => value === key);
        if (currentSubIndex === -1) return;
        if (currentSubIndex > 0) {
            this.selectGrid[index][keys[currentSubIndex - 1]]?.focus();
        } else {
            if (index > 0) {
                setTimeout(() => {
                    this.deleteCondition(index);
                    let newIndex = index - 1;
                    let keys = Object.keys(this.selectGrid[newIndex]).sort();
                    this.selectGrid[newIndex][keys[keys.length - 1]]?.focus();
                }, 0);
            }
        }
    }

    private toFunction<T>(
        value: T | ((rowIndex: number) => T) | undefined | null,
        defaultValue: T
    ): (rowIndex: number) => T {
        if (value == null) {
            return () => defaultValue;
        } else if (typeof value !== "function") {
            return () => value;
        } else {
            return value as (rowIndex: number) => T;
        }
    }

    public render(): JSX.Element {
        const conditionVariablesForSelection: VariableOption[] = this.props.allVariables.map(
            (variable, index) => ({
                label: variable.name,
                value: index,
                type: variable.type,
                panel: variable.panel,
            })
        );
        const leftBrackets: NullableOption[] = [
            {
                label: "(",
                value: "(",
            },
            {
                label: "\u200B",
                value: null,
            },
        ];
        const rightBrackets: NullableOption[] = [
            {
                label: ")",
                value: ")",
            },
            {
                label: "\u200B",
                value: null,
            },
        ];

        const conditions: Condition[] =
            this.props.value ?? ConditionsSelector.defaultValue;

        const small: boolean = this.props.small ?? false;

        const rowStyle = this.toFunction(this.props.rowStyle, {});
        const rowStyleWithoutPlusMinus = this.toFunction(
            this.props.rowStyleWithoutPlusMinus,
            {}
        );
        const inputStyle = this.toFunction(this.props.inputStyle, {});
        const selectStyle = this.toFunction(this.props.selectStyle, {});
        const buttonsStyle = this.toFunction(this.props.buttonsStyle, {});
        const auxiliarySelectorsStyle = this.toFunction(
            this.props.auxiliarySelectorsStyle,
            {}
        );

        return (
            <div
                className={"flex-simple-column"}
                tabIndex={0}
                style={{
                    marginTop: small ? 5 : 25,
                    marginLeft: small ? undefined : 100,
                    minHeight: small ? undefined : 300,
                    ...this.props.style,
                }}
                onKeyDown={(evt) => {
                    if (
                        evt.key === "ArrowDown" ||
                        evt.key === "ArrowUp" ||
                        evt.key === "ArrowRight"
                    )
                        evt.stopPropagation();
                }}
            >
                {!small && (
                    <span
                        className="exploration-big-title-span"
                        style={this.props.titleStyle}
                    >
                        {this.props.title}
                    </span>
                )}

                {conditions.map((condition: Condition, index: number) => {
                    return (
                        <div
                            className={"my-row"}
                            key={index}
                            style={{
                                marginTop: index > 0 ? 5 : 0,
                                backgroundColor: mainStyle.getPropertyValue(
                                    "--filters-background-color"
                                ),
                                ...rowStyle(index),
                                // height:38
                            }}
                        >
                            {this.showPlaceholder(condition, index) ? (
                                <div
                                    className={cx(
                                        "my-row",
                                        this.props.rowWithoutPlusMinusClassName
                                    )}
                                    key={`${index}_withoutPlusMinus`}
                                    onClick={() => {
                                        this.setState((state) => {
                                            state.emptyFocused.add(index);
                                            return state;
                                        });
                                    }}
                                    style={{
                                        ...rowStyleWithoutPlusMinus(index),
                                        flex: 1,
                                        color: "#666666",
                                        padding: "10px",
                                        fontFamily: "Roboto",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        height: 30,
                                        alignItems: "center",
                                    }}
                                >
                                    {"Filter by..."}
                                </div>
                            ) : (
                                <div
                                    className={cx(
                                        "my-row",
                                        this.props.rowWithoutPlusMinusClassName
                                    )}
                                    key={`${index}_withoutPlusMinus`}
                                    style={{
                                        ...rowStyleWithoutPlusMinus(index),
                                        overflow: "hidden",
                                    }}
                                >
                                    {index > 0 && (
                                        <div
                                            style={{
                                                width: 50,
                                            }}
                                        >
                                            <FocusIterable
                                                onNext={() => {
                                                    this.selectNextFocusItem(
                                                        index,
                                                        "0"
                                                    );
                                                }}
                                                onPrevious={() => {
                                                    this.selectPreviousFocusItem(
                                                        index,
                                                        "0"
                                                    );
                                                }}
                                            >
                                                <Select
                                                    isClearable={true}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    ref={(ref) => {
                                                        this.selectGrid[
                                                            index
                                                        ] = {
                                                            ...this.selectGrid[
                                                                index
                                                            ],
                                                            "0": ref,
                                                        };
                                                    }}
                                                    placeholder={"Logical"}
                                                    styles={
                                                        {
                                                            ...customFilterStyles,
                                                            container: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                height: "38px",
                                                            }),
                                                            ...selectStyle(
                                                                index
                                                            ),
                                                            ...auxiliarySelectorsStyle(
                                                                index
                                                            ),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 100000000,
                                                            }),
                                                        } as StylesConfig<
                                                            StringOption,
                                                            false,
                                                            GroupBase<
                                                                StringOption
                                                            >
                                                        >
                                                    }
                                                    options={logicalOperations}
                                                    value={condition.logical}
                                                    onChange={(newValue) => {
                                                        this.changeConditions(
                                                            (conditions) => {
                                                                let newConditions: Condition[] = Array.from(
                                                                    conditions
                                                                );
                                                                newConditions[
                                                                    index
                                                                ] = {
                                                                    ...newConditions[
                                                                        index
                                                                    ],
                                                                    logical: (newValue ??
                                                                        null) as StringOption | null,
                                                                };
                                                                return newConditions;
                                                            }
                                                        );
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            </FocusIterable>
                                        </div>
                                    )}
                                    {small && index === 0 && this.props.title && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span
                                                className="regular-text"
                                                style={{
                                                    width: "50px",
                                                    textAlign: "center",
                                                    ...this.props.titleStyle,
                                                }}
                                            >
                                                {this.props.title}
                                            </span>
                                        </div>
                                    )}
                                    {((small && !this.props.title) || !small) &&
                                        index === 0 &&
                                        this.props.value &&
                                        this.props.value.length > 1 && (
                                            <div
                                                style={{
                                                    width: "50px",
                                                    ...this.props.titleStyle,
                                                }}
                                            ></div>
                                        )}
                                    {!this.props.single && (
                                        <div
                                            style={{ width: 50, marginLeft: 1 }}
                                        >
                                            {(!this.props.asWizard ||
                                                condition.variable != null) && (
                                                <FocusIterable
                                                    onNext={() => {
                                                        this.selectNextFocusItem(
                                                            index,
                                                            "2"
                                                        );
                                                    }}
                                                    onPrevious={() => {
                                                        this.selectPreviousFocusItem(
                                                            index,
                                                            "2"
                                                        );
                                                    }}
                                                >
                                                    <Select
                                                        filterOption={createFilter(
                                                            {
                                                                ignoreAccents: false,
                                                            }
                                                        )}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "2": ref,
                                                            };
                                                        }}
                                                        placeholder={"Bracket"}
                                                        styles={
                                                            {
                                                                ...customFilterStyles,
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                }),
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                ...auxiliarySelectorsStyle(
                                                                    index
                                                                ),
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                }),
                                                            } as StylesConfig<
                                                                NullableOption,
                                                                false,
                                                                GroupBase<
                                                                    NullableOption
                                                                >
                                                            >
                                                        }
                                                        isClearable={true}
                                                        options={leftBrackets}
                                                        value={
                                                            condition.leftBracket
                                                        }
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions: Condition[] = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        leftBracket:
                                                                            newValue?.value !=
                                                                            null
                                                                                ? (newValue as StringOption)
                                                                                : null,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: "white",
                                                                primary25:
                                                                    "var(--selectors-background-hover-color)",
                                                            },
                                                        })}
                                                    />
                                                </FocusIterable>
                                            )}
                                        </div>
                                    )}

                                    <div
                                        style={{
                                            width: 100,
                                            marginLeft: 1,
                                            paddingLeft: 0,
                                        }}
                                    >
                                        <FocusIterable
                                            onNext={() => {
                                                this.selectNextFocusItem(
                                                    index,
                                                    "3"
                                                );
                                            }}
                                            onPrevious={() => {
                                                this.selectPreviousFocusItem(
                                                    index,
                                                    "3"
                                                );
                                            }}
                                        >
                                            <Select
                                                isClearable={true}
                                                menuPlacement="auto"
                                                menuPortalTarget={document.body}
                                                ref={(ref) => {
                                                    this.selectGrid[index] = {
                                                        ...this.selectGrid[
                                                            index
                                                        ],
                                                        "3": ref,
                                                    };
                                                }}
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Variable"}
                                                styles={
                                                    {
                                                        ...customFilterStyles,
                                                        container: (base) => ({
                                                            ...base,
                                                            height: "38px",
                                                        }),
                                                        ...selectStyle(index),
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 100000000,
                                                            // 11 - fontSize, 8 - average number of letters, 100 - extra padding
                                                            minWidth:
                                                                11 * 8 + 100,
                                                        }),
                                                    } as StylesConfig<
                                                        VariableOption,
                                                        false,
                                                        GroupBase<
                                                            VariableOption
                                                        >
                                                    >
                                                }
                                                options={
                                                    conditionVariablesForSelection
                                                }
                                                value={condition.variable}
                                                onChange={(newValue) => {
                                                    this.changeConditions(
                                                        (conditions) => {
                                                            let newConditions = Array.from(
                                                                conditions
                                                            );
                                                            newConditions[
                                                                index
                                                            ] = {
                                                                ...newConditions[
                                                                    index
                                                                ],
                                                                variable: (newValue ??
                                                                    null) as VariableOption | null,
                                                                value: null,
                                                                operation: null,
                                                            };
                                                            return newConditions;
                                                        }
                                                    );
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </FocusIterable>
                                    </div>
                                    <div style={{ width: 60, marginLeft: 1 }}>
                                        {(!this.props.asWizard ||
                                            condition.variable != null) && (
                                            <FocusIterable
                                                onNext={() => {
                                                    this.selectNextFocusItem(
                                                        index,
                                                        "4"
                                                    );
                                                }}
                                                onPrevious={() => {
                                                    this.selectPreviousFocusItem(
                                                        index,
                                                        "4"
                                                    );
                                                }}
                                            >
                                                <Select
                                                    isClearable={true}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    ref={(ref) => {
                                                        this.selectGrid[
                                                            index
                                                        ] = {
                                                            ...this.selectGrid[
                                                                index
                                                            ],
                                                            "4": ref,
                                                        };
                                                    }}
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    placeholder={"Operation"}
                                                    styles={
                                                        {
                                                            ...customFilterStyles,
                                                            container: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                height: "38px",
                                                            }),
                                                            ...selectStyle(
                                                                index
                                                            ),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 100000000,
                                                                minWidth: 60,
                                                            }),
                                                        } as StylesConfig<
                                                            StringOption,
                                                            false,
                                                            GroupBase<
                                                                StringOption
                                                            >
                                                        >
                                                    }
                                                    options={
                                                        condition.variable !=
                                                            null &&
                                                        ![
                                                            "int",
                                                            "float",
                                                            "datetime",
                                                        ].includes(
                                                            condition.variable
                                                                .type
                                                        )
                                                            ? comparisonPanelOperations
                                                            : comparisonOperations
                                                    }
                                                    onChange={(newValue) => {
                                                        this.changeConditions(
                                                            (conditions) => {
                                                                let newConditions = Array.from(
                                                                    conditions
                                                                );
                                                                newConditions[
                                                                    index
                                                                ] = {
                                                                    ...newConditions[
                                                                        index
                                                                    ],
                                                                    operation: (newValue ??
                                                                        null) as StringOption | null,
                                                                };
                                                                let condition =
                                                                    newConditions[
                                                                        index
                                                                    ];
                                                                if (
                                                                    isMultiCondition(
                                                                        condition
                                                                    )
                                                                ) {
                                                                    if (
                                                                        !Array.isArray(
                                                                            condition.value
                                                                        )
                                                                    ) {
                                                                        condition.value =
                                                                            condition.value !=
                                                                            null
                                                                                ? [
                                                                                      condition.value,
                                                                                  ]
                                                                                : null;
                                                                    }
                                                                } else {
                                                                    if (
                                                                        Array.isArray(
                                                                            condition.value
                                                                        )
                                                                    ) {
                                                                        condition.value =
                                                                            condition
                                                                                .value?.[0] ??
                                                                            null;
                                                                    }
                                                                }
                                                                return newConditions;
                                                            }
                                                        );
                                                    }}
                                                    value={condition.operation}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            </FocusIterable>
                                        )}
                                    </div>
                                    {this.props.allowLinks && (
                                        <div
                                            style={{ width: 50, marginLeft: 1 }}
                                        >
                                            {(!this.props.asWizard ||
                                                (condition.variable != null &&
                                                    condition.operation !=
                                                        null)) && (
                                                <FocusIterable
                                                    onNext={() => {
                                                        this.selectNextFocusItem(
                                                            index,
                                                            "5"
                                                        );
                                                    }}
                                                    onPrevious={() => {
                                                        this.selectPreviousFocusItem(
                                                            index,
                                                            "5"
                                                        );
                                                    }}
                                                >
                                                    <Select
                                                        isClearable={true}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "5": ref,
                                                            };
                                                        }}
                                                        filterOption={createFilter(
                                                            {
                                                                ignoreAccents: false,
                                                            }
                                                        )}
                                                        placeholder={""}
                                                        styles={
                                                            {
                                                                ...customFilterStyles,
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    height:
                                                                        "38px",
                                                                }),
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                    minWidth: 50,
                                                                }),
                                                            } as StylesConfig<
                                                                StringOption,
                                                                false,
                                                                GroupBase<
                                                                    StringOption
                                                                >
                                                            >
                                                        }
                                                        options={linkOptions}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            let isInput =
                                                                newValue?.value ===
                                                                "link";
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        isInput: isInput,
                                                                        value: null,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        value={
                                                            condition.isInput
                                                                ? linkOptions[1]
                                                                : linkOptions[0]
                                                        }
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: "white",
                                                                primary25:
                                                                    "var(--selectors-background-hover-color)",
                                                            },
                                                        })}
                                                    />
                                                </FocusIterable>
                                            )}
                                        </div>
                                    )}

                                    <div
                                        style={{
                                            marginLeft: 1,
                                            width: 100,
                                            flex: 1,
                                        }}
                                    >
                                        {(!this.props.asWizard ||
                                            (condition.variable != null &&
                                                condition.operation !=
                                                    null)) && (
                                            <FocusIterable
                                                onNext={() => {
                                                    this.selectNextFocusItem(
                                                        index,
                                                        "6"
                                                    );
                                                }}
                                                onPrevious={() => {
                                                    this.selectPreviousFocusItem(
                                                        index,
                                                        "6"
                                                    );
                                                }}
                                            >
                                                {!condition.isInput &&
                                                condition.variable != null &&
                                                (condition.variable.panel !==
                                                    "regular" ||
                                                    condition.variable.type ===
                                                        "str" ||
                                                    condition.variable.type ===
                                                        "datetime") ? (
                                                    <SearchComponent
                                                        isMulti={isMultiCondition(
                                                            condition
                                                        )}
                                                        allowNewOption={true}
                                                        formatCreateLabel={(
                                                            userInput
                                                        ) =>
                                                            `Select "${userInput}"`
                                                        }
                                                        createOptionPosition="first"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        currentModuleId={
                                                            this.props
                                                                .currentModuleId
                                                        }
                                                        clearable={true}
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "6": ref,
                                                            };
                                                        }}
                                                        dataScopeId={
                                                            this.props
                                                                .dataScopeId
                                                        }
                                                        customStyles={
                                                            {
                                                                ...customFilterStyles,
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                    minWidth:
                                                                        11 *
                                                                            (condition
                                                                                .variable
                                                                                ?.label
                                                                                .length ??
                                                                                1) + // 11 - fontSize, 60 - extra padding
                                                                        60,
                                                                }),
                                                            } as StylesConfig<
                                                                SearchComponentOption,
                                                                false,
                                                                GroupBase<
                                                                    SearchComponentOption
                                                                >
                                                            >
                                                        }
                                                        initialValue={
                                                            condition.value
                                                        }
                                                        searchIndex={
                                                            condition.variable
                                                                .value
                                                        }
                                                        onOptionSelected={(
                                                            selectedValue
                                                        ) => {
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    if (
                                                                        isMultiCondition(
                                                                            condition
                                                                        ) &&
                                                                        Array.isArray(
                                                                            selectedValue
                                                                        ) &&
                                                                        selectedValue.length ===
                                                                            0
                                                                    ) {
                                                                        selectedValue = null;
                                                                    }
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        value: selectedValue,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                    />
                                                ) : !condition.isInput ? (
                                                    <input
                                                        onMouseDown={(evt) => {
                                                            evt.stopPropagation();
                                                        }}
                                                        className="like-select"
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "6": ref,
                                                            };
                                                        }}
                                                        style={{
                                                            backgroundColor: mainStyle.getPropertyValue(
                                                                "--filters-item-color"
                                                            ),
                                                            color: mainStyle.getPropertyValue(
                                                                "--filters-text-color"
                                                            ),
                                                            borderRadius: 0,
                                                            paddingTop: "0px",
                                                            paddingBottom:
                                                                "0px",
                                                            height: "26px",
                                                            fontFamily:
                                                                "monospace",
                                                            textAlign: "left",
                                                            flex: 1,
                                                            margin: 3,
                                                            ...inputStyle(
                                                                index
                                                            ),
                                                            ...(this.props
                                                                .asWizard &&
                                                            !getConditionValue(
                                                                condition
                                                            )
                                                                ? {
                                                                      backgroundColor:
                                                                          "transparent",
                                                                  }
                                                                : undefined),
                                                        }}
                                                        placeholder=""
                                                        onKeyDown={(e) => {
                                                            e.stopPropagation();
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                const value: string =
                                                                    e
                                                                        .currentTarget
                                                                        .value;
                                                                this.changeConditions(
                                                                    (
                                                                        conditions
                                                                    ) => {
                                                                        let newConditions = Array.from(
                                                                            conditions
                                                                        );
                                                                        newConditions[
                                                                            index
                                                                        ] = {
                                                                            ...newConditions[
                                                                                index
                                                                            ],
                                                                            value: {
                                                                                label: value,
                                                                                value: value,
                                                                            },
                                                                        };
                                                                        return newConditions;
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const value: string =
                                                                e.target.value;
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        value: {
                                                                            label: value,
                                                                            value: value,
                                                                        },
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        defaultValue={
                                                            getConditionValue(
                                                                condition
                                                            ) ?? ""
                                                        }
                                                    />
                                                ) : (
                                                    <Select
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        isMulti={isMultiCondition(
                                                            condition
                                                        )}
                                                        isClearable={true}
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "6": ref,
                                                            };
                                                        }}
                                                        filterOption={createFilter(
                                                            {
                                                                ignoreAccents: false,
                                                            }
                                                        )}
                                                        placeholder={""}
                                                        styles={
                                                            {
                                                                ...customFilterStyles,
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    height:
                                                                        "38px",
                                                                }),
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                    minWidth:
                                                                        11 * 2 + // 11 - fontSize, 2 - values length, 60 - extra padding
                                                                        60,
                                                                }),
                                                            } as StylesConfig<
                                                                | NodeLinkOption
                                                                | SearchComponentOption,
                                                                boolean,
                                                                GroupBase<
                                                                    | NodeLinkOption
                                                                    | SearchComponentOption
                                                                >
                                                            >
                                                        }
                                                        options={
                                                            this.props
                                                                .nodeLinkOptions
                                                        }
                                                        value={condition.value}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    if (
                                                                        isMultiCondition(
                                                                            condition
                                                                        ) &&
                                                                        Array.isArray(
                                                                            newValue
                                                                        ) &&
                                                                        newValue.length ===
                                                                            0
                                                                    ) {
                                                                        newValue = null;
                                                                    }
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        value: newValue as
                                                                            | NodeLinkOption
                                                                            | NodeLinkOption[]
                                                                            | null,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: "white",
                                                                primary25:
                                                                    "var(--selectors-background-hover-color)",
                                                            },
                                                        })}
                                                    />
                                                )}
                                            </FocusIterable>
                                        )}
                                    </div>
                                    {!this.props.single && (
                                        <div
                                            style={{ width: 50, marginLeft: 1 }}
                                        >
                                            {(!this.props.asWizard ||
                                                condition.variable != null) && (
                                                <FocusIterable
                                                    onNext={() => {
                                                        this.selectNextFocusItem(
                                                            index,
                                                            "7"
                                                        );
                                                    }}
                                                    onPrevious={() => {
                                                        this.selectPreviousFocusItem(
                                                            index,
                                                            "7"
                                                        );
                                                    }}
                                                >
                                                    <Select
                                                        isClearable={true}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "7": ref,
                                                            };
                                                        }}
                                                        filterOption={createFilter(
                                                            {
                                                                ignoreAccents: false,
                                                            }
                                                        )}
                                                        placeholder={"Bracket"}
                                                        styles={
                                                            {
                                                                ...customFilterStyles,
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    height:
                                                                        "38px",
                                                                }),
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                ...auxiliarySelectorsStyle(
                                                                    index
                                                                ),
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                }),
                                                            } as StylesConfig<
                                                                NullableOption,
                                                                false,
                                                                GroupBase<
                                                                    NullableOption
                                                                >
                                                            >
                                                        }
                                                        options={rightBrackets}
                                                        value={
                                                            condition.rightBracket
                                                        }
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        rightBracket:
                                                                            newValue?.value !=
                                                                            null
                                                                                ? (newValue as StringOption)
                                                                                : null,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: "white",
                                                                primary25:
                                                                    "var(--selectors-background-hover-color)",
                                                            },
                                                        })}
                                                    />
                                                </FocusIterable>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            {!this.props.single && (
                                <div
                                    className="flex-simple-column"
                                    style={{ marginLeft: 5, marginTop: 3 }}
                                >
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            backgroundColor: mainStyle.getPropertyValue(
                                                "--filters-background-color"
                                            ),
                                            color: mainStyle.getPropertyValue(
                                                "--filters-text-color"
                                            ),
                                            width: "19px",
                                            height: "19px",
                                            ...buttonsStyle(index),
                                        }}
                                        onClick={() => {
                                            this.addCondition(index);
                                        }}
                                    >
                                        {"\uFF0B" /* plus */}
                                    </Button>
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            backgroundColor: mainStyle.getPropertyValue(
                                                "--filters-background-color"
                                            ),
                                            color: mainStyle.getPropertyValue(
                                                "--filters-text-color"
                                            ),
                                            width: "19px",
                                            height: "19px",
                                            ...buttonsStyle(index),
                                        }}
                                        onClick={() => {
                                            this.deleteCondition(index);
                                        }}
                                    >
                                        {"\uFF0D" /* minus */}
                                    </Button>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export { conditionsToString, conditionsToJson, ConditionsSelector };
