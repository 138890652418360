import Variables from "common/Variables";
import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import { ScatterChartType } from "common/ScatterChartType";

class Api {
    static getFinding(props) {
        let { data, config, variables, units, type, label, hoverVariables } =
            props;
        let item = {
            type: "15",
            content: {
                variables: variables,
                units: units,
                type: type,
                data: data,
                label: label,
                hoverVariables: hoverVariables,
            },
            config: config,
        };
        return item;
    }
    static getData(config, findingOptions, moduleId) {
        let {
            dataScope,
            selectedTargetVariables,
            selectedTargetVariablesIndices,
            selectedHoverVariables,
            selectedHoverVariablesIndices,
            conditions,
            selectedTable,
            count,
            additionalParameter,
            additionalParameterIndex,
            leftAdditionalOperator,
            rightAdditionalOperator,
            leftAdditionalValue,
            rightAdditionalValue,
            selectedLabel,
        } = config;

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let isScatter = selectedTargetVariablesIndices.length > 1;
        let variableIndices = selectedTargetVariablesIndices;
        if (selectedLabel != null && isScatter) {
            variableIndices = variableIndices.concat(selectedLabel.value);
        }
        let hoverVariables = [];
        if (isScatter) {
            if (
                selectedHoverVariables != null &&
                selectedHoverVariablesIndices != null
            ) {
                for (let i = 0; i < selectedHoverVariablesIndices.length; ++i) {
                    let hoverIndex = selectedHoverVariablesIndices[i];
                    if (!variableIndices.includes(hoverIndex)) {
                        variableIndices.push(hoverIndex);
                    }
                }
                hoverVariables = selectedHoverVariables.filter(
                    (item) =>
                        !selectedTargetVariables.includes(item) &&
                        item !== selectedLabel?.label
                );
            }
        }

        let requestJson = {
            variable_indices: variableIndices,
            table: selectedTable.value,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            condition_id: selectedTable.condition_id,
            conditions: conditions,
            count: count || 100,
            module_id: moduleId,
        };
        if (
            additionalParameter &&
            (leftAdditionalValue || rightAdditionalValue)
        ) {
            requestJson.where = {
                group_index: additionalParameterIndex,
                expressions: [],
            };
            if (leftAdditionalValue) {
                requestJson.where.expressions.push({
                    operation: leftAdditionalOperator?.value ?? "=",
                    value: leftAdditionalValue.value,
                });
            }
            if (rightAdditionalValue) {
                requestJson.where.expressions.push({
                    operation: rightAdditionalOperator?.value ?? "=",
                    value: rightAdditionalValue.value,
                });
            }
        }
        return axios
            .post("/api/e/scatterplot_exploration", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = response.data.data;
                    let type =
                        selectedTargetVariables.length === 1
                            ? ScatterChartType.Histogram
                            : selectedTargetVariables.length === 2
                            ? ScatterChartType.TwoD
                            : ScatterChartType.ThreeD;
                    let finalItem = Api.getFinding({
                        type: type,
                        data: [data],
                        config: config,
                        label:
                            selectedLabel != null ? selectedLabel.label : null,
                        variables: selectedTargetVariables,
                        hoverVariables: hoverVariables,
                        units: selectedTargetVariables.map((variable) =>
                            Variables(
                                requestJson.data_table_idx,
                                moduleId
                            ).getUnits(variable)
                        ),
                    });
                    return Promise.resolve(finalItem);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
