import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { mainStyle } from "common/MainStyle";
import { ConditionsSelector, conditionsToString } from "common/Conditions";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import ErrorBoundary from "common/ErrorBoundary";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import FunnelPlot from "common/graphics/FunnelPlot";

const Step = Object.freeze({
	initial: 0,
	selectDataScope: 1,
	selectConditions: 2,
	selectTarget: 3,
	selectLabel: 4,
	selectCount: 5,
	final: 6,
});

function StepFinal(props) {
	let {
		onBack,
		onShowRecipesPopup,
		onSaveFinding,
		onNewFinding,
		finding,
		loading,
	} = props;
	return (
		<>
			<div
				className="my-row"
				style={{
					justifyContent: "space-between",
					marginTop: 10,
					height: "100%",
					width: "100%",
				}}
			>
				<div
					onClick={() => {
						onBack();
					}}
					style={{
						minWidth: 100,
						height: "100%",
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						justifyContent: "center",
					}}
				>
					<img
						alt=""
						src="/dist/img/data-exploration/chevron_back.png"
					/>
				</div>
				{loading ? (
					<span className="exploration-big-title-span">
						{"LOADING"}
					</span>
				) : (
					finding && (
						<div
							className="flex-simple-column"
							style={{
								width: "calc(100% - 200px)",
								height: "100%",
							}}
						>
							<div
								style={{
									alignSelf: "center",
									width: "100%",
									height: "330px",
								}}
							>
								<ErrorBoundary>
									<FunnelPlot
										data={finding.content.data}
										config={finding.config}
										editable
										onConfigChange={(config) => {
											let newFinging = {
												...finding,
												config: config,
											};
											onNewFinding(newFinging);
										}}
									/>
								</ErrorBoundary>
							</div>

							<FinalStepBottomBar
								onShowRecipesPopup={onShowRecipesPopup}
								onSaveFinding={onSaveFinding}
								topMargin={20}
							/>
						</div>
					)
				)}
				<div
					style={{
						minWidth: 100,
						height: "100%",
					}}
				/>
			</div>
		</>
	);
}

class MainComponent extends BaseJourney {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			previousStep: 0,
			step: 0,
			animationDirection: "top",
			targetVariables: [],
			selectedVariable: null,
			labelVariable: null,
			count: 100,
			finding: undefined,
			step2VariablesMap: {
				[Step.selectTarget]: ["selectedVariable", "targetVariables"],
				[Step.selectVariable]: ["labelVariable", "labelVariables"],
			},
			conditions: [
				{
					variable: null,
					operation: null,
					value: null,
					logical: null,
					leftBracket: null,
					rightBracket: null,
				},
			],
		};
		this.getData = this.getData.bind(this);
		this.initializeVariables = this.initializeVariables.bind(this);
		this.switchVariables = this.switchVariables.bind(this);
	}

	clearOptionalArgs() {
		this.setState({
			selectedVariable: null,
			labelVariable: null,
			selectedVariableIndex: null,
			labelVariableIndex: null,
			selectedTable: this.defaultTable(),
			conditions: ConditionsSelector.defaultValue,
			dynamicOptions: {},
			title: null,
			labelsColor: null,
			funnelColor: null,
			valuesColor: null,
			titleColor: null,
			labelsFontSize: null,
			valuesFontSize: null,
		});
	}

	getConfig() {
		let config = {
			version: configVersion,
			dataScope: this.state.dataScope,
			conditions: this.state.conditions,
			count: this.state.count || 100,
			selectedVariable: this.state.selectedVariable,
			labelVariable: this.state.labelVariable,
			targetVariables: this.state.targetVariables,
			selectedVariableIndex: this.getVariables().variableToIndex(
				this.state.selectedVariable
			),
			labelVariableIndex: this.getVariables().variableToIndex(
				this.state.labelVariable
			),
			journeyName: this.props.journeyName,
			title: this.state.title ?? null,
			labelsColor: this.state.labelsColor ?? null,
			funnelColor: this.state.funnelColor ?? null,
			valuesColor: this.state.valuesColor ?? null,
			titleColor: this.state.titleColor ?? null,
			labelsFontSize: this.state.labelsFontSize ?? null,
			valuesFontSize: this.state.valuesFontSize ?? null,
		};
		if (this.props.addToCanvas) {
			config.dynamicOptions = this.prepareDynamicOptions(
				this.state.dynamicOptions,
				config
			);
		}
		return config;
	}

	getData() {
		this.setState({ loading: true });
		Api.getData(this.getConfig(), undefined, this.props.currentModuleId)
			.then((finding) => {
				this.setState({ finding: finding, loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}

	buildContent() {
		switch (this.state.step) {
			case Step.initial:
				return this.buildInitial(
					"How do you want to examine your variables?"
				);
			case Step.selectDataScope:
				return this.buildDataScopesSelector();
			case Step.selectConditions:
				return this.buildConditionsSelector();
			case Step.selectTarget:
				return this.buildVariablesSelector(
					"I want to examine",
					"selectedVariable",
					"targetVariables",
					false,
					DynamicOptionType.variable,
					"selectedVariableIndex",
					true
				);
			case Step.selectLabel:
				return this.buildVariablesSelector(
					"by",
					"labelVariable",
					"labelVariables",
					false,
					DynamicOptionType.variable,
					"labelVariableIndex",
					true
				);
			case Step.selectCount:
				return this.buildInput(
					"Display",
					"largest values",
					"count",
					true
				);

			case Step.final:
				return (
					<StepFinal
						onShowRecipesPopup={this.showRecipesPopup}
						loading={this.state.loading}
						finding={this.state.finding}
						onSaveFinding={this.saveFinding}
						onNewFinding={this.onNewFinding}
						onBack={this.back}
					/>
				);

			default:
				return <div />;
		}
	}

	buildLeftBar() {
		return super.buildLeftBar(this.state.step < Step.final - 1);
	}
	initializeVariables() {
		let scopeVariables = this.getVariables();
		let targetVariables = scopeVariables.numericVariables.map(
			(item) => item.name
		);
		let labelVariables = scopeVariables.dataVariables.map(
			(item) => item.name
		);
		this.setState({
			targetVariables: targetVariables,
			labelVariables: labelVariables,
		});
	}
	switchVariables() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
		this.initializeVariables();
		this.initializeVariablesReaction = reaction(
			() => this.getVariables().dataVariables,
			() => {
				this.initializeVariables();
			}
		);
	}

	componentDidMount() {
		this.getLastJourneyConfig();
	}

	componentWillUnmount() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
	}

	showFinalStep() {
		if (
			this.state.dataScope &&
			this.state.selectedVariable &&
			this.state.labelVariable &&
			this.state.step > Step.initial &&
			this.state.step < Step.final
		)
			this.setState({ animationDirection: "top" }, () => {
				this.setState(
					(state) => ({
						previousStep: state.step,
						step: Step.final,
					}),
					() => {
						this.getData();
						this.saveNewJourneyConfig();
					}
				);
			});
	}

	selectVariable(variableName, variablesName, index, maxCount = undefined) {
		let stateDiff = {};
		let multiselection = Array.isArray(this.state[variableName]);
		if (!multiselection) {
			stateDiff = {
				[variableName]: this.state[variablesName][index],
			};
		} else {
			let selectedVariables = Array.from(this.state[variableName]);
			let newVariable = this.state[variablesName][index];
			if (selectedVariables.includes(newVariable)) {
				selectedVariables = selectedVariables.filter(
					(item) => item !== newVariable
				);
			} else {
				selectedVariables.push(newVariable);
			}
			let allVariables = Array.from(this.state[variablesName]);
			selectedVariables = selectedVariables.filter((variable) =>
				allVariables.includes(variable)
			);
			if (maxCount != null) {
				if (selectedVariables.length > maxCount) {
					selectedVariables = selectedVariables.slice(
						selectedVariables.length - maxCount
					);
				}
			}
			stateDiff = {
				[variableName]: selectedVariables,
			};
		}

		this.setState(stateDiff, () => {
			if (this.state.step === Step.selectTarget)
				setTimeout(() => {
					this.stepDown();
				}, 100);
		});
	}

	getSubtitle() {
		let conditionsString = conditionsToString(
			Array.from(this.state.conditions)
		);
		if (
			this.state.step === Step.selectTable ||
			this.state.step === Step.selectDataScope
		)
			return "How do you want to examine your variables?";
		if (
			this.state.step > Step.selectTable &&
			this.state.step < Step.final
		) {
			let subtitle = `For the data in ${
				this.state.dataScope ? this.state.dataScope.label : ""
			}`;
			if (this.state.step > Step.selectConditions) {
				if (conditionsString.length > 0)
					subtitle += ` and under the conditions ${conditionsString}`;
			}
			if (this.state.step > Step.selectTarget)
				subtitle += ` I want to examine ${this.state.selectedVariable},`;
			if (this.state.step > Step.selectLabel)
				subtitle += ` by ${this.state.labelVariable || ""}`;

			return subtitle;
		}

		if (this.state.step === Step.final) {
			return (
				<>
					<span>For the data </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.dataScope
							? this.state.dataScope.label.concat(" ")
							: ""}
					</span>
					{conditionsString.length > 0 && (
						<>
							<span> and under the conditions </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{conditionsString}
							</span>
						</>
					)}
					<span> I want to examine </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedVariable}
					</span>
					<span> by </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.labelVariable}
					</span>

					<span>.</span>
				</>
			);
		}
		return "";
	}
	getFooterTitle() {
		switch (this.state.step) {
			case Step.initial:
				return "... For the data in...";
			case Step.selectTable:
				return "... and under the conditions...";
			case Step.selectConditions:
				return "... I want to examine ...";
			case Step.selectTarget:
				return "... by ...";
			case Step.selectLabel:
				return "... display ...";
			default:
				return "";
		}
	}
	stepDown() {
		if (this.state.step === Step.selectDataScope && !this.state.dataScope) {
			return;
		}
		let delta = 1;
		if (this.state.step + delta < Step.final) {
			this.setState((state) => ({
				previousStep: state.step,
				step: state.step + delta,
			}));
		} else {
			this.showFinalStep();
		}
	}

	stepUp() {
		let step = this.state.step;

		if (step > Step.initial)
			this.setState({ animationDirection: "bottom" }, () => {
				this.setState({
					step: step - 1,
				});
			});
	}
	render() {
		return (
			<div
				className="dashboard-rect-journey-focus"
				tabIndex="0"
				style={{
					height: 520,
					overflow: "hidden",
					display: !this.props.collapsed ? "block" : "none",
				}}
				onClick={() => {
					if (this.state.step === Step.initial) this.stepDown();
				}}
				onKeyDown={(evt) => {
					if (evt.key === "Escape") {
						this.props.onClose();
					}
					if (evt.key === "ArrowDown") {
						if (
							this.state.step > Step.initial &&
							this.state.step < Step.final
						)
							this.stepDown();
						evt.preventDefault();
					}
					if (evt.key === "ArrowLeft") {
						if (this.state.step === Step.final) {
							this.back();
						}
					}
					if (evt.key === "ArrowRight") {
						this.showFinalStep();
					}
					if (evt.key === "ArrowUp") {
						if (this.state.step < Step.final) this.stepUp();
						evt.preventDefault();
					}
					if (evt.key === "p") {
						if (this.state.step === Step.final) {
							this.saveFinding();
						}
					}
					if (evt.key === "d") {
						if (this.state.step === Step.final) {
							evt.preventDefault();
							this.showRecipesPopup();
						}
					}
					let variableInfo = this.state.step2VariablesMap[
						this.state.step
					];
					if (variableInfo) {
						let variablesLength = this.state[variableInfo[1]]
							.length;
						let variableIndex = evt.keyCode - 65;
						if (
							variableIndex >= 0 &&
							variableIndex < variablesLength
						) {
							this.selectVariable(
								variableInfo[0],
								variableInfo[1],
								variableIndex
							);
						}
					}
					if (evt.key === "Enter") {
						// if (
						// 	this.state.step === Step.selectAdditionalValue &&
						// 	Variables(this.dataScopeValue()).isMonthVariable(
						// 		this.state.additionalParameter
						// 	) &&
						// 	this.state.leftAdditionalValue &&
						// 	!this.state.leftAdditionalValueSelected
						// ) {
						// 	this.setState({
						// 		leftAdditionalValueSelected: true,
						// 	});
						// 	return;
						// }
						// if (
						// 	this.state.step === Step.selectAdditionalValue &&
						// 	Variables(this.dataScopeValue()).isMonthVariable(
						// 		this.state.additionalParameter
						// 	) &&
						// 	this.state.leftAdditionalValueSelected &&
						// 	this.state.rightAdditionalValue
						// ) {
						// 	this.setState(
						// 		{
						// 			rightAdditionalValueSelected: true,
						// 		},
						// 		() => {
						// 			this.showFinalStep();
						// 		}
						// 	);
						// 	return;
						// }
					}
					// if (
					// 	this.state.step === Step.selectAdditionalValue &&
					// 	Variables(this.dataScopeValue()).isMonthVariable(
					// 		this.state.additionalParameter
					// 	)
					// ) {
					// 	this.selectMonth(evt.key, true);
					// }
				}}
			>
				<div
					className="my-row"
					style={{ justifyContent: "space-between", height: "100%" }}
				>
					<div
						className="flex-column"
						style={{
							height: "100%",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						{this.buildHeader()}

						<TransitionGroup
							style={{
								minHeight: "inherit",
							}}
						>
							<CSSTransition
								style={{
									height: "100%",
								}}
								key={this.state.step}
								timeout={500}
								classNames={"journeywizard-".concat(
									this.state.animationDirection || ""
								)}
							>
								<div
									style={{
										height: "100%",
									}}
								>
									{this.buildContent()}
								</div>
							</CSSTransition>
						</TransitionGroup>

						<Footer
							showArrow={
								this.state.step > Step.initial &&
								this.state.step < Step.final
							}
							title={this.getFooterTitle()}
						/>
					</div>
				</div>
				{this.buildAddToRecipesPopup()}
			</div>
		);
	}
}

export { MainComponent };