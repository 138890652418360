import Slider, { createSliderWithTooltip } from "rc-slider";
import React from "react";
import "rc-slider/assets/index.css";
import { mainStyle } from "common/MainStyle";

const TooltipSlider: any = createSliderWithTooltip(Slider);

interface Props {
	optionName?: string;
	title?: string;
	width?: number;
	finding: any;
	defaultValue?: number;
	onNewFinding: (finding: any) => void;
	min?: number;
	max?: number;
}

export default function BarSizeSlider(props: Props) {
	let optionName = props.optionName ?? "barSize";
	let width = props.width ?? 150;
	let title = props.title ?? "Change bar width:";
	return (
		<div className="my-row" style={{ alignItems: "center", height: 30 }}>
			<span
				style={{
					whiteSpace: "nowrap",
					fontFamily: "Roboto",
					fontSize: "10px",
					color: mainStyle.getPropertyValue(
						"--exploration-tertiary-text-color"
					),
					fontWeight: 300,
				}}
			>
				{title}
			</span>
			<TooltipSlider
				style={{ marginLeft: "10px", width: width }}
				min={props.min ?? 0}
				max={props.max ?? 100}
				step={1}
				onChange={(value: number) => {
					let newFinding = Object.assign({}, props.finding);
					newFinding.config[optionName] = value;

					if (props.onNewFinding) {
						props.onNewFinding(newFinding);
					}
				}}
				defaultValue={
					props.finding.config[optionName] ?? props.defaultValue ?? 10
				}
			/>
		</div>
	);
}