import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import "./styles/datatables.css";
import { CSVElement, CSVRow } from "./CSVTypes";
// import { TableHeader } from "spectacle";
import { SelectedVariable } from "modules/data_hub_page/MergeSection";
import { DataScopeOption } from "./DataScopes";
import Variables, { Variable } from "./Variables";
import { strftime } from "./utilities/TimeFormatUtils";

interface Props {
    tableName: string;
    highlightColumn?: string;
    highlightRows?: boolean[];
    highlightCells?: string[];
    tableHeader: string[];
    tableContent: CSVRow[];
    leftDataset?: DataScopeOption;
    rightDataset?: DataScopeOption;
    paging?: boolean;
    small?: boolean;
    sortable?: boolean;
    deleteColumnOption?: boolean;
    mergableVariables?: SelectedVariable[] | [];
    className?: string;
    setPreviewInfo?: (params: any) => any;
    onCellContextMenu?: (
        evt: any,
        row: number,
        column: number,
        value: any
    ) => void;
    onCellDoubleClick?: (row: number, column: number, value: any) => void;
    headerRenderer?: (item: string, index: number) => JSX.Element;
    cellRenderer?: (item: string, column: number, index: number) => JSX.Element;
    tab?: string;
}

class AdminTableWithFullFeatures extends Component<Props> {
    lastHighlitedTarget: HTMLElement | null = null;
    render() {
        const columns: {
            label: string | JSX.Element;
            field: string;
            sort?: string;
        }[] = this.props.tableHeader.map((item, index) => {
            return {
                label:
                    this.props.headerRenderer?.(item, index) ??
                    (this.props.highlightColumn != null ? (
                        item === this.props.highlightColumn ? (
                            <div
                                style={{
                                    backgroundColor: "#fff",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                {item}
                            </div>
                        ) : (
                            item
                        )
                    ) : (
                        <div style={{ display: "flex" }}>
                            {this.props.tab === "mergeSection" && (
                                <button
                                    className="removeBtn"
                                    style={{
                                        marginLeft: "10px",
                                        visibility: this.props
                                            .deleteColumnOption
                                            ? "visible"
                                            : "hidden",
                                        //
                                        marginRight: 10,
                                    }}
                                    onClick={() => {
                                        this.props.tableHeader.splice(index, 1);
                                        this.props.tableContent.map(
                                            (item, idx) => {
                                                item.splice(index, 1);
                                                return null;
                                            }
                                        );

                                        this.props.setPreviewInfo?.(
                                            (previous: any) => ({
                                                ...previous,
                                                tableHeader: this.props
                                                    .tableHeader,
                                                tableContent: this.props
                                                    .tableContent,
                                            })
                                        );
                                    }}
                                >
                                    <img
                                        src="dist/img/data-exploration/minus.png"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </button>
                            )}
                            <div
                                style={{
                                    marginRight: "10px",
                                    maxWidth: "100%",
                                }}
                            >
                                {item}
                            </div>
                        </div>
                    )),
                field: item,
            };
        });

        let leftVariablesInfo = [];
        let rightVariablesInfo = [];
        let variablesInfo: (Variable | null)[] = [];

        if (this.props.leftDataset && this.props.rightDataset) {
            leftVariablesInfo = this.props.tableHeader.map((name) =>
                Variables(this.props.leftDataset!.value).getVariableByName(name)
            );
            rightVariablesInfo = this.props.tableHeader.map((name) =>
                Variables(this.props.rightDataset!.value).getVariableByName(
                    name
                )
            );
            variablesInfo = [
                ...leftVariablesInfo.filter((info) => info),
                ...rightVariablesInfo.filter((info) => info),
            ];
        }

        const rows: {
            [key: string]: CSVElement | JSX.Element;
        }[] = this.props.tableContent.map((item, rowIndex) => {
            let obj: { [key: string]: CSVElement | JSX.Element } = {};
            item.forEach((columnValue, index) => {
                const columnKey = columns[index];

                if (columnKey != null) {
                    if (typeof columnValue === "number") {
                        obj[columnKey.field] = columnValue.toFixed(2);
                    } else if (variablesInfo[index]?.type === "datetime") {
                        const format = variablesInfo[index]?.format;
                        if (format) {
                            obj[columnKey.field] = strftime(
                                format,
                                new Date(columnValue as string)
                            );
                        } else {
                            obj[columnKey.field] = columnValue;
                        }
                    } else {
                        obj[columnKey.field] = columnValue;
                    }
                    if (this.props.cellRenderer) {
                        obj[columnKey.field] = this.props.cellRenderer(
                            obj[columnKey.field] as string,
                            index,
                            rowIndex
                        );
                    }
                    let highlightRow =
                        this.props.highlightRows == null ||
                        this.props.highlightRows[rowIndex];
                    let highlightCell =
                        highlightRow &&
                        this.props.highlightCells != null &&
                        this.props.highlightCells.includes(columnKey.field);
                    if (
                        this.props.highlightColumn != null ||
                        this.props.highlightRows != null
                    ) {
                        obj[columnKey.field] = (
                            <div
                                style={{
                                    paddingLeft: highlightCell ? 2 : 0,
                                    paddingRight: highlightCell ? 2 : 0,
                                    backgroundColor:
                                        columnKey.field ===
                                            this.props.highlightColumn ||
                                        highlightCell
                                            ? "#1F8EFA33"
                                            : "transparent",
                                    color: highlightRow ? "#000000" : "#D8D8D8",
                                    border: highlightCell
                                        ? "1px solid #1F8EFA"
                                        : "none",
                                    borderRadius: "3px",
                                    boxShadow: highlightCell
                                        ? "0px 0px 5px #1F8EFA"
                                        : "none",
                                }}
                            >
                                {obj[columnKey.field]}
                            </div>
                        );
                    }
                }
            });
            return obj;
        });

        return (
            <MDBDataTable
                className={this.props.className ?? "customized-table"}
                paging={this.props.paging ?? true}
                sortable={this.props.sortable ?? false}
                small={this.props.small ?? true}
                searching={false}
                data={{ rows: rows, columns: columns }}
                onContextMenu={(evt: any) => {
                    let columnIndex = evt.target.cellIndex;
                    let rowIndex = evt.target.parentNode.rowIndex - 2;
                    if (
                        this.props.onCellContextMenu != null &&
                        rowIndex >= 0 &&
                        columnIndex >= 0
                    ) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        this.props.onCellContextMenu(
                            evt,
                            rowIndex,
                            columnIndex,
                            evt.target.innerText
                        );
                    }
                }}
                onDoubleClick={(evt: any) => {
                    let columnIndex = evt.target.cellIndex;
                    let rowIndex = evt.target.parentNode.rowIndex - 2;
                    if (
                        this.props.onCellDoubleClick != null &&
                        rowIndex >= 0 &&
                        columnIndex >= 0
                    ) {
                        evt.target.style.border = "1px solid #39F";
                        if (this.lastHighlitedTarget != null) {
                            this.lastHighlitedTarget.style.border = "";
                        }
                        this.lastHighlitedTarget = evt.target;
                        this.props.onCellDoubleClick(
                            rowIndex,
                            columnIndex,
                            evt.target.innerText
                        );
                    }
                }}
            ></MDBDataTable>
        );
    }
}

export default AdminTableWithFullFeatures;
