import React from "react";
import { DeepgramContextProvider } from "../../context/DeepgramContextProvider"
import { MicrophoneContextProvider } from "../../context/MicrophoneContextProvider"
import VoiceComponent from "./VoiceComponent"
import { ReactComponent as MicIcon } from '../../assets/mic.svg';
import styles from './voiceInput.module.css';
import { store } from '../../stores/Store';

/* Add text to speech API */
import Text2Speech from '../../components/Text2Speech';

interface IVoiceInput {
  handleSubmit:  any;
  waitingResponse: boolean;
}

const VoiceInput: React.FC<any> = ({handleSubmit, waitingResponse}) => {

  const [enabled, setEnabled] = React.useState(false);
  const audioRef = React.useRef(null);

  React.useEffect(() => {
    handlePlay();
  }, [enabled]);

  const handlePlay = () => {
    // Play the audio when the button is clicked
    if (audioRef.current) {
    (audioRef.current as any).play();
    }
  };

  const toggleEnabled = () => {setEnabled(!enabled)};

  const renderContent = () => {
    if(waitingResponse)
      return false;

    if(enabled) {
      return(
        <div className={styles.micWrapper} onClick={toggleEnabled}>
          {enabled && (
          <Text2Speech text={store.answers[store.answers.length -1 ]}/>                             
          )}
          <MicrophoneContextProvider>
            <DeepgramContextProvider>
              <VoiceComponent handleVoiceSubmit={handleSubmit}/>
            </DeepgramContextProvider>
          </MicrophoneContextProvider>
        </div>
      )
    } else {
       return(
        <div className={styles.contentWrapper}>
          <div onClick={toggleEnabled} className={styles.micBtnWrapper}>
            <div className={styles.iconSlideUp}>
              <MicIcon/>
            </div>
          </div>
        </div>
       )
    }
  }

  return(
    <>
      <audio ref={audioRef} src={(enabled)? "/dist/mp3/mic-on-5WH4CSY7.mp3" : "/dist/mp3/mic-off-JEWTNSMK.mp3" }  />
      {renderContent()}
    </>
  )
}

export default VoiceInput