import React, { Component } from "react";
import {
    CanvasProgressElement,
    defaultErrorColors,
    getPrettyPrintFormatValue,
} from "common/Canvas";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getNodeSize } from "../../canvas_elements/getNodeSize";
import styles from "./ProgressElement.module.css";
import { defaultLabelSize } from "../../Constants";
import { mainStyle } from "common/MainStyle";
import StringUtils from "common/utilities/StringUtils";
import CanvasTreeStore from "../../CanvasTreeStore";

interface InnerProps {
    node: CanvasProgressElement;
    scale: number;
    fontColor: string;
    fontSize: number;
    canvasTreeStore: CanvasTreeStore;
}

function CircularProgressWithLabel(props: InnerProps) {
    const shapeOptions = props.node?.shapeOptions;
    let scaleX = 1;
    let scaleY = 1;
    if (shapeOptions) {
        scaleX = shapeOptions[props.canvasTreeStore.canvasViewMode].scaleX ?? 1;
        scaleY = shapeOptions[props.canvasTreeStore.canvasViewMode].scaleY ?? 1;
    }
    const minElementScale = Math.min(scaleX, scaleY);

    let min = (props.node.minOutput?.value ?? 0) as number;
    let max = (props.node.maxOutput?.value ?? 100) as number;
    let value = (props.node.value ?? min) as number;
    if (isNaN(min)) min = 0;
    if (isNaN(max)) max = 0;

    if (isNaN(value)) value = min;

    let displayValue = ((value - min) * 100) / (max - min);
    let labelSize = props.node.labelSize ?? defaultLabelSize;
    let labelColor =
        props.node.labelColor ??
        mainStyle.getPropertyValue("--secondary-text-color");
    const size = getNodeSize(props.node).nodeSize.desktop;
    let prettyPrintedMin =
        getPrettyPrintFormatValue(
            {
                ...props.node.minOutput,
                decimalPoints: props.node.decimalPoints,
            },
            null
        ) || "0.00";
    let prettyPrintedMax =
        getPrettyPrintFormatValue(
            {
                ...props.node.maxOutput,
                decimalPoints: props.node.decimalPoints,
            },
            null
        ) || "0.00";
    let prettyPrintedValue =
        getPrettyPrintFormatValue(
            { ...props.node, decimalPoints: props.node.decimalPoints },
            null
        ) || prettyPrintedMin;
    prettyPrintedMin = `${props.node.leftUnit ?? ""}${prettyPrintedMin}${
        props.node.unit ?? ""
    }`;
    prettyPrintedMax = `${props.node.leftUnit ?? ""}${prettyPrintedMax}${
        props.node.unit ?? ""
    }`;
    prettyPrintedValue = `${props.node.leftUnit ?? ""}${prettyPrintedValue}${
        props.node.unit ?? ""
    }`;
    let scaledSize = {
        width: size.width * props.scale,
        height: size.height * props.scale,
    };
    let progressSize =
        Math.min(size.height, size.width) * props.scale -
        2 * labelSize * 1.25 * props.scale;
    let sign: "positive" | "negative" | "neutral" | undefined = undefined;
    let errorColors = props.node.errorColors ?? defaultErrorColors;
    let errorBoxStyles: React.CSSProperties = {};
    if (props.node.errorOutput != null && props.node.showErrorOutput) {
        if (
            props.node.errorOutput.metric &&
            StringUtils.isNumber(
                props.node.errorOutput.value as string | number
            ) &&
            !Number.isNaN(props.node.errorOutput.value)
        ) {
            let value = Number(props.node.errorOutput.value);
            if (value > 0) sign = "positive";
            else if (value < 0) sign = "negative";
            else sign = "neutral";
            if (sign === "neutral") {
                errorBoxStyles = {
                    width: 12 * props.scale * minElementScale,
                    height: 2 * props.scale * minElementScale,
                    backgroundColor: errorColors[sign],
                };
            }
            if (sign === "positive") {
                errorBoxStyles = {
                    width: 0,
                    height: 0,
                    borderLeft: `${
                        6 * props.scale * minElementScale
                    }px solid transparent`,
                    borderRight: `${
                        6 * props.scale * minElementScale
                    }px solid transparent`,
                    borderBottom: `${
                        12 * props.scale * minElementScale
                    }px solid ${errorColors[sign]}`,
                };
            }
            if (sign === "negative") {
                errorBoxStyles = {
                    width: 0,
                    height: 0,
                    borderLeft: `${
                        6 * props.scale * minElementScale
                    }px solid transparent`,
                    borderRight: `${
                        6 * props.scale * minElementScale
                    }px solid transparent`,
                    borderTop: `${12 * props.scale * minElementScale}px solid ${
                        errorColors[sign]
                    }`,
                };
            }
        }
    }

    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-flex",
                width: scaledSize.width,
                height: scaledSize.height,
            }}
        >
            {!props.node.linear && (
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            position: "relative",
                            width: progressSize,
                            height: progressSize,
                        }}
                    >
                        <CircularProgress
                            value={100}
                            thickness={
                                (props.node.thickness ?? 2) * props.scale
                            }
                            sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                color: "#dddddd",
                            }}
                            size={progressSize}
                            variant={"determinate"}
                        />
                        <CircularProgress
                            value={displayValue}
                            thickness={
                                (props.node.thickness ?? 2) * props.scale
                            }
                            style={{ transform: "rotate(90deg)" }}
                            sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                color:
                                    props.node.fillColor ??
                                    mainStyle.getPropertyValue(
                                        "--primary-content-color"
                                    ),
                            }}
                            size={progressSize}
                            variant={"determinate"}
                        />
                    </Box>
                </Box>
            )}
            {props.node.linear && (
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            style={{
                                color: props.fontColor,
                                fontSize:
                                    props.fontSize *
                                    props.scale *
                                    minElementScale,
                            }}
                        >
                            {prettyPrintedValue}
                        </Typography>
                        {sign != null && <Box sx={errorBoxStyles}></Box>}
                    </Box>
                    <LinearProgress
                        sx={{
                            backgroundColor: "#dddddd",
                            width: "100%",
                            height: (props.node.thickness ?? 2) * props.scale,
                            "& .MuiLinearProgress-bar": {
                                backgroundColor:
                                    props.node.fillColor ??
                                    mainStyle.getPropertyValue(
                                        "--primary-content-color"
                                    ),
                            },
                        }}
                        variant="determinate"
                        value={displayValue}
                    />
                </Box>
            )}
            {!props.node.linear && (
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            style={{
                                color: props.fontColor,
                                fontSize:
                                    props.fontSize *
                                    props.scale *
                                    minElementScale,
                            }}
                        >
                            {prettyPrintedValue}
                        </Typography>
                        {sign != null && <Box sx={errorBoxStyles}></Box>}
                    </Box>
                </Box>
            )}

            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    style={{
                        color: labelColor,
                        fontSize: labelSize * props.scale * minElementScale,
                    }}
                >
                    {prettyPrintedMin}
                </Typography>
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    style={{
                        color: labelColor,
                        fontSize: labelSize * props.scale * minElementScale,
                    }}
                >
                    {props.node.subtitle}
                </Typography>
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    style={{
                        color: labelColor,
                        fontSize: labelSize * props.scale * minElementScale,
                    }}
                >
                    {prettyPrintedMax}
                </Typography>
            </Box>
        </Box>
    );
}

interface Props {
    rootDataTestId: string;
    fontColor: string;
    fontSize: number;
    node: CanvasProgressElement;
    scale: number;
    canvasTreeStore: CanvasTreeStore;
}

class HTMLProgressElement extends Component<Props> {
    public render(): JSX.Element {
        return (
            <div className={styles.root}>
                <CircularProgressWithLabel
                    fontColor={this.props.fontColor}
                    fontSize={this.props.fontSize}
                    scale={this.props.scale}
                    node={this.props.node}
                    canvasTreeStore={this.props.canvasTreeStore}
                />
            </div>
        );
    }
}

export default HTMLProgressElement;
