import axios from "common/ServerConnection";

export interface SubscriptionInfo {
    id: number;
    link_id?: string;
    shared_module_id?: number;
    creation_time: Date;
    renew_time: Date;
    payment_type: string;
    price: number;
    cancelled: boolean;
    app_title?: string | null;
    shared_module_title?: string | null;
}

export async function listSubscriptionsApi(): Promise<SubscriptionInfo[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            subscriptions?: {
                id: number;
                link_id?: string;
                shared_module_id?: number;
                creation_time: number;
                renew_time: number;
                payment_type: string;
                price: number;
                cancelled: boolean;
                app_title?: string | null;
                shared_module_title?: string | null;
            }[];
        }>("/api/get_subscriptions_for_current_user")
        .then((response) => {
            if (response.data.success && response.data.subscriptions != null) {
                return response.data.subscriptions.map((item) => ({
                    id: item.id,
                    link_id: item.link_id,
                    shared_module_id: item.shared_module_id,
                    creation_time: new Date(item.creation_time * 1000),
                    renew_time: new Date(item.renew_time * 1000),
                    payment_type: item.payment_type,
                    price: item.price,
                    cancelled: item.cancelled,
                    app_title: item.app_title,
                    shared_module_title: item.shared_module_title,
                }));
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function cancelSubscriptionApi(
    id: number,
    kit: boolean
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/subscription_cancel_at_period_end", { id: id, kit: kit })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export interface CustomerInfo {
    user_info: {
        user_name: string;
        first_name: string;
        last_name: string;
        icon_url: string;
        email: string | null;
    };
    item_info: {
        type: "app" | "kit";
        title: string;
        paywall?: {
            price: number;
            payment_type: "monthly" | "yearly";
            active: boolean;
        };
    };
    subscription_info: {
        creation_time: Date | null;
        total_revenue: number;
        ended_at: Date | null;
    };
}

interface CustomerInfoResponse {
    user_info: {
        user_name: string;
        first_name: string;
        last_name: string;
        icon_url: string;
        email: string | null;
    };
    item_info: {
        type: "app" | "kit";
        title: string;
        paywall?: {
            price: number;
            payment_type: "monthly" | "yearly";
            active: boolean;
        };
    };
    subscription_info: {
        creation_time: number | null; // timestamp
        total_revenue: number;
        ended_at: number | null; // timestamp
    };
}

function customerInfoResponseToCustomerInfo(
    item: CustomerInfoResponse
): CustomerInfo {
    return {
        ...item,
        subscription_info: {
            ...item.subscription_info,
            creation_time:
                item.subscription_info.creation_time != null
                    ? new Date(item.subscription_info.creation_time * 1000)
                    : null,
            ended_at:
                item.subscription_info.ended_at != null
                    ? new Date(item.subscription_info.ended_at * 1000)
                    : null,
        },
    };
}

export async function listCustomersApi(): Promise<CustomerInfo[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            items: CustomerInfoResponse[];
        }>("/api/get_customers_of_current_user")
        .then((response) => {
            if (response.data.success) {
                return response.data.items.map(
                    customerInfoResponseToCustomerInfo
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
