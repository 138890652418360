import React, { Component } from "react";
import sections from "sections.json";
import { LegendEditElement } from "./LegendEditElement";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { formatValue } from "common/utilities/FormatValue";

import {
    ComposedChart,
    Area,
    Line,
    ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { mainStyle } from "common/MainStyle";
import { SingleLineAxisTick } from "./AxesTicks";
import CustomizedAxisTick from "common/graphics/CustomizedAxisTick";
import { TooltipStyles } from "./TooltipStyles";

class TimeTrendPlotComponent extends Component {
    constructor(props) {
        super(props);
        this.buildTimeTrendPlot = this.buildTimeTrendPlot.bind(this);
    }
    drawLegendElement(color, legendsColor, labelMapping, defaultText) {
        return (
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    marginLeft: 50,
                    marginTop: 5,
                    marginBottom: 5,
                }}
            >
                <div
                    style={{
                        width: 4,
                        height: 2,
                        backgroundColor: color,
                    }}
                />
                <div
                    style={{
                        marginLeft: 5,
                        width: 4,
                        height: 2,
                        backgroundColor: color,
                    }}
                />
                {this.props.editable ? (
                    <input
                        defaultValue={
                            labelMapping?.[defaultText] ?? defaultText
                        }
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        placeholder=""
                        onBlur={(e) => {
                            e.preventDefault();
                            let value = e.target.value;
                            let newLabelMapping = {
                                ...labelMapping,
                                [defaultText]: value,
                            };
                            let config = {
                                ...this.props.config,
                                labelMapping: newLabelMapping,
                            };
                            this.props.onConfigChange(config);
                        }}
                        style={{
                            width: "100px",
                            alignSelf: "center",
                            backgroundColor: "transparent",
                            outline: "none",
                            color: legendsColor,
                            fontFamily: "Arial",
                            fontSize: mainStyle.getPropertyValue(
                                "--graphs-legends-size"
                            ),
                        }}
                    ></input>
                ) : (
                    <span
                        style={{
                            marginLeft: "5px",
                            color: legendsColor,
                            fontFamily: "Arial",
                            fontSize: mainStyle.getPropertyValue(
                                "--graphs-legends-size"
                            ),
                        }}
                    >
                        {labelMapping?.[defaultText] ?? defaultText}
                    </span>
                )}
            </div>
        );
    }

    buildOptionsToolbar(
        axesColor,
        legendsColor,
        variableColor,
        trend1Color,
        trend2Color,
        referenceLineColor
    ) {
        return (
            <div className="my-row" style={{ justifyContent: "center" }}>
                <LegendEditElement
                    allowColorChange
                    disallowTextChange
                    onFinishColor={(color) => {
                        let config = {
                            ...this.props.config,
                            legendsColor: color,
                        };
                        this.props.onConfigChange(config);
                    }}
                    color={legendsColor}
                    text={"Legends Color"}
                />

                <LegendEditElement
                    allowColorChange
                    disallowTextChange
                    onFinishColor={(color) => {
                        let config = {
                            ...this.props.config,
                            axesColor: color,
                        };
                        this.props.onConfigChange(config);
                    }}
                    color={axesColor}
                    text={"Axes Color"}
                />
                <LegendEditElement
                    allowColorChange
                    disallowTextChange
                    onFinishColor={(color) => {
                        let config = {
                            ...this.props.config,
                            variableColor: color,
                        };
                        this.props.onConfigChange(config);
                    }}
                    color={variableColor}
                    text={"Variable Color"}
                />
                <LegendEditElement
                    allowColorChange
                    disallowTextChange
                    onFinishColor={(color) => {
                        let config = {
                            ...this.props.config,
                            trend1Color: color,
                        };
                        this.props.onConfigChange(config);
                    }}
                    color={trend1Color}
                    text={"Trend 1 Color"}
                />
                <LegendEditElement
                    allowColorChange
                    disallowTextChange
                    onFinishColor={(color) => {
                        let config = {
                            ...this.props.config,
                            trend2Color: color,
                        };
                        this.props.onConfigChange(config);
                    }}
                    color={trend2Color}
                    text={"Trend 2 Color"}
                />
                <LegendEditElement
                    allowColorChange
                    disallowTextChange
                    onFinishColor={(color) => {
                        let config = {
                            ...this.props.config,
                            referenceLineColor: color,
                        };
                        this.props.onConfigChange(config);
                    }}
                    color={referenceLineColor}
                    text={"Divider Color"}
                />
            </div>
        );
    }

    buildTimeTrendPlot() {
        const bgDarkColor = mainStyle.getPropertyValue("--graphs-stroke-color");

        let {
            labelMapping,
            nameMapping,
            axesColor,
            linesCount,
            legendsColor,
            trend1Color,
            trend2Color,
            referenceLineColor,
            variableColor,
            maxYRange,
            minYRange,
        } = this.props.config ?? {};

        trend1Color = trend1Color ?? "#EE4344"; // Red
        trend2Color = trend2Color ?? "#ECA059"; // Yellow
        referenceLineColor = referenceLineColor ?? "#0D7EDA"; // Blue
        legendsColor =
            legendsColor ??
            mainStyle.getPropertyValue("--graphs-legends-text-color");
        axesColor =
            axesColor ?? mainStyle.getPropertyValue("--graphs-axes-text-color");
        variableColor = variableColor ?? "#00C687"; // Green
        let yAxisName =
            nameMapping?.[this.props.content.variable] ??
            this.props.content.variable;
        let lines = null; // Plot lines
        let noDataText = null; // Will be not null if no data selected

        let allData = this.props.content.data;
        if (allData.length >= 1 && !("plot" in allData[0])) {
            allData = [{ plot: allData }];
        }

        let plots = [];
        for (let [index, data] of allData.entries()) {
            let additionalVariable =
                data.where?.group_name ?? this.props.content.additionalVariable;
            let additionalOperator =
                data.where?.operation ?? this.props.content.additionalOperator;
            let additionalValue =
                data.where?.value ?? this.props.content.additionalValue;
            if (data.plot.length === 0) {
                noDataText = (
                    <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="white"
                        fontSize="2rem"
                    >
                        NO DATA FOR SELECTED FILTERS
                    </text>
                );
            }
            // If data is not empty, then add lines to the plot
            else {
                lines = [
                    <XAxis
                        key={0}
                        tickLine={false}
                        axisLine={false}
                        dataKey="label"
                        tick={
                            <SingleLineAxisTick
                                axesColor={axesColor}
                                dx={16}
                                dy={8}
                                angle={-15}
                            />
                        }
                    />,
                    <YAxis
                        domain={[
                            minYRange ?? "dataMin",
                            maxYRange ?? "dataMax",
                        ]}
                        interval={0}
                        tickCount={linesCount ?? undefined}
                        allowDataOverflow={
                            minYRange != null || maxYRange != null
                        }
                        key={1}
                        tick={
                            <CustomizedAxisTick
                                axesColor={axesColor}
                                formatValues={true}
                                truncValues={false}
                                fontSize={mainStyle.getPropertyValue(
                                    "--graphs-axes-size"
                                )}
                                dx={0}
                                dy={0}
                                angle={0}
                            />
                        }
                        tickLine={false}
                        axisLine={false}
                    />,
                ];

                // If there is only 1 row, then show dots instead of areas
                let dot = (color) => false;
                if (data.plot.length === 1) {
                    dot = (color) => ({
                        fill: color,
                        strokeWidth: 0,
                        r: 5,
                    });
                }

                lines.push(
                    <Area
                        isAnimationActive={false}
                        key={2}
                        type="monotone"
                        dataKey="value"
                        dot={dot(variableColor)}
                        stroke={variableColor}
                        strokeWidth={3}
                        fillOpacity={1}
                        fill={`url(#color_variable)`}
                    />
                );

                lines.push(
                    <Line
                        key={3}
                        isAnimationActive={false}
                        dataKey="trend1"
                        dot={false}
                        stroke={trend1Color}
                        strokeWidth="2"
                        strokeDasharray="5 5"
                    />
                );

                lines.push(
                    <Line
                        key={4}
                        isAnimationActive={false}
                        dataKey="trend2"
                        dot={false}
                        stroke={trend2Color}
                        strokeWidth="2"
                        strokeDasharray="5 5"
                    />
                );

                if (this.props.content.referenceLineIndex >= 0) {
                    lines.push(
                        <ReferenceLine
                            key={5}
                            x={
                                data.plot[this.props.content.referenceLineIndex]
                                    .label
                            }
                            stroke={referenceLineColor}
                            strokeWidth="2"
                            strokeDasharray="5 5"
                        />
                    );
                }
            }
            let plot = (
                <div
                    className="flex-column"
                    style={{
                        alignItems: "center",
                        height: "100%",
                        width: allData.length === 2 ? "50%" : undefined,
                    }}
                >
                    {this.props.content.scale &&
                        this.drawLegendElement(
                            trend1Color,
                            legendsColor,
                            labelMapping,
                            `${this.props.content.variable} Trend - Last ${
                                this.props.content.scale || ""
                            }${
                                additionalVariable && additionalValue
                                    ? ` for ${additionalVariable} ${
                                          additionalOperator ?? "="
                                      } ${additionalValue}`
                                    : ""
                            }`
                        )}
                    {this.props.content.scale &&
                        this.drawLegendElement(
                            trend2Color,
                            legendsColor,
                            labelMapping,
                            `${this.props.content.variable} Trend - Historical${
                                additionalVariable && additionalValue
                                    ? ` for ${additionalVariable} ${
                                          additionalOperator ?? "="
                                      } ${additionalValue}`
                                    : ""
                            }`
                        )}
                    {this.props.content.timeSplit &&
                        this.drawLegendElement(
                            trend1Color,
                            legendsColor,
                            labelMapping,
                            `${this.props.content.variable} Trend - After ${
                                this.props.content.timeSplit
                            }${
                                additionalVariable && additionalValue
                                    ? ` for ${additionalVariable} ${
                                          additionalOperator ?? "="
                                      } ${additionalValue}`
                                    : ""
                            }`
                        )}
                    {this.props.content.timeSplit &&
                        this.drawLegendElement(
                            trend2Color,
                            legendsColor,
                            labelMapping,
                            `${this.props.content.variable} Trend - Before ${
                                this.props.content.timeSplit
                            }${
                                additionalValue
                                    ? ` for ${additionalVariable} ${
                                          additionalOperator ?? "="
                                      } ${additionalValue}`
                                    : ""
                            }`
                        )}

                    <div
                        className="my-row center-container"
                        style={{ width: "100%", height: "100%" }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            {index === 0 &&
                                (this.props.editable ? (
                                    <input
                                        defaultValue={yAxisName}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        placeholder="Enter Y-Axis Name"
                                        onBlur={(e) => {
                                            e.preventDefault();
                                            let label = e.target.value;
                                            let newNameMapping = {
                                                ...nameMapping,
                                                [this.props.content
                                                    .variable]: label,
                                            };
                                            let newConfig = {
                                                ...this.props.config,
                                                nameMapping: newNameMapping,
                                            };
                                            this.props.onConfigChange(
                                                newConfig
                                            );
                                        }}
                                        style={{
                                            width: "110px",
                                            alignSelf: "center",
                                            backgroundColor: "transparent",
                                            outline: "none",
                                            color: axesColor,
                                            fontFamily: "Arial",
                                            fontSize: mainStyle.getPropertyValue(
                                                "--graphs-axes-size"
                                            ),
                                        }}
                                    ></input>
                                ) : yAxisName != null ? (
                                    <span
                                        style={{
                                            alignSelf: "center",
                                            textAlign: "right",
                                            color: axesColor,
                                            fontFamily: "Arial",
                                            fontSize: mainStyle.getPropertyValue(
                                                "--graphs-axes-size"
                                            ),
                                        }}
                                    >
                                        {yAxisName}
                                    </span>
                                ) : null)}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <ResponsiveContainer height="100%">
                                <ComposedChart
                                    margin={{ top: 10 }}
                                    data={data.plot}
                                >
                                    {sections._Global.watermark != null && (
                                        <text
                                            x="50%"
                                            y="30%"
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            fill={mainStyle.getPropertyValue(
                                                "--content-secondary-text-color"
                                            )}
                                            fontSize={"4rem"}
                                            style={{
                                                opacity: 0.1,
                                            }}
                                        >
                                            {sections._Global.watermark}
                                        </text>
                                    )}
                                    <defs>
                                        <linearGradient
                                            id="color_variable"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="12%"
                                                stopColor={variableColor}
                                                stopOpacity={0.1}
                                            />
                                            <stop
                                                offset="25%"
                                                stopColor={variableColor}
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid
                                        strokeDasharray="3 3"
                                        stroke={bgDarkColor}
                                        strokeWidth="2"
                                    />
                                    <RechartsTooltip
                                        formatter={(value, name, props) => {
                                            let formattedValue = formatValue(
                                                value,
                                                false
                                            );
                                            return formattedValue[0].concat(
                                                formattedValue[1]
                                            );
                                        }}
                                        {...TooltipStyles()}
                                    />
                                    {noDataText}
                                    {lines}
                                </ComposedChart>
                            </ResponsiveContainer>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <span
                                    className="no-selection"
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        ),
                                        fontFamily: "Arial",
                                        fontSize: mainStyle.getPropertyValue(
                                            "--graphs-axes-size"
                                        ),
                                    }}
                                >
                                    {this.props.content.scale}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
            plots.push(plot);
        }
        if (plots.length === 1) {
            return plots[0];
        } else {
            return (
                <div
                    className="flex-simple-column"
                    style={{ height: "100%", width: "100%" }}
                >
                    {this.props.editable &&
                        this.buildOptionsToolbar(
                            axesColor,
                            legendsColor,
                            variableColor,
                            trend1Color,
                            trend2Color,
                            referenceLineColor
                        )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            height: "100%",
                        }}
                    >
                        {plots}
                    </div>
                </div>
            );
        }
    }
    render() {
        return this.buildTimeTrendPlot();
    }
}

export default TimeTrendPlotComponent;