import "common/styles/App.css";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reaction } from "mobx";
import { Api } from "./Api";
import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import BaseJourney from "../../common/BaseJourney";
import DynamicOptionType from "common/DynamicOptionType";
import LeversBenchmarkingChart from "common/graphics/LeversBenchmarkingChart";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import BarSizeSlider from "../../common/BarSizeSlider";
import RangeSelector from "common/graphics/RangeSelector";

const Step = Object.freeze({
	initial: 0,
	selectDataScope: 1,
	selectTable: 2,
	selectConditions: 3,
	selectAggregate: 4,
	selectTarget: 5,
	selectCompare: 6,
	selectAdditionalParameter: 7,
	selectAdditionalValue: 8,
	final: 9,
});

function StepFinal(props) {
	let {
		onSaveFinding,
		onNewFinding,
		onShowRecipesPopup,
		finding,
		onBack,
		loading,
	} = props;
	return (
		<>
			<div
				className="my-row"
				style={{
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
				}}
			>
				<div
					onClick={() => {
						onBack();
					}}
					style={{
						width: 80,
						height: "100%",
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						justifyContent: "center",
					}}
				>
					<img
						alt=""
						src="/dist/img/data-exploration/chevron_back.png"
					/>
				</div>
				{loading ? (
					<span className="exploration-big-title-span">
						{"LOADING"}
					</span>
				) : (
					finding && (
						<div
							className="flex-simple-column"
							style={{
								width: "calc(100% - 180px)",
								height: "100%",
							}}
						>
							<div
								style={{
									alignSelf: "center",
									width: "100%",
									height: "340px",
								}}
							>
								<ErrorBoundary>
									<div
										className="my-row"
										style={{
											alignItems: "center",
											paddingBottom: "10px",
										}}
									>
										<BarSizeSlider
											onNewFinding={onNewFinding}
											finding={finding}
										/>
										<RangeSelector
											value={finding}
											onChange={onNewFinding}
										/>
									</div>
									<LeversBenchmarkingChart
										editable
										onConfigChange={(config) => {
											let newFinding = {
												...finding,
												config: config,
											};
											onNewFinding(newFinding);
										}}
										config={finding.config}
										groupValue={finding.content.groupValue}
										compareValue={
											finding.content.compareValue
										}
										aggregateVariable={
											finding.content.aggregateVariable
										}
										dataComparison={
											finding.content.dataComparison
										}
										groupOperation={
											finding.content.groupOperation
										}
										compareOperation={
											finding.content.compareOperation
										}
									/>
								</ErrorBoundary>
							</div>

							<FinalStepBottomBar
								onShowRecipesPopup={onShowRecipesPopup}
								onSaveFinding={onSaveFinding}
								topMargin={60}
							/>
						</div>
					)
				)}

				<div
					style={{
						minWidth: 100,
						height: "100%",
					}}
				/>
			</div>
		</>
	);
}

class MainComponent extends BaseJourney {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			step: 0,
			finding: undefined,
			aggregateVariable: undefined,
			animationDirection: "top",
			loading: false,
			showRecipesPopup: false,
			aggregateVariables: [],
			targetVariable: undefined,
			targetVariables: [],
			compareVariable: undefined,
			groupOperation: "max",
			compareVariables: ["best", "worst", "average"],
			selectedTable: undefined,
			step2VariablesMap: {
				[Step.selectAggregate]: [
					"aggregateVariable",
					"aggregateVariables",
				],
				[Step.selectTarget]: ["targetVariable", "targetVariables"],
				[Step.selectCompare]: ["compareVariable", "compareVariables"],
				[Step.selectAdditionalParameter]: [
					"additionalParameter",
					"additionalParameters",
				],
			},
			conditions: [
				{
					variable: null,
					operation: null,
					value: null,
					logical: null,
					leftBracket: null,
					rightBracket: null,
				},
			],
			additionalParameter: undefined,
			leftAdditionalValue: undefined,
		};
		this.initializeVariables = this.initializeVariables.bind(this);
		this.switchVariables = this.switchVariables.bind(this);
	}
	clearOptionalArgs() {
		this.setState({
			aggregateVariable: null,
			targetVariable: null,
			targetVariableIndex: null,
			aggregateVariableIndex: null,
			additionalParameter: null,
			additionalParameterIndex: null,
			leftAdditionalOperator: null,
			leftAdditionalValue: null,
			selectedTable: this.defaultTable(),
			conditions: ConditionsSelector.defaultValue,
			dynamicOptions: {},
			maxYRange: null,
			minYRange: null,
			linesCount: null,
			barSize: null,
			labelMapping: null,
			colorMapping: null,
			legendsColor: null,
			axesColor: null,
		});
	}
	getConfig() {
		let config = {
			version: configVersion,
			variableIndices:
				this.getVariables().numericVariables.map(
					(item) => item.index
				) ?? null,
			variables:
				this.getVariables().numericVariables.map((item) => item.name) ??
				null,
			dataScope: this.state.dataScope ?? null,
			aggregateVariable: this.state.aggregateVariable ?? null,
			aggregateVariableIndex:
				this.getVariables().variableToIndex(
					this.state.aggregateVariable
				) ?? null,
			compareVariable: this.state.compareVariable ?? null,
			targetVariable: this.state.targetVariable ?? null,
			targetVariableIndex:
				this.getVariables().variableToIndex(
					this.state.targetVariable
				) ?? null,
			selectedTable: this.state.selectedTable ?? null,
			conditions: this.state.conditions ?? null,
			groupOperation: this.state.groupOperation ?? null,
			leftAdditionalValue: this.state.leftAdditionalValue ?? null,
			additionalParameter: this.state.additionalParameter,
			additionalParameterIndex:
				this.getVariables().variableToIndex(
					this.state.additionalParameter
				) ?? null,
			journeyName: this.props.journeyName,
			maxYRange: this.state.maxYRange ?? null,
			minYRange: this.state.minYRange ?? null,
			linesCount: this.state.linesCount ?? null,
			barSize: this.state.barSize ?? null,
			labelMapping: this.state.labelMapping ?? null,
			colorMapping: this.state.colorMapping ?? null,
			legendsColor: this.state.legendsColor ?? null,
			axesColor: this.state.axesColor ?? null,
		};
		if (this.props.addToCanvas) {
			config.dynamicOptions = this.prepareDynamicOptions(
				this.state.dynamicOptions,
				config
			);
		}
		return config;
	}
	buildContent() {
		switch (this.state.step) {
			case Step.initial:
				return this.buildInitial(
					"How do you want to examine your levers benchmarking?"
				);
			case Step.selectDataScope:
				return this.buildDataScopesSelector();
			case Step.selectConditions:
				return this.buildConditionsSelector();
			case Step.selectTable:
				return this.buildTableSelector();
			case Step.selectAggregate:
				return this.buildVariablesSelector(
					"I want to see how our best",
					"aggregateVariable",
					"aggregateVariables",
					false,
					DynamicOptionType.variable,
					"aggregateVariableIndex"
				);
			case Step.selectTarget:
				return this.buildVariablesSelector(
					"as measured by outcome",
					"targetVariable",
					"targetVariables",
					false,
					DynamicOptionType.variable,
					"targetVariableIndex"
				);
			case Step.selectCompare:
				return this.buildVariablesSelector(
					"to the",
					"compareVariable",
					"compareVariables"
				);
			case Step.selectAdditionalParameter:
				return this.buildVariablesSelector(
					"In month",
					"additionalParameter",
					"additionalParameters",
					false,
					DynamicOptionType.additionalParameter,
					"additionalParameterIndex"
				);
			case Step.selectAdditionalValue:
				return this.buildAdditionalValueSelector(
					`In month ${this.state.additionalParameter}`,
					false,
					true
				);

			case Step.final:
				return (
					<StepFinal
						onNewFinding={this.onNewFinding}
						onShowRecipesPopup={this.showRecipesPopup}
						onSaveFinding={this.saveFinding}
						onBack={this.back}
						finding={this.state.finding}
						loading={this.state.loading}
					/>
				);

			default:
				return <div />;
		}
	}
	buildLeftBar() {
		return super.buildLeftBar(this.state.step < Step.final - 1);
	}

	getData() {
		let config = this.getConfig();
		this.setState({ loading: true });
		Api.getData(config, undefined, this.props.currentModuleId)
			.then((item) => {
				this.setState({
					finding: item,
					loading: false,
				});
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
			});
	}
	initializeVariables() {
		let scopeVariables = this.getVariables();
		let newState = Object.assign({}, this.state);
		newState.targetVariables = scopeVariables.numericVariables.map(
			(item) => item.name
		);
		newState.aggregateVariables = scopeVariables.dataVariables.map(
			(item) => item.name
		);
		newState.additionalParameters = scopeVariables.dataVariables
			.filter((item) => item.level === "month")
			.map((item) => item.name);
		this.setState(newState);
	}
	switchVariables() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
		this.initializeVariables();
		this.initializeVariablesReaction = reaction(
			() => this.getVariables().dataVariables,
			() => {
				this.initializeVariables();
			}
		);
	}

	componentDidMount() {
		this.getLastJourneyConfig();
	}
	componentWillUnmount() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
	}

	selectVariable(variableName, variablesName, index) {
		if (index >= this.state[variablesName].length) return;
		let stateDiff = {
			[variableName]: this.state[variablesName][index],
		};
		if (this.state.step === Step.selectAdditionalParameter) {
			stateDiff.leftAdditionalValue = undefined;
		}
		this.setState(stateDiff, () => {
			setTimeout(() => {
				this.stepDown();
			}, 100);
		});
	}

	getSubtitle() {
		let conditionsString = conditionsToString(
			Array.from(this.state.conditions)
		);
		if (
			this.state.step === Step.selectDataScope ||
			this.state.step === Step.selectTable
		)
			return "How do you want to examine your levers data?";
		if (
			this.state.step > Step.selectTable &&
			this.state.step < Step.final
		) {
			let subtitle = `For the data in ${
				this.state.dataScope ? this.state.dataScope.label : ""
			} ${this.state.selectedTable.label}`;
			if (this.state.step > Step.selectConditions) {
				if (conditionsString.length > 0)
					subtitle += ` and under the conditions ${conditionsString}`;
			}
			if (this.state.step > Step.selectAggregate)
				subtitle += ` I want to see how our best ${
					this.state.aggregateVariable || ""
				}`;
			if (this.state.step > Step.selectTarget)
				subtitle += ` as measured by outcome ${
					this.state.targetVariable || ""
				}`;
			if (this.state.step > Step.selectCompare) {
				subtitle += ` compare in amount of all levers to the ${
					this.state.compareVariable || ""
				} ${this.state.aggregateVariable || ""} in month`;
			}
			return subtitle;
		}

		if (this.state.step === Step.final) {
			return (
				<>
					<span>For the data </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.dataScope
							? this.state.dataScope.label.concat(" ")
							: ""}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedTable.label}
					</span>
					{conditionsString.length > 0 && (
						<>
							<span> and under the conditions </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{conditionsString}
							</span>
						</>
					)}
					<span> I want to see how our best </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.aggregateVariable || ""}
					</span>
					<span> as measured by the outcome </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.targetVariable || ""}
					</span>
					<span> compare in amount of all levers </span>
					<span> to the </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.compareVariable || ""}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{" ".concat(this.state.aggregateVariable || "")}
					</span>
					{this.state.leftAdditionalValue ? (
						<>
							<span>{" in month "}</span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{`${this.state.additionalParameter || ""} `}
							</span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.leftAdditionalValue.label}
							</span>
						</>
					) : null}
					<span>.</span>
				</>
			);
		}
		return "";
	}

	showFinalStep() {
		if (
			this.state.targetVariable &&
			this.state.aggregateVariable &&
			this.state.compareVariable &&
			this.state.step > Step.initial &&
			this.state.step < Step.final
		)
			this.setState({ animationDirection: "top" }, () => {
				this.setState(
					(state) => ({
						previousStep: state.step,
						step: Step.final,
					}),
					() => {
						this.getData();
						this.saveNewJourneyConfig();
					}
				);
			});
	}

	getFooterTitle() {
		switch (this.state.step) {
			case Step.initial:
				return "... For the data in...";
			case Step.selectTable:
				return "... and under the conditions...";
			case Step.selectConditions:
				return "... I want to find our best and worst patients...";
			case Step.selectAggregate:
				return "... as measured by outcome...";
			case Step.selectTarget:
				return "... compare in amount of ...";
			case Step.selectCompare:
				return "[optional] in month ...";
			default:
				return "";
		}
	}
	stepDown() {
		if (this.state.step === Step.selectTable && !this.state.selectedTable) {
			return;
		}
		if (
			this.state.step === Step.selectTarget &&
			!this.state.targetVariable
		) {
			return;
		}
		if (
			this.state.step === Step.selectAggregate &&
			!this.state.aggregateVariable
		) {
			return;
		}
		if (
			this.state.step === Step.selectCompare &&
			!this.state.compareVariable
		) {
			return;
		}
		this.setState({ animationDirection: "top" }, () => {
			this.setState(
				(state) => {
					return {
						previousStep: state.step,
						step: state.step + 1,
					};
				},
				() => {
					if (this.state.step === Step.final) {
						this.getData();
						this.saveNewJourneyConfig();
					}
				}
			);
		});
	}

	stepUp() {
		if (this.state.step > Step.initial) {
			this.setState({ animationDirection: "bottom" }, () => {
				this.setState((state) => ({
					step: state.step - 1,
				}));
			});
		}
	}
	render() {
		return (
			<div
				className="dashboard-rect-journey-focus"
				tabIndex="0"
				style={{
					height: 580,
					overflow: "hidden",
					display: !this.props.collapsed ? "block" : "none",
				}}
				onClick={() => {
					if (this.state.step === Step.initial) this.stepDown();
				}}
				onKeyDown={(evt) => {
					if (evt.key === "Escape") {
						this.props.onClose();
					}

					if (evt.key === "ArrowDown") {
						if (
							this.state.step > Step.initial &&
							this.state.step < Step.final
						)
							this.stepDown();
						evt.preventDefault();
					}
					if (evt.key === "ArrowUp") {
						if (this.state.step < Step.final) this.stepUp();
						evt.preventDefault();
					}
					if (evt.key === "p") {
						if (this.state.step === Step.final) {
							this.saveFinding();
						}
					}
					if (evt.key === "d") {
						if (this.state.step === Step.final) {
							evt.preventDefault();
							this.showRecipesPopup();
						}
					}
					if (evt.key === "ArrowLeft") {
						if (this.state.step === Step.final) {
							this.back();
						}
					}
					if (evt.key === "ArrowRight") {
						this.showFinalStep();
					}

					let variableInfo = this.state.step2VariablesMap[
						this.state.step
					];
					if (variableInfo) {
						let variablesLength = this.state[variableInfo[1]]
							.length;
						let variableIndex = evt.keyCode - 65;
						if (
							variableIndex >= 0 &&
							variableIndex < variablesLength
						) {
							this.selectVariable(
								variableInfo[0],
								variableInfo[1],
								variableIndex
							);
						}
					}
					if (this.state.step === Step.selectAdditionalValue) {
						this.selectMonth(evt.key);
					}
					if (evt.key === "Enter") {
						if (
							this.state.step === Step.selectAdditionalValue &&
							this.state.leftAdditionalValue
						) {
							this.showFinalStep();
						}
					}
				}}
			>
				<div
					className="my-row"
					style={{ justifyContent: "space-between", height: "100%" }}
				>
					<div
						className="flex-column"
						style={{
							height: "100%",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						{this.buildHeader()}

						<TransitionGroup
							style={{
								minHeight: "inherit",
							}}
						>
							<CSSTransition
								style={{
									height: "100%",
								}}
								key={this.state.step}
								timeout={500}
								classNames={"journeywizard-".concat(
									this.state.animationDirection || ""
								)}
							>
								<div
									style={{
										height: "100%",
									}}
								>
									{this.buildContent()}
								</div>
							</CSSTransition>
						</TransitionGroup>

						<Footer
							showArrow={
								this.state.step > Step.initial &&
								this.state.step < Step.final
							}
							title={this.getFooterTitle()}
						/>
					</div>
				</div>
				{this.buildAddToRecipesPopup()}
			</div>
		);
	}
}

export { MainComponent };
