import React, { useState, useEffect } from "react";
import { useDrop } from "react-dnd";

import {
    HeatmapFinding,
    defaultHeatmapD3MinColor,
    defaultHeatmapD3MaxColor,
} from "common/Finding";
import EditableAxisItem from "../EditableAxisItem";
import * as d3 from "d3";
import { TooltipStyles } from "../../TooltipStyles";
import { mainStyle } from "common/MainStyle";
import {
    getGridColorByTheme,
    getDefaultColorsByTheme,
} from "../../BarChartTheme";
import { formatValue } from "common/utilities/FormatValue";
import D3ChartBase, { AxisType } from "common/graphics/v2/D3ChartBase";
import Portal from "common/Portal";
import { ReactComponent as SwapIcon } from "icons/canvas/exploration/swap_axes.svg";
import AnimationSlider from "common/AnimationSlider";
import { getTextSize } from "common/utilities/MeasureText";

interface Props {
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    content: HeatmapFinding["content"];
    config: HeatmapFinding["config"];
    onChangeData?: (
        data: HeatmapFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: HeatmapFinding["config"],
        updateData?: boolean
    ) => void;
    onChangeContent?: (
        content: HeatmapFinding["content"],
        updateData?: boolean
    ) => void;
    width: number;
    height: number;
    scale: number;
}

interface Datum {
    x: string;
    y: string;
    value: number;
    index: number;
    time?: string | number | null;
}

export default function HeatmapD3(props: Props) {
    let [showTooltipInfo, setShowTooltipInfo] = useState<{
        x: number;
        y: number;
        datum: Datum;
    } | null>(null);
    let [axisItemStyles, setAxisItemStyles] = useState<Record<any, number>>({
        xAxisWidth: 0,
        yAxisHeight: 0,
        xAxisOffset: 0,
    });

    let parentRef = React.useRef<HTMLDivElement>(null);
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    let dropRef = React.useRef<HTMLDivElement>(null);
    let rectsRef = React.useRef<
        d3.Selection<SVGRectElement, Datum, SVGGElement, unknown>
    >();
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            linkVariable(
                2,
                otherItem.content.variableName,
                otherItem.content.variableIndex
            );
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    if (props.editable) {
        drop(dropRef);
    }

    let currentEditVariableIndex: number | undefined = undefined;
    if (props.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = props.content.data.findIndex(
            (item) => item.variableIndex == null
        );
    }

    let onChangeName = (index: number, value: string) => {
        let newData = Array.from(props.content.data);
        newData[index].name = value;
        props.onChangeData?.(newData);
    };

    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.content.data);
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };

    useEffect(() => {
        let colorScale: (value: number) => string;
        if (props.content.data[2].value.length === 0) {
            colorScale = () => defaultHeatmapD3MinColor;
        } else {
            let extent = d3.extent(props.content.data[2].value as number[]) as [
                number,
                number
            ];
            if (props.config.minValue != null) {
                extent[0] = props.config.minValue;
            }
            if (props.config.maxValue != null) {
                extent[1] = Math.max(props.config.maxValue, extent[0]);
            }
            colorScale = d3
                .scaleLinear(extent, [
                    props.config.minColor ?? defaultHeatmapD3MinColor,
                    props.config.maxColor ?? defaultHeatmapD3MaxColor,
                ])
                .unknown(props.config.minColor ?? defaultHeatmapD3MinColor);
        }

        let axisFontSize: number =
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));
        let defaultColors = getDefaultColorsByTheme(props.config.chartTheme);
        // let themeOptions = getOptionsByTheme(props.config.chartTheme);
        let gridFillColor = getGridColorByTheme(
            props.config.chartTheme,
            props.config.baseBackgroundColor
        );

        const yAxisExtraSpace =
            getTextSize(
                "0",
                "Roboto",
                axisFontSize,
                "normal"
            ).width + 7;

        const xAxisExtraSpace =
            getTextSize(
                "A",
                "Roboto",
                axisFontSize,
                "normal"
            ).height / 2 - 23;


        // Padding is necessary to prevent tick text from being cut off
        let paddingRight = 20;
        let fullHeight = parentSvgRef.current?.clientHeight ?? 0;
        let fullWidth = parentSvgRef.current?.clientWidth ?? 0;
        let yAxisWidth = 50;
        let xAxisHeight = 30;
        // set the dimensions and margins of the graph
        let height = fullHeight - xAxisHeight;
        // We subtract yAxisExtraSpace to prevent the chart from shifting to
        // the right and cutting off axis label
        // https://eisengardai.atlassian.net/browse/EIS-259?focusedCommentId=12079
        let width = fullWidth - yAxisWidth - paddingRight - yAxisExtraSpace;

        setAxisItemStyles({
            xAxisWidth: width,
            yAxisHeight: height + 2,
            xAxisOffset: yAxisExtraSpace,
            yAxisOffset: xAxisExtraSpace,
        });

        // append the svg object to the body of the page
        d3.select(svgRef.current!).selectAll("*").remove();
        let svg = d3
            .select(svgRef.current!)
            .attr("width", fullWidth)
            .attr("height", fullHeight)
            .append("g")
            .style("cursor", "crosshair")
            .attr("transform", `translate(${yAxisExtraSpace}, ${-xAxisExtraSpace})`);

        let base = new D3ChartBase(svg, width, height);

        let backgroundColor = gridFillColor ?? "transparent";
        base.drawBackground(backgroundColor);
        // Rectangles. This g should be below axes.
        let rectG = svg.append("g");
        // X Axis
        let xValues = props.content.data[0].value.map(String);
        let { axis: xAxis } = base.drawBandAxis(
            AxisType.XAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: axisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
            },
            xValues
        );
        let xScale = xAxis.scale<d3.ScaleBand<string>>();
        // Y Axis
        let yValues = props.content.data[1].value.map(String);
        let { axis: yAxis } = base.drawBandAxis(
            AxisType.YAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: axisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
            },
            yValues
        );
        let yScale = yAxis.scale<d3.ScaleBand<string>>();

        let plotData: Datum[] = xValues.map((x, index) => ({
            x: x,
            y: yValues[index],
            value: props.content.data[2].value[index] as number,
            index: index,
            time: props.content.time?.value[index],
        }));

        rectsRef.current = rectG
            .selectAll<SVGRectElement, Datum[]>("rect")
            .data(plotData)
            .join("rect")
            .attr("x", (d) => xScale(d.x)!)
            .attr("y", (d) => yScale(d.y)!)
            .attr("width", xScale.bandwidth())
            .attr("height", yScale.bandwidth())
            .style("fill", (d) => colorScale(d.value))
            .attr("hidden", (d) =>
                d.time == null || d.time <= props.content.time!.uniqueValues[0]
                    ? null
                    : true
            );

        rectsRef
            .current!.on("mouseenter", (event: MouseEvent, d: Datum) => {
                setShowTooltipInfo({
                    x: event.x,
                    y: event.y,
                    datum: d,
                });
            })
            .on("mousemove", (event: MouseEvent) => {
                setShowTooltipInfo((showTooltipInfo) =>
                    showTooltipInfo == null
                        ? null
                        : {
                              ...showTooltipInfo,
                              x: event.x,
                              y: event.y,
                          }
                );
            })
            .on("mouseleave", () => setShowTooltipInfo(null));
    }, [
        props.content.data,
        props.content.time,
        props.content.time?.value,
        props.content.time?.uniqueValues,
        props.config.ticksSize,
        props.config.chartTheme,
        props.config.baseBackgroundColor,
        props.config.maxValue,
        props.config.minValue,
        props.config.maxYRange,
        props.config.minYRange,
        props.config.maxXRange,
        props.config.minXRange,
        props.config.linesCount,
        props.config.showXAxisName,
        props.config.showYAxisName,
        props.config.count,
        props.config.trendline,
        props.config.trendlineColor,
        props.config.axesLinesColor,
        props.config.chartColor,
        props.config.ticksColor,
        props.config.minColor,
        props.config.maxColor,
        props.preview,
        props.width,
        props.height,
        props.scale,
    ]);

    let tooltipStyle = {
        ...TooltipStyles(
            props.config.tooltipColor,
            props.config.tooltipFontSize
        ),
    };
    return (
        <div style={{ height: "100%" }}>
            <div
                ref={parentRef}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {props.content.time?.variableIndex != null &&
                    props.content.time?.uniqueValues.length !== 0 && (
                        <AnimationSlider
                            sliderStyle={{
                                cursor: "default",
                                width: 165,
                                pointerEvents: "auto",
                            }}
                            values={props.content.time?.uniqueValues}
                            onChange={(index) => {
                                rectsRef.current?.attr("hidden", (d) =>
                                    d.time == null ||
                                    d.time <=
                                        props.content.time!.uniqueValues[index]
                                        ? null
                                        : true
                                );
                            }}
                        />
                    )}
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "space-between"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{ width: "48px", height: "100%" }}
                        >
                            <div
                                style={{
                                    height: axisItemStyles.yAxisHeight,
                                    position: "relative",
                                    top: -axisItemStyles.yAxisOffset - 1,
                                }}
                            >
                                {props.config.showYAxisName && (
                                    <EditableAxisItem
                                        onChange={(value) => {
                                            onChangeName(1, value);
                                        }}
                                        color={props.config.axesNamesColor}
                                        index={1}
                                        currentEditVariableIndex={
                                            currentEditVariableIndex
                                        }
                                        vertical
                                        name={props.content.data[1].name}
                                        onDrop={linkVariable}
                                        editable={props.editable}
                                    />
                                )}
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <div
                                style={{
                                    width: 48,
                                    minHeight: 48,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {props.editable && (
                                    <div
                                        style={{
                                            width: 32,
                                            height: 32,
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            let data = Array.from(
                                                props.content.data
                                            );
                                            [data[0], data[1]] = [
                                                data[1],
                                                data[0],
                                            ];
                                            props.onChangeContent?.({
                                                data: data,
                                            });
                                        }}
                                    >
                                        <SwapIcon />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // Resizing does not work properly
                            // without overflow: "hidden"
                            overflow: "hidden",
                        }}
                    >
                        <div
                            ref={parentSvgRef}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                position: "relative",
                                // Resizing does not work properly
                                // without overflow: "hidden"
                                overflow: "hidden",
                            }}
                        >
                            {showTooltipInfo && (
                                <Portal rootNode={document.body}>
                                    <div
                                        style={{
                                            zIndex: 100000,
                                            position: "absolute",
                                            top: showTooltipInfo.y,
                                            left: showTooltipInfo.x,
                                            display: "flex",
                                            pointerEvents: "none",
                                            transform: "translateY(-50%)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: "10px",
                                                marginLeft: "5px",
                                                ...tooltipStyle.contentStyle,
                                                display: "flex",
                                                flexDirection: "column",
                                                zIndex: 100001,
                                            }}
                                        >
                                            <span
                                                className="unselectable"
                                                style={tooltipStyle.labelStyle}
                                            >
                                                {showTooltipInfo.datum.x}
                                            </span>
                                            <span
                                                className="unselectable"
                                                style={tooltipStyle.labelStyle}
                                            >
                                                {showTooltipInfo.datum.y}
                                            </span>
                                            <span
                                                style={{
                                                    ...tooltipStyle.itemStyle,
                                                    color: "black",
                                                }}
                                                className="unselectable"
                                            >{`${
                                                props.content.data[2].name
                                            }: ${formatValue(
                                                showTooltipInfo.datum.value
                                            ).join("")}`}</span>
                                        </div>
                                    </div>
                                </Portal>
                            )}
                            <svg ref={svgRef} />
                            <div
                                style={{
                                    left: 0,
                                    top: 0,
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    display: props.columnDragActive
                                        ? "flex"
                                        : "none",
                                }}
                            >
                                <div
                                    ref={dropRef}
                                    style={{
                                        width: "100%",
                                        borderStyle:
                                            collected.hover ||
                                            currentEditVariableIndex === 2
                                                ? "dashed"
                                                : "solid",
                                        borderWidth: 1,
                                        borderColor: collected.hover
                                            ? "#36B743"
                                            : currentEditVariableIndex === 2
                                            ? "#8DB8E3"
                                            : "#E9E9E9",
                                        display: "flex",
                                        background: collected.hover
                                            ? "#F4FBF5"
                                            : currentEditVariableIndex === 2
                                            ? "#EBF2F9"
                                            : "var(--slide-data-processing-content-color)",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <span
                                        className="no-selection"
                                        style={{
                                            backgroundColor: "transparent",
                                            fontFamily: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            textAlign: "center",
                                            color: "#333333",
                                        }}
                                    >
                                        {currentEditVariableIndex === 2
                                            ? "Drop variable here"
                                            : props.content.data[2].name}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="my-row"
                            style={{
                                // height: "48px" would not be enough since
                                // its size would change when x axis name is
                                // hidden. We have to set min and max height.
                                minHeight: "48px",
                                maxHeight: "48px",
                                position: "relative",
                                width: axisItemStyles.xAxisWidth,
                                left: axisItemStyles.xAxisOffset,
                            }}
                        >
                            {props.config.showXAxisName && (
                                <EditableAxisItem
                                    onChange={(value) => {
                                        onChangeName(0, value);
                                    }}
                                    color={props.config.axesNamesColor}
                                    currentEditVariableIndex={
                                        currentEditVariableIndex
                                    }
                                    editable={props.editable}
                                    index={0}
                                    vertical={false}
                                    name={props.content.data[0].name}
                                    onDrop={linkVariable}
                                />
                            )}
                            <div style={{ flexGrow: 1 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
