import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props) {
        let { data, config } = props;
        let item = {
            type: "18",
            content: {
                data: data,
            },
            config: config,
        };
        return item;
    }
    static getData(config, findingOptions, moduleId) {
        let {
            dataScope,
            conditions,
            count,
            selectedVariable,
            leftTimeVariable,
            rightTimeVariable,
            colorVariable,
            selectedVariableIndex,
            leftTimeVariableIndex,
            rightTimeVariableIndex,
            colorVariableIndex,
        } = config;

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );

        conditions = conditionsToJson(conditions);

        let variableIndices = [
            selectedVariableIndex,
            leftTimeVariableIndex,
            rightTimeVariableIndex,
        ];
        if (colorVariable != null) {
            variableIndices.push(colorVariableIndex);
        }

        let requestJson = {
            variable_indices: variableIndices,
            table: [],
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            conditions: conditions,
            limit: count || 100,
            module_id: moduleId,
        };
        return axios
            .post("/api/e/get_raw_data", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = response.data.current_levels;
                    let modifiedData = [];
                    for (let i = 0; i < data[selectedVariable].length; ++i) {
                        if (
                            data[leftTimeVariable][i] == null ||
                            data[rightTimeVariable][i] == null
                        )
                            continue;
                        let leftTime = Math.min(
                            data[leftTimeVariable][i],
                            data[rightTimeVariable][i]
                        );
                        let rightTime = Math.max(
                            data[leftTimeVariable][i],
                            data[rightTimeVariable][i]
                        );

                        let entry = {
                            name: data[selectedVariable][i],
                            "%gantt_interval": [leftTime, rightTime],
                        };
                        let color = data[colorVariable][i];
                        if (colorVariable != null) {
                            entry["%color"] =
                                typeof color === "string"
                                    ? color.trim()
                                    : color;
                        }
                        modifiedData.push(entry);
                    }
                    let finalItem = Api.getFinding({
                        data: modifiedData,
                        config: config,
                    });
                    return Promise.resolve(finalItem);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
