import React, { Component } from "react";
import InsightsGridCard from "./InsightsGridCard";
import ScrollableRow from "./ScrollableRow";
import { Permission } from "common/Permissions";

class InsightsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.buildInsightsGrid = this.buildInsightsGrid.bind(this);
        this.scrollableRowRef = React.createRef();
    }
    componentDidMount() {}

    buildInsightsGrid(canWrite) {
        if (typeof this.props.insights === "undefined") return null;
        let grid = this.props.insights.map((item, index) => {
            return (
                <div
                    style={{ marginLeft: index === 0 ? "0px" : "20px" }}
                    key={item.id}
                >
                    <InsightsGridCard
                        tabName={
                            this.props.tabs
                                ? this.props.tabs[item.tab_id]
                                : undefined
                        }
                        acceptDrag={"insight"}
                        acceptDrop={"insight"}
                        key={item.id}
                        canPin={true}
                        canCopy={canWrite}
                        canEdit={canWrite}
                        frozen={this.props.frozen}
                        onCrossClicked={() => {
                            this.props.onInsightDelete(item);
                        }}
                        onCardClicked={() => {
                            this.props.onInsightClick(item);
                        }}
                        onShareClicked={() => {
                            this.props.onInsightShare(item);
                        }}
                        onCardsSwapped={(item, other) => {
                            this.props.onInsightsSwap(item, other);
                        }}
                        onCopyClicked={() => {
                            this.props.onInsightCopy(item);
                        }}
                        onPinClicked={() => {
                            this.props.onInsightPin(item);
                        }}
                        item={item}
                    />
                </div>
            );
        });
        return grid;
    }

    render() {
        let canWrite = this.props.permission > Permission.ReadOnly;

        return (
            <div className="my-row">
                {!this.props.frozen && canWrite && !this.props.hideAddButton && (
                    <div
                        style={{ width: 50, height: 324 }}
                        type="button"
                        onClick={() => {
                            this.props.onInsightAdd(
                                this.props.tabId,
                                this.scrollableRowRef
                            );
                        }}
                    >
                        <img
                            alt=""
                            src="/dist/img/insights/add_insight_small.png"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                )}
                <ScrollableRow ref={this.scrollableRowRef}>
                    <>{this.buildInsightsGrid(canWrite)}</>
                </ScrollableRow>
            </div>
        );
    }
}

export default InsightsContent;
