import React from "react";
import Switch from "react-switch";
import Select, { createFilter } from "react-select";
import {
    CanvasDropdownSelector,
    CanvasFilter,
    ColumnFormat,
    DropdownOptionsAligment,
    dropdownOptionsAligmentOptions,
    isDropdownSelector,
    isTextBox,
    sortedOptions,
    SortedOrder,
} from "common/Canvas";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import styles from "./Settings.module.css";
import stylingSectionStyles from "modules/canvas_page/canvas_elements/NewDashboards/ChartsRibbon/StylingSection/StylingSection.module.css";
import { GlobalContextContents } from "GlobalContext";
import DefaultValueOption from "../../common/DefaultValueOption";
import { observer } from "mobx-react";
import Tables, { TableOption } from "common/Tables";
import remoteModuleId from "common/remoteModuleId";
import selectStyles from "../../common/SelectStyles";
import Variables, { VariableOption } from "common/Variables";
import { variableToColumnFormat } from "common/InputData";
import { ConditionsSelector } from "common/Conditions";
import { Condition, NodeLinkOption } from "common/Conditions";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import Input from "modules/canvas_page/canvas_elements/NewDashboards/ChartsRibbon/StylingSection/Input";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import GlobalInputs from "common/GlobalInputs";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    node: CanvasDropdownSelector | CanvasFilter;
    nodeLinkOptions: NodeLinkOption[];
    shared: boolean;
    globalContext: GlobalContextContents;
    onChange: (
        changes: Partial<CanvasDropdownSelector> | Partial<CanvasFilter>,
        commit?: boolean
    ) => void;
    onChangeShared: (shared: boolean) => void;
}

enum InputType {
    Regular = 1,
    Linked = 2,
    Global = 3,
    Cloud = 4,
    CloudInput = 5,
}

interface InputOption {
    label: string;
    value: number | number[];
    type?: InputType;
    outputIndex?: number;
    canvasId?: number;
    outerId?: string;
}

function Settings({
    currentModuleId,
    node,
    nodeLinkOptions,
    onChange,
    shared,
    globalContext,
    onChangeShared,
    canvasTreeStore
}: Props) {
    let dataScopes =
        currentModuleId != null
            ? DataScopesForModules(currentModuleId)
            : DataScopes;
    let allowMultipleOption = isDropdownSelector(node)
        ? node.multipleSelection
        : false;
    let allowAllValues = isDropdownSelector(node)
        ? node.allowAllValues ?? node.allValues ?? false
        : false;

    const [ contextOptions, setContextOptions ] = React.useState<InputOption[]>([]);
    const [ columnIndexes, setColumnIndexes ] = React.useState<any[]>([]);

    function getOptions(): InputOption[] {
        let options: InputOption[] = [];
        options = options
        .concat(
            GlobalInputs.map((globalInput) => ({
                ...globalInput,
                type: InputType.Global,
            }))
        );
        
        return options;
    }

    React.useEffect(() => {
        let options = getOptions();
        setContextOptions(options);
    }, []);


    return (
        <>
            <div className={styles.root}>
                <div className={styles.field}>
                    <span className={styles.label}>Dataset</span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={"Select dataset"}
                        styles={selectStyles}
                        options={dataScopes.dataScopesOptions}
                        onChange={(newValue) => {
                            onChange({
                                dataScopeOption: newValue as DataScopeOption,
                                tableOption: Tables(
                                    (newValue as DataScopeOption).value,
                                    currentModuleId ?? remoteModuleId
                                ).tableToOption(),
                                conditions: null,
                                variableOption: null,
                                metric: "",
                                value: NaN,
                                additionalOutputs: [],
                            });
                        }}
                        value={node.dataScopeOption}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
                {node.dataScopeOption != null && (
                    <div className={styles.field}>
                        <span className={styles.label}>Select table</span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Table"}
                            styles={selectStyles}
                            options={
                                Tables(
                                    node.dataScopeOption.value,
                                    currentModuleId ?? remoteModuleId
                                ).rawAndAggregateTableOptions
                            }
                            onChange={(newValue) => {
                                onChange({
                                    tableOption: newValue as TableOption,
                                    metric: "",
                                    value: NaN,
                                    additionalOutputs: [],
                                });
                            }}
                            value={node.tableOption}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                )}

                {isDropdownSelector(node) && (
                    <>
                        <div className={styles.switchField}>
                            <Switch
                                onChange={() => {
                                    onChange({
                                        allValues: false,
                                        variableMode: !(
                                            (node as CanvasDropdownSelector)
                                                .variableMode ?? false
                                        ),
                                        conditions: null,
                                        variableOption: null,
                                        metric: "",
                                        value: NaN,
                                        format: null,
                                        additionalOutputs: [],
                                    });
                                }}
                                checked={node.variableMode ?? false}
                                width={26}
                                height={13}
                                offColor="#D1D1D1"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#FFF"
                                onHandleColor="#1F8EFA"
                            />
                            <span className={styles.switchLabel}>
                                Variable mode
                            </span>
                        </div>

                        {!node.variableMode && node.dataScopeOption != null && (
                            <div
                                className={styles.field}
                                style={{ marginTop: "10px" }}
                            >
                                <span className={styles.label}>Variable</span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...selectStyles,
                                    }}
                                    options={Variables(
                                        node.dataScopeOption.value,
                                        currentModuleId ?? remoteModuleId
                                    ).dataVariables.map((variable) => ({
                                        label: variable.name,
                                        value: variable.index ?? -1,
                                        type: variable.type,
                                        panel: variable.panel,
                                    }))}
                                    onChange={(newValue) => {
                                        if (
                                            (newValue as VariableOption)
                                                .value ===
                                            node.variableOption?.value
                                        ) {
                                            onChange({
                                                variableOption:
                                                    newValue as VariableOption,
                                            });
                                            return;
                                        }

                                        let variable = Variables(
                                            (node as CanvasDropdownSelector)
                                                .dataScopeOption!.value,

                                            currentModuleId ?? remoteModuleId
                                        ).getVariableByIndex(
                                            (newValue as VariableOption).value
                                        );
                                        let format: ColumnFormat | undefined;
                                        if (variable != null)
                                            format =
                                                variableToColumnFormat(
                                                    variable
                                                );
                                        onChange({
                                            variableOption:
                                                newValue as VariableOption,
                                            metric: "",
                                            value: NaN,
                                            format: format,
                                            additionalOutputs: [],
                                        });
                                    }}
                                    value={node.variableOption}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                        )}
                        {!node.variableMode && node.dataScopeOption != null && (
                            <div className={styles.field}>
                                <span className={styles.label}>Filter</span>

                                <ConditionsSelector
                                    allowLinks
                                    nodeLinkOptions={nodeLinkOptions}
                                    small
                                    dataScopeId={node.dataScopeOption.value}
                                    title={""}
                                    style={{
                                        marginTop: 0,
                                        backgroundColor: "transparent",
                                    }}
                                    currentModuleId={currentModuleId}
                                    value={node.conditions ?? undefined}
                                    onChange={(conditions: Condition[]) => {
                                        onChange({
                                            conditions: conditions,
                                            metric: "",
                                            value: NaN,
                                        });
                                    }}
                                    allVariables={
                                        Variables(
                                            node.dataScopeOption.value,
                                            currentModuleId ?? remoteModuleId
                                        ).dataVariables
                                    }
                                    {...ConditionSelectorStyles}
                                />
                            </div>
                        )}
                    </>
                )}

                {isDropdownSelector(node) && (
                    <div className={styles.switchField}>
                        <Switch
                            onChange={() => {
                                onChange({
                                    multipleSelection: !allowMultipleOption,
                                    additionalOutputs: [],
                                } as any);
                            }}
                            checked={allowMultipleOption}
                            width={26}
                            height={13}
                            offColor="#D1D1D1"
                            onColor="#20293C"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#FFF"
                            onHandleColor="#1F8EFA"
                        />
                        <span className={styles.switchLabel}>
                            Allow multiple selection
                        </span>
                    </div>
                )}
                {isDropdownSelector(node) && (
                    <div>
                        <div className={styles.switchField}>
                            <Switch
                                onChange={(checked) => {
                                    onChangeShared(checked);
                                }}
                                checked={shared}
                                width={26}
                                height={13}
                                offColor="#D1D1D1"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#FFF"
                                onHandleColor="#1F8EFA"
                            />
                            <span className={styles.switchLabel}>
                                Linkable from other slides
                            </span>
                        </div>
                        {"CanvasLiveStream" in globalContext.permissions && (
                            <div className={styles.switchField}>
                                <Switch
                                    onChange={(checked) => {
                                        onChange({ liveStreaming: checked });
                                    }}
                                    checked={node.liveStreaming ?? false}
                                    width={26}
                                    height={13}
                                    offColor="#D1D1D1"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#FFF"
                                    onHandleColor="#1F8EFA"
                                />
                                <span className={styles.switchLabel}>
                                    Record Metric to Dataset
                                </span>
                            </div>
                        )}
                    </div>
                )}
                {isDropdownSelector(node) && (
                    <div className={styles.field} style={{ marginTop: "10px" }}>
                        <span className={styles.label}>{"Order"}</span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Order"}
                            styles={selectStyles}
                            options={sortedOptions}
                            value={
                                node.columnOrder
                                    ? sortedOptions[1]
                                    : sortedOptions[0]
                            }
                            onChange={(newValue) => {
                                onChange({
                                    columnOrder:
                                        (
                                            newValue as {
                                                value: SortedOrder;
                                            }
                                        ).value === SortedOrder.Database,
                                } as any);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                )}
                <div className={stylingSectionStyles.optionContainer}>
                    <span className={stylingSectionStyles.optionName}>
                        Font size
                    </span>

                    <Input
                        value={Number(node.fontSize ?? 10)}
                        onChange={(value: string | number) => {
                            onChange({
                                fontSize: Number(value),
                            });
                        }}
                    />
                </div>
                {isDropdownSelector(node) && (
                    <div className={styles.field} style={{ marginTop: "10px" }}>
                        <span className={styles.label}>{"Alignment"}</span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Alignment"}
                            styles={selectStyles}
                            options={dropdownOptionsAligmentOptions}
                            value={node.dropdownOptionsAligment}
                            onChange={(newValue) => {
                                onChange({
                                    dropdownOptionsAligment:
                                        newValue as DropdownOptionsAligment,
                                });
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                )}
            </div>

            {isDropdownSelector(node) && (
                <DefaultValueOption node={node} onChange={onChange} />
            )}
            {isDropdownSelector(node) && (
                <div style={{ paddingLeft: 20 }}>
                    <div className={styles.switchField}>
                        <Switch
                            onChange={() => {
                                let newValue = !allowAllValues;
                                onChange({
                                    allowAllValues: newValue,
                                } as any);
                            }}
                            checked={allowAllValues}
                            width={26}
                            height={13}
                            offColor="#D1D1D1"
                            onColor="#20293C"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#FFF"
                            onHandleColor="#1F8EFA"
                        />
                        <span className={styles.switchLabel}>
                            Select All Option
                        </span>
                    </div>
                </div>
            )}
            {isDropdownSelector(node) && (
                <div
                    style={{ padding: 20 }}
                >
                    <div
                        className="my-row"
                        style={{
                            alignItems: "center",
                            marginTop: "5px",
                        }}
                    >
                        <span
                            className={styles.thinOptionName}
                            style={{ marginTop: 30 }}
                        >
                            Update Element
                        </span>

                        <div
                            // className={commonStyles.optionContainer}
                            style={{ marginTop: 30, marginLeft: 15 }}
                        >
                            <div className="pretty p-default" contentEditable={false}>
                                <input
                                    type="checkbox"
                                    checked={node.update?.active}
                                    onChange={() => {
                                        onChange({
                                            update: {
                                                ...node.update,
                                                active: !node.update?.active ?? true
                                            } 
                                        });
                                    }}
                                />
                                <div className="state p-primary">
                                    <label>
                                        {/* Update element */}
                                        {/* {props.finding.config.navigation?.status ? "Active" : "Inactive"} */}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(node.update?.active) && (
                        <>
                            <div className={styles.field} style={{ marginTop: "10px" }}>
                                <span className={styles.label}>Context ID</span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Context ID"}
                                    styles={selectStyles}
                                    options={contextOptions as any}
                                    value={node.update?.contextId}
                                    onChange={(newValue) => {
                                        onChange({
                                            update: {
                                                ...node.update,
                                                contextId: newValue as any
                                            } 
                                        });
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div> 
                            <div
                                className={styles.field}
                                style={{ marginTop: "10px" }}
                            >
                                <span className={styles.label}>Context Variable</span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...selectStyles,
                                    }}
                                    options={Variables(
                                        node.dataScopeOption?.value,
                                        currentModuleId ?? remoteModuleId
                                    ).dataVariables.map((variable) => ({
                                        label: variable.name,
                                        value: variable.index ?? -1,
                                        type: variable.type,
                                        panel: variable.panel,
                                    }))}
                                    onChange={(newValue) => {
                                        onChange({
                                            update: {
                                                ...node.update,
                                                variableOption: newValue as VariableOption
                                            }
                                        });
                                    }}
                                    value={node.update?.variableOption}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default observer(Settings);
