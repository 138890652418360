import React, { useState } from "react";
import Select, { components } from "react-select";

import styles from "./styles.module.css";

type SelectProps = React.ComponentProps<typeof Select>;
interface Props extends SelectProps {
  fontColor?: string | null;
  fillColor?: string | null;
  borderColor?: string | null;
  fontSize?: string | number | null;
}

const SelectStyled1: React.FC<Props> = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Select
      {...props}
      onMenuOpen={() => setMenuOpen(true)}
      onMenuClose={() => setMenuOpen(false)}
      styles={{
        ...props.styles,
        control: (base, state) => ({
          ...base,
          borderWidth: !state.isFocused ? "0 0 1px 0" : 0,
          borderColor: "#e9e9e9",
          // This line disable the blue border
          boxShadow: state.isFocused ? "none" : "none",
          fontSize: props.fontSize as string | number,
          backgroundColor: props.fillColor as string,
          "&:hover": {
            borderColor: "#e9e9e9",
          },
          "&:after": {
            display: "block",
            content: '""',
            position: "relative",
            bottom: 0,
            width: "100%",
            borderBottom: "solid 3px #e91e63",
            borderColor: props.borderColor || undefined,
            transition: "transform 250ms ease-in-out",
            transform: state.isFocused ? "scaleX(1)" : "scaleX(0)",
          },
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: "transform 250ms ease-in-out",
          transform: state.isFocused ? "rotate(180deg)" : "rotate(0)",
          paddingLeft: 0,
          paddingRight: 0,
          "& > svg": {
            width: 15,
            height: 15,
          },
        }),
        clearIndicator: (base, state) => ({
          ...base,
          paddingLeft: 0,
          paddingRight: 0,
          "& > svg": {
            width: 15,
            height: 15,
          },
        }),
        menu: (base, state) => {
          return {
            ...base,
            borderRadius: 6,
            padding: 10,
            opacity: menuOpen ? 1 : 0,
            transition: "all 1s ease-in-out",
            visibility: menuOpen ? "visible" : "hidden",
          };
        },
        option: (base, { isFocused, isSelected }) => {
          return {
            ...base,
            borderRadius: 6,
            backgroundColor: isSelected
              ? "#3399ff"
              : isFocused
              ? "#f0f2f5"
              : "transparent",
            marginBottom: 5,
          };
        },
        input: undefined,
        singleValue: (base) => {
          return { ...base, color: (props.fontColor as string) || "black" };
        },
        multiValue: (base) => {
          return {
            ...base,
            backgroundColor: "transparent",
          };
        },
        multiValueLabel: (base) => {
          return {
            ...base,
            backgroundColor: "black",
            borderRadius: "30px 0 0 30px",
            padding: 5,
            color: (props.fontColor as string) || "white",
          };
        },
        multiValueRemove: (base) => {
          return {
            ...base,
            color: "white",
            backgroundColor: "black",
            borderRadius: "0 30px 30px 0",
            padding: 5,
          };
        },
      }}
      components={{ Menu }}
    />
  );
};

const Menu: React.FC<React.ComponentProps<typeof components.Menu>> = (
  props
) => {
  return (
    <components.Menu {...props} className={styles.menu}>
      {props.children}
    </components.Menu>
  );
};

export default SelectStyled1;
