import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="3 3 24 24"
			xmlns="http://www.w3.org/2000/svg"
			fill="#C4C4C4"
			stroke="#D8D8D8"
		>
			<rect x="5" y="5" width="14" height="14" rx="1" />
			<path {...props} d="M7 22L22 22" strokeLinejoin="round" />
			<line
				{...props}
				x1="21.5"
				y1="7"
				x2="21.5"
				y2="22"
				strokeLinejoin="round"
			/>
		</svg>
	);
}