import React from "react";
import AutosizeInput from "react-input-autosize";
import DynamicOptionType from "common/DynamicOptionType";
import Select, { createFilter } from "react-select";
import customSelectStyles, {
    customMultiSelectStyles,
} from "common/SelectStyles";
import SearchComponent from "common/SearchComponent";
import { ConditionsSelector } from "common/Conditions";
import Variables from "common/Variables";
import CustomSelect from "common/CustomSelect";
import ValueWithOperator from "common/ValueWithOperator";
import MultiValueWithOperator from "common/MultiValueWithOperator";
import SharedBoxesStore from "../SharedBoxesStore";
import { getTargetValue } from "common/Canvas";
import remoteModuleId from "common/remoteModuleId";
import { observer } from "mobx-react";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";

export const DashboardDropdown = observer(function DashboardDropdown(props) {
    let { config, dynamicOption } = props;
    let inner = DashboardDropdownInner(props);
    if (inner === null) return null;
    return (
        <div
            className="my-row"
            onKeyDown={(evt) => {
                evt.stopPropagation();
            }}
        >
            {dynamicOption.type !== DynamicOptionType.conditions && (
                <AutosizeInput
                    type="text"
                    inputClassName="dropdown-label"
                    inputStyle={{
                        height: "40px",
                    }}
                    value={dynamicOption.label}
                    onChange={(evt) => {
                        let newConfig = Object.assign({}, config);
                        newConfig.dynamicOptions[dynamicOption.name].label =
                            evt.target.value;
                        props.onChange(newConfig);
                    }}
                />
            )}
            {inner}
        </div>
    );
});

function DashboardDropdownInner(props) {
    let { config, dynamicOption } = props;
    if (
        config.additionalParameter != null &&
        config.dataScope != null &&
        config.additionalParameterIndex == null
    ) {
        config.additionalParameterIndex = Variables(
            config.dataScope?.value,
            props.currentModuleId ?? remoteModuleId
        ).variableToIndex(config.additionalParameter);
    }

    if (dynamicOption.type === DynamicOptionType.regular) {
        let value = config[dynamicOption.name];
        let options = dynamicOption.options;
        //    return null;
        return (
            <div style={{ width: 200 }}>
                <CustomSelect
                    isMulti={dynamicOption.multiselection}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    customIndicators={dynamicOption.multiselection}
                    placeholder={""}
                    styles={
                        dynamicOption.multiselection
                            ? customMultiSelectStyles
                            : customSelectStyles
                    }
                    options={options.map((item) => {
                        return {
                            label: item,
                            value: item,
                        };
                    })}
                    value={
                        value
                            ? !dynamicOption.multiselection
                                ? { label: value, value: value }
                                : value.map((item) => ({
                                      label: item,
                                      value: item,
                                  }))
                            : null
                    }
                    onChange={(newValue) => {
                        let newConfig = Object.assign({}, config);
                        newConfig[dynamicOption.name] =
                            dynamicOption.multiselection
                                ? newValue
                                    ? newValue.map((item) => item.value)
                                    : []
                                : newValue.value;
                        props.onChange(newConfig);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
        );
    }
    if (dynamicOption.type === DynamicOptionType.variable) {
        let value = undefined;
        if (!dynamicOption.multiselection)
            value = config[dynamicOption.name]
                ? {
                      label: config[dynamicOption.name],
                      value: config[dynamicOption.indexName],
                  }
                : null;
        else {
            value = config[dynamicOption.name].map((item, index) => ({
                label: item,
                value: config[dynamicOption.indexName][index],
            }));
        }
        let options = dynamicOption.options;
        //    return null;
        let hasLocked = false;
        if (dynamicOption.multiselection && dynamicOption.locked != null) {
            hasLocked = value?.reduce(
                (acc, _item, index) => acc || dynamicOption.locked[index],
                false
            );
        }
        if (hasLocked)
            return (
                <div style={{ width: 200 }}>
                    {value?.map((item, index) => (
                        <CustomSelect
                            key={index}
                            isMulti={false}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            isDisabled={dynamicOption.locked[index]}
                            customIndicators={false}
                            placeholder={""}
                            styles={{
                                ...customSelectStyles,
                                dropdownIndicator: !dynamicOption.locked[index]
                                    ? customMultiSelectStyles.dropdownIndicator
                                    : () => ({
                                          display: "none",
                                      }),
                            }}
                            options={options}
                            value={item}
                            onChange={(newValue) => {
                                let newConfig = Object.assign({}, config);
                                newConfig[dynamicOption.name][index] =
                                    newValue.label;
                                newConfig[dynamicOption.indexName][index] =
                                    newValue.value;

                                props.onChange(newConfig);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    ))}
                </div>
            );

        return (
            <div style={{ width: 200 }}>
                <CustomSelect
                    isMulti={dynamicOption.multiselection}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    customIndicators={dynamicOption.multiselection}
                    placeholder={""}
                    styles={
                        dynamicOption.multiselection
                            ? customMultiSelectStyles
                            : customSelectStyles
                    }
                    options={options}
                    value={value}
                    onChange={(newValue) => {
                        let newConfig = Object.assign({}, config);
                        newConfig[dynamicOption.name] =
                            dynamicOption.multiselection
                                ? newValue
                                    ? newValue.map((item) => item.label)
                                    : []
                                : newValue.label;
                        newConfig[dynamicOption.indexName] =
                            dynamicOption.multiselection
                                ? newValue
                                    ? newValue.map((item) => item.value)
                                    : []
                                : newValue.value;
                        if (
                            dynamicOption.multiselection &&
                            dynamicOption.maxCount != null
                        ) {
                            let length = newConfig[dynamicOption.name].length;
                            if (length > dynamicOption.maxCount) {
                                newConfig[dynamicOption.name] = newConfig[
                                    dynamicOption.name
                                ].slice(length - dynamicOption.maxCount);
                                newConfig[dynamicOption.indexName] = newConfig[
                                    dynamicOption.indexName
                                ].slice(length - dynamicOption.maxCount);
                            }
                        }
                        props.onChange(newConfig);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
        );
    }
    if (dynamicOption.type === DynamicOptionType.conditions) {
        let dataScopeId =
            config.dataScope === "undefined" ? 0 : config.dataScope.value;
        return (
            <ConditionsSelector
                currentModuleId={props.currentModuleId}
                allowLinks
                nodeLinkOptions={Array.from(
                    props.canvasTreeStore.canvasTreeState.values()
                )
                    .map((item) => ({
                        label: item.outerId,
                        value: item.id,
                        isCloneInput: false,
                        target: getTargetValue(item),
                    }))
                    .concat(
                        SharedBoxesStore.sharedBoxes(
                            props.canvasTreeStore.canvasTreeState.canvasId
                        ).map((item) => ({
                            label: item.label,
                            value: item.value,
                            isCloneInput: true,
                            target: getTargetValue(item.item.box),
                        }))
                    )}
                small
                dataScopeId={dataScopeId}
                value={config.conditions}
                title={"Filters"}
                onChange={(conditions) => {
                    let newConfig = Object.assign({}, config);
                    newConfig.conditions = conditions;
                    props.onChange(newConfig);
                }}
                allVariables={
                    Variables(
                        dataScopeId,
                        props.currentModuleId ?? remoteModuleId
                    ).dataVariables
                }
                {...ConditionSelectorStyles}
            />
        );
    }
    if (dynamicOption.type === DynamicOptionType.additionalParameter) {
        let value = config[dynamicOption.name]
            ? {
                  label: config[dynamicOption.name],
                  value: config[dynamicOption.indexName],
              }
            : null;
        let options = dynamicOption.options;
        return (
            <div style={{ width: 200 }}>
                <Select
                    isClearable
                    isMulti={dynamicOption.multiselection}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={""}
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({
                            ...base,
                            height: "38px",
                        }),
                    }}
                    options={options}
                    value={value}
                    onChange={(newValue) => {
                        let newConfig = Object.assign({}, config);
                        newConfig[dynamicOption.name] = newValue
                            ? newValue.label
                            : null;
                        newConfig[dynamicOption.indexName] = newValue
                            ? newValue.value
                            : null;
                        if ("leftAdditionalValue" in newConfig)
                            newConfig.leftAdditionalValue = null;
                        if ("rightAdditionalValue" in newConfig)
                            newConfig.rightAdditionalValue = null;
                        if ("additionalValues")
                            newConfig.additionalValues = new Array(5).fill({
                                label: null,
                                value: null,
                            });
                        props.onChange(newConfig);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
        );
    }
    if (dynamicOption.type === DynamicOptionType.autocomplete) {
        if (!config.additionalParameter) return null;
        let value = config[dynamicOption.name];
        if (!dynamicOption.multiselection) {
            return (
                <div
                    style={{
                        width: 200,
                    }}
                >
                    <SearchComponent
                        clearable
                        dataScopeId={config.dataScope.value}
                        customStyles={customSelectStyles}
                        initialValue={value}
                        searchIndex={config.additionalParameterIndex}
                        onOptionSelected={(selectedValue) => {
                            let newConfig = Object.assign({}, config);
                            newConfig[dynamicOption.name] = selectedValue;
                            props.onChange(newConfig);
                        }}
                        currentModuleId={props.currentModuleId}
                    />
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        width: 200,
                    }}
                >
                    <SearchComponent
                        isMulti
                        clearable
                        dataScopeId={config.dataScope.value}
                        customStyles={customMultiSelectStyles}
                        customIndicators={true}
                        initialValue={
                            config[dynamicOption.name] != null
                                ? config[dynamicOption.name].filter(
                                      (item) => item.value != null
                                  )
                                : null
                        }
                        searchIndex={config.additionalParameterIndex}
                        onOptionSelected={(selectedValue) => {
                            let newConfig = Object.assign({}, config);
                            if (selectedValue && selectedValue.length > 0) {
                                selectedValue = selectedValue.slice(0, 5);
                            }
                            newConfig[dynamicOption.name] = selectedValue || [];
                            props.onChange(newConfig);
                        }}
                        currentModuleId={props.currentModuleId}
                    />
                </div>
            );
        }
    }
    if (dynamicOption.type === DynamicOptionType.valueWithOperator) {
        if (!config.additionalParameter) return null;

        let [value, operator] = dynamicOption.name.split(",");
        let dataScopeId =
            config.dataScope === "undefined" ? 0 : config.dataScope.value;
        const variableInfo = Variables(
            dataScopeId,
            props.currentModuleId ?? remoteModuleId
        ).dataVariables[config.additionalParameterIndex];
        if (variableInfo == null) return null;
        const numeric =
            variableInfo?.type === "float" || variableInfo?.type === "int";

        if (!dynamicOption.multiselection) {
            return (
                <ValueWithOperator
                    smallText
                    clearable
                    dataScopeId={config.dataScope.value}
                    operator={config[operator]}
                    value={config[value]}
                    searchKey={config.additionalParameter}
                    searchIndex={config.additionalParameterIndex}
                    numeric={numeric}
                    onOperatorChange={(newOperator) => {
                        let newConfig = Object.assign({}, config);
                        newConfig[operator] = newOperator;
                        props.onChange(newConfig);
                    }}
                    onValueChange={(newValue) => {
                        let newConfig = Object.assign({}, config);
                        newConfig[value] = newValue;
                        props.onChange(newConfig);
                    }}
                />
            );
        } else {
            return (
                <div>
                    <MultiValueWithOperator
                        clearable
                        dataScopeId={config.dataScope.value}
                        operators={config[operator]}
                        values={config[value]}
                        searchKey={config.additionalParameter}
                        searchIndex={config.additionalParameterIndex}
                        numeric={numeric}
                        onChange={(newOperators, newValues) => {
                            let newConfig = Object.assign({}, config);
                            newConfig[operator] = newOperators;
                            newConfig[value] = newValues;
                            props.onChange(newConfig);
                        }}
                    />
                    {/* <SearchComponent
                        isMulti
                        clearable
                        dataScopeId={config.dataScope.value}
                        customStyles={customMultiSelectStyles}
                        customIndicators={true}
                        initialValue={
                            config[dynamicOption.name] != null
                                ? config[dynamicOption.name].filter(
                                        (item) => item.value != null
                                  )
                                : null
                        }
                        searchKey={config.additionalParameter}
                        searchIndex={config.additionalParameterIndex}
                        onOptionSelected={(selectedValue) => {
                            let newConfig = Object.assign({}, config);
                            if (selectedValue && selectedValue.length > 0) {
                                selectedValue = selectedValue.slice(0, 5);
                            }
                            newConfig[dynamicOption.name] = selectedValue || [];
                            props.onChange(newConfig);
                        }}
                        currentModuleId={props.currentModuleId}
                    /> */}
                </div>
            );
        }
    }

    return null;
}
