import React, { CSSProperties } from "react";
import Switch from "react-switch";
import { observer } from "mobx-react";
import { MapVariableOption, TextAlign } from "common/Canvas";
import ColorPicker from "common/ColorPicker";
import { defaultMapColorOptions } from "../Constants";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";

interface Props {
    variables: (MapVariableOption | null)[];
    onChange: (variableOptions: (MapVariableOption | null)[]) => void;
    style?: CSSProperties;
    itemStyle?: CSSProperties;
}

interface TextAlignOption {
    label: TextAlign;
    value: TextAlign;
}

const ColorsSelectorView = observer(function ColorsSelectorView(props: Props) {
    let alignmentOptions = Object.values(TextAlign).map((value) => ({
        label: value,
        value: value,
    }));
    return (
        <div
            className="my-row"
            style={{
                overflowWrap: "break-word",
                marginBottom: "5px",
                ...props.style,
            }}
        >
            {props.variables.map(
                (value, index) =>
                    value != null && (
                        <div
                            key={index}
                            style={{
                                alignItems: "center",
                                ...props.itemStyle,
                            }}
                            className="my-row"
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            <span
                                className="unselectable"
                                style={{
                                    marginLeft: "20px",
                                    fontSize: 10,
                                    fontFamily: "Roboto",
                                    color:
                                        dataScienceElementsStyle.primaryTextColor,
                                }}
                            >
                                {`${value.variable.label}:`}
                            </span>
                            <ColorPicker
                                tooltip="Font Color"
                                enableAlpha
                                value={value.options.fontColor}
                                onChange={(newValue) => {
                                    let variables = Array.from(props.variables);
                                    variables[index] = {
                                        ...variables[index]!,
                                        options: {
                                            ...variables[index]!.options,
                                            fontColor: newValue,
                                        },
                                    };
                                    props.onChange(variables);
                                }}
                                style={{
                                    alignSelf: "flex-end",
                                    marginLeft: "5px",
                                }}
                            />
                            <input
                                title="Font Size"
                                type={"number"}
                                className="like-select"
                                style={{
                                    padding: "0 0 0 0",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    width: "45px",
                                    minHeight: "23px",
                                    height: "23px",
                                }}
                                onContextMenu={(evt) => {
                                    evt.stopPropagation();
                                }}
                                value={String(value.options.fontSize)}
                                placeholder=""
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onChange={(e) => {
                                    let variables = Array.from(props.variables);
                                    variables[index] = {
                                        ...variables[index]!,
                                        options: {
                                            ...variables[index]!.options,
                                            fontSize:
                                                Number(e.target.value) ??
                                                defaultMapColorOptions.fontSize,
                                        },
                                    };
                                    props.onChange(variables);
                                }}
                            />
                            <div title="Show title">
                                <Switch
                                    onChange={(checked) => {
                                        let variables = Array.from(
                                            props.variables
                                        );
                                        variables[index] = {
                                            ...variables[index]!,
                                            options: {
                                                ...variables[index]!.options,
                                                showTitle: checked,
                                            },
                                        };
                                        props.onChange(variables);
                                    }}
                                    checked={value.options.showTitle ?? true}
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100px",
                                        marginLeft: "5px",
                                        height: "38px",
                                    }),
                                }}
                                options={alignmentOptions}
                                value={alignmentOptions.find(
                                    (option) =>
                                        (value.options.textAlign ??
                                            TextAlign.left) === option.value
                                )}
                                onChange={(newValue) => {
                                    let variables = Array.from(props.variables);
                                    variables[index] = {
                                        ...variables[index]!,
                                        options: {
                                            ...variables[index]!.options,
                                            textAlign: (newValue as TextAlignOption)
                                                .value,
                                        },
                                    };
                                    props.onChange(variables);
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                    )
            )}
        </div>
    );
});
export default ColorsSelectorView;
