import React from "react";
import { CommentPin, CanvasComment } from "../../../comments/CanvasComments";
import { observer } from "mobx-react";
import styles from "./PinItem.module.css";
import PinStore from "../../../comments/PinStore";
import TextareaAutosize from "react-autosize-textarea";
import cx from "classnames";
import { formatDate } from "common/utilities/FormatDate";
import NotificationDot from "common/NotificationIcon";
import NewCommentsStore from "../../../comments/NewCommentsStore";

interface Props {
	canvasId: number;
	moduleId: number;
	pin: CommentPin;
	onClick: () => void;
}

function PinItem(props: Props) {
	let comments = PinStore(props.canvasId).comments(props.pin.id);
	let firstComment: CanvasComment | undefined;
	if (comments.length > 0) firstComment = comments[0];
	let restCount = 0;
	if (comments.length > 0) restCount = comments.length - 1;
	return (
		<div
			className={cx(styles.container, "unselectable")}
			onClick={props.onClick}
		>
			{NewCommentsStore(props.moduleId).hasPin(props.pin.id) && (
				<NotificationDot
					hideBorder
					style={{
						top: 5,
						right: 5,
					}}
				/>
			)}
			<div className={styles.headerContainer}>
				<div className={styles.pin} />
				<span
					className={styles.secondaryTextStyle}
					style={{ marginLeft: 5 }}
				>
					{props.pin.id}
				</span>
			</div>
			{firstComment != null && (
				<>
					<div className={styles.userInfoContainer}>
						<span className={styles.secondaryTextStyle}>
							{firstComment.user_info.user_name}
						</span>
						<span
							className={styles.secondaryTextStyle}
							style={{ marginLeft: 5 }}
						>
							{formatDate(firstComment.creation_time, "added")}
						</span>
					</div>
					<TextareaAutosize
						className={styles.lastCommentTextStyle}
						readOnly={true}
						placeholder="Reply"
						value={firstComment.feedback}
					/>
					{restCount > 0 && (
						<span
							className={styles.secondaryTextStyle}
							style={{ marginTop: 10 }}
						>
							{`Replies: ${restCount}`}
						</span>
					)}
				</>
			)}
		</div>
	);
}

export default observer(PinItem);