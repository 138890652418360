import React, { Component } from "react";
import * as d3 from "d3";
import "leaflet/dist/leaflet.css";
import Leaflet, { LatLng } from "leaflet";
import { useMap, MapContainer, GeoJSON } from "react-leaflet";
import "leaflet.heat";
import { DualRing } from "react-spinners-css";
import axios from "common/ServerConnection";
import { GeoJsonObject } from "geojson";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { colorList } from "common/graphics/LineColors";
import remoteModuleId from "common/remoteModuleId";
import { PopupStatus } from "common/StatusPopup";
import {
    MapFinding,
    NetworkFinding,
    defaultHeatmapMinOpacity,
    defaultHeatmapGradient,
    choroplethVariablesToTooltipVariables,
    defaultBubbleBoundariesColor,
    defaultChoroplethMinColor,
    defaultChoroplethMiddleColor,
    defaultChoroplethMaxColor,
} from "common/Finding";
import AnimationSlider from "common/AnimationSlider";
import Network from "common/Network";
import countryData from "common/countries.json";
import statesUsData from "common/states_us.json";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

// Added SVG buttons
import TileFilterLayer from "common/leaflet_addons/TileFilterLayer";
import OutsideAlerter from "common/OutsideAlerter";
import { SearchComponentOption } from "../SearchComponent";
import "./styles.css";
import {
    ChoroplethDataInterface,
    DashboardMap,
    HoverInfo,
    MapData,
} from "./types";
import { OperationStatus } from "./consts";
import { ChoroplethMap } from "./components/ChoroplethMap";
import { filterAdditionalMapData, MapListener } from "./helpers";
import { HeatMap } from "./components/HeatMap";
import { markerIconWithSymbol } from "./components/MarkerIcon";
import { MarkerClusterGroupWrapper } from "./components/MarkerClusterGroupWrapper";
import { TooltipContent } from "./components/TooltipContent";
import { BubbleMap } from "./components/BubbleMap";

interface Props {
    // The finding
    mapFinding: MapFinding;

    // Network finding (for network over map)
    networkFinding?: NetworkFinding;

    // unique identifier of the element
    mapId: string;

    // if true then we in live mode
    live: boolean;

    // scale of presentation
    scale: number;

    // onChange callback
    onChange: (mapElement: MapFinding, updateData?: boolean) => void;

    // id of current presentation
    currentModuleId: number | undefined;

    loading: boolean;

    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;

    // dashboard size
    width: number;
    height: number;

    centerVariableValue?: SearchComponentOption | null;

    onNetworkChange?: (
        networkFinding: NetworkFinding,
        updateData?: boolean
    ) => void;
}

interface State {
    updating: boolean;
    operationErrorMessage: string | null;
    operationStatus: OperationStatus;
    popupMarkerIndex: number | null;
    hoverInfo: HoverInfo | null;
    colorVariableValues: { [key: string]: (string | number | null)[] };
    colorVariableValueToIndex: {
        [key: string]: Map<string | number | null, number>;
    };
    geoJsonSizes: { [key: string]: number };
    popupStatus: PopupStatus | undefined;
    message: string | undefined;
    hamburgerMenuOpened: boolean;
    timeIndex: number;
    mapRef: Leaflet.Map | null;
    mainMapShown: boolean;
    filterAdditionalMapVariableIndex: number | null;
    tooltipMapLevel: number;
    mapLevel: number;
    initGestureHandling: boolean;
    choroplethData: ChoroplethDataInterface | null;
    findingData: any | null;
    findingHeatMapData: any | null;
    findingChoroplethData: any | null;
    mapsData: MapData[] | null;
}

/*!
 * MapElementView is a root class that renders
 * wizard for creation of map and result map
 */
export default class MapElementView extends Component<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement>;
    private clusterRef = React.createRef<MarkerClusterGroup>();
    // If the map is a choropleth (the map itself, not the additional maps)
    // then this is a ref to the choropleth
    private rootChoroplethRef = React.createRef<ChoroplethMap>();

    private moveendDisabledRef: React.MutableRefObject<
        boolean | null
    > = React.createRef<boolean>();

    constructor(props: Props) {
        super(props);
        this.state = {
            updating: false,
            operationErrorMessage: null,
            operationStatus: OperationStatus.NotStarted,
            popupMarkerIndex: null,
            hoverInfo: null,
            colorVariableValues: {},
            colorVariableValueToIndex: {},
            geoJsonSizes: {},
            popupStatus: undefined,
            message: undefined,
            hamburgerMenuOpened: false,
            mapLevel: 0,
            tooltipMapLevel: 0,
            timeIndex: 0,
            mapRef: null,
            mainMapShown: true,
            filterAdditionalMapVariableIndex: null,
            initGestureHandling: true,
            choroplethData: null,
            findingData: null,
            findingHeatMapData: null,
            findingChoroplethData: null,
            mapsData: null,
        };
        this.rootRef = React.createRef();
        this.showPopup = this.showPopup.bind(this);
        this.showChoroplethPopup = this.showChoroplethPopup.bind(this);
        this.showHoverTooltip = this.showHoverTooltip.bind(this);
        this.onMapCreated = this.onMapCreated.bind(this);
        this.handleLoaded = this.handleLoaded.bind(this);

        this.moveendDisabledRef.current = false;
    }

    public componentDidMount(): void {
        const { parentMap, additionalMaps } = this.identifyMaps(this.props);

        const maps = this.getSortedMapsList(parentMap, additionalMaps);
        for (let i = 0; i < maps.length; ++i) {
            let map = maps[i];
            if (
                map.finding.config.varyMarkerColorByVariable &&
                map.finding.config.markerColorVariableIndex != null &&
                map.finding.config.markerIcon == null
            ) {
                this.getColorVariableValues(map.finding, i);
            }
        }

        let geoJsonSizes: { [key: string]: number } = {};
        for (let [key, item] of Object.entries(
            this.props.mapFinding.config.geoJsonFiles ?? {}
        )) {
            if (item != null) {
                geoJsonSizes[key] = JSON.stringify(item).length;
            }
        }
        this.setState({ geoJsonSizes: geoJsonSizes });
        this._setMapsDataState();
    }

    private _setMapsDataState(): void {
        const { parentMap, additionalMaps } = this.identifyMaps(this.props);

        let maps = this.getSortedMapsList(parentMap, additionalMaps);
        const unsortedMaps = [parentMap, ...additionalMaps];

        this.setState({
            mapsData: maps.map((x) => {
                const index = unsortedMaps.findIndex(
                    (data) => data.id === x.id
                );
                const finding = unsortedMaps[index].finding;

                const {
                    findingData,
                    findingHeatMapData,
                    findingChoroplethData,
                } = filterAdditionalMapData(
                    finding,
                    this.state.filterAdditionalMapVariableIndex,
                    x.finding?.config.filterAdditionalMapVariable?.value ?? null
                );
                return {
                    map: x,
                    finding: finding,
                    index: index,
                    findingData: findingData,
                    findingHeatMapData: findingHeatMapData,
                    findingChoroplethData: findingChoroplethData,
                };
            }),
        });
    }

    public handleLoaded(state: boolean): void {
        this.setState({ updating: state });
    }
    private getCenterByValue(): number[] | string[] | null {
        if (this.props.mapFinding.type === "maps_choropleth") {
            let config = this.props.mapFinding.config;
            if (
                config.centerVariableIndex == null ||
                config.centerVariableValue == null
            )
                return null;
            let dataSource: {
                [key: string]: number[][] | number[][][];
            };
            switch (config.choroplethLevel ?? "country") {
                case "state_us":
                    dataSource = statesUsData;
                    break;
                case "zipcode_us":
                case "county_us":
                    dataSource = this.rootChoroplethRef.current!.boundaries;
                    break;
                default:
                    dataSource = countryData;
                    break;
            }
            let data =
                dataSource[
                    String(config.centerVariableValue.value).toLowerCase()
                ];
            if (data == null || data.length === 0) {
                return null;
            } else if (typeof data[0][0] === "number") {
                return [
                    d3.mean(data as number[][], (d) => d[1])!,
                    d3.mean(data as number[][], (d) => d[0])!,
                ];
            } else {
                return [
                    d3.mean(
                        data as number[][][],
                        (d) => d3.mean(d, (d) => d[1])!
                    )!,
                    d3.mean(
                        data as number[][][],
                        (d) => d3.mean(d, (d) => d[0])!
                    )!,
                ];
            }
        } else {
            let data = this.props.mapFinding.content.data;
            let config = (this.props.networkFinding ?? this.props.mapFinding)
                .config;
            if (
                config.centerVariableIndex == null ||
                config.centerVariableValue == null
            )
                return null;
            if (data == null) return null;
            let latIndex: string | undefined;
            let lonIndex: string | undefined;
            if (config.usesCoordinates) {
                latIndex = config.coordinates?.latitude?.value.toString();
                lonIndex = config.coordinates?.longitude?.value.toString();
            } else {
                latIndex = "%lat";
                lonIndex = "%lon";
            }
            let coordinates: number[] | string[] | null = null;

            if (
                latIndex != null &&
                lonIndex != null &&
                data[latIndex] != null &&
                data[lonIndex] != null &&
                data[config.centerVariableIndex] != null
            ) {
                let len = data[latIndex]?.length ?? 0;
                for (let index = 0; index < len; ++index) {
                    if (
                        data[config.centerVariableIndex][index] ===
                            config.centerVariableValue.value &&
                        data[latIndex][index] != null &&
                        data[lonIndex][index] != null
                    ) {
                        coordinates = [
                            data[latIndex][index] as number,
                            data[lonIndex][index] as number,
                        ];
                        return coordinates;
                    }
                }
            }
            return coordinates;
        }
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        // Update geoJsonSizes
        let prevGeoJsonFiles = prevProps.mapFinding.config.geoJsonFiles ?? {};
        let geoJsonSizes: { [key: string]: number } = {};
        let changed = false;
        for (let [key, item] of Object.entries(
            this.props.mapFinding.config.geoJsonFiles ?? {}
        )) {
            let prevItem = prevGeoJsonFiles[key];
            if (item == null && prevItem != null) {
                geoJsonSizes[key] = 0;
                changed = true;
            } else if (item != null && prevItem == null) {
                geoJsonSizes[key] = JSON.stringify(item).length;
                changed = true;
            }
        }
        if (changed) {
            this.setState((state) => ({
                geoJsonSizes: {
                    ...state.geoJsonSizes,
                    ...geoJsonSizes,
                },
            }));
        }

        // We are invalidating size of leaflet map if parent component is
        // rescaled or resized
        if (
            (this.props.width !== prevProps.width ||
                this.props.height !== prevProps.height) &&
            prevProps.scale === this.props.scale
        ) {
            if (this.state.mapRef != null) {
                let oldBounds = this.state.mapRef.getBounds();
                this.state.mapRef.invalidateSize();
                if (oldBounds != null) {
                    this.state.mapRef.fitBounds(oldBounds);
                }
            }
        }
        let minZoomLevel =
            this.props.mapFinding.config.colorOptions?.minZoomLevel ?? 0;
        if (this.state.mapRef != null) {
            this.state.mapRef.setMinZoom(minZoomLevel);
        }

        if (this.props.live !== prevProps.live) {
            let lockMap =
                this.props.mapFinding.config.colorOptions?.lockMap ?? false;
            lockMap = lockMap && this.props.live;
            if (this.clusterRef.current != null) {
                ((this.clusterRef.current as any)
                    .options as any).zoomToBoundsOnClick = !lockMap;
            }
            if (this.state.mapRef != null) {
                if (lockMap) {
                    this.state.mapRef.touchZoom.disable();
                    this.state.mapRef.doubleClickZoom.disable();
                    this.state.mapRef.boxZoom.disable();
                    this.state.mapRef.scrollWheelZoom.disable();
                    this.state.mapRef.dragging.disable();
                    this.state.mapRef.tap?.disable();
                    this.state.mapRef.keyboard.disable();
                    this.state.mapRef.zoomControl.remove();
                } else {
                    this.state.mapRef.touchZoom.enable();
                    this.state.mapRef.doubleClickZoom.enable();
                    this.state.mapRef.boxZoom.enable();
                    this.state.mapRef.scrollWheelZoom.enable();
                    this.state.mapRef.dragging.enable();
                    this.state.mapRef.tap?.enable();
                    this.state.mapRef.keyboard.enable();
                    if (
                        this.state.mapRef.zoomControl == null ||
                        (this.state.mapRef.zoomControl as any)._map == null
                    ) {
                        let zoomControl = new Leaflet.Control.Zoom();
                        // eslint-disable-next-line react/no-direct-mutation-state
                        this.state.mapRef.zoomControl = zoomControl;
                        this.state.mapRef.addControl(zoomControl);
                    }
                }
            }
        }
        if (
            this.state.mapRef != null &&
            (this.props.networkFinding ?? this.props.mapFinding).config
                .centerVariableIndex != null &&
            prevProps.centerVariableValue !== this.props.centerVariableValue
        ) {
            let coordinates = this.getCenterByValue();
            if (coordinates != null) {
                let zoom: number;
                if (this.props.mapFinding.type === "maps_choropleth") {
                    switch (
                        this.props.mapFinding.config.choroplethLevel ??
                        "country"
                    ) {
                        case "state_us":
                        case "country":
                            zoom = 5;
                            break;
                        default:
                            // "zipcode_us", "county_us"
                            zoom = 12;
                            break;
                    }
                } else {
                    zoom = 12;
                }
                const center = new LatLng(
                    Number(coordinates[0]),
                    Number(coordinates[1])
                );
                // Moveend would trigger an infinite loop here
                this.moveendDisabledRef.current = true;
                this.state.mapRef.setView(center, zoom);
                this.moveendDisabledRef.current = false;
                this.props.onChange({
                    ...this.props.mapFinding,
                    config: {
                        ...this.props.mapFinding.config,
                        zoom: zoom,
                        center: { lat: center.lat, lng: center.lng },
                    },
                });
            }
        }

        const { parentMap, additionalMaps } = this.identifyMaps(this.props);

        const prevMapsData = this.identifyMaps(prevProps);

        let maps = this.getSortedMapsList(parentMap, additionalMaps);
        let prevMaps = this.getSortedMapsList(
            prevMapsData.parentMap,
            prevMapsData.additionalMaps
        );
        for (let i = 0; i < maps.length; ++i) {
            let finding = maps[i]?.finding;
            let prevFinding = prevMaps[i]?.finding;
            if (
                finding.config.varyMarkerColorByVariable &&
                finding.config.markerColorVariableIndex != null &&
                finding.config.markerIcon == null &&
                finding.config.markerColorVariableIndex !==
                    prevFinding?.config?.markerColorVariableIndex
            ) {
                this.getColorVariableValues(finding, i);
            }
        }
        if (
            prevState.mapLevel !== this.state.mapLevel ||
            prevState.filterAdditionalMapVariableIndex !==
                this.state.filterAdditionalMapVariableIndex ||
            prevProps.mapFinding.content !== this.props.mapFinding.content ||
            prevProps.networkFinding?.content !==
                this.props.networkFinding?.content ||
            prevProps.mapFinding.additionalMapsFindings !==
                this.props.mapFinding.additionalMapsFindings ||
            prevProps.mapFinding.config !== this.props.mapFinding.config
        ) {
            this._setMapsDataState();
        }
    }

    /*!
     * function getColorVariableValues obtains values of this.props.mapElement.markerColorVariableIndex;
     * Called if this.props.mapElement.varyMarkerColorByVariable is true
     */
    private getColorVariableValues(finding: MapFinding, index: number): void {
        // NOTE this function assumes that varyMarkerColorByVariable will be enabled
        // for at most one layer

        axios
            .post<{
                success: boolean;
                error_msg: string;
                items?: (string | number | null)[];
            }>("/api/e/autocomplete", {
                variable_index: finding.config.markerColorVariableIndex,
                data_table_idx: finding.config.dataScope?.value,
                starts_with: "",
                module_id: this.props.currentModuleId ?? remoteModuleId,
            })
            .then((response) => {
                if (response.data.success && response.data.items != null) {
                    let colorVariableValueToIndex: Map<
                        string | number | null,
                        number
                    > = new Map();
                    let items = new Set(response.data.items).values();
                    let i = 0;
                    for (let item of items) {
                        colorVariableValueToIndex.set(item, i);
                        ++i;
                    }
                    this.setState((state) => {
                        return {
                            colorVariableValues: {
                                ...state.colorVariableValues,
                                [index]: response.data.items,
                            },
                            colorVariableValueToIndex: {
                                ...state.colorVariableValueToIndex,
                                [index]: colorVariableValueToIndex,
                            },
                        };
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /*!
     * function showPopup called if we clock some marker and
     * have variables displayed on click
     */
    private showPopup(index: number, tooltipMapLevel: number) {
        this.setState({
            popupMarkerIndex: index,
            tooltipMapLevel,
        });
    }

    private showChoroplethPopup(
        choroplethData: ChoroplethDataInterface,
        dataIndex: number,
        tooltipMapLevel: number
    ) {
        this.setState({
            choroplethData: choroplethData,
            popupMarkerIndex: dataIndex,
            tooltipMapLevel,
        });
    }
    /*!
     * function showHoverTooltip called if we hover some marker and
     * have variables displayed on hover
     */
    private showHoverTooltip(
        index: number | null,
        tooltipMapLevel: number,
        point?: { x: number; y: number }
    ) {
        if (index != null) {
            this.setState({
                hoverInfo: {
                    index: index,
                    x: point!.x,
                    y: point!.y,
                },
                tooltipMapLevel,
            });
        } else this.setState({ hoverInfo: null, tooltipMapLevel: 0 });
    }

    /*!
     * function showHoverTooltip called if we hover some marker and
     * have variables displayed on hover
     */
    private onMapCreated(mapInstance: Leaflet.Map): void {
        // This svg is required for d3 overlays
        Leaflet.svg().addTo(mapInstance);
        this.setState({ mapRef: mapInstance });
    }

    private identifyMaps(
        props: Props
    ): {
        parentMap: DashboardMap;
        additionalMaps: DashboardMap[];
    } {
        const parentMap = {
            id: 0,
            finding: props.networkFinding
                ? props.networkFinding
                : props.mapFinding,
        };

        let additionalMaps =
            parentMap.finding?.additionalMapsFindings?.map((finding, idx) => {
                return {
                    id: idx + 1,
                    finding,
                };
            }) ?? [];

        return {
            parentMap,
            additionalMaps,
        };
    }

    private getSortedMapsList(
        parentFinding: DashboardMap,
        additionalMapsFindings: DashboardMap[] | undefined
    ): DashboardMap[] {
        let maps: Array<DashboardMap> = [];

        maps = [parentFinding, ...(additionalMapsFindings ?? [])];
        // Put choropleth under all other maps
        const choroplethMapIdx = maps.findIndex(
            (item: any) => item.type === "maps_choropleth"
        );
        if (choroplethMapIdx > 0) {
            const choroplethMap = maps[choroplethMapIdx];
            maps = maps.filter((map) => map.finding.type !== "maps_choropleth");
            maps.unshift(choroplethMap);
        }

        return maps;
    }

    /*!
     * function buildInnerItem renders main view of component
     */
    private buildInnerItem(): JSX.Element | null {
        //If step is initial and map element is done
        //then we render map element
        let lockMap =
            this.props.mapFinding.config.colorOptions?.lockMap ?? false;
        lockMap = lockMap && this.props.live;
        let minZoomLevel =
            this.props.mapFinding.config.colorOptions?.minZoomLevel ?? 0;

        // To make the map less blurry on higher scales
        const mapViewScale = 1.5;
        const { parentMap, additionalMaps } = this.identifyMaps(this.props);

        // const maps = this.getSortedMapsList(parentMap, additionalMaps);
        const unsortedMaps = [parentMap, ...additionalMaps];

        let secondMap: DashboardMap | null = null;
        if (additionalMaps.length > 0) {
            secondMap = additionalMaps[0];
        }
        const tooltipActionButtonText =
            secondMap?.finding?.config?.tooltipActionButtonText || "ENHANCE";

        const GestureHandlingSetter = () => {
            const map = useMap() as any;
            map.gestureHandling.enable();
            map.addHandler("gestureHandling", GestureHandling);
            this.setState({
                initGestureHandling: false,
            });
            return null;
        };

        return (
            <div>
                <div
                    style={{
                        width: `calc((100% - 20px) / ${this.props.scale} * ${mapViewScale})`,
                        height: `calc((100% - 20px) / ${this.props.scale} * ${mapViewScale})`,
                        // If position is not absolute, then scaleY
                        // would consider the parent's height, which
                        // is NOT the desired behavior
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        transform: `scale(${this.props.scale / mapViewScale})`,
                        transformOrigin: "top left",
                    }}
                >
                    {this.state.updating && (
                        <div
                            style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                zIndex: 2,
                                backgroundColor: "rgba(0, 0, 0, .2)",
                            }}
                        >
                            <DualRing
                                color="#323232"
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            zIndex: 1,
                        }}
                    >
                        <MapContainer
                            worldCopyJump
                            minZoom={minZoomLevel}
                            tap={!lockMap}
                            keyboard={!lockMap}
                            touchZoom={!lockMap}
                            doubleClickZoom={!lockMap}
                            scrollWheelZoom={!lockMap}
                            boxZoom={!lockMap}
                            dragging={!lockMap}
                            zoomControl={!lockMap}
                            whenCreated={this.onMapCreated}
                            className="element-leaflet-map"
                            center={
                                this.props.mapFinding.config.center
                                    ? [
                                          this.props.mapFinding.config.center
                                              .lat,
                                          this.props.mapFinding.config.center
                                              .lng,
                                      ]
                                    : [0, 0]
                            }
                            zoom={this.props.mapFinding.config.zoom ?? 1}
                        >
                            {this.state.initGestureHandling && (
                                <GestureHandlingSetter />
                            )}
                            <MapListener
                                mapFinding={this.props.mapFinding}
                                onChange={this.props.onChange}
                                moveendDisabledRef={this.moveendDisabledRef}
                            />
                            <TileFilterLayer
                                attribution='&copy; <a href="http://osm.org/copyright" spellcheck="false">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                filter={
                                    // It should always use this.props.mapFinding.config.colorOptions
                                    // even if parentFinding is a NetworkFinding
                                    this.props.mapFinding.config.colorOptions
                                        ?.grayscale
                                        ? ["grayscale:100%", "invert: 100%"]
                                        : []
                                }
                            />
                            {this.state.mapsData?.length !== 0 &&
                                this.state.mapsData?.map((mapData: any) => {
                                    let map: DashboardMap = mapData.map;

                                    const index = unsortedMaps.findIndex(
                                        (data) => data.id === map.id
                                    );
                                    const finding = unsortedMaps[index].finding;
                                    let additionalMap =
                                        index !== 0
                                            ? finding
                                            : unsortedMaps[1]?.finding;
                                    const {
                                        findingData,
                                        findingHeatMapData,
                                    } = filterAdditionalMapData(
                                        finding,
                                        this.state
                                            .filterAdditionalMapVariableIndex,
                                        finding?.config
                                            .filterAdditionalMapVariable
                                            ?.value ?? null
                                    );

                                    let isShown = true;
                                    if (
                                        additionalMap?.config
                                            .isAdditionalMapHiddenBehindMenu &&
                                        this.state.mapLevel !== index
                                    ) {
                                        isShown = false;
                                    } else if (
                                        !additionalMap?.config
                                            .isAdditionalMapHiddenBehindMenu &&
                                        !this.state.mainMapShown
                                    ) {
                                        isShown = false;
                                    } else {
                                        isShown = true;
                                    }

                                    if (
                                        index === 0 &&
                                        !this.state.mainMapShown
                                    ) {
                                        isShown = false;
                                    }

                                    return (
                                        <>
                                            {isShown && (
                                                <>
                                                    {finding.type ===
                                                        "maps_heatmap" && (
                                                        <HeatMap
                                                            data={
                                                                findingHeatMapData ??
                                                                []
                                                            }
                                                            heatMapTime={
                                                                finding.content
                                                                    .heatMapTime
                                                            }
                                                            timeVariableIndex={
                                                                finding.config
                                                                    .timeVariableIndex
                                                            }
                                                            currentTimeValue={
                                                                finding.content
                                                                    .time?.[
                                                                    this.state
                                                                        .timeIndex
                                                                ]
                                                            }
                                                            findingType={
                                                                finding.type
                                                            }
                                                            gradient={
                                                                finding.config
                                                                    .heatmapGradient ||
                                                                defaultHeatmapGradient
                                                            }
                                                            minOpacity={
                                                                finding.config
                                                                    .heatmapMinOpacity ??
                                                                defaultHeatmapMinOpacity
                                                            }
                                                        />
                                                    )}
                                                    {Object.values(
                                                        finding.config
                                                            .geoJsonFiles ?? {}
                                                    )
                                                        .filter(
                                                            (
                                                                item
                                                            ): item is {
                                                                name: string;
                                                                contents: GeoJsonObject;
                                                                color?: string;
                                                            } => item != null
                                                        )
                                                        .map(
                                                            (
                                                                {
                                                                    contents,
                                                                    color,
                                                                },
                                                                index
                                                            ) => {
                                                                return (
                                                                    <GeoJSON
                                                                        key={`map-geojson-${index}`}
                                                                        data={
                                                                            contents
                                                                        }
                                                                        style={{
                                                                            fillColor:
                                                                                color ??
                                                                                colorList[
                                                                                    index %
                                                                                        colorList.length
                                                                                ],
                                                                            fillOpacity: 0.4,
                                                                            color:
                                                                                color ??
                                                                                colorList[
                                                                                    index %
                                                                                        colorList.length
                                                                                ],
                                                                            opacity: 1,
                                                                            weight: 2,
                                                                        }}
                                                                        pointToLayer={(
                                                                            feature,
                                                                            latlng
                                                                        ) => {
                                                                            return Leaflet.marker(
                                                                                latlng,
                                                                                {
                                                                                    icon: markerIconWithSymbol(
                                                                                        finding
                                                                                            .config
                                                                                            .markerIcon,
                                                                                        feature
                                                                                            .properties[
                                                                                            "marker-color"
                                                                                        ] ??
                                                                                            color ??
                                                                                            colorList[
                                                                                                index %
                                                                                                    colorList.length
                                                                                            ],
                                                                                        feature
                                                                                            .properties[
                                                                                            "marker-symbol"
                                                                                        ]
                                                                                    ),
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    <MarkerClusterGroupWrapper
                                                        coordinates={
                                                            finding.config
                                                                .coordinates
                                                        }
                                                        zoomLevel={
                                                            this.props
                                                                .mapFinding
                                                                .config.zoom
                                                        }
                                                        flags={
                                                            finding.config.flags
                                                        }
                                                        hoverInfo={
                                                            this.state.hoverInfo
                                                        }
                                                        usesCoordinates={
                                                            finding.config
                                                                .usesCoordinates
                                                        }
                                                        displayMode={
                                                            finding.config
                                                                .displayMode
                                                        }
                                                        tooltipVariables={
                                                            finding.config
                                                                .tooltipVariables
                                                        }
                                                        markerIcon={
                                                            finding.config
                                                                .markerIcon
                                                        }
                                                        markerLogoIcon={
                                                            finding.config
                                                                .markerLogoIcon
                                                        }
                                                        markerColor={
                                                            finding.config
                                                                .markerColor
                                                        }
                                                        markerClusterColor={
                                                            finding.config
                                                                .markerClusterColor
                                                        }
                                                        markerBorderColor={
                                                            finding.config
                                                                .markerBorderColor
                                                        }
                                                        markerColorVariableIndex={
                                                            finding.config
                                                                .markerColorVariableIndex
                                                        }
                                                        varyMarkerColorByVariable={
                                                            finding.config
                                                                .varyMarkerColorByVariable
                                                        }
                                                        markerValueToColor={
                                                            finding.config
                                                                .markerValueToColor
                                                        }
                                                        timeVariableIndex={
                                                            finding.config
                                                                .timeVariableIndex
                                                        }
                                                        currentTimeValue={
                                                            finding.content
                                                                .time?.[
                                                                this.state
                                                                    .timeIndex
                                                            ]
                                                        }
                                                        heatMap={
                                                            finding.config
                                                                .heatMap
                                                        }
                                                        scale={mapViewScale}
                                                        data={findingData ?? {}}
                                                        colorVariableValueToIndex={
                                                            this.state
                                                                .colorVariableValueToIndex?.[
                                                                index
                                                            ]
                                                        }
                                                        onShowPopup={
                                                            this.showPopup
                                                        }
                                                        onShowHoverTooltip={
                                                            this
                                                                .showHoverTooltip
                                                        }
                                                        ref={this.clusterRef}
                                                        lockMap={lockMap}
                                                        minZoomLevel={
                                                            minZoomLevel
                                                        }
                                                        mapId={this.props.mapId}
                                                        findingType={
                                                            finding.type
                                                        }
                                                        tooltipMapLevel={index}
                                                    />
                                                </>
                                            )}
                                        </>
                                    );
                                })}
                        </MapContainer>
                        {(this.state.popupMarkerIndex != null ||
                            this.state.choroplethData != null) && (
                            <OutsideAlerter
                                onReject={() => {
                                    this.setState({
                                        popupMarkerIndex: null,
                                        choroplethData: null,
                                    });
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: 1000,
                                        opacity: 0.9,
                                        boxShadow: "0 1px 3px rgba(0,0,0,.4)",
                                        backgroundColor:
                                            unsortedMaps[
                                                this.state.tooltipMapLevel
                                            ].finding.config.tooltipOptions
                                                ?.fillColor ?? "white",
                                        userSelect: "none",
                                        borderRadius:
                                            this.props.mapFinding.config
                                                .tooltipBorderRadius ?? 0,
                                    }}
                                    onDrag={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDragStart={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDragEnd={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <TooltipContent
                                        options={
                                            this.props.mapFinding.config
                                                .tooltipOptions
                                        }
                                        choroplethData={
                                            this.state.choroplethData
                                        }
                                        scale={mapViewScale}
                                        onChange={this.props.onChange}
                                        mapFinding={
                                            unsortedMaps[
                                                this.state.tooltipMapLevel
                                            ].finding
                                        }
                                        dataIndex={this.state.popupMarkerIndex}
                                        data={
                                            unsortedMaps[
                                                this.state.tooltipMapLevel
                                            ].finding?.content.data
                                        }
                                        variables={
                                            this.state.choroplethData != null
                                                ? choroplethVariablesToTooltipVariables(
                                                      unsortedMaps[
                                                          this.state
                                                              .tooltipMapLevel
                                                      ].finding
                                                  )
                                                : unsortedMaps[
                                                      this.state.tooltipMapLevel
                                                  ].finding?.config
                                                      .tooltipVariables
                                        }
                                        mapId={this.props.mapId}
                                        actionButton={{
                                            isShown: additionalMaps.find(
                                                (map) =>
                                                    map.finding.config
                                                        .isAdditionalMapHiddenBehindMenu ===
                                                    true
                                            )?.finding?.config
                                                .isAdditionalMapHiddenBehindMenu,
                                            onClick: (dataIndex, mapLevel) => {
                                                this.setState({
                                                    mainMapShown: !this.state
                                                        .mainMapShown,
                                                    popupMarkerIndex: null,
                                                    choroplethData: null,
                                                    mapLevel,
                                                    filterAdditionalMapVariableIndex: dataIndex,
                                                });
                                                if (this.state.mainMapShown) {
                                                    const lat = (this.props
                                                        .mapFinding?.content
                                                        .data ?? {})["0"]?.[
                                                        dataIndex
                                                    ];
                                                    const lng = (this.props
                                                        .mapFinding?.content
                                                        .data ?? {})["1"]?.[
                                                        dataIndex
                                                    ];

                                                    if (!lat || !lng) return;

                                                    this.state?.mapRef?.setView(
                                                        new LatLng(
                                                            Number(lat),
                                                            Number(lng)
                                                        ),
                                                        12
                                                    );
                                                }
                                            },
                                            buttonText: this.state.mainMapShown
                                                ? tooltipActionButtonText
                                                : "BACK",
                                        }}
                                        maps={unsortedMaps}
                                        hiddenMaps={
                                            additionalMaps.filter(
                                                (map) =>
                                                    map.finding.config
                                                        .isAdditionalMapHiddenBehindMenu
                                            ) ?? []
                                        }
                                        isParentFinding={
                                            (
                                                (unsortedMaps[
                                                    this.state.tooltipMapLevel
                                                ].finding as
                                                    | MapFinding
                                                    | NetworkFinding)
                                                    ?.additionalMapsFindings ??
                                                []
                                            ).length > 0
                                        }
                                    />
                                </div>
                            </OutsideAlerter>
                        )}
                        {this.state.hoverInfo != null && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: this.state.hoverInfo.y,
                                    left: this.state.hoverInfo.x,
                                    zIndex: 1000,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onDrag={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onDragStart={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onDragEnd={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <div
                                    style={{
                                        opacity: 0.9,
                                        boxShadow: "0 1px 3px rgba(0,0,0,.4)",
                                        padding: "6px",
                                        borderRadius: "3px",
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        borderColor: "white",
                                        backgroundColor:
                                            this.props.mapFinding.config
                                                .tooltipOptions ?? "white",
                                        userSelect: "none",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDrag={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDragStart={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDragEnd={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <TooltipContent
                                        options={
                                            this.props.mapFinding.config
                                                .tooltipOptions
                                        }
                                        scale={mapViewScale}
                                        onChange={this.props.onChange}
                                        mapFinding={
                                            unsortedMaps[
                                                this.state.tooltipMapLevel
                                            ].finding
                                        }
                                        dataIndex={this.state.hoverInfo.index}
                                        data={
                                            unsortedMaps[
                                                this.state.tooltipMapLevel
                                            ].finding.content.data
                                        }
                                        variables={
                                            this.state.choroplethData != null
                                                ? choroplethVariablesToTooltipVariables(
                                                      unsortedMaps[
                                                          this.state
                                                              .tooltipMapLevel
                                                      ].finding
                                                  )
                                                : unsortedMaps[
                                                      this.state.tooltipMapLevel
                                                  ].finding.config
                                                      .tooltipVariables
                                        }
                                        mapId={this.props.mapId}
                                        actionButton={{
                                            isShown: additionalMaps.find(
                                                (map) =>
                                                    map.finding.config
                                                        .isAdditionalMapHiddenBehindMenu ===
                                                    true
                                            )?.finding?.config
                                                .isAdditionalMapHiddenBehindMenu,
                                            onClick: (dataIndex) => {
                                                this.setState({
                                                    mainMapShown: !this.state
                                                        .mainMapShown,
                                                    popupMarkerIndex: null,
                                                    choroplethData: null,
                                                    filterAdditionalMapVariableIndex: dataIndex,
                                                });
                                                if (this.state.mainMapShown) {
                                                    const lat = (this.props
                                                        .mapFinding?.content
                                                        .data ?? {})["0"]?.[
                                                        dataIndex
                                                    ];
                                                    const lng = (this.props
                                                        .mapFinding?.content
                                                        .data ?? {})["1"]?.[
                                                        dataIndex
                                                    ];

                                                    if (!lat || !lng) return;

                                                    this.state?.mapRef?.setView(
                                                        new LatLng(
                                                            Number(lat),
                                                            Number(lng)
                                                        ),
                                                        12
                                                    );
                                                }
                                            },
                                            buttonText: this.state.mainMapShown
                                                ? tooltipActionButtonText
                                                : "BACK",
                                        }}
                                        maps={unsortedMaps}
                                        hiddenMaps={
                                            additionalMaps.filter(
                                                (map) =>
                                                    map.finding.config
                                                        .isAdditionalMapHiddenBehindMenu
                                            ) ?? []
                                        }
                                        isParentFinding={
                                            (
                                                (unsortedMaps[
                                                    this.state.tooltipMapLevel
                                                ].finding as
                                                    | MapFinding
                                                    | NetworkFinding)
                                                    ?.additionalMapsFindings ??
                                                []
                                            ).length > 0
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {this.state.mapsData?.length !== 0 &&
                            this.state.mapsData?.map(
                                (mapData, index) =>
                                    !mapData.map.finding.config
                                        .hideVaryMarkerColorLegend &&
                                    mapData.map.finding.config
                                        .varyMarkerColorByVariable &&
                                    mapData.map.finding.config
                                        .markerColorVariableIndex != null &&
                                    mapData.map.finding.config.markerIcon ==
                                        null &&
                                    this.state.colorVariableValues[index] !=
                                        null &&
                                    this.state.colorVariableValues[index]
                                        .length > 0 && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "0px",
                                                left: -index * 120,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "flex-start",
                                                width: "100%",
                                                height: "100%",
                                                zIndex: 999,
                                                pointerEvents: "none",
                                            }}
                                        >
                                            <div
                                                className="element-leaflet-map"
                                                style={{
                                                    marginTop: "30px",
                                                    marginRight: "30px",
                                                    maxWidth: "120px",
                                                    maxHeight:
                                                        "calc(100% - 47px)",
                                                    opacity: 0.9,
                                                    boxShadow:
                                                        "0 1px 3px rgba(0,0,0,.4)",
                                                    padding: "6px",
                                                    borderRadius: "3px",
                                                    borderWidth: "1px",
                                                    borderStyle: "solid",
                                                    borderColor: "white",
                                                    backgroundColor: "white",
                                                    userSelect: "none",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    overflowY: "auto",
                                                    pointerEvents: "auto",
                                                }}
                                            >
                                                {this.state.colorVariableValues[
                                                    index
                                                ].map((value, colorIndex) => (
                                                    <div
                                                        key={colorIndex}
                                                        style={{
                                                            display: "flex",
                                                            marginTop:
                                                                index !== 0
                                                                    ? "5px"
                                                                    : undefined,
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "12px",
                                                                height: "12px",
                                                                minWidth:
                                                                    "12px",
                                                                minHeight:
                                                                    "12px",
                                                                backgroundColor:
                                                                    (
                                                                        this
                                                                            .state
                                                                            .mapsData &&
                                                                        this
                                                                            .state
                                                                            .mapsData[
                                                                            index
                                                                        ].map
                                                                            .finding
                                                                            .config
                                                                    )
                                                                        ?.markerValueToColor?.[
                                                                        value ??
                                                                            ""
                                                                    ] ??
                                                                    colorList[
                                                                        colorIndex %
                                                                            colorList.length
                                                                    ],
                                                                borderRadius:
                                                                    "6px",
                                                            }}
                                                        />
                                                        <span
                                                            className="regular-text"
                                                            style={{
                                                                marginLeft:
                                                                    "5px",
                                                                color: "black",
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            {value}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                            )}
                        {this.props.mapFinding.content.time != null && (
                            <AnimationSlider
                                style={{
                                    position: "absolute",
                                    left: 60,
                                    top: 11,
                                    pointerEvents: "auto",
                                    zIndex: 999,
                                    backgroundColor: "white",
                                    borderRadius: "3px",
                                    opacity: 0.9,
                                    boxShadow: "0 1px 3px rgba(0,0,0,.4)",
                                    padding: "4px",
                                }}
                                sliderStyle={{
                                    cursor: "default",
                                    width: 165,
                                    pointerEvents: "auto",
                                }}
                                values={this.props.mapFinding.content.time}
                                onChange={(index) => {
                                    this.setState({ timeIndex: index });
                                }}
                                onChangeConfig={(config) => {
                                    this.props.onChange({
                                        ...this.props.mapFinding,
                                        config: {
                                            ...this.props.mapFinding.config,
                                            ...config,
                                        },
                                    });
                                }}
                                config={this.props.mapFinding.config}
                            />
                        )}
                        {this.state.mapsData?.length !== 0 &&
                            this.state.mapsData?.map((mapData: MapData) => {
                                let additionalMap =
                                    mapData.index !== 0
                                        ? mapData.finding
                                        : unsortedMaps[1]?.finding;

                                let isShown = true;
                                if (
                                    additionalMap?.config
                                        .isAdditionalMapHiddenBehindMenu &&
                                    this.state.mapLevel !== mapData.index
                                ) {
                                    isShown = false;
                                } else if (
                                    !additionalMap?.config
                                        .isAdditionalMapHiddenBehindMenu &&
                                    !this.state.mainMapShown
                                ) {
                                    isShown = false;
                                } else {
                                    isShown = true;
                                }

                                if (
                                    mapData.index === 0 &&
                                    !this.state.mainMapShown
                                ) {
                                    isShown = false;
                                }

                                // Backward comp... because earlier we have just min, middle and max colors instead gradient option
                                const choroplethGradient = mapData.finding
                                    .config?.heatmapGradient ?? [
                                    {
                                        label: "min",
                                        value: 0,
                                        color:
                                            mapData.finding.config
                                                .choroplethMinColor ??
                                            defaultChoroplethMinColor,
                                    },
                                    {
                                        label: "middle",
                                        value: 0.5,
                                        color:
                                            mapData.finding.config
                                                .choroplethMiddleColor ??
                                            defaultChoroplethMiddleColor,
                                    },
                                    {
                                        label: "max",
                                        value: 1,
                                        color:
                                            mapData.finding.config
                                                .choroplethMaxColor ??
                                            defaultChoroplethMaxColor,
                                    },
                                ];

                                return (
                                    <>
                                        {isShown && (
                                            <>
                                                {mapData.finding.type ===
                                                    "maps_networkovermap" &&
                                                    this.state.mapRef !=
                                                        null && (
                                                        <>
                                                            <Network
                                                                networkId={
                                                                    this.props
                                                                        .mapId
                                                                }
                                                                edgeList={
                                                                    mapData.findingData
                                                                }
                                                                attachNodeColorToTarget={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .attachNodeColorToTarget
                                                                }
                                                                edgeColor={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .edgeColor
                                                                }
                                                                nodeColor={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .nodeColor
                                                                }
                                                                baseEdgeThickness={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .baseEdgeThickness
                                                                }
                                                                dragActive={
                                                                    true
                                                                }
                                                                nodePlacement="map"
                                                                nodeLabelsDisplayMode={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .nodeLabelsDisplayMode
                                                                }
                                                                nodeLabelsDisplayPosition={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .nodeLabelsDisplayPosition
                                                                }
                                                                mapRef={
                                                                    this.state
                                                                        .mapRef
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                {mapData.finding.type ===
                                                    "maps_bubble" &&
                                                    this.state.mapRef !=
                                                        null && (
                                                        <>
                                                            <BubbleMap
                                                                markerValueToColor={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .markerValueToColor
                                                                }
                                                                heatmapData={
                                                                    mapData.findingHeatMapData ??
                                                                    []
                                                                }
                                                                data={
                                                                    mapData.findingData ??
                                                                    {}
                                                                }
                                                                heatMapTime={
                                                                    mapData
                                                                        .finding
                                                                        .content
                                                                        .heatMapTime
                                                                }
                                                                currentTimeValue={
                                                                    mapData
                                                                        .finding
                                                                        .content
                                                                        .time?.[
                                                                        this
                                                                            .state
                                                                            .timeIndex
                                                                    ]
                                                                }
                                                                colorVariableValueToIndex={
                                                                    this.state
                                                                        .colorVariableValueToIndex[
                                                                        mapData
                                                                            .index
                                                                    ]
                                                                }
                                                                markerColorVariableIndex={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .markerColorVariableIndex
                                                                }
                                                                map={
                                                                    this.state
                                                                        .mapRef
                                                                }
                                                                markerColor={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .markerColor ??
                                                                    "red"
                                                                }
                                                                varyMarkerColorByVariable={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .varyMarkerColorByVariable
                                                                }
                                                                level={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .choroplethLevel ??
                                                                    "country"
                                                                }
                                                                showBoundaries={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        ?.showBoundaries ??
                                                                    false
                                                                }
                                                                boundariesColor={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        ?.boundariesColor ??
                                                                    defaultBubbleBoundariesColor
                                                                }
                                                                bounderiesIdx={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        ?.choroplethCountryVariableIndex
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                {mapData.finding.type ===
                                                    "maps_choropleth" &&
                                                    this.state.mapRef !=
                                                        null && (
                                                        <>
                                                            <ChoroplethMap
                                                                handleLoaded={
                                                                    this
                                                                        .handleLoaded
                                                                }
                                                                ref={
                                                                    mapData.index ===
                                                                    0
                                                                        ? this
                                                                              .rootChoroplethRef
                                                                        : undefined
                                                                }
                                                                onShowChoroplethPopup={
                                                                    this
                                                                        .showChoroplethPopup
                                                                }
                                                                data={
                                                                    mapData.findingChoroplethData ??
                                                                    []
                                                                }
                                                                currentTimeValue={
                                                                    mapData
                                                                        .finding
                                                                        .content
                                                                        .time?.[
                                                                        this
                                                                            .state
                                                                            .timeIndex
                                                                    ]
                                                                }
                                                                map={
                                                                    this.state
                                                                        .mapRef
                                                                }
                                                                gradient={
                                                                    choroplethGradient
                                                                }
                                                                opacity={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .choroplethOpacity
                                                                }
                                                                showBorders={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .choroplethShowBorders
                                                                }
                                                                level={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .choroplethLevel ??
                                                                    "country"
                                                                }
                                                                clickedTileData={
                                                                    this.state
                                                                        .choroplethData
                                                                }
                                                                maps={
                                                                    unsortedMaps
                                                                }
                                                                tooltipMapLevel={
                                                                    mapData.index
                                                                }
                                                                choroplethMetricVariableOptions={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .choroplethMetricVariableOptions
                                                                }
                                                                choroplethBorder={
                                                                    mapData
                                                                        .finding
                                                                        .config
                                                                        .choroplethBorder
                                                                }
                                                            />
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    </>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }

    private buildContent(): JSX.Element {
        return (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <div style={{ width: "100%" }}>{this.buildInnerItem()}</div>
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <>
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                    {this.buildContent()}
                </div>
            </>
        );
    }
}
