import React from "react";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { Button } from "react-bootstrap";
import UserInfo from "common/UserInfo";

interface StringOption {
    label: string;
    value: string;
}

interface Props {
    users: (string | null)[];
    neighborsUsers: UserInfo[];
    onChange: (users: (string | null)[]) => void;
}

export default class UsersSelector extends React.Component<Props> {
    static getUserOptions(users: UserInfo[]) {
        return users.map((user) => ({
            label: user.user_name,
            value: user.user_name,
        }));
    }

    render() {
        return (
            <div className="flex-simple-column" style={{ marginLeft: "5px" }}>
                {this.props.users.map((userName, userIndex) => (
                    <div key={userIndex} className="my-row" style={{ marginTop: "2px" }}>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Add user"}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    width: "120px",
                                    marginLeft: "5px",
                                }),
                            }}
                            options={UsersSelector.getUserOptions(
                                this.props.neighborsUsers
                            )}
                            onChange={(newValue) => {
                                let users = Array.from(this.props.users);
                                users[
                                    userIndex
                                ] = (newValue as StringOption).value;
                                this.props.onChange(users);
                            }}
                            value={UsersSelector.getUserOptions(
                                this.props.neighborsUsers
                            ).find((user) => user.value === userName) ?? null}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />

                        <div
                            className="flex-simple-column"
                            style={{ marginLeft: 5 }}
                        >
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let users = Array.from(this.props.users);
                                    users.push(null);

                                    this.props.onChange(users);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let users = Array.from(this.props.users);
                                    users.splice(userIndex, 1);

                                    if (users.length === 0) {
                                        users.push(null);
                                    }

                                    this.props.onChange(users);
                                }}
                            >
                                {"\uFF0D" /* minus */}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}