import "common/styles/App.css";
import { Component, useContext } from "react";
import { observer } from "mobx-react";
import ViewMode from "common/home_components/PresentationTabs/ViewMode";
import Toolbar from "common/home_components/Toolbar";
import TabContent from "common/home_components/PresentationTabs/TabContent";
import Instrumentation from "common/Instrumentation";
import { getWidth, getHeight } from "common/utilities/UIResponsiveManager";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import StoreContext, { store } from "state/StoreContext";
import RootStore from "state/store";
import { goToInternalLink } from "common/InternalLinksHelper";
import MessagePopup from "common/MessagePopup";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { unhideUserModuleApi } from "common/ModulesApi";
import Presentation from "state/models/Presentation";
import ModuleCard from "common/home_components/ModuleCard";
import Popup from "reactjs-popup";
import styles from "./tutorials_page.module.css";
import { FolderMenu } from "common/home_components/FolderMenu";
import { Folder, FolderType } from "common/FoldersApi";
import FolderCreator from "common/home_components/FolderCreator";

interface Props {
    store: RootStore;
    globalContext: GlobalContextContents;
}

interface State {
    viewMode: ViewMode;
    innerWidth: number;
    innerHeight: number;
    popupWidth: number;
    popupHeight: number;
    errorInfo:
    | {
        status: PopupStatus;
        message: string;
    }
    | undefined;
    deleteInfo:
    | {
        id: number;
    }
    | undefined;
    currentTutorial: Presentation | null;
    newFolderType: FolderType | null;
    editFolderId: number | null;
    editFolderTitle: string | null;
    deleteFolder: Folder | null;
    status: {
        status: PopupStatus;
        message: string;
    } | null;
}

function defaultState() {
    return {
        innerWidth: getWidth(),
        innerHeight: getHeight(),
        popupHeight: 0,
        popupWidth: 0,
        deleteInfo: undefined,
        errorInfo: undefined,
        viewMode: ViewMode.Grid,
        currentTutorial: null,
        newFolderType: null,
        editFolderId: null,
        editFolderTitle: null,
        deleteFolder: null,
        status: null,
    };
}

const MainComponent = observer(
    class MainComponent extends Component<Props, State> {
        performance: Date | null;
        mounted: boolean;
        tutorialId: number | undefined;
        constructor(props: Props) {
            super(props);
            this.state = defaultState();
            this.updateDimensions = this.updateDimensions.bind(this);
            this.performance = null;
            this.mounted = false;
            const urlParams = new URLSearchParams(window.location.search);
            let stringTutorialId = urlParams.get("tutorial_id");
            let tutorialId = stringTutorialId
                ? Number(stringTutorialId)
                : undefined;
            this.tutorialId = tutorialId;
        }
        popupHeight() {
            return this.state.innerHeight * 0.7;
        }
        updateDimensions() {
            let innerWidth = getWidth();
            let innerHeight = getHeight();
            this.setState({
                innerWidth: innerWidth,
                innerHeight: innerHeight,
            });
        }
        popupWidth() {
            return this.state.innerWidth * 0.7;
        }

        componentDidMount() {
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("Tutorials", timeMs);
            }
            this.updateDimensions();
            window.addEventListener("resize", this.updateDimensions);
            store.presentationStore.fetchTutorials();
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.updateDimensions);
        }

        componentDidUpdate() {
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("Tutorials", timeMs);
            }
            if (this.tutorialId != null) {
                let tutorial = store.presentationStore.tutorials.find(
                    (tutorial) => tutorial.id === this.tutorialId
                );
                if (tutorial != null) {
                    this.tutorialId = undefined;
                    this.setState({ currentTutorial: tutorial });
                }
            }
        }

        searchPresentations = (searchText: string) => {
            const { store } = this.props;
            store.presentationStore.searchPresentations(searchText);
        };

        createPresentation = async () => {
            const presentationId =
                await store.presentationStore.createBlankPresentation();
            goToInternalLink(`canvas.html?current_module_id=${presentationId}`);
        };

        openPresentation = (presentationId: number) => {
            unhideUserModuleApi(presentationId)
                .then((id) => {
                    goToInternalLink(`canvas.html?current_module_id=${id}`);
                })
                .catch((error) => {
                    this.setState({
                        errorInfo: {
                            status: PopupStatus.Error,
                            message: `Error: ${String(error)}`,
                        },
                    });
                });
        };

        changeViewMode = (viewMode: ViewMode) => {
            this.setState({ viewMode });
        };

        render() {
            if (!this.mounted) {
                this.performance = new Date();
                this.mounted = true;
            }

            const { viewMode } = this.state;

            return (
                <div className="content-wrapper">
                    <div className={styles.topBarsContainer}>
                        <div className={styles.content}>
                            <Toolbar
                                title="tutorials"
                                onChangeSearchText={this.searchPresentations}
                                createPresentation={this.createPresentation}
                            />
                            <div className={styles.content}>
                                <section>
                                    <FolderMenu
                                        menuId="tutorials"
                                        onCreateFolder={() => {
                                            this.setState({
                                                newFolderType: FolderType.Tutorials,
                                            });
                                        }}
                                    />
                                    <TabContent
                                        currentFolder={
                                            store.presentationStore.currentFolder ??
                                            undefined
                                        }
                                        folders={
                                            store.presentationStore.tutorialFolders
                                        }
                                        clickPresentation={(id: number) => {
                                            let tutorial =
                                                store.presentationStore.tutorials.find(
                                                    (tutorial) => tutorial.id === id
                                                );
                                            if (tutorial != null)
                                                this.setState({
                                                    currentTutorial: tutorial,
                                                });
                                        }}
                                        presentations={
                                            store.presentationStore.tutorials
                                        }
                                        loading={
                                            store.presentationStore
                                                .fetchingTutorials
                                        }
                                        deletePresentation={(id: number) => {
                                            this.setState({
                                                deleteInfo: {
                                                    id: id,
                                                },
                                            });
                                        }}
                                        renameFolder={(folder) => {
                                            this.setState({
                                                editFolderTitle: folder.title,
                                                newFolderType: folder.type,
                                                editFolderId: folder.id,
                                            });
                                        }}
                                        deleteFolder={(folder) => {
                                            this.setState({ deleteFolder: folder });
                                        }}
                                        viewMode={viewMode}
                                    />
                                </section>
                                <Popup
                                    arrow={true}
                                    contentStyle={{
                                        width: this.popupWidth(),
                                        maxWidth: this.popupWidth(),
                                        top: 20,
                                        minHeight: this.popupHeight(),
                                        border: "none",
                                        backgroundColor: "transparent",
                                    }}
                                    open={this.state.currentTutorial != null}
                                    onClose={() => {
                                        this.setState({ currentTutorial: null });
                                    }}
                                    closeOnDocumentClick
                                >
                                    <div
                                        className="dashboard-rect element"
                                        style={{
                                            minHeight: "100%",
                                            width: this.popupWidth(),
                                        }}
                                    >
                                        {this.state.currentTutorial ? (
                                            <ModuleCard
                                                sectionName={"Tutorials"}
                                                parentContainerHeight={this.popupHeight()}
                                                parentContainerWidth={this.popupWidth()}
                                                module={this.state.currentTutorial}
                                            />
                                        ) : null}
                                    </div>
                                </Popup>
                                {this.state.newFolderType != null && (
                                    <FolderCreator
                                        type={this.state.newFolderType}
                                        title={
                                            this.state.editFolderTitle ?? null
                                        }
                                        editFolderId={
                                            this.state.editFolderId ?? null
                                        }
                                        onSave={(newFolderId) => {
                                            this.setState({
                                                newFolderType: null,
                                                editFolderId: null,
                                                editFolderTitle: null,
                                                status: {
                                                    status: PopupStatus.Success,
                                                    message:
                                                        newFolderId != null
                                                            ? "Folder is created"
                                                            : "Folder is edited",
                                                },
                                            });
                                        }}
                                        onClose={() => {
                                            this.setState({
                                                newFolderType: null,
                                                editFolderId: null,
                                                editFolderTitle: null,
                                            });
                                        }}
                                        onError={(errorMessage) => {
                                            this.setState({
                                                status: {
                                                    status: PopupStatus.Error,
                                                    message: errorMessage,
                                                },
                                            });
                                        }}
                                    />
                                )}
                                {this.state.status && (
                                    <StatusPopup
                                        status={this.state.status.status}
                                        message={this.state.status.message}
                                        onClose={() => {
                                            this.setState({ status: null });
                                        }}
                                    />
                                )}
                                {this.state.deleteFolder && (
                                    <MessagePopup
                                        title={"Delete folder"}
                                        message={
                                            "Are you want to delete folder (data will be kept)?"
                                        }
                                        acceptButtonTitle={"Delete"}
                                        onAccept={() => {
                                            this.performance = new Date();
                                            store.presentationStore.deleteFolder(
                                                this.state.deleteFolder!
                                            );
                                            this.setState({
                                                deleteFolder: null,
                                            });
                                        }}
                                        onReject={() => {
                                            this.setState({
                                                deleteFolder: null,
                                            });
                                        }}
                                    />
                                )}
                                {this.state.deleteInfo != null && (
                                    <MessagePopup
                                        danger
                                        title={"Remove tutorial"}
                                        message={`Are you sure you want to remove this tutorial?`}
                                        acceptButtonTitle={"Remove"}
                                        onAccept={() => {
                                            this.performance = new Date();
                                            store.presentationStore.deletePresentationFromTutorials(
                                                this.state.deleteInfo!.id
                                            );
                                            this.setState({
                                                deleteInfo: undefined,
                                            });
                                        }}
                                        onReject={() => {
                                            this.setState({
                                                deleteInfo: undefined,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
);

const ConnectedComponent = () => {
    const store = useContext(StoreContext);
    const globalContext = useContext(GlobalContext);

    return <MainComponent store={store} globalContext={globalContext} />;
};

export { ConnectedComponent as MainComponent };
export let route = "/tutorials.html",
    section = "Tutorials",
    leftPanelItem = {
        section: "Tutorials",
        href: "tutorials.html",
        icon: "Home",
    },
    requirePermission = "Modules";
