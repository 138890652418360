import React from "react";
import styles from "./connectCard.module.css";
import cx from "classnames";


interface IConnectCard {
    enabled: boolean;
    icon: any;
    title: string;
    subtitle?: string;
    footerText?: string;
    connected: boolean;
    onClick?: () => void;
 }

const ConnectCard: React.FC<IConnectCard> = ({
    enabled,
    icon,
    title,
    subtitle,
    connected,
    footerText,
    onClick
}) => {

    const renderFooter = () => {
        if(footerText) {
            return(
                <div className={styles.centralize}>
                    <p className={styles.text}>{footerText}</p>
                </div>
            )
        } else {
            if(connected) {
                return (
                    <>
                        <div className={cx(styles.statusCircle, { [styles.active]: connected })}></div>
                        <p className={styles.text}>Connected</p>
                    </>
                )
            } else {
                return (
                    <>
                <div className={cx(styles.statusCircle, { [styles.active]: connected })}></div>
                <p className={styles.text}>Not Connected</p>
            </>
                )
            }    
        }
    }
    return (
        <div 
            className={cx({ [styles.card]: enabled, [styles.disabled]: !enabled })}
            onClick={onClick}
        >
            <div className={cx({ [styles.overlay]: !enabled })}></div>
            {React.createElement(icon, { width: 65, height: 65 })}
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitle}</p>

            <div className={cx(styles.footer, {[styles.center]: footerText})}>
                {renderFooter()}
            </div>

        </div>
    )
}

export default ConnectCard