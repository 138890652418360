import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { DensityPlotFinding } from "common/Finding";
import { updateOriginalNames } from "../../common/Utils";

class Api {
    static getDefaultConfig(journeyName: string): DensityPlotFinding["config"] {
        return {
            count: 100,
            random: false,
            journeyName: journeyName,
            version: configVersionV2,
            showXAxisName: true,
            showYAxisName: true,
            showGrid: false,
        };
    }

    static getPreviewFinding(journeyName: string): DensityPlotFinding {
        let item = {
            type: "densityplot",
            content: {
                data: [
                    {
                        name: "value",
                        value: [
                            [0, 1, 2, 3, 4, 5, 6, 7, 8],
                            [0.05, 0.05, 0.1, 0.15, 0.3, 0.15, 0.1, 0.05, 0.05],
                        ],
                    },
                ],
                isTimeSeries: false,
                densityAxisName: "density",
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getData(
        finding: DensityPlotFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<DensityPlotFinding> {
        let { dataScope, selectedTable, conditions } = finding.config ?? {};
        conditions = filterNonEmptyConditions(conditions ?? []);

        let requestJson = {
            variable_indices: [finding.content.data[0].variableIndex],
            density: true,
            conditions: conditionsToJson(conditions),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id: moduleId,
        };

        return axios
            .post<{
                data: {
                    "%values": number[];
                    "%density": number[];
                    "%is_datetime": boolean;
                };
                success: boolean;
                error_msg: string;
            }>("/api/e/scatterplot_exploration", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: [...finding.content.data],
                        },
                    };

                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );

                    newFinding.content.data[0] = {
                        ...newFinding.content.data[0],
                        value: [
                            response.data.data["%values"],
                            response.data.data["%density"],
                        ],
                    };
                    newFinding.content.isDateTime =
                        response.data.data["%is_datetime"];
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
