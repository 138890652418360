import { SlideRect } from "common/Canvas";

interface MobileViewFrameProps {
  slideRect: SlideRect;
  defaultMobileSlideHeight: number;
  scale: number;
}

function MobileViewFrame(props: MobileViewFrameProps) {
  const { slideRect, defaultMobileSlideHeight, scale } = props;
  return (
    <>
      <section
        style={{
            position: "absolute",
            left: slideRect.x,
            top: slideRect.y,
            border: "2px solid rgba(0,0,0,0.6)",
            width: slideRect.width * scale,
            height: defaultMobileSlideHeight * scale + 2,
        }}
      ></section>

      <section
        style={{
            position: "absolute",
            top: 780 * scale,
            backgroundColor: "rgba(0,0,0,0.6)",
            width: slideRect.width * scale,
            height: "2px",
            zIndex: 1,
            opacity: 0.4,
        }}
      ></section>
    </>
  )
};

export default MobileViewFrame;