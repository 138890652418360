import axios from "./ServerConnection";
import {
    BriefModuleRequest,
    BriefModule,
    BriefModuleResponse,
    toBriefModule,
    toAppModule,
    AppModule,
    AppModuleResponse,
    SharedModule,
    SharedModuleResponse,
    toSharedModule,
    SpecialTabId,
    getSpecialTabIdIndex,
} from "common/Module";
import {
    GroupPermission,
    GroupExtendedPermission,
} from "common/GroupPermissions";
import { Permission } from "common/Permissions";
import UserInfo from "common/UserInfo";
import { PaymentType } from "common/mystripe_components/ProductParametersInput";
import { PageBarInfo, ModuleOptions } from "common/Canvas";

export interface SelfSignupInfo {
    logo: string | null;
    options: {
        backgroundColor?: string;
        createButtonColor?: string;
        logoColor?: string;
        registerText?: string;
        backgroundImage?: string;
        customFields?: {
            name: string;
            label: string;
            required: boolean;
        }[];
        //unused
        // textColor: string;
        // inputFieldColor: string;
        // inputFieldTextColor: string;
        // description: string;
    } | null;
}

export const registrationFields = [
    {
        name: "email",
        label: "Email",
    },
    {
        name: "phone_number",
        label: "Phone Number",
    },
    {
        name: "first_name",
        label: "First Name",
    },
    {
        name: "last_name",
        label: "Last Name",
    },
    {
        name: "organization",
        label: "Organization",
    },
    {
        name: "position",
        label: "Position",
    },
];

export const defaultCustomFields = [

];

export enum PermanentLinkType {
    Vanilla = 0,
    App = 1,
    Module = 2,
    Referral = 3,
}

export async function moveModuleApi(
    storyId: number,
    swapId: number,
    tabId: number
): Promise<void> {
    let requestJson = {
        id: storyId,
        swap_id: swapId,
        tab_id: tabId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>("/api/module_shared_move", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addUserPageToModuleApi(
    title: string,
    moduleId: number,
    updateId?: string
): Promise<number> {
    let requestJson: {
        title: string;
        module_id: number;
        update_id?: string;
    } = {
        title: title,
        module_id: moduleId,
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_user_page_to_module", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function loadModuleApi(
    moduleId: number
): Promise<{
    page_ids: number[];
    id: number;
    title: string;
    description: string;
    keywords: string[];
    thumbnail: string;
    action_required?: "payment" | "attach_payment_method";
    paywall?: {
        title: string;
        client: string;
        description: string;
        payment_type: PaymentType;
        price: number;
        logo: string | null;
    };
    redirect_url?: string;
}> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            page_ids: number[];
            id: number;
            title: string;
            description: string;
            keywords: string[];
            thumbnail: string;
            action_required?: "payment" | "attach_payment_method";
            paywall?: {
                title: string;
                client: string;
                description: string;
                payment_type: PaymentType;
                price: number;
                logo: string | null;
            };
            redirect_url?: string;
        }>(`/api/module_user_load_from_shared`, { id: moduleId })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function unloadModuleApi(
    module: BriefModule,
    image: Blob | null
): Promise<void> {
    let route: string = "module_user_hide";
    let formData = new FormData();
    formData.append(
        "metadata",
        JSON.stringify({
            id: module.id,
            thumbnail: module.thumbnail,
        })
    );
    if (image != null) {
        formData.append("image", image, "image");
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>(`/api/${route}`, formData)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteModuleApi(
    module: {
        id: number;
        isShared: boolean;
    },
    isTemplate = false
): Promise<void> {
    let route: string = !isTemplate
        ? "module_user_remove"
        : !module.isShared
        ? "module_shared_remove_own"
        : "module_shared_remove";
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>(`/api/${route}`, { id: module.id })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function moveTrashModuleApi(
    module: {
        id: string | number;
    },
    inTrash: boolean
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_set_in_trash", {
            id: module.id,
            in_trash: inTrash,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function exportModuleApi(
    module: { id: number },
    isTemplate: boolean
): Promise<string> {
    let route: string = !isTemplate
        ? "module_user_export"
        : "module_shared_export";
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            module_str: string;
        }>(`/api/${route}`, { id: module.id })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.module_str);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function shareModuleLink(
    moduleId: number,
    requireAuthentication: boolean,
    password: string | undefined | null,
    showAsSlideShow: boolean,
    editableByCollaborators: boolean,
    allowAccessToAllDataSets: boolean,
    dataSets?: {
        data_table_idx: number | string;
        allow_uploading?: boolean;
    }[]
): Promise<string> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id: string;
        }>("/api/module_user_share_link", {
            id: moduleId,
            require_authentication: requireAuthentication,
            show_as_slideshow: showAsSlideShow,
            editable_by_collaborators: editableByCollaborators,
            password: password,
            allow_access_to_all_data_sets: allowAccessToAllDataSets,
            data_sets: dataSets,
        })
        .then((response) => {
            if (response.data.success) {
                // amplitude.getInstance().logEvent("Shared presentation", {
                //     type: "publish to link"
                // });
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedModuleByLink(
    moduleId: string,
    password?: string
): Promise<SharedModule> {
    return axios
        .post<SharedModuleResponse & { success: boolean; error_msg: string }>(
            "/api/e/module_user_share_link_get",
            {
                id: moduleId,
                password: password,
            }
        )
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(toSharedModule(response.data));
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function confirmSession(sessionId: string): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/session_confirm", {
            session_id: sessionId,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedModuleByLinkAuth(
    moduleId: string,
    password?: string,
    mode?: string
): Promise<
    | {
          id: number;
          updated: boolean;
      }
    | SharedModule
    | {
          action_required: "attach_payment_method" | "payment";
          title: string;
          description: string;
          keywords: string[];
          thumbnail: string;
          paywall?: {
              title: string;
              client: string;
              description: string;
              payment_type: PaymentType;
              price: number;
              logo: string | null;
          };
      }
    | {
          redirect_url: string;
      }
> {
    let jsonData: {
        id?: string;
        short_link?: string;
        password?: string;
        mode?: string;
    } = {
        password: password,
        mode: mode,
    };
    if (moduleId.startsWith("~")) {
        jsonData.short_link = moduleId.slice(1);
    } else {
        jsonData.id = moduleId;
    }
    return axios
        .post<any>("/api/module_user_share_link_get_auth", jsonData)
        .then((response) => {
            if (response.data.success) {
                if (
                    "id" in response.data ||
                    "action_required" in response.data
                ) {
                    delete response.data.success;
                    delete response.data.error_msg;
                    return Promise.resolve(response.data);
                } else if ("redirect_url" in response.data) {
                    return Promise.resolve({
                        redirect_url: response.data.redirect_url,
                    });
                } else {
                    return Promise.resolve(toSharedModule(response.data));
                }
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getTutorial(moduleId: number): Promise<SharedModule> {
    let jsonData: {
        id: number;
    } = {
        id: moduleId,
    };
    return axios
        .post<any>("/api/e/modules_shared_tutorial_get", jsonData)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(toSharedModule(response.data));
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteSharedModuleLink(linkId: string): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_share_link_remove", {
            id: linkId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function importModuleApi(
    moduleStr: string,
    tabId: number,
    isUserModule: boolean,
    userGroups: string[]
): Promise<number | void> {
    let route: string = isUserModule
        ? "module_user_import"
        : "module_shared_import";
    let requestJson = isUserModule
        ? {
              module_str: moduleStr,
          }
        : {
              tab_id: tabId,
              user_groups: userGroups,
              module_str: moduleStr,
          };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id: number;
        }>(`/api/${route}`, requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function importModuleToSpecialTabApi(
    moduleStr: string,
    specialTabId: SpecialTabId
): Promise<void> {
    let requestJson = {
        module_str: moduleStr,
        tab_id: specialTabId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_shared_import_to_special_tab", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addModuleApi(
    module: BriefModuleRequest,
    moduleId: number,
    image: Blob | null,
    dataSets?: {
        data_table_idx: string | number;
        allow_uploading?: boolean;
        copy_on_load?: boolean;
    }[],
    specialTabId?: SpecialTabId,
    sharedModuleId?: number
): Promise<void> {
    let formData = new FormData();
    formData.append(
        "metadata",
        JSON.stringify({
            ...module,
            module_id: moduleId,
            data_sets: dataSets,
            tab_id: specialTabId,
            shared_module_id: sharedModuleId,
        })
    );
    if (image != null) {
        formData.append("image", image, "image");
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_shared_add", formData)
        .then((response) => {
            if (response.data.success) {
                // amplitude.getInstance().logEvent("Shared presentation", {
                //     type: "share template"
                // });
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addEmptyUserModuleApi(
    title?: string,
    description?: string,
    keywords?: string[]
): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id: number;
        }>("/api/module_user_create_empty", {
            title: title,
            description: description,
            keywords: keywords,
        })
        .then((response) => {
            if (response.data.success) {
                // amplitude.getInstance().logEvent("Create new presentation");
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editModuleApi(
    moduleId: number,
    title?: string,
    permissions?: GroupPermission[],
    updateId?: string
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_edit", {
            id: moduleId,
            title: title,
            permissions: permissions,
            update_id: updateId,
        })
        .then((response) => {
            if (response.data.success) {
                // amplitude.getInstance().logEvent("Edit presentation");
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function moduleUserUpdatePageBarInfo(
    moduleId: number,
    pageBarInfo: PageBarInfo,
    updateId?: string
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_update_page_bar_info", {
            id: moduleId,
            page_bar_info: JSON.stringify(pageBarInfo),
            update_id: updateId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function moduleUserUpdateOptions(
    moduleId: number,
    options: ModuleOptions,
    updateId?: string
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_update_options", {
            id: moduleId,
            options: JSON.stringify(options),
            update_id: updateId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editUserPageModuleApi(
    title: string,
    pageId: number,
    updateId?: string
): Promise<void> {
    let requestJson: {
        title: string;
        id: number;
        update_id?: string;
    } = {
        title: title,
        id: pageId,
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/edit_user_page_module", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function deleteUserPageModuleApi(
    pageId: number,
    updateId?: string
): Promise<void> {
    let requestJson: {
        id: number;
        update_id?: string;
    } = {
        id: pageId,
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/remove_user_page_module", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function moveUserPageModuleApi(
    pageId: number,
    beforeId: number,
    updateId?: string
): Promise<void> {
    let requestJson = { id: pageId, before_id: beforeId, update_id: updateId };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/move_user_page_module", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function moduleUserShareApi(
    id: number,
    tabId: number,
    editable: boolean,
    republish: boolean,
    title: string,
    userGroups: string[]
): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id: number;
        }>("/api/module_user_share", {
            tab_id: tabId,
            id: id,
            editable: editable,
            republish: republish,
            title: title,
            user_groups: userGroups,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getTemplateModulesApi(
    offset?: number,
    limit?: number,
    user_module_id?: number
): Promise<BriefModule[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: BriefModuleResponse[];
        }>("/api/modules_shared_get_by_user", {
            offset: offset,
            limit: limit,
            user_module_id: user_module_id,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map(
                        (module: BriefModuleResponse): BriefModule =>
                            toBriefModule(module, false, false)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export enum UserModuleType {
    Current = 0,
    History = 1,
}

export interface AppGroupExtendedPermission {
    group_id: number;
    group_name: string;
    can_edit: boolean;
    personal: boolean;
    user_info?: UserInfo;
}

export interface ModuleGroupExtendedPermission extends GroupExtendedPermission {
    personal: boolean;
    user_info?: UserInfo;
    invited?: boolean;
}

export interface ModuleInvitation {
    permission_type: number;
    email: string;
}

export async function getModuleUsers(
    moduleId: number,
    userId?: number
): Promise<UserInfo[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            users?: UserInfo[];
        }>("/api/modules_user_get_permissions_all_users", {
            module_id: moduleId,
            user_id: userId,
        })
        .then((response) => {
            if (response.data.success && response.data.users != null) {
                return Promise.resolve(response.data.users);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getUserModulePermissionsApi(
    moduleId: number
): Promise<{
    permissions: ModuleGroupExtendedPermission[];
    invitations: ModuleInvitation[];
}> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            permissions?: ModuleGroupExtendedPermission[];
            invitations?: ModuleInvitation[];
        }>("/api/modules_user_get_permissions", {
            module_id: moduleId,
        })
        .then((response) => {
            if (
                response.data.success &&
                response.data.permissions != null &&
                response.data.invitations != null
            ) {
                return Promise.resolve({
                    permissions: response.data.permissions,
                    invitations: response.data.invitations,
                });
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getUserModulesApi(
    inTrash: boolean = false,
    offset?: number,
    limit?: number
): Promise<BriefModule[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: BriefModuleResponse[];
        }>("/api/modules_user_get_by_user", {
            offset: offset,
            limit: limit,
            in_trash: inTrash,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map(
                        (module: BriefModuleResponse): BriefModule =>
                            toBriefModule(
                                module,
                                module.tab_id === UserModuleType.Current,
                                module.tab_id === UserModuleType.History
                            )
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedModulesSpecialTabApi(
    specialTabIds: SpecialTabId[],
    offset?: number,
    limit?: number,
    moduleId?: number
): Promise<BriefModule[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: BriefModuleResponse[];
        }>("/api/modules_shared_special_tab_get", {
            tab_ids: specialTabIds,
            offset: offset,
            limit: limit,
            user_module_id: moduleId,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map((module) =>
                        toBriefModule(module, false, false)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedModulesWithCategories(
    offset?: number,
    limit?: number,
    moduleId?: number
): Promise<BriefModule[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: BriefModuleResponse[];
        }>("/api/modules_shared_get_with_category", {
            offset: offset,
            limit: limit,
            user_module_id: moduleId,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map((module) =>
                        toBriefModule(module, false, false)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedModulesByUserModuleId(
    offset?: number,
    limit?: number,
    moduleId?: number
): Promise<BriefModule[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: BriefModuleResponse[];
        }>("/api/modules_shared_get", {
            offset: offset,
            limit: limit,
            user_module_id: moduleId,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map((module) =>
                        toBriefModule(module, false, false)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedModuleAppsSpecialTabApi(
    specialTabId: SpecialTabId,
    offset?: number,
    limit?: number
): Promise<AppModule[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: AppModuleResponse[];
        }>("/api/module_user_share_link_app_get_special", {
            special_tab_id: getSpecialTabIdIndex(specialTabId),
            offset: offset,
            limit: limit,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map((module) => toAppModule(module))
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedModuleAppsWithCategory(
    offset?: number,
    limit?: number
): Promise<AppModule[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: AppModuleResponse[];
        }>("/api/module_user_share_link_app_get_with_category", {
            offset: offset,
            limit: limit,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map((module) => toAppModule(module))
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getCommonUserModulesApi(
    offset?: number,
    limit?: number
): Promise<BriefModule[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: BriefModuleResponse[];
        }>("/api/modules_user_get_common_by_user", {
            offset: offset,
            limit: limit,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map(
                        (module: BriefModuleResponse): BriefModule =>
                            toBriefModule(module, true, false)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getUserModuleByIdOrCurrent(
    moduleId?: number
): Promise<BriefModule[]> {
    let json = moduleId == null ? {} : { module_id: moduleId };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: BriefModuleResponse[];
        }>("/api/modules_user_get_by_id_or_current", json)
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map(
                        (module: BriefModuleResponse): BriefModule =>
                            toBriefModule(module, true, false)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getModuleUserDataSetsApi(
    moduleId: number,
    currentUserOnly: boolean = true,
    includeCopies: boolean = false
): Promise<
    {
        data_table_idx: number | string;
        data_table_name: string;
        user?: string;
        permission_type?: Permission;
        allow_uploading?: boolean;
        set_manually: boolean;
    }[]
> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            data_tables?: {
                data_table_idx: number | string;
                data_table_name: string;
                user?: string;
                permission_type?: Permission;
                allow_uploading?: boolean;
                set_manually: boolean;
            }[];
        }>("/api/e/module_user_get_data_sets", {
            id: moduleId,
            current_user_only: currentUserOnly,
            include_copies: includeCopies,
        })
        .then((response) => {
            if (response.data.success && response.data.data_tables != null) {
                return response.data.data_tables;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getTemplateDataSetsApi(
    moduleId: number
): Promise<
    {
        data_table_idx: number | string;
        data_table_name: string;
        allow_uploading: boolean;
        copy_on_load: boolean;
        set_manually: boolean;
    }[]
> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            data_tables?: {
                data_table_idx: number | string;
                data_table_name: string;
                allow_uploading: boolean;
                copy_on_load: boolean;
                set_manually: boolean;
            }[];
        }>("/api/e/module_template_get_data_sets", {
            id: moduleId,
        })
        .then((response) => {
            if (response.data.success && response.data.data_tables != null) {
                return response.data.data_tables;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getModuleUserSharedLinkDataSetsApi(
    linkId: string,
    currentUserOnly: boolean = true,
    includeCopies: boolean = false
): Promise<{
    allowAccessToAllDataSets: boolean;
    dataSets: {
        data_table_idx: number | string;
        data_table_name: string;
        permission_type?: Permission;
        allow_uploading?: boolean;
        set_manually: boolean;
    }[];
}> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            allow_access_to_all_data_sets?: boolean;
            data_tables?: {
                data_table_idx: number | string;
                data_table_name: string;
                user?: string;
                permission_type?: Permission;
                allow_uploading?: boolean;
                set_manually: boolean;
            }[];
        }>("/api/e/module_user_get_data_sets", {
            id: linkId,
            current_user_only: currentUserOnly,
            include_copies: includeCopies,
        })
        .then((response) => {
            if (
                response.data.success &&
                response.data.data_tables != null &&
                response.data.allow_access_to_all_data_sets != null
            ) {
                return {
                    allowAccessToAllDataSets:
                        response.data.allow_access_to_all_data_sets,
                    dataSets: response.data.data_tables,
                };
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

/*
export async function setModuleUserDataSetsApi(
    moduleId: number,
    dataSets: {
        data_table_idx: number | string;
        permission_type: Permission;
        allow_uploading?: boolean;
        set_manually: boolean;
    }[],
    updateId?: string
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_set_data_sets", {
            id: moduleId,
            data_sets: dataSets,
            update_id: updateId,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
*/

export async function addOrEditModuleUserDataSetApi(
    moduleId: number,
    dataScopeId: number | string,
    permissionType: Permission,
    allowUploading?: boolean,
    setManually: boolean = false,
    keepManual: boolean = false,
    canvasId?: number,
    updateId?: string
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_add_or_edit_data_set", {
            id: moduleId,
            data_set: {
                data_table_idx: dataScopeId,
                permission_type: permissionType,
                allow_uploading: allowUploading,
                set_manually: setManually,
            },
            keep_manual: keepManual,
            canvas_id: canvasId,
            update_id: updateId,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteModuleUserDataSetApi(
    moduleId: number,
    dataScopeId: number | string,
    keepManual?: boolean,
    canvasId?: number,
    updateId?: string
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_delete_data_set", {
            id: moduleId,
            data_table_idx: dataScopeId,
            keep_manual: keepManual,
            canvas_id: canvasId,
            update_id: updateId,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addOrEditAppApi(
    moduleId: number,
    title: string,
    thumbnail: string,
    description: string,
    keywords: string[],
    dataSets: {
        data_table_idx: number | string;
        allow_uploading?: boolean;
    }[],
    editableByCollaborators: boolean,
    specialTabId: number | undefined,
    logo?: string | undefined,
    paywall?: {
        title: string;
        client: string;
        description: string;
        payment_type: PaymentType;
        price: number;
        logo: string;
    },
    shortLink?: string,
    usePermissions?: boolean,
    permissions?: { group_id: number; can_edit: boolean }[],
    allowAccessToAllDataSets?: boolean,
    categoryId?: number
): Promise<string> {
    let jsonRequest = {
        id: moduleId,
        special_tab_id: specialTabId,
        thumbnail: thumbnail,
        title: title,
        logo: logo ?? "",
        description: description,
        keywords: keywords,
        data_sets: dataSets,
        editable_by_collaborators: editableByCollaborators,
        paywall: paywall,
        short_link: shortLink,
        use_permissions: usePermissions,
        permissions: permissions,
        allow_access_to_all_data_sets: allowAccessToAllDataSets,
        category_id: categoryId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: string;
        }>("/api/module_user_share_link_app_add_or_edit", jsonRequest)
        .then((response) => {
            if (response.data.success) {
                // amplitude.getInstance().logEvent("Shared presentation", {
                //     type: "publish to app"
                // });
                return Promise.resolve(response.data.id!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function getAppApi(
    moduleId: number
): Promise<{
    title: string;
    description: string;
    keywords: string[];
    dataSets: {
        data_table_name: string;
        data_table_idx: number | string;
        allow_uploading?: boolean;
        copy_on_load?: boolean;
    }[];
    special_tab_id: number | null;
    self_signup_link_id?: string;
    self_signup_info?: {
        logo: string | null;
        options: string | null;
    };
    logo?: string | undefined;
    thumbnail?: string | undefined;
    paywall?: {
        title: string;
        client: string;
        description: string;
        payment_type: PaymentType;
        price: number;
        logo: string | null;
    };
    link_id: string;
    short_link?: string | null;
    use_permissions: boolean;
    allow_access_to_all_data_sets: boolean;
    permissions?: AppGroupExtendedPermission[];
    category?: {
        id: number;
        title: string;
    };
} | null> {
    let jsonRequest = {
        id: moduleId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            title?: string;
            description?: string;
            thumbnail?: string;
            keywords?: string[];
            data_sets?: {
                data_table_name: string;
                data_table_idx: number | string;
                user: string;
                allow_uploading?: boolean;
            }[];
            special_tab_id: number | null;
            self_signup_link_id?: string;
            self_signup_info?: {
                logo: string | null;
                options: string | null;
            };
            logo?: string | undefined;
            paywall?: {
                title: string;
                client: string;
                description: string;
                payment_type: PaymentType;
                price: number;
                logo: string | null;
            };
            link_id?: string;
            short_link?: string | null;
            use_permissions: boolean;
            allow_access_to_all_data_sets: boolean;
            permissions?: AppGroupExtendedPermission[];
            category?: {
                id: number;
                title: string;
            };
        }>("/api/module_user_share_link_app_get", jsonRequest)
        .then((response) => {
            if (response.data.success) {
                if (response.data.title == null) {
                    return Promise.resolve(null);
                } else {
                    return Promise.resolve({
                        title: response.data.title,
                        description: response.data.description!,
                        thumbnail: response.data.thumbnail,
                        logo: response.data.logo,
                        keywords: response.data.keywords!,
                        dataSets: response.data.data_sets!,
                        paywall: response.data.paywall,
                        link_id: response.data.link_id!,
                        special_tab_id: response.data.special_tab_id,
                        self_signup_link_id: response.data.self_signup_link_id,
                        self_signup_info: response.data.self_signup_info,
                        short_link: response.data.short_link,
                        use_permissions: response.data.use_permissions,
                        allow_access_to_all_data_sets:
                            response.data.allow_access_to_all_data_sets,
                        permissions: response.data.permissions,
                        category: response.data.category,
                    });
                }
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            if (error.response != null)
                return Promise.reject(
                    `${error.response.status} ${error.response.statusText}`
                );
            else return Promise.reject(error.message);
        });
}

export async function removeAppApi(moduleId: number): Promise<void> {
    let jsonRequest = {
        id: moduleId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_share_link_app_remove", jsonRequest)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            if (error.response != null)
                return Promise.reject(
                    `${error.response.status} ${error.response.statusText}`
                );
            else return Promise.reject(error.message);
        });
}

export async function getLastAccessedAppsApi(
    offset?: number,
    limit?: number
): Promise<AppModule[]> {
    let jsonRequest = {
        limit: limit,
        offset: offset,
        app_exists: true,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            cards?: AppModuleResponse[];
        }>("/api/module_user_share_link_get_last_accessed_links", jsonRequest)
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map(
                        (module: AppModuleResponse): AppModule =>
                            toAppModule(module)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            if (error.response != null)
                return Promise.reject(
                    `${error.response.status} ${error.response.statusText}`
                );
            else return Promise.reject(error.message);
        });
}

export async function checkModulePaymentApi(
    moduleId: number
): Promise<{
    has_access: boolean;
    title: string | null;
    client: string | null;
    description: string | null;
    price: number | null;
    logo: string | null;
}> {
    let jsonRequest = {
        id: moduleId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            has_access: boolean;
            title: string | null;
            client: string | null;
            description: string | null;
            logo: string | null;
            price: number | null;
        }>("/api/module_shared_payment_check", jsonRequest)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve({
                    has_access: response.data.has_access,
                    title: response.data.title,
                    client: response.data.client,
                    description: response.data.description,
                    price: response.data.price,
                    logo: response.data.logo,
                });
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            if (error.response != null)
                return Promise.reject(
                    `${error.response.status} ${error.response.statusText}`
                );
            else return Promise.reject(error.message);
        });
}

export async function unhideUserModuleApi(moduleId: number): Promise<number> {
    let jsonRequest = {
        id: moduleId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/module_user_unhide", jsonRequest)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            if (error.response != null)
                return Promise.reject(
                    `${error.response.status} ${error.response.statusText}`
                );
            else return Promise.reject(error.message);
        });
}

export async function addDailyViewUserModuleApi(
    moduleId: number
): Promise<void> {
    let jsonRequest = {
        id: moduleId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/add_view_user_module", jsonRequest)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            if (error.response != null)
                return Promise.reject(
                    `${error.response.status} ${error.response.statusText}`
                );
            else return Promise.reject(error.message);
        });
}

export async function addSelfSignupLinkToAppApi(
    linkId: string,
    options: string | undefined | null,
    logo: string | undefined | null
): Promise<string> {
    let jsonRequest = {
        link_id: linkId,
        options: options,
        logo: logo,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            self_signup_link_id?: string;
        }>("/api/module_user_share_link_app_self_signup_add", jsonRequest)
        .then((response) => {
            if (
                response.data.success &&
                response.data.self_signup_link_id != null
            ) {
                return Promise.resolve(response.data.self_signup_link_id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            if (error.response != null)
                return Promise.reject(
                    `${error.response.status} ${error.response.statusText}`
                );
            else return Promise.reject(error.message);
        });
}

export async function removeSelfSignupLinkFromAppApi(
    linkId: string
): Promise<void> {
    let jsonRequest = {
        link_id: linkId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_share_link_app_self_signup_remove", jsonRequest)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            if (error.response != null)
                return Promise.reject(
                    `${error.response.status} ${error.response.statusText}`
                );
            else return Promise.reject(error.message);
        });
}

export async function verifySelfSignupLinkApi(
    selfSignupLinkId: string,
    permanentLinkType: PermanentLinkType
): Promise<{
    options: string | null;
    logo: string | null;
    is_tutorial?: boolean;
}> {
    let jsonRequest = {
        self_signup_link_id: selfSignupLinkId,
    };
    let url = "";
    switch (permanentLinkType) {
        case PermanentLinkType.App:
            url = "/api/e/module_user_share_link_app_self_signup_verify";
            break;
        case PermanentLinkType.Module:
            url = "/api/e/module_shared_self_signup_verify";
            break;
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            logo?: string | null;
            options?: string | null;
            is_tutorial?: boolean;
        }>(url, jsonRequest)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            } else
                return Promise.resolve({
                    options: response.data.options!,
                    logo: response.data.logo!,
                    is_tutorial: response.data.is_tutorial,
                });
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function selfSignupRegisterApi(
    config: { [key: string]: string | string[] },
    permanentLinkType: PermanentLinkType
): Promise<string | number> {
    let url = "";
    switch (permanentLinkType) {
        case PermanentLinkType.App:
            url = "/api/e/module_user_share_link_app_self_signup_register";
            break;
        case PermanentLinkType.Module:
            url = "/api/e/module_shared_self_signup_register";
            break;
        case PermanentLinkType.Referral:
            url = "/api/e/self_signup_register";
            break;
        case PermanentLinkType.Vanilla:
            url = "/api/e/self_signup_register";
            break;
    }
    return axios
        .post<{
            success: boolean;
            link_id?: string;
            shared_module_id?: number;
            error_msg: string;
        }>(url, config)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve(
                    (response.data.link_id ?? response.data.shared_module_id)!
                );
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function selfSignupExistingAccountApi(
    linkId: string,
    permanentLinkType: PermanentLinkType
): Promise<string | number> {
    let url = "";
    switch (permanentLinkType) {
        case PermanentLinkType.App:
            url =
                "/api/module_user_share_link_app_self_signup_existing_account";
            break;
        case PermanentLinkType.Module:
            url = "/api/module_shared_self_signup_existing_account";
            break;
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            shared_module_id?: number;
            link_id?: string;
        }>(url, { self_signup_link_id: linkId })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve(
                    (response.data.link_id ?? response.data.shared_module_id)!
                );
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function appGetPermissions(
    linkId: string
): Promise<AppGroupExtendedPermission[]> {
    let json: { id?: string; short_link?: string };
    if (linkId.startsWith("~")) {
        json = { short_link: linkId.slice(1) };
    } else {
        json = { id: linkId };
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            permissions?: AppGroupExtendedPermission[];
        }>("/api/module_user_share_link_app_get_permissions", json)
        .then((response) => {
            if (response.data.success && response.data.permissions != null) {
                return Promise.resolve(response.data.permissions);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function appSetPermissions(
    linkId: string,
    permissions: { group_id: number; can_edit: boolean }[]
): Promise<void> {
    let json: {
        id?: string;
        short_link?: string;
        permissions: { group_id: number; can_edit: boolean }[];
    } = {
        permissions: permissions,
    };
    if (linkId.startsWith("~")) {
        json.short_link = linkId.slice(1);
    } else {
        json.id = linkId;
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_user_share_link_app_set_permissions", json)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function transferModuleUserOwnershipApi(
    moduleId: number,
    transferToUserName: string
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/transfer_ownership_user_module", {
            id: moduleId,
            transfer_to_user_name: transferToUserName,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function updateSharedModuleImageAndThumbnail(
    sharedModuleId: number,
    thumbnail: string,
    image: Blob | null
): Promise<void> {
    let formData = new FormData();
    formData.append(
        "metadata",
        JSON.stringify({
            shared_module_id: sharedModuleId,
            thumbnail: thumbnail
        })
    );

    if (image != null) {
        formData.append("image", image, "image");
    }

    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/update_shared_module_thumbnail", formData)
        .then((response) => {
            if (response.data.success) {
                Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        })
}
