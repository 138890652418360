import React, { useState, useMemo } from "react";
import { GeoJsonObject } from "geojson";
import { Button } from "react-bootstrap";

import { MapFinding } from "common/Finding";
import ColorPicker from "common/ColorPicker";
import { colorList } from "common/graphics/LineColors";
import StatusPopup, { PopupStatus } from "common/StatusPopup";

import styles from "./GeoJsonMenu.module.css";

const geoJsonSizeLimit = 50 * 1024 * 1024;

interface Props {
    mapFinding: MapFinding;
    onChange: (finding: MapFinding, update?: boolean) => void;
}

function GeoJsonMenu({ mapFinding, onChange }: Props) {
    const [errorMessage, setErrorMessage] = useState("");

    const geoJsonSizes = useMemo(() => {
        let sizes: { [key: string]: number } = {};

        for (let [key, item] of Object.entries(
            mapFinding.config.geoJsonFiles ?? {}
        )) {
            if (item != null) {
                sizes[key] = JSON.stringify(item).length;
            }
        }

        return sizes;
    }, [mapFinding.config.geoJsonFiles]);

    return (
        <div className={styles.root}>
            {Object.entries(mapFinding.config.geoJsonFiles ?? {})
                .filter(
                    (
                        item
                    ): item is [
                        string,
                        {
                            name: string;
                            contents: GeoJsonObject;
                            color?: string;
                        }
                    ] => item[1] != null
                )
                .map(([index, { name, color }], mapIndex) => (
                    <div
                        key={`geojson-file-${name}-${index}`}
                        className={styles.fileRow}
                    >
                        <span className={styles.rowIndex}>{mapIndex + 1}</span>
                        <input
                            type="text"
                            className={styles.rowInput}
                            placeholder="Load File"
                            defaultValue={name}
                            onBlur={(e) => {
                                if (e.target.value === name) return;

                                onChange({
                                    ...mapFinding,
                                    config: {
                                        ...mapFinding.config,
                                        geoJsonFiles: {
                                            ...mapFinding.config.geoJsonFiles,
                                            [index]: {
                                                ...mapFinding.config
                                                    .geoJsonFiles![index]!,
                                                name: e.target.value,
                                            },
                                        },
                                    },
                                });
                            }}
                        />
                        <ColorPicker
                            inPopup
                            value={
                                color ?? colorList[mapIndex % colorList.length]
                            }
                            onChange={(newValue) => {
                                onChange({
                                    ...mapFinding,
                                    config: {
                                        ...mapFinding.config,
                                        geoJsonFiles: {
                                            ...mapFinding.config.geoJsonFiles,
                                            [index]: {
                                                ...mapFinding.config
                                                    .geoJsonFiles![index]!,
                                                color: newValue,
                                            },
                                        },
                                    },
                                });
                            }}
                            style={{
                                marginLeft: "6px",
                            }}
                        />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "10px",
                                width: "19px",
                            }}
                        >
                            <Button
                                className="close"
                                onClick={() => {
                                    onChange({
                                        ...mapFinding,
                                        config: {
                                            ...mapFinding.config,
                                            geoJsonFiles: {
                                                ...mapFinding.config
                                                    .geoJsonFiles,
                                                [index]: null,
                                            },
                                        },
                                    });
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        </div>
                    </div>
                ))}
            <div>
                <label className={styles.addButton}>
                    <input
                        style={{ display: "none" }}
                        type="file"
                        accept="text/json"
                        onChange={(event) => {
                            const files = event?.target?.files ?? [];

                            if (!files.length) return;

                            let totalSize = 0;
                            for (let size of Object.values(geoJsonSizes)) {
                                totalSize += size;
                            }

                            if (totalSize + files[0].size > geoJsonSizeLimit) {
                                setErrorMessage(
                                    "Not enough space. All GeoJSON files combined cannot exceed 50 MB."
                                );
                                return;
                            }

                            let reader = new FileReader();
                            let key = files[0].name;
                            let keyIndex = 0;
                            while (
                                key in (mapFinding.config.geoJsonFiles ?? {})
                            ) {
                                key = `${keyIndex}_${files[0].name}`;
                                keyIndex += 1;
                            }
                            reader.onload = (e) => {
                                onChange({
                                    ...mapFinding,
                                    config: {
                                        ...mapFinding.config,
                                        geoJsonFiles: {
                                            ...mapFinding.config.geoJsonFiles,
                                            [key]: {
                                                name: files[0].name,
                                                contents: JSON.parse(
                                                    e.target!.result as string
                                                ) as GeoJsonObject,
                                                color:
                                                    colorList[
                                                        Object.keys(
                                                            mapFinding.config
                                                                .geoJsonFiles ??
                                                                {}
                                                        ).length %
                                                            colorList.length
                                                    ],
                                            },
                                        },
                                    },
                                });
                            };

                            reader.readAsText(files[0]);
                        }}
                    />
                    Add +
                </label>
            </div>
            {errorMessage && (
                <StatusPopup
                    onClose={() => {
                        setErrorMessage("");
                    }}
                    status={PopupStatus.Error}
                    message={errorMessage}
                />
            )}
        </div>
    );
}

export default GeoJsonMenu;
