import React, { useState, useEffect } from "react";

import { ScatterPlotFinding } from "common/Finding";
import EditableAxisItem from "../EditableAxisItem";
import * as d3 from "d3";
import { TooltipStyles } from "../../TooltipStyles";
import { mainStyle } from "common/MainStyle";
import {
    getGridColorByTheme,
    getDefaultColorsByTheme,
} from "../../BarChartTheme";
import { formatValue } from "common/utilities/FormatValue";
import D3ChartBase, { AxisType } from "common/graphics/v2/D3ChartBase";
import Portal from "common/Portal";
import { colorList } from "common/graphics/LineColors";
import { ReactComponent as SwapIcon } from "icons/canvas/exploration/swap_axes.svg";
import AnimationSlider from "common/AnimationSlider";
import Popup from "reactjs-popup";
import ColorPicker from "../ChartColorPicker";
import { chartColorPickerPopupStyles } from "common/Constants";
import { getTextSize } from "common/utilities/MeasureText";
import { useUpdateEffect } from "common/CustomHooks";
import { calculateChartColorPickerPosition, getLongestString } from "../utils";

interface Props {
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    content: ScatterPlotFinding["content"];
    trendlineInfo: ScatterPlotFinding["content"]["trendlineInfo"];
    config: ScatterPlotFinding["config"];
    onChangeData?: (
        data: ScatterPlotFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: ScatterPlotFinding["config"],
        updateData?: boolean
    ) => void;
    onChangeContent?: (
        content: ScatterPlotFinding["content"],
        updateData?: boolean
    ) => void;
    width: number;
    height: number;
    scale: number;
    selected: boolean;
}

interface Point {
    x: number;
    y: number;
    index: number;
    time?: string | number | null;
}

export default function ScatterPlot(props: Props) {
    let [showTooltipInfo, setShowTooltipInfo] = useState<{
        x: number;
        y: number;
        point: Point;
    } | null>(null);
    let [longestYAxisValue, setLongestYAxisValue] = useState<string>("0");
    let [colorPickerIsEnabled, setColorPickerIsEnabled] = useState<boolean>(
        false
    );
    let [axisItemStyles, setAxisItemStyles] = useState<Record<any, number>>({
        xAxisWidth: 0,
        yAxisHeight: 0,
        xAxisOffset: 0,
        yAxisOffset: 0,
    });
    let [showColorPicker, setShowColorPicker] = useState<{
        x: number;
        y: number;
    } | null>(null);

    let parentRef = React.useRef<HTMLDivElement>(null);
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    let circlesRef = React.useRef<
        d3.Selection<SVGCircleElement, Point, SVGGElement, unknown>
    >();

    let currentEditVariableIndex: number | undefined = undefined;
    if (props.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = props.content.data.findIndex(
            (item) => item.variableIndex == null
        );
    }

    let onChangeName = (index: number, value: string) => {
        let newData = Array.from(props.content.data);
        newData[index].name = value;
        props.onChangeData?.(newData);
    };

    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.content.data);
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };

    useUpdateEffect(() => {
        setTimeout(() => {
            setColorPickerIsEnabled(props.selected);
        }, 100);
    }, [props.selected]);

    useEffect(() => {
        let xAxisFontSize: number =
            props.config.ticksAndLabels?.x?.size ??
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

        let yAxisFontSize: number =
            props.config.ticksAndLabels?.y?.size ??
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

        let defaultColors = getDefaultColorsByTheme(props.config.chartTheme);
        // let themeOptions = getOptionsByTheme(props.config.chartTheme);
        let gridFillColor = getGridColorByTheme(
            props.config.chartTheme,
            props.config.baseBackgroundColor
        );

        let [minX, maxX] = d3.extent(props.content.data[0].value);
        minX = minX ?? 0;
        maxX = maxX ?? 10;
        let [minY, maxY] = d3.extent(props.content.data[1].value);
        minY = minY ?? 0;
        maxY = maxY ?? 10;

        let minXRange = props.config.minXRange ?? minX!;
        let maxXRange = props.config.maxXRange ?? maxX!;
        let minYRange = props.config.minYRange ?? minY!;
        let maxYRange = props.config.maxYRange ?? maxY!;

        const yAxisExtraSpace =
            getTextSize(longestYAxisValue, "Roboto", yAxisFontSize, "normal")
                .width + 7;

        const xAxisExtraSpace =
            getTextSize("0", "Roboto", xAxisFontSize, "normal").height / 2 - 22;

        // Padding is necessary to prevent tick text from being cut off
        let fullHeight = parentSvgRef.current?.clientHeight ?? 0;
        let fullWidth = parentSvgRef.current?.clientWidth ?? 0;
        let yAxisWidth = 50;
        let xAxisHeight = 31;
        // set the dimensions and margins of the graph
        let height = fullHeight - xAxisHeight;
        // We subtract yAxisExtraSpace to prevent the chart from shifting to
        // the right and cutting off axis label
        // https://eisengardai.atlassian.net/browse/EIS-259?focusedCommentId=12079
        let width = fullWidth - yAxisWidth - yAxisExtraSpace;

        setAxisItemStyles({
            xAxisWidth: width,
            yAxisHeight: height + 2,
            xAxisOffset: yAxisExtraSpace,
            yAxisOffset: xAxisExtraSpace,
        });

        // append the svg object to the body of the page
        d3.select(svgRef.current!).selectAll("*").remove();
        let svg = d3
            .select(svgRef.current!)
            .attr("width", fullWidth)
            .attr("height", fullHeight)
            .append("g")
            .style("cursor", "crosshair")
            .attr(
                "transform",
                `translate(${yAxisExtraSpace}, ${-xAxisExtraSpace})`
            );

        let base = new D3ChartBase(svg, width, height);

        base.drawBackground(gridFillColor ?? "transparent");

        const axisAbsolutePadding = 8;

        // Calculate ticks
        let {
            ticks: xTicks,
            decimals: xDecimals,
        } = D3ChartBase.calculateLinearTicks(
            minXRange,
            maxXRange,
            props.config?.ticksAndLabels?.x?.interval
        );
        let {
            ticks: yTicks,
            decimals: yDecimals,
        } = D3ChartBase.calculateLinearTicks(
            minYRange,
            maxYRange,
            props.config?.ticksAndLabels?.y?.interval
        );

        let xTickFormat = (tick: number) => tick.toFixed(xDecimals);
        let yTickFormat = (tick: number) => tick.toFixed(yDecimals);

        // X Axis
        let { axis: xAxis } = base.drawLinearAxis(
            AxisType.XAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: xAxisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
                tickValues: xTicks,
                tickFormat: xTickFormat,
            },
            [minXRange, maxXRange],
            undefined,
            {
                start: axisAbsolutePadding,
                end: axisAbsolutePadding,
            }
        );
        let xScale = xAxis.scale<d3.ScaleLinear<number, number>>();
        // Y Axis
        let { axis: yAxis } = base.drawLinearAxis(
            AxisType.YAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: yAxisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
                tickValues: yTicks,
                tickFormat: yTickFormat,
            },
            [minYRange, maxYRange],
            undefined,
            {
                start: axisAbsolutePadding,
                end: axisAbsolutePadding,
            }
        );
        let yScale = yAxis.scale<d3.ScaleLinear<number, number>>();

        const longestYValue = getLongestString(yTicks.map(yTickFormat));

        if (longestYAxisValue !== longestYValue) {
            setLongestYAxisValue(longestYValue);
        }

        // Grid
        if (props.config.showGrid) {
            base.drawGrid(
                xTicks,
                yTicks,
                props.config.axesLinesColor ?? defaultColors.gridColor,
                xScale,
                yScale
            );
        }

        let plotData: Point[] = props.content.data[0].value.map((x, index) => ({
            x: x,
            y: props.content.data[1].value[index],
            index: index,
            time: props.content.time?.value[index],
        }));

        circlesRef.current = svg
            .append("g")
            .selectAll<SVGCircleElement, Point[]>("circle")
            .data(plotData)
            .join("circle")
            .attr("cx", (d) =>
                xAxis.scale<d3.ScaleLinear<number, number>>()(d.x)
            )
            .attr("cy", (d) =>
                yAxis.scale<d3.ScaleLinear<number, number>>()(d.y)
            )
            .attr("r", 8)
            .attr(
                "fill",
                props.preview
                    ? "#FFFFFF"
                    : props.config.chartColor ?? colorList[1]
            )
            .attr("stroke", props.preview ? "#D1D1D1" : "transparent")
            .attr("hidden", (d) => {
                // Condition to hide circles that goes beyond y axis
                return (
                    d.x < minXRange ||
                    d.x > maxXRange ||
                    d.y < minYRange ||
                    d.y > maxYRange ||
                    (d.time == null ||
                    d.time <= props.content.time!.uniqueValues[0]
                        ? null
                        : true)
                );
            })
            .on("click", (evt) => {
                const { y } = calculateChartColorPickerPosition(evt);
                if (colorPickerIsEnabled) {
                    setShowColorPicker({
                        x: evt.clientX + 5,
                        y: y + 5,
                    });
                }
            });

        circlesRef
            .current!.on("mouseenter", (_event: MouseEvent, point: Point) => {
                let rect = (circlesRef.current!.nodes()[
                    point.index
                ] as SVGCircleElement).getBoundingClientRect();
                setShowTooltipInfo({
                    x: (rect.left + rect.right) / 2,
                    y: (rect.top + rect.bottom) / 2,
                    point: point,
                });
            })
            .on("mouseleave", (_event: MouseEvent) => {
                setShowTooltipInfo(null);
            });

        if (props.trendlineInfo != null) {
            svg.append("g")
                .append("line")
                .attr("x1", xScale(minX))
                .attr("x2", xScale(maxX))
                .attr(
                    "y1",
                    yScale(
                        props.trendlineInfo.coef * minX +
                            props.trendlineInfo.intercept
                    )
                )
                .attr(
                    "y2",
                    yScale(
                        props.trendlineInfo.coef * maxX +
                            props.trendlineInfo.intercept
                    )
                )
                .attr("stroke", props.config.trendlineColor ?? colorList[0])
                .attr("stroke-width", 2);
        }
    }, [
        props.content.data,
        props.content.time,
        props.content.time?.value,
        props.content.time?.uniqueValues,
        props.config.ticksAndLabels,
        props.config.chartTheme,
        props.config.baseBackgroundColor,
        props.config.maxYRange,
        props.config.minYRange,
        props.config.maxXRange,
        props.config.minXRange,
        props.config.linesCount,
        props.config.showXAxisName,
        props.config.showYAxisName,
        props.config.count,
        props.config.trendline,
        props.config.random,
        props.config.trendlineColor,
        props.config.axesLinesColor,
        props.config.chartColor,
        props.config.showGrid,
        props.config.ticksColor,
        props.config.ticksSize,
        props.trendlineInfo,
        props.trendlineInfo?.coef,
        props.trendlineInfo?.intercept,
        props.preview,
        props.width,
        props.height,
        props.scale,
        longestYAxisValue,
        colorPickerIsEnabled,
    ]);

    let tooltipStyle = {
        ...TooltipStyles(
            props.config.tooltipColor,
            props.config.tooltipFontSize
        ),
    };
    return (
        <div style={{ height: "100%" }}>
            <div
                ref={parentRef}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {props.content.time?.variableIndex != null &&
                    props.content.time?.uniqueValues.length !== 0 && (
                        <AnimationSlider
                            sliderStyle={{
                                cursor: "default",
                                width: 165,
                                pointerEvents: "auto",
                            }}
                            values={props.content.time.uniqueValues}
                            onChange={(index) => {
                                circlesRef.current?.attr("hidden", (d) => {
                                    let minX =
                                        d3.extent(
                                            props.content.data[0].value
                                        )[0] ?? 0;
                                    let [minY, maxY] = d3.extent(
                                        props.content.data[1].value
                                    );
                                    minY = minY ?? 0;
                                    maxY = maxY ?? 10;

                                    const minXRange =
                                        props.config.minXRange ?? minX!;
                                    const minYRange =
                                        props.config.minYRange ?? minY!;
                                    const maxYRange =
                                        props.config.maxYRange ?? maxY!;
                                    // Condition to hide circles that goes beyond y axis
                                    if (d.x < minXRange) return true;
                                    if (d.y < minYRange) return true;
                                    if (d.y > maxYRange) return true;
                                    return d.time == null ||
                                        d.time <=
                                            props.content.time!.uniqueValues[
                                                index
                                            ]
                                        ? null
                                        : true;
                                });
                            }}
                        />
                    )}
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            height: "100%",
                        }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{ width: "48px", height: "100%" }}
                        >
                            <div
                                style={{
                                    height: axisItemStyles.yAxisHeight,
                                    position: "relative",
                                    top: -axisItemStyles.yAxisOffset - 1,
                                }}
                            >
                                {props.config.showYAxisName && (
                                    <EditableAxisItem
                                        onChange={(value) => {
                                            onChangeName(1, value);
                                        }}
                                        color={props.config.axesNamesColor}
                                        index={1}
                                        currentEditVariableIndex={
                                            currentEditVariableIndex
                                        }
                                        vertical
                                        name={props.content.data[1].name}
                                        onDrop={linkVariable}
                                        editable={props.editable}
                                    />
                                )}
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <div
                                style={{
                                    width: 48,
                                    minHeight: 48,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {props.editable && (
                                    <div
                                        style={{
                                            width: 32,
                                            height: 32,
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            let data = Array.from(
                                                props.content.data
                                            );
                                            [data[0], data[1]] = [
                                                data[1],
                                                data[0],
                                            ];
                                            props.onChangeContent?.(
                                                {
                                                    ...props.content,
                                                    data: data,
                                                    trendlineInfo: null,
                                                },
                                                props.trendlineInfo != null
                                            );
                                        }}
                                    >
                                        <SwapIcon />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // Resizing does not work properly
                            // without overflow: "hidden"
                            overflow: "hidden",
                        }}
                    >
                        <div
                            ref={parentSvgRef}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                position: "relative",
                                // Resizing does not work properly
                                // without overflow: "hidden"
                                overflow: "hidden",
                            }}
                        >
                            {showTooltipInfo && (
                                <Portal rootNode={document.body}>
                                    <div
                                        style={{
                                            zIndex: 100000,
                                            position: "absolute",
                                            top: showTooltipInfo.y,
                                            left: showTooltipInfo.x,
                                            display: "flex",
                                            pointerEvents: "none",
                                            transform: "translateY(-50%)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 10,
                                                    height: 10,
                                                    transform:
                                                        "translateX(50%) rotate(45deg)",
                                                    ...tooltipStyle.contentStyle,
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px",
                                                ...tooltipStyle.contentStyle,
                                                display: "flex",
                                                flexDirection: "column",
                                                zIndex: 100001,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    ...tooltipStyle.itemStyle,
                                                    color: "rgb(85, 105, 125)",
                                                }}
                                                className="unselectable"
                                            >{`${
                                                props.content.data[0].name
                                            }: ${formatValue(
                                                showTooltipInfo.point.x
                                            ).join("")}`}</span>
                                            <span
                                                style={{
                                                    ...tooltipStyle.itemStyle,
                                                    color: "rgb(85, 105, 125)",
                                                }}
                                                className="unselectable"
                                            >{`${
                                                props.content.data[1].name
                                            }: ${formatValue(
                                                showTooltipInfo.point.y
                                            ).join("")}`}</span>
                                        </div>
                                    </div>
                                </Portal>
                            )}
                            <svg ref={svgRef} />
                        </div>
                        <div
                            className="my-row"
                            style={{
                                // height: "48px" would not be enough since
                                // its size would change when x axis name is
                                // hidden. We have to set min and max height.
                                minHeight: "48px",
                                maxHeight: "48px",
                                position: "relative",
                                width: axisItemStyles.xAxisWidth,
                                left: axisItemStyles.xAxisOffset,
                            }}
                        >
                            {props.config.showXAxisName && (
                                <EditableAxisItem
                                    onChange={(value) => {
                                        onChangeName(0, value);
                                    }}
                                    color={props.config.axesNamesColor}
                                    currentEditVariableIndex={
                                        currentEditVariableIndex
                                    }
                                    editable={props.editable}
                                    index={0}
                                    vertical={false}
                                    name={props.content.data[0].name}
                                    onDrop={linkVariable}
                                    inputStyle={{
                                        width: "100%",
                                    }}
                                />
                            )}
                            <div style={{ flexGrow: 1 }} />
                        </div>
                    </div>
                </div>
            </div>
            {showColorPicker && (
                <Popup
                    arrow={true}
                    contentStyle={{
                        ...chartColorPickerPopupStyles,
                        left: showColorPicker.x,
                        top: showColorPicker.y,
                    }}
                    open={true}
                    onClose={() => {
                        setShowColorPicker(null);
                    }}
                    nested={true}
                    closeOnDocumentClick
                >
                    <ColorPicker
                        enableAlpha={true}
                        width={"220px"}
                        color={props.config.chartColor ?? colorList[1]}
                        onChange={(color) => {
                            let newConfig = {
                                ...props.config,
                                chartColor: color,
                            };
                            props.onChangeConfig?.(newConfig);
                        }}
                    />
                </Popup>
            )}
        </div>
    );
}
