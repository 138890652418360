import React, { Component } from "react";
import { AppModuleProps } from "AppModule";
import { MainComponent as ImportDataPage } from "./ImportDataPage";
import Instrumentation from "common/Instrumentation";
import ScaledPage from "common/ScaledPage";

import { ReactComponent as InputIcon } from "icons/left_nav_menu/input.svg";

import styles from "./main.module.css";


interface Props extends AppModuleProps {
    onReturnToCanvas?: () => void;
    currentModuleId?: number;
    dockSize?: number;
    spreadsheetId?: string;
}

class MainComponent extends Component<Props> {
    private performance: Date | null;
    private mounted: boolean;

    constructor(props: AppModuleProps) {
        super(props);
        this.performance = null;
        this.mounted = false;
    }

    componentDidMount(): void {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Input", timeMs);
        }
    }

    componentDidUpdate(): void {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Input", timeMs);
        }
    }

    render(): JSX.Element {
        if (!this.mounted) {
            this.performance = new Date();
            this.mounted = true;
        }

        let innerComponent = <ImportDataPage {...this.props} />;

        if (this.props.dockSize != null)
            // if page in a dock
            return innerComponent;
        return (
            <ScaledPage>
                <div className="content-wrapper hide-scroll">
                    <section
                        className="content"
                        style={{ ...this.props.positionStyle, height: "100%" }}
                    >
                        {innerComponent}
                    </section>
                </div>
            </ScaledPage>
        );
    }
}

export { MainComponent };
export let route: string = "/data.html",
    section: string = "Input",
    leftPanelItem = {
        section: "Input",
        href: "data.html",
        icon: <InputIcon className={styles.icon} />
    },
    requirePermission: string = "Input";
