import axios from "./ServerConnection";

export function getQualtricsOptions() {
  return axios.get<QualtricsResponse>("/api/external_api_options?slug=QUALTRICS");
}

export interface QualtricsResponse {
  success: boolean;
  error_msg: string;
  options: QualtricOptions;
}

export interface QualtricOptions {
  surveys: Array<QualtricSurvey>;
}

export interface QualtricSurvey {
  id: string;
  name: string;
  ownerId: string;
  lastModified: string;
  creationDate: string;
  isActive: boolean;
}
