import React from "react";

import { CanvasElement, CanvasSlider } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import { DataTableInput } from "modules/canvas_page/DataTableInputSelector";
import InputsConfig from "../../common/InputsConfig";
import DefaultValueOption from "../../common/DefaultValueOption";
import SliderOutputsSelector from "modules/canvas_page/SilderOutputsSelector";
import NumberFormater from "components/Formaters/NumberFormater";

interface Props {
    canvasId: number;
    node: CanvasSlider;
    currentModuleId?: number;
    nodeLinkOptions: NodeLinkOption[];
    dataTableInput: DataTableInput[];
    onChange: (changes: Partial<CanvasSlider>, commit?: boolean) => void;
    onChangeDataTableInput: (newValue: DataTableInput[]) => void;
}

function Data({
    canvasId,
    node,
    currentModuleId,
    nodeLinkOptions,
    dataTableInput,
    onChange,
    onChangeDataTableInput,
}: Props) {
    return (
        <>
            <InputsConfig
                canvasId={canvasId}
                node={node as any as CanvasElement}
                currentModuleId={currentModuleId}
                nodeLinkOptions={nodeLinkOptions}
                dataTableInput={dataTableInput}
                onChange={
                    onChange as (
                        changes: Partial<CanvasElement>,
                        commit?: boolean
                    ) => void
                }
                onChangeDataTableInput={onChangeDataTableInput}
            />
            <SliderOutputsSelector value={node} onChange={onChange} />
            <DefaultValueOption node={node} onChange={onChange} />
            <NumberFormater
                currentFormat={node.format}
                onChange={(format) => onChange({ format })}
            />
        </>
    );
}

export default Data;
