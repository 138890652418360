import axios from "common/ServerConnection";
import { DataScopeOption } from "common/DataScopes";
import { TableOption } from "common/Tables";
import variables, { VariableOption } from "common/Variables";
import StringOption from "common/StringOption";
import { stringSessionId } from "common/SessionId";

export const aggregationOptions = Object.freeze([
    { label: "Mean", value: "mean" },
    { label: "Sum", value: "sum" },
    { label: "Count", value: "count" },
    { label: "Std", value: "std" },
    { label: "Min", value: "min" },
    { label: "Max", value: "max" },
    { label: "None", value: "none" },
]);

export function defaultAggregateOptions(
    dataScopeId: string | number | undefined
) {
    return variables(dataScopeId).dataVariables.map((value) =>
        ["int", "float"].includes(value.type)
            ? aggregationOptions[0]
            : aggregationOptions[6]
    );
}

export function defaultAggregateState(): AggregateState {
    return {
        aggregateDataTable: null,
        dataScope: null,
        updateCurrentBy: [null],
        updateCurrentVariableAggregation: [],
        updateCurrentDropMissing: false,
    };
}

export interface AggregateState {
    aggregateDataTable: TableOption | null;
    dataScope: DataScopeOption | null;
    updateCurrentBy: (VariableOption | null)[];
    updateCurrentVariableAggregation: StringOption[];
    updateCurrentDropMissing: boolean;
    targetDataScope?: DataScopeOption | null;
}

export function updateCurrentAggregate(
    aggregateState: AggregateState,
    onSuccess: (
        resultPreview:
            | {
                  droppedValuesCount: number;
                  tableHeader: string[];
                  tablePreview: any[];
              }
            | undefined,
        targetDataTableIdx?: number
    ) => void,
    onError: (error: string) => void,
    preview = false,
    dropMissing = false,
    moduleId?: number | string | null
) {
    let jsonRequest: {
        add_derived: boolean;
        drop_missing: boolean;
        table: number[];
        data_table_idx: number | string;
        condition_id?: string | null;
        group_indices: number[];
        aggregation: string[];
        preview?: number;
        module_id?: number | string | null;
        update_id?: string | null;
        target_data_table_idx?: number | string | null;
        new_data_table_name?: string | null;
    } = {
        add_derived: false,
        drop_missing: dropMissing,
        table: aggregateState.aggregateDataTable!.value,
        data_table_idx: aggregateState.aggregateDataTable!.data_table_idx!,
        condition_id: aggregateState.aggregateDataTable!.condition_id,
        group_indices: aggregateState.updateCurrentBy
            .filter((item) => item != null)
            .map((item) => item!.value),
        aggregation: aggregateState.updateCurrentVariableAggregation.map(
            (func) => func.value
        ),
        preview: preview ? 5 : undefined,
        module_id: moduleId,
        update_id: stringSessionId(),
    };
    if (aggregateState.targetDataScope != null) {
        if (
            aggregateState.targetDataScope.value != null &&
            !Number.isNaN(aggregateState.targetDataScope.value)
        ) {
            jsonRequest.target_data_table_idx =
                aggregateState.targetDataScope.value;
        } else {
            jsonRequest.new_data_table_name =
                aggregateState.targetDataScope.label;
        }
    }
    axios
        .post<{
            success: boolean;
            error_msg: string;
            dropped: number;
            table_header: string[];
            table_preview: any[];
            target_data_table_idx?: number;
        }>("/api/e/update_current_aggregate", jsonRequest)
        .then((response) => {
            if (response.data.success === false) {
                onError(response.data.error_msg);
            } else {
                if (preview) {
                    onSuccess(
                        {
                            droppedValuesCount: response.data.dropped,
                            tableHeader: response.data.table_header,
                            tablePreview: response.data.table_preview,
                        },
                        undefined
                    );
                } else {
                    onSuccess(undefined, response.data.target_data_table_idx);
                }
            }
        })
        .catch((error) => {
            onError(String(error));
        });
}
