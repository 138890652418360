import React, {
    CSSProperties,
    useRef,
    useState,
    useMemo,
    useCallback,
} from "react";
import { observer } from "mobx-react";
import { LegendElement } from "./LegendElement";
import {
    ColumnFormatType,
    defaultErrorColors,
    getPrettyPrintFormatValue,
    LeadersLaggersFontStyle,
    NumberFormatType,
} from "common/Canvas";
import {
    ArrowIndicatorColumn,
    LeadersLaggersFinding,
    LeadersLaggersLogosColumnData,
} from "common/Finding";
import Box from "@mui/material/Box";
import { getValueSignStyle } from "common/utilities/UIResponsiveManager";
import styles from "./GraphicsComponents/MetricsTable/MetricsTable.module.css";
import CanvasIconsContainter from "modules/canvas_page/CanvasIconsContainer";
import Portal from "common/Portal";
import SubTitle from "./GraphicsComponents/MetricsTable/SubTitle";
import { mapViewScale } from "modules/canvas_page/Constants";
import OutsideAlerter from "common/OutsideAlerter";
import { Resizable } from "re-resizable";
import FormatColumnPopup from "./GraphicsComponents/FormatColumnPopup";
import { strftime } from "common/utilities/TimeFormatUtils";
import Variables from "common/Variables";
import remoteModuleId from "common/remoteModuleId";
import { useLockScrollDirection } from "hooks/useLockScroolDirection";

const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const indexColumnWidth = 40;
const signColumnWidth = 25;
const defaultColumnWidth = 50;
const cellPadding = "8px 0px";
const containerStyle = {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    justifyContent: "center",
};

interface Props {
    editable?: boolean;
    config: LeadersLaggersFinding["config"];
    onColorChange: (color: string) => void;
    onTitleChange: (title: string) => void;
    disableDashboardDrag: (disable: boolean) => void;
    onChangeConfig?: (
        config: LeadersLaggersFinding["config"],
        updateFinding?: boolean
    ) => void;
    data?: { [key: string | number]: string | number | null }[];
    title: string;
    color: string;
    scale: number;
    fontStyle?: LeadersLaggersFontStyle;
    aliaseTextColor?: string;
    aliaseTextSize?: number;
    units?: string | null;
    columnsWidth: { [key: number]: number } | null;
    setColumnsWidth: (
        data:
            | { [key: number]: number }
            | ((
                  prevState: { [key: number]: number } | null
              ) => { [key: number]: number } | null)
    ) => void;
    fitToContent: (key: string | number, index: number) => void;
    currentModuleId?: string;
    onRowClick?: (data:{
        [key: string]: string | number | null;
        [key: number]: string | number | null;
    }) => void;
    live?: boolean;
}

export interface Column {
    label: string;
    field: string | number;
    sort: "desc" | "asc";
    subTitle: string;
    index: number | null;
}



function MetricsTable(props: Props) {
    const {
        editable,
        onChangeConfig,
        config,
        data,
        title,
        color,
        fontStyle,
        aliaseTextColor,
        aliaseTextSize,
        units,
        disableDashboardDrag,
        setColumnsWidth,
        onRowClick
    } = props;
    const tableWrapperRef = useRef(null);
    
    React.useEffect(() => {
        function checkScrollDirection(event: any) {

            console.log('test');
            const container = tableWrapperRef.current as any;
            if (container.scrollLeft === 0 && container.scrollTop !== 0) {
                // Vertical scrolling
                container.style.overflowY = 'auto';
                container.style.overflowX = 'hidden';
            } else if (container.scrollTop === 0 && container.scrollLeft !== 0) {
                // Horizontal scrolling
                container.style.overflowX = 'auto';
                container.style.overflowY = 'hidden';
            }
        }

        if (tableWrapperRef.current) {
            (tableWrapperRef.current as any).addEventListener('scroll', checkScrollDirection);
        }

        return () => {
            if (tableWrapperRef.current) {
                (tableWrapperRef.current as any).removeEventListener('scroll', checkScrollDirection);
            }
        };
    }, [tableWrapperRef]);

    const {
        dataScope,
        targetVariableIndex,
        targetVariableSubTitle = "",
        targetVariableFormat = {
            numberType: NumberFormatType.Number,
            decimals: 2,
        },
        shownVariableIndices,
        shownVariableSubTitles,
        shownVariableFormats,
        variableAliases,
        columnsProps,
        showRankNumber = true,
        showLegend = true,
    } = config;
    const arrowIndicatorColumn: ArrowIndicatorColumn =
        config.arrowIndicatorColumn;

    let columnsWidth: { [key: string | number]: number } = useMemo(() => {
        if (
            props.columnsWidth == null ||
            Object.keys(props.columnsWidth).length === 0
        ) {
            return columnsProps?.width ?? [];
        } else {
            return props.columnsWidth ?? {};
        }
    }, [props.columnsWidth, columnsProps?.width]);

    const calculateColumnWidth = useCallback(
        (initialWidth: number, delta: number): number => {
            return Math.max(0, initialWidth + delta);
        },
        []
    );

    const [formatingPopup, setFormatingPopup] = useState<{
        showPopup: boolean;
        position: Record<string, number>;
    }>({
        showPopup: false,
        position: {
            x: 0,
            y: 0,
        },
    });

    const [resizing, setResizing] = useState<{
        initialWidth: number;
    } | null>(null);

    const [
        currentEditableVariableIndex,
        setCurrentEditableVariableIndex,
    ] = useState<number | null>(null);
    const [
        showFormatingDropdownButton,
        setShowFormatingDropdownButton,
    ] = useState(false);
    const [editTitleMode, setEditTitleMode] = useState(false);
    // Column resizing should always be enabled now
    // const columnResizingIsEnabled = columnsProps?.enableResizing ?? false;

    let dataVariables = Variables(
        dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).dataVariables;

    let columns: Column[] = useMemo(() => {
        let columns: Column[] = [
            {
                label: "",
                field: "index",
                sort: "desc",
                subTitle: "",
                index: null,
            },
        ];
        columns.push({
            label:
                targetVariableIndex != null
                    ? variableAliases[targetVariableIndex] ??
                      dataVariables[targetVariableIndex]?.name
                    : "variable",
            field: targetVariableIndex ?? "preview",
            sort: "desc",
            subTitle: targetVariableSubTitle,
            index: -1,
        });
        if (arrowIndicatorColumn?.isAllowed) {
            columns.splice(1, 0, {
                label: "",
                field: "sign",
                sort: "desc",
                subTitle: "",
                index: null,
            });
        }
        shownVariableIndices.forEach((index: number | null, key: number) => {
            columns.push({
                label:
                    index != null
                        ? variableAliases[index] ?? dataVariables[index]?.name
                        : `variable ${key + 1}`,
                field: index ?? "preview",
                sort: "desc",
                subTitle: shownVariableSubTitles?.[key] ?? "",
                index: key,
            });
        });
        return columns;
    }, [
        dataVariables,
        variableAliases,
        targetVariableIndex,
        targetVariableSubTitle,
        arrowIndicatorColumn?.isAllowed,
        shownVariableIndices,
        shownVariableSubTitles,
    ]);

    let [rows, isThereAnySubtitle]: [
        { [key: string | number]: string | number | null }[],
        boolean
    ] = useMemo(() => {
        let isThereAnySubtitle = false;
        let rows: { [key: string | number]: string | number | null }[] =
            data?.map((item, index) => {
                let fixedItem: {
                    [key: string | number]: string | number | null;
                } = {
                    index: index + 1,
                    row_id: item['row_id'],
                    sign: arrowIndicatorColumn?.sign ?? "",
                };
                let unitsWithSpace = units != null ? units.concat(" ") : "";

                if (targetVariableIndex == null) {
                    fixedItem["preview"] = item["preview"];
                } else if (typeof item[targetVariableIndex] !== "undefined") {
                    let formatedValue = "";
                    if (
                        targetVariableFormat?.type === ColumnFormatType.Date &&
                        typeof item[targetVariableIndex] === "string"
                    ) {
                        formatedValue = strftime(
                            targetVariableFormat.format,
                            new Date(String(item[targetVariableIndex]))
                        );
                    } else {
                        formatedValue = getPrettyPrintFormatValue(
                            {
                                metric: String(item[targetVariableIndex]),
                                value: item[targetVariableIndex],
                            },
                            targetVariableFormat
                        );
                    }

                    fixedItem[targetVariableIndex] = unitsWithSpace.concat(
                        formatedValue
                    );
                }

                if (targetVariableSubTitle) isThereAnySubtitle = true;

                shownVariableIndices.forEach(
                    (index: number | null, key: number) => {
                        if (
                            shownVariableSubTitles?.[key] &&
                            !isThereAnySubtitle
                        )
                            isThereAnySubtitle = true;

                        if (index == null) {
                            fixedItem["preview"] = item["preview"];
                        } else if (
                            index != null &&
                            dataVariables[index]?.level !== "month"
                        ) {
                            const format = shownVariableFormats?.[key];
                            if (format) {
                                if (
                                    format.type === ColumnFormatType.Date &&
                                    typeof item[index] === "string"
                                ) {
                                    fixedItem[index] = strftime(
                                        shownVariableFormats[key].format,
                                        new Date(String(item[key]))
                                    );
                                } else {
                                    fixedItem[
                                        index
                                    ] = getPrettyPrintFormatValue(
                                        {
                                            metric: String(item[index]),
                                            value: item[index],
                                        },
                                        shownVariableFormats[key]
                                    );
                                }
                            } else {
                                fixedItem[index] = item[index]
                                    ? item[index]
                                    : "-";
                            }
                        } else
                            fixedItem[index ?? "preview"] =
                                monthName[
                                    (item[index ?? "preview"] as number) - 1
                                ];
                    }
                );

                // Set sign indicator for value
                let columnValue = arrowIndicatorColumn
                    ? item[arrowIndicatorColumn.value]
                    : null;
                if (typeof columnValue === "string") {
                    columnValue = columnValue.replace("$", "").trim();
                }

                if (columnValue != null) {
                    const value =
                        typeof columnValue === "string"
                            ? parseFloat(columnValue as string)
                            : columnValue;
                    if (value > 0) fixedItem.sign = "positive";
                    else if (value < 0) fixedItem.sign = "negative";
                    else fixedItem.sign = "neutral";
                }

                return fixedItem;
            }) ?? [];
        return [rows, isThereAnySubtitle];
    }, [
        data,
        units,
        variableAliases,
        targetVariableIndex,
        targetVariableSubTitle,
        targetVariableFormat,
        shownVariableIndices,
        shownVariableSubTitles,
        shownVariableFormats,
        arrowIndicatorColumn,
    ]);

    const legendElementPosition = useMemo(
        () => ({
            x: config.legendPosition?.x ?? 0,
            y: config.legendPosition?.y ?? 0,
        }),
        [config.legendPosition?.x, config.legendPosition?.y]
    );

    const buildHeader = useCallback(
        (column: Column, idx: number) => {
            const isIndexField = column.field === "index";
            const noHeaderField =
                column.field === "index" || column.field === "sign";
            if (isIndexField && !showRankNumber) {
                return (
                    <div
                        className={styles.headerCell}
                        key={idx}
                        style={{
                            position: "relative",
                            wordWrap: "break-word",
                            width: indexColumnWidth,
                            minWidth: indexColumnWidth,
                            maxWidth: indexColumnWidth,
                            ...fontStyle?.title,
                            textAlign: fontStyle?.alignment?.value ?? "left",
                            padding: cellPadding,
                        }}
                    />
                );
            } else if (
                column.field === "sign" &&
                arrowIndicatorColumn?.isAllowed
            ) {
                return (
                    <div
                        className={styles.headerCell}
                        key={idx}
                        style={{
                            position: "relative",
                            wordWrap: "break-word",
                            width: signColumnWidth,
                            minWidth: signColumnWidth,
                            maxWidth: signColumnWidth,
                            ...fontStyle?.title,
                            textAlign: fontStyle?.alignment?.value ?? "left",
                            padding: cellPadding,
                        }}
                    />
                );
            }

            const activateEditTitleMode =
                dataScope &&
                targetVariableIndex != null &&
                (isThereAnySubtitle || (editTitleMode && editable));

            return (
                <div
                    className={styles.headerCell}
                    key={idx}
                    style={{
                        position: "relative",
                        wordWrap: "break-word",
                        width: isIndexField
                            ? indexColumnWidth
                            : columnsWidth[column.field] ?? defaultColumnWidth,
                        minWidth: isIndexField
                            ? indexColumnWidth
                            : columnsWidth[column.field] ?? defaultColumnWidth,
                        maxWidth: isIndexField
                            ? indexColumnWidth
                            : columnsWidth[column.field] ?? defaultColumnWidth,
                        ...fontStyle?.title,
                        textAlign: fontStyle?.alignment?.value ?? "left",
                        padding: cellPadding,
                        boxSizing: "border-box",
                        borderRight:
                            editable && !noHeaderField && !props.live
                                ? "1px solid #d1d1d1"
                                : undefined,
                    }}
                >
                    <p
                        style={{
                            margin: 0,
                            cursor: editable ? "pointer" : "auto",
                        }}
                        onDoubleClick={() => {
                            if (!editable) return;
                            setEditTitleMode(!editTitleMode);
                        }}
                    >
                        {column.label}
                    </p>
                    {activateEditTitleMode && (
                        <SubTitle
                            editable={editable}
                            noHeaderField={noHeaderField}
                            column={column}
                            config={config}
                            onChangeConfig={onChangeConfig}
                            fontStyle={fontStyle}
                            currentEditableVariableIndex={
                                currentEditableVariableIndex
                            }
                            setCurrentEditableVariableIndex={
                                setCurrentEditableVariableIndex
                            }
                        />
                    )}

                    {!props.live && !noHeaderField && showFormatingDropdownButton && (
                        <button
                            className={styles.formatColumnDropdownButton}
                            onClick={(e) => {
                                setFormatingPopup({
                                    ...formatingPopup,
                                    position: {
                                        x: e.clientX - 15,
                                        y: e.clientY + 5,
                                    },
                                    showPopup: !formatingPopup.showPopup,
                                });
                                setCurrentEditableVariableIndex(column.index);
                            }}
                        >
                            <CanvasIconsContainter
                                type={"FormatMenu"}
                                options={{
                                    width: 12,
                                    height: 12,
                                }}
                            />
                        </button>
                    )}
                </div>
            );
        },
        [
            config,
            showRankNumber,
            arrowIndicatorColumn?.isAllowed,
            dataScope,
            targetVariableIndex,
            isThereAnySubtitle,
            editTitleMode,
            editable,
            columnsWidth,
            currentEditableVariableIndex,
            fontStyle,
            formatingPopup,
            onChangeConfig,
            showFormatingDropdownButton,
        ]
    );

    const buildRows = useCallback(
        (
            row: { [key: string | number]: string | number | null },
            itemIdx: number,
            key: string | number
        ) => {
            if (
                config.logoColumn?.label === key &&
                typeof row[key] === "string" &&
                row[key]
            ) {
                const fontSize = fontStyle?.text?.fontSize ?? 10;
                const values = (row[key] as string)
                    .replaceAll(/\s/g, "")
                    .split(",");
                const logosFirstLetters = values.map((logo) => logo[0]);
                const logoData: LeadersLaggersLogosColumnData[] = config.logosColumnData.filter(
                    (data: LeadersLaggersLogosColumnData) =>
                        values.includes(data.label)
                );
                return (
                    <div
                        className={styles.cell}
                        key={itemIdx}
                        style={{
                            ...fontStyle?.text,
                            wordWrap: "break-word",
                        }}
                    >
                        {logoData.map((data, idx) => (
                            <div
                                className={styles.logo}
                                style={{
                                    backgroundColor: data.background ?? "blue",
                                    color: data?.color ?? "white",
                                    lineHeight: `${fontSize}px`,
                                }}
                            >
                                <p
                                    className={styles.logoText}
                                    style={{
                                        height: `${fontSize}px`,
                                    }}
                                >
                                    {data?.textLogo || logosFirstLetters[idx]}
                                </p>
                            </div>
                        ))}
                    </div>
                );
            } else {
                return (
                    <div
                        className={styles.cell}
                        key={itemIdx}
                        style={{
                            ...fontStyle?.text,
                            wordWrap: "break-word",
                        }}
                    >
                        {(row[key] && row[key] !== "null") ? row[key] : "-"}
                    </div>
                );
            }
        },
        [config.logoColumn?.label, fontStyle?.text, config.logosColumnData]
    );

    if (data == null) {
        return null;
    }

    return (
        <div>
            <LegendElement
                containerStyle={containerStyle}
                color={color}
                text={title}
                textColor={aliaseTextColor}
                textSize={aliaseTextSize}
                showLegend={showLegend}
                textStyle={(fontStyle?.aliase ?? {}) as CSSProperties}
                legendPosition={{
                    x: legendElementPosition.x,
                    y: 0,
                }}
                scale={props.scale / mapViewScale}
                onChangeLegendPosition={(position) => {
                    let newConfig = {
                        ...config,
                        legendPosition: position,
                    };

                    onChangeConfig?.(newConfig, true);
                }}
            />
            <div className={styles.table} ref={tableWrapperRef}>
                <div
                    className={styles.header}
                    onMouseEnter={() => {
                        if(!props.live) {
                            setShowFormatingDropdownButton(true);
                        }                        
                    }}
                    onMouseLeave={() => {
                        if(!props.live) {
                            setShowFormatingDropdownButton(false);
                        }                        
                    }}
                >
                    {columns.map((column, idx) => {
                        const firstColumn = idx === 0;
                        const noHeaderField =
                            column.field === "index" ||
                            column.field === "sign";
                        return (
                            <Resizable
                                enable={{
                                    top: false,
                                    right:
                                        // Resizing should always be enabled, except in live mode.
                                        !firstColumn && !noHeaderField && !props.live,
                                    bottom: false,
                                    left: false,
                                    topRight: false,
                                    bottomRight: false,
                                    bottomLeft: false,
                                    topLeft: false,
                                }}
                                onResizeStart={(evt) => {
                                    evt.stopPropagation();
                                    setResizing({
                                        initialWidth:
                                            columnsWidth[column.field] ??
                                            defaultColumnWidth,
                                    });
                                }}
                                onResize={(evt, direction, ref, d) => {
                                    evt.stopPropagation();
                                    if (resizing != null) {
                                        setColumnsWidth((columnsWidth) => ({
                                            ...columnsWidth,
                                            [column.field]: calculateColumnWidth(
                                                resizing.initialWidth,
                                                d.width
                                            ),
                                        }));
                                    }
                                }}
                                onResizeStop={(evt, direction, ref, d) => {
                                    if (resizing != null) {
                                        let newConfig = {
                                            ...config,
                                            columnsProps: {
                                                ...(config?.columnsProps ?? {}),
                                                width: {
                                                    ...columnsWidth,
                                                    [column.field]: calculateColumnWidth(
                                                        resizing.initialWidth,
                                                        d.width
                                                    ),
                                                },
                                            },
                                        };
                                        onChangeConfig?.(newConfig, true);
                                        disableDashboardDrag(false);
                                        setResizing(null);
                                    }
                                }}
                                size={{
                                    width:
                                        column.field === "index"
                                            ? indexColumnWidth
                                            : column.field === "sign"
                                            ? signColumnWidth
                                            : columnsWidth?.[column.field] ??
                                              defaultColumnWidth,
                                    height: "auto",
                                }}
                                style={{
                                    minWidth: 0,
                                    padding: cellPadding,
                                }}
                                className="resizer"
                            >
                                {buildHeader(column, idx)}
                            </Resizable>
                        );
                    })}
                    {(!props.live) && (formatingPopup.showPopup) && (
                        <Portal rootNode={document.body}>
                            <OutsideAlerter
                                onReject={() => {
                                    setFormatingPopup({
                                        ...formatingPopup,
                                        showPopup: false,
                                    });
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        left: formatingPopup.position.x,
                                        top: formatingPopup.position.y,
                                        zIndex: 999999,
                                    }}
                                >
                                    <FormatColumnPopup
                                        allowLocationGeneration={false}
                                        allowWidthInput={true}
                                        width={
                                            columnsWidth?.[
                                                columns.find(
                                                    (column) =>
                                                        column.index ===
                                                        currentEditableVariableIndex
                                                )?.field ?? ""
                                            ] ?? defaultColumnWidth
                                        }
                                        fitToContent={() => {
                                            if (
                                                currentEditableVariableIndex !=
                                                null
                                            ) {
                                                let column = columns.find(
                                                    (column) =>
                                                        column.index ===
                                                        currentEditableVariableIndex
                                                );
                                                if (column != null) {
                                                    props.fitToContent(
                                                        column.field,
                                                        currentEditableVariableIndex
                                                    );
                                                }
                                            }
                                        }}
                                        columnFormat={
                                            currentEditableVariableIndex === -1
                                                ? targetVariableFormat
                                                : shownVariableFormats[
                                                      currentEditableVariableIndex ??
                                                          0
                                                  ]
                                        }
                                        onClose={(apply, format, width) => {
                                            if (
                                                currentEditableVariableIndex ==
                                                null
                                            )
                                                return;

                                            let newConfig = {
                                                ...config,
                                            };

                                            if (
                                                currentEditableVariableIndex ===
                                                -1
                                            ) {
                                                newConfig.targetVariableFormat = format;
                                            } else {
                                                newConfig.shownVariableFormats[
                                                    currentEditableVariableIndex
                                                ] = {
                                                    ...newConfig
                                                        .shownVariableFormats[
                                                        currentEditableVariableIndex
                                                    ],
                                                    ...format,
                                                };
                                            }

                                            // Change width
                                            let column = columns.find(
                                                (column) =>
                                                    column.index ===
                                                    currentEditableVariableIndex
                                            );
                                            if (
                                                column != null &&
                                                width != null &&
                                                (columnsWidth?.[column.field] ??
                                                    defaultColumnWidth) !==
                                                    width
                                            ) {
                                                setColumnsWidth(
                                                    (columnsWidth) => ({
                                                        ...columnsWidth,
                                                        [column!.field]: width,
                                                    })
                                                );

                                                newConfig.columnsProps = {
                                                    ...(config?.columnsProps ??
                                                        {}),
                                                    width: {
                                                        ...columnsWidth,
                                                        [column.field]: width,
                                                    },
                                                };
                                            }

                                            onChangeConfig?.(newConfig, true);
                                            setFormatingPopup({
                                                ...formatingPopup,
                                                showPopup: false,
                                            });
                                        }}
                                    />
                                </div>
                            </OutsideAlerter>
                        </Portal>
                    )}
                </div>
                {rows.map((row, idx) => (
                    <div
                        onClick={() => {
                            if(props.config.navigation?.status)
                                onRowClick?.(row)
                        }}
                        className={styles.row}
                        key={idx}
                        style={{
                            textAlign: fontStyle?.alignment?.value ?? "left",
                            cursor: (props.live && props.config.navigation?.status)? "pointer" : "default"
                        }}
                    >
                        {columns.map(({ field }, itemIdx) => {
                            if (row[field] === "-") {
                                row[field] = "";
                            }
                            if (field === "index" && !showRankNumber) {
                                return (
                                    <div
                                        className={styles.cell}
                                        style={{
                                            width: indexColumnWidth,
                                            minWidth: indexColumnWidth,
                                            maxWidth: indexColumnWidth,
                                        }}
                                    />
                                );
                            } else if (field === "sign") {
                                if (arrowIndicatorColumn?.isAllowed) {
                                    let errorBoxStyles: React.CSSProperties = {};
                                    const { sign } = row;
                                    errorBoxStyles = getValueSignStyle(
                                        String(sign),
                                        arrowIndicatorColumn?.errorColors ??
                                            defaultErrorColors
                                    );
                                    return (
                                        <div
                                            style={{
                                                width: signColumnWidth,
                                                minWidth: signColumnWidth,
                                                maxWidth: signColumnWidth,
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box sx={errorBoxStyles}></Box>
                                        </div>
                                    );
                                } else {
                                    return <div />;
                                }
                            }

                            // const lastColumn =
                            //     itemIdx === Object.keys(row).length - 1;
                            // const firstColumn = itemIdx === 0;

                            return (
                                <Resizable
                                    enable={{
                                        top: false,
                                        right:
                                            // Resizing should only be enabled
                                            // in the header now
                                            /* !lastColumn &&
                                            !firstColumn &&
                                            columnResizingIsEnabled
                                                ? true
                                                : */ false,
                                        bottom: false,
                                        left: false,
                                        topRight: false,
                                        bottomRight: false,
                                        bottomLeft: false,
                                        topLeft: false,
                                    }}
                                    onResizeStart={(evt) => {
                                        evt.stopPropagation();
                                        setResizing({
                                            initialWidth:
                                                columnsWidth[field] ??
                                                defaultColumnWidth,
                                        });
                                    }}
                                    onResize={(evt, direction, ref, d) => {
                                        evt.stopPropagation();
                                        if (resizing != null) {
                                            setColumnsWidth((columnsWidth) => ({
                                                ...columnsWidth,
                                                [field]: calculateColumnWidth(
                                                    resizing.initialWidth,
                                                    d.width
                                                ),
                                            }));
                                        }
                                    }}
                                    onResizeStop={(evt, direction, ref, d) => {
                                        if (resizing != null) {
                                            let newConfig = {
                                                ...config,
                                                columnsProps: {
                                                    ...(config?.columnsProps ??
                                                        {}),
                                                    width: {
                                                        ...columnsWidth,
                                                        [field]: calculateColumnWidth(
                                                            resizing.initialWidth,
                                                            d.width
                                                        ),
                                                    },
                                                },
                                            };
                                            onChangeConfig?.(newConfig, true);
                                            disableDashboardDrag(false);
                                            setResizing(null);
                                        }
                                    }}
                                    size={{
                                        width:
                                            field === "index"
                                                ? indexColumnWidth
                                                : columnsWidth?.[field] ??
                                                  defaultColumnWidth,
                                        height: "auto",
                                    }}
                                    style={{
                                        minWidth: 0,
                                        padding: cellPadding,
                                    }}
                                    className="resizer"
                                >
                                    {buildRows(row, itemIdx, field)}
                                </Resizable>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default observer(MetricsTable);
