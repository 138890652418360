import React, { CSSProperties } from "react";
import { MultipleChoiceQuestion } from "common/Canvas";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";

interface Props {
    showPlusButton?: boolean;
    question: MultipleChoiceQuestion;
    maxHeight: number;
    live: boolean;
    frozen: boolean;
    multiselection: boolean;
    onChange: (question: MultipleChoiceQuestion) => void;
    forceRowRepresentation?: boolean;
    fontStyle?: CSSProperties;
}

export default function MultipleChoiceQuestionView(props: Props) {
    const selectOption = (index: number) => {
        if (props.multiselection) {
            let question = Object.assign({}, props.question);
            question.options[index].selected = !question.options[index]
                .selected;
            props.onChange(question);
        } else {
            let question = Object.assign({}, props.question);
            for (let i = 0; i < question.options.length; ++i)
                question.options[i].selected = i === index;
            props.onChange(question);
        }
    };
    let options = props.question.options;
    let isGridRepresentation =
        !props.forceRowRepresentation &&
        props.live &&
        options.reduce((acc, val) => acc || val.option.length > 9, false);
    let style = isGridRepresentation
        ? {
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, 250px)",
              gridRowGap: "20px",
              gridColumnGap: "20px",
          }
        : { display: "flex" };
    return (
        <div style={style}>
            {options.map((item, index) => {
                let selected = false;
                if (props.live) selected = item.selected;

                let journeyVariableStyle = "questionnaire-variable".concat(
                    selected ? "-selected" : ""
                );
                let journeyVariableSmallStyle = "questionnaire-small-variable".concat(
                    selected ? "-selected" : ""
                );
                if (!isGridRepresentation) {
                    return (
                        <div
                            className={journeyVariableStyle}
                            key={index}
                            style={{
                                margin: 20,
                                maxHeight: 175,
                                maxWidth: 160,
                                padding: 2,
                                height: props.maxHeight,
                                width: props.maxHeight - 3,
                            }}
                            onClick={(evt) => {
                                if (props.live) {
                                    evt.stopPropagation();
                                    selectOption(index);
                                }
                            }}
                        >
                            {selected ? (
                                <div
                                    style={{
                                        justifyContent: "flex-end",
                                        display: "flex",
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/data-exploration/icon_checkbox.png"
                                    />
                                </div>
                            ) : (
                                <div style={{ height: "27px" }} />
                            )}

                            <div
                                className="flex-simple-column"
                                style={{
                                    height: "calc(100% - 27px)",
                                    paddingTop: "10px",
                                }}
                            >
                                {props.live && (
                                    <span
                                        style={{
                                            cursor: "default",
                                            textAlign: "center",
                                            wordBreak: "break-word",
                                            fontFamily: "Roboto",
                                            fontSize: "25px",
                                            lineHeight: "29px",
                                            color: selected
                                                ? dataScienceElementsStyle.primaryTextColor
                                                : dataScienceElementsStyle.secondaryTextColor,
                                            display: "block",
                                            ...props.fontStyle,
                                        }}
                                    >
                                        {item.option}
                                    </span>
                                )}
                                {!props.live && (
                                    <input
                                        disabled={props.frozen}
                                        autoFocus
                                        defaultValue={item.option}
                                        placeholder={"Choice ".concat(
                                            String(index + 1)
                                        )}
                                        style={{
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            width: "100%",
                                            fontSize: "25px",
                                            border: "none",
                                            outline: "none",
                                            backgroundColor: "transparent",
                                            fontFamily: "Roboto",
                                            color:
                                                dataScienceElementsStyle.primaryTextColor,
                                        }}
                                        onMouseDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onBlur={(evt) => {
                                            let question = Object.assign(
                                                {},
                                                props.question
                                            );
                                            question.options[index].option =
                                                evt.target.value;
                                            props.onChange(question);
                                        }}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                            if (evt.key === "Enter") {
                                                evt.preventDefault();
                                                let question = Object.assign(
                                                    {},
                                                    props.question
                                                );
                                                question.options[index].option =
                                                    evt.currentTarget.value;
                                                if (
                                                    index ===
                                                    question.options.length - 1
                                                ) {
                                                    question.options.push({
                                                        option: "",
                                                        selected: false,
                                                    });
                                                }

                                                props.onChange(question);
                                            }
                                            if (
                                                evt.key === "Backspace" &&
                                                !evt.currentTarget.value &&
                                                index > 0
                                            ) {
                                                evt.preventDefault();
                                                let question = Object.assign(
                                                    {},
                                                    props.question
                                                );
                                                question.options.splice(
                                                    index,
                                                    1
                                                );
                                                props.onChange(question);
                                            }
                                        }}
                                    ></input>
                                )}
                                <div style={{ flexGrow: 1 }} />
                                <div
                                    className={journeyVariableSmallStyle}
                                    style={{
                                        height: "28px",
                                        width: "26px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        marginBottom: 22,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "Roboto",
                                            fontSize: "20px",
                                            lineHeight: "23px",
                                            color: selected
                                                ? dataScienceElementsStyle.primaryTextColor
                                                : dataScienceElementsStyle.secondaryTextColor,
                                        }}
                                    >
                                        {String.fromCharCode(65 + index)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            onClick={(evt) => {
                                if (props.live) {
                                    evt.stopPropagation();
                                    selectOption(index);
                                }
                            }}
                            key={index}
                            className={"my-row ".concat(journeyVariableStyle)}
                            style={{
                                justifyContent: "space-between",
                                minHeight: "56px",
                                width: "250px",
                                cursor: "pointer",
                            }}
                        >
                            <div
                                className="my-row"
                                style={{ alignItems: "center" }}
                            >
                                <div
                                    className={journeyVariableSmallStyle}
                                    style={{
                                        margin: 10,
                                        height: "28px",
                                        minWidth: "26px",
                                    }}
                                >
                                    <div
                                        className="center-container"
                                        style={{
                                            height: "100%",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "Roboto",
                                                fontSize: "20px",
                                                lineHeight: "23px",
                                                color: selected
                                                    ? dataScienceElementsStyle.primaryTextColor
                                                    : dataScienceElementsStyle.secondaryTextColor,
                                                fontWeight: 300,
                                            }}
                                        >
                                            {String.fromCharCode(65 + index)}
                                        </span>
                                    </div>
                                </div>
                                <span
                                    style={{
                                        textAlign: "center",
                                        wordBreak: "break-word",
                                        fontFamily: "Roboto",
                                        fontSize: "20px",
                                        lineHeight: "23px",
                                        color: selected
                                            ? dataScienceElementsStyle.primaryTextColor
                                            : dataScienceElementsStyle.secondaryTextColor,
                                        fontWeight: 300,
                                        display: "block",
                                    }}
                                >
                                    {item.option}
                                </span>
                            </div>
                            {selected ? (
                                <div
                                    style={{
                                        margin: 20,
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/data-exploration/icon_checkbox.png"
                                    />
                                </div>
                            ) : (
                                <div style={{ height: "27px" }} />
                            )}
                        </div>
                    );
                }
            })}
            {props.showPlusButton && (
                <button
                    onClick={() => {
                        let question = Object.assign({}, props.question);

                        question.options.push({
                            option: "",
                            selected: false,
                        });

                        props.onChange(question);
                    }}
                    className="my-row"
                    style={{
                        alignItems: "center",
                        height: "175px",
                        width: "40px",
                        margin: 20,
                        justifyContent: "center",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Roboto",
                            fontSize: 17,
                            color: "#1F8EFA",
                            fontWeight: 300,
                        }}
                    >
                        {"\uFF0B"}
                    </span>
                </button>
            )}
        </div>
    );
}