import React, { Component } from "react";
import CustomBarChartWithOverlay from "common/graphics/CustomBarChartWithOverlay";
import { mainStyle } from "common/MainStyle";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import StringUtils from "common/utilities/StringUtils";
import sections from "sections.json";

class LeverInput extends Component {
    constructor(props) {
        super(props);
        let decimals = props.decimals ?? 1;
        this.state = {
            value: props.value?.toFixed(decimals) ?? "",
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            let decimals = this.props.decimals ?? 1;

            let numValue = NaN;
            if (StringUtils.isNumber(this.state.value)) {
                numValue = Number(this.state.value);
            }
            if (Number.isNaN(this.props.value) && Number.isNaN(numValue))
                return;
            if (this.props.value !== numValue) {
                this.setState({
                    value: this.props.value?.toFixed(decimals) ?? "",
                });
            }
        } else if (prevProps.decimals !== this.props.decimals) {
            this.setState({
                value:
                    this.props.value?.toFixed(this.props.decimals ?? 1) ?? "",
            });
        }
    }

    render() {
        return (
            <div
                className="flex-simple-column"
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                <input
                    style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                        marginRight: "5px",
                        width: "100px",
                    }}
                    className="like-select"
                    value={this.state.value}
                    onChange={(e) => {
                        let newValue = e.target.value;
                        this.setState({ value: newValue });
                        if (StringUtils.isNumber(newValue)) {
                            let numValue = Number(newValue);
                            this.props.onChange(numValue);
                        } else this.props.onChange(NaN);
                    }}
                ></input>

                <span
                    style={{
                        width: "100%",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: mainStyle.getPropertyValue(
                            "--graphs-axes-text-color"
                        ),
                        display: "block",
                        textAlign: "center",
                        wordWrap: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {this.props.name}
                </span>
            </div>
        );
    }
}

class HoldOutPredictionParametersChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentVariable: undefined,
        };
        this.selectPreviousVariable = this.selectPreviousVariable.bind(this);
        this.selectNextVariable = this.selectNextVariable.bind(this);
        this.selectVariable = this.selectVariable.bind(this);
        this.customRefs = {};
        this.rootRef = null;
        this.prevClientHeight = 0;
    }
    selectVariable(variable, scroll, index) {
        this.setState(
            {
                currentVariable: {
                    name: variable,
                    clustIndex: index,
                },
            },
            () => {
                this.props.clust.forEach((clust, clustIndex) => {
                    let key = "clustBarChart".concat(clustIndex);
                    if (index === clustIndex) {
                        if (key in this.customRefs)
                            this.customRefs[
                                "clustBarChart".concat(clustIndex)
                            ]?.selectVariable(variable, scroll);
                    } else {
                        if (key in this.customRefs)
                            this.customRefs[
                                "clustBarChart".concat(clustIndex)
                            ]?.selectVariable(undefined, false);
                    }
                });
            }
        );
    }
    selectPreviousVariable(currentVariable, scroll, clustIndex) {
        let index = this.props.continuousVariables.findIndex(
            (item) => item === currentVariable
        );
        let newClustIndex = clustIndex;
        if (index > 0) {
            index = index - 1;
        } else {
            newClustIndex = (clustIndex - 1) % this.props.clust.length;
            if (newClustIndex < 0) newClustIndex = this.props.clust.length - 1;
            index = this.props.continuousVariables.length - 1;
        }
        let newVariable = this.props.continuousVariables[index];
        this.selectVariable(newVariable, scroll, newClustIndex);
    }
    selectNextVariable(currentVariable, scroll, clustIndex) {
        let index = this.props.continuousVariables.findIndex(
            (item) => item === currentVariable
        );
        let newClustIndex = clustIndex;
        if (index < this.props.continuousVariables.length - 1) {
            index = index + 1;
        } else {
            newClustIndex = (clustIndex + 1) % this.props.clust.length;
            index = 0;
        }
        let newVariable = this.props.continuousVariables[index];
        this.selectVariable(newVariable, scroll, newClustIndex);
    }

    buildLeversInRow(clustIndex) {
        let clustItem = this.props.clust[clustIndex];
        let categoriesClustItem = this.props.categoriesClust[clustIndex];
        return (
            <div
                className="my-row"
                style={{ height: "250px", flexWrap: "wrap", overflow: "auto" }}
            >
                {clustItem.map((item, index) => {
                    return (
                        <LeverInput
                            key={index}
                            decimals={this.props.decimals?.[item.name]}
                            name={item.name}
                            value={item.value}
                            onChange={(value) => {
                                let clust = Array.from(this.props.clust);
                                clust[clustIndex][index].value = value;
                                this.props.onClustChange(clust);
                            }}
                        />
                    );
                })}
                {categoriesClustItem.map((item, index) => {
                    return this.buildCategoryItem(item, clustIndex, index);
                })}
            </div>
        );
    }
    buildCategoryItem(item, clustIndex, index) {
        return (
            <div
                key={index}
                className="flex-simple-column"
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                <Select
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({
                            ...base,
                            height: "38px",
                            width: "100px",
                            marginTop: "5px",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }),
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 100000000,
                        }),
                    }}
                    options={item.values.map((value) => ({
                        label: value,
                        value: value,
                    }))}
                    value={{
                        label: item.value,
                        value: item.value,
                    }}
                    onChange={(newValue) => {
                        let categoriesClust = Array.from(
                            this.props.categoriesClust
                        );
                        categoriesClust[clustIndex][index].value =
                            newValue.value;
                        this.props.onCategoriesClustChange(categoriesClust);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                <span
                    style={{
                        width: "100%",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: mainStyle.getPropertyValue(
                            "--graphs-axes-text-color"
                        ),
                        display: "block",
                        textAlign: "center",
                        wordWrap: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {item.name}
                </span>
            </div>
        );
    }

    buildCategoricalColumns(clustIndex) {
        let subcategoriesClust = this.props.categoriesClust[clustIndex];
        return (
            <div className="my-row">
                {subcategoriesClust.map((item, index) => {
                    return this.buildCategoryItem(item, clustIndex, index);
                })}
            </div>
        );
    }

    componentDidUpdate(nextProps) {
        let currentHeight = this.rootRef?.clientHeight ?? 0;
        if (
            currentHeight !== this.prevClientHeight ||
            (!this.state.currentVariable &&
                this.props.continuousVariables.length > 0)
        ) {
            setTimeout(() => {
                this.selectVariable(
                    this.props.continuousVariables[0],
                    false,
                    0
                );
            }, 300);
        }
        this.prevClientHeight = currentHeight;
    }

    componentDidMount() {
        if (
            !this.state.currentVariable &&
            this.props.continuousVariables.length > 0
        ) {
            setTimeout(() => {
                this.selectVariable(
                    this.props.continuousVariables[0],
                    false,
                    0
                );
            }, 300);
        }
    }

    render() {
        if (
            this.props.categoricalVariables.length +
                this.props.continuousVariables.length ===
            0
        )
            return null;
        let chartWidth =
            this.props.clust.length > 0
                ? Math.floor(100 / this.props.clust.length)
                : undefined;

        return (
            <div
                ref={(ref) => {
                    this.rootRef = ref;
                }}
                className="flex-simple-column"
                style={{
                    height: "100%",
                }}
            >
                <span
                    style={{
                        fontFamily: "Arial",
                        fontSize: 15,
                        paddingTop: 10,
                        paddingBottom: 10,
                        color: mainStyle.getPropertyValue(
                            "--exploration-tertiary-text-color"
                        ),
                        paddingLeft: 30,
                        display: "block",
                        textAlign: "left",
                    }}
                >
                    {`Levers`}
                </span>
                <div
                    className="my-row"
                    style={{
                        height: "100%",
                    }}
                >
                    {this.props.clust.length !== 0 &&
                        this.props.clust.map((clustItem, clustIndex) => {
                            return (
                                <div
                                    key={clustIndex}
                                    className="element"
                                    ref={(el) => {
                                        this.customRefs[
                                            "clustBarChartScrollable".concat(
                                                clustIndex
                                            )
                                        ] = el;
                                    }}
                                    style={{
                                        overflow: "auto hidden",
                                        position: "relative",
                                        height: "100%",
                                        minWidth: `calc(${chartWidth}% - 5px)`,
                                        marginRight: "5px",
                                    }}
                                >
                                    {this.props.showLeverBars && (
                                        <div style={{ height: "190px" }}>
                                            {clustItem.length > 0 && (
                                                <CustomBarChartWithOverlay
													decimals={this.props.decimals}
                                                    ref={(el) => {
                                                        this.customRefs[
                                                            "clustBarChart".concat(
                                                                clustIndex
                                                            )
                                                        ] = el;
                                                    }}
                                                    onSnapToGrid={() => {}}
                                                    onVariableChanged={(
                                                        variable,
                                                        maxChartValue,
                                                        levelsCount,
                                                        currentIndex,
                                                        type
                                                    ) => {
                                                        let index =
                                                            clustItem.findIndex(
                                                                (item) =>
                                                                    item.name ===
                                                                    variable
                                                            );
                                                        let newClust =
                                                            Array.from(
                                                                this.props.clust
                                                            );
                                                        newClust[clustIndex][
                                                            index
                                                        ][type] =
                                                            (maxChartValue *
                                                                currentIndex) /
                                                            levelsCount;
                                                        this.props.onClustChange(
                                                            newClust
                                                        );
                                                    }}
                                                    onVariableEntered={(
                                                        variable,
                                                        type,
                                                        value
                                                    ) => {
                                                        let index =
                                                            clustItem.findIndex(
                                                                (item) =>
                                                                    item.name ===
                                                                    variable
                                                            );
                                                        let newClust =
                                                            Array.from(
                                                                this.props.clust
                                                            );
                                                        newClust[clustIndex][
                                                            index
                                                        ][type] = value;
                                                        this.props.onClustChange(
                                                            newClust
                                                        );
                                                    }}
                                                    selectNextVariable={(
                                                        variable,
                                                        scroll
                                                    ) =>
                                                        this.selectNextVariable(
                                                            variable,
                                                            scroll,
                                                            clustIndex
                                                        )
                                                    }
                                                    selectPreviousVariable={(
                                                        variable,
                                                        scroll
                                                    ) =>
                                                        this.selectPreviousVariable(
                                                            variable,
                                                            scroll,
                                                            clustIndex
                                                        )
                                                    }
                                                    selectVariable={(
                                                        variable,
                                                        scroll
                                                    ) =>
                                                        this.selectVariable(
                                                            variable,
                                                            scroll,
                                                            clustIndex
                                                        )
                                                    }
                                                    leftBarKey={"value"}
                                                    leftBarColor={"#FFAB4F"}
                                                    data={clustItem}
                                                    scrollableRef={
                                                        this.customRefs[
                                                            "clustBarChartScrollable".concat(
                                                                clustIndex
                                                            )
                                                        ]
                                                    }
                                                    title={
                                                        this.props.data[
                                                            clustIndex
                                                        ].name
                                                    }
                                                    watermark={
                                                        sections._Global
                                                            ?.watermark
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}
                                    {!this.props.showLeverBars &&
                                        this.buildLeversInRow(clustIndex)}
                                    {this.props.showLeverBars &&
                                        this.buildCategoricalColumns(
                                            clustIndex
                                        )}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default HoldOutPredictionParametersChart;
