import { replaceSchema } from "common/InputData";
import { Permission } from "common/Permissions";
import axios from "common/ServerConnection";

export enum ColumnIndex {
    CustomerZipcode = 0,
    Customer = 1,
    CustomerRevenueCurrentPeriod = 2,
    CustomerRevenueLastPeriod = 3,
    Difference = 4,
    Opportunity = 5,
    OpportunityAmount = 6,
    Period = 7,
}

// Omit the name to make it pick a unique name
export async function createEmptyDataSet(name?: string): Promise<number> {
    return new Promise((resolve, reject) =>
        replaceSchema(
            [
                "Customer Zipcode",
                "Customer",
                "Revenue Current Period",
                "Revenue Last Period",
                "Difference",
                "Opportunity",
                "Opportunity Amount",
                "Period",
            ],
            ["str", "str", "float", "float", "float", "float", "float", "str"],
            ["", "", "", "", "", "", "", ""],
            [
                "geography",
                "regular",
                "regular",
                "regular",
                "regular",
                "regular",
                "regular",
                "regular",
            ],
            [null, null, null, null, null, null, null, null],
            ["zipcode", null, null, null, null, null, null, null],
            [true, true, true, true, true, true, true, true],
            {
                label: name ?? "guidance_wizard_",
                value: NaN,
                permissionType: Permission.ReadWrite,
            },
            name == null, // Pick a unique name if the name is null
            undefined,
            (dataScopeId) => resolve(dataScopeId as number),
            (error) => reject(error)
        )
    );
}

// For small files (<= 80 MB) specify the file parameter.
// For large files (> 80 MB) use uploadFileToBackend from common/InputData,
// and then specify fileId, but not file.
export async function uploadGuidanceWizardData(
    dataScopeId: number,
    file?: File | string,
    fileId?: string
): Promise<string> {
    let formData = new FormData();

    let json: {
        data_table_idx: number | string;
        encoding: string;
        columns_enabled?: boolean[];
        mode?: string;
        csv?: {
            link?: string;
            file_id?: string;
            sheet_id?: number;
        };
        mysql?: {
            host: string;
            port: number;
            database: string;
            table: string;
            login: string;
            password: string;
        };
        oracledb?: {
            host?: string;
            port?: number;
            service_name?: string;
            schema?: string;
            table: string;
            login: string;
            password: string;
            dsn?: string;
        };
        api?: {
            slug: string;
            options?: any;
        };
        every?: {
            interval: string;
            weekday?: number;
            time: string;
        };
        rerun_operations?: boolean;
        force_date_to_nans?: boolean;
        force_numbers_to_null?: boolean;
        is_guidance_wizard_template?: boolean;
        update_id?: string;
    } = {
        is_guidance_wizard_template: true,
        data_table_idx: dataScopeId,
        encoding: "utf-8",
        mode: "none",
        columns_enabled: [true, true, true, true, true, true, true, true],
    };
    if (file != null) {
        formData.append("content", file);
    } else if (fileId != null) {
        json.csv = {
            file_id: fileId,
        };
    }
    formData.append("metadata", JSON.stringify(json));
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            room_id?: string;
        }>("/api/replace_current_data", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            if (response.data.success && response.data.room_id != null) {
                return response.data.room_id;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}
