import React from "react";
import Select, { createFilter } from "react-select";
import Collapse from "react-bootstrap/Collapse";
import cx from "classnames";

import { CanvasProgressElement, StatusExpression } from "common/Canvas";
import ColorOptions from "common/ColorOptions";
import customSelectStyles from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";
import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import StatusSubExpressionSelector from "common/RevampedStatusSubExpressionsSelector";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./StatusExpressionsSelector.module.css";

interface StringOption {
    label: string;
    value: string;
}

export interface StatusFillOption {
    label: string;
    value: FillType;
}

export enum FillType {
    Border = 1,
    Fill = 2,
    Font = 3,
}

export const statusFillOptions: ReadonlyArray<StatusFillOption> = [
    {
        label: "Border",
        value: FillType.Border,
    },
    {
        label: "Fill",
        value: FillType.Fill,
    },
    {
        label: "Font Color",
        value: FillType.Font,
    },
];

interface Props {
    selectOutput: boolean;
    nodeLinkOptions: NodeLinkOption[];
    statusExpressions: StatusExpression[];
    node: CanvasElement | CanvasTextBox | CanvasProgressElement;
    onChange: (changes: StatusExpression[]) => void;
}

interface State {
    collapsedRows: any;
}

interface OutputIndexOption {
    label: string;
    value: number | null;
}

export default class StatusExpressionsSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            collapsedRows: {},
        };
    }

    static getOutputValue(
        node: CanvasElement | CanvasTextBox | CanvasProgressElement,
        statusExpression: StatusExpression
    ): OutputIndexOption | null {
        if (statusExpression.outputIndex == null) return null;
        if (statusExpression.outputIndex === 1)
            return {
                label: "Output#1",
                value: 1,
            };
        if (statusExpression.outputIndex > 1) {
            if (
                node.additionalOutputs.length >
                statusExpression.outputIndex - 2
            )
                return {
                    label: `Output#${statusExpression.outputIndex}`,
                    value: statusExpression.outputIndex,
                };
        }
        return null;
    }
    render() {
        return (
            <div>
                {this.props.statusExpressions.map((statusExpression, expressionIndex) => (
                    <div
                        key={expressionIndex}
                        className={styles.expressionRow}
                    >
                        <div className={styles.addRemoveButtonsContainer}>
                            <button
                                type="button"
                                onClick={() => {
                                    this.setState((prev) => {
                                        const collapsedRows = { ...prev.collapsedRows };

                                        const key = String(expressionIndex);
                                        if (key in prev.collapsedRows) {
                                            delete collapsedRows[key];
                                        } else {
                                            collapsedRows[key] = true;
                                        }

                                        return { collapsedRows };
                                    });
                                }}
                                className={styles.expressionCollapseButton}
                            >
                                <ChevronIcon
                                    className={cx(styles.expressionCollapseButtonIcon, {
                                        [styles.chevronOpen]: !(String(expressionIndex) in this.state.collapsedRows)
                                    })}
                                />
                                Format {expressionIndex + 1}
                            </button>
                            {expressionIndex === 0 ? (
                                <button
                                    className={styles.addRemoveButton}
                                    type="button"
                                    onClick={() => {
                                        let statusExpressions = Array.from(
                                            this.props.statusExpressions
                                        );
                                        statusExpressions.push({
                                            subexpressions: [
                                                { operation: "", value: "" },
                                            ],
                                            color: ColorOptions[0],
                                        });

                                        this.props.onChange(statusExpressions);
                                    }}
                                >
                                    {"\uFF0B"} Add
                                </button>
                            ) : (
                                <button
                                    className={styles.addRemoveButton}
                                    type="button"
                                    onClick={() => {
                                        let statusExpressions = Array.from(
                                            this.props.statusExpressions
                                        );
                                        statusExpressions.splice(
                                            expressionIndex,
                                            1
                                        );
                                        if (statusExpressions.length === 0) {
                                            statusExpressions.push({
                                                subexpressions: [
                                                    {
                                                        operation: "",
                                                        value: "",
                                                    },
                                                ],
                                                color: ColorOptions[0],
                                            });
                                        }

                                        this.props.onChange(statusExpressions);
                                    }}
                                >
                                    {"\u2715"}
                                </button>
                            )}
                        </div>
                        <Collapse in={!(String(expressionIndex) in this.state.collapsedRows)}>
                            <div>
                                <div className={styles.expressionColorRow}>
                                    <Select
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderRadius: "4px",
                                            }),
                                            indicatorSeparator: (provided) => ({
                                                ...provided,
                                                display: "none",
                                            }),
                                        }}
                                        options={ColorOptions}
                                        value={statusExpression.color}
                                        onChange={(newValue) => {
                                            const newStatusColorValue: StringOption = newValue as StringOption;
                                            let statusExpressions = Array.from(
                                                this.props.statusExpressions
                                            );
                                            statusExpressions[
                                                expressionIndex
                                            ].color = newStatusColorValue;
                                            this.props.onChange(statusExpressions);
                                        }}
                                    />
                                    <Select
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderRadius: "4px",
                                            }),
                                            indicatorSeparator: (provided) => ({
                                                ...provided,
                                                display: "none",
                                            }),
                                        }}
                                        options={statusFillOptions}
                                        value={
                                            statusExpression.font
                                                ? statusFillOptions[2]
                                                : statusExpression.fill
                                                ? statusFillOptions[1]
                                                : statusFillOptions[0]
                                        }
                                        onChange={(newValue) => {
                                            let statusExpressions = Array.from(
                                                this.props.statusExpressions
                                            );
                                            statusExpressions[expressionIndex].fill =
                                                (newValue as StatusFillOption).value ===
                                                FillType.Fill;
                                            statusExpressions[expressionIndex].font =
                                                (newValue as StatusFillOption).value ===
                                                FillType.Font;
                                            statusExpressions[
                                                expressionIndex
                                            ].outputIndex = null;
                                            if (
                                                this.props.selectOutput &&
                                                statusExpressions[expressionIndex].font
                                            )
                                                statusExpressions[
                                                    expressionIndex
                                                ].outputIndex = 1;
                                            this.props.onChange(statusExpressions);
                                        }}
                                    />
                                    {!statusExpression.fill && !statusExpression.font && (
                                        <>
                                            <input
                                                className={styles.textInput}
                                                onChange={(e) => {
                                                    const value: string =
                                                        e.target.value;
                                                    if (!/^[0-9]*$/.test(value)) {
                                                        e.preventDefault();
                                                    } else {
                                                        let statusExpressions = Array.from(
                                                            this.props.statusExpressions
                                                        );
                                                        statusExpressions[
                                                            expressionIndex
                                                        ].borderWidth = Number(value);
                                                        this.props.onChange(
                                                            statusExpressions
                                                        );
                                                    }
                                                }}
                                                value={
                                                    statusExpression.borderWidth === 0
                                                        ? ""
                                                        : (
                                                                statusExpression.borderWidth ??
                                                                1
                                                            ).toString()
                                                }
                                            />
                                            <span
                                            style={{
                                                fontFamily: "Arial",
                                                fontSize: "17px",
                                                width: "15px",
                                                marginLeft: "5px",
                                            }}
                                            >
                                            px
                                            </span>
                                        </>
                                    )}
                                </div>
                                {statusExpression.font && this.props.selectOutput && (
                                    <div
                                        className="my-row"
                                        style={{
                                            alignItems: "center",
                                            height: "38px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                marginLeft: "5px",
                                                textAlign: "right",
                                                fontFamily: "Arial",
                                                fontSize: "17px",
                                                color: mainStyle.getPropertyValue(
                                                    "--popup-primary-text-color"
                                                ),
                                            }}
                                        >
                                            {"For"}
                                        </span>
                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={""}
                                            styles={{
                                                ...customSelectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    height: "38px",
                                                    width: "120px",
                                                    marginLeft: "5px",
                                                }),
                                            }}
                                            options={[
                                                {
                                                    label: "Output#1",
                                                    value: 1,
                                                },
                                            ].concat(
                                                this.props.node.additionalOutputs?.map(
                                                    (_output, outputIndex) => ({
                                                        label: `Output#${
                                                            outputIndex + 2
                                                        }`,
                                                        value: outputIndex + 2,
                                                    })
                                                )
                                            )}
                                            onChange={(newValue) => {
                                                let newStatusExpressions = Array.from(
                                                    this.props.statusExpressions
                                                );
                                                newStatusExpressions[
                                                    expressionIndex
                                                ].outputIndex = (newValue as OutputIndexOption).value;

                                                this.props.onChange(
                                                    newStatusExpressions
                                                );
                                            }}
                                            value={StatusExpressionsSelector.getOutputValue(
                                                this.props.node,
                                                statusExpression
                                            )}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                )}
                                <StatusSubExpressionSelector
                                    node={this.props.node}
                                    nodeLinkOptions={this.props.nodeLinkOptions}
                                    subexpressions={statusExpression.subexpressions}
                                    onChange={(subexpressions) => {
                                        let statusExpressions = Array.from(
                                            this.props.statusExpressions
                                        );
                                        statusExpressions[
                                            expressionIndex
                                        ].subexpressions = subexpressions;
                                        this.props.onChange(statusExpressions);
                                    }}
                                />
                            </div>
                        </Collapse>
                    </div>
                ))}
            </div>
        );
    }
}
