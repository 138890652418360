




export const defaultItemHeight = 50;
export const defaultItemWidth = 100;

export const visibleMarkersLimit = 1000;

export enum OperationStatus {
  NotStarted = 1,
  Running = 2,
  Success = 3,
  Error = 4,
}

export const markerClusterGroupWrapperSupportedTypes = new Set<string>([
  "maps_pins",
  "maps_heatmap",
  "maps_bubble",
  "maps_choropleth",
]);

// Currently unused
// const geoJsonSizeLimit: number = 50 * 1024 * 1024;
