import React, { useState, useEffect, useCallback, CSSProperties } from "react";

import { Element } from "./Element";

interface Props {
    elements: string[];
    indices: number[];
    onDragEnd?: (indices: number[]) => void;
    style?: CSSProperties;
}

export function SortableList({ elements, indices, onDragEnd, style }: Props) {
    // For performance reasons the indices are stored internally until the user
    // stops dragging the element. Only then the change gets propagated.
    const [internalIndices, setInternalIndices] = useState<number[]>([]);

    useEffect(() => {
        setInternalIndices(indices);
    }, [indices]);

    const moveElement = useCallback((dragIndex: number, hoverIndex: number) => {
        setInternalIndices((prevIndices) => {
            let newIndices = Array.from(prevIndices);
            newIndices[dragIndex] = prevIndices[hoverIndex];
            newIndices[hoverIndex] = prevIndices[dragIndex];
            return newIndices;
        });
    }, []);

    return (
        <div style={style}>
            {internalIndices.map((i, index) => (
                <Element
                    key={i}
                    id={i}
                    index={index}
                    text={elements[i]}
                    moveElement={moveElement}
                    onEnd={() => {
                        onDragEnd?.(internalIndices);
                    }}
                />
            ))}
        </div>
    );
}
