import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";

import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import TimePatternsChart from "common/graphics/TimePatternsChart";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import { AggregateFunctionsAliasesLinePlot } from "common/AggregateFunctionsAliases";
import RangeSelector from "common/graphics/RangeSelector";

const Step = Object.freeze({
	initial: 0,
	selectDataScope: 1,
	selectTable: 2,
	selectConditions: 3,
	selectXAxis: 4,
	selectOperation: 5,
	selectTarget: 6,
	selectCountOrValuesOrColumn: 7,
	selectAdditionalParameter: 8,
	selectAdditionalValue: 9,
	final: 10,
});

function StepFinal(props) {
	let {
		onNewFinding,
		onBack,
		onShowRecipesPopup,
		onSaveFinding,
		finding,
		loading,
		// data
	} = props;
	return (
		<>
			<div
				className="my-row"
				style={{
					justifyContent: "space-between",
					marginTop: 10,
					height: "100%",
					width: "100%",
				}}
			>
				<div
					onClick={() => {
						onBack();
					}}
					style={{
						minWidth: 100,
						height: "100%",
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						justifyContent: "center",
					}}
				>
					<img
						alt=""
						src="/dist/img/data-exploration/chevron_back.png"
					/>
				</div>
				{loading ? (
					<span className="exploration-big-title-span">
						{"LOADING"}
					</span>
				) : (
					finding && (
						<div
							className="flex-simple-column"
							style={{
								width: "calc(100% - 200px)",
								height: "100%",
							}}
						>
							<div
								style={{
									alignSelf: "center",
									width: "100%",
									height: "380px",
								}}
							>
								<ErrorBoundary>
									<div
										style={{
											display: "flex",
											marginBottom: 10,
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<RangeSelector
											value={finding}
											onChange={onNewFinding}
										/>
									</div>
									<div style={{ height: "330px" }}>
										<TimePatternsChart
											isTimeSeries={
												finding.content.isTimeSeries
											}
											onNewLine={(line) => {
												let newFinding = { ...finding };
												if (
													newFinding.content
														.additionalData == null
												) {
													newFinding.content.additionalData = [];
												}
												newFinding.content.additionalData.push(
													line
												);
												onNewFinding(newFinding);
											}}
											onClearLines={() => {
												let newFinding = { ...finding };
												newFinding.content.additionalData = [];
												onNewFinding(newFinding);
											}}
											data={finding.content.data}
											additionalData={
												finding.content.additionalData
											}
											timeKey={finding.content.timeKey}
											displayMode={
												finding.config.displayMode
											}
											dateFormat={
												finding.config.dateFormat
											}
											config={finding.config}
											editable
											onConfigChange={(field, value) => {
												let newFinding = { ...finding };
												newFinding.config[
													field
												] = value;
												onNewFinding(newFinding);
											}}
											onFinishEdit={(
												serieIndex,
												barKey,
												label
											) => {
												let newFinding = { ...finding };
												if (
													newFinding.config
														.nameMapping == null
												)
													newFinding.config.nameMapping = {};
												newFinding.config.nameMapping[
													serieIndex
												] = {
													...finding.config
														.nameMapping[
														serieIndex
													],
													[barKey]: label,
												};
												onNewFinding(newFinding);
											}}
											onFinishEditLabel={(
												barKey,
												label
											) => {
												let newFinding = { ...finding };
												newFinding.config.labelMapping = {
													...finding.config
														.labelMapping,
													[barKey]: label,
												};
												onNewFinding(newFinding);
											}}
											onFinishColor={(
												serieIndex,
												barKey,
												color
											) => {
												let newFinding = { ...finding };
												if (
													newFinding.config
														.colorMapping == null
												)
													newFinding.config.colorMapping = {};
												newFinding.config.colorMapping[
													serieIndex
												] = {
													...finding.config
														.colorMapping[
														serieIndex
													],
													[barKey]: color,
												};
												onNewFinding(newFinding);
											}}
										/>
									</div>
								</ErrorBoundary>
							</div>
							<FinalStepBottomBar
								onShowRecipesPopup={onShowRecipesPopup}
								onSaveFinding={onSaveFinding}
								topMargin={20}
							/>
						</div>
					)
				)}
				<div
					style={{
						minWidth: 100,
						height: "100%",
					}}
				/>
			</div>
		</>
	);
}

class MainComponent extends BaseJourney {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			previousStep: 0,
			step: 0,
			count: 100,
			animationDirection: "top",
			selectedXAxisVariable: undefined,
			allVariables: [],
			selectedTargetVariables: [],
			targetVariables: [],
			finding: undefined,
			additionalParameter: undefined,
			additionalParameters: [],
			selectedTable: undefined,
			operationVariable: undefined,
			operationVariables: [
				"mean",
				"sum",
				"count",
				"count_distinct",
				"mean_column",
				"sum_column",
			],
			step2VariablesMap: {
				[Step.selectOperation]: [
					"operationVariable",
					"operationVariables",
				],
				[Step.selectTarget]: [
					"selectedTargetVariables",
					"targetVariables",
				],
				[Step.selectXAxis]: ["selectedXAxisVariable", "allVariables"],
				[Step.selectAdditionalParameter]: [
					"additionalParameter",
					"additionalParameters",
				],
			},
			targetValues: new Array(5).fill(null),
			conditions: [
				{
					variable: null,
					operation: null,
					value: null,
					logical: null,
					leftBracket: null,
					rightBracket: null,
				},
			],
		};
		this.getData = this.getData.bind(this);
		this.initializeVariables = this.initializeVariables.bind(this);
		this.switchVariables = this.switchVariables.bind(this);
	}

	selectVariablesByOperation(operationName) {
		if (operationName === "count" || operationName === "count_distinct") {
			return this.getVariables().dataVariables.map((item) => item.name);
		} else {
			return this.getVariables().numericVariables.map(
				(item) => item.name
			);
		}
	}

	switchVariables() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
		this.initializeVariables();
		this.initializeVariablesReaction = reaction(
			() => this.getVariables().dataVariables,
			() => {
				this.initializeVariables();
			}
		);
	}

	clearOptionalArgs() {
		this.setState({
			selectedXAxisVariable: null,
			selectedXAxisVariableIndex: null,
			selectedTargetVariables: [],
			selectedTargetVariablesIndices: [],
			additionalParameter: null,
			additionalParameterIndex: null,
			columnByVariable: null,
			columnByVariableIndex: null,
			leftAdditionalOperator: null,
			leftAdditionalValue: null,
			rightAdditionalOperator: null,
			rightAdditionalValue: null,
			selectedTable: this.defaultTable(),
			conditions: ConditionsSelector.defaultValue,
			dynamicOptions: {},
			nameMapping: null,
			labelMapping: null,
			colorMapping: null,
			legendsColor: null,
			titlesColor: null,
			axesColor: null,
			maxYRange: null,
			minYRange: null,
			linesCount: null,
			yAxisName: null,
		});
	}

	// We need to convert undefined to null to remove field if used in canvas page
	getConfig() {
		let config = {
			version: configVersion,
			count: this.state.count || 100,
			dataScope: this.state.dataScope ?? null,
			selectedTable: this.state.selectedTable ?? null,
			conditions: this.state.conditions ?? null,
			selectedXAxisVariable: this.state.selectedXAxisVariable ?? null,
			selectedXAxisVariableIndex:
				this.getVariables().variableToIndex(
					this.state.selectedXAxisVariable
				) ?? null,
			columnByVariable: this.state.columnByVariable ?? null,
			columnByVariableIndex:
				this.getVariables().variableToIndex(
					this.state.columnByVariable
				) ?? null,
			selectedTargetVariables: this.state.selectedTargetVariables ?? null,
			selectedTargetVariablesIndices:
				this.state.selectedTargetVariables.map((item) =>
					this.getVariables().variableToIndex(item)
				) ?? null,
			targetValues: this.state.targetValues ?? null,
			operationVariable: this.state.operationVariable ?? null,
			additionalParameters: this.state.additionalParameters ?? null,
			leftAdditionalOperator: this.state.leftAdditionalOperator ?? null,
			rightAdditionalOperator: this.state.rightAdditionalOperator ?? null,
			leftAdditionalValue: this.state.leftAdditionalValue ?? null,
			rightAdditionalValue: this.state.rightAdditionalValue ?? null,
			additionalParameter: this.state.additionalParameter ?? null,
			additionalParameterIndex:
				this.getVariables().variableToIndex(
					this.state.additionalParameter
				) ?? null,
			journeyName: this.props.journeyName,
			nameMapping: this.state.nameMapping ?? null,
			labelMapping: this.state.labelMapping ?? null,
			colorMapping: this.state.colorMapping ?? null,
			legendsColor: this.state.legendsColor ?? null,
			titlesColor: this.state.titlesColor ?? null,
			axesColor: this.state.axesColor ?? null,
			maxYRange: this.state.maxYRange ?? null,
			minYRange: this.state.minYRange ?? null,
			linesCount: this.state.linesCount ?? null,
			yAxisName: this.state.yAxisName ?? null,
		};
		if (this.props.addToCanvas) {
			config.dynamicOptions = this.prepareDynamicOptions(
				this.state.dynamicOptions,
				config
			);
		}
		return config;
	}
	getData() {
		this.setState({ loading: true });

		Api.getData(this.getConfig(), undefined, this.props.currentModuleId)
			.then((finding) => {
				this.setState({ finding: finding, loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}
	buildContent() {
		switch (this.state.step) {
			case Step.initial:
				return this.buildInitial(
					"How do you want to examine your variables?"
				);
			case Step.selectDataScope:
				return this.buildDataScopesSelector();
			case Step.selectTable:
				return this.buildTableSelector();
			case Step.selectConditions:
				return this.buildConditionsSelector();
			case Step.selectXAxis:
				return this.buildVariablesSelector(
					"I want to examine: X-axis",
					"selectedXAxisVariable",
					"allVariables",
					false,
					DynamicOptionType.variable,
					"selectedXAxisVariableIndex",
					true
				);
			case Step.selectOperation:
				return this.buildVariablesSelector(
					"By comparing on the Y-axis",
					"operationVariable",
					"operationVariables",
					false,
					DynamicOptionType.regular,
					undefined,
					false,
					AggregateFunctionsAliasesLinePlot
				);
			case Step.selectTarget:
				return this.buildMultiVariablesSelector(
					`for the following variables${
						!["count", "mean_column", "sum_column"].includes(
							this.state.operationVariable
						)
							? " up to 5 max"
							: ""
					}: Y-axis`,
					"selectedTargetVariables",
					"targetVariables",
					DynamicOptionType.variable,
					"selectedTargetVariablesIndices",
					["count", "mean_column", "sum_column"].includes(
						this.state.operationVariable
					)
						? 1
						: 5
				);

			case Step.selectCountOrValuesOrColumn:
				if (this.state.operationVariable === "count")
					return this.buildMultiCountModeVariablesSelector(
						"And show count for",
						this.state.selectedTargetVariables[0]
					);
				else if (
					["mean_column", "sum_column"].includes(
						this.state.operationVariable
					)
				) {
					return this.buildCustomVariablesSelector(
						"By the different values of",
						"columnByVariable",
						["selectedTargetVariables"],
						"allVariables"
					);
				} else
					return this.buildInput(
						"Randomly sample",
						"points",
						"count",
						true
					);
			case Step.selectAdditionalParameter:
				return this.buildVariablesSelector(
					"[+] and how they vary by",
					"additionalParameter",
					"additionalParameters",
					false,
					DynamicOptionType.additionalParameter,
					"additionalParameterIndex"
				);
			case Step.selectAdditionalValue:
				return this.buildAdditionalValueSelector(
					`[+] and how they vary by ${this.state.additionalParameter}`,
					true
				);
			case Step.final:
				return (
					<StepFinal
						onShowRecipesPopup={this.showRecipesPopup}
						loading={this.state.loading}
						finding={this.state.finding}
						onSaveFinding={this.saveFinding}
						onBack={this.back}
						onNewFinding={this.onNewFinding}
					/>
				);

			default:
				return <div />;
		}
	}
	buildLeftBar() {
		return super.buildLeftBar(this.state.step < Step.final - 1);
	}
	initializeVariables() {
		let scopeVariables = this.getVariables();
		let targetVariables = this.selectVariablesByOperation(
			this.state.operationVariable
		);
		let dataVariables = scopeVariables.dataVariables.map(
			(item) => item.name
		);
		this.setState({
			additionalParameters: [null].concat(dataVariables),
			targetVariables: targetVariables,
			allVariables: dataVariables,
		});
	}
	componentDidMount() {
		this.getLastJourneyConfig();
	}

	componentWillUnmount() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
	}
	showFinalStep() {
		if (
			this.state.selectedXAxisVariable &&
			this.state.dataScope &&
			this.state.selectedTable &&
			this.state.selectedTargetVariables &&
			this.state.operationVariable &&
			this.state.step > Step.initial &&
			this.state.step < Step.final
		)
			this.setState({ animationDirection: "top" }, () => {
				this.setState(
					(state) => ({
						previousStep: state.step,
						step: Step.final,
					}),
					() => {
						this.getData();
						this.saveNewJourneyConfig();
					}
				);
			});
	}

	selectVariable(variableName, variablesName, index, maxCount = undefined) {
		let stateDiff = {};
		let multiselection = Array.isArray(this.state[variableName]);
		if (!multiselection) {
			stateDiff = {
				[variableName]: this.state[variablesName][index],
			};
		} else {
			let selectedVariables = Array.from(this.state[variableName]);
			let newVariable = this.state[variablesName][index];
			if (selectedVariables.includes(newVariable)) {
				selectedVariables = selectedVariables.filter(
					(item) => item !== newVariable
				);
			} else {
				selectedVariables.push(newVariable);
			}
			let allVariables = Array.from(this.state[variablesName]);
			selectedVariables = selectedVariables.filter((variable) =>
				allVariables.includes(variable)
			);
			if (maxCount != null) {
				if (selectedVariables.length > maxCount) {
					selectedVariables = selectedVariables.slice(
						selectedVariables.length - maxCount
					);
				}
			}
			stateDiff = {
				[variableName]: selectedVariables,
			};
		}
		if (this.state.step === Step.selectOperation) {
			stateDiff.targetVariables = this.selectVariablesByOperation(
				stateDiff[variableName]
			);
			stateDiff.selectedTargetVariables = [];
		}
		if (
			this.state.step === Step.selectOperation ||
			this.state.step === Step.selectTarget
		) {
			stateDiff.targetValues = new Array(5).fill(null);
			stateDiff.columnByVariable = undefined;
		}
		if (this.state.step === Step.selectAdditionalParameter) {
			stateDiff.leftAdditionalValue = undefined;
			stateDiff.rightAdditionalValue = undefined;
		}

		this.setState(stateDiff, () => {
			if (this.state.step !== Step.selectTarget)
				setTimeout(() => {
					this.stepDown();
				}, 100);
		});
	}

	getSubtitle() {
		let conditionsString = conditionsToString(
			Array.from(this.state.conditions)
		);
		if (
			this.state.step === Step.selectTable ||
			this.state.step === Step.selectDataScope
		)
			return "How do you want to examine your variables?";
		if (
			this.state.step > Step.selectTable &&
			this.state.step < Step.final
		) {
			let subtitle = `For the data in ${
				this.state.dataScope ? this.state.dataScope.label : ""
			} ${this.state.selectedTable.label}`;
			if (this.state.step > Step.selectConditions) {
				if (conditionsString.length > 0)
					subtitle += ` and under the conditions ${conditionsString}`;
			}
			if (this.state.step > Step.selectXAxis)
				subtitle += ` I want to examine: X-axis ${
					this.state.selectedXAxisVariable ?? ""
				}`;
			if (this.state.step > Step.selectOperation)
				subtitle += `  by comparing on the Y-axis ${
					AggregateFunctionsAliasesLinePlot[
						this.state.operationVariable
					] || ""
				}`;
			if (this.state.step > Step.selectTarget)
				subtitle += ` against the following variables: Y-axis ${this.state.selectedTargetVariables.join(
					", "
				)}`;
			if (
				this.state.step > Step.selectCountOrValuesOrColumn &&
				this.state.targetValues.filter((item) => item?.value != null)
					.length > 0 &&
				this.state.operationVariable === "count"
			)
				subtitle += ` and show count for ${this.state.targetValues
					.filter((value) => value?.value != null)
					.map(
						(value, index) =>
							`${this.state.selectedTargetVariables[0]}=${value.label}`
					)
					.join(", ")},`;
			if (
				this.state.step > Step.selectCountOrValuesOrColumn &&
				(this.state.operationVariable === "mean_column" ||
					this.state.operationVariable === "sum_column") &&
				this.state.columnByVariable != null
			)
				subtitle += ` by the different values of ${this.state.columnByVariable},`;
			if (this.state.step > Step.selectAdditionalParameter)
				subtitle += ` and how they vary by ${
					this.state.additionalParameter || ""
				}`;

			return subtitle;
		}

		if (this.state.step === Step.final) {
			return (
				<>
					<span>For the data </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.dataScope
							? this.state.dataScope.label.concat(" ")
							: ""}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedTable.label}
					</span>
					{conditionsString.length > 0 && (
						<>
							<span> and under the conditions </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{conditionsString}
							</span>
						</>
					)}
					<span> I want to examine: X-axis </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedXAxisVariable ?? ""}
					</span>
					<span> by comparing on the Y-axis </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{AggregateFunctionsAliasesLinePlot[
							this.state.operationVariable
						] || ""}
					</span>
					<span> for the following variables: Y-axis </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedTargetVariables.join(", ")}
					</span>
					{this.state.operationVariable === "count" &&
					this.state.targetValues.filter(
						(item) => item?.value != null
					).length > 0 ? (
						<>
							<span> and show count for </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.targetValues
									.filter((value) => value?.value != null)
									.map(
										(value, index) =>
											`${this.state.selectedTargetVariables[0]}=${value.label}`
									)
									.join(", ")}
							</span>
						</>
					) : null}
					{(this.state.operationVariable === "mean_column" ||
						this.state.operationVariable === "sum_column") &&
					this.state.columnByVariable != null ? (
						<>
							<span> by the different values of </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.columnByVariable}
							</span>
						</>
					) : null}
					{this.state.additionalParameter &&
					[
						this.state.leftAdditionalValue,
						this.state.rightAdditionalValue,
					]
						.filter((item) => item)
						.filter((item) => item.value).length > 0 ? (
						<>
							<span> and how they vary by </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{(this.state.leftAdditionalValue?.label != null
									? ` ${this.state.additionalParameter} ${
											this.state.leftAdditionalOperator
												?.label ?? "="
									  } ${this.state.leftAdditionalValue.label}`
									: "") +
									(this.state.rightAdditionalValue?.label !=
									null
										? (this.state.leftAdditionalValue
												?.label != null
												? ", "
												: " ") +
										  `${this.state.additionalParameter} ${
												this.state
													.rightAdditionalOperator
													?.label ?? "="
										  } ${
												this.state.rightAdditionalValue
													.label
										  }`
										: "")}
							</span>
						</>
					) : null}
					<span>.</span>
				</>
			);
		}
		return "";
	}
	getFooterTitle() {
		switch (this.state.step) {
			case Step.initial:
				return "... For the data in...";
			case Step.selectTable:
				return "... and under the conditions...";
			case Step.selectConditions:
				return "... I want to examine ...";
			case Step.selectXAxis:
				return "... by comparing on the Y-axis ...";
			case Step.selectOperation:
				return "... against the following variables ...";
			case Step.selectTarget:
				return this.state.operationVariable === "count"
					? "... and show count for ..."
					: ["mean_column", "sum_column"].includes(
							this.state.operationVariable
					  )
					? "... by the different values of ..."
					: "... [optional] randomly sample ...";
			case Step.selectCountOrValuesOrColumn:
				return "... [optional] for ...";
			default:
				return "";
		}
	}
	stepDown() {
		if (this.state.step === Step.selectTable && !this.state.selectedTable) {
			return;
		}
		if (
			this.state.step === Step.selectXAxis &&
			!this.state.selectedXAxisVariable
		) {
			return;
		}
		let delta = 1;
		if (
			this.state.step === Step.selectAdditionalParameter &&
			!this.state.additionalParameter
		)
			delta = 2;
		if (this.state.step + delta < Step.final) {
			this.setState((state) => ({
				previousStep: state.step,
				step: state.step + delta,
			}));
		} else {
			this.showFinalStep();
		}
	}

	stepUp() {
		let step = this.state.step;
		let delta = 1;
		if (step > Step.initial)
			this.setState({ animationDirection: "bottom" }, () => {
				this.setState({
					step: step - delta,
				});
			});
	}

	render() {
		return (
			<div
				className="dashboard-rect-journey-focus"
				tabIndex="0"
				style={{
					height: 570,
					overflow: "hidden",
					display: !this.props.collapsed ? "block" : "none",
				}}
				onClick={() => {
					if (this.state.step === Step.initial) this.stepDown();
				}}
				onKeyDown={(evt) => {
					if (evt.key === "Escape") {
						this.props.onClose();
					}
					if (evt.key === "ArrowDown") {
						if (
							this.state.step > Step.initial &&
							this.state.step < Step.final
						)
							this.stepDown();
						evt.preventDefault();
					}
					if (evt.key === "ArrowLeft") {
						if (this.state.step === Step.final) {
							this.back();
						}
					}
					if (evt.key === "ArrowRight") {
						this.showFinalStep();
					}
					if (evt.key === "ArrowUp") {
						if (this.state.step < Step.final) this.stepUp();
						evt.preventDefault();
					}
					if (evt.key === "p") {
						if (this.state.step === Step.final) {
							this.saveFinding();
						}
					}
					if (evt.key === "d") {
						if (this.state.step === Step.final) {
							evt.preventDefault();
							this.showRecipesPopup();
						}
					}

					let variableInfo = this.state.step2VariablesMap[
						this.state.step
					];
					if (variableInfo) {
						let variablesLength = this.state[variableInfo[1]]
							.length;
						let variableIndex = evt.keyCode - 65;
						if (
							variableIndex >= 0 &&
							variableIndex < variablesLength
						) {
							this.selectVariable(
								variableInfo[0],
								variableInfo[1],
								variableIndex
							);
						}
					}
				}}
			>
				<div
					className="my-row"
					style={{ justifyContent: "space-between", height: "100%" }}
				>
					<div
						className="flex-column"
						style={{
							height: "100%",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						{this.buildHeader()}

						<TransitionGroup
							style={{
								minHeight: "inherit",
							}}
						>
							<CSSTransition
								style={{
									height: "100%",
								}}
								key={this.state.step}
								timeout={500}
								classNames={"journeywizard-".concat(
									this.state.animationDirection || ""
								)}
							>
								<div
									style={{
										height: "100%",
									}}
								>
									{this.buildContent()}
								</div>
							</CSSTransition>
						</TransitionGroup>

						<Footer
							showArrow={
								this.state.step > Step.initial &&
								this.state.step < Step.final
							}
							title={this.getFooterTitle()}
						/>
					</div>
				</div>
				{this.buildAddToRecipesPopup()}
			</div>
		);
	}
}

export { MainComponent };