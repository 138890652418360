import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { CanvasNode, CanvasSharedToggle } from "common/Canvas";
import TabTitle from "common/TabTitle";
import { GlobalContextContents } from "GlobalContext";
import Settings from "./Settings";

import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";

import styles from "./Toggle.module.css";

interface Props {
  node: CanvasSharedToggle;
  shared: boolean;
  globalContext: GlobalContextContents;
  onChange: (changes: Partial<CanvasSharedToggle | CanvasNode>, commit?: boolean) => void;
  onChangeShared: (shared: boolean) => void;
  onClose: () => void;
}

function Toggle({ node, shared, globalContext, onChange, onChangeShared, onClose }: Props) {
  const [selectedTab, setSelectedTab] = useState("settings");

  function selectTab(tabKey: string | null) {
    if (tabKey == null) return;
    setSelectedTab(tabKey);
  }

  return (
    <div>
      <div className={styles.editMenuHeader}>
        <h6 className={styles.editMenuHeaderTitle}>On/Off Toggle</h6>
        <button
          type="button"
          onClick={onClose}
          className={styles.editMenuCloseButton}
        >
          <CloseIcon />
        </button>
      </div>
      <Tabs
        id="advanced-menu-toggle-tabs"
        activeKey={selectedTab}
        onSelect={selectTab}
        className={styles.tabMenu}
      >
        <Tab
          eventKey="settings"
          title={
            <TabTitle
              title="Settings"
              selected={selectedTab === "settings"}
              className={styles.tabTitle}
            />
          }
          className={styles.tab}
        >
          <Settings
            node={node}
            shared={shared}
            globalContext={globalContext}
            onChange={onChange}
            onChangeShared={onChangeShared}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Toggle;
