import React from "react";

import { colorList } from "./LineColors";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { LegendEditElement } from "./LegendEditElement";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { SingleLineAxisTick } from "./AxesTicks";
import moment from "moment";
import _ from "lodash";
import { getTextWidthCanvas } from "common/utilities/MeasureText";

import {
    LabelList,
    BarChart,
    Bar,
    Cell,
    XAxis,
    CartesianGrid,
    YAxis,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { TooltipStyles } from "./TooltipStyles";

interface Config {
    selectedVariable: string;
    leftTimeVariable: string;
    rightTimeVariable: string;
    colorVariable?: string;
    axesColor?: string;
    ganttColor?: string;
    ganttVariableColors?: { [key: string]: string };
    titleColor?: string;
    labelsColor?: string;
    title?: string;
}

interface GanttProps {
    onConfigChange: (config: Config) => void;
    data: {
        [key: string]: number | string;
    }[];
    config: Config;
    editable?: boolean;
}

function formatter(value: string | number) {
    if (typeof value === "string" || typeof value === "number") {
        let formattedValue = formatValue(value, false);

        let result = formattedValue[0].concat(formattedValue[1]);
        return result;
    }
    return value;
}

function formatTime(timestamp: number) {
    return moment.unix(timestamp).format("YYYY MMM DD").toUpperCase();
}

export default function GanttChart(props: GanttProps) {
    let editable = props.editable ?? false;
    let axesColor =
        props.config.axesColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
    let titleColor =
        props.config.titleColor ??
        mainStyle.getPropertyValue("--graphs-legends-text-color").trim();
    let ganttColor = props.config.ganttColor ?? colorList[1];
    let labelsColor =
        props.config.labelsColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
    let title = props.config.title ?? "";
    let timestamps: number[] = props.data
        .map((item) => item["%gantt_interval"])
        .flat() as number[];
    let maxTimestamp = Math.max(...timestamps);
    let minTimestamp = Math.min(...timestamps);
    let week = 7 * 24 * 60 * 60;
    let intervals = _.range(minTimestamp, maxTimestamp, week);
    if (intervals[intervals.length - 1] < maxTimestamp) {
        intervals.push(maxTimestamp);
    }

    let ganttVariableColors = props.config.ganttVariableColors ?? {};
    if (
        props.config.colorVariable != null &&
        props.config.ganttVariableColors == null
    ) {
        // Set default colors
        let colorListIndex = 0;
        for (let { "%color": value } of props.data) {
            if (value != null && !(value in ganttVariableColors)) {
                ganttVariableColors[value] =
                    colorList[colorListIndex % colorList.length];
                colorListIndex += 1;
            }
        }
        props.onConfigChange({
            ...props.config,
            ganttVariableColors: ganttVariableColors,
        });
    }
    let widths = props.data.map((item) => getTextWidthCanvas(String(item.name), "Arial", mainStyle.getPropertyValue("--graphs-axes-size"), "normal"));
    let maxYaxisWidth = Math.max(...widths);

    let plot = (
        <div
            className="flex-simple-column"
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            {editable && (
                <div
                    className="my-row"
                    style={{
                        flexWrap: "wrap",
                        justifyContent: "center",
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 10,
                        width: "100%",
                        // Do not set minHeight here. It causes the legend to
                        // be displayed incorrectly if there are too many
                        // entries.
                    }}
                >
                    <LegendEditElement
                        allowColorChange
                        disallowTextChange
                        onFinishColor={(color: string) => {
                            let config = {
                                ...props.config,
                                axesColor: color,
                            };
                            props.onConfigChange(config);
                        }}
                        color={axesColor}
                        text={"Axes Color"}
                    />
                    <LegendEditElement
                        allowColorChange
                        disallowTextChange
                        onFinishColor={(color: string) => {
                            let config = {
                                ...props.config,
                                labelsColor: color,
                            };
                            props.onConfigChange(config);
                        }}
                        color={labelsColor}
                        text={"Labels Color"}
                    />
                    <LegendEditElement
                        allowColorChange
                        disallowTextChange
                        onFinishColor={(color: string) => {
                            let config = {
                                ...props.config,
                                titleColor: color,
                            };
                            props.onConfigChange(config);
                        }}
                        color={titleColor}
                        text={"Title Color"}
                    />
                    <LegendEditElement
                        allowColorChange
                        disallowTextChange
                        onFinishColor={(color: string) => {
                            let config = {
                                ...props.config,
                                ganttColor: color,
                            };
                            props.onConfigChange(config);
                        }}
                        color={ganttColor}
                        text={
                            props.config.colorVariable != null
                                ? "Default Color (for NULL values)"
                                : "Chart Color"
                        }
                    />
                    {props.config.colorVariable != null &&
                        Object.entries(ganttVariableColors).map(
                            ([value, color]) => (
                                <LegendEditElement
                                    allowColorChange
                                    disallowTextChange
                                    key={`legend-color-${value}`}
                                    onFinishColor={(color: string) => {
                                        let config = {
                                            ...props.config,
                                            ganttVariableColors: {
                                                ...ganttVariableColors,
                                                [value]: color,
                                            },
                                        };
                                        props.onConfigChange(config);
                                    }}
                                    color={color}
                                    text={value}
                                />
                            )
                        )}
                </div>
            )}
            {editable ? (
                <input
                    defaultValue={title}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    placeholder=""
                    onBlur={(e) => {
                        e.preventDefault();
                        let title = e.target.value;
                        let config = {
                            ...props.config,
                            title: title,
                        };
                        props.onConfigChange(config);
                    }}
                    style={{
                        marginTop: 10,

                        alignSelf: "center",
                        backgroundColor: "transparent",
                        outline: "none",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        color: titleColor,
                    }}
                ></input>
            ) : (
                title && (
                    <span
                        style={{
                            marginTop: 10,
                            alignSelf: "center",
                            fontFamily: "Roboto",
                            fontSize: "15px",
                            color: titleColor,
                        }}
                    >
                        {title}
                    </span>
                )
            )}

            <div style={{ marginTop: 10, height: "100%", overflow: "hidden" }}>
                <ResponsiveContainer width="100%" height="99%">
                    <BarChart
                        barCategoryGap={5}
                        layout="vertical"
                        data={props.data}
                        margin={{
                            top: 10,
                            left: 50,
                            right: 50,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid
                            stroke={mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )}
                            horizontal={false}
                            strokeWidth={1}
                        />
                        <XAxis
                            domain={["dataMin", "dataMax"]}
                            stroke={mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )}
                            orientation="bottom"
                            type="number"
                            ticks={intervals}
                            tickLine={false}
                            axisLine={false}
                            tick={
                                <SingleLineAxisTick
                                    axesColor={axesColor}
                                    dx={16}
                                    dy={8}
                                    angle={-15}
                                    formatValue={formatTime}
                                />
                            }
                        />
                        <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            width={maxYaxisWidth}
                            tick={
                                <CustomizedAxisTick
                                    axesColor={axesColor}
                                    unit={undefined}
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={10}
                                    dx={0}
                                    dy={0}
                                />
                            }
                        />
                        <Bar
                            isAnimationActive={false}
                            dataKey="%gantt_interval"
                        >
                            {props.data.map((entry) => (
                                <Cell
                                    fill={
                                        props.config.colorVariable != null
                                            ? ganttVariableColors[
                                                  entry["%color"]
                                              ] ?? ganttColor
                                            : ganttColor
                                    }
                                />
                            ))}
                            <LabelList
                                position="insideLeft"
                                fill={labelsColor}
                                stroke="none"
                                dataKey={"name"}
                                {...{
                                    formatter: formatter,
                                }}
                            />
                        </Bar>
                        {sections._Global.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={"4rem"}
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {sections._Global.watermark}
                            </text>
                        )}
                        <RechartsTooltip
                            cursor={false}
                            labelFormatter={formatter}
                            formatter={(
                                value: number[],
                                name: string,
                                _props: any
                            ) => {
                                let formattedValue = `${formatTime(
                                    value[0]
                                )}-${formatTime(value[1])}`;
                                return [formattedValue, "interval"];
                            }}
                            {...TooltipStyles()}

                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
    return plot;
}
