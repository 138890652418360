import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { getSharedModuleByLinkAuth, loadModuleApi } from "common/ModulesApi";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import { AppModuleProps } from "AppModule";
import ScaledPage from "common/ScaledPage";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import axios from "common/ServerConnection";

const paymentTypeLabel: Readonly<{ [key: string]: string }> = {
    none: "",
    one_time: "once",
    monthly: "per month",
    yearly: "per year",
};

interface State {
    status: PopupStatus | null;
    message: string;
    linkId: string | null;
    sharedModuleId: number | null;
    paymentInfo?: {
        title: string;
        description: string;
        keywords: string[];
        thumbnail: string;
        paywall?: {
            title: string;
            client: string;
            description: string;
            payment_type: string;
            price: number;
            logo: string | null;
        };
    };
}

class MainComponent extends Component<AppModuleProps, State> {
    constructor(props: AppModuleProps) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        const linkId = urlParams.get("app_id");
        const sharedModuleId = urlParams.get("shared_module_id");
        this.state = {
            status: null,
            message: "",
            linkId: linkId,
            sharedModuleId:
                sharedModuleId != null ? Number(sharedModuleId) : null,
        };
    }

    public componentDidMount(): void {
        if (this.state.linkId != null) {
            getSharedModuleByLinkAuth(this.state.linkId)
                .then((sharedModule) => {
                    if ("action_required" in sharedModule) {
                        // remove payment_required
                        let { action_required, ...rest } = sharedModule;
                        this.setState({
                            paymentInfo: rest,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        status: PopupStatus.Error,
                        message: String(error),
                    });
                });
        } else if (this.state.sharedModuleId != null) {
            loadModuleApi(this.state.sharedModuleId)
                .then((sharedModule) => {
                    if ("action_required" in sharedModule) {
                        // remove payment_required
                        this.setState({
                            paymentInfo: {
                                title: sharedModule.title,
                                description: sharedModule.description,
                                keywords: sharedModule.keywords,
                                thumbnail: sharedModule.thumbnail,
                                paywall: sharedModule.paywall,
                            },
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        status: PopupStatus.Error,
                        message: String(error),
                    });
                });
        }
    }

    private showMultilineText(text: string): JSX.Element {
        return (
            <>
                {text.split("\n").map((line, index) => (
                    <span
                        key={index}
                        style={{
                            textAlign: "left",
                            marginTop: 10,
                            marginLeft: 10,
                            wordBreak: "break-word",
                            color: "var(--popup-primary-text-color)",
                            fontFamily: "Roboto",
                            fontSize: 12,
                            display: "block",
                        }}
                    >
                        {line}
                    </span>
                ))}
            </>
        );
    }

    private buildInnerView(): JSX.Element {
        return (
            <div
                className="center-container"
                style={{
                    overflow: "visible",
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    className="flex-simple-column"
                    style={{
                        overflow: "visible",
                        marginTop: 20,
                        height: "100%",
                        width: "90%",
                    }}
                >
                    {this.state.paymentInfo != null && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <div className="flex-simple-column">
                                <div
                                    className="center-container"
                                    style={{ minHeight: 30 }}
                                >
                                    <span
                                        style={{
                                            marginTop: 20,
                                            paddingTop: 0,
                                            paddingLeft: 10,
                                            color: "#0097A7",
                                            fontFamily: "Roboto",
                                            fontSize: 30,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {`You're about to subscribe to ${this.state.paymentInfo.title}`}
                                    </span>
                                </div>

                                <div
                                    className="my-row"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        marginTop: 20,
                                    }}
                                >
                                    <div
                                        className="flex-simple-column"
                                        style={{
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                            width: "75%",
                                            height: "100%",
                                            minHeight: 0,
                                        }}
                                    >
                                        <span
                                            style={{
                                                textAlign: "left",
                                                color:
                                                    "var(--popup-secondary-text-color)",
                                                fontFamily: "Roboto",
                                                fontSize: 17,
                                                fontWeight: 700,
                                            }}
                                        >
                                            Price
                                        </span>
                                        <span style={{ marginTop: 10 }}>
                                            {`$${
                                                this.state.paymentInfo.paywall
                                                    ?.price
                                            } ${
                                                paymentTypeLabel[
                                                    this.state.paymentInfo
                                                        .paywall
                                                        ?.payment_type ?? "none"
                                                ]
                                            }`}
                                        </span>
                                        <span
                                            style={{
                                                marginTop: 50,
                                                textAlign: "left",
                                                color:
                                                    "var(--popup-secondary-text-color)",
                                                fontFamily: "Roboto",
                                                fontSize: 17,
                                                fontWeight: 700,
                                            }}
                                        >
                                            Next steps
                                        </span>
                                        <span style={{ marginTop: 10 }}>
                                            1. Attach a payment method
                                        </span>
                                        <span style={{ marginTop: 10 }}>
                                            {`2. Subscribe to ${this.state.paymentInfo.title}`}
                                        </span>
                                        <div
                                            className="my-row"
                                            style={{
                                                marginTop: 50,
                                                alignItems: "center",
                                            }}
                                        >
                                            <a href="/home.html">
                                                Cancel and go to home page
                                            </a>
                                            <Button
                                                type="button"
                                                className="btn btn-sm btn-primary my-primary"
                                                style={{
                                                    alignSelf: "center",
                                                    width: "112px",
                                                    marginLeft: 50,
                                                }}
                                                onClick={() => {
                                                    let internalLink =
                                                        "/api/create_setup_checkout_session";
                                                    if (
                                                        this.state.linkId !=
                                                        null
                                                    ) {
                                                        internalLink += `?app_id=${encodeURIComponent(
                                                            this.state.linkId
                                                        )}`;
                                                    } else if (
                                                        this.state
                                                            .sharedModuleId !=
                                                        null
                                                    ) {
                                                        internalLink += `?shared_module_id=${encodeURIComponent(
                                                            this.state.sharedModuleId.toString()
                                                        )}`;
                                                    }
                                                    axios
                                                        .get<{
                                                            redirect_url?: string;
                                                            success: boolean;
                                                            error_msg: string;
                                                        }>(internalLink)
                                                        .then((response) => {
                                                            if (
                                                                response.data
                                                                    .success
                                                            ) {
                                                                window.location.href = response.data.redirect_url!;
                                                            } else {
                                                                this.setState({
                                                                    status:
                                                                        PopupStatus.Error,
                                                                    message:
                                                                        response
                                                                            .data
                                                                            .error_msg,
                                                                });
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            this.setState({
                                                                status:
                                                                    PopupStatus.Error,
                                                                message: String(
                                                                    error
                                                                ),
                                                            });
                                                        });
                                                }}
                                            >
                                                NEXT
                                            </Button>
                                        </div>
                                    </div>
                                    <div
                                        className="flex-simple-column"
                                        style={{
                                            width: "25%",
                                            paddingRight: 30,
                                        }}
                                    >
                                        <div
                                            className="flex-simple-column"
                                            style={{
                                                width: "100%",
                                                minHeight: 300,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    textAlign: "left",
                                                    color:
                                                        "var(--popup-secondary-text-color)",
                                                    fontFamily: "Roboto",
                                                    fontSize: 17,
                                                    fontWeight: 700,
                                                    marginTop: 10,
                                                }}
                                            >
                                                Description
                                            </span>
                                            <div>
                                                {this.showMultilineText(
                                                    this.state.paymentInfo
                                                        .description ?? ""
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="flex-simple-column"
                                            style={{
                                                width: "100%",
                                                minHeight: 300,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    textAlign: "left",
                                                    color:
                                                        "var(--popup-secondary-text-color)",
                                                    fontFamily: "Roboto",
                                                    fontSize: 17,
                                                    fontWeight: 700,
                                                    marginTop: 10,
                                                }}
                                            >
                                                Keywords
                                            </span>
                                            <div>
                                                {this.showMultilineText(
                                                    this.state.paymentInfo.keywords?.join(
                                                        "\n"
                                                    ) ?? ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        let height = 712;
        let width = 1000;
        return (
            <ScaledPage>
                <div
                    className="dashboard-rect"
                    style={{
                        overflow: "visible",
                        position: "fixed",
                        border: "1px solid black",
                        top: (window.innerHeight - height) / 2,
                        alignItems: "center",
                        cursor: "pointer",
                        left: (window.innerWidth - width) / 2,
                        width: width,
                        zIndex: 1000,
                    }}
                >
                    {this.buildInnerView()}
                    {this.state.status != null && (
                        <StatusPopup
                            status={this.state.status}
                            message={this.state.message}
                            onClose={() => {
                                this.setState({
                                    status: null,
                                    message: "",
                                });
                            }}
                        />
                    )}
                </div>
            </ScaledPage>
        );
    }
}

export { MainComponent };
export let route = "/attach_and_subscribe.html";
