import React from "react";
import KanbanBoardWrapper from "common/graphics/v2/KanbanBoardWrapper";
import Finding from "common/Finding";

function MainComponent(props: {
    finding: Finding;
    onNewFinding?: (finding: Finding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
}) {
	let finding = props.finding;
	return (
		<KanbanBoardWrapper
            data={finding.content.data}
			config={finding.config}
            preview={props.preview}
            editable={props.editable}
            columnDragActive={props.columnDragActive}
            onChangeData={
                props.onNewFinding
                    ? (data: any, updateData?: boolean) => {
                          let finding = { ...props.finding };
                          finding.content.data = data;
                          props.onNewFinding!(finding, updateData);
                      }
                    : undefined
            }
            onChangeConfig={
                props.onNewFinding
                    ? (config: any, updateData?: boolean) => {
                          let finding = { ...props.finding };
                          finding.config = config;
                          props.onNewFinding!(finding, updateData);
                      }
                    : undefined
            }
		/>
	);
}

export { MainComponent };
