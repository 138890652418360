import React, { Component } from "react";
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker,
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import { mainStyle } from "common/MainStyle";
import { RGBAToHexA } from "common/utilities/ColorUtils";

const defaultGeographyLevels = {
    city: "city",
    state: "state",
    latitude: "latitude",
    longitude: "longitude",
    zipcode: "zipcode",
};

interface Props {
    height: number;
    leaders?: { [key: string]: string | number | null }[] | null;
    laggers?: { [key: string]: string | number | null }[] | null;
    geographyLevels?: { [key: string]: string } | null;
    leadersColor?: string;
    laggersColor?: string;
    watermark?: string;
}

interface State {
    markersCount: number;
}

class MapComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            markersCount: 5,
        };
    }

    private calcRadius(index: number, length: number): number {
        return 10 + (40 * (length - index)) / length;
    }

    private calcColor(index: number, length: number, isLeader: boolean): string {
        let r, g, b, a;
        if (isLeader) {
            r = 51;
            g = 153;
            b = 255;

            a = (length - index) / length;

            return RGBAToHexA(r, g, b, a);
        } else {
            r = 238;
            g = 66;
            b = 61;

            a = (length - index) / length;

            return RGBAToHexA(r, g, b, a);
        }
    }

    static getDerivedStateFromProps(_nextProps: Props, prevState: State): State {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 100);
        return prevState;
    }

    public componentDidMount(): void {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 100);
    }

    public render(): JSX.Element {
        let geographyLevels =
            this.props.geographyLevels ?? defaultGeographyLevels;
        let leaders = this.props.leaders
            ?.slice(0, this.state.markersCount)
            ?.filter((leader) => leader[geographyLevels.latitude])
            ?.filter((leader) => leader[geographyLevels.state] !== "PR")
            ?.filter(
                (leader) => !(leader[geographyLevels.zipcode] as string).startsWith("00")
            ) ?? [];
        let laggers = this.props.laggers
            ?.slice(0, this.state.markersCount)
            ?.filter((lagger) => lagger[geographyLevels.latitude])
            ?.filter((lagger) => lagger[geographyLevels.state] !== "PR")
            ?.filter(
                (lagger) => !(lagger[geographyLevels.zipcode] as string).startsWith("00")
            ) ?? [];

        let leaderStates = new Set();
        let laggersStates = new Set();
        if (leaders.concat(laggers).length === 0) {
            leaderStates = new Set(
                this.props.leaders?.map(
                    (leader) => leader[geographyLevels.state]
                )
            );
            laggersStates = new Set(
                this.props.laggers?.map(
                    (lagger) => lagger[geographyLevels.state]
                )
            );
        }
        let defaultStateStyle = {
            default: {
                fill: "#242E42",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
            hover: {
                cursor: "pointer",
                fill: "#A0D1DC",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
            pressed: {
                fill: "#A0D1DC",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
        };

        let highlitedLeadersStateStyle = {
            default: {
                fill: this.props.leadersColor ?? "#39F",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
            hover: {
                cursor: "pointer",
                fill: "#A0D1DC",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
            pressed: {
                fill: "#A0D1DC",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
        };
        let highlitedLaggersStateStyle = {
            default: {
                fill: this.props.laggersColor ?? "#EE423D",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
            hover: {
                cursor: "pointer",
                fill: "#A0D1DC",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
            pressed: {
                fill: "#A0D1DC",
                stroke: "#607D8B",
                strokeWidth: 0.25,
                outline: "none",
            },
        };

        return (
            <div className="flex-column">
                <div>
                    <ComposableMap
                        projection="albersUsa"
                        projectionConfig={{
                            scale: 1000,
                        }}
                        style={{
                            width: "100%",
                            height: this.props.height,
                        }}
                    >
                        <ZoomableGroup>
                            <Geographies<JSON>
                                geography="/dist/map/states.json"
                                disableOptimization
                            >
                                {(geographies, projection) =>
                                    geographies.map((geography) => {
                                        return (
                                            <Geography
                                                key={`state-${(geography as any).properties.ID_1}`}
                                                cacheId={`state-${(geography as any).properties.ID_1}`}
                                                round
                                                geography={geography}
                                                data-tip={
                                                    (geography as any).properties.NAME_1
                                                }
                                                projection={projection}
                                                style={
                                                    leaderStates.has(
                                                        (geography as any).properties
                                                            .CODE_1
                                                    )
                                                        ? highlitedLeadersStateStyle
                                                        : laggersStates.has(
                                                              (geography as any)
                                                                  .properties
                                                                  .CODE_1
                                                          )
                                                        ? highlitedLaggersStateStyle
                                                        : defaultStateStyle
                                                }
                                            />
                                        );
                                    })
                                }
                            </Geographies>
                            {this.props.watermark != null && (
                                <text
                                    x="50%"
                                    y="30%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fill={mainStyle.getPropertyValue(
                                        "--content-secondary-text-color"
                                    )}
                                    fontSize={"4rem"}
                                    style={{
                                        opacity: 0.1,
                                    }}
                                >
                                    {this.props.watermark}
                                </text>
                            )}
                            <Markers>
                                {leaders.map((leader, i) => (
                                    <Marker
                                        key={i}
                                        marker={{
                                            coordinates: [
                                                leader[
                                                    geographyLevels.longitude
                                                ] as number,
                                                leader[
                                                    geographyLevels.latitude
                                                ] as number,
                                            ],
                                            name: leader[geographyLevels.city] as string,
                                        }}
                                        style={{
                                            hover: { cursor: "pointer" },
                                        }}
                                    >
                                        <circle
                                            data-tip={
                                                leader[geographyLevels.city]
                                            }
                                            cx={0}
                                            cy={0}
                                            r={8}
                                            fill={
                                                this.props.leadersColor ||
                                                "#39F"
                                            }
                                            stroke="#383f47"
                                        />
                                    </Marker>
                                ))}
                            </Markers>
                            <Markers>
                                {laggers.map((lagger, i) => (
                                    <Marker
                                        key={i}
                                        marker={{
                                            coordinates: [
                                                lagger[
                                                    geographyLevels.longitude
                                                ] as number,
                                                lagger[
                                                    geographyLevels.latitude
                                                ] as number,
                                            ],
                                            name: lagger[geographyLevels.city] as string,
                                        }}
                                        style={{
                                            hover: { cursor: "pointer" },
                                        }}
                                    >
                                        <circle
                                            data-tip={
                                                lagger[geographyLevels.city]
                                            }
                                            cx={0}
                                            cy={0}
                                            r={8}
                                            fill={
                                                this.props.laggersColor ||
                                                "#EE423D"
                                            }
                                            stroke="#383f47"
                                        />
                                    </Marker>
                                ))}
                            </Markers>
                        </ZoomableGroup>
                    </ComposableMap>

                    <ReactTooltip />
                </div>
            </div>
        );
    }
}

export default MapComponent;
