import "common/styles/App.css";
import React, { Component, useContext } from "react";
import { observer } from "mobx-react";
import Instrumentation from "common/Instrumentation";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import StoreContext from "state/StoreContext";
import RootStore from "state/store";
import styles from "./main.module.css";
import { ReactComponent as InputIcon } from "icons/left_nav_menu/input.svg";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import MediaCollectionsComponent from "./MediaCollectionsComponent"
import {FontLoaderAdmin} from "common/FontLoader";

interface Props {
    store: RootStore;
    globalContext: GlobalContextContents;
    canvasTreeStore?: CanvasTreeStore;
    onReturnToCanvas?: () => void;
}

const MainComponent = observer(
    class MainComponent extends Component<Props> {
        performance: Date | null;
        mounted: boolean;
        tutorialId: number | undefined;
        constructor(props: Props) {
            super(props);
            this.performance = null;
            this.mounted = false;
        }

        componentDidMount() {
            FontLoaderAdmin.update();
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("MediaCollections", timeMs);
            }
        }

        componentDidUpdate() {
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("MediaCollections", timeMs);
            }
        }
        render() {
            if (!this.mounted) {
                this.performance = new Date();
                this.mounted = true;
            }

            return (
                <>
                    <div className="content-wrapper">
                        <div className={styles.content}>
                            <section className={styles.content}>
                                <MediaCollectionsComponent/>
                            </section>
                        </div>
                    </div>
                </>
            );
        }
    }
);

const ConnectedComponent = (props: any) => {
    const store = useContext(StoreContext);
    const globalContext = useContext(GlobalContext);
    return (
        <MainComponent store={store} globalContext={globalContext} {...props} />
    );
};

export { ConnectedComponent as MainComponent };
export let route = "/media_collections.html",
    section = "MediaCollections",
    leftPanelItem = {
        section: "MediaCollections",
        href: "media_collections.html",
        icon: <InputIcon className={styles.icon} />,
    },
    requirePermission = "MediaCollections";
