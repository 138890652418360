import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import TabTitle from "common/TabTitle";
import UserInfo from "common/UserInfo";
import { DataTableInput } from "modules/canvas_page/DataTableInputSelector";
import Settings from "./Settings";
import Data from "./Data";

import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";

import styles from "./Textbox.module.css";

interface Props {
  canvasId: number;
  node: CanvasTextBox;
  delegate?: UserInfo;
  userOptions: UserInfo[];
  nodeLinkOptions: NodeLinkOption[];
  shared: boolean;
  currentModuleId?: number;
  dataTableInput: DataTableInput[];
  onChange: (changes: Partial<CanvasElement>, commit?: boolean) => void;
  onChangeDataTableInput: (newValue: DataTableInput[]) => void;
  onChangeDelegate: (user?: UserInfo) => void;
  onChangeShared: (shared: boolean) => void;
  onClose: () => void;
}

function Box({ node, delegate, userOptions, shared, nodeLinkOptions, canvasId, currentModuleId, dataTableInput,
  onChange, onChangeDataTableInput, onChangeDelegate, onChangeShared, onClose
}: Props) {
  const [selectedTab, setSelectedTab] = useState("settings");

  function selectTab(tabKey: string | null) {
    if (tabKey == null) return;
    setSelectedTab(tabKey);
  }

  return (
    <div>
      <div className={styles.editMenuHeader}>
        <h6 className={styles.editMenuHeaderTitle}>Advanced Options</h6>
        <button
          type="button"
          onClick={onClose}
          className={styles.editMenuCloseButton}
        >
          <CloseIcon />
        </button>
      </div>
      <Tabs
        id="advanced-menu-textbox-tabs"
        activeKey={selectedTab}
        onSelect={selectTab}
        className={styles.tabMenu}
      >
        <Tab
          eventKey="settings"
          title={
            <TabTitle
              title="Settings"
              selected={selectedTab === "settings"}
              className={styles.tabTitle}
            />
          }
          className={styles.tab}
        >
          <Settings
            node={node}
            delegate={delegate}
            userOptions={userOptions}
            nodeLinkOptions={nodeLinkOptions}
            shared={shared}
            onChange={onChange}
            onChangeDelegate={onChangeDelegate}
            onChangeShared={onChangeShared}
          />
        </Tab>
        <Tab
          eventKey="data"
          title={
            <TabTitle
              title="Data"
              selected={selectedTab === "data"}
              className={styles.tabTitle}
            />
          }
          className={styles.tab}
        >
          <Data
            canvasId={canvasId}
            node={node}
            currentModuleId={currentModuleId}
            nodeLinkOptions={nodeLinkOptions}
            dataTableInput={dataTableInput}
            onChange={onChange}
            onChangeDataTableInput={onChangeDataTableInput}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Box;
