import User from "state/models/User";
import {
    BriefModule,
    LinkSharingInfo,
    SpecialTabId,
    AppModule,
} from "common/Module";

export enum PresentationType {
    RegularPresentation = 1,
    Template = 2,
    App = 3,
    MarketPlace = 4,
}

export interface CategoryData {
    id: number | null;
    title: string;
    thumbnail: string;
}

export interface AbstractPresentationModel {
    id: number | string;
    title: string;
    thumbnail?: string;
    owner: User;
    keywords?: Array<string>;
    description?: string;
    type: PresentationType;
    lastUpdated: string;
    isShared: boolean;
    isTutorial: () => boolean;
    isKit: () => boolean;
    isCreateNewTemplate: () => boolean;
}

class Presentation implements AbstractPresentationModel {
    id: number | string;
    folderId?: number;
    categoryId?: number;
    appId?: string;
    title: string;
    tabId: string | number | null;
    description?: string;
    lastUpdated: string;
    thumbnail?: string;
    owner: User;
    keywords?: Array<string>;
    sharedLink?: LinkSharingInfo;
    isShared: boolean;
    userCopyExists: boolean;
    inTrash: boolean;
    rawUpdateTime: number;
    type: PresentationType;
    image: Blob | undefined;

    constructor(
        presentationJson: BriefModule | AppModule,
        type: PresentationType,
        inTrash: boolean = false
    ) {
        this.id = presentationJson.id;
        this.categoryId = presentationJson.category?.id;
        this.folderId = presentationJson.folder_id;
        this.type = type;
        this.description = presentationJson.description;
        this.lastUpdated = presentationJson.update_time;
        this.thumbnail = presentationJson.thumbnail;
        this.owner = new User(presentationJson.user_data);
        this.keywords = presentationJson.keywords;
        this.isShared = !presentationJson.user_data?.is_owner;
        this.rawUpdateTime = presentationJson.raw_update_timestamp;
        this.inTrash = inTrash;
        if (type !== PresentationType.App) {
            this.title = presentationJson.title;
            this.tabId = (presentationJson as BriefModule).tab_id;
            this.sharedLink = (presentationJson as BriefModule).shared_link;
            this.userCopyExists =
                (presentationJson as BriefModule).user_copy_exists ?? false;
        } else {
            this.title = (presentationJson as AppModule).app.title;
            this.tabId = (presentationJson as AppModule).app.tab_id;
            this.keywords = (presentationJson as AppModule).app.keywords ?? this.keywords;
            this.description =  (presentationJson as AppModule).app.description ?? this.description;
            this.thumbnail = (presentationJson as AppModule).app.thumbnail ?? this.thumbnail;
            this.sharedLink = undefined;
            this.userCopyExists = false;
        }
    }
    isCreateNewTemplate(): boolean {
        return this.tabId === SpecialTabId.CreateNew;
    }
    isTutorial(): boolean {
        return (
            this.tabId === SpecialTabId.Tutorials ||
            this.tabId === SpecialTabId.TutorialsInitialList
        );
    }
    isKit(): boolean {
        return this.tabId === SpecialTabId.Kit;
    }
}

export default Presentation;
