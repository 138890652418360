import React from "react";
import { HoldOutPredictionFinding } from "common/Finding";
import styles from "./HoldOutPredictionChart.module.css";
import cx from "classnames";
import HoldOutPredictionBarChart from "./HoldOutPredictionBarChart";
import _ from "lodash";

interface Props {
    data: HoldOutPredictionFinding["content"]["data"];
    config: HoldOutPredictionFinding["config"];
    regressionInfo: HoldOutPredictionFinding["content"]["regressionInfo"];
    groupNames?: string[] | null;
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    dataSetMenuIsOpen?: boolean;
    onChangeData?: (
        data: HoldOutPredictionFinding["content"]["data"],
        updateFinding?: boolean
    ) => void;
    onChangeConfig?: (
        config: HoldOutPredictionFinding["config"],
        updateFinding?: boolean
    ) => void;
}

function getModel(
    regressionInfo: HoldOutPredictionFinding["content"]["regressionInfo"],
    config: HoldOutPredictionFinding["config"]
): string {
    let model: string = "";
    let mainTerm = "";
    if (regressionInfo == null && config.dataScope == null)
        model = "Y = intercept + \u03B21 x1 + \u03B22 x2";
    else if (regressionInfo == null) model = "";
    else {
        let betaIndex = 1;
        let allIv = [regressionInfo.iv.main, ...regressionInfo.iv.other];
        let allIvLog = [
            regressionInfo.iv.main_log,
            ...regressionInfo.iv.other_log,
        ];

        model = `${regressionInfo.dv_log ? "log " : ""}${regressionInfo.dv} = ${
            regressionInfo.intercept ? "intercept" : ""
        }${mainTerm}`;
        for (let i in allIv) {
            if (i === "0" && !regressionInfo.intercept) {
                model += `\u03B2${betaIndex} ${allIvLog[i] ? "log " : ""}${allIv[i] ?? ""}`;
            } else model += ` + \u03B2${betaIndex} ${allIvLog[i] ? "log " : ""}${allIv[i] ?? ""}`;
            betaIndex += 1;
        }
        for (let item of regressionInfo.iv.interactions) {
            let item1Log = allIvLog[allIv.findIndex((iv) => iv === item.var1)];
            let item2Log = allIvLog[allIv.findIndex((iv) => iv === item.var2)];
            model += ` + \u03B2${betaIndex} ${item1Log ? "log " : ""}${item.var1} ${
                item2Log ? "log " : ""
            }${item.var2}`;
            betaIndex += 1;
        }
    }

    // if (regressionInfo?.iv.interacts_with != null) {
    //     interactsWithTerm = `${
    //         regressionInfo?.iv.interacts_with_log ? "log " : ""
    //     }${regressionInfo?.iv.interacts_with ?? ""}`;
    //     model += ` + ${interactsWithTerm}`;
    //     model += ` + ${mainTerm} ${interactsWithTerm}`;
    // }

    return "Current model: ".concat(model);
}

export function HoldOutPredictionChart(props: Props) {
    let model = getModel(props.regressionInfo, props.config);
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {(props.config.showModel ?? true) && (
                <div className={styles.modelContainer} title={model}>
                    <textarea
                        disabled
                        className={cx(styles.summaryArea, styles.modelArea)}
                        style={{
                            resize: "none",
                            width: "100%",
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        value={model}
                    ></textarea>
                </div>
            )}
            {!props.preview && props.regressionInfo != null && (
                <HoldOutPredictionBarChart
                    preview={false}
                    data={props.data}
                    config={_.cloneDeep(props.config)}
                    onChangeConfig={props.onChangeConfig}
                    onChangeData={props.onChangeData}
                    regressionInfo={props.regressionInfo}
                    groupNames={props.groupNames}
                />
            )}
            {(props.preview || props.config.dataScope == null) && (
                <HoldOutPredictionBarChart
                    preview
                    data={{
                        ivMainNames: ["x"],
                        ivOtherNames: [],
                        predictedBars: [
                            {
                                confidence_interval: [0.95, 1],
                                chart_interval: [0.95, 1],
                                confidence_level: 0.95,
                                sample_size: 1000,
                                model_id: 0,
                                show_intervals: true,
                                mean_iv: [0.99],
                                mean_predicted: 0.99,
                                mean_true: 0.98,
                            },
                        ],
                    }}
                    config={_.cloneDeep(props.config)}
                    regressionInfo={{
                        dv: "y",
                        dv_log: false,
                        model: {
                            name: "dummy",
                        },
                        predict_split: {
                            train_size: 0.5,
                            confidence_level: 0.95,
                        },
                        intercept: false,
                        iv: {
                            main: "x",
                            main_log: false,
                            main_fixed: false,
                            other: [],
                            other_log: [],
                            other_fixed: [],
                            interactions: [],
                        },
                    }}
                    groupNames={props.groupNames}
                />
            )}
        </div>
    );
}

export default HoldOutPredictionChart;
