import React from "react";
import { StatusBarExpression } from "common/Canvas";
import ColorOptions from "common/ColorOptions";
import Select, { createFilter } from "react-select";
import customSelectStyles, { Styles } from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";
import { Button } from "react-bootstrap";
import { NodeLinkOption } from "common/Conditions";
import StringOption from "common/StringOption";
import NumericOption from "common/NumericOption";
import cx from "classnames";
import StatusSubExpressionSelector from "common/StatusSubExpressionsSelector";

interface Props {
    spanStyle?: React.CSSProperties;
    expressionContainerStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    upDownButtonsClass?: string;
    selectStyles?: Styles;
    variables: NumericOption[];
    nodeLinkOptions: NodeLinkOption[];
    statusExpressions: StatusBarExpression[];
    onChange: (changes: StatusBarExpression[]) => void;
}

const colorOptionsWithoutNeutral = ColorOptions.slice(1);

export const defaultExpressions: StatusBarExpression[] = [
    {
        subexpressions: [
            {
                operation: "",
                value: "",
            },
        ],
        color: colorOptionsWithoutNeutral[0] as StringOption,
        variable: null,
    },
];

export default class ColorExpressionsSelector extends React.Component<Props> {
    render() {
        return (
            <div className="flex-simple-column">
                {this.props.statusExpressions.map(
                    (statusExpression, expressionIndex) => (
                        <div
                            key={expressionIndex}
                            className="my-row"
                            style={{
                                marginLeft: "10px",
                                ...this.props.expressionContainerStyle
                            }}
                        >
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"COLOR"}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginLeft: "26px",
                                        width: "110px",
                                        height: "38px",
                                    }),
                                    placeholder: (base) => ({
                                        ...base,
                                        color: "#869AAC",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color:
                                            state.data.value == null ||
                                            state.data.value === "transparent"
                                                ? mainStyle.getPropertyValue(
                                                      "--selectors-text-color"
                                                  )
                                                : state.data.value,
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color:
                                            state.data.value == null ||
                                            state.data.value === "transparent"
                                                ? mainStyle.getPropertyValue(
                                                      "--selectors-text-color"
                                                  )
                                                : state.data.value,
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        display: state.selectProps.menuIsOpen
                                            ? "none"
                                            : "block",
                                    }),
                                    ...this.props.selectStyles,
                                }}
                                options={colorOptionsWithoutNeutral}
                                value={statusExpression.color}
                                onChange={(newValue) => {
                                    const newStatusColorValue: StringOption =
                                        newValue as StringOption;
                                    let statusExpressions = Array.from(
                                        this.props.statusExpressions
                                    );
                                    statusExpressions[expressionIndex].color =
                                        newStatusColorValue;
                                    this.props.onChange(statusExpressions);
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    height: "38px",
                                }}
                            >
                                <span
                                    style={{
                                        marginLeft: "5px",
                                        textAlign: "right",

                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        ...this.props.spanStyle,
                                    }}
                                >
                                    {"For"}
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            height: "38px",
                                            width: "120px",
                                            marginLeft: "5px",
                                        }),
                                        ...this.props.selectStyles,
                                    }}
                                    options={this.props.variables}
                                    onChange={(newValue) => {
                                        let newStatusExpressions = Array.from(
                                            this.props.statusExpressions
                                        );
                                        newStatusExpressions[
                                            expressionIndex
                                        ].variable = newValue as NumericOption;

                                        this.props.onChange(
                                            newStatusExpressions
                                        );
                                    }}
                                    value={this.props.variables.find(
                                        (variable) =>
                                            statusExpression.variable?.value ===
                                            variable.value
                                    )}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <StatusSubExpressionSelector
                                spanStyle={this.props.spanStyle}
                                selectStyles={this.props.selectStyles}
                                upDownButtonsClass={
                                    this.props.upDownButtonsClass
                                }
                                nodeLinkOptions={this.props.nodeLinkOptions}
                                subexpressions={statusExpression.subexpressions}
                                onChange={(subexpressions) => {
                                    let statusExpressions = Array.from(
                                        this.props.statusExpressions
                                    );
                                    statusExpressions[
                                        expressionIndex
                                    ].subexpressions = subexpressions;
                                    this.props.onChange(statusExpressions);
                                }}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 20 }}
                            >
                                <Button
                                    className={
                                        this.props.upDownButtonsClass != null
                                            ? cx(
                                                  "btn-small",
                                                  this.props.upDownButtonsClass
                                              )
                                            : "btn-small-like-select"
                                    }
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let statusExpressions = Array.from(
                                            this.props.statusExpressions
                                        );
                                        statusExpressions.push({
                                            subexpressions: [
                                                { operation: "", value: "" },
                                            ],
                                            variable: null,
                                            color: colorOptionsWithoutNeutral[0],
                                        });

                                        this.props.onChange(statusExpressions);
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className={
                                        this.props.upDownButtonsClass != null
                                            ? cx(
                                                  "btn-small",
                                                  this.props.upDownButtonsClass
                                              )
                                            : "btn-small-like-select"
                                    }
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let statusExpressions = Array.from(
                                            this.props.statusExpressions
                                        );
                                        statusExpressions.splice(
                                            expressionIndex,
                                            1
                                        );
                                        if (statusExpressions.length === 0) {
                                            statusExpressions.push({
                                                subexpressions: [
                                                    {
                                                        operation: "",
                                                        value: "",
                                                    },
                                                ],
                                                color: colorOptionsWithoutNeutral[0],
                                                variable: null,
                                            });
                                        }

                                        this.props.onChange(statusExpressions);
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}
