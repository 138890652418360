import React from "react";
import FinalStep1A from "common/graphics/FinalStep1A";

function MainComponent(props) {
	let finding = props.finding;
	return (
		<FinalStep1A
			frozen={true}
			leaders={finding.content.leaders}
			laggers={finding.content.laggers}
			geographyLevels={finding.content.geographyLevels}
			targetVariable={finding.content.targetVariable}
			showedVariables={finding.content.showedVariables}
			units={finding.content.units}
			showMap={finding.content.showMap}
			config={finding.config}
		/>
	);
}

export { MainComponent };