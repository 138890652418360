import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
    DraggableCore,
    DraggableEvent,
    DraggableData,
    DraggableEventHandler,
} from "react-draggable";

interface State {
    position: { x: number; y: number };
    snapPosition: { x: number; y: number };
    isElementSVG: boolean;
}

interface Props {
    // DraggableCore props
    allowAnyClick?: boolean;
    cancel?: string;
    disabled?: boolean;
    enableUserSelectHack?: boolean;
    offsetParent?: HTMLElement;
    grid?: [number, number];
    handle?: string;
    nodeRef?: React.RefObject<HTMLElement>;
    onStart?: DraggableEventHandler;
    onStop?: DraggableEventHandler;
    onMouseDown?: (e: MouseEvent) => void;
    scale?: number;

    // Redefined and new props
    onDrag?: (
        evt: DraggableEvent,
        data: DraggableData
    ) => void | false | { x?: number; y?: number };
    position?: { x: number; y: number };

    children: React.ReactElement;
}

class DraggableWithSnapping extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            position: props.position ?? { x: 0, y: 0 },
            snapPosition: props.position ?? { x: 0, y: 0 },
            isElementSVG: false,
        };

        this.onDrag = this.onDrag.bind(this);
        this.onStart = this.onStart.bind(this);
        this.onStop = this.onStop.bind(this);
    }

    public componentDidMount(): void {
        // Check to see if the element passed is an instanceof SVGElement
        if (
            typeof window.SVGElement !== "undefined" &&
            (this.props?.nodeRef?.current ??
                ReactDOM.findDOMNode(this)) instanceof window.SVGElement
        ) {
            this.setState({ isElementSVG: true });
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (
            this.props.position != null &&
            (prevProps.position == null ||
                prevProps.position.x !== this.props.position.x ||
                prevProps.position.y !== this.props.position.y)
        ) {
            this.setState({
                position: this.props.position,
                snapPosition: this.props.position,
            });
        }
    }

    private changePositionInData(
        data: DraggableData,
        useSnapPosition: boolean = false
    ): DraggableData {
        let scale = this.props.scale ?? 1;
        let position = useSnapPosition
            ? this.state.snapPosition
            : this.state.position;
        // Make the data match Draggable
        return {
            node: data.node,
            x: position.x + data.deltaX / scale,
            y: position.y + data.deltaY / scale,
            deltaX: data.deltaX / scale,
            deltaY: data.deltaY / scale,
            lastX: position.x,
            lastY: position.y,
        };
    }

    private onDrag(evt: DraggableEvent, data: DraggableData): void | false {
        let newData = this.changePositionInData(data);
        let returnValue = this.props.onDrag?.(evt, newData);
        if (returnValue === false) {
            return false;
        } else if (returnValue != null) {
            this.setState({
                position: {
                    x: newData.x,
                    y: newData.y,
                },
                snapPosition: {
                    x: returnValue.x ?? newData.x,
                    y: returnValue.y ?? newData.y,
                },
            });
        } else {
            this.setState({
                position: {
                    x: newData.x,
                    y: newData.y,
                },
                snapPosition: {
                    x: newData.x,
                    y: newData.y,
                },
            });
        }
    }

    private onStart(evt: DraggableEvent, data: DraggableData): void | false {
        return this.props.onStart?.(evt, this.changePositionInData(data));
    }

    private onStop(evt: DraggableEvent, data: DraggableData): void | false {
        return this.props.onStop?.(evt, this.changePositionInData(data, true));
    }

    public render(): JSX.Element {
        const transform = {
            transform: `translate(${this.state.snapPosition.x}px, ${this.state.snapPosition.y}px)`,
        };

        const styleTransform = this.state.isElementSVG ? undefined : transform;
        const svgTransform = this.state.isElementSVG ? transform : undefined;

        return (
            <DraggableCore
                allowAnyClick={this.props.allowAnyClick}
                cancel={this.props.cancel}
                disabled={this.props.disabled}
                enableUserSelectHack={this.props.enableUserSelectHack}
                offsetParent={this.props.offsetParent}
                grid={this.props.grid}
                handle={this.props.handle}
                nodeRef={this.props.nodeRef}
                onMouseDown={this.props.onMouseDown}
                scale={this.props.scale}
                onDrag={this.onDrag}
                onStart={this.onStart}
                onStop={this.onStop}
            >
                {React.cloneElement(React.Children.only(this.props.children), {
                    style: {
                        ...this.props.children.props.style,
                        ...styleTransform,
                    },
                    ...svgTransform,
                })}
            </DraggableCore>
        );
    }
}

export default DraggableWithSnapping;
