import React from "react";
import cx from "classnames";
import styles from "./ThemesBar.module.css";
import commonStyles from "modules/home_page/home_page.module.css";
import pressentationCardStyles from "common/home_components/PresentationCard/PresentationCard.module.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Presentation, { PresentationType } from "state/models/Presentation";

interface Props {
    theme: Presentation;
    onClickTheme: (key: string, id: string | number) => void;
    onClickDelete?: (id: number) => void;
}

function PresentationCategoriesBar({
    theme,
    onClickTheme,
    onClickDelete,
}: Props) {
    const themeType = theme.type === PresentationType.Template ? "templates" : "apps";
    const deleteCallback = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (onClickDelete) {
            onClickDelete(theme.id as number);
        }
    }
    
    return (
        <div className={styles.themeContainer}>
            {onClickDelete && (
                <button
                    type="button"
                    onClick={deleteCallback}
                    className={pressentationCardStyles.deleteButton}
                >
                    <img
                        src="/dist/img/insights/cross_big.png"
                        alt="delete"
                        className={pressentationCardStyles.deleteIcon}
                    />
                </button>
            )}

            <div
                className={styles.theme}
                onClick={() => onClickTheme(themeType, theme.id)}
            >
                {theme.type === PresentationType.Template && (
                    <img src={theme.thumbnail} alt="" className={styles.themeCover} />
                )}
                {theme.type === PresentationType.App && (
                    <div className={styles.appCover}>
                        <h2>{theme.title}</h2>
                    </div>
                )}
                <p
                    className={cx(commonStyles.smallText, styles.themeLabel)}
                >{theme.title}</p>
            </div>
        </div>
    )
}

export default PresentationCategoriesBar;
