import React from "react";
import { Accordion, Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import { VariableOption } from "common/Variables";
import {
	BarChartFinding,
	PieChartFinding,
	isBarChart,
} from "common/Finding";
import Variables from "common/Variables";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import commonStyles from "../DataSection/DataSection.module.css";
import ribbonStyles from "../ChartsRibbon.module.css";
import cx from "classnames";

enum BarChartMenuOptions {
	None = 0,
	HorizontalLines = 1,
}

interface Props {
	finding: BarChartFinding | PieChartFinding;
	currentModuleId?: number;
	onAddHorizontalLines: (
		finding: BarChartFinding | PieChartFinding,
		updateData?: boolean
	) => void;
}

export default function HorizontalLinesStylingOption(props: Props) {
	let selectStyles = getCustomSelectStyleForDataSection(14, false);
	let numericOptions: VariableOption[] = [];
	if (props.finding.config.dataScope != null) {
		numericOptions = Variables(
			props.finding.config.dataScope.value,
			props.currentModuleId
		).numericVariableOptions;
	}

	let [menuOptionSelected, setMenuOptionSelected] = React.useState(
		BarChartMenuOptions.None
	);

	let horizontalLines: ({
		name: string;
		value: number;
		variableIndex?: number;
		originalName?: string;
	} | null)[] = [null];
	if (isBarChart(props.finding)) {
		horizontalLines = props.finding.content.horizontalLines ?? [null];
		if (horizontalLines.length === 0) {
			horizontalLines = [null];
		}
	}
	return (
		<div>
			<div style={{ marginTop: 10 }}>
				<Accordion
					activeKey={String(menuOptionSelected)}
					onSelect={(event: any) => {
						setMenuOptionSelected(Number(event));
					}}
				>
					<Accordion.Toggle
						eventKey={String(
							BarChartMenuOptions.HorizontalLines
						)}
						className={ribbonStyles.editMenuAdvancedOption}
					>
						Horizontal Lines
						<ChevronIcon
							className={cx(
								ribbonStyles.editMenuAdvancedOptionIcon,
								{
									[ribbonStyles.chevronOpen]:
										menuOptionSelected ===
										BarChartMenuOptions.HorizontalLines,
								}
							)}
						/>
					</Accordion.Toggle>
					<Accordion.Collapse
						eventKey={String(
							BarChartMenuOptions.HorizontalLines
						)}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							{horizontalLines.map((item, index) => (
								<div
									className="my-row"
									style={{
										alignItems: "center",
										marginTop: "8px",
									}}
								>
									<Select
										key={index}
										filterOption={createFilter({
											ignoreAccents: false,
										})}
										placeholder="Select variable"
										styles={{
											...selectStyles,
											container: (base) => ({
												...base,
												width: "100%",
												height: "38px",
											}),
										}}
										options={numericOptions}
										onChange={(newValue) => {
											let finding: BarChartFinding = {
												...props.finding!,
												content: {
													...props.finding!
														.content,
													horizontalLines: [
														...horizontalLines,
													],
												},
											};
											finding.content.horizontalLines![
												index
											] = {
												name: (
													newValue as VariableOption
												).label,
												value: 0,
												originalName: (
													newValue as VariableOption
												).label,
												variableIndex: (
													newValue as VariableOption
												).value,
											};
											props.onAddHorizontalLines(finding, true);
										}}
										value={
											item?.variableIndex == null
												? null
												: {
													label: item.originalName,
													value: item.variableIndex,
												}
										}
										theme={(theme) => ({
											...theme,
											borderRadius: 0,
											colors: {
												...theme.colors,
												text: "white",
												primary25:
													"var(--selectors-background-hover-color)",
											},
										})}
									/>
									{index === 0 && (
										<Button
											title="Add Line"
											className={cx(
												"btn btn-sm btn-primary my-primary",
												commonStyles.addColumnButton
											)}
											onClick={() => {
												let finding: BarChartFinding =
												{
													...props.finding!,
													content: {
														...props.finding!
															.content,
														horizontalLines:
															[
																...horizontalLines,
																null,
															],
													},
												};
												props.onAddHorizontalLines(finding);
											}}
										>
											{"\uFF0B"}
										</Button>
									)}
									<Button
										title="Remove series"
										className={cx(
											"btn btn-sm btn-primary my-primary",
											commonStyles.addColumnButton,
											commonStyles.deleteColumnButton
										)}
										onClick={() => {
											let finding: BarChartFinding = {
												...props.finding!,
												content: {
													...props.finding!
														.content,
													horizontalLines: [
														...horizontalLines,
													],
												},
											};
											finding.content.horizontalLines!.splice(
												index,
												1
											);
											props.onAddHorizontalLines(finding, true);
										}}
									>
										{"\u2715"}
									</Button>
								</div>
							))}
						</div>
					</Accordion.Collapse>
				</Accordion>
			</div>
		</div>
	);
}
