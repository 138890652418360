import { CanvasInput, InputFieldStyle } from "common/Canvas";
import cx from "classnames";
import React, {
    ChangeEventHandler,
    CSSProperties,
    FocusEventHandler,
    KeyboardEventHandler,
    Ref,
    useRef,
} from "react";
import { getTextSize } from "common/utilities/MeasureText";
import { defaultInputFieldPlaceholder } from "modules/canvas_page/Constants";

interface Props {
    currentSimpleEdit: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
    editedNode: CanvasInput;
    style: CSSProperties;
    value: string;
    labelSize: number;
    scale: number;
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onChangeLabel: (label: string) => void;
    onFocus: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    keepSimpleEditChanges: Function;
    onKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const InputFieldMetrialDesign: React.FC<Props> = (props) => {
    const {
        currentSimpleEdit,
        style,
        value,
        onChange,
        onChangeLabel,
        onFocus,
        keepSimpleEditChanges,
        onKeyDown,
        editedNode,
        labelSize,
    } = props;
    const labelFontFamily = style?.fontFamily ?? "Roboto";
    const multiline = editedNode?.multiline ?? false;

    let [labelText, setLabelText] = React.useState(editedNode?.label ?? "Label");
    const inputFieldLabelRef = useRef<HTMLInputElement>(null);
    const inputFieldLineRef = useRef<HTMLDivElement>(null);
    const inputFieldLabelWhiteSpaceRef = useRef<HTMLDivElement>(null);

    const isMDFilledInputField = editedNode?.inputFieldStyle === InputFieldStyle.MDFilled;
    const isMDOutlinedInputField = editedNode?.inputFieldStyle === InputFieldStyle.MDOutlined;

    const labelClass = isMDFilledInputField ? "MDFilledInputFieldLabel" : "MDOutlinedInputFieldLabel"
    const inputClass = isMDOutlinedInputField
                        ? "MDOutlinedInputField"
                        : isMDFilledInputField
                            ? "MDFilledInputField"
                            : "";
    const inputLabelWhiteSpaceClass = "MDOutlinedInputFieldWhiteLabelSpace";

    let labelWhiteSpaceWidth = 0;
    let labelWidth = 0;
    let textMarginTop = 0;

    const textSize = getTextSize(
        labelText ?? "Label",
        labelFontFamily,
        labelSize,
        "600",
    );

    labelWidth = textSize.width;

    if (multiline) {
        textMarginTop = isMDFilledInputField ? textSize.height / 1.2 : textSize.height / 3;
    }

    labelWhiteSpaceWidth = labelWidth * 0.6 + 10;
    labelWidth += 20;
    return (
        <>
            {isMDOutlinedInputField && (
                <div
                    style={{
                        width: labelWhiteSpaceWidth,
                        height: 4,
                        position: "absolute",
                        top: -2,
                        left: 15,
                        backgroundColor: "transparent",
                    }}
                >
                    <div
                        ref={inputFieldLabelWhiteSpaceRef}
                        className={
                            value || currentSimpleEdit.current
                                ? `${inputLabelWhiteSpaceClass} active`
                                : `${inputLabelWhiteSpaceClass} notActive`
                        }
                    ></div>
                </div>
            )}
            <input
                type="text"
                value={labelText}
                ref={inputFieldLabelRef}
                className={value || currentSimpleEdit.current
                    ? `${labelClass} ${multiline ? "multiline" : ""} active`
                    : `${labelClass} ${multiline ? "multiline" : ""}`
                }
                style={{
                    fontSize: labelSize,
                    fontFamily: labelFontFamily,
                    width: labelWidth + 10,
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                onChange={(evt) => {
                    setLabelText(evt.target.value);
                    onChangeLabel(evt.target.value);
                }}
            />
            {isMDFilledInputField && (
                <div ref={inputFieldLineRef} className="MDFilledInputFieldLine"></div>
            )}
            {!multiline && (
                <input
                    ref={currentSimpleEdit as React.RefObject<HTMLInputElement>}
                    className={inputClass}
                    placeholder={defaultInputFieldPlaceholder}
                    autoFocus
                    style={style}
                    onFocus={(evt) => {
                        if (inputFieldLabelRef.current) {
                            inputFieldLabelRef.current.classList.add("active");
                        }
                        if (isMDFilledInputField && inputFieldLineRef.current) {
                            inputFieldLineRef.current.classList.add("active");
                        }
                        if (
                            isMDOutlinedInputField &&
                            inputFieldLabelWhiteSpaceRef.current
                        ) {
                            inputFieldLabelWhiteSpaceRef.current.classList.add("active");
                        }
                        onFocus(evt);
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={(evt) => {
                        keepSimpleEditChanges();
                    }}
                    onKeyDown={onKeyDown}
                />
            )}

            {multiline && (
                <textarea
                    ref={currentSimpleEdit as React.RefObject<HTMLTextAreaElement>}
                    className={cx(inputClass, "element")}
                    placeholder={defaultInputFieldPlaceholder}
                    autoFocus
                    style={{
                        ...style,
                        marginTop: textMarginTop,
                        height: `calc(100% - ${textMarginTop}px)`,
                        padding: "0 20px",
			            textAlign: "left",
                        resize: "none",
                    }}
                    onFocus={(evt) => {
                        if (inputFieldLabelRef.current) {
                            inputFieldLabelRef.current.classList.add("active");
                        }
                        if (isMDFilledInputField && inputFieldLineRef.current) {
                            inputFieldLineRef.current.classList.add("active");
                        }
                        if (
                            isMDOutlinedInputField &&
                            inputFieldLabelWhiteSpaceRef.current
                        ) {
                            inputFieldLabelWhiteSpaceRef.current.classList.add("active");
                        }
                        onFocus(evt);
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={(evt) => {
                        keepSimpleEditChanges();
                    }}
                    onKeyDown={onKeyDown}
                ></textarea>
            )}

        </>
    );
};

export default InputFieldMetrialDesign;
