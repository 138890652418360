import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { SentimentAnalysisFinding } from "common/Finding";
import { Condition } from "common/Conditions";

class Api {
    static getDefaultConfig(
        journeyName: string
    ): SentimentAnalysisFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            showEmotions: true,
            barSize: 15,
            barGap: 0,
            barCategoryGap: 60,
            linesCount: 1,
            showXAxisName: true,
            showYAxisName: false,
        };
    }

    static getPreviewFinding(journeyName: string): SentimentAnalysisFinding {
        let item = {
            type: "sentimentanalysis",
            content: {
                data: [
                    { name: "cheated", value: 0 },
                    { name: "singledout", value: 0 },
                    { name: "loved", value: 9 },
                    { name: "attracted", value: 0 },
                    { name: "sad", value: 0 },
                    { name: "fearful", value: 0 },
                    { name: "happy", value: 10 },
                    { name: "angry", value: 0 },
                    { name: "bored", value: 0 },
                    { name: "esteemed", value: 0 },
                    { name: "lustful", value: 0 },
                    { name: "attached", value: 0 },
                    { name: "independent", value: 0 },
                    { name: "embarrassed", value: 0 },
                    { name: "powerless", value: 0 },
                    { name: "surprise", value: 0 },
                    { name: "fearless", value: 0 },
                    { name: "safe", value: 7 },
                    { name: "adequate", value: 0 },
                    { name: "belittled", value: 0 },
                    { name: "hated", value: 0 },
                    { name: "codependent", value: 0 },
                    { name: "average", value: 0 },
                    { name: "apathetic", value: 0 },
                    { name: "obsessed", value: 0 },
                    { name: "entitled", value: 0 },
                    { name: "alone", value: 0 },
                    { name: "focused", value: 0 },
                    { name: "demoralized", value: 0 },
                    { name: "derailed", value: 0 },
                    { name: "anxious", value: 0 },
                    { name: "ecstatic", value: 0 },
                    { name: "free", value: 8 },
                    { name: "lost", value: 0 },
                    { name: "burdened", value: 0 },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static prepateSentimentDistribution(
        data: { [key: string]: number }[],
        showEmotions: boolean
    ): { name: string; value: number }[] {
        let result: { [key: string]: number } = {};
        if (!showEmotions)
            result = {
                neg: 0,
                neu: 0,
                pos: 0,
                // compound: 0,
            };
        for (let item of data) {
            for (const [key, value] of Object.entries(item)) {
                // skip compound
                if (showEmotions || key !== "compound")
                    result[key] = (result[key] ?? 0) + value;
            }
        }
        if (!showEmotions)
            return Object.keys(result).map((key) => ({
                name: key,
                value: result[key] / data.length,
            }));
        else {
            let chartData = Object.keys(result).filter(
                (key) => result[key] > 0
            );
            if (chartData.length === 0) {
                chartData = Object.keys(result);
            }
            return chartData.map((key) => ({
                name: key,
                value: result[key],
            }));
        }
    }

    static getData(
        finding: SentimentAnalysisFinding,
        findingOptions: any,
        moduleId?: number
    ): Promise<SentimentAnalysisFinding> {
        let { dataScope, selectedVariableIndex, showEmotions } = finding.config;

        let conditions: Condition[] | undefined = finding.config.conditions;

        conditions = conditions?.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        let requestJson = {
            variable_index: selectedVariableIndex,
            table: [],
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            conditions:
                conditions != null ? conditionsToJson(conditions) : undefined,
            module_id: moduleId,
            show_emotions: showEmotions ?? false,
        };

        return axios
            .post<{
                success: boolean;
                error_msg: string;
                data?: { [key: string]: number }[];
            }>("/api/e/sentiment_analysis", requestJson)
            .then((response) => {
                if (response.data.success && response.data.data != null) {
                    let data = Api.prepateSentimentDistribution(
                        response.data.data,
                        showEmotions
                    );
                    return Promise.resolve({
                        ...finding,
                        content: {
                            ...finding.content,
                            data: data,
                        },
                    });
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
