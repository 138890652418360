import React from "react";
import Select, { createFilter, CSSObjectWithLabel } from "react-select";
import {
    customSelectStylesLight,
    customSelectStylesRefine,
    getCustomSelectStyleLight,
    getCustomSelectStyleRefine,
} from "common/SelectStyles";
import { TableOption } from "common/Tables";
import { DataScopeOption } from "common/DataScopes";
import Variables, { VariableOption } from "common/Variables";
import { observer } from "mobx-react";
import SearchComponent, { SearchComponentOption } from "common/SearchComponent";
import { Condition } from "common/Conditions";
import _ from "lodash";
import remoteModuleId from "common/remoteModuleId";
import {
    CanvasDropdownSelector,
    DropdownStyle,
    TextAlign,
} from "common/Canvas";
import SelectStyled1 from "components/SelectStyled1";
import { reaction } from "mobx";
import { selectAllOptions } from "common/SearchComponentOptionWithMeta";

interface Props {
    variableMode?: boolean;
    allValues?: boolean;
    allowMultipleSelection: boolean;
    allowAllValues?: boolean;
    disabled?: boolean;
    frozen: boolean;
    live: boolean;
    conditions?: Condition[];
    tableOption: TableOption | null;
    variableOption: VariableOption | null;
    dataScopeOption: DataScopeOption | null;
    value: SearchComponentOption[] | SearchComponentOption | null;
    //   onChangeTable: (table: TableOption) => void;
    //   onChangeVariable: (variable: VariableOption) => void;
    onChangeValue: (
        value: SearchComponentOption[] | SearchComponentOption | null,
        allValues?: boolean
    ) => void;
    //   onChangeDataScope: (dataScope: DataScopeOption) => void;
    onSelectAllValues: () => void;
    currentModuleId?: number;
    rootDataTestId: string;
    node: CanvasDropdownSelector;
    theme?: DropdownStyle;
    scale?: number;
}

interface State {
    top: number;
    selectCompIsOpened: boolean;
}


@observer
class CanvasDropdownSelectorView extends React.Component<Props, State> {
    dataVariablesReaction: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            top: 0,
            selectCompIsOpened: false,
        }
    }

    componentDidMount(): void {
        this.initializeReaction();
        if (
            this.props.variableMode &&
            this.props.allValues &&
            this.props.dataScopeOption != null
        ) {
            if (
                Variables(
                    this.props.dataScopeOption.value,
                    this.props.currentModuleId ?? remoteModuleId
                ).initialized
            )
                this.props.onChangeValue(
                    Variables(
                        this.props.dataScopeOption.value,
                        this.props.currentModuleId ?? remoteModuleId
                    ).variableOptions,
                    true
                );
        }
    }

    initializeReaction() {
        if (this.props.dataScopeOption != null)
            this.dataVariablesReaction = reaction(
                () =>
                    Variables(
                        this.props.dataScopeOption?.value,
                        this.props.currentModuleId ?? remoteModuleId
                    ).dataVariables,
                () => {
                    if (
                        this.props.variableMode &&
                        this.props.allValues &&
                        this.props.dataScopeOption != null
                    ) {
                        this.props.onChangeValue(
                            Variables(
                                this.props.dataScopeOption.value,
                                this.props.currentModuleId ?? remoteModuleId
                            ).variableOptions,
                            true
                        );
                    }
                }
            );
    }

    componentDidUpdate(prevProps: Props): void {
        if (
            prevProps.dataScopeOption?.value !==
            this.props.dataScopeOption?.value
        ) {
            this.dataVariablesReaction?.();
            this.initializeReaction();
        }
        if (
            this.props.variableMode &&
            this.props.allValues &&
            this.props.dataScopeOption != null &&
            (prevProps.dataScopeOption?.value !==
                this.props.dataScopeOption?.value ||
                prevProps.variableMode !== this.props.variableMode ||
                prevProps.allValues !== this.props.allValues)
        ) {
            if (
                Variables(
                    this.props.dataScopeOption.value,
                    this.props.currentModuleId ?? remoteModuleId
                ).initialized
            )
                this.props.onChangeValue(
                    Variables(
                        this.props.dataScopeOption.value,
                        this.props.currentModuleId ?? remoteModuleId
                    ).variableOptions,
                    true
                );
        }
    }

    handleCloseMenuOnScroll() {
		// forcing select menu re-rendering on scroll
        if (this.state.selectCompIsOpened) {
            this.setState({ top: 0 });
        }
		// as expected do not close the menu on scroll
		return false;
	}

    render() {
        const SelectComp =
            this.props.theme === DropdownStyle.Refine ? SelectStyled1 : Select;
        const dropdownOptionsAligment =
            this.props.node?.dropdownOptionsAligment?.value ?? TextAlign.center;

        let controlOptions: CSSObjectWithLabel = {};
        if (this.props.node.borderColor != null) {
            controlOptions.border = `1px solid ${this.props.node.borderColor}`;
        }
        let fontOptions: CSSObjectWithLabel = {
            textAlign: dropdownOptionsAligment as TextAlign,
        };
        if (this.props.node.fontColor != null) {
            fontOptions.color = this.props.node.fontColor;
        }
        if (this.props.node.fontFamily != null) {
            fontOptions.fontFamily = this.props.node.fontFamily;
        }
        let fontSize = this.props.node.fontSize ?? 10;
        let styles =
            this.props.theme === DropdownStyle.Refine
                ? getCustomSelectStyleRefine(
                      fontSize,
                      true,
                      this.props.node.fillColor ?? undefined,
                      this.props.scale
                  )
                : getCustomSelectStyleLight(
                      fontSize,
                      true,
                      this.props.node.fillColor ?? undefined,
                      this.props.scale
                  );
        return (
            <div
                data-test-id={`${this.props.rootDataTestId}-datasetSelector`}
                className="flex-simple-column"
                style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "10px",
                    width: "100%",
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                {this.props.dataScopeOption != null &&
                this.props.variableOption != null &&
                !this.props.variableMode ? (
                    <SearchComponent
                        conditions={
                            this.props.conditions != null
                                ? _.cloneDeep(this.props.conditions)
                                : undefined
                        }
                        allValues={this.props.allValues}
                        keepColumnOrder={this.props.node.columnOrder}
                        rootDataTestId={this.props.rootDataTestId}
                        allowSelectAllOption={
                            this.props.allowAllValues
                        }
                        isMulti={this.props.allowMultipleSelection &&
                            !this.props.allValues}
                        isDisabled={this.props.frozen}
                        clearable
                        dataScopeId={this.props.dataScopeOption.value}
                        customStyles={{
                            //...customSelectStylesSmall,
                            ...styles,
                            container: (provided, _state) => ({
                                ...provided,
                                marginLeft: 0,
                            }),
                            clearIndicator: (provided, _state) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingRight: 0,
                                transformOrigin: "right center",
                                transform: "scale(0.5)",
                            }),
                            control: (provided, state) => ({
                                ...provided,
                                ...styles.control?.(provided, state),
                                ...controlOptions,
                            }),
                            dropdownIndicator: (provided, _state) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                transformOrigin: "right center",
                                transform: "scale(0.5)",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                            }),
                            singleValue: (provided, state) => ({
                                ...provided,
                                ...styles.singleValue?.(provided, state),
                                ...fontOptions,
                                paddingRight: 2,
                            }),
                            option: (base, state) => ({
                                ...base,
                                ...styles.option?.(base, state),
                                ...fontOptions,
                            }),
                            input: (base, state) => ({
                                ...base,
                                ...styles.input?.(base, state),
                                ...fontOptions,
                            }),
                            multiValueLabel: (provided, state) => ({
                                ...provided,
                                ...styles.multiValueLabel?.(provided, state),
                                ...fontOptions,
                            }),
                            multiValueRemove: (provided, state) => {
                                return {
                                    ...provided,
                                    ...styles.multiValueRemove?.(
                                        provided,
                                        state
                                    ),
                                    ...fontOptions,
                                    color: "#CCCCCC",
                                    "&:hover": {
                                        ...fontOptions,
                                        color: "#AAAAAA",
                                        backgroundColor:
                                            this.props.node.fillColor ??
                                            (styles.multiValueRemove?.(
                                                provided,
                                                state
                                            ).backroundColor as string) ??
                                            "#FFFFFF",
                                    },
                                };
                            },
                        }}
                        initialValue={this.props.value}
                        searchIndex={this.props.variableOption.value}
                        onOptionSelected={(selectedValue, allValues) => {
                            this.props.onChangeValue(
                                selectedValue as SearchComponentOption[],
                                allValues
                            );
                        }}
                        onSelectAllOptions={this.props.onSelectAllValues}
                        menuPortalTarget={document.body}
                        currentModuleId={this.props.currentModuleId}
                        theme={this.props.theme}
                        filterMissing={true}
                    />
                ) : this.props.dataScopeOption != null &&
                  this.props.variableMode ? (
                    <SelectComp
                        isMulti={
                            this.props.allowMultipleSelection &&
                            !this.props.allValues
                        }
                        isClearable
                        isDisabled={this.props.frozen}
                        // filterOption={createFilter({
                        //     ignoreAccents: false,
                        // })}
                        placeholder={""}
                        styles={{
                            //...customSelectStylesSmall,
                            ...styles,
                            container: (provided, _state) => ({
                                ...provided,
                                marginLeft: 0,
                            }),
                            control: (provided, state) => ({
                                ...provided,
                                ...styles.control?.(provided, state),
                                ...controlOptions,
                            }),
                            clearIndicator: (provided, _state) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingRight: 0,
                                transformOrigin: "right center",
                                transform: "scale(0.5)",
                            }),
                            dropdownIndicator: (provided, _state) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                transformOrigin: "right center",
                                transform: "scale(0.5)",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 998,
                            }),
                            singleValue: (provided, state) => ({
                                ...provided,
                                ...styles.singleValue?.(provided, state),
                                ...fontOptions,
                            }),
                            option: (base, state) => ({
                                ...base,
                                ...styles.option?.(base, state),
                                ...fontOptions,
                            }),
                            input: (base, state) => ({
                                ...base,
                                ...styles.input?.(base, state),
                                ...fontOptions,
                            }),
                            multiValueLabel: (provided, state) => ({
                                ...provided,
                                ...styles.multiValueLabel?.(provided, state),
                                ...fontOptions,
                            }),
                            multiValueRemove: (provided, state) => {
                                return {
                                    ...provided,
                                    ...styles.multiValueRemove?.(
                                        provided,
                                        state
                                    ),
                                    ...fontOptions,
                                    color: "#CCCCCC",
                                    "&:hover": {
                                        ...fontOptions,
                                        color: "#AAAAAA",
                                        backgroundColor:
                                            this.props.node.fillColor ??
                                            (styles.multiValueRemove?.(
                                                provided,
                                                state
                                            ).backroundColor as string) ??
                                            "#FFFFFF",
                                    },
                                };
                            },
                        }}
                        options={
                            this.props.allowAllValues &&
                            !this.props.allValues
                                ? selectAllOptions.concat(
                                      Variables(
                                          this.props.dataScopeOption.value,
                                          this.props.currentModuleId ??
                                              remoteModuleId
                                      ).variableOptions
                                  )
                                : Variables(
                                      this.props.dataScopeOption.value,
                                      this.props.currentModuleId ??
                                          remoteModuleId
                                  ).variableOptions
                        }
                        onChange={(newValue) => {
                            if (Array.isArray(newValue)) {
                                if (
                                    newValue.find(
                                        (option) => option.meta?.selectAll
                                    )
                                ) {
                                    this.props.onSelectAllValues();
                                    return;
                                }
                            } else {
                                if ((newValue as any)?.meta?.selectAll) {
                                    this.props.onSelectAllValues();
                                    return;
                                }
                            }
                            this.props.onChangeValue(
                                newValue as SearchComponentOption[]
                            );
                        }}
                        value={
                            this.props.allValues
                                ? { label: "ALL" }
                                : this.props.value
                        }
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25: "#1F8EFA",
                            },
                        })}
                        menuPortalTarget={document.body}
                    />
                ) : (
                    <SelectComp
                        isDisabled={this.props.frozen}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        onMenuOpen={() => {
                            this.setState({
                                selectCompIsOpened: true,
                            });
                        }}
                        onMenuClose={() => {
                            this.setState({
                                selectCompIsOpened: false,
                            });
                        }}
                        placeholder={""}
                        styles={{
                            //...customSelectStylesSmall,
                            ...(this.props.theme === DropdownStyle.Refine
                                ? customSelectStylesRefine
                                : customSelectStylesLight),
                            container: (provided, _state) => ({
                                ...provided,
                                marginLeft: 0,
                            }),
                            control: (provided, state) => ({
                                ...provided,
                                ...styles.control?.(provided, state),
                                ...controlOptions,
                            }),
                            clearIndicator: (provided, _state) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingRight: 0,
                                transformOrigin: "right center",
                                transform: "scale(0.5)",
                            }),
                            dropdownIndicator: (provided, _state) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                transformOrigin: "right center",
                                transform: "scale(0.5)",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 998,
                            }),
                            singleValue: (provided, state) => ({
                                ...provided,
                                ...styles.singleValue?.(provided, state),
                                ...fontOptions,
                            }),
                            option: (base, state) => ({
                                ...base,
                                ...styles.option?.(base, state),
                                ...fontOptions,
                            }),
                            input: (base, state) => ({
                                ...base,
                                ...styles.input?.(base, state),
                                ...fontOptions,
                            }),
                        }}
                        options={[]}
                        onChange={(_newValue) => {}}
                        value={null}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25: "#1F8EFA",
                            },
                        })}
                        menuPortalTarget={document.body}
                        closeMenuOnScroll={this.handleCloseMenuOnScroll.bind(this)}
                    />
                )}
            </div>
        );
    }
}

export default CanvasDropdownSelectorView;
