import React, { Component, CSSProperties } from "react";

import { mainStyle } from "common/MainStyle";

interface Props {
    segmentColor: string;
    style?: CSSProperties;
    maxProgress: number;
    progress: number;
    segmentSpacing?: number;
}

class SegmentedProgressBar extends Component<Props> {
    private renderSegments(): JSX.Element[] {
        const segmentSpacing: number = this.props.segmentSpacing ?? 1;
        let segments: JSX.Element[] = [];
        for(let i=0; i < this.props.maxProgress; ++i) {
            segments.push(
                <div
                    key={i}
                    style={{
                        backgroundColor: i < this.props.progress
                            ? this.props.segmentColor
                            : "transparent",
                        flex: 1,
                        marginLeft: i > 0
                            ? segmentSpacing
                            : 0
                    }}
                />
            );
        }
        return segments;
    }

    public render() {
        const segmentSpacing: number = this.props.segmentSpacing ?? 1;
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 2,
                    backgroundColor: mainStyle.getPropertyValue("--search-items-background-color"),
                    borderRadius: 2,
                    width: 30 * this.props.maxProgress + segmentSpacing * (this.props.maxProgress - 1),
                    height: 20,
                    ...this.props.style
                }}
            >
                {this.renderSegments()}
            </div>
        );
    }
}

export default SegmentedProgressBar;
