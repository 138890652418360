import React, { useState, CSSProperties } from "react";
import { Dropdown } from "react-bootstrap";

import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import CanvasPreventPropagationButton from "../../CanvasPreventPropagationButton";
import { DropdownMenu, DropdownItem } from "../../DropdownMenu";

import styles from "./HamburgerMenu.module.css";

interface Props {
    onToggle: (show: boolean) => void;
    onTouchStart?: () => void;
    sharedPolicy: CanvasSharedPolicy;
    scale: number;
    elementId: string;
    customStyle?: CSSProperties;
    openColorOptions?: () => void;
    onDelete: () => void;
    onEdit: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onHide: () => void;
}

function HamburgerMenu({
    scale,
    onToggle,
    onTouchStart,
    elementId,
    customStyle,
    openColorOptions,
    onDelete,
    onEdit,
    onHide,
}: Props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown
            title="Options"
            style={{
                width: 15 * (scale ?? 1),
                height: 20 * (scale ?? 1),
                backgroundColor: "transparent",
                ...(customStyle ?? {}),
            }}
            show={isOpen}
            onToggle={(isOpen: boolean) => {
                setIsOpen(isOpen);
                onToggle(isOpen);
            }}
            onTouchStart={() => {
                setIsOpen(true);
                onTouchStart?.();
            }}
        >
            <CanvasPreventPropagationButton>
                <Dropdown.Toggle
                    className={styles.hamburger}
                    id={`hamburger-menu-map-${elementId}`}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "transparent",
                        border: "0",
                    }}
                >
                    <span
                        className={styles.hamburger}
                        style={{
                            fontSize: 14 * (scale ?? 1),
                        }}
                    >
                        &#8942;
                    </span>
                </Dropdown.Toggle>
            </CanvasPreventPropagationButton>
            <DropdownMenu noBorder className={styles.dropdownMenu}>
                <CanvasPreventPropagationButton>
                    <DropdownItem
                        highlightColor="#39F"
                        hoverColor="#39F"
                        title="Edit"
                        onClick={onEdit}
                    />
                </CanvasPreventPropagationButton>
                <CanvasPreventPropagationButton>
                    <DropdownItem
                        highlightColor="#39F"
                        hoverColor="#39F"
                        title="Hide"
                        onClick={onHide}
                    />
                </CanvasPreventPropagationButton>
                {openColorOptions && (
                    <CanvasPreventPropagationButton>
                        <DropdownItem
                            highlightColor="#39F"
                            hoverColor="#39F"
                            title="STYLE"
                            onClick={openColorOptions}
                        />
                    </CanvasPreventPropagationButton>
                )}
                <CanvasPreventPropagationButton>
                    <DropdownItem
                        highlightColor="#39F"
                        hoverColor="#39F"
                        title="Delete"
                        onClick={onDelete}
                    />
                </CanvasPreventPropagationButton>
            </DropdownMenu>
        </Dropdown>
    );
}

export default HamburgerMenu;
