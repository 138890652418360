import React from "react";
import WordCloud from "common/graphics/v2/WordCloud";
import Finding from "common/Finding";

function MainComponent(props: {
    finding: Finding;
    onNewFinding?: (finding: Finding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    dataSetMenuIsOpen?: boolean;
    columnDragActive?: boolean;
}): JSX.Element {
    let finding = props.finding;
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <WordCloud
                data={finding.content.data}
                config={finding.config}
                preview={props.preview}
                editable={props.editable}
                columnDragActive={props.columnDragActive}
                dataSetMenuIsOpen={props.dataSetMenuIsOpen}
                onChangeData={
                    props.onNewFinding
                        ? (data: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.content.data = data;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (config: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.config = config;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
            />
        </div>
    );
}

export { MainComponent };
