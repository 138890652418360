import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { KanbanBoardFinding } from "common/Finding";

class Api {
    static getDefaultConfig(journeyName: string): KanbanBoardFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
        };
    }
    static getPreviewFinding(journeyName: string): KanbanBoardFinding {
        let item = {
            type: "kanbanboard",
            content: {
                data: {
                    lanes: [
                        {
                            id: "lane1",
                            title: "Planned Tasks",
                            label: "2/2",
                            cards: [
                                {
                                    id: "Card1",
                                    title: "Write Blog",
                                    description: "Can AI make memes",
                                    label: "30 mins",
                                    draggable: false,
                                },
                                {
                                    id: "Card2",
                                    title: "Pay Rent",
                                    description: "Transfer via NEFT",
                                    label: "5 mins",
                                    metadata: { sha: "be312a1" },
                                },
                            ],
                        },
                        {
                            id: "lane2",
                            title: "Completed",
                            label: "0/0",
                            cards: [],
                        },
                    ],
                    metadata: [],
                },
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }
    static getData(
        finding: KanbanBoardFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<KanbanBoardFinding> {
        let {
            operationVariable,
            dataScope,
            selectedTargetVariablesIndices,
            conditions,
            selectedTable,
            selectedSummaryVariablesIndices,
            categoryVariableIndex,
            titleVariableIndex,
        } = finding.config;

        conditions = filterNonEmptyConditions(conditions ?? []);
        let variableIndices = (selectedTargetVariablesIndices ?? []).filter(
            (item: number | undefined) => item != null
        );
        let summaryIndices = (selectedSummaryVariablesIndices ?? []).filter(
            (item: number | undefined) => item != null
        );

        // if (variableIndices.length === 0 && summaryIndices.length === 0)
        //     return Promise.reject("Empty list of variables for card");

        let requestJson = {
            table: selectedTable.value,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            condition_id: selectedTable.condition_id,
            conditions: conditionsToJson(conditions),
            title_variable_index: titleVariableIndex,
            variable_indices: variableIndices,
            category_variable_index: categoryVariableIndex,
            summary_operation: operationVariable,
            summary_variable_indices: summaryIndices,
            module_id: moduleId,
        };
        return axios
            .post<{
                success: boolean;
                error_msg?: string;
                lanes?: any;
                metadata?: any;
            }>("/api/e/kanban_board", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding = { ...finding };
                    newFinding.content.data = {
                        lanes: response.data.lanes,
                        metadata: response.data.metadata,
                    };
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
