import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
    CanvasBarcodeReader,
    CanvasSubmitButton,
    isSubmitButton,
} from "common/Canvas";
import TabTitle from "common/TabTitle";
import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";
import Data from "./Data";
import styles from "./SubmitButton.module.css";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

interface Props {
    canvasId: number;
    node: CanvasSubmitButton | CanvasBarcodeReader;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    onChange: (changes: Partial<CanvasSubmitButton>, commit?: boolean) => void;
    onClose: () => void;
}

function SubmitButton({
    node,
    canvasId,
    currentModuleId,
    canvasTreeStore,
    onChange,
    onClose,
}: Props) {
    const [selectedTab, setSelectedTab] = useState("data");

    function selectTab(tabKey: string | null) {
        if (tabKey == null) return;
        setSelectedTab(tabKey);
    }

    return (
        <div>
            <div className={styles.editMenuHeader}>
                <h6 className={styles.editMenuHeaderTitle}>Advanced Options</h6>
                <button
                    type="button"
                    onClick={onClose}
                    className={styles.editMenuCloseButton}
                >
                    <CloseIcon />
                </button>
            </div>
            <Tabs
                id={`advanced-menu-${
                    isSubmitButton(node) ? "submit-button" : "barcode-reader"
                }-tabs`}
                activeKey={selectedTab}
                onSelect={selectTab}
                className={styles.tabMenu}
            >
                <Tab
                    eventKey="data"
                    title={
                        <TabTitle
                            title="Data"
                            selected={selectedTab === "data"}
                            className={styles.tabTitle}
                        />
                    }
                    className={styles.tab}
                >
                    <Data
                        canvasTreeStore={canvasTreeStore}
                        canvasId={canvasId}
                        node={node}
                        currentModuleId={currentModuleId}
                        onChange={onChange}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}

export default SubmitButton;
