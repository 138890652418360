import Variables from "common/Variables";
import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

// Line Plot Graph produces the same graphics as Time Patterns Graph
// timeParameter can be a non-time variable, the name is not changed
// for backward compatibility

class Api {
    static getFinding(props) {
        let { data, timeParameter, config, isTimeSeries } = props;
        let item = {
            type: "11",
            content: {
                data: data,
                timeKey: timeParameter,
                isTimeSeries: isTimeSeries,
            },
            config: config,
        };
        return item;
    }
    static getData(config, findingOptions, moduleId) {
        let {
            count,
            selectedXAxisVariable,
            selectedXAxisVariableIndex,
            operationVariable,
            dataScope,
            selectedTargetVariables,
            selectedTargetVariablesIndices,
            conditions,
            selectedTable,
            targetValues,
            additionalParameter,
            additionalParameterIndex,
            leftAdditionalOperator,
            rightAdditionalOperator,
            leftAdditionalValue,
            rightAdditionalValue,
            columnByVariableIndex,
        } = config;

        let variables = Array.from(selectedTargetVariables);
        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        if (targetValues != null) {
            targetValues = targetValues
                .filter((value) => value?.value != null)
                .map((value) => value.value);
        }
        if (targetValues != null) {
            for (let [index, targetValue] of targetValues.entries()) {
                conditions.push({
                    operation: {
                        value: "=",
                    },
                    leftBracket:
                        index === 0
                            ? {
                                  value: "(",
                              }
                            : null,
                    rightBracket:
                        index === targetValues.length - 1
                            ? {
                                  value: ")",
                              }
                            : null,
                    logical: { value: index === 0 ? "and" : "or" },
                    value: { value: targetValue },
                    variable: { value: selectedTargetVariablesIndices[0] },
                });
            }
        }
        conditions = conditionsToJson(conditions);
        let requestJson = {
            variable_indices: selectedTargetVariablesIndices,
            table: selectedTable.value,
            x_axis_index: selectedXAxisVariableIndex,
            group_operation: operationVariable,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            condition_id: selectedTable.condition_id,
            conditions: conditions,
            count: count,
            column_by: columnByVariableIndex,
            module_id: moduleId,
        };
        if (
            additionalParameter &&
            (leftAdditionalValue || rightAdditionalValue)
        ) {
            requestJson.where = {
                group_index: additionalParameterIndex,
                expressions: [],
            };
            if (leftAdditionalValue) {
                requestJson.where.expressions.push({
                    operation: leftAdditionalOperator?.value ?? "=",
                    value: leftAdditionalValue.value,
                });
            }
            if (rightAdditionalValue) {
                requestJson.where.expressions.push({
                    operation: rightAdditionalOperator?.value ?? "=",
                    value: rightAdditionalValue.value,
                });
            }
        }
        return axios
            .post("/api/e/get_line_plot", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = response.data.values;
                    let x_axis_type = response.data.x_axis_type;
                    data.forEach((item) => {
                        item.units =
                            variables.length > 0
                                ? Variables(
                                      requestJson.data_table_idx,
                                      moduleId
                                  ).getUnits(variables[0])
                                : "";
                        if (
                            targetValues != null &&
                            operationVariable === "count"
                        ) {
                            item.curves.forEach((point) => {
                                for (let targetValue of targetValues) {
                                    point[targetValue] =
                                        point[targetValue] ?? 0;
                                }
                            });
                        }
                    });
                    let finding = Api.getFinding({
                        data: data,
                        config: config,
                        timeParameter: selectedXAxisVariable,
                        isTimeSeries: x_axis_type === "datetime",
                    });
                    if (findingOptions?.content?.additionalData != null) {
                        finding.content.additionalData =
                            findingOptions.content.additionalData;
                    }
                    return Promise.resolve(finding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };