import { getLabelByTime, monthNameShort } from "common/MonthData";
import moment from "moment";
import { formatValue } from "common/utilities/FormatValue";
import { GanttChartDateIntervalType } from "common/Canvas";

export function formatDate(
    dateStr: string,
    dateFormat: {
        year: boolean;
        month: boolean;
        day: boolean;
    }
) {
    /**
     * Format the date.
     *
     * @param dateStr Date string like "2019 OCT\n15"
     * @param dateFormat Object like { year: true, month: true, day: false }
     */
    let match = dateStr.match(/^([0-9]+)\s+([a-zA-Z]+)\s+([0-9]+)/);
    if (match == null) return "";
    let newDateStr = "";
    if (dateFormat.year) newDateStr += match[1];
    if (dateFormat.month) newDateStr += ` ${match[2]}`;
    if (dateFormat.day) newDateStr += ` ${match[3]}`;
    return newDateStr;
}

export function toMoment(dateStr: string) {
    /**
     * Convert date string to moment.
     *
     * @param dateStr Date string like "2019 OCT\n15"
     */
    let match = dateStr.match(/^([0-9]+)\s+([a-zA-Z]+)\s+([0-9]+)/);
    if (match == null) return null;
    return moment.utc({
        year: Number(match[1]),
        month: monthNameShort.indexOf(match[2]),
        day: Number(match[3]),
    });
}

export const displayModeToDiffType: {
    [key in
        | "Year Count"
        | "Week Count"
        | "Month Count"
        | "Day Count"]: moment.unitOfTime.Diff;
} = {
    "Year Count": "years",
    "Week Count": "weeks",
    "Month Count": "months",
    "Day Count": "days",
};

export const displayModeToAxisLabel = {
    "Year Count": "YEAR",
    "Month Count": "MONTH",
    "Day Count": "DAY",
    "Week Count": "WEEK",
};

// timeKey can be a non-time variable (for Line Plot Graph)

export function timeLabelFormatter(
    value: number | string,
    isTimeSeries: boolean,
    dateFormat: {
        year: boolean;
        month: boolean;
        day: boolean;
    },
    displayMode: keyof typeof displayModeToDiffType | "Date",
    firstRawDate: number
): string {
    if (isTimeSeries === false) {
        if (typeof value === "number") {
            let formatted = formatValue(value, false);
            return formatted[0] + formatted[1];
        } else {
            return value;
        }
    } else {
        let stringValue = getLabelByTime(value as number, null);
        let stringFirstRawDate = getLabelByTime(firstRawDate, null);
        if (displayMode === "Date") {
            // if the date format is Year,Month,Day, then we don't need to reformat anything
            if (!dateFormat.year || !dateFormat.month || !dateFormat.day) {
                return formatDate(stringValue, dateFormat);
            }
            return stringValue;
        } else {
            moment.locale("en");
            let diffType = displayModeToDiffType[displayMode];
            let firstDate = toMoment(stringFirstRawDate);
            return `${displayModeToAxisLabel[displayMode]} ${toMoment(
                stringValue
            )?.diff(firstDate, diffType)}`;
        }
    }
}

export function getDatesInRange(
    startDate: Date,
    endDate: Date,
    type: GanttChartDateIntervalType = GanttChartDateIntervalType.Week
) {
    const date = new Date(startDate.getTime());

    const dates = [];
    const months: number[] = [];
    const quarters: number[] = [];
    const years: number[] = [];
    const mondayNum = 1; // It's checking for tuesday because of the odd behaviour of dateToUtc function that -1 day

    while (date <= endDate) {
        let condition = false;

        switch(type) {
            case GanttChartDateIntervalType.Day:
                condition = true; break;
            case GanttChartDateIntervalType.Week:
                condition = date.getDay() === mondayNum; break;
            case GanttChartDateIntervalType.Month:
                    const month = new Date(date).getMonth();
                    if (date.getDay() === mondayNum) {
                        condition = !months.includes(month);
                        months.push(month);
                    }
                break;
            case GanttChartDateIntervalType.Quarter:
                    const quarter = Math.floor(date.getMonth() / 3 + 1);
                    if (date.getDay() === mondayNum) {
                        condition = !quarters.includes(quarter);
                        quarters.push(quarter);
                    }
                break;
            case GanttChartDateIntervalType.Year:
                    const year = date.getFullYear();
                    if (date.getDay() === mondayNum) {
                        condition = !years.includes(year);
                        years.push(year);
                    }
                break;
        }

        if (condition) {
            var datum = Date.parse(new Date(date).toLocaleDateString());
            dates.push(datum / 1000);
        }

        date.setDate(date.getDate() + 1);
    }
    
    return dates;
}
