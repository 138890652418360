import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Instrumentation from "common/Instrumentation";
import Alert from "./Alert";
import sections from "sections.json";
import { addEmailApi } from "./EmailApi";

enum UpdateStatus {
    success = 1,
    loading = 2,
    error = 3,
    notUploaded = 4,
}

interface Props {
    emailInfo: {
        email: string;
        verified: boolean;
    };
    onClose: () => void;
    onNewEmail: () => void;
    section?: keyof typeof sections;
}

interface State {
    status: UpdateStatus;
    errorMessage: string;
    newEmail: string;
    verified: boolean;
    emailInfo: {
        email: string;
        verified: boolean;
    };
}

class EmailForm extends Component<Props, State> {
    private performance: Date | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            status: UpdateStatus.notUploaded,
            errorMessage: "",
            emailInfo: { ...props.emailInfo },
            newEmail: String(props.emailInfo?.email || ""),
            verified: props.emailInfo?.verified,
        };

        this.performance = null;
    }

    componentDidUpdate(): void {
        if (this.props.section != null && this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction(this.props.section, timeMs);
        }
    }

    private addEmail(): void {
        let localPerformance: Date = new Date();
        this.setState({
            status: UpdateStatus.loading,
            errorMessage: "",
        });

        addEmailApi(this.state.newEmail)
            .then(() => {
                if (this.props.section != null) {
                    Instrumentation.addInteraction(
                        this.props.section,
                        new Date().getTime() - localPerformance.getTime()
                    );
                }
                this.setState({
                    status: UpdateStatus.success,
                    errorMessage: "",
                });
                this.props.onNewEmail();
            })
            .catch((error) => {
                this.performance = localPerformance;

                this.setState({
                    status: UpdateStatus.error,
                    errorMessage: String(error),
                });
            });
    }

    private buildAlert(): JSX.Element {
        let alert: JSX.Element = <div style={{ height: 72 }} />;
        if (this.state.status === UpdateStatus.success) {
            alert = (
                <Alert
                    text="Successully updated."
                    className="alert alert-success alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.error) {
            alert = (
                <Alert
                    text={"Update error: ".concat(this.state.errorMessage)}
                    className="alert alert-danger alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.loading) {
            alert = (
                <Alert
                    text={"Updating email"}
                    className="alert alert-warning alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        }
        return alert;
    }

    render(): JSX.Element {
        return (
            <div
                className="center-container"
                style={{
                    overflow: "visible",
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    className="flex-simple-column"
                    style={{
                        overflow: "visible",
                        marginTop: 20,
                        height: "100%",
                        width: "90%",
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div className="my-row" style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{marginRight: 10, fontSize: 16}}>Enter email address: </span>
                        <input
                            type={"email"}
                            placeholder={"Email"}
                            className="like-select"
                            style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                            }}
                            onChange={(e) => {
                                let newValue: string = e.target.value;
                                this.setState({
                                    newEmail: newValue,
                                    verified: false,
                                });
                            }}
                            value={this.state.newEmail}
                        />
                        <i
                            title={
                                this.state.verified
                                    ? "Verified"
                                    : "Not Verified"
                            }
                            style={{
                                marginLeft: "5px",
                                alignSelf: "center",
                                color: this.state.verified ? "green" : "black",
                            }}
                            className="fa fa-check-circle"
                        ></i>
                    </div>
                    <div style={{ marginTop: "10px" }}>{this.buildAlert()}</div>
                    <div
                        className="my-row"
                        style={{
                            marginTop: 10,
                            marginBottom: 20,
                            alignSelf: "flex-end",
                            alignItems: "center",
                            flex: 1
                        }}
                    >
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginLeft: 10,
                                width: "112px",
                                fontSize: 14,
                                color: '#3b82c9',
                                border: '1px solid #e0e0e0',
                                backgroundColor: 'white'
                            }}
                            onClick={() => {
                                this.props.onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            disabled={
                                !this.state.newEmail ||
                                this.state.newEmail ===
                                    this.state.emailInfo.email
                            }
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginLeft: 10,
                                width: "112px",
                                backgroundColor: '#3b82c9',
                                fontSize: 14,
                            }}
                            onClick={() => {
                                this.addEmail();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailForm;
