import React, { Component } from "react";
import { observer } from "mobx-react";
import ls from "local-storage";
import Cookies from "universal-cookie";
import "common/styles/App.css";
import CanvasContent from "./CanvasContent";
import CanvasTreeStore, { SPECIAL_TEMPLATE_PAGE } from "./CanvasTreeStore";
import {
    isSubmitButton,
    isBox,
    SharedLinkScrollOption,
    defaultCanvas,
    isSpreadSheetGrid,
} from "common/Canvas";
import StringUtils from "common/utilities/StringUtils";
import mobileBreakpoint, {
    calcScale,
    isLandscape,
    getWidth,
    getHeight,
    tabletBreakpoint,
    mobileAndTabletBreakpoint,
} from "common/utilities/UIResponsiveManager";
import SavePopup, { SaveType } from "./SavePopup";
import LoadPopup from "./LoadPopup";
import ContactUserPopup from "./ContactUserPopup";
import PopupCardsContent from "./PopupCardsContent";
import OutsideAlerter from "common/OutsideAlerter";
import PageType from "common/PageType";
import Instrumentation from "common/Instrumentation";
import PagesStore from "common/PagesStore";
import Canvases from "common/Canvases";
import { SocketIOInstance } from "common/ServerConnection";
import CommandPalette from "./CommandPalette";
import GlobalContext from "GlobalContext";
import {
    getInternalLinkActive,
    goToInternalLink,
} from "common/InternalLinksHelper";
import LeftTopBar from "./LeftTopBar";
import RightTopBar from "./RightTopBar";
import BottomBar from "./BottomBar";
import CurrentModulesStore from "common/CurrentModulesStore";
import { reaction } from "mobx";
import {
    addUserPageToModuleApi,
    addEmptyUserModuleApi,
    addDailyViewUserModuleApi,
} from "common/ModulesApi";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import SectionPortal from "./SectionPortal";
import SharePopup, { ShareType } from "./SharePopup";
import LinkSlidePopup from "./LinkSlidePopup";
import HeaderBarRevamped from "common/HeaderBarRevamped";
import headerStyles from "common/HeaderBarRevamped/HeaderBar.module.css";
import cx from "classnames";
import ModuleTitle from "./ModuleTitle";
import ModuleUserGroupsStore from "common/ModuleUserGroupsStore";
import { UserIcon, GroupIcon } from "common/UserIcon";
import CommentRibbon from "./comment_ui/CommentRibbon";
// Added loading art
import ItemInitializer from "./ItemInitializer";
import { ReactComponent as MessageIcon } from "icons/canvas_header/message.svg";
import PinInformationStore from "./comments/PinInformationStore";
import DropdownCanvasButton from "common/HeaderBarRevamped/Dropdown/DropdownCanvasButton";
import { CSSTransition } from "react-transition-group";
import FadeStyles from "./transitions/FadeTransition.module.css";
// import {loadGoogleMapsApi} from "common/google_api/Geocoder";
import FloatingMessage from "common/FloatingMessage";
import ExportPdfPopup from "./ExportPdfPopup";
import TemplatesPopup, { ThemeType } from "./TemplatesPopup";

import StatusPopup, { PopupStatus } from "common/StatusPopup";

import { ReactComponent as BoardIcon } from "icons/left_nav_menu/board.svg";

import styles from "./main.module.css";
import CurrentUser from "common/CurrentUser";
import {
    addCanvasApi,
    setLastAccessedCanvasApi,
    editNotSavedCanvasThumbnailApi,
} from "common/CanvasUserApi";
import FontLoader from "common/FontLoader";
import DataScopes from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { renderThumbnailOfNotRenderedCanvas } from "./utilities/renderCanvas";
import { isLeadersLaggers } from "common/Finding";

const cookies = new Cookies();
const fadeTimeout = 500;

const MainComponent = observer(
    class MainComponent extends Component {
        constructor(props) {
            super(props);
            this.canvasTreeStore = new CanvasTreeStore();
            const urlParams = new URLSearchParams(window.location.search);
            let currentModuleId = urlParams.get("current_module_id");
            if (currentModuleId != null) {
                currentModuleId = Number(currentModuleId) ?? null;
                ls.set("lastModuleId", currentModuleId);
                this.canvasTreeStore.moduleId = currentModuleId;
            }
            let live = urlParams.get("live");
            if (live != null) {
                this.canvasTreeStore.live = live === "1";
            } else {
                this.canvasTreeStore.live = ls.get("canvasLiveMode", true);
            }

            if (tabletBreakpoint() && !isLandscape()) {
                this.canvasTreeStore.canvasViewMode = "mobile";
            } else if (mobileBreakpoint()) {
                this.canvasTreeStore.live = true;
                this.canvasTreeStore.canvasViewMode = "mobile";
            }

            let hideTop = urlParams.get("hide_top") === "1";
            let hideLinks = urlParams.get("hide_links") === "1";
            this.state = {
                dashboardEditMenuIsOpened: false,
                chartsFilterIdInitializer: undefined,
                headerBarIsOpened: false,
                fadeActive: false,
                finding: null,
                showAsSlideShow: false,
                liveOnly: false,
                bottomPortalType: undefined,
                bottomPortalOptions: undefined,
                currentModuleId: currentModuleId,
                saveOptions: undefined,
                showSharePopup: false,
                sharePopupType: ShareType.Regular,
                themesPopup: {
                    isOpened: false,
                    type: ThemeType.Charts,
                },
                currentDashboardEditId: undefined,
                showExportAsPdfPopup: false,
                pinInitializer: undefined,
                showSheetRibbon: !this.canvasTreeStore.live,
                showDots: ls.get("canvasShowDots", false),
                landscapeOrientation: isLandscape(),
                innerWidth: getWidth(),
                innerHeight: getHeight(),
                gridOptions: {
                    gridRows: 10,
                    gridColumns: 5,
                    spreadsheetRowTitles: false,
                    spreadsheetColumnTitles: true,
                },
                lastLink: undefined,
                captureCanvas: false,
                commandPaletteEnabled: false,
                popusStatus: null,
                popupMessage: null,
                linkSlideCanvas: null,
                hideTop: hideTop,
                hideLinks: hideLinks,
                streamStatus: null,
                streamMessage: null,
                lastPresentationCanvasId: null,
                tablePreviewVisible: true,
            };
            this.updateDimensions = this.updateDimensions.bind(this);
            this.onUnload = this.onUnload.bind(this);
            this.backgroundSelectorRef = React.createRef();
            this.rootSectionRef = React.createRef();
            this.hiddenBackgroundSelectorRef = React.createRef();
            this.canvasContentRef = React.createRef();
            this.rootRef = React.createRef();
            this.loadPopupRef = React.createRef();
            this.shareClick = this.shareClick.bind(this);
            this.loadCanvas = this.loadCanvas.bind(this);
            this.onCanvasClick = this.onCanvasClick.bind(this);
            this.goToCanvas = this.goToCanvas.bind(this);
            this.goToComment = this.goToComment.bind(this);
            this.switchCanvasView = this.switchCanvasView.bind(this);
            this.initialSize = this.initialSize.bind(this);
            this.addBackgroundClick = this.addBackgroundClick.bind(this);
            this.loadCanvasClick = this.loadCanvasClick.bind(this);
            this.captureCanvasClick = this.captureCanvasClick.bind(this);
            this.toggleEditLiveClick = this.toggleEditLiveClick.bind(this);
            this.toggleShowHideDots = this.toggleShowHideDots.bind(this);
            this.toggleShowHideSlideNumbers = this.toggleShowHideSlideNumbers.bind(
                this
            );
            this.deleteBackgroundClick = this.deleteBackgroundClick.bind(this);
            this.saveCanvasClick = this.saveCanvasClick.bind(this);
            this.newCanvasClick = this.newCanvasClick.bind(this);
            this.turnItemInitializer = this.turnItemInitializer.bind(this);
            this.turnPinInitializer = this.turnPinInitializer.bind(this);

            this.expandCard = this.expandCard.bind(this);
            this.expandUserCard = this.expandUserCard.bind(this);
            this.finishCapture = this.finishCapture.bind(this);
            this.fitScale = this.fitScale.bind(this);
            this.changeScale = this.changeScale.bind(this);
            this.closeCapture = this.closeCapture.bind(this);
            this.changeGridOptions = this.changeGridOptions.bind(this);
            this.trackNewPerformance = this.trackNewPerformance.bind(this);
            this.commandPaletteClick = this.commandPaletteClick.bind(this);
            this.renderContents = this.renderContents.bind(this);
            this.performance = null;
            this.performanceElement = "";
            this.mounted = false;
            this.openBottomPortal = this.openBottomPortal.bind(this);
            this.openThemes = this.openThemes.bind(this);
            this.setEditId = this.setEditId.bind(this);
            this.addImageFromCollection = this.addImageFromCollection.bind(
                this
            );
            this.addPreset = this.addPreset.bind(this);

            this.returnToCanvas = this.returnToCanvas.bind(this);
            this.updateCanvasSelectionBounds = this.updateCanvasSelectionBounds.bind(
                this
            );
            this.onClearEditing = this.onClearEditing.bind(this);
            this.onUpdateSelectionBounds = this.onUpdateSelectionBounds.bind(
                this
            );
            this.props.onLiveModeChanged(this.canvasTreeStore.live);
            // loadGoogleMapsApi();
            this.setErrorMessage = this.setErrorMessage.bind(this);
            this.canvasTreeStore.onError = this.setErrorMessage;
            this.exportAsPdf = this.exportAsPdf.bind(this);
            this.scrollToTop = this.scrollToTop.bind(this);
            window.addEventListener(
                "orientationchange",
                (e) => {
                    const orientation = e.target.screen.orientation.angle;
                    if (orientation === 0 || orientation === 180) {
                        // Portrait
                        this.canvasTreeStore.canvasViewMode = "mobile";
                    } else {
                        // Landscape
                        this.canvasTreeStore.canvasViewMode = "desktop";
                    }
                },
                false
            );
        }

        setEditId(dashboardId) {
            this.setState({
                currentDashboardEditId: dashboardId,
            });
        }

        openThemes(isOpened, type, filterId) {
            this.setState({
                themesPopup: {
                    isOpened,
                    type,
                },
            });

            if (type === ThemeType.Charts) {
                this.setState({
                    dashboardEditMenuIsOpened: isOpened,
                    chartsFilterIdInitializer: filterId,
                });
            }
        }

        scrollToTop() {
            if (this.rootSectionRef.current)
                this.rootSectionRef.current.scrollTop = 0;
        }

        selectModule(modules) {
            if (modules.length === 0) {
                addEmptyUserModuleApi()
                    .then((moduleId) => {
                        addUserPageToModuleApi(
                            "UNTITLED",
                            moduleId,
                            cookies.get("instrumentation_session_id")
                        )
                            .then((pageId) => {
                                goToInternalLink(
                                    `canvas.html?current_module_id=${moduleId}`
                                );
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                return;
            }
            let moduleIds = modules.map((module) => module.id);
            let currentModuleId = ls.get("lastModuleId");
            if (!moduleIds.includes(currentModuleId))
                currentModuleId = moduleIds[0];
            let link = `canvas.html?current_module_id=${currentModuleId}`;
            goToInternalLink(link);
            return;
        }
        async initializePage(currentModule) {
            if (currentModule.shared_link?.copied_from_link) {
                let pages = PagesStore(PageType.Canvases).pages;
                let canvasNotInitializedStores = pages
                    .map((page) => Canvases(page.id))
                    .filter((store) => !store.initialized);
                if (canvasNotInitializedStores.length === 0)
                    this.toggleEditLiveClick(
                        currentModule.shared_link.show_as_slideshow,
                        true,
                        false
                    );
                else {
                    this.canvasReactions = {};
                    for (let storeIndex in canvasNotInitializedStores) {
                        let store = canvasNotInitializedStores[storeIndex];
                        let stateReaction = reaction(
                            () => store.canvasesState,
                            () => {
                                stateReaction();
                                this.canvasReactions[storeIndex] = null;
                                let restReactions = Object.values(
                                    this.canvasReactions
                                ).filter((reaction) => reaction != null);
                                if (restReactions.length === 0)
                                    this.toggleEditLiveClick(
                                        currentModule.shared_link
                                            .show_as_slideshow,
                                        true,
                                        false
                                    );
                            }
                        );
                        this.canvasReactions[storeIndex] = stateReaction;
                    }
                }
            } else {
                let page = this.canvasTreeStore.live
                    ? PagesStore(PageType.Canvases).pages[0]
                    : PagesStore(PageType.Canvases).pages.find(
                          (page) => page.last_accessed
                      );
                if (page == null) page = PagesStore(PageType.Canvases).pages[0];
                if (page != null) {
                    await this.canvasTreeStore.restoreFromPageAsyncAction.bothParts(
                        page.id,
                        () => {
                            this.onClearEditing();
                        },
                        this.canvasTreeStore.live
                    );
                    this.fitScale();
                }
            }
        }
        async initializeModuleAndPage() {
            let currentModule = CurrentModulesStore.getModule(
                this.state.currentModuleId
            );
            if (currentModule == null) return;
            if (currentModule.shared_link?.copied_from_link) {
                Instrumentation.addModuleVisit(currentModule.title);
            } else if (currentModule.user_data?.is_owner === false) {
                Instrumentation.addModuleVisit(currentModule.title);
            }
            // Do not use else since it might have changed above
            if (this.state.currentModuleId != null) {
                SocketIOInstance?.emit("module_user_shared_module_join", {
                    room: this.state.currentModuleId,
                });
            }

            const urlParams = new URLSearchParams(window.location.search);
            let canvasId = urlParams.get("canvas_id");
            let pageId = urlParams.get("page_id");
            let pinId = urlParams.get("pin_id");
            if (canvasId != null && pageId != null) {
                await this.loadCanvas({
                    id: Number(canvasId),
                    page_id: Number(pageId),
                });
                if (pinId != null) {
                    pinId = Number(pinId);
                    this.setState(
                        { pinInitializer: { type: ItemInitializer.Pin } },
                        () => {
                            PinInformationStore.setExpandedPinId(pinId);
                        }
                    );
                }
                this.fitScale();
            } else {
                if (PagesStore(PageType.Canvases).initialized) {
                    this.initializePage(currentModule);
                } else {
                    this.currentPagesReaction = reaction(
                        () => PagesStore(PageType.Canvases).pagesState,
                        () => {
                            this.currentPagesReaction();
                            this.initializePage(currentModule);
                        }
                    );
                }
            }
        }
        updateCanvasSelectionBounds() {
            setTimeout(() => {
                if (this.canvasContentRef.current != null) {
                    this.canvasContentRef.current.updateSelectionBounds();
                }
            }, 0);
        }
        componentDidMount() {
            FontLoader.update();
            this.setAppHeight();
            if (this.performance != null) {
                let timeMs = new Date() - this.performance;
                Instrumentation.addInteraction(
                    "Canvas",
                    timeMs,
                    this.performanceElement,
                    PagesStore(PageType.Canvases).module(
                        this.canvasTreeStore
                    ) ?? ""
                );
                this.performance = null;
                this.performanceElement = "";
            }
            addDailyViewUserModuleApi(this.state.currentModuleId).catch(
                (error) => {
                    console.log("Could not add a view", error);
                }
            );
            this.updateDimensions();
            window.addEventListener("resize", this.updateDimensions);
            window.addEventListener("beforeunload", this.onUnload);
            if (this.rootRef.current != null)
                this.rootRef.current.focus({ preventScroll: true });
            if (this.state.currentModuleId == null) {
                if (CurrentModulesStore.initialized)
                    this.selectModule(CurrentModulesStore.modulesState);
                else {
                    this.currentModulesReaction = reaction(
                        () => CurrentModulesStore.modulesState,
                        (modules) => {
                            this.currentModulesReaction();
                            this.selectModule(modules);
                        }
                    );
                }
                return;
            } else {
                if (CurrentModulesStore.initialized) {
                    this.initializeModuleAndPage();
                } else {
                    this.currentModulesReaction = reaction(
                        () => CurrentModulesStore.modulesState,
                        () => {
                            this.currentModulesReaction();
                            this.initializeModuleAndPage();
                        }
                    );
                }
            }
            this.pullSpreadsheetsData();
        }

        pullSpreadsheetsData() {
            for (let grid of this.canvasTreeStore.gridsState.values()) {
                if (
                    isSpreadSheetGrid(grid) &&
                    grid.fullSpreadSheetBackendOutputOptions.dataScopeId != null
                ) {
                    const dataScopes =
                        this.currentModuleId == null
                            ? DataScopes
                            : DataScopesForModules(this.currentModuleId);
                    const dataScopeOption =
                        dataScopes.dataScopesOptions.find(
                            (option) =>
                                option.value ===
                                grid.fullSpreadSheetBackendOutputOptions
                                    ?.dataScopeId
                        ) ?? null;

                    this.canvasTreeStore
                        .readDataIntoSpreadSheet(
                            grid.id,
                            dataScopeOption.value,
                            grid.fullSpreadSheetBackendOutputOptions
                                .tableOption ?? null,
                            grid.fullSpreadSheetBackendOutputOptions.limit ??
                                10,
                            true,
                            false,
                            grid.fullSpreadSheetBackendOutputOptions
                                .conditions ?? [],
                            grid.fullSpreadSheetBackendOutputOptions
                                .variables ?? [],
                            grid.fullSpreadSheetBackendOutputOptions
                                .bottomRows ?? false,
                            undefined,
                            false
                        )
                        .then(() => {
                            this.canvasTreeStore.updateAllAsyncAction.bothParts(
                                false,
                                this.canvasTreeStore.canvasTreeState
                            );
                        });
                }
            }
        }

        addImageFromCollection(url, naturalWidth, naturalHeight) {
            if (this.canvasContentRef.current != null) {
                this.canvasContentRef.current.addImageByUrl(
                    url,
                    naturalWidth,
                    naturalHeight
                );
            }
        }

        addPreset(preset) {
            if (this.canvasContentRef.current != null) {
                this.canvasContentRef.current.addTextByPreset(preset);
            }
        }

        changeGridOptions(gridOptions) {
            this.setState({ gridOptions: gridOptions });
        }
        trackNewPerformance(elementName) {
            this.performance = new Date();
            if (elementName != null) {
                this.performanceElement = elementName;
            }
        }
        setErrorMessage(error) {
            if (this.canvasTreeStore.canvasPageId == null) return;
            this.onClearEditing();
            this.setState({
                popupStatus: PopupStatus.Error,
                popupMessage: error,
            });
        }
        changeScale(scale) {
            this.canvasTreeStore.scale = scale;
            setTimeout(() => {
                if (this.canvasContentRef.current != null) {
                    this.canvasContentRef.current.updateSelectionBounds();
                }
            }, 0);
        }
        captureCanvasClick() {
            this.setState((state) => ({
                captureCanvas: !state.captureCanvas,
            }));
        }
        fitScale() {
            this.canvasTreeStore.calculateFitScale(
                this.canvasTreeStore.live,
                this.state.showSheetRibbon,
                this.state.showAsSlideShow
            );
            setTimeout(() => {
                if (this.canvasTreeStore.current != null) {
                    this.canvasTreeStore.current.updateSelectionBounds();
                }
            }, 0);
        }
        loadCanvasClick() {
            this.setState(
                (state) => ({ showSheetRibbon: !state.showSheetRibbon })
                /*() => {
                     this.fitScale();  // This prevents resizing when clickong on ribbon
                }*/
            );
        }
        allCanvases() {
            let pages = PagesStore(PageType.Canvases).pages;
            let canvases = pages
                .map((page) => Canvases(page.id).canvases)
                .flat();
            return canvases;
        }
        expandCard(node, popup) {
            if (this.state.hideLinks) return;
            if ((isBox(node) || isSubmitButton(node) || isLeadersLaggers(node)) && node.external) {
                if (node.externalLink)
                    window.open(
                        StringUtils.addSchemaToUrl(node.externalLink),
                        "_blank"
                    );
                return;
            }
            let canvases = this.allCanvases();
            if (
                node.links.length === 1 &&
                (node.linkPopups == null || node.linkPopups.length === 0)
            ) {
                let canvas = canvases.find(
                    (canvas) => canvas.id === node.links[0]
                );

                if (canvas != null) {
                    if (
                        isSubmitButton(node) &&
                        (node.sharedLinkScrollOption == null ||
                            node.sharedLinkScrollOption ===
                                SharedLinkScrollOption.ScrollToTop)
                    ) {
                        this.scrollToTop();
                    }
                    this.goToCanvas(canvas);
                }
            } else if (
                node.linkPopups != null &&
                node.linkPopups.length === 1 &&
                node.links.length === 0
            ) {
                let canvas = canvases.find(
                    (canvas) => canvas.id === node.linkPopups[0]
                );
                if (canvas != null) {
                    this.setState({
                        linkSlideCanvas: canvas,
                    });
                }
            } else {
                this.setState({
                    currentNodePopup: node,
                });
            }
            if (popup != null) {
                this.setState({
                    popupStatus: popup.status,
                    popupMessage: popup.message,
                });
            }
        }

        expandUserCard(node) {
            if (this.state.hideLinks) return;
            this.setState({
                currentUserNodePopup: node,
            });
        }
        saveCanvasClick(pageId) {
            this.setState({
                saveOptions: {
                    pageId: pageId,
                    type: SaveType.Save,
                },
            });
        }
        newCanvasClick(pageId) {
            this.setState({
                saveOptions: {
                    pageId: pageId,
                    type: SaveType.New,
                },
            });
        }
        addBackgroundClick() {
            if (this.hiddenBackgroundSelectorRef.current) {
                this.hiddenBackgroundSelectorRef.current.click();
            }
        }
        deleteBackgroundClick() {
            this.performance = new Date();
            this.canvasTreeStore.deleteRootBackgroundAction();
            if (this.backgroundSelectorRef.current) {
                this.backgroundSelectorRef.current.value = "";
            }
            if (this.hiddenBackgroundSelectorRef.current) {
                this.hiddenBackgroundSelectorRef.current.value = "";
            }
            this.onClearEditing();
        }
        finishCapture(imageBlob) {
            this.setState({
                captureCanvas: false,
            });
            let finding = {
                type: "image",
                content: {},
            };
            this.props.onNewFinding(finding, imageBlob, true, true, true);
        }
        closeCapture() {
            this.setState({ captureCanvas: false });
        }
        toggleEditLiveClick(
            showAsSlideShow = false,
            liveOnly = false,
            fromCurrentSlide = false
        ) {
            if (this.state.liveOnly) return;
            if (this.rootSectionRef.current)
                this.rootSectionRef.current.scrollLeft = 0;
            this.setState(
                (state) => ({
                    showAsSlideShow: showAsSlideShow,
                    showSheetRibbon: liveOnly
                        ? false
                        : this.canvasTreeStore.live,
                    pinInitializer: liveOnly
                        ? undefined
                        : this.canvasTreeStore.live
                        ? state.pinInitializer
                        : undefined,
                    captureCanvas: liveOnly
                        ? false
                        : this.canvasTreeStore.live
                        ? state.captureCanvas
                        : false,
                    liveOnly: liveOnly,
                    // Close the node popups
                    // Legacy insight popup
                    currentNodePopup:
                        liveOnly || this.canvasTreeStore.live
                            ? undefined
                            : state.currentNodePopup,
                    // Slide popup
                    linkSlideCanvas:
                        liveOnly || this.canvasTreeStore.live
                            ? null
                            : state.linkSlideCanvas,
                }),
                async () => {
                    this.canvasTreeStore.live = liveOnly
                        ? true
                        : !this.canvasTreeStore.live;
                    ls.set("canvasLiveMode", this.canvasTreeStore.live);
                    this.props.onLiveModeChanged(this.canvasTreeStore.live);
                    if (this.canvasTreeStore.live) {
                        let canvases = this.allCanvases().filter(
                            (canvas) => !canvas.layer_for_canvas_id
                        );
                        let canvas;
                        if (fromCurrentSlide) {
                            canvas = canvases.find(
                                (canvas) =>
                                    canvas.id === this.canvasTreeStore.canvasId
                            );
                        } else if (this.state.showAsSlideShow) {
                            canvas = canvases.find(
                                (canvas) => !canvas.hide_in_slideshow
                            );
                        } else {
                            canvas = canvases[0];
                        }
                        if (canvas != null) {
                            if (this.canvasTreeStore.canvasId !== canvas.id) {
                                this.onClearEditing();
                                this.canvasTreeStore.clearAllFieldsAction();
                                await this.loadCanvas(canvas);
                            }
                        } else {
                            this.onClearEditing();
                            this.canvasTreeStore.clearAllFieldsAction();
                        }
                        if (this.rootRef.current != null)
                            this.rootRef.current.focus({
                                preventScroll: true,
                            });
                    }
                    this.fitScale();
                }
            );
        }

        toggleShowHideDots() {
            this.setState(
                (state) => ({
                    showDots: !state.showDots,
                }),
                () => {
                    ls.set("canvasShowDots", this.state.showDots);
                }
            );
        }

        toggleShowHideSlideNumbers() {
            if (this.state.currentModuleId != null)
                CurrentModulesStore.toggleShowSlideNumber(
                    this.state.currentModuleId,
                    {
                        desktop: {
                            width: this.canvasTreeStore.slideWidth["desktop"],
                            height: this.canvasTreeStore.slideHeight["desktop"],
                        },
                        mobile: {
                            width: this.canvasTreeStore.slideWidth["mobile"],
                            height: this.canvasTreeStore.slideHeight["mobile"],
                        },
                    }
                );
        }

        componentDidUpdate(prevProps) {
            if (this.performance != null) {
                let timeMs = new Date() - this.performance;
                Instrumentation.addInteraction(
                    "Canvas",
                    timeMs,
                    this.performanceElement,
                    PagesStore(PageType.Canvases).module(
                        this.canvasTreeStore.canvasPageId
                    ) ?? ""
                );
                this.performance = null;
                this.performanceElement = "";
            }
        }

        onClearEditing() {
            if (this.canvasContentRef.current != null)
                this.canvasContentRef.current.clearEditing();
        }

        onUpdateSelectionBounds() {
            if (this.canvasContentRef.current != null)
                this.canvasContentRef.current.updateSelectionBounds();
        }

        clearCanva() {
            let dashboard = this.canvasTreeStore.dashboardsState.get(
                this.state.currentDashboardEditId
            );
            if (dashboard && !dashboard.finding) {
                this.canvasTreeStore.deleteByMetadataAction([
                    {
                        groupId: null,
                        id: this.state.currentDashboardEditId,
                        type: "dashboardsState",
                    },
                ]);
                this.onClearEditing();
                if (this.rootRef.current != null)
                    this.rootRef.current.focus({ preventScroll: true });
            }
            this.setState({
                finding: undefined,
                dashboardEditMenuIsOpened: false,
                themesPopup: {
                    isOpened: false,
                    type: ThemeType.Templates,
                },
            });
        }

        switchCanvasView(view) {
            this.canvasTreeStore.canvasViewMode = view;
        }

        async loadCanvas(canvas) {
            this.onClearEditing();
            this.setState({
                themesPopup: {
                    isOpened: false,
                    type: ThemeType.Charts,
                },
                currentDashboardEditId: undefined,
                currentNodePopup: undefined,
                lastPresentationCanvasId: null,
                currentUserNodePopup: undefined,
            });
            if (canvas.page_id !== this.canvasTreeStore.canvasPageId) {
                this.canvasTreeStore.joinPageRoom(
                    canvas.page_id,
                    this.canvasTreeStore.canvasPageId
                );
            }
            this.canvasTreeStore.canvasPageId = canvas.page_id;
            this.canvasTreeStore.isLoadingState = true;

            let currentModule = CurrentModulesStore.getModule(
                this.state.currentModuleId
            );
            let allowCache = currentModule?.shared_link?.copied_from_link;
            const innerLoading = async () => {
                try {
                    let fetchedCanvas = await Canvases(
                        canvas.page_id
                    ).getFullCanvas(canvas.id, allowCache);

                    await this.canvasTreeStore.deserializeAsyncAction.bothParts(
                        fetchedCanvas.canvas,
                        {
                            canvasId: fetchedCanvas.id,
                            backgrounds: fetchedCanvas.backgrounds,
                            delegateId: fetchedCanvas.delegate_id,
                        }
                    );
                    if (this.canvasTreeStore.live) {
                        this.fitScale();
                    }
                    this.onClearEditing();
                    setLastAccessedCanvasApi(fetchedCanvas.id);
                    //updateCanvasAccessTimeApi(canvas.id);
                } catch (error) {
                    console.log(error);
                }
            };
            if (this.state.showAsSlideShow) {
                this.setState({ fadeActive: true }, async () => {
                    setTimeout(async () => {
                        this.setState({ fadeActive: false });
                        await innerLoading();
                    }, fadeTimeout);
                });
            } else {
                await innerLoading();
            }
        }
        onCanvasClick(canvas) {
            if (this.state.hideLinks) return;
            this.setState({
                linkSlideCanvas: canvas,
                currentNodePopup: null,
            });
        }
        goToCanvas(canvas) {
            if (this.state.hideLinks) return;
            let canvasId = this.canvasTreeStore.canvasId;
            let pageId = this.canvasTreeStore.canvasPageId;
            renderThumbnailOfNotRenderedCanvas(
                this.rootRef,
                canvasId,
                pageId,
                this.canvasTreeStore.moduleId
            )
                .then((thumbnail) => {
                    if (pageId != null) {
                        let index = Canvases(pageId).canvasesState.findIndex(
                            (item) => item.id === canvasId
                        );
                        Canvases(pageId).canvasesState[index] = {
                            ...Canvases(pageId).canvasesState[index],
                            thumbnail: thumbnail,
                        };
                    }
                    return editNotSavedCanvasThumbnailApi(
                        canvasId,
                        thumbnail,
                        cookies.get("instrumentation_session_id")
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
            this.loadCanvas(canvas);
        }
        async goToComment(pageId, canvasId, pinId) {
            if (this.canvasTreeStore.canvasId !== canvasId) {
                await this.loadCanvas({ page_id: pageId, id: canvasId });
            }
            PinInformationStore.setExpandedPinId(pinId);
        }
        componentWillUnmount() {
            window.removeEventListener("resize", this.updateDimensions);
            window.removeEventListener("beforeunload", this.onUnload);
            if (this.currentModulesReaction) {
                this.currentModulesReaction();
            }
            if (this.currentPagesReaction) {
                this.currentPagesReaction();
            }
            if (this.canvasReactions) {
                for (let reaction of Object.values(this.canvasReactions)) {
                    reaction?.();
                }
            }
        }

        shareClick(e, sharePopupType) {
            this.setState({
                showSharePopup: true,
                sharePopupType,
            });
        }

        exportAsPdf() {
            this.setState({ showExportAsPdfPopup: true });
        }

        openBottomPortal(portalType, options) {
            this.setState({
                bottomPortalType: portalType,
                bottomPortalOptions: {
                    ...options,
                    canvasTreeStore: this.canvasTreeStore,
                },
            });
        }
        returnToCanvas(options) {
            this.setState({
                bottomPortalType: undefined,
                bottomPortalOptions: undefined,
            });
            if (options?.streamStatus) {
                this.setState({
                    streamStatus: PopupStatus.Error,
                    streamMessage: "Upload cancelled",
                });
            }
        }

        commandPaletteClick(value = undefined) {
            this.setState(
                (state) => ({
                    commandPaletteEnabled:
                        value ?? !state.commandPaletteEnabled,
                }),
                () => {
                    if (!this.state.commandPaletteEnabled) {
                        if (this.rootRef.current != null) {
                            this.rootRef.current.focus({ preventScroll: true });
                        }
                    }
                }
            );
        }

        popupHeight() {
            return this.state.innerHeight * 0.7;
        }
        popupWidth() {
            return this.state.innerWidth * 0.7;
        }

        onUnload(e) {
            let needSave = false; //&& !this.state.live;
            let confirmationMessage = "Leave site?";
            if (needSave && !getInternalLinkActive() && !e.returnValue) {
                e.preventDefault();
                e.returnValue = confirmationMessage;
                return confirmationMessage;
            } else {
                SocketIOInstance?.disconnect(true);
            }
        }

        setAppHeight() {
            let vh = window.innerHeight;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        }

        updateDimensions() {
            calcScale();
            this.setAppHeight();
            let innerWidth = getWidth();
            let innerHeight = getHeight();
            this.setState(
                {
                    landscapeOrientation: isLandscape(),
                    innerWidth: innerWidth,
                    innerHeight: innerHeight,
                },
                () => {
                    this.canvasTreeStore.setInitialSize(this.initialSize());
                    this.fitScale();
                    setTimeout(() => {
                        if (this.canvasContentRef.current != null) {
                            this.canvasContentRef.current.updateSelectionBounds();
                        }
                    }, 0);
                }
            );
        }

        turnItemInitializer(type, options = {}) {
            if (this.canvasContentRef.current != null) {
                return this.canvasContentRef.current.addNewItem({
                    type: type,
                    options: options,
                });
            }

            return;
        }

        turnPinInitializer(type, options = {}) {
            this.setState((state) => {
                if (state.pinInitializer == null)
                    return {
                        pinInitializer: {
                            type: ItemInitializer.Pin,
                        },
                    };
                else return { pinInitializer: undefined };
            });
        }

        async changeSlideShowSlide(sign) {
            let canvases = this.allCanvases().filter(
                (canvas) =>
                    !canvas.hide_in_slideshow && !canvas.layer_for_canvas_id
            );
            let index = canvases.findIndex(
                (canvas) => canvas.id === this.canvasTreeStore.canvasId
            );
            if (index === -1) {
                if (canvases.length > 0) await this.loadCanvas(canvases[0]);
                return;
            }
            index = index + sign;
            if (index >= 0 && index < canvases.length)
                await this.loadCanvas(canvases[index]);
        }

        initialSize() {
            let initialSize = {
                width: this.state.innerWidth,
                height: this.state.innerHeight,
            };
            return initialSize;
        }

        renderContents(globalContext) {
            let contentWrapperStyle = {
                "content-wrapper": true,
            };
            if (this.state.hideTop || this.canvasTreeStore.live) {
                contentWrapperStyle["no-top-padding"] = true;
            }
            let canWrite = true;
            let currentModule = CurrentModulesStore.getModule(
                this.state.currentModuleId
            );
            if (currentModule == null) return null;
            if (!this.mounted) {
                this.performance = new Date();
                this.mounted = true;
            }
            let isLiveStreaming = false;
            if (
                this.canvasTreeStore.canvasPageId != null &&
                this.canvasTreeStore.canvasId != null
            ) {
                isLiveStreaming = true;
                let canvas = Canvases(
                    this.canvasTreeStore.canvasPageId
                ).getCanvas(this.canvasTreeStore.canvasId);
                if (canvas != null) {
                    isLiveStreaming = canvas.live_streaming.exists;
                }
            }

            return (
                <>
                    {((!this.state.showAsSlideShow && !this.state.hideTop) ||
                        (tabletBreakpoint() && this.state.showAsSlideShow)) && (
                        <HeaderBarRevamped
                            routes={this.props.routes}
                            hideNavigationMenu={
                                this.canvasTreeStore.live && !mobileBreakpoint()
                            }
                            hoverMode={
                                this.state.showAsSlideShow ||
                                (this.canvasTreeStore.live &&
                                    !this.state.headerBarIsOpened)
                            }
                            shared={false}
                            hideAdvancedUserMenu={this.canvasTreeStore.live}
                            centerComponent={
                                <>
                                    <div
                                        className={
                                            headerStyles.canvasBarContainer
                                        }
                                    >
                                        <LeftTopBar
                                            onOpenBottomPortal={
                                                this.openBottomPortal
                                            }
                                            onOpenThemes={this.openThemes}
                                            onCommandPaletteClick={
                                                this.commandPaletteClick
                                            }
                                            canvasTreeStore={
                                                this.canvasTreeStore
                                            }
                                            currentModuleId={
                                                this.state.currentModuleId
                                            }
                                            showDots={this.state.showDots}
                                            onShowHideDots={
                                                this.toggleShowHideDots
                                            }
                                            showSlideNumbers={
                                                currentModule.options
                                                    ?.slideNumberOptions?.show
                                            }
                                            onShowHideSlideNumbers={
                                                this.toggleShowHideSlideNumbers
                                            }
                                            captureCanvas={
                                                this.state.captureCanvas
                                            }
                                            commandPaletteEnabled={
                                                this.state.commandPaletteEnabled
                                            }
                                            gridOptions={this.state.gridOptions}
                                            canWrite={canWrite}
                                            live={this.canvasTreeStore.live}
                                            hiddenBackgroundSelectorRef={
                                                this.hiddenBackgroundSelectorRef
                                            }
                                            backgroundSelectorRef={
                                                this.backgroundSelectorRef
                                            }
                                            onDeleteBackgroundClick={
                                                this.deleteBackgroundClick
                                            }
                                            onTurnItemInitializer={
                                                this.turnItemInitializer
                                            }
                                            onChangeGridOptions={
                                                this.changeGridOptions
                                            }
                                            onNewPerformance={
                                                this.trackNewPerformance
                                            }
                                            onCaptureCanvas={
                                                this.captureCanvasClick
                                            }
                                            onExportAsPdf={this.exportAsPdf}
                                            onDataSetsClick={this.dataSetsClick}
                                        />
                                        <div style={{ flex: 0.5 }} />
                                        <ModuleTitle
                                            moduleId={
                                                this.state.currentModuleId
                                            }
                                        />
                                        <div style={{ flex: 0.5 }} />
                                        <div
                                            className="my-row"
                                            style={{
                                                alignItems: "center",
                                                marginRight: "14px",
                                            }}
                                        >
                                            <UserIcon
                                                showBorder={
                                                    ModuleUserGroupsStore(
                                                        this.state
                                                            .currentModuleId
                                                    ).activeUsers.has(
                                                        currentModule.user_data
                                                            ?.user_name
                                                    ) &&
                                                    currentModule.user_data
                                                        ?.user_name !==
                                                        CurrentUser.info
                                                            ?.user_name
                                                }
                                                width={25}
                                                height={25}
                                                fontSize={14}
                                                user={currentModule.user_data}
                                            />

                                            {ModuleUserGroupsStore(
                                                this.state.currentModuleId
                                            ).userGroupsState.map(
                                                (group, index) => (
                                                    <div
                                                        key={index}
                                                        className="my-row"
                                                        style={{
                                                            marginLeft: "14px",
                                                        }}
                                                    >
                                                        {!group.personal && (
                                                            <GroupIcon
                                                                fontSize={14}
                                                                groupName={
                                                                    group.group_name
                                                                }
                                                            />
                                                        )}
                                                        {group.personal &&
                                                            !group.invited && (
                                                                <UserIcon
                                                                    showBorder={
                                                                        ModuleUserGroupsStore(
                                                                            this
                                                                                .state
                                                                                .currentModuleId
                                                                        ).activeUsers.has(
                                                                            group
                                                                                .user_info
                                                                                ?.user_name
                                                                        ) &&
                                                                        group
                                                                            .user_info
                                                                            ?.user_name !==
                                                                            CurrentUser
                                                                                .info
                                                                                ?.user_name
                                                                    }
                                                                    width={25}
                                                                    height={25}
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    user={
                                                                        group.user_info
                                                                    }
                                                                />
                                                            )}
                                                        {group.personal &&
                                                            group.invited && (
                                                                <UserIcon
                                                                    opacity={
                                                                        0.7
                                                                    }
                                                                    width={25}
                                                                    height={25}
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    user={
                                                                        group.user_info
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                )
                                            )}
                                        </div>

                                        <RightTopBar
                                            showShareButton={
                                                currentModule != null &&
                                                (currentModule.shared_link ==
                                                    null ||
                                                    !currentModule.shared_link
                                                        .copied_from_link)
                                            }
                                            forceCanOpenRibbon={false}
                                            show={
                                                this.canvasTreeStore
                                                    .canvasPageId != null &&
                                                !mobileBreakpoint()
                                            }
                                            live={this.canvasTreeStore.live}
                                            scale={this.canvasTreeStore.scale}
                                            ribbonOpened={
                                                this.state.showSheetRibbon
                                            }
                                            onLiveChange={
                                                this.toggleEditLiveClick
                                            }
                                            onOpenRibbonChange={
                                                this.loadCanvasClick
                                            }
                                            onShare={this.shareClick}
                                            onZoomFit={this.fitScale}
                                            onZoomChange={this.changeScale}
                                        />
                                    </div>
                                    {!this.canvasTreeStore.live && (
                                        <DropdownCanvasButton
                                            title="Add pin"
                                            active={
                                                this.state.pinInitializer !=
                                                null
                                            }
                                            id="add-pin"
                                            style={{
                                                padding: 8,
                                                width: 34,
                                                height: 34,
                                            }}
                                            onClick={this.turnPinInitializer}
                                        >
                                            <MessageIcon />
                                        </DropdownCanvasButton>
                                    )}
                                </>
                            }
                        ></HeaderBarRevamped>
                    )}

                    {this.state.currentModuleId != null && (
                        <div
                            className={cx(contentWrapperStyle)}
                            tabIndex={0}
                            ref={this.rootRef}
                            style={{
                                opacity:
                                    this.state.showSharePopup ||
                                    this.state.bottomPortalType != null
                                        ? 0.5
                                        : 1,
                                pointerEvents:
                                    this.state.bottomPortalType != null
                                        ? "none"
                                        : "auto",
                                backgroundColor: this.state.showAsSlideShow
                                    ? "black"
                                    : undefined,
                                marginLeft: "0px",
                                outline: "none",
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            onKeyDown={async (evt) => {
                                if (
                                    evt.key === "P" &&
                                    (evt.ctrlKey || evt.metaKey) &&
                                    evt.shiftKey
                                ) {
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    this.commandPaletteClick(true);
                                }
                                if (evt.key === "Escape") {
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    this.commandPaletteClick(false);
                                    if (this.state.showAsSlideShow) {
                                        this.toggleEditLiveClick(
                                            false,
                                            false,
                                            false
                                        );
                                    }
                                }
                                if (this.state.showAsSlideShow) {
                                    if (
                                        evt.key === "ArrowLeft" ||
                                        evt.key === "ArrowRight"
                                    ) {
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        this.changeSlideShowSlide(
                                            evt.key === "ArrowLeft" ? -1 : 1
                                        );
                                    }
                                }
                            }}
                        >
                            <section
                                id="main-section"
                                ref={this.rootSectionRef}
                                className="content element hide-scroll"
                                style={{
                                    ...this.props.positionStyle,
                                    flexGrow: 1,
                                    width: "100%",
                                    overflow: this.canvasTreeStore.live
                                        ? "hidden auto"
                                        : "auto",
                                    paddingLeft: "0px",
                                    paddingBottom: "0px",
                                    paddingTop: "0px",
                                    paddingRight: "0px",
                                }}
                            >
                                <CSSTransition
                                    in={this.state.fadeActive}
                                    timeout={fadeTimeout}
                                    classNames={{
                                        enterActive:
                                            FadeStyles["fade-enter-active"],
                                        enterDone: FadeStyles["fade-enter"],
                                        exitActive:
                                            FadeStyles["fade-exit-active"],
                                        exitDone: FadeStyles["fade-exit"],
                                    }}
                                >
                                    <>
                                        {!this.canvasTreeStore.live && (
                                            <div
                                                style={{
                                                    padding: 15,
                                                }}
                                            ></div>
                                        )}
                                        {this.canvasTreeStore.canvasPageId !=
                                            null && (
                                            <CanvasContent
                                                setCurrentEditId={
                                                    this.setEditId
                                                }
                                                currentEditId={
                                                    this.state
                                                        .currentDashboardEditId
                                                }
                                                dashboardEditMenuIsOpened={
                                                    this.state
                                                        .dashboardEditMenuIsOpened
                                                }
                                                onDashboardEditMenuIsOpened={(
                                                    state
                                                ) => {
                                                    if (!state) {
                                                        this.clearCanva();
                                                    }
                                                }}
                                                tablePreviewVisible={
                                                    this.state
                                                        .tablePreviewVisible
                                                }
                                                finding={this.state.finding}
                                                onOpenThemes={this.openThemes}
                                                hideHeaderBar={() =>
                                                    this.setState({
                                                        headerBarIsOpened: false,
                                                    })
                                                }
                                                onScrollToTop={this.scrollToTop}
                                                pages={
                                                    PagesStore(
                                                        PageType.Canvases
                                                    ).pages
                                                }
                                                rootSectionRef={
                                                    this.rootSectionRef
                                                }
                                                headerBarIsOpened={
                                                    this.state.headerBarIsOpened
                                                }
                                                canvasTreeStore={
                                                    this.canvasTreeStore
                                                }
                                                showPageBar={
                                                    !this.canvasTreeStore
                                                        .hidePagesBar &&
                                                    !this.canvasTreeStore
                                                        .isLoadingState &&
                                                    (currentModule
                                                        ?.page_bar_info?.show ??
                                                        false) &&
                                                    !(
                                                        currentModule
                                                            ?.page_bar_info
                                                            ?.hiddenOnPage ??
                                                        false
                                                    )
                                                }
                                                pageBarInfo={
                                                    currentModule?.page_bar_info
                                                }
                                                switchCanvasView={
                                                    this.switchCanvasView
                                                }
                                                onShowGuideDots={
                                                    this.toggleShowHideDots
                                                }
                                                onZoomChange={this.changeScale}
                                                onOpenBottomPortal={
                                                    this.openBottomPortal
                                                }
                                                sharedPolicy={
                                                    CanvasSharedPolicy.NotShared
                                                }
                                                showDots={
                                                    this.state.showDots &&
                                                    !this.canvasTreeStore.live
                                                }
                                                showSlideNumbers={
                                                    currentModule.options
                                                        ?.slideNumberOptions
                                                        ?.show
                                                }
                                                hidden={
                                                    (!this.canvasTreeStore
                                                        .live &&
                                                        this.canvasTreeStore
                                                            .isLoadingState) ||
                                                    this.canvasTreeStore
                                                        .canvasId == null
                                                }
                                                ribbonIsOpen={
                                                    this.state
                                                        .showSheetRibbon &&
                                                    !this.canvasTreeStore.live
                                                }
                                                showAsSlideShow={
                                                    this.state.showAsSlideShow
                                                }
                                                isLiveStreaming={
                                                    isLiveStreaming
                                                }
                                                captureCanvas={
                                                    this.state.captureCanvas
                                                }
                                                canWrite={canWrite}
                                                onCloseCapture={
                                                    this.closeCapture
                                                }
                                                onFinishCapture={
                                                    this.finishCapture
                                                }
                                                scale={
                                                    this.canvasTreeStore.scale
                                                }
                                                ref={this.canvasContentRef}
                                                live={this.canvasTreeStore.live}
                                                pinInitializer={
                                                    this.state.pinInitializer
                                                }
                                                selectionIsActive={
                                                    this.state.pinInitializer ==
                                                        null &&
                                                    canWrite &&
                                                    !this.canvasTreeStore.live
                                                }
                                                onExpandCard={this.expandCard}
                                                onExpandUserCard={
                                                    this.expandUserCard
                                                }
                                                moduleId={
                                                    this.state.currentModuleId
                                                }
                                                moduleTitle={
                                                    currentModule.title
                                                }
                                                disableZoomWheel={
                                                    this.state
                                                        .linkSlideCanvas != null
                                                }
                                                themesPopupOpen={
                                                    this.state.themesPopup
                                                        .isOpened
                                                }
                                            />
                                        )}
                                    </>
                                </CSSTransition>
                            </section>
                            <BottomBar
                                live={this.canvasTreeStore.live}
                                time={this.canvasTreeStore.canvasUpdateTime}
                            />
                            {this.state.currentUserNodePopup && (
                                <ContactUserPopup
                                    onReject={() => {
                                        this.setState({
                                            currentUserNodePopup: undefined,
                                        });
                                    }}
                                    left={Math.min(
                                        this.state.currentUserNodePopup.x + 250,
                                        Math.max(this.state.innerWidth - 500, 0)
                                    )}
                                    top={Math.min(
                                        this.state.currentUserNodePopup.y + 200,
                                        Math.max(
                                            this.state.innerHeight - 500,
                                            0
                                        )
                                    )}
                                    userInfo={this.canvasTreeStore.connectedUsersState.get(
                                        this.state.currentUserNodePopup.delegate
                                    )}
                                />
                            )}
                            {this.state.currentNodePopup && (
                                <OutsideAlerter
                                    onReject={() => {
                                        if (!this.state.currentInsight)
                                            this.setState({
                                                currentNodePopup: undefined,
                                            });
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: Math.min(
                                                this.state.currentNodePopup
                                                    .nodePosition[
                                                    this.canvasTreeStore
                                                        .canvasViewMode
                                                ].y,
                                                Math.max(
                                                    this.state.innerHeight -
                                                        500,
                                                    0
                                                )
                                            ),
                                            cursor: "pointer",
                                            left: Math.min(
                                                this.state.currentNodePopup
                                                    .nodePosition[
                                                    this.canvasTreeStore
                                                        .canvasViewMode
                                                ].x,
                                                Math.max(
                                                    this.state.innerWidth - 500,
                                                    0
                                                )
                                            ),
                                            zIndex: 998,
                                            maxWidth:
                                                this.state.innerWidth -
                                                Math.min(
                                                    this.state.currentNodePopup
                                                        .nodePosition[
                                                        this.canvasTreeStore
                                                            .canvasViewMode
                                                    ].x,
                                                    Math.max(
                                                        this.state.innerWidth -
                                                            500,
                                                        0
                                                    )
                                                ),
                                        }}
                                    >
                                        <PopupCardsContent
                                            onLinkClick={this.goToCanvas}
                                            onLinkPopupClick={
                                                this.onCanvasClick
                                            }
                                            links={(
                                                this.state.currentNodePopup
                                                    .links || []
                                            )
                                                .map((link) =>
                                                    this.allCanvases().find(
                                                        (canvas) =>
                                                            canvas.id === link
                                                    )
                                                )
                                                .filter((item) => item != null)}
                                            linkPopups={(
                                                this.state.currentNodePopup
                                                    .linkPopups || []
                                            )
                                                .map((link) =>
                                                    this.allCanvases().find(
                                                        (canvas) =>
                                                            canvas.id === link
                                                    )
                                                )
                                                .filter((item) => item != null)}
                                        />
                                    </div>
                                </OutsideAlerter>
                            )}
                            {this.state.saveOptions && (
                                <SavePopup
                                    canvasTreeStore={this.canvasTreeStore}
                                    onClearEditing={this.onClearEditing}
                                    scale={this.canvasTreeStore.scale}
                                    saveOptions={this.state.saveOptions}
                                    rootRef={
                                        this.canvasContentRef.current?.rootRef
                                    }
                                    onSave={() => {
                                        this.performance = new Date();
                                    }}
                                    onClose={() => {
                                        this.setState({
                                            saveOptions: undefined,
                                        });
                                    }}
                                    loadCanvas={this.loadCanvas}
                                    canWrite={canWrite}
                                />
                            )}
                            {this.state.themesPopup.isOpened && (
                                <TemplatesPopup
                                    rootRef={this.rootRef}
                                    chartsFilterIdInitializer={
                                        this.state.chartsFilterIdInitializer
                                    }
                                    tablePreviewVisible={
                                        this.state.tablePreviewVisible
                                    }
                                    onToggleTablePreview={() => {
                                        this.setState({
                                            tablePreviewVisible: !this.state
                                                .tablePreviewVisible,
                                        });
                                    }}
                                    onFindingSelected={(finding) => {
                                        this.setState({
                                            finding: finding,
                                        });
                                    }}
                                    canvasTreeStore={this.canvasTreeStore}
                                    live={this.canvasTreeStore.live}
                                    themeType={this.state.themesPopup.type}
                                    dashboardId={
                                        this.state.currentDashboardEditId
                                    }
                                    currentPageId={
                                        this.canvasTreeStore.canvasPageId
                                    }
                                    currentCanvasId={
                                        this.canvasTreeStore.canvasId
                                    }
                                    onAddImage={this.addImageFromCollection}
                                    onAddPreset={this.addPreset}
                                    onClearEditing={this.onClearEditing}
                                    onUpdateSelectionBounds={
                                        this.onUpdateSelectionBounds
                                    }
                                    onRestore={(close) => {
                                        if (
                                            this.canvasTreeStore
                                                .canvasPageId ===
                                            SPECIAL_TEMPLATE_PAGE
                                        ) {
                                            if (
                                                this.state
                                                    .lastPresentationCanvasId !=
                                                null
                                            ) {
                                                let canvas =
                                                    this.allCanvases().find(
                                                        (canvas) =>
                                                            canvas.id ===
                                                            this.state
                                                                .lastPresentationCanvasId
                                                    ) ?? this.allCanvases[0];
                                                this.loadCanvas(canvas);
                                            }
                                        }
                                        this.setState({
                                            lastPresentationCanvasId: null,
                                        });
                                        if (close) {
                                            this.clearCanva();
                                        }
                                    }}
                                    onReplaceCanvasTemplate={async (
                                        canvasId,
                                        pageId
                                    ) => {
                                        await this.loadCanvas({
                                            page_id: pageId,
                                            id: canvasId,
                                        });
                                    }}
                                    onEditSlide={async (slide) => {
                                        if (
                                            typeof this.canvasTreeStore
                                                .canvasPageId === "number"
                                        )
                                            this.setState({
                                                lastPresentationCanvasId: this
                                                    .canvasTreeStore.canvasId,
                                            });
                                        this.canvasTreeStore.clearAllFieldsAction();
                                        await this.canvasTreeStore.deserializeAsyncAction.bothParts(
                                            slide.canvas,
                                            {
                                                canvasId: slide.id,
                                                backgrounds: slide.backgrounds,
                                                isTemplate: true,
                                            }
                                        );
                                        this.onClearEditing();
                                    }}
                                    onTurnItemInitializer={
                                        this.turnItemInitializer
                                    }
                                    onNewPerformance={this.trackNewPerformance}
                                    currentModuleId={this.state.currentModuleId}
                                />
                            )}

                            {this.state.showSheetRibbon && (
                                <LoadPopup
                                    ref={this.loadPopupRef}
                                    sectionName={
                                        globalContext.Canvas?.display_name ??
                                        "Canvas"
                                    }
                                    canvasTreeStore={this.canvasTreeStore}
                                    moduleId={this.state.currentModuleId}
                                    onShowPageBarChange={
                                        this.updateCanvasSelectionBounds
                                    }
                                    lastPresentationCanvasId={
                                        this.state.lastPresentationCanvasId
                                    }
                                    onClearEditing={this.onClearEditing}
                                    canWrite={canWrite}
                                    onSaveSlide={this.saveCanvasClick}
                                    onCanvasClick={this.goToCanvas}
                                    onNewCanvas={this.newCanvasClick}
                                    onImport={(
                                        pageId,
                                        foreground,
                                        background
                                    ) => {
                                        this.setState({
                                            saveOptions: {
                                                type: SaveType.Import,
                                                pageId: pageId,
                                                importingCanvas: {
                                                    foreground,
                                                    background,
                                                },
                                            },
                                        });
                                    }}
                                    scale={this.canvasTreeStore.scale}
                                    rootRef={
                                        this.canvasContentRef.current?.rootRef
                                    }
                                />
                            )}
                            {this.state.pinInitializer && (
                                <CommentRibbon onItemClick={this.goToComment} />
                            )}
                            {this.state.popupMessage != null && (
                                <StatusPopup
                                    status={this.state.popupStatus}
                                    message={this.state.popupMessage}
                                    onClose={() => {
                                        this.setState({
                                            popupStatus: null,
                                            popupMessage: null,
                                        });
                                    }}
                                />
                            )}
                            {this.state.showSharePopup && (
                                <SharePopup
                                    rootRef={this.rootRef}
                                    moduleId={this.state.currentModuleId}
                                    sharePopupType={this.state.sharePopupType}
                                    canvasTreeStore={this.canvasTreeStore}
                                    onClose={() => {
                                        this.setState({
                                            showSharePopup: false,
                                        });
                                    }}
                                />
                            )}
                            {this.state.showExportAsPdfPopup && (
                                <ExportPdfPopup
                                    rootRef={this.rootRef}
                                    currentModuleId={this.state.currentModuleId}
                                    onClose={() => {
                                        this.setState({
                                            showExportAsPdfPopup: false,
                                        });
                                    }}
                                />
                            )}
                            {this.state.commandPaletteEnabled && (
                                <CommandPalette
                                    x={368}
                                    y={13}
                                    onClose={this.commandPaletteClick}
                                />
                            )}
                        </div>
                    )}
                    {this.state.bottomPortalType != null && (
                        <SectionPortal
                            options={this.state.bottomPortalOptions}
                            onReturnToCanvas={this.returnToCanvas}
                            portalType={this.state.bottomPortalType}
                        />
                    )}
                    {this.state.linkSlideCanvas != null && (
                        <LinkSlidePopup
                            allowCache={
                                currentModule?.shared_link?.copied_from_link
                            }
                            windowSize={{
                                width: this.state.innerWidth,
                                height: this.state.innerHeight,
                            }}
                            onDblClick={() => {
                                let canvas = this.state.linkSlideCanvas;
                                this.setState({
                                    linkSlideCanvas: null,
                                });
                                this.loadCanvas(canvas);
                            }}
                            onClose={() => {                                
                                this.canvasTreeStore.updateAllAsyncAction.bothParts(
                                    false,
                                    this.canvasTreeStore.canvasTreeState
                                );
                                this.setState({
                                    linkSlideCanvas: null,
                                });
                                this.rootRef.current?.focus({
                                    preventScroll: true,
                                });
                            }}
                            moduleId={this.state.currentModuleId}
                            canvas={this.state.linkSlideCanvas}
                        />
                    )}
                    {!tabletBreakpoint() && (
                        <FloatingMessage
                            show={this.state.showAsSlideShow}
                            timeout={4000}
                            className={styles.slideshowExitHint}
                        >
                            <h4>
                                Press <span>ESC</span> to exit presentation mode
                            </h4>
                        </FloatingMessage>
                    )}
                    {!tabletBreakpoint() && (
                        <FloatingMessage
                            show={this.state.showAsSlideShow}
                            timeout={4000}
                            className={styles.slideshowArrowHint}
                        >
                            <h4>
                                Press <span>&#8594;</span> to move to next slide
                            </h4>
                        </FloatingMessage>
                    )}
                    {this.state.streamStatus != null && (
                        <StatusPopup
                            status={this.state.streamStatus}
                            message={this.state.streamMessage}
                            onClose={() => {
                                this.setState({
                                    streamStatus: null,
                                    streamMessage: null,
                                });
                            }}
                        />
                    )}
                </>
            );
        }

        render() {
            return (
                <GlobalContext.ObserverConsumer>
                    {this.renderContents}
                </GlobalContext.ObserverConsumer>
            );
        }
    }
);

export { MainComponent };
export let route = "/canvas.html",
    section = "Canvas",
    leftPanelItem = {
        section: "Current Preso",
        href: "canvas.html",
        icon: <BoardIcon className={styles.icon} />,
    },
    requirePermission = "Canvas";
