export function formatDate(timestamp: number, prefix: string): string {
	let localTime: Date = new Date();
	let utcTimestamp: number = localTime.getTime();
	let differenceMinutes: number = Math.max(
		0,
		Math.floor((utcTimestamp - timestamp * 1000) / 60000)
	);
	if (differenceMinutes < 0) return `${prefix} ${differenceMinutes}m ago`;
	let differenceHours: number = Math.floor(differenceMinutes / 60);
	if (differenceHours === 0) return `${prefix} ${differenceMinutes}m ago`;
	let differenceDays: number = Math.floor(differenceHours / 24);
	if (differenceDays === 0) return `${prefix} ${differenceHours}h ago`;
	let differenceWeeks: number = Math.floor(differenceDays / 7);
	if (differenceWeeks === 0) return `${prefix} ${differenceDays}d ago`;
	let differenceMonth = Math.floor(differenceDays / 30);
	if (differenceMonth === 0) return `${prefix} ${differenceWeeks} week${differenceWeeks > 1 ? 's' : ''} ago`;
	return `${prefix} ${differenceMonth} month${differenceMonth > 1 ? 's' : ''} ago`;
}
