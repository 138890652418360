import { SocketIOInstance } from "./ServerConnection";
import Canvases from "./Canvases";

interface ConditionalNotification {
    operation: "conditional_notification";
    page_id: number;
    canvas_id: number;
}

type Data = ConditionalNotification;

function isConditionalNotification(
    data: Data
): data is ConditionalNotification {
    return data.operation === "conditional_notification";
}

function userNotifcationReaction({ data }: { data: Data }): void {
    if (isConditionalNotification(data)) {
        Canvases(data.page_id).turnOnNotifications(data.canvas_id);
    }
}

function registerUserNotifications(): void {
    SocketIOInstance?.emit("user_notifications_join");
    SocketIOInstance?.on("user_notification", userNotifcationReaction);
}

function unRegisterUserNotifications(): void {
    SocketIOInstance?.emit("user_notifications_leave");
    SocketIOInstance?.off("user_notification", userNotifcationReaction);
}

export { registerUserNotifications, unRegisterUserNotifications };
