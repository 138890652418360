import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0)">
				<path d="M22 24H2V20H22V24Z" {...props} />
				<path
					d="M13.06 5.19L16.81 8.94L7.75 18H4V14.25L13.06 5.19ZM17.88 7.87L14.13 4.12L15.96 2.29C16.35 1.9 16.98 1.9 17.37 2.29L19.71 4.63C20.1 5.02 20.1 5.65 19.71 6.04L17.88 7.87Z"
					fill="#D8D8D8"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}