import { Auth0Provider } from "@auth0/auth0-react";
import Auth0Wrapper from "Auth0Wrapper";
import auth0Scope from "common/Auth0Scope";


interface Props {
    redirectUri?: string;
    modulePath: string;
    auth0_domain: string;
    auth0_clientId: string;
    auth0_audience: string;
    moduleName?: string;
    moduleProps?: { [key: string]: any };
}

const Auth0ProviderWrapper = (props: Props) => {
    
    return (
        <Auth0Provider
            domain={props.auth0_domain}
            clientId={props.auth0_clientId}
            redirectUri={props.redirectUri ?? window.location.origin}
            audience={props.auth0_audience}
            scope={auth0Scope}
            cacheLocation="memory"
        >
            <Auth0Wrapper {...props} />
        </Auth0Provider>
    );
};

export default Auth0ProviderWrapper;
