import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import styles from "./ExportSection.module.css";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
// import cx from "classnames";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { exportTableToCSVApi } from "common/DataApi";
import { preview } from "../MergeSection";
// import MessagePopup from "common/MessagePopup";
import MessagePopup from "../MessagePopup";
import SelectedOption from "../SelectedOption";
import TabSwitchConfirmationPopup from "common/TabSwitchConfirmationPopup";

function ExportSection(props: { setOperationInProgress: (state: boolean) => void; }) {
    const downloadExportedFile = (text: string, title: string) => {
        const element = document.createElement("a");
        const file = new Blob([text], {
            type: "text/csv;charset=utf-8",
        });
        element.href = URL.createObjectURL(file);
        element.download = `${title}`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const executeExport = async (table: TableOption) => {
        // setExporting(true);
        try {
            let data = await exportTableToCSVApi(table);
            downloadExportedFile(data, title);
            // setExporting(false);
            setStatusPopup({
                status: PopupStatus.Success,
                message: "Success",
            });
            setTimeout(() => {
                setStatusPopup(null)
            }, 3000);
        } catch (error) {
            // setExporting(false);

            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    };

    let [dataset, setDataset] = React.useState<DataScopeOption | null>(null);
    let [table, setTable] = React.useState<TableOption | null>(null);
    let [title, setTitle] = React.useState<string>("");
    // let [exporting, setExporting] = React.useState<boolean>(false);
    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    let [exportPopup, setexportPopup] = useState<boolean>(false)
    let [focusState, setFocusState] = useState<boolean>(false)

    useEffect(() => {
        return () => {
            DataScopes.previewState = { headers: null, body: null }
            setTitle("")
        }
    }, [])

    React.useEffect(() => {
        props.setOperationInProgress((dataset)? true : false);
    }, [dataset])

    return (
        <div className={"mainContainer"} style={{ paddingBlock: 0 }}>
            <div className={styles.datasetsContainer}>
                <div className={styles.textItem}>Source</div>
                <div>
                    <DataScopeAndTableSelectorView
                        dataHubMode
                        dataScopeOption={dataset}
                        tableOption={table}
                        onChange={(dataScopeOption, tableOption) => {
                            setDataset(dataScopeOption);
                            setTable(tableOption);
                            setTitle("")

                            DataScopes.previewState = { headers: null, body: null }

                            SelectedOption.UpdateaskSwitchTab(false);
                            SelectedOption.UpdateSwitchTab(false);
                        }}
                        dataScopePlaceholder={"Select dataset"}
                        tableOptionPlaceholder={"Select table"}
                        needWritePermissions={true}
                        tab="export"
                    ></DataScopeAndTableSelectorView>
                </div>
            </div>

            {
                DataScopes.preview?.headers != null ?
                    <div className={"customPreview"} style={{ minWidth: "100%" }}>
                        {preview(DataScopes.preview?.headers, DataScopes.preview?.body, 1, true)}
                    </div>
                    : <div className={styles.previewContainer} >Preview</div>
            }

            <div className={`pageEndbuttons`} >
                <button
                    className={"acceptBtn"}
                    style={{ paddingBlock: 7 }}

                    type="button"
                    disabled={
                        dataset == null || table == null
                    }
                    onClick={() => { setexportPopup(true); }}
                >
                    Export
                </button>
            </div>

            {
                (SelectedOption.getAskSwitchTab && dataset !== null) &&
                <TabSwitchConfirmationPopup />
            }


            {
                exportPopup && (
                    <MessagePopup
                        title={<p style={{ width: '100%', fontSize: 18, color: "#000000" }}>File Name</p>}
                        acceptButtonTitle="Save"
                        rejactButton='false'
                        message={
                            <input
                                placeholder="File name"
                                type="text"
                                value={title}
                                onChange={(evt) => {
                                    let value = evt.target.value;
                                    setTitle(value);
                                }}
                                onFocus={() => setFocusState(true)}
                                className={focusState ? (`${styles.editInput} ${styles.focusHover}`) : styles.editInput}
                            />
                        }
                        disabled={title === ""}
                        onAccept={() => {
                            executeExport(table!)
                            setexportPopup(false);
                            setTitle("");
                            SelectedOption.UpdateaskSwitchTab(false);
                            SelectedOption.UpdateSwitchTab(true);
                        }}
                        onReject={() => {
                            setexportPopup(false);
                        }}
                    />
                )

            }

            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </div>
    );
}

export default observer(ExportSection);
