import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { observer } from "mobx-react";
// import Switch from "react-switch";

import CanvasTreeStore from "./CanvasTreeStore";
import elements from "common/CanvasElements";
import ItemInitializer, { DataScienceMenuItems } from "./ItemInitializer";
import StringUtils from "common/utilities/StringUtils";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import DropdownMenu from "common/HeaderBarRevamped/Dropdown/DropdownMenu";
import DropdownItem from "common/HeaderBarRevamped/Dropdown/DropdownItem";
import DropdownCanvasButton from "common/HeaderBarRevamped/Dropdown/DropdownCanvasButton";
//import getImageNaturalSize from "common/utilities/getImageNaturalSize";
import dropdownItemStyles from "common/HeaderBarRevamped/Dropdown/DropdownItem/DropdownItem.module.css";
import { Button } from "react-bootstrap";
import { PortalType } from "./SectionPortal";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
// import { ReactComponent as AddFlowChartIcon } from "icons/canvas_header/add_flowchart.svg";
import { ReactComponent as AddInputIcon } from "icons/canvas_header/add_input.svg";
import { ReactComponent as AddTableIcon } from "icons/canvas_header/add_table.svg";
import { ReactComponent as AddDataScienceIcon } from "icons/canvas_header/add_datascience.svg";
import { ReactComponent as MoreIcon } from "icons/canvas_header/dots.svg";
import { ReactComponent as AddTextBoxIcon } from "icons/canvas_header/add_textbox.svg";
import { ReactComponent as PointerIcon } from "icons/canvas_header/pointer.svg";
import { ReactComponent as SearchIcon } from "icons/canvas_header/search.svg";
import { ReactComponent as AddThemes } from "icons/canvas_header/add_themes.svg";
import { ReactComponent as AddShapes } from "icons/canvas_header/add_shapes.svg";
import { ReactComponent as InfoIcon } from "icons/info_icon.svg";

import cx from "classnames";
import { DashboardVersion } from "common/Canvas";
import styles from "./LeftTopBar.module.css";
import { useDrag } from "react-dnd";
import OutsideAlerter from "common/OutsideAlerter";
import ReactTooltip from "react-tooltip";
import { getTextSize } from "common/utilities/MeasureText";
import { ThemeType } from "./TemplatesPopup";
// import { getPreview } from "./utilities/PreviewGenerator";

// interface BackgroundProps {
//     id: string;
//     onStartLoading: () => void;
//     onLoad: (
//         result: string | ArrayBuffer | null,
//         naturalWidth: number,
//         naturalHeight: number
//     ) => void;
//     ref: any; // avoid typescript warning
// }

interface GridOptions {
    gridRows: number;
    gridColumns: number;
    spreadsheetRowTitles: boolean;
    spreadsheetColumnTitles: boolean;
}

interface TopBarProps {
    canvasTreeStore: CanvasTreeStore;
    currentModuleId: number | undefined;
    captureCanvas: boolean;
    itemInitializer?: {
        type: ItemInitializer;
        options: any;
    };
    gridOptions: GridOptions;
    live: boolean;
    canWrite: boolean;
    hiddenBackgroundSelectorRef: any;
    backgroundSelectorRef: any;
    onNewPerformance: (elementName?: string) => void;
    onOpenBottomPortal: (type: PortalType) => void;
    onDeleteBackgroundClick: () => void;
    onCaptureCanvas: () => void;
    onDataSetsClick: () => void;
    onTurnItemInitializer: (
        itemInitializer: ItemInitializer | null,
        options?: any
    ) => void;
    showDots: boolean;
    onShowHideDots: () => void;
    showSlideNumbers: boolean;
    onShowHideSlideNumbers: () => void;
    onExportAsPdf: () => void;
    onChangeGridOptions: (gridOptions: GridOptions) => void;
    onCommandPaletteClick: () => void;
    commandPaletteEnabled: boolean;
    onOpenThemes: (
        isOpened: boolean,
        type: ThemeType | undefined,
        filterId?: number
    ) => void;
}

enum DropdownType {
    Shape = 1,
    Input = 2,
    Tables = 3,
    DataScience = 4,
    More = 5,
    Text = 6,
}

interface State {
    toggledDropdown: DropdownType | undefined;
    showAddSpreadshetRowsColumnsModal: boolean;
    hoveredMenuItem: number | null;
}

interface DragInializerProps {
    itemInitializer: ItemInitializer;
    options?: any;
    onDrop: () => void;
}

const DragInitializer: React.FunctionComponent<DragInializerProps> = (
    props
) => {
    const [, drag] = useDrag({
        item: {
            content: {
                type: props.itemInitializer,
                options: props.options,
            },
        },
        end: (item, monitor) => {
            props.onDrop();
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        type: "item_initializer",
    });

    return (
        <>
            <div ref={drag}>{props.children}</div>
        </>
    );
};

export const LeftTopBar = observer(
    class LeftTopBar extends Component<TopBarProps, State> {
        constructor(props: TopBarProps) {
            super(props);
            this.state = {
                toggledDropdown: undefined,
                showAddSpreadshetRowsColumnsModal: false,
                hoveredMenuItem: null,
            };

            this.renderContents = this.renderContents.bind(this);
            this.hideDropdown = this.hideDropdown.bind(this);
        }
        private buildSpreadSheetSubmenu() {
            return (
                <>
                    <OutsideAlerter
                        onReject={() =>
                            this.setState({
                                showAddSpreadshetRowsColumnsModal: false,
                            })
                        }
                    >
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "160px",
                                padding: "11px 0",
                            }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                            }}
                        >
                            <div className={styles.arrow} style={{ top: 12 }} />
                            <div
                                className={cx(
                                    "my-row",
                                    dropdownItemStyles.left,
                                    styles.spreadsheetOptionHoverEffect
                                )}
                                style={{
                                    padding: "3px 12px",
                                }}
                            >
                                <span>Rows</span>
                                <div
                                    style={{
                                        flexGrow: 1,
                                    }}
                                />
                                <input
                                    min="1"
                                    className="like-select"
                                    style={{
                                        padding: "0 0 0 0",
                                        marginLeft: "5px",
                                        width: "35px",
                                        minHeight: "30px",
                                        height: "20px",
                                        textAlign: "center",
                                    }}
                                    placeholder=""
                                    onBlur={(e) => {
                                        let rows = Number(e.target.value);
                                        this.props.onChangeGridOptions({
                                            ...this.props.gridOptions,
                                            gridRows: rows,
                                        });
                                    }}
                                    defaultValue={
                                        this.props.gridOptions.gridRows
                                    }
                                />
                            </div>
                            <div
                                className={cx(
                                    "my-row",
                                    dropdownItemStyles.left,
                                    styles.spreadsheetOptionHoverEffect
                                )}
                                style={{
                                    padding: "3px 12px",
                                    margin: "1px 0",
                                }}
                            >
                                <span>Columns</span>
                                <div
                                    style={{
                                        flexGrow: 1,
                                    }}
                                />
                                <input
                                    min="1"
                                    className="like-select"
                                    style={{
                                        padding: "0 0 0 0",
                                        marginLeft: "5px",
                                        width: "35px",
                                        minHeight: "30px",
                                        height: "20px",
                                        textAlign: "center",
                                    }}
                                    placeholder=""
                                    onBlur={(e) => {
                                        let cols = Number(e.target.value);
                                        this.props.onChangeGridOptions({
                                            ...this.props.gridOptions,
                                            gridColumns: cols,
                                        });
                                    }}
                                    defaultValue={
                                        this.props.gridOptions.gridColumns
                                    }
                                />
                            </div>
                            <div style={{ padding: "0 7px" }}>
                                <DragInitializer
                                    onDrop={this.hideDropdown}
                                    itemInitializer={
                                        ItemInitializer.SimpleSpreadSheetGrid
                                    }
                                    options={{
                                        gridOptions: this.props.gridOptions,
                                    }}
                                >
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{
                                            width: "100%",
                                            height: 35,
                                            borderRadius: 4,
                                            padding: 0,
                                            marginTop: 5,
                                            alignSelf: "center",
                                        }}
                                        onClick={(
                                            evt: React.MouseEvent<
                                                HTMLButtonElement
                                            >
                                        ) => {
                                            this.props.onNewPerformance(
                                                elements.spreadsheet
                                            );
                                            this.props.onTurnItemInitializer(
                                                ItemInitializer.SimpleSpreadSheetGrid,
                                                {
                                                    gridOptions: this.props
                                                        .gridOptions,
                                                }
                                            );
                                            this.setState({
                                                toggledDropdown: undefined,
                                                showAddSpreadshetRowsColumnsModal: false,
                                            });
                                        }}
                                    >
                                        ADD
                                    </Button>
                                </DragInitializer>
                            </div>
                        </div>
                    </OutsideAlerter>
                </>
            );
        }

        private hideDropdown() {
            this.setState({
                toggledDropdown: undefined,
            });
        }

        private renderContents(
            globalContext: GlobalContextContents
        ): JSX.Element | null {
            if (!this.props.canvasTreeStore.canvasPageId) return null;
            return (
                <div className={styles.container}>
                    {!this.props.live && this.props.canWrite && (
                        <>
                            {/*                            <AddBackgroundLabel
                                ref={this.props.hiddenBackgroundSelectorRef}
                                id="hidden-background-selector"
                                onStartLoading={() => {
                                    this.props.onNewPerformance(
                                        elements.background
                                    );
                                }}
                                onLoad={(
                                    result,
                                    naturalWidth,
                                    naturalHeight
                                ) => {
                                    this.props.onNewPerformance();
                                    CanvasTreeStore().addBackground(
                                        result as string,
                                        true,
                                        naturalWidth,
                                        naturalHeight
                                    );
                                }}
                            />*/}
                            <DropdownCanvasButton
                                title="Select & Move Items"
                                active={
                                    !this.props.captureCanvas &&
                                    this.props.itemInitializer == null &&
                                    !this.props.commandPaletteEnabled &&
                                    this.state.toggledDropdown == null
                                }
                                id="canvas-pointer"
                                style={{
                                    padding: 0,
                                    width: 32,
                                    height: 32,
                                }}
                                onClick={() => {
                                    this.props.onTurnItemInitializer(null);
                                }}
                            >
                                <PointerIcon />
                            </DropdownCanvasButton>
                            {"AddTextBoxes" in globalContext.permissions && (
                                <div className={styles.row}>
                                    <DragInitializer
                                        onDrop={this.hideDropdown}
                                        itemInitializer={ItemInitializer.Tag}
                                    >
                                        <DropdownCanvasButton
                                            title="Add text box     [T]"
                                            active={
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.Tag &&
                                                globalContext.permissions
                                                    .AddTextBoxes
                                                    ?.access_type !== -1
                                            }
                                            id="add-text-box"
                                            style={{
                                                padding: 0,
                                                width: 27,
                                                height: 32,
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                            onClick={() => {
                                                this.props.onTurnItemInitializer(
                                                    ItemInitializer.Tag
                                                );
                                            }}
                                        >
                                            <AddTextBoxIcon />
                                        </DropdownCanvasButton>
                                    </DragInitializer>

                                    <Dropdown
                                        show={
                                            this.state.toggledDropdown ===
                                            DropdownType.Text
                                        }
                                        onToggle={(
                                            show: boolean,
                                            meta: any
                                        ) => {
                                            const dropdownItemClicked = (Array.from(
                                                meta.target.classList
                                            ) as string[]).find(
                                                (className: string) => {
                                                    if (
                                                        className.includes(
                                                            "DropdownItem"
                                                        )
                                                    )
                                                        return true;
                                                    return false;
                                                }
                                            );

                                            if (show) {
                                                this.setState({
                                                    toggledDropdown:
                                                        DropdownType.Text,
                                                });
                                            } else {
                                                if (
                                                    this.state
                                                        .toggledDropdown ===
                                                        DropdownType.Text &&
                                                    !dropdownItemClicked
                                                )
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                            }
                                        }}
                                    >
                                        <DropdownCanvasButton
                                            title="Add text"
                                            opened={
                                                this.state.toggledDropdown ===
                                                DropdownType.Text
                                            }
                                            active={
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.SimpleSpreadSheetGrid ||
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.SpreadSheetGrid
                                            }
                                            id="texts-options"
                                            style={{
                                                width: 20,
                                                height: 32,
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                                paddingLeft: 1,
                                            }}
                                        >
                                            <ChevronIcon
                                                style={{ marginLeft: 2 }}
                                            />
                                        </DropdownCanvasButton>

                                        <DropdownMenu
                                            arrowPlacement={{ left: "20px" }}
                                            className={cx(
                                                styles.dropdownMenu,
                                                styles.dropdownMenuLeftOffset
                                            )}
                                        >
                                            <>
                                                <DropdownItem
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={() => {
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Text
                                                        );
                                                    }}
                                                >
                                                    Presets
                                                </DropdownItem>
                                            </>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            )}

                            <div className={styles.row}>
                                <DropdownCanvasButton
                                    title="Shapes"
                                    id="add-theme"
                                    style={{
                                        padding: 0,
                                        width: 32,
                                        height: 32,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                    }}
                                    onClick={() =>
                                        this.props.onOpenThemes(
                                            true,
                                            ThemeType.Elements
                                        )
                                    }
                                >
                                    <AddShapes />
                                </DropdownCanvasButton>
                                <Dropdown
                                    show={
                                        this.state.toggledDropdown ===
                                        DropdownType.Shape
                                    }
                                    onToggle={(show: boolean, meta: any) => {
                                        const dropdownItemClicked = (Array.from(
                                            meta.target.classList
                                        ) as string[]).find(
                                            (className: string) => {
                                                if (
                                                    className.includes(
                                                        "DropdownItem"
                                                    )
                                                )
                                                    return true;
                                                return false;
                                            }
                                        );

                                        if (show) {
                                            this.setState({
                                                toggledDropdown:
                                                    DropdownType.Shape,
                                            });
                                        } else {
                                            if (
                                                this.state.toggledDropdown ===
                                                    DropdownType.Shape &&
                                                !dropdownItemClicked
                                            )
                                                this.setState({
                                                    toggledDropdown: undefined,
                                                });
                                        }
                                    }}
                                >
                                    <DropdownCanvasButton
                                        title="Add shape"
                                        opened={
                                            this.state.toggledDropdown ===
                                            DropdownType.Shape
                                        }
                                        active={
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.SimpleSpreadSheetGrid ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.SpreadSheetGrid
                                        }
                                        id="shapes-options"
                                        style={{
                                            width: 20,
                                            height: 32,
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            paddingLeft: 1,
                                        }}
                                    >
                                        <ChevronIcon
                                            style={{ marginLeft: 2 }}
                                        />
                                    </DropdownCanvasButton>

                                    <DropdownMenu
                                        arrowPlacement={{ left: "20px" }}
                                        className={cx(
                                            styles.dropdownMenu,
                                            styles.dropdownMenuLeftOffset
                                        )}
                                    >
                                        <>
                                            {[
                                                "Square",
                                                "Parallelogram",
                                                "Circle",
                                                "Line",
                                                "Arrow",
                                            ].map((item) => {
                                                if (
                                                    !(
                                                        `Add${item}Shape` in
                                                        globalContext.permissions
                                                    )
                                                ) {
                                                    return null;
                                                }
                                                return (
                                                    <DragInitializer
                                                        onDrop={
                                                            this.hideDropdown
                                                        }
                                                        itemInitializer={
                                                            ItemInitializer.Shape
                                                        }
                                                        options={{
                                                            subtype: item,
                                                        }}
                                                        key={item}
                                                    >
                                                        <DropdownItem
                                                            leftIcon={null}
                                                            rightIcon={null}
                                                            shortcut={
                                                                item === "Arrow"
                                                                    ? " Shift + L"
                                                                    : item ===
                                                                      "Line"
                                                                    ? "L"
                                                                    : undefined
                                                            }
                                                            premium={
                                                                globalContext
                                                                    .permissions[
                                                                    `Add${item}Shape`
                                                                ]
                                                                    ?.access_type ===
                                                                -1
                                                            }
                                                            onClick={(_evt) => {
                                                                this.props.onNewPerformance(
                                                                    elements[
                                                                        StringUtils.decapitalize(
                                                                            item
                                                                        )
                                                                    ]
                                                                );
                                                                this.props.onTurnItemInitializer(
                                                                    ItemInitializer.Shape,
                                                                    {
                                                                        subtype: item,
                                                                    }
                                                                );
                                                                this.setState({
                                                                    toggledDropdown: undefined,
                                                                });
                                                            }}
                                                        >
                                                            {`${item}`}
                                                        </DropdownItem>
                                                    </DragInitializer>
                                                );
                                            })}
                                        </>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                            {("AddCanvasTables" in globalContext.permissions ||
                                "AddBackendTables" in
                                    globalContext.permissions) && (
                                <>
                                    <div className={styles.row}>
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.SimpleSpreadSheetGrid
                                            }
                                            options={{
                                                viewDataset: true,
                                                gridOptions: this.props
                                                    .gridOptions,
                                            }}
                                        >
                                            <DropdownCanvasButton
                                                title="Add dataset"
                                                active={
                                                    this.props.itemInitializer
                                                        ?.type ===
                                                        ItemInitializer.SimpleSpreadSheetGrid ||
                                                    this.props.itemInitializer
                                                        ?.type ===
                                                        ItemInitializer.SpreadSheetGrid ||
                                                    this.props.itemInitializer
                                                        ?.type ===
                                                        ItemInitializer.BackendTable
                                                }
                                                opened={
                                                    this.state
                                                        .toggledDropdown ===
                                                    DropdownType.Tables
                                                }
                                                id="tables-options"
                                                style={{
                                                    width: 30,
                                                    height: 32,
                                                    paddingRight: 2,
                                                    borderTopRightRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                }}
                                                onClick={() => {
                                                    this.props.onNewPerformance(
                                                        elements.spreadsheet
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.SimpleSpreadSheetGrid,
                                                        {
                                                            viewDataset: true,
                                                            gridOptions: this
                                                                .props
                                                                .gridOptions,
                                                        }
                                                    );
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                                }}
                                            >
                                                <AddInputIcon
                                                    width={32}
                                                    height={32}
                                                />
                                            </DropdownCanvasButton>
                                        </DragInitializer>

                                        <Dropdown
                                            show={
                                                this.state.toggledDropdown ===
                                                DropdownType.Tables
                                            }
                                            onToggle={(
                                                show: boolean,
                                                meta: any
                                            ) => {
                                                const dropdownItemClicked = (Array.from(
                                                    meta.target.classList
                                                ) as string[]).find(
                                                    (className: string) => {
                                                        if (
                                                            className.includes(
                                                                "DropdownItem"
                                                            )
                                                        )
                                                            return true;
                                                        return false;
                                                    }
                                                );

                                                if (show) {
                                                    this.setState({
                                                        toggledDropdown:
                                                            DropdownType.Tables,
                                                    });
                                                } else {
                                                    if (
                                                        this.state
                                                            .toggledDropdown ===
                                                            DropdownType.Tables &&
                                                        !dropdownItemClicked
                                                    )
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                }
                                            }}
                                        >
                                            <DropdownCanvasButton
                                                title="Add dataset"
                                                opened={
                                                    this.state
                                                        .toggledDropdown ===
                                                    DropdownType.Tables
                                                }
                                                active={
                                                    this.props.itemInitializer
                                                        ?.type ===
                                                        ItemInitializer.SimpleSpreadSheetGrid ||
                                                    this.props.itemInitializer
                                                        ?.type ===
                                                        ItemInitializer.SpreadSheetGrid ||
                                                    this.props.itemInitializer
                                                        ?.type ===
                                                        ItemInitializer.BackendTable
                                                }
                                                id="tables-options"
                                                style={{
                                                    width: 20,
                                                    height: 32,
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0,
                                                    paddingLeft: 1,
                                                }}
                                            >
                                                <ChevronIcon
                                                    style={{ marginLeft: 2 }}
                                                />
                                            </DropdownCanvasButton>

                                            <DropdownMenu
                                                arrowPlacement={{
                                                    left: "20px",
                                                }}
                                                className={cx(
                                                    styles.dropdownMenu,
                                                    styles.dropdownMenuLeftOffset
                                                )}
                                            >
                                                <>
                                                    <DropdownItem
                                                        key={
                                                            this.state
                                                                .toggledDropdown ===
                                                            DropdownType.Tables
                                                                ? 1
                                                                : 0
                                                        }
                                                        className={
                                                            styles.spreadsheetDropdownItem
                                                        }
                                                        leftIcon={null}
                                                        rightIcon={
                                                            <ChevronIcon transform="rotate(-90)" />
                                                        }
                                                        submenu={this.buildSpreadSheetSubmenu()}
                                                        onClick={() => {
                                                            this.props.onNewPerformance(
                                                                elements.spreadsheet
                                                            );
                                                            this.props.onTurnItemInitializer(
                                                                ItemInitializer.SimpleSpreadSheetGrid,
                                                                {
                                                                    viewDataset: true,
                                                                    gridOptions: this
                                                                        .props
                                                                        .gridOptions,
                                                                }
                                                            );
                                                            this.setState({
                                                                toggledDropdown: undefined,
                                                            });
                                                        }}
                                                    >
                                                        Spreadsheet
                                                    </DropdownItem>

                                                    {"AddBackendTables" in
                                                        globalContext.permissions && (
                                                        <DragInitializer
                                                            onDrop={
                                                                this
                                                                    .hideDropdown
                                                            }
                                                            itemInitializer={
                                                                ItemInitializer.BackendTable
                                                            }
                                                        >
                                                            <DropdownItem
                                                                leftIcon={null}
                                                                rightIcon={null}
                                                                onClick={() => {
                                                                    this.props.onNewPerformance(
                                                                        elements.backendTable
                                                                    );
                                                                    this.props.onTurnItemInitializer(
                                                                        ItemInitializer.BackendTable
                                                                    );
                                                                    this.setState(
                                                                        {
                                                                            toggledDropdown: undefined,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Dataset Viewer
                                                            </DropdownItem>
                                                        </DragInitializer>
                                                    )}

                                                    <DropdownItem
                                                        leftIcon={null}
                                                        rightIcon={null}
                                                        className={
                                                            styles.spreadsheetDropdownItem
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                this.props
                                                                    .onOpenBottomPortal
                                                            )
                                                                this.props.onOpenBottomPortal(
                                                                    PortalType.ImportData
                                                                );

                                                            this.setState({
                                                                toggledDropdown: undefined,
                                                                showAddSpreadshetRowsColumnsModal: false,
                                                            });
                                                        }}
                                                    >
                                                        Connect Data Sources
                                                    </DropdownItem>

                                                    <DropdownItem
                                                        leftIcon={null}
                                                        rightIcon={null}
                                                        onClick={() => {
                                                            if (
                                                                this.props
                                                                    .onOpenBottomPortal
                                                            )
                                                                this.props.onOpenBottomPortal(
                                                                    PortalType.Marketplace
                                                                );
                                                            this.setState({
                                                                toggledDropdown: undefined,
                                                                showAddSpreadshetRowsColumnsModal: false,
                                                            });
                                                        }}
                                                    >
                                                        Pre-loaded Data
                                                    </DropdownItem>
                                                </>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </>
                            )}
                            {("AddQuestionnaire" in globalContext.permissions ||
                                "AddDashboards" in globalContext.permissions ||
                                "AddDashboardsV2" in
                                    globalContext.permissions ||
                                "AddMergeDataElements" in
                                    globalContext.permissions ||
                                "AddAggregateDataElements" in
                                    globalContext.permissions ||
                                "AddManageDataElements" in
                                    globalContext.permissions ||
                                "AddMaps" in globalContext.permissions ||
                                "AddMapsV2" in globalContext.permissions ||
                                "AddGraphs" in globalContext.permissions ||
                                "AddEmbedUrls" in globalContext.permissions ||
                                "InputData" in globalContext.permissions) && (
                                <div className={styles.row}>
                                    <DropdownCanvasButton
                                        id="data-options"
                                        title="Data Science & Visualization"
                                        style={{
                                            width: 32,
                                            height: 32,
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            paddingRight: 1,
                                        }}
                                        opened={
                                            this.state.toggledDropdown ===
                                            DropdownType.DataScience
                                        }
                                        active={
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.Dashboard ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.AggregateData ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.ManageData ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.MergeData ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.Map ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.Questionnaire ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.EmbedUrl
                                        }
                                        onClick={() => {
                                            this.props.onNewPerformance(
                                                elements.dashboard
                                            );
                                            this.props.onTurnItemInitializer(
                                                ItemInitializer.Dashboard,
                                                {
                                                    version:
                                                        DashboardVersion.Second,
                                                    filterIndexInitializer: -1,
                                                }
                                            );
                                            this.props.onOpenThemes(
                                                true,
                                                ThemeType.Charts
                                            );
                                        }}
                                    >
                                        <AddDataScienceIcon
                                            style={{ marginRight: 2 }}
                                        />
                                    </DropdownCanvasButton>
                                    <Dropdown
                                        show={
                                            this.state.toggledDropdown ===
                                            DropdownType.DataScience
                                        }
                                        onToggle={(show: boolean) => {
                                            if (show) {
                                                this.setState({
                                                    toggledDropdown:
                                                        DropdownType.DataScience,
                                                });
                                            } else {
                                                if (
                                                    this.state
                                                        .toggledDropdown ===
                                                    DropdownType.DataScience
                                                )
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                            }
                                        }}
                                    >
                                        <DropdownCanvasButton
                                            id="data-options"
                                            title="Data Science & Visualization"
                                            style={{
                                                width: 20,
                                                height: 32,
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                                paddingLeft: 1,
                                            }}
                                            opened={
                                                this.state.toggledDropdown ===
                                                DropdownType.DataScience
                                            }
                                            active={
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.Dashboard ||
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.AggregateData ||
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.ManageData ||
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.MergeData ||
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.Map ||
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.Questionnaire ||
                                                this.props.itemInitializer
                                                    ?.type ===
                                                    ItemInitializer.EmbedUrl
                                            }
                                        >
                                            <ChevronIcon />
                                        </DropdownCanvasButton>

                                        <DropdownMenu
                                            arrowPlacement={{ left: "20px" }}
                                            className={cx(
                                                styles.dropdownMenu,
                                                styles.dropdownMenuLeftOffset
                                            )}
                                        >
                                            {globalContext.permissions
                                                .ExplorationDescriptiveAnalysis && (
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .ExplorationDescriptiveAnalysis
                                                            ?.access_type === -1
                                                    }
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.dashboard
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Dashboard,
                                                            {
                                                                version:
                                                                    DashboardVersion.Second,
                                                                filterIndexInitializer: 0,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Charts,
                                                            0
                                                        );
                                                    }}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            hoveredMenuItem:
                                                                DataScienceMenuItems.Basic,
                                                        });
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            hoveredMenuItem: null,
                                                        });
                                                    }}
                                                >
                                                    Basic Charts (Bar, Pie,
                                                    Line)
                                                    <span
                                                        data-tip={
                                                            "Basic Charts."
                                                        }
                                                        data-for={`${DataScienceMenuItems.Basic}-tip`}
                                                        onMouseEnter={() => {
                                                            ReactTooltip.rebuild();
                                                        }}
                                                        className={
                                                            styles.menuItemInfoIcon
                                                        }
                                                        style={{
                                                            visibility:
                                                                this.state
                                                                    .hoveredMenuItem ===
                                                                DataScienceMenuItems.Basic
                                                                    ? "visible"
                                                                    : "hidden",
                                                        }}
                                                    >
                                                        <InfoIcon />
                                                    </span>
                                                </DropdownItem>
                                            )}
                                            {globalContext.permissions
                                                .ExplorationHistoricalDataAnalysis && (
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .ExplorationHistoricalDataAnalysis
                                                            ?.access_type === -1
                                                    }
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.dashboard
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Dashboard,
                                                            {
                                                                version:
                                                                    DashboardVersion.Second,
                                                                filterIndexInitializer: 4,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Charts,
                                                            4
                                                        );
                                                    }}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            hoveredMenuItem:
                                                                DataScienceMenuItems.Leaderboard,
                                                        });
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            hoveredMenuItem: null,
                                                        });
                                                    }}
                                                >
                                                    Leaderboard
                                                    <span
                                                        data-tip={
                                                            "Use this to rank categories, groups, individuals etc."
                                                        }
                                                        data-for={`${DataScienceMenuItems.Leaderboard}-tip`}
                                                        onMouseEnter={() => {
                                                            ReactTooltip.rebuild();
                                                        }}
                                                        className={
                                                            styles.menuItemInfoIcon
                                                        }
                                                        style={{
                                                            visibility:
                                                                this.state
                                                                    .hoveredMenuItem ===
                                                                DataScienceMenuItems.Leaderboard
                                                                    ? "visible"
                                                                    : "hidden",
                                                        }}
                                                    >
                                                        <InfoIcon />
                                                    </span>
                                                </DropdownItem>
                                            )}
                                            {globalContext.permissions
                                                .ExplorationMaps && (
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .ExplorationMaps
                                                            ?.access_type === -1
                                                    }
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.dashboard
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Dashboard,
                                                            {
                                                                version:
                                                                    DashboardVersion.Second,
                                                                filterIndexInitializer: 9,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Charts,
                                                            9
                                                        );
                                                    }}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            hoveredMenuItem:
                                                                DataScienceMenuItems.Map,
                                                        });
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            hoveredMenuItem: null,
                                                        });
                                                    }}
                                                >
                                                    Map
                                                    <span
                                                        data-tip={
                                                            "Visualize your data geographically."
                                                        }
                                                        data-for={`${DataScienceMenuItems.Map}-tip`}
                                                        onMouseEnter={() => {
                                                            ReactTooltip.rebuild();
                                                        }}
                                                        className={
                                                            styles.menuItemInfoIcon
                                                        }
                                                        style={{
                                                            visibility:
                                                                this.state
                                                                    .hoveredMenuItem ===
                                                                DataScienceMenuItems.Map
                                                                    ? "visible"
                                                                    : "hidden",
                                                        }}
                                                    >
                                                        <InfoIcon />
                                                    </span>
                                                </DropdownItem>
                                            )}
                                            {globalContext.permissions
                                                .ExplorationWorkflow && (
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .ExplorationWorkflow
                                                            ?.access_type === -1
                                                    }
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.dashboard
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Dashboard,
                                                            {
                                                                version:
                                                                    DashboardVersion.Second,
                                                                filterIndexInitializer: 3,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Charts,
                                                            3
                                                        );
                                                    }}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            hoveredMenuItem:
                                                                DataScienceMenuItems.Workflow,
                                                        });
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            hoveredMenuItem: null,
                                                        });
                                                    }}
                                                >
                                                    Workflow
                                                    <span
                                                        data-tip={
                                                            "Task Management features."
                                                        }
                                                        data-for={`${DataScienceMenuItems.Workflow}-tip`}
                                                        onMouseEnter={() => {
                                                            ReactTooltip.rebuild();
                                                        }}
                                                        className={
                                                            styles.menuItemInfoIcon
                                                        }
                                                        style={{
                                                            visibility:
                                                                this.state
                                                                    .hoveredMenuItem ===
                                                                DataScienceMenuItems.Workflow
                                                                    ? "visible"
                                                                    : "hidden",
                                                        }}
                                                    >
                                                        <InfoIcon />
                                                    </span>
                                                </DropdownItem>
                                            )}
                                            {globalContext.permissions
                                                .ExplorationText && (
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .ExplorationText
                                                            ?.access_type === -1
                                                    }
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.dashboard
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Dashboard,
                                                            {
                                                                version:
                                                                    DashboardVersion.Second,
                                                                filterIndexInitializer: 2,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Charts,
                                                            2
                                                        );
                                                    }}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            hoveredMenuItem:
                                                                DataScienceMenuItems.TextAnalytics,
                                                        });
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            hoveredMenuItem: null,
                                                        });
                                                    }}
                                                >
                                                    Text Analytics
                                                    <span
                                                        data-tip={
                                                            "Text Analytics."
                                                        }
                                                        data-for={`${DataScienceMenuItems.TextAnalytics}-tip`}
                                                        onMouseEnter={() => {
                                                            ReactTooltip.rebuild();
                                                        }}
                                                        className={
                                                            styles.menuItemInfoIcon
                                                        }
                                                        style={{
                                                            visibility:
                                                                this.state
                                                                    .hoveredMenuItem ===
                                                                DataScienceMenuItems.TextAnalytics
                                                                    ? "visible"
                                                                    : "hidden",
                                                        }}
                                                    >
                                                        <InfoIcon />
                                                    </span>
                                                </DropdownItem>
                                            )}
                                            {globalContext.permissions
                                                .ExplorationPredictiveAnalytics && (
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .ExplorationPredictiveAnalytics
                                                            ?.access_type === -1
                                                    }
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.dashboard
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Dashboard,
                                                            {
                                                                version:
                                                                    DashboardVersion.Second,
                                                                filterIndexInitializer: 1,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Charts,
                                                            1
                                                        );
                                                    }}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            hoveredMenuItem:
                                                                DataScienceMenuItems.MachineLearning,
                                                        });
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            hoveredMenuItem: null,
                                                        });
                                                    }}
                                                >
                                                    Machine Learning
                                                    <span
                                                        data-tip={
                                                            "Machine Learning."
                                                        }
                                                        data-for={`${DataScienceMenuItems.MachineLearning}-tip`}
                                                        onMouseEnter={() => {
                                                            ReactTooltip.rebuild();
                                                        }}
                                                        className={
                                                            styles.menuItemInfoIcon
                                                        }
                                                        style={{
                                                            visibility:
                                                                this.state
                                                                    .hoveredMenuItem ===
                                                                DataScienceMenuItems.MachineLearning
                                                                    ? "visible"
                                                                    : "hidden",
                                                        }}
                                                    >
                                                        <InfoIcon />
                                                    </span>
                                                </DropdownItem>
                                            )}
                                            {globalContext.permissions
                                                .ExplorationNetwork && (
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .ExplorationNetwork
                                                            ?.access_type === -1
                                                    }
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.dashboard
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Dashboard,
                                                            {
                                                                version:
                                                                    DashboardVersion.Second,
                                                                filterIndexInitializer: 8,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Charts,
                                                            8
                                                        );
                                                    }}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            hoveredMenuItem:
                                                                DataScienceMenuItems.NetworkGraph,
                                                        });
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            hoveredMenuItem: null,
                                                        });
                                                    }}
                                                >
                                                    Network Graph
                                                    <span
                                                        data-tip={
                                                            "Network Graph."
                                                        }
                                                        data-for={`${DataScienceMenuItems.NetworkGraph}-tip`}
                                                        onMouseEnter={() => {
                                                            ReactTooltip.rebuild();
                                                        }}
                                                        className={
                                                            styles.menuItemInfoIcon
                                                        }
                                                        style={{
                                                            visibility:
                                                                this.state
                                                                    .hoveredMenuItem ===
                                                                DataScienceMenuItems.NetworkGraph
                                                                    ? "visible"
                                                                    : "hidden",
                                                        }}
                                                    >
                                                        <InfoIcon />
                                                    </span>
                                                </DropdownItem>
                                            )}
                                            <DropdownItem premium>
                                                Recommendation Engine (Coming
                                                soon)
                                            </DropdownItem>
                                            <DropdownItem premium>
                                                Deep Learning (Coming soon)
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            )}
                            {("AddDataInputs" in globalContext.permissions ||
                                "AddNumericInputs" in
                                    globalContext.permissions ||
                                "AddSliderInputs" in
                                    globalContext.permissions ||
                                "AddToggleInputs" in
                                    globalContext.permissions ||
                                "AddDropdownInputs" in
                                    globalContext.permissions) && (
                                <Dropdown
                                    show={
                                        this.state.toggledDropdown ===
                                        DropdownType.Input
                                    }
                                    onToggle={(show: boolean) => {
                                        if (show) {
                                            this.setState({
                                                toggledDropdown:
                                                    DropdownType.Input,
                                            });
                                        } else {
                                            if (
                                                this.state.toggledDropdown ===
                                                DropdownType.Input
                                            )
                                                this.setState({
                                                    toggledDropdown: undefined,
                                                });
                                        }
                                    }}
                                >
                                    <DropdownCanvasButton
                                        title="Add Interactions"
                                        opened={
                                            this.state.toggledDropdown ===
                                            DropdownType.Input
                                        }
                                        active={
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.Input ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.DataInputButton ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.SubmitButton ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.Slider ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.Toggle ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.DropdownSelector ||
                                            this.props.itemInitializer?.type ===
                                                ItemInitializer.Filter
                                        }
                                        id="inputs-options"
                                        style={{
                                            width: 47,
                                            height: 32,
                                        }}
                                    >
                                        <AddTableIcon
                                            style={{ marginRight: 2 }}
                                        />
                                        <ChevronIcon />
                                    </DropdownCanvasButton>

                                    <DropdownMenu
                                        arrowPlacement={{ left: "20px" }}
                                        className={styles.dropdownMenu}
                                    >
                                        {"AddFlowcharts" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.FlowChart
                                                }
                                            >
                                                <DropdownItem
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddFlowcharts
                                                            ?.access_type === -1
                                                    }
                                                    shortcut="F"
                                                    onClick={(_evt) => {
                                                        this.props.onNewPerformance(
                                                            elements.flowchart
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.FlowChart
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Flowchart
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddNumericInputs" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.Input
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddNumericInputs
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    shortcut="I"
                                                    onClick={(_evt) => {
                                                        this.props.onNewPerformance(
                                                            elements.numericInput
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Input
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Input Text Field
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddSubmitButtons" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.SubmitButton
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddSubmitButtons
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={(_evt) => {
                                                        this.props.onNewPerformance(
                                                            elements.submitButton
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.SubmitButton
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Button
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddSliderInputs" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.Slider
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddSliderInputs
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={(_evt) => {
                                                        this.props.onNewPerformance(
                                                            elements.sliderInput
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Slider
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Slider
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddDropdownInputs" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.DropdownSelector
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddDropdownInputs
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={(_evt) => {
                                                        this.props.onNewPerformance(
                                                            elements.dropdownInput
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.DropdownSelector
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Dropdown Selector
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddToggleInputs" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.Toggle
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddToggleInputs
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={(_evt) => {
                                                        this.props.onNewPerformance(
                                                            elements.toggleInput
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Toggle
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    On/Off Toggle
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.RadioButtonsGroup
                                            }
                                        >
                                            <DropdownItem
                                                premium={
                                                    globalContext.permissions
                                                        .AddToggleInputs
                                                        ?.access_type === -1
                                                } // TODO
                                                leftIcon={null}
                                                rightIcon={null}
                                                onClick={(_evt) => {
                                                    this.props.onNewPerformance(
                                                        elements.radioButton
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.RadioButtonsGroup
                                                    );
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                                }}
                                            >
                                                Radio button
                                            </DropdownItem>
                                        </DragInitializer>
                                        {"AddProgressElements" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.ProgressElement
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddProgressElements
                                                            ?.access_type === -1
                                                    }
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.progressElement
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.ProgressElement
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Progress Element
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddEmbedUrls" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.EmbedUrl
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddEmbedUrls
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.embedUrl
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.EmbedUrl
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Embedded Video
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddEmbedUrls" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.EmbedUrl
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddEmbedUrls
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.embedUrl
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.EmbedUrl,
                                                            {
                                                                iframe: true,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Embedded URL
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddQuestionnaire" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.Questionnaire
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddQuestionnaire
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.questionnaire
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Questionnaire
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Questionnaire
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddDropdownInputs" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.Filter
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddDropdownInputs
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={(_evt) => {
                                                        this.props.onNewPerformance(
                                                            elements.filter
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Filter
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Filter
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddDataInputs" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.DataInputButton
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddDropdownInputs
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={(_evt) => {
                                                        this.props.onNewPerformance(
                                                            elements.dataInputButton
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.DataInputButton
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Data Input Button
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddBarcodeReaderInputs" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.BarcodeReader
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddBarcodeReaderInputs
                                                            ?.access_type === -1
                                                    }
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.barcodeReader
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.BarcodeReader
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Barcode Reader
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddSurveyInputs" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.Survey
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddSurveyInputs
                                                            ?.access_type === -1
                                                    }
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.survey
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.Survey
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Survey
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                        {"AddEmbedUrls" in
                                            globalContext.permissions && (
                                            <DragInitializer
                                                onDrop={this.hideDropdown}
                                                itemInitializer={
                                                    ItemInitializer.EmbedUrl
                                                }
                                            >
                                                <DropdownItem
                                                    premium={
                                                        globalContext
                                                            .permissions
                                                            .AddEmbedUrls
                                                            ?.access_type === -1
                                                    }
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    onClick={() => {
                                                        this.props.onNewPerformance(
                                                            elements.embedUrl
                                                        );
                                                        this.props.onTurnItemInitializer(
                                                            ItemInitializer.EmbedUrl,
                                                            {
                                                                iframe: true,
                                                            }
                                                        );
                                                        this.setState({
                                                            toggledDropdown: undefined,
                                                        });
                                                    }}
                                                >
                                                    Add Image By URL
                                                </DropdownItem>
                                            </DragInitializer>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            )}

                            <DropdownCanvasButton
                                title="Templates"
                                id="add-theme"
                                style={{
                                    padding: 0,
                                    width: 32,
                                    height: 32,
                                }}
                                className="left-top-bar-button"
                                onClick={() => {
                                    this.props.onOpenThemes(
                                        true,
                                        ThemeType.Templates
                                    );
                                }}
                            >
                                <AddThemes />
                            </DropdownCanvasButton>

                            <Dropdown
                                onToggle={(show: boolean) => {
                                    if (show) {
                                        this.setState({
                                            toggledDropdown: DropdownType.More,
                                        });
                                    } else {
                                        if (
                                            this.state.toggledDropdown ===
                                            DropdownType.More
                                        )
                                            this.setState({
                                                toggledDropdown: undefined,
                                            });
                                    }
                                }}
                            >
                                <DropdownCanvasButton
                                    title="More"
                                    opened={
                                        this.state.toggledDropdown ===
                                        DropdownType.More
                                    }
                                    id="background-options-toggle"
                                    style={{
                                        padding: 0,
                                        width: 32,
                                        height: 32,
                                    }}
                                >
                                    <MoreIcon />
                                </DropdownCanvasButton>
                                <DropdownMenu
                                    arrowPlacement={{ left: "6px" }}
                                    className={styles.dropdownMenu}
                                >
                                    {/*CurrentModulesStore.getModule(
                                        this.props.currentModuleId
                                    )?.user_data?.is_owner && (
                                        <DropdownItem
                                            highlightColor="#39F"
                                            hoverColor="#39F"
                                            title="SHARE DATA SETS"
                                            onClick={this.props.onDataSetsClick}
                                        />
                                    )*/}
                                    {/*"AddBackgrounds" in
                                        globalContext.permissions && (
                                        <AddBackgroundLabel
                                            ref={
                                                this.props.backgroundSelectorRef
                                            }
                                            id="background-selector"
                                            onStartLoading={() => {
                                                this.props.onNewPerformance(
                                                    elements.background
                                                );
                                            }}
                                            onLoad={(
                                                result,
                                                naturalWidth,
                                                naturalHeight
                                            ) => {
                                                this.props.onNewPerformance();
                                                CanvasTreeStore().addBackground(
                                                    result as string,
                                                    true,
                                                    naturalWidth,
                                                    naturalHeight
                                                );
                                            }}
                                        >
                                            <div
                                                className={
                                                    dropdownItemStyles.left
                                                }
                                                style={{ cursor: "pointer" }}
                                            >
                                                Add background
                                            </div>
                                        </AddBackgroundLabel>
                                    )*/}
                                    {/*"DeleteBackgrounds" in
                                        globalContext.permissions && (
                                        <DropdownItem
                                            leftIcon={null}
                                            rightIcon={null}
                                            onClick={
                                                this.props
                                                    .onDeleteBackgroundClick
                                            }
                                        >
                                            Delete background
                                        </DropdownItem>
                                    )*/}
                                    {/*<DropdownItem
                                        leftIcon={null}
                                        rightIcon={null}
                                        // highlightColor={"#39F"}
                                        // highlight={this.props.captureCanvas}
                                        // hoverColor={"#39F"}
                                        // title={"CAPTURE SLIDE"}
                                        onClick={this.props.onCaptureCanvas}
                                    >
                                        Capture slide
                                    </DropdownItem>*/}
                                    {/* {"InputData" in
                                        globalContext.permissions && (
                                        <DropdownItem
                                            leftIcon={null}
                                            rightIcon={null}
                                            onClick={() => {
                                                this.props.onOpenBottomPortal(
                                                    PortalType.ImportData
                                                );
                                                this.setState({
                                                    toggledDropdown:
                                                        undefined,
                                                });
                                            }}
                                        >
                                            Input Data
                                        </DropdownItem>
                                    )} */}

                                    {"AddManageDataElements" in
                                        globalContext.permissions && (
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.ManageData
                                            }
                                        >
                                            <DropdownItem
                                                leftIcon={null}
                                                rightIcon={null}
                                                onClick={() => {
                                                    this.props.onNewPerformance(
                                                        elements.manageTablesDataProcessing
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.ManageData
                                                    );
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                                }}
                                            >
                                                Manage Data
                                            </DropdownItem>
                                        </DragInitializer>
                                    )}

                                    {"AddMergeDataElements" in
                                        globalContext.permissions && (
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.MergeData
                                            }
                                        >
                                            <DropdownItem
                                                leftIcon={null}
                                                rightIcon={null}
                                                onClick={() => {
                                                    this.props.onNewPerformance(
                                                        elements.mergeDataProcessing
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.MergeData
                                                    );
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                                }}
                                            >
                                                Merge data
                                            </DropdownItem>
                                        </DragInitializer>
                                    )}
                                    {"AddAggregateDataElements" in
                                        globalContext.permissions && (
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.AggregateData
                                            }
                                        >
                                            <DropdownItem
                                                leftIcon={null}
                                                rightIcon={null}
                                                onClick={() => {
                                                    this.props.onNewPerformance(
                                                        elements.aggregateDataProcessing
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.AggregateData
                                                    );
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                                }}
                                            >
                                                Aggregate Data
                                            </DropdownItem>
                                        </DragInitializer>
                                    )}
                                    {"AddDashboards" in
                                        globalContext.permissions && (
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.Dashboard
                                            }
                                        >
                                            <DropdownItem
                                                leftIcon={null}
                                                rightIcon={null}
                                                onClick={() => {
                                                    this.props.onNewPerformance(
                                                        elements.dashboard
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.Dashboard
                                                    );
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                                }}
                                            >
                                                Chart
                                            </DropdownItem>
                                        </DragInitializer>
                                    )}
                                    {/* {"AddDashboardsV2" in
                                        globalContext.permissions && (
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.Dashboard
                                            }
                                            options={{
                                                version:
                                                    DashboardVersion.Second,
                                            }}
                                        >
                                            <DropdownItem
                                                leftIcon={null}
                                                rightIcon={null}
                                            >
                                                Add Chart v2
                                            </DropdownItem>
                                        </DragInitializer>
                                    )} */}
                                    {"AddMaps" in globalContext.permissions && (
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.Map
                                            }
                                        >
                                            <DropdownItem
                                                leftIcon={null}
                                                rightIcon={null}
                                                onClick={() => {
                                                    this.props.onNewPerformance(
                                                        elements.map
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.Map
                                                    );
                                                    this.setState({
                                                        toggledDropdown: undefined,
                                                    });
                                                }}
                                            >
                                                Map
                                            </DropdownItem>
                                        </DragInitializer>
                                    )}
                                    {/* {"AddMapsV2" in
                                        globalContext.permissions && (
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.Map
                                            }
                                            options={{
                                                version: MapVersion.Second,
                                            }}
                                        >
                                            <DropdownItem
                                                leftIcon={null}
                                                rightIcon={null}
                                                onClick={() => {
                                                    this.props.onNewPerformance(
                                                        elements.map
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.Map,
                                                        {
                                                            version:
                                                                MapVersion.Second,
                                                        }
                                                    );
                                                    this.setState({
                                                        toggledDropdown:
                                                            undefined,
                                                    });
                                                }}
                                            >
                                                Add Map v2
                                            </DropdownItem>
                                        </DragInitializer>
                                    )} */}
                                    {/* {"AddGraphs" in
                                        globalContext.permissions && (
                                        <DragInitializer
                                            onDrop={this.hideDropdown}
                                            itemInitializer={
                                                ItemInitializer.Graph
                                            }
                                        >
                                            <DropdownItem
                                                leftIcon={null}
                                                rightIcon={null}
                                                onClick={() => {
                                                    this.props.onNewPerformance(
                                                        elements.graph
                                                    );
                                                    this.props.onTurnItemInitializer(
                                                        ItemInitializer.Graph
                                                    );
                                                    this.setState({
                                                        toggledDropdown:
                                                            undefined,
                                                    });
                                                }}
                                            >
                                                Add Graph
                                            </DropdownItem>
                                        </DragInitializer>
                                    )} */}
                                    {"ShowGuideDots" in
                                        globalContext.permissions && (
                                        <DropdownItem
                                            leftIcon={null}
                                            rightIcon={null}
                                            onClick={this.props.onShowHideDots}
                                        >{`${
                                            this.props.showDots
                                                ? "Hide"
                                                : "Show"
                                        } guide dots`}</DropdownItem>
                                    )}
                                    <DropdownItem
                                        leftIcon={null}
                                        rightIcon={null}
                                        onClick={
                                            this.props.onShowHideSlideNumbers
                                        }
                                    >{`${
                                        this.props.showSlideNumbers
                                            ? "Hide"
                                            : "Show"
                                    } slide numbers`}</DropdownItem>
                                    <DropdownItem
                                        leftIcon={null}
                                        rightIcon={null}
                                        onClick={this.props.onExportAsPdf}
                                    >
                                        {"Export as PDF"}
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <DropdownCanvasButton
                                title="Search"
                                id="open-searchbar"
                                style={{
                                    justifyContent: "center",
                                    padding: 0,
                                    width: 32,
                                    height: 32,
                                }}
                                onClick={this.props.onCommandPaletteClick}
                            >
                                <SearchIcon />
                            </DropdownCanvasButton>
                        </>
                    )}
                    <style>{`
                        .__react_component_tooltip.charts-tooltip.place-bottom::after {
                            left: 20px !important;
                        }
                        .__react_component_tooltip.charts-tooltip {
                            font-size: 16px;
                        }
                    `}</style>
                    <ReactTooltip
                        class="charts-tooltip"
                        place="bottom"
                        id={`${this.state.hoveredMenuItem}-tip`}
                        effect="solid"
                        backgroundColor="#3B82C9"
                        type="dark"
                        overridePosition={(
                            { left, top }: any,
                            event: any,
                            triggerElement: any,
                            tooltipElement: any
                        ) => {
                            return {
                                top: top,
                                left:
                                    left +
                                    getTextSize(
                                        `${triggerElement.dataset.tip}`,
                                        "Roboto",
                                        16,
                                        "600"
                                    ).width /
                                        2 -
                                    2.5,
                            };
                        }}
                    />
                </div>
            );
        }

        render() {
            return (
                <GlobalContext.ObserverConsumer>
                    {this.renderContents}
                </GlobalContext.ObserverConsumer>
            );
        }
    }
);

export default LeftTopBar;
