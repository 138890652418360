import React from "react";

import { CanvasSubmitButton } from "common/Canvas";
import SubmitOptionsSelector from "modules/canvas_page/SubmitOptionsSelector";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import styles from "./Data.module.css";

interface Props {
    canvasId: number;
    node: Pick<CanvasSubmitButton, "backendOutput">;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    onChange: (changes: Partial<Pick<CanvasSubmitButton, "backendOutput">>, commit?: boolean) => void;
}

function Data({ canvasTreeStore, node, currentModuleId, onChange }: Props) {
    return (
        <div className={styles.root}>
            <SubmitOptionsSelector
                currentModuleId={currentModuleId}
                canvasTreeStore={canvasTreeStore}
                value={node}
                onChange={onChange}
            />
        </div>
    );
}

export default Data;
