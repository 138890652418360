import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as BarChartIcon } from "icons/canvas/exploration/bar_chart_icon.svg";
import { ReactComponent as FunnelPlotIcon } from "icons/canvas/exploration/funnel_plot_icon.svg";
import { ReactComponent as GanttIcon } from "icons/canvas/exploration/gantt_icon.svg";
import { ReactComponent as KanbanBoardIcon } from "icons/canvas/exploration/kanban_board_icon.svg";
import { ReactComponent as LinePlotIcon } from "icons/canvas/exploration/line_plot_icon.svg";
import { ReactComponent as PieChartIcon } from "icons/canvas/exploration/pie_chart_icon.svg";
import { ReactComponent as ScatterplotIcon } from "icons/canvas/exploration/scatterplot_icon.svg";
import { ReactComponent as SentmentAnalysisIcon } from "icons/canvas/exploration/sentiment_analysis_icon.svg";
import { ReactComponent as TimePatternIcon } from "icons/canvas/exploration/time_pattern_icon.svg";
import { ReactComponent as WordCloudIcon } from "icons/canvas/exploration/word_cloud_icon.svg";
import { ReactComponent as MagicWandIcon } from "icons/canvas/exploration/magic_wand_2.svg";
import { ReactComponent as LeaderLaggardsIcon } from "icons/canvas/exploration/leader_laggards.svg";
import { ReactComponent as LeversBenchmarkingIcon } from "icons/canvas/exploration/levers_benchmarking.svg";
import { ReactComponent as TimeTrendsIcon } from "icons/canvas/exploration/time_trends.svg";
import { ReactComponent as NetworkIcon } from "icons/canvas/exploration/network.svg";
import { ReactComponent as HistogramIcon } from "icons/canvas/exploration/histogram.svg";
import { ReactComponent as DensityPlotIcon } from "icons/canvas/exploration/density.svg";
import { ReactComponent as Density2dIcon } from "icons/canvas/exploration/density_2d.svg";
import { ReactComponent as CorrelogramIcon } from "icons/canvas/exploration/correlogram.svg";
import { ReactComponent as ArcDiagramIcon } from "icons/canvas/exploration/arc_diagram.svg";
import { ReactComponent as TwoWayHeatmapIcon } from "icons/canvas/exploration/two_way_heatmap.svg";
import { ReactComponent as MapsBubbleIcon } from "icons/canvas/exploration/maps_bubble.svg";
import { ReactComponent as MapsPinsIcon } from "icons/canvas/exploration/maps_pins.svg";
import { ReactComponent as MapsNetworkOverMapIcon } from "icons/canvas/exploration/maps_network_over_map.svg";
import { ReactComponent as MapsHeatmapIcon } from "icons/canvas/exploration/maps_heatmap.svg";
import { ReactComponent as MapsChoroplethIcon } from "icons/canvas/exploration/maps_choropleth.svg";
import { ReactComponent as BubbleIcon } from "icons/canvas/exploration/bubble.svg";
import { ReactComponent as EdgeBundlingIcon } from "icons/canvas/exploration/edge_bundling.svg";
import { ReactComponent as BoxPlotIcon } from "icons/canvas/exploration/box_plot.svg";
import { ReactComponent as ViolinPlotIcon } from "icons/canvas/exploration/violin_plot.svg";
import { ReactComponent as RidgelineIcon } from "icons/canvas/exploration/ridgeline.svg";
import { ReactComponent as StarIcon } from "icons/canvas/exploration/star.svg";
import { ReactComponent as TreemapIcon } from "icons/canvas/exploration/treemap.svg";
import { ReactComponent as DendrogramIcon } from "icons/canvas/exploration/dendrogram.svg";
import styles from "./ChartIcon.module.css";

interface Props {
    name: string;
    fill?: string;
    filter?: string;
}

const nameToIcon: Readonly<{
    [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>;
}> = {
    CompareVariablesJourney: BarChartIcon,
    CompareVariablesD3Journey: BarChartIcon,
    FunnelPlotJourney: FunnelPlotIcon,
    GanttChartJourney: GanttIcon,
    KanbanBoardJourney: KanbanBoardIcon,
    LineplotJourney: LinePlotIcon,
    LineplotD3Journey: LinePlotIcon,
    CompareRatiosJourney: PieChartIcon,
    ScatterplotJourney: ScatterplotIcon,
    ScatterplotD3Journey: ScatterplotIcon,
    SentimentAnalysisJourney: SentmentAnalysisIcon,
    CompareTimePatternsJourney: TimePatternIcon,
    WordCloudJourney: WordCloudIcon,
    AnalyzeLeverOutcomeRelationshipJourney: MagicWandIcon,
    HoldOutPredictionJourney: MagicWandIcon,
    LeadersLaggardsJourney: LeaderLaggardsIcon,
    LeversBenchmarkingJourney: LeversBenchmarkingIcon,
    ExamineTimeTrendsJourney: TimeTrendsIcon,
    NetworkJourney: NetworkIcon,
    HistogramJourney: HistogramIcon,
    DensityPlotJourney: DensityPlotIcon,
    Density2dJourney: Density2dIcon,
    CorrelogramJourney: CorrelogramIcon,
    ArcDiagramJourney: ArcDiagramIcon,
    HeatmapJourney: TwoWayHeatmapIcon,
    MapsBubbleJourney: MapsBubbleIcon,
    MapsPinsJourney: MapsPinsIcon,
    MapsNetworkOverMapJourney: MapsNetworkOverMapIcon,
    MapsHeatmapJourney: MapsHeatmapIcon,
    MapsChoroplethJourney: MapsChoroplethIcon,
    BubbleJourney: BubbleIcon,
    EdgeBundlingJourney: EdgeBundlingIcon,
    BoxPlotJourney: BoxPlotIcon,
    ViolinPlotJourney: ViolinPlotIcon,
    RidgelineJourney: RidgelineIcon,
    TreemapJourney: TreemapIcon,
    DendrogramJourney: DendrogramIcon,
};

export default function ChartIcon(props: Props): JSX.Element | null {
    let { name, ...restProps } = props;

    let Icon = nameToIcon[name] ?? StarIcon;
    return (
        <div className={styles.chartIconDefaultContainer}>
            <Icon {...restProps} />
        </div>
    );
}
