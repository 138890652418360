import axios from "common/ServerConnection";

export interface Token {
    display_name: string;
    name: string;
    connected: boolean;
}

export interface API {
    fields: string[]
    display_name: string;
    key: string;
    connected: boolean;
}

export async function getTokenListApi(): Promise<Token[]> {
    return axios
        .get<{
            success: boolean;
            error_msg: string;
            token_list?: Token[];
        }>("/api/token_storage_get_token_list")
        .then((response) => {
            if (response.data.success && response.data.token_list != null) {
                return response.data.token_list;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function getApiListApi(): Promise<API[]> {
    return axios
        .get<{
            success: boolean;
            error_msg: string;
            api_list?: API[];
        }>("/api/token_storage_get_api_list")
        .then((response) => {
            if (response.data.success && response.data.api_list != null) {
                return response.data.api_list;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function getAuthorizationUrlApi(
    tokenName: string
): Promise<string> {
    let requestJson = {
        token_name: tokenName,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            uri?: string;
        }>("/api/token_storage_get_authorization_url", requestJson)
        .then((response) => {
            if (response.data.success && response.data.uri != null) {
                return response.data.uri;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function postAPIConnection(
    api_data: any
): Promise<boolean> {
    let requestJson = {
        api_data: api_data,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/token_storage_set_api_details", requestJson)
        .then((response) => {
            if (response.data.success) {
                return true;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
}

export async function completeAuthorizationApi(
    authorizationResponse: string
): Promise<void> {
    let requestJson = {
        authorization_response: authorizationResponse,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            uri?: string;
        }>("/api/token_storage_complete_authorization", requestJson)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function deleteTokenApi(tokenName: string): Promise<void> {
    let requestJson = {
        token_name: tokenName,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/token_storage_delete_token", requestJson)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function deleteApi(tokenName: string): Promise<void> {
    let requestJson = {
        token_name: tokenName,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/token_storage_delete_api", requestJson)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        });
}
