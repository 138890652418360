import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";

import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import {
    TokenSettings,
    SettingsType,
    getTokenSettingsApi,
    setTokenSettingsApi,
} from "./api";
import customSelectStyles from "common/SelectStyles";

interface State {
    expanded: boolean;
    statusFor: string | null;
    status: UpdateStatus;
    errorMessage: string;
    settings: TokenSettings[];
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            expanded: false,
            statusFor: null,
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            settings: [],
        };
        this.performance = null;

        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    private toggleExpanded(): void {
        this.setState((state) => ({
            expanded: !state.expanded,
        }));
    }

    public componentDidMount(): void {
        getTokenSettingsApi()
            .then((settings) => {
                this.setState({
                    settings: settings,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: error.toString(),
                });
            });
    }

    public componentDidUpdate(): void {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    public render(): JSX.Element | null {
        if (this.state.settings.length === 0) {
            return null;
        }

        return (
            <div style={{ marginTop: 20 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                    }}
                    onClick={this.toggleExpanded}
                >
                    <div
                        className="regular-text"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 25,
                        }}
                    >
                        <i
                            className={`fa fa-2x fa-angle-${
                                this.state.expanded ? "down" : "right"
                            }`}
                        />
                    </div>
                    <span className="big-title-span">API keys</span>
                </div>
                <div className="flex-simple-column">
                    {this.state.expanded &&
                        this.state.settings.map((settings, settingsIndex) => (
                            <div
                                style={{ marginTop: 10, width: 1000 }}
                                className="flex-simple-column"
                            >
                                <span
                                    className="regular-text"
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {settings.display_name}
                                </span>
                                {Object.entries(settings.settings).map(
                                    ([fieldName, field]) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                paddingLeft: 15,
                                                marginLeft: 19,
                                                marginBottom: 10,
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 140,
                                                    marginRight: 10,
                                                }}
                                                className="regular-text"
                                            >
                                                {field.display_name}
                                            </div>
                                            {field.type ===
                                                SettingsType.String && (
                                                <input
                                                    className="like-select"
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                    value={
                                                        settings
                                                            .settings_values[
                                                            fieldName
                                                        ] ?? ""
                                                    }
                                                    placeholder="NONE"
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value;
                                                        this.setState(
                                                            (state) => {
                                                                let settings = Array.from(
                                                                    state.settings
                                                                );
                                                                settings[
                                                                    settingsIndex
                                                                ] = {
                                                                    ...settings[
                                                                        settingsIndex
                                                                    ],
                                                                    settings_values: {
                                                                        ...settings[
                                                                            settingsIndex
                                                                        ]
                                                                            .settings_values,
                                                                        [fieldName]: value,
                                                                    },
                                                                };
                                                                return {
                                                                    settings: settings,
                                                                };
                                                            }
                                                        );
                                                    }}
                                                />
                                            )}
                                            {field.type ===
                                                SettingsType.Choice && (
                                                <Select
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    placeholder={""}
                                                    styles={{
                                                        ...customSelectStyles,
                                                        container: (base) => ({
                                                            ...base,
                                                            height: "38px",
                                                            flex: 1,
                                                        }),
                                                    }}
                                                    options={field.options!}
                                                    value={
                                                        settings
                                                            .settings_values[
                                                            fieldName
                                                        ] == null
                                                            ? null
                                                            : field.options!.find(
                                                                  (option) =>
                                                                      option.value ===
                                                                      settings
                                                                          .settings_values[
                                                                          fieldName
                                                                      ]
                                                              )
                                                    }
                                                    onChange={(newValue) => {
                                                        const value = (newValue as {
                                                            label: string;
                                                            value:
                                                                | string
                                                                | number;
                                                        }).value;
                                                        this.setState(
                                                            (state) => {
                                                                let settings = Array.from(
                                                                    state.settings
                                                                );
                                                                settings[
                                                                    settingsIndex
                                                                ] = {
                                                                    ...settings[
                                                                        settingsIndex
                                                                    ],
                                                                    settings_values: {
                                                                        ...settings[
                                                                            settingsIndex
                                                                        ]
                                                                            .settings_values,
                                                                        [fieldName]: value,
                                                                    },
                                                                };
                                                                return {
                                                                    settings: settings,
                                                                };
                                                            }
                                                        );
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            )}
                                        </div>
                                    )
                                )}
                                <Button
                                    type="button"
                                    className="btn btn-lg btn-primary my-primary"
                                    style={{
                                        marginLeft: 19,
                                        marginBottom: 10,
                                        width: 200,
                                    }}
                                    onClick={() => {
                                        setTokenSettingsApi(
                                            settings.name,
                                            settings.settings_values
                                        )
                                            .then(() => {
                                                this.setState({
                                                    statusFor: settings.name,
                                                    status:
                                                        UpdateStatus.Success,
                                                    errorMessage: "",
                                                });
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                this.setState({
                                                    statusFor: settings.name,
                                                    status: UpdateStatus.Error,
                                                    errorMessage: error.toString(),
                                                });
                                            });
                                    }}
                                >
                                    Change
                                </Button>
                                {this.state.statusFor === settings.name &&
                                    this.state.status !==
                                        UpdateStatus.NotUploaded && (
                                        <UpdateStatusAlert
                                            value={this.state.status}
                                            onChange={(status) => {
                                                this.setState({
                                                    statusFor: null,
                                                    status: status,
                                                });
                                            }}
                                            errorMessage={
                                                this.state.errorMessage
                                            }
                                        />
                                    )}
                            </div>
                        ))}
                    {this.state.statusFor == null &&
                        this.state.status !== UpdateStatus.NotUploaded && (
                            <UpdateStatusAlert
                                value={this.state.status}
                                onChange={(status) => {
                                    this.setState({
                                        statusFor: null,
                                        status: status,
                                    });
                                }}
                                errorMessage={this.state.errorMessage}
                            />
                        )}
                </div>
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "ChangeAPIKeys";
