import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import styles from "./EditVariablesSection.module.css";
import {
    getCustomSelectStyleForDataSection,
    leftAlignAndScrollBarCSS,
    Styles,
} from "common/SelectStyles";

import { PopupStatus } from "common/StatusPopup";
import Variables, { VariableOption } from "common/Variables";
import {
    geographyLevelsOptions,
    geographyLevelsSelectOptions,
    numberLevelsSelectOptions,
    Panel,
    timeLevelsOptions,
    Type,
    TypeOption,
    typeOptions,
} from "common/InputData";
import { RenameVariable } from "common/VariableEditor";
import DateTimeFormatSelect, {
    OptionType as DateTimeOptionType,
} from "common/DateTimeFormatSelect";
import Select, { createFilter } from "react-select";
import StringOption from "common/StringOption";
import {
    addVariable,
    dropVariableApi,
    renameVariablesApi,
} from "common/DataApi";
import { PreviewHeader } from "../MergeSection";
import MessagePopup from "../MessagePopup";
import SelectedOption from "../SelectedOption";
import { NewVariable } from "common/VariableCreator";

enum EditType {
    Edit = 1,
    Delete = 2,
}

export interface EditOption {
    label: string;
    value: EditType;
}

export const editOptions: ReadonlyArray<EditOption> = [
    { label: "Edit", value: EditType.Edit },
    { label: "Delete", value: EditType.Delete },
];
interface props {
    dataset?: DataScopeOption | null;
    selectedVariable: PreviewHeader;
    setSelectedVariable: (
        selectedVariable: React.SetStateAction<{
            id: number;
            name: string;
            type: string;
            Xcoord: number;
            Ycoord: number;
        } | null>
    ) => void;
    setStatusPopup: (
        contents: {
            status: PopupStatus;
            message: string;
        } | null
    ) => void;
    table: TableOption | null;
    newVariable?: boolean;
    setGenericVariables?: (
        genericVariables: React.SetStateAction<NewVariable[]>
    ) => void;
}

const getType = (
    type: string | undefined | null,
    panel: string | null | undefined
) => {
    switch (type) {
        case "text":
            return typeOptions[0].label;
        case "str": {
            switch (panel) {
                case "geography":
                    return typeOptions[3].label;

                default:
                    return typeOptions[0].label;
            }
        }
        case "double":
            return typeOptions[1].label;
        case "int":
            return typeOptions[1].label;
        case "float":
            return typeOptions[1].label;
        case "datetime":
            return typeOptions[2].label;
        default:
            return typeOptions[3].label;
    }
};

function EditVariablesSection(props: props) {
    const editVariable = async (
        editOption: EditOption,
        dataset: DataScopeOption,
        selectedVariable: VariableOption,
        renameVariable: RenameVariable
    ) => {
        if (
            renameVariable.level === "Percent" ||
            renameVariable.level === "Currency"
        ) {
            renameVariable.type = Type.Str;
        }

        // if (renameVariable.type === "int" && renameVariable.level === null) {
        //     renameVariable.level = numberLevelsOptions[0];
        // }

        if (editOption.value === EditType.Delete) {
            await dropVariableApi(dataset.value, selectedVariable!.value);
        } else {
            await renameVariablesApi(dataset.value, [renameVariable], null);
        }
    };

    const executeEdit = async (
        editOption: EditOption,
        dataset: DataScopeOption,
        selectedVariable: VariableOption,
        renameVariable: RenameVariable
    ) => {
        // setEditing(true);
        props.setStatusPopup({
            status: PopupStatus.Success,
            message: "Edit data...",
        });
        await editVariable(
            editOption,
            dataset,
            selectedVariable,
            renameVariable
        )
            .then(() => {
                // setEditing(false);

                props.setStatusPopup({
                    status: PopupStatus.Success,
                    message: "Success",
                });
                setSelectedVariable(null);
                setRenameVariable(null);
                Tables(dataset.value).update();
                Variables(dataset.value).update();
                props.setSelectedVariable(null);
            })
            .catch((error) => {
                // setEditing(false);
                props.setStatusPopup({
                    status: PopupStatus.Error,
                    message: String(error),
                });
            });
        DataScopes.previewState = { headers: null, body: null };

        if (props.dataset?.value != null) {
            await DataScopes.DatasetPreview(props.dataset?.value);
        }
        setDeletePopup(null);
    };

    const executeCreateVariables = (
        table: TableOption,
        selectedVariable: VariableOption,
        preview: boolean
    ) => {
        props.setStatusPopup({
            status: PopupStatus.Success,
            message: "Generating data...",
        });
        addVariable(
            table!,
            "",
            [],
            (previewData) => {
                props.setStatusPopup({
                    status: PopupStatus.Success,
                    message: "Success",
                });

                Tables(table.data_table_idx!).update();
                Variables(table.data_table_idx!).update();
            },
            (errorMessage: string) => {
                props.setStatusPopup({
                    status: PopupStatus.Error,
                    message: errorMessage,
                });
            },
            preview,
            false,
            selectedVariable
        );
    };

    let [
        selectedVariable,
        setSelectedVariable,
    ] = React.useState<VariableOption | null>(null);
    let [
        renameVariable,
        setRenameVariable,
    ] = React.useState<RenameVariable | null>(null);
    let [formatOptions, setFormatOptions] = React.useState<
        DateTimeOptionType[]
    >(DateTimeFormatSelect.defaultOptions());
    let [deletePopup, setDeletePopup] = useState<null | boolean>(false);

    let optionStyles: Styles = {
        ...getCustomSelectStyleForDataSection(14, false),
        ...leftAlignAndScrollBarCSS,
        container: (base) => ({
            ...base,
            height: "26px",
            borderRadius: 4,
        }),
        control: (base) => ({
            ...base,
            minHeight: 26,
            height: 28,
            borderRadius: 4,
        }),

        valueContainer: (base) => ({
            ...base,
            padding: "2px 0px",
        }),
        input: (base) => ({
            ...base,
            margin: 0,
            paddingLeft: 7,
        }),
        singleValue: (base) => ({
            ...base,
            textAlign: "left",
            fontSize: 14,
            paddingLeft: 5,
            color: "#555",
            opacity: "80%",
        }),
        placeholder: (base) => ({
            ...base,
            paddingLeft: "5px",
            textAlign: "left",
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? "var(--greyColor)" : "white",
            color: "black",
            textAlign: "left",
            borderRadius: 5,
            "&:hover": {
                backgroundColor: state.isSelected
                    ? "var(--greyColor)"
                    : "var(--greyColor)",
            },
        }),
    };

    const newVariable = props.newVariable ?? false;

    useEffect(() => {
        if (props.selectedVariable) {
            const vari: VariableOption | null = {
                label: props.selectedVariable.name,
                value: props.selectedVariable.id,
                type:
                    props.selectedVariable.type === "text"
                        ? "str"
                        : props.selectedVariable.type,
                panel: "regular",
                unit: undefined,
                level:
                    props.selectedVariable.type === "int" ||
                    props.selectedVariable.type === "float" ||
                    props.selectedVariable.type === "double"
                        ? numberLevelsSelectOptions[0].value
                        : null,
            };
            setSelectedVariable(vari);

            setRenameVariable((oldRenameVariable) => {
                let renameVariable = {
                    ...oldRenameVariable!,
                    index: props.selectedVariable.id,
                    name: props.selectedVariable.name,
                    new_name: props.selectedVariable.name,
                    type:
                        props.selectedVariable.type === "text"
                            ? "str"
                            : props.selectedVariable.type,
                    panel:
                        props.selectedVariable.type === "int" ||
                        props.selectedVariable.type === "float" ||
                        props.selectedVariable.type === "double" ||
                        props.selectedVariable.type === "text"
                            ? Panel.Regular
                            : props.selectedVariable.type === "datetime"
                            ? Panel.Time
                            : null,
                    level:
                        props.selectedVariable.type === "int" ||
                        props.selectedVariable.type === "float" ||
                        props.selectedVariable.type === "double"
                            ? numberLevelsSelectOptions[0].value
                            : null,
                    format: null,
                };
                return renameVariable;
            });
        }
    }, [newVariable]);

    const levelOptions =
        renameVariable?.panel === Panel.Geography
            ? geographyLevelsSelectOptions
            : numberLevelsSelectOptions;

    return (
        <div className={styles.variablesContainer}>
            <div
                className="my-row"
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
                <div>
                    <div className={styles.editHeader}>Change Column Type </div>
                    <Select
                        filterOption={createFilter({ ignoreAccents: false })}
                        placeholder={"Data type"}
                        styles={optionStyles}
                        options={typeOptions}
                        onChange={(newValue) => {
                            let typeOption = newValue as TypeOption;
                            let type = typeOption.type;
                            let panel = typeOption.panel;
                            let level =
                                panel === Panel.Time
                                    ? timeLevelsOptions[0]
                                    : panel === Panel.Geography
                                    ? geographyLevelsOptions[0]
                                    : null;
                            setRenameVariable((oldRenameVariable) => {
                                // let typeOption = newValue as TypeOption;
                                // let type = typeOption.type;
                                // let panel = typeOption.panel;
                                // let level =
                                //     panel === Panel.Time
                                //         ? timeLevelsOptions[0]
                                //         : panel === Panel.Geography
                                //             ? geographyLevelsOptions[0]
                                //             : null;
                                let unit = typeOption.fixedUnit
                                    ? null
                                    : oldRenameVariable?.unit ?? null;
                                let renameVariable = {
                                    ...oldRenameVariable!,
                                    type: type,
                                    panel: panel,
                                    level: level,
                                    format:
                                        panel === Panel.Time
                                            ? formatOptions[0].value
                                            : null,
                                    unit: unit,
                                };
                                return renameVariable;
                            });
                            props.setGenericVariables?.((genericVariables) => [
                                {
                                    ...genericVariables[0],
                                    type: type,
                                    panel: panel,
                                    level: level,
                                    format:
                                        panel === Panel.Time
                                            ? formatOptions[0].value
                                            : null,
                                },
                            ]);
                        }}
                        value={{
                            label: getType(
                                renameVariable?.type,
                                renameVariable?.panel
                            ),
                            value: renameVariable?.type,
                            type: Type.Str,
                            panel: Panel.Regular,
                            fixedUnit: false,
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
                {/* <CreatableSelect
                    filterOption={createFilter({ ignoreAccents: false, })}
                    isDisabled={renameVariable?.panel !== Panel.Regular}
                    placeholder={"Units"}
                    styles={{
                        ...optionStyles,
                        dropdownIndicator: ((provided) => ({
                            ...provided,
                            padding: "0 8px 0 0",
                            display: renameVariable?.panel !== Panel.Regular ? "none" : "",
                        })),
                    }}
                    options={
                        dataset
                            ? Variables(dataset.value).columnUnitOptions.map((option) => ({
                                label: option.length === 0
                                    ? "None"
                                    : option,
                                value: option,
                            }))
                            : []
                    }
                    onChange={(newValue) => {
                        let unit = (newValue as StringOption).value;
                        setRenameVariable((oldRenameVariable) => ({
                            ...oldRenameVariable!,
                            unit: unit,
                        }));
                    }}
                    value={
                        renameVariable?.unit
                            ? {
                                label: renameVariable.unit,
                                value: renameVariable.unit,
                            }
                            : null
                    }
                    onCreateOption={(option) => {
                        Variables(
                            dataset!.value
                        ).setColumnUnitOptions([
                            ...Variables(dataset!.value)
                                .columnUnitOptions,
                            option,
                        ]);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                /> */}

                {
                    // ((renameVariable?.type === "int" || renameVariable?.type === "float" || renameVariable?.type === "double") ||
                    renameVariable?.type === "str" &&
                        renameVariable?.panel === "geography" && (
                            <div>
                                <div className={styles.editHeader}>Format</div>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Format"}
                                    styles={optionStyles}
                                    options={levelOptions}
                                    onChange={(newValue) => {
                                        let level = (newValue as StringOption)
                                            .value;
                                        setRenameVariable(
                                            (oldRenameVariable) => {
                                                let renameVariable = {
                                                    ...oldRenameVariable!,
                                                    level: level,
                                                };
                                                return renameVariable;
                                            }
                                        );
                                    }}
                                    value={
                                        // renameVariable?.level  ?
                                        {
                                            label: renameVariable.level,
                                            value: renameVariable.level,
                                        }
                                        // : renameVariable?.type === "int"
                                        //     ? {
                                        //         label: numberLevelsOptions[0],
                                        //         value: numberLevelsOptions[0],
                                        //     }
                                        //     : null
                                    }
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                {/* {
                            (renameVariable?.type === "int" || renameVariable?.type === "float" || renameVariable?.type === "double") &&
                            <div className={`${styles.decimalContainer} hideIcon`} style={{ marginTop: 10 }} >
                                <div className={styles.editHeader}>Decimals</div>
                                <input
                                    className={cx(styles.decimalInput, "like-select")}
                                    style={{
                                        height: "26px",
                                        fontFamily: "Roboto",
                                        textAlign: "left",
                                        border: '1px solid #ccc',
                                        color: "#555",
                                        padding: "0 0 0 6px",
                                        minHeight: 26,
                                        width: '100%'
                                    }}
                                    type="number"
                                    onChange={(e) => {
                                        let value = Number(e.target.value)

                                        if (value > 6 || value < 0) {
                                            setRenameVariable((oldRenameVariable) => {
                                                let renameVariable = {
                                                    ...oldRenameVariable!,
                                                    decimal: 2,
                                                };
                                                return renameVariable;
                                            });
                                            setdecimal(2)
                                        }
                                        else {
                                            setRenameVariable((oldRenameVariable) => {
                                                let renameVariable = {
                                                    ...oldRenameVariable!,
                                                    decimal: value,
                                                    type: value !== 0 ? Type.Float : Type.Int
                                                };
                                                return renameVariable;
                                            });
                                            setdecimal(2)
                                        }
                                    }}
                                    value={renameVariable?.decimal ?? ""}
                                />
                            </div>
                        } */}
                            </div>
                        )
                }

                {renameVariable?.type === "datetime" && (
                    <DateTimeFormatSelect
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        onCreateOption={(option) => {
                            setRenameVariable((oldRenameVariable) => {
                                let renameVariable = {
                                    ...oldRenameVariable!,
                                    format: (option as DateTimeOptionType)
                                        .value,
                                };
                                return renameVariable;
                            });
                            setFormatOptions((oldFormatOptions) => [
                                option,
                                ...oldFormatOptions,
                            ]);
                        }}
                        placeholder={
                            renameVariable?.panel === Panel.Time
                                ? "Example: %Y-%m-%d"
                                : ""
                        }
                        styles={{
                            ...optionStyles,
                            dropdownIndicator: (provided) => ({
                                ...provided,
                                padding: "0 6px 0 0",
                                display:
                                    renameVariable?.panel !== Panel.Time
                                        ? "none"
                                        : "",
                            }),
                            menu: (base) => ({
                                ...base,
                                borderRadius: 6,
                                overflow: "auto",
                            }),
                        }}
                        options={formatOptions}
                        onChange={(newValue) => {
                            setRenameVariable((oldRenameVariable) => {
                                let renameVariable = {
                                    ...oldRenameVariable!,
                                    format: (newValue as DateTimeOptionType)
                                        .value,
                                };
                                return renameVariable;
                            });
                            props.setGenericVariables?.((genericVariables) => [
                                {
                                    ...genericVariables[0],
                                    format: (newValue as DateTimeOptionType)
                                        .value,
                                },
                            ]);
                        }}
                        value={renameVariable?.format ?? formatOptions}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                )}
                {renameVariable?.type === "str" &&
                    renameVariable?.panel === "geography" && (
                        //  ||   renameVariable?.panel === "regular"
                        <button
                            className={"acceptBtn"}
                            style={{
                                marginTop: 7,
                                width: "100%",
                                paddingBlock: 5,
                            }}
                            type="button"
                            disabled={props.dataset == null}
                            onClick={() => {
                                executeCreateVariables(
                                    props.table!,
                                    selectedVariable!,
                                    false
                                );
                                DataScopes.previewState = {
                                    headers: null,
                                    body: null,
                                };

                                if (props.dataset?.value != null) {
                                    DataScopes.DatasetPreview(
                                        props.dataset?.value
                                    );
                                }
                                SelectedOption.UpdateaskSwitchTab(false);
                                SelectedOption.UpdateSwitchTab(true);
                            }}
                        >
                            Create GPS Coordinates
                        </button>
                    )}
                <button
                    className={"acceptBtn"}
                    style={{ border: 0, paddingBlock: 3 }}
                    type="button"
                    disabled={props.dataset == null}
                    onClick={() => {
                        if (!props.newVariable) {
                            executeEdit(
                                editOptions[0],
                                props.dataset!,
                                selectedVariable!,
                                renameVariable!
                            );
                        }
                        props.setSelectedVariable(null);
                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(true);
                    }}
                >
                    Save
                </button>
                {!props.newVariable && (
                    <button
                        className={`${styles.deleteBtn} acceptBtn`}
                        style={{
                            background: "var(--danger-color)",
                            color: "white",
                            border: 0,
                            paddingBlock: 3,
                        }}
                        type="button"
                        disabled={props.dataset == null}
                        onClick={() => {
                            setDeletePopup(true);
                            SelectedOption.UpdateaskSwitchTab(false);
                            SelectedOption.UpdateSwitchTab(true);
                        }}
                    >
                        Delete Variable
                    </button>
                )}
            </div>

            {deletePopup && (
                <MessagePopup
                    title={<p>Delete</p>}
                    danger={true}
                    acceptButtonTitle="Delete"
                    message={`Are you sure you want to Delete this Variable?`}
                    onAccept={() => {
                        executeEdit(
                            { label: "Delete", value: EditType.Delete },
                            props.dataset!,
                            selectedVariable!,
                            renameVariable!
                        );
                        props.setSelectedVariable(null);
                    }}
                    onReject={() => {
                        setDeletePopup(null);
                        props.setSelectedVariable(null);
                    }}
                />
            )}
        </div>
    );
}

export default observer(EditVariablesSection);
