import jsPDF from "jspdf";
import { renderPreview } from "../utilities/renderCanvas";
import { BriefCanvas } from "common/Canvas";
import Canvases from "common/Canvases";
import CanvasTreeStore from "../CanvasTreeStore";
import { defaultSlideHeight, defaultSlideWidth } from "../Constants";
import { BriefModule } from "common/Module";

export async function toPdf(
	ref: React.Ref<HTMLElement>,
	canvases: BriefCanvas[],
	briefModule: BriefModule,
	moduleId: number
) {
	const doc = new jsPDF({
		orientation: "l", // landscape
		unit: "pt", // points, pixels won't work properly
		format: [defaultSlideWidth, defaultSlideHeight], // set needed dimensions for any element
	});
	let count = 0;

	for (let canvas of canvases) {
		let fetchedCanvas = await Canvases(canvas.page_id).getFullCanvas(
			canvas.id,
			false
		);
		let canvasTreeStore = new CanvasTreeStore(true);
		canvasTreeStore.scale = 2;
		canvasTreeStore.moduleId = moduleId;
		canvasTreeStore.canvasPageId = canvas.page_id;
		await canvasTreeStore.deserializeAsyncAction.bothParts(
			{ ...fetchedCanvas.canvas, mapElementsState: {} },
			{
				canvasId: fetchedCanvas.id,
				backgrounds: fetchedCanvas.backgrounds,
				delegateId: fetchedCanvas.delegate_id,
			}
		);

		canvasTreeStore.updateCanvasSizeAction(canvasTreeStore.slideRect());
		const pageImage = await renderPreview(
			ref,
			canvasTreeStore,
			moduleId,
			briefModule.options?.slideNumberOptions?.show
				? count + 1
				: undefined,
			5000,
		);
		let slideRect = canvasTreeStore.slideRect();
		let slideWidth = slideRect.width;
		let slideHeight = slideRect.height;
		doc.addPage([slideWidth, slideHeight], "l");
		doc.addImage(pageImage, "PNG", 0, 0, slideWidth, slideHeight);

		count += 1;
	}
	doc.deletePage(1);
	const pdf = doc.output("blob");
	return pdf;
}
