import React from "react";
import { StatusExpression } from "common/Canvas";
import ColorOptions from "common/ColorOptions";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";
import { Button } from "react-bootstrap";
import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import StatusSubExpressionSelector from "common/StatusSubExpressionsSelector";
interface StringOption {
    label: string;
    value: string;
}

// interface NumberOption {
//     label: string;
//     value: number;
// }

export interface StatusFillOption {
    label: string;
    value: FillType;
}

export enum FillType {
    Border = 1,
    Fill = 2,
    Font = 3,
}

export const statusFillOptions: ReadonlyArray<StatusFillOption> = [
    {
        label: "Border",
        value: FillType.Border,
    },
    {
        label: "Fill",
        value: FillType.Fill,
    },
    {
        label: "Font Color",
        value: FillType.Font,
    },
];

interface Props {
    selectOutput: boolean;
    nodeLinkOptions: NodeLinkOption[];
    statusExpressions: StatusExpression[];
    node: CanvasElement | CanvasTextBox;
    onChange: (changes: StatusExpression[]) => void;
}

interface OutputIndexOption {
    label: string;
    value: number | null;
}

export default class StatusExpressionsSelector extends React.Component<Props> {
    static getOutputValue(
        node: CanvasElement | CanvasTextBox,
        statusExpression: StatusExpression
    ): OutputIndexOption | null {
        if (statusExpression.outputIndex == null) return null;
        if (statusExpression.outputIndex === 1)
            return {
                label: "Output#1",
                value: 1,
            };
        if (statusExpression.outputIndex > 1) {
            if (
                node.additionalOutputs.length >
                statusExpression.outputIndex - 2
            )
                return {
                    label: `Output#${statusExpression.outputIndex}`,
                    value: statusExpression.outputIndex,
                };
        }
        return null;
    }
    render() {
        return (
            <div className="flex-simple-column">
                {this.props.statusExpressions.map(
                    (statusExpression, expressionIndex) => (
                        <div
                            key={expressionIndex}
                            className="my-row"
                            style={{
                                marginLeft: "10px",
                            }}
                        >
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"COLOR"}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginLeft: "26px",
                                        width: "110px",
                                        height: "38px",
                                    }),
                                    placeholder: (base) => ({
                                        ...base,
                                        color: "#869AAC",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color:
                                            state.data.value == null ||
                                            state.data.value === "transparent"
                                                ? mainStyle.getPropertyValue(
                                                      "--selectors-text-color"
                                                  )
                                                : state.data.value,
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color:
                                            state.data.value == null ||
                                            state.data.value === "transparent"
                                                ? mainStyle.getPropertyValue(
                                                      "--selectors-text-color"
                                                  )
                                                : state.data.value,
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        display: state.selectProps.menuIsOpen
                                            ? "none"
                                            : "block",
                                    }),
                                }}
                                options={ColorOptions}
                                value={statusExpression.color}
                                onChange={(newValue) => {
                                    const newStatusColorValue: StringOption = newValue as StringOption;
                                    let statusExpressions = Array.from(
                                        this.props.statusExpressions
                                    );
                                    statusExpressions[
                                        expressionIndex
                                    ].color = newStatusColorValue;
                                    this.props.onChange(statusExpressions);
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "110px",
                                        marginLeft: "5px",
                                        height: "38px",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={statusFillOptions}
                                value={
                                    statusExpression.font
                                        ? statusFillOptions[2]
                                        : statusExpression.fill
                                        ? statusFillOptions[1]
                                        : statusFillOptions[0]
                                }
                                onChange={(newValue) => {
                                    let statusExpressions = Array.from(
                                        this.props.statusExpressions
                                    );
                                    statusExpressions[expressionIndex].fill =
                                        (newValue as StatusFillOption).value ===
                                        FillType.Fill;
                                    statusExpressions[expressionIndex].font =
                                        (newValue as StatusFillOption).value ===
                                        FillType.Font;
                                    statusExpressions[
                                        expressionIndex
                                    ].outputIndex = null;
                                    if (
                                        this.props.selectOutput &&
                                        statusExpressions[expressionIndex].font
                                    )
                                        statusExpressions[
                                            expressionIndex
                                        ].outputIndex = 1;
                                    this.props.onChange(statusExpressions);
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                menuPortalTarget={document.body}
                            />
                            {!statusExpression.fill && !statusExpression.font && (
                                <>
                                    <input
                                        className="like-select"
                                        style={{
                                            marginLeft: "5px",
                                            width: "35px",
                                            height: "38px",
                                        }}
                                        onChange={(e) => {
                                            const value: string =
                                                e.target.value;
                                            if (!/^[0-9]*$/.test(value)) {
                                                e.preventDefault();
                                            } else {
                                                let statusExpressions = Array.from(
                                                    this.props.statusExpressions
                                                );
                                                statusExpressions[
                                                    expressionIndex
                                                ].borderWidth = Number(value);
                                                this.props.onChange(
                                                    statusExpressions
                                                );
                                            }
                                        }}
                                        value={
                                            statusExpression.borderWidth === 0
                                                ? ""
                                                : (
                                                      statusExpression.borderWidth ??
                                                      1
                                                  ).toString()
                                        }
                                    />

                                    <span
                                        style={{
                                            fontFamily: "Arial",
                                            fontSize: "17px",
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                            width: "15px",
                                            marginLeft: "5px",
                                            lineHeight: "38px",
                                        }}
                                    >
                                        {"px"}
                                    </span>
                                </>
                            )}
                            {statusExpression.fill && (
                                <div
                                    style={{
                                        marginLeft: "5px",
                                        width: "55px",
                                    }}
                                />
                            )}
                            {statusExpression.font && this.props.selectOutput && (
                                <div
                                    className="my-row"
                                    style={{
                                        alignItems: "center",
                                        height: "38px",
                                    }}
                                >
                                    <span
                                        style={{
                                            marginLeft: "5px",
                                            textAlign: "right",
                                            fontFamily: "Arial",
                                            fontSize: "17px",
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                        }}
                                    >
                                        {"For"}
                                    </span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                height: "38px",
                                                width: "120px",
                                                marginLeft: "5px",
                                            }),
                                        }}
                                        options={[
                                            {
                                                label: "Output#1",
                                                value: 1,
                                            },
                                        ].concat(
                                            this.props.node.additionalOutputs?.map(
                                                (_output, outputIndex) => ({
                                                    label: `Output#${
                                                        outputIndex + 2
                                                    }`,
                                                    value: outputIndex + 2,
                                                })
                                            )
                                        )}
                                        onChange={(newValue) => {
                                            let newStatusExpressions = Array.from(
                                                this.props.statusExpressions
                                            );
                                            newStatusExpressions[
                                                expressionIndex
                                            ].outputIndex = (newValue as OutputIndexOption).value;

                                            this.props.onChange(
                                                newStatusExpressions
                                            );
                                        }}
                                        value={StatusExpressionsSelector.getOutputValue(
                                            this.props.node,
                                            statusExpression
                                        )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            )}
                            <StatusSubExpressionSelector
                                node={this.props.node}
                                nodeLinkOptions={this.props.nodeLinkOptions}
                                subexpressions={statusExpression.subexpressions}
                                onChange={(subexpressions) => {
                                    let statusExpressions = Array.from(
                                        this.props.statusExpressions
                                    );
                                    statusExpressions[
                                        expressionIndex
                                    ].subexpressions = subexpressions;
                                    this.props.onChange(statusExpressions);
                                }}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 20 }}
                            >
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let statusExpressions = Array.from(
                                            this.props.statusExpressions
                                        );
                                        statusExpressions.push({
                                            subexpressions: [
                                                { operation: "", value: "" },
                                            ],
                                            color: ColorOptions[0],
                                        });

                                        this.props.onChange(statusExpressions);
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let statusExpressions = Array.from(
                                            this.props.statusExpressions
                                        );
                                        statusExpressions.splice(
                                            expressionIndex,
                                            1
                                        );
                                        if (statusExpressions.length === 0) {
                                            statusExpressions.push({
                                                subexpressions: [
                                                    {
                                                        operation: "",
                                                        value: "",
                                                    },
                                                ],
                                                color: ColorOptions[0],
                                            });
                                        }

                                        this.props.onChange(statusExpressions);
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}
