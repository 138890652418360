import React, { Component, CSSProperties } from "react";

import CustomSelect from "common/CustomSelect";
import { monthName } from "common/MonthData";
import axios from "../ServerConnection";
import Variables from "common/Variables";
import { strftime, strptime } from "common/utilities/TimeFormatUtils";
import { Condition, conditionsToJson } from "common/Conditions";
import _ from "lodash";
import remoteModuleId from "common/remoteModuleId";
import { NodeValue } from "common/Canvas";

export type SearchComponentValue = NodeValue | null;
export interface SearchComponentOption {
    label: string;
    value: SearchComponentValue;
}

interface Props {
    allowNewOption?: boolean;
    isDisabled?: boolean;
    isMulti?: boolean;
    customIndicators?: boolean;
    clearable?: boolean;
    backspaceRemovesValue?: boolean;
    filterMissing?: boolean;
    dataScopeId: number | string | undefined;
    initialValue: SearchComponentOption | null | SearchComponentOption[];
    searchIndex: number;
    onOptionSelected: (
        value: SearchComponentOption | SearchComponentOption[]
    ) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    additionalOptions?: { [key: string]: any };
    additionalStyles?: { [key: string]: any };
    onKeyDown?: (evt: any) => void;
    conditions?: Condition[];
    menuPortalTarget?: HTMLElement | null;
    currentModuleId?: number | string;
}

interface State {
    allOptions: SearchComponentOption[];
    options: SearchComponentOption[];
}

export default class SearchComponent extends Component<Props, State> {
    selectRef: React.RefObject<CustomSelect>;
    constructor(props: Props) {
        super(props);
        this.state = {
            allOptions: [],
            options: [],
        };
        this.selectRef = React.createRef();
    }

    public focus(): void {
        if (this.selectRef.current != null) this.selectRef.current.focus();
    }

    private mapValuesToOption(
        values: SearchComponentValue[]
    ): SearchComponentOption[] {
        // mainStyle.getPropertyValue("--primary-content-color")
        let variable = Variables(
            this.props.dataScopeId,
            this.props.currentModuleId ?? remoteModuleId
        ).getVariableByIndex(this.props.searchIndex);
        return values.map(
            (item: SearchComponentValue): SearchComponentOption => {
                if (item != null) {
                    if (item === "") {
                        return {
                            label: "EMPTY STRING",
                            value: "",
                        };
                    } else {
                        return {
                            label:
                                variable?.panel === "time" &&
                                variable?.level === "month"
                                    ? monthName[Number(Number(item) - 1)] ?? ""
                                    : variable?.type === "datetime"
                                    ? strftime(
                                          variable.format!,
                                          new Date(Number(item) * 1000)
                                      )
                                    : item.toString(),
                            value: item,
                        };
                    }
                } else {
                    return {
                        label: "NULL",
                        value: null,
                    };
                }
            }
        );
    }

    private loadOptions(input: string): void {
        let variable = Variables(
            this.props.dataScopeId,
            this.props.currentModuleId ?? remoteModuleId
        ).getVariableByIndex(this.props.searchIndex);
        let filteredConditions: Condition[] | undefined = undefined;
        if (this.props.conditions != null) {
            filteredConditions = this.props.conditions.filter(
                (condition) =>
                    condition.variable != null &&
                    condition.operation != null &&
                    condition.value != null
            );
        }
        axios
            .post<{
                success: boolean;
                error_msg: string;
                items?: SearchComponentValue[];
            }>("/api/e/autocomplete", {
                variable_index: this.props.searchIndex ?? variable?.index,
                starts_with: input,
                data_table_idx: this.props.dataScopeId,
                filter_missing: this.props.filterMissing,
                conditions:
                    filteredConditions != null
                        ? conditionsToJson(filteredConditions)
                        : undefined,
                module_id: this.props.currentModuleId ?? remoteModuleId,
            })
            .then((response) => {
                if (response.data.success && response.data.items != null) {
                    let allOptions = this.mapValuesToOption(
                        response.data.items
                    );
                    this.setState({
                        allOptions: allOptions,
                        options: allOptions.slice(0, 100),
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private filterOptions(input: string): void {
        this.setState((state) => ({
            options: state.allOptions
                .filter((item: SearchComponentOption) =>
                    item.label.toLowerCase().startsWith(input.toLowerCase())
                )
                .slice(0, 100),
        }));
    }

    private inputChange(input: string): void {
        this.filterOptions(input);
    }

    private onChange(optionSelected: SearchComponentOption): void {
        this.props.onOptionSelected(optionSelected);
    }

    componentDidMount(): void {
        this.loadOptions("");
    }

    componentDidUpdate(prevProps: Props): void {
        if (
            prevProps.dataScopeId !== this.props.dataScopeId ||
            prevProps.searchIndex !== this.props.searchIndex ||
            !_.isEqual(prevProps.conditions, this.props.conditions)
        ) {
            this.loadOptions("");
        }
    }

    render() {
        return (
            <div>
                <CustomSelect
                    ref={this.selectRef}
                    {...this.props.additionalOptions}
                    isCreatable={this.props.allowNewOption}
                    onCreateOption={(option: string) => {
                        let variable = Variables(
                            this.props.dataScopeId,
                            this.props.currentModuleId ?? remoteModuleId
                        ).getVariableByIndex(this.props.searchIndex);

                        if (
                            variable?.panel === "time" &&
                            variable?.derived_from === null
                        ) {
                            let time = strptime(variable.format!, option);
                            if (time != null) {
                                let newOption = {
                                    label: option,
                                    value: time.getTime() / 1000,
                                };
                                this.onChange(newOption);
                            }
                        } else {
                            let newOption = {
                                label: option,
                                value: option,
                            };
                            this.onChange(newOption);
                        }
                    }}
                    isDisabled={this.props.isDisabled}
                    isMulti={this.props.isMulti}
                    customIndicators={this.props.customIndicators}
                    backspaceRemovesValue={this.props.backspaceRemovesValue}
                    onFocus={() => {
                        if (this.props.onFocus != null) this.props.onFocus();
                    }}
                    onBlur={() => {
                        if (this.props.onBlur != null) this.props.onBlur();
                    }}
                    onKeyDown={this.props.onKeyDown}
                    value={this.props.initialValue}
                    styles={{
                        control: (provided: CSSProperties): any => ({
                            ...provided,
                            borderRadius: "4px",
                            borderColor: "transparent",
                            "&:hover": {
                                borderColor: "rgb(204, 204, 204)",
                            },
                        }),
                        indicatorSeparator: (
                            provided: CSSProperties
                        ): CSSProperties => ({
                            ...provided,
                            display: "none",
                        }),
                    }}
                    placeholder={`Select ${
                        Variables(
                            this.props.dataScopeId,
                            this.props.currentModuleId ?? remoteModuleId
                        ).getVariableByIndex(this.props.searchIndex)?.name
                    }`}
                    options={this.state.options}
                    onInputChange={(input: string) => this.inputChange(input)}
                    onChange={(option: any) =>
                        this.onChange(option as SearchComponentOption)
                    }
                    theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                    menuPortalTarget={this.props.menuPortalTarget}
                />
            </div>
        );
    }
}
