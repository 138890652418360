import React from "react";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";

// interface CovidOptions {
//     columnName: string;
//     rowNumber: string;
// }

export default class COVID extends React.Component<APIComponentProps, {}> {
    render() {
        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
                <div
                    className="flex-simple-column"
                    style={{ marginTop: "23px" }}
                >
                    <span className="APILabel">Filter column</span>
                    <input
                        style={{
                            width: 300,
                            marginTop: 4,
                        }}
                        className="textInput"
                        value={this.props.options?.columnName ?? ""}
                        onChange={(evt) => {
                            let column = evt.target.value;
                            let options = { ...this.props.options };
                            this.props.onOptionsChange({
                                ...options,
                                columnName: column,
                            });
                        }}
                    ></input>
                    <br />
                    <span className="APILabel">Filter row</span>
                    <input
                        style={{
                            width: 300,
                            marginTop: 4,
                        }}
                        className="textInput"
                        value={this.props.options?.rowNumber ?? ""}
                        onChange={(evt) => {
                            let row = evt.target.value;
                            let options = { ...this.props.options };
                            this.props.onOptionsChange({
                                ...options,
                                rowNumber: row,
                            });
                        }}
                    ></input>
                </div>
            </div>
        );
    }
}

export { COVID as MainComponent };
