import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import CollapseExtendButton from "common/CollapseExtendButton";
import ScaledPage from "common/ScaledPage";
import Instrumentation from "common/Instrumentation";
import { AppModuleProps } from "AppModule";
import GlobalContext, { GlobalContextContents } from "GlobalContext";

import { ReactComponent as DataUsageIcon } from "icons/left_nav_menu/data_usage.svg";

import styles from "./main.module.css";
import BaseSubmodule from "./common/BaseSubmodule";
import modules from "./modules/module_list.json";

interface State {
    submodules: {
        MainComponent: typeof BaseSubmodule;
        requirePermission?: string;
    }[];
    sections: boolean[];
}

class MainComponent extends Component<AppModuleProps, State> {
    private performance: Date | null;
    private mounted: boolean;

    constructor(props: AppModuleProps) {
        super(props);
        this.state = {
            submodules: [],
            sections: [],
        };

        this.performance = null;
        this.mounted = false;
        this.renderContents = this.renderContents.bind(this);
    }

    public componentDidMount(): void {
        if (this.performance != null) {
            let timeMs = new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Usage", timeMs);
        }
        let submodules: {
            MainComponent: typeof BaseSubmodule;
            requirePermission?: string;
        }[] = [];
        let sections = new Array(modules.length).fill(false);
        modules.forEach((dir) => {
            let mod = require(`./modules/${dir}/main`);
            submodules.push(mod);
        });
        this.setState({
            submodules: submodules,
            sections: sections,
        });
    }

    public componentDidUpdate(): void {
        if (this.performance != null) {
            let timeMs = new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Usage", timeMs);
        }
    }

    private renderContents(globalContext: GlobalContextContents): JSX.Element {
        if (!this.mounted) {
            this.performance = new Date();
            this.mounted = false;
        }
        let items = [];
        let submodule;
        for (let index in this.state.submodules) {
            submodule = this.state.submodules[index];
            if (
                submodule.requirePermission == null ||
                submodule.requirePermission in globalContext.permissions
            ) {
                const MainComponent = submodule.MainComponent;
                items.push(
                    <>
                        <CollapseExtendButton
                            caption={MainComponent.getTitle()}
                            expanded={this.state.sections[index]}
                            onClick={() => {
                                this.performance = new Date();
                                this.setState((state) => {
                                    let sections = new Array(
                                        modules.length
                                    ).fill(false);
                                    sections[index] = !state.sections[index];
                                    return {
                                        sections: sections,
                                    };
                                });
                            }}
                        />
                        <MainComponent visible={this.state.sections[index]} />
                    </>
                );
            }
        }
        return (
            <ScaledPage>
                <div className="content-wrapper hide-scroll">
                    <section
                        className="content"
                        style={this.props.positionStyle}
                    >
                        <Navbar className="bg-light justify-content-between">
                            {items}
                        </Navbar>
                    </section>
                </div>
            </ScaledPage>
        );
    }

    render() {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export { MainComponent };
export let route: string = "/usage.html",
    section: string = "Usage",
    leftPanelItem = {
        section: "Usage",
        href: "usage.html",
        icon: <DataUsageIcon className={styles.icon} />,
    },
    requirePermission: string = "Usage";
