import React, { Component } from "react";
import { MergeDataElement, QuestionType } from "common/Canvas";
import {
    mergeData,
    VariableJoin,
    isVariableJoinsValid,
} from "common/MergeData";
import { mainStyle } from "common/MainStyle";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import MultipleChoiceQuestionView from "./question_views/MultipleChoiceQuestionView";
import QuestionHeaderView from "./question_views/QuestionHeaderView";
import DataScopeCreatableSelectorView from "common/DataScopeCreatableSelectorView";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import { mergeOptions } from "common/MergeData";
import { Button } from "react-bootstrap";
import VariablePairQuestionView from "common/VariablePairQuestionView";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { getDataTablePermissionsApi } from "common/UserGroupsApi";
import Switch from "react-switch";
import CanvasPreventPropagationButton from "./CanvasPreventPropagationButton";
import { goToInternalLink } from "common/InternalLinksHelper";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

// Added SVG buttons
import UpButtonBig from "icons/UpButtonBig.svg";
import DownButtonBig from "icons/DownButtonBig.svg";

interface Props {
    onContextMenu: (evt: React.MouseEvent) => void;
    mergeDataElement: MergeDataElement;
    mergeDataElementId: string;
    height: number;
    live: boolean;
    frozen: boolean;
    sharedPolicy: CanvasSharedPolicy;
    onChange: (mergeDataElement: MergeDataElement) => void;
    onDelete: () => void;
    currentModuleId: number | undefined;
}

enum MergeStatus {
    NotStarted = 1,
    MergingData = 2,
    Success = 3,
    Error = 4,
}

interface State {
    step: Step;
    animationDirection: string;
    mergeMessageError: string | null;
    mergeStatus: MergeStatus;
    nameAvailable: boolean | null;
    tableHeader: string[];
    tablePreview: any[];
    dropped: number;
}
enum Step {
    Initial = 0,
    SelectLeftData = 1,
    SelectRightData = 2,
    SelectVariables = 3,
    SelectMergeType = 4,
    Preview = 5,
    Final = 6,
}

function Header(props: { frozen: boolean; onDelete: () => void }) {
    return (
        <div
            style={{
                minHeight: "90px",
                width: "100%",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
            }}
        >
            <div className="my-row" style={{ justifyContent: "space-between" }}>
                {false ? (
                    <div className="my-row" style={{ alignItems: "center" }}>
                        <img
                            alt=""
                            src={"/dist/img/data-exploration/server_purple.png"}
                        />
                        <span
                            style={{
                                marginLeft: 30,
                                fontFamily: "Roboto",
                                fontSize: mainStyle.getPropertyValue(
                                    "--path-header-size"
                                ),
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                            }}
                        >
                            {"Creating Merge Data Element"}
                        </span>
                    </div>
                ) : (
                    <div />
                )}
                {!props.frozen && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                props.onDelete();
                            }}
                        >
                            <img
                                alt=""
                                src="/dist/img/insights/insights_remove.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
            </div>
        </div>
    );
}

export default class MergeDataView extends Component<Props, State> {
    rootRef: React.RefObject<HTMLDivElement>;
    stepHistory: Step[];

    constructor(props: Props) {
        super(props);
        this.state = {
            step: Step.Initial,
            animationDirection: "top",
            mergeMessageError: null,
            mergeStatus: MergeStatus.NotStarted,
            nameAvailable: null,
            tableHeader: [],
            tablePreview: [],
            dropped: -1,
        };
        this.stepHistory = [Step.Initial];
        this.rootRef = React.createRef();
    }

    componentDidMount() {
        const dataScope = this.props.mergeDataElement.targetDataScope;
        if (dataScope != null) {
            let nameAvailable: boolean | null = null;
            if (!Number.isNaN(dataScope.value) && dataScope.value != null) {
                nameAvailable = true;
            } else {
                DataScopes.isNameAvailable(
                    this.props.mergeDataElement.targetDataScope!.label
                )
                    .then((available) => {
                        this.setState({
                            nameAvailable: available,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            nameAvailable: true,
                        });
                    });
            }
            this.setState({
                nameAvailable: nameAvailable,
            });
        }
    }

    buildHeader() {
        if (this.props.live) return <div style={{ minHeight: "90px" }} />;
        return (
            <Header
                frozen={this.props.frozen}
                onDelete={() => {
                    this.props.onDelete();
                }}
            />
        );
    }

    private mergeData(
        mergeDataElement: MergeDataElement,
        preview: boolean
    ): void {
        if (!preview) {
            this.setState({
                mergeStatus: MergeStatus.MergingData,
            });
            DataScopes.update();
            if (this.props.currentModuleId != null) {
                DataScopesForModules(this.props.currentModuleId).update();
            }
        }
        if (preview) {
            this.setState({ dropped: -1 });
        }
        mergeData(
            mergeDataElement,
            (previewData) => {
                if (!preview) {
                    this.setState({
                        mergeStatus: MergeStatus.Success,
                    });
                } else {
                    this.setState({
                        dropped: previewData!.dropped ?? -1,
                        tableHeader: previewData!.tableHeader,
                        tablePreview: previewData!.tablePreview,
                    });
                }
            },
            (errorMessage: string) => {
                if (!preview) {
                    this.setState({
                        mergeStatus: MergeStatus.Error,
                        mergeMessageError: errorMessage,
                    });
                } else this.setState({ mergeMessageError: errorMessage });
            },
            preview ? 5 : undefined,
            this.props.currentModuleId ?? remoteModuleId
        );
    }

    calculateNextStep(step: Step): Step {
        let newStep = step;
        switch (step) {
            case Step.Initial:
                newStep = Step.SelectLeftData;
                break;
            case Step.SelectLeftData:
                if (
                    this.props.mergeDataElement.leftDataScope != null &&
                    this.props.mergeDataElement.leftTableOption != null
                )
                    newStep = Step.SelectRightData;
                break;
            case Step.SelectRightData:
                if (
                    this.props.mergeDataElement.rightDataScope != null &&
                    this.props.mergeDataElement.rightTableOption != null
                )
                    newStep = Step.SelectVariables;
                break;
            case Step.SelectVariables:
                if (
                    isVariableJoinsValid(
                        this.props.mergeDataElement.variableJoins
                    )
                )
                    newStep = Step.SelectMergeType;
                break;
            case Step.SelectMergeType:
                if (this.props.mergeDataElement.mergeType != null) {
                    newStep = Step.Preview;
                }
                break;
            case Step.Preview:
                if (this.state.mergeMessageError == null) newStep = Step.Final;
                break;
            default:
                // code...
                break;
        }
        return newStep;
    }
    stepUp() {
        if (this.state.step === Step.Initial) return;
        if (this.state.step === Step.Final) {
            if (this.state.mergeStatus === MergeStatus.MergingData) return;
            this.setState({
                mergeStatus: MergeStatus.NotStarted,
                mergeMessageError: null,
            });
        }
        if (this.state.step === Step.Preview) {
            this.setState({
                mergeMessageError: null,
            });
        }
        let prevStep = this.stepHistory[this.stepHistory.length - 2];
        this.setState({ animationDirection: "bottom" }, () => {
            this.setState(
                {
                    step: prevStep!,
                },
                () => {
                    this.stepHistory.pop();
                }
            );
        });
    }
    goToInitial() {
        this.setState(
            {
                animationDirection: "bottom",
                mergeStatus: MergeStatus.NotStarted,
            },
            () => {
                this.setState(
                    {
                        step: Step.Initial,
                    },
                    () => {
                        this.stepHistory = [this.state.step];
                    }
                );
            }
        );
    }
    stepDown() {
        if (this.state.step === Step.Final) return;
        let nextStep = this.calculateNextStep(this.state.step);
        if (nextStep === this.state.step) return;
        if (nextStep === Step.Preview) {
            this.mergeData(this.props.mergeDataElement, true);
        }

        this.setState({ animationDirection: "top" }, () => {
            this.setState(
                {
                    step: nextStep,
                },
                () => {
                    this.stepHistory.push(nextStep);
                }
            );
        });
        this.rootRef.current?.focus();
    }

    buildLeftBar() {
        return (
            <div
                className="flex-simple-column"
                style={{
                    paddingLeft: 48,
                    minWidth: "116px",
                }}
            >
                {this.state.step > Step.Initial && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                this.stepUp();
                            }}
                        >
                            <img
                                alt=""
                                src={UpButtonBig} //"/dist/img/canvas/up_big.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
                <div style={{ flexGrow: 1 }}> </div>
                {this.state.step !== Step.Initial &&
                    this.state.step < Step.Final && (
                        <CanvasPreventPropagationButton>
                            <div
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.stepDown();
                                }}
                            >
                                <img
                                    alt=""
                                    src={DownButtonBig} //"/dist/img/canvas/down_big.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                    )}
            </div>
        );
    }

    buildInnerItem() {
        if (this.state.step === Step.Initial) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        paddingBottom: "52px",
                        paddingRight: "116px",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{ width: "100%", alignItems: "center" }}
                    >
                        <div className="flex-simple-column">
                            <span
                                style={{
                                    textAlign: "center",
                                    fontSize: mainStyle.getPropertyValue(
                                        "--primary-path-title-size"
                                    ),
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    color:
                                        dataScienceElementsStyle.primaryTextColor,
                                }}
                            >
                                {"Merge two data tables"}
                            </span>
                            <div
                                style={{
                                    marginTop: "31px",
                                    alignSelf: "center",
                                }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    if (
                                        this.props.sharedPolicy ===
                                        CanvasSharedPolicy.NotShared
                                    )
                                        this.stepDown();
                                    if (
                                        this.props.sharedPolicy ===
                                        CanvasSharedPolicy.SharedSlideUnAuth
                                    )
                                        goToInternalLink("/");
                                }}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/data/add_data_small.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <span
                                style={{
                                    alignSelf: "center",
                                    marginTop: 10,
                                    cursor: "pointer",
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    color:
                                        dataScienceElementsStyle.secondaryTextColor,
                                }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    if (
                                        this.props.sharedPolicy ===
                                        CanvasSharedPolicy.NotShared
                                    )
                                        this.stepDown();
                                    if (
                                        this.props.sharedPolicy ===
                                        CanvasSharedPolicy.SharedSlideUnAuth
                                    )
                                        goToInternalLink("/");
                                }}
                            >
                                {"Click to proceed"}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.step === Step.SelectLeftData) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Select the first dataset and table"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <DataScopeAndTableSelectorView
                        needWritePermissions={false}
                        dataScopeOption={
                            this.props.mergeDataElement.leftDataScope
                        }
                        tableOption={
                            this.props.mergeDataElement.leftTableOption
                        }
                        maxHeight={this.props.height * 0.5}
                        onChange={(
                            dataScopeOption: DataScopeOption | null,
                            tableOption: TableOption | null
                        ) => {
                            let mergeDataElement = {
                                ...this.props.mergeDataElement,
                                leftDataScope: dataScopeOption,
                                leftTableOption: tableOption,
                            };
                            this.props.onChange(mergeDataElement);
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        }
        if (this.state.step === Step.SelectRightData) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Select the second dataset and table"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <DataScopeAndTableSelectorView
                        needWritePermissions={false}
                        dataScopeOption={
                            this.props.mergeDataElement.rightDataScope
                        }
                        tableOption={
                            this.props.mergeDataElement.rightTableOption
                        }
                        maxHeight={this.props.height * 0.5}
                        onChange={(
                            dataScopeOption: DataScopeOption | null,
                            tableOption: TableOption | null
                        ) => {
                            let mergeDataElement = {
                                ...this.props.mergeDataElement,
                                rightDataScope: dataScopeOption,
                                rightTableOption: tableOption,
                            };
                            this.props.onChange(mergeDataElement);
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        }
        if (this.state.step === Step.SelectVariables) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Select the variables to merge"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <VariablePairQuestionView
                        leftDataScope={
                            this.props.mergeDataElement.leftDataScope!
                        }
                        rightDataScope={
                            this.props.mergeDataElement.rightDataScope!
                        }
                        variableJoins={
                            this.props.mergeDataElement.variableJoins
                        }
                        maxHeight={this.props.height * 0.5}
                        onChange={(variableJoins: VariableJoin[]) => {
                            let mergeDataElement = {
                                ...this.props.mergeDataElement,
                                variableJoins: variableJoins,
                            };
                            this.props.onChange(mergeDataElement);
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        }
        if (this.state.step === Step.SelectMergeType) {
            let question = {
                question: "Select merge type",
                type: QuestionType.MultipleChoice,
                options: mergeOptions.map((option) => ({
                    selected:
                        option.value ===
                        this.props.mergeDataElement.mergeType?.value,
                    option: option.label,
                })),
            };
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={question.question}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <MultipleChoiceQuestionView
                        frozen={true}
                        forceRowRepresentation={true}
                        multiselection={false}
                        live={true}
                        maxHeight={this.props.height * 0.5}
                        question={question}
                        onChange={(question) => {
                            let selectedIndex = question.options.findIndex(
                                (item: { option: string; selected: boolean }) =>
                                    item.selected
                            );
                            let mergeDataElement = Object.assign(
                                {},
                                this.props.mergeDataElement
                            );
                            mergeDataElement.mergeType =
                                mergeOptions[selectedIndex];
                            this.props.onChange(mergeDataElement);
                        }}
                    />
                </div>
            );
        }

        if (this.state.step === Step.Preview) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Preview"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <div
                        style={{
                            height: this.props.height * 0.5,
                            maxHeight: this.props.height * 0.5,
                            marginTop: "20px",
                            marginRight: "109px",
                        }}
                    >
                        <div
                            className="element"
                            style={{ overflow: "auto" }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            <AdminTableWithFullFeatures
                                tableName="Data"
                                paging={false}
                                tableHeader={this.state.tableHeader}
                                tableContent={this.state.tablePreview}
                            />
                        </div>
                        <div className="my-row" style={{ marginTop: "20px" }}>
                            {this.props.mergeDataElement.mergeType?.value ===
                                "inner" && (
                                <>
                                    <span
                                        className="content-regular-text"
                                        style={{
                                            color:
                                                dataScienceElementsStyle.primaryTextColor,
                                        }}
                                    >
                                        {"Drop missing"}
                                    </span>
                                    <div
                                        style={{ marginLeft: "5px" }}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onMouseDown={(evt: any) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Switch
                                            onChange={(checked) => {
                                                let mergeDataElement = {
                                                    ...this.props
                                                        .mergeDataElement,
                                                    dropMissingLeft: checked,
                                                    dropMissingRight: checked,
                                                };
                                                this.props.onChange(
                                                    mergeDataElement
                                                );
                                                this.mergeData(
                                                    mergeDataElement,
                                                    true
                                                );
                                            }}
                                            checked={
                                                this.props.mergeDataElement
                                                    .dropMissingLeft ||
                                                this.props.mergeDataElement
                                                    .dropMissingRight
                                            }
                                            width={40}
                                            height={20}
                                            offColor="#20293C"
                                            onColor="#20293C"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            offHandleColor="#70889E"
                                            onHandleColor="#1F8EFA"
                                        />
                                    </div>
                                    {this.state.dropped !== -1 && (
                                        <span
                                            className="content-regular-text"
                                            style={{
                                                marginLeft: "5px",
                                                color:
                                                    dataScienceElementsStyle.primaryTextColor,
                                            }}
                                        >
                                            {`${this.state.dropped} will be dropped`}
                                        </span>
                                    )}
                                </>
                            )}
                            {this.props.mergeDataElement.mergeType?.value !==
                                "inner" && (
                                <>
                                    <span
                                        className="content-regular-text"
                                        style={{
                                            color:
                                                dataScienceElementsStyle.primaryTextColor,
                                        }}
                                    >
                                        {"Drop left missing"}
                                    </span>
                                    <div
                                        style={{ marginLeft: "5px" }}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onMouseDown={(evt: any) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Switch
                                            onChange={() => {
                                                let mergeDataElement = {
                                                    ...this.props
                                                        .mergeDataElement,
                                                    dropMissingLeft: !this.props
                                                        .mergeDataElement
                                                        .dropMissingLeft,
                                                };
                                                this.props.onChange(
                                                    mergeDataElement
                                                );
                                                this.mergeData(
                                                    mergeDataElement,
                                                    true
                                                );
                                            }}
                                            checked={
                                                this.props.mergeDataElement
                                                    .dropMissingLeft
                                            }
                                            width={40}
                                            height={20}
                                            offColor="#20293C"
                                            onColor="#20293C"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            offHandleColor="#70889E"
                                            onHandleColor="#1F8EFA"
                                        />
                                    </div>
                                    <div style={{ flexGrow: 1 }} />
                                    {this.state.dropped !== -1 && (
                                        <span
                                            style={{
                                                marginLeft: "5px",
                                                color:
                                                    dataScienceElementsStyle.primaryTextColor,
                                            }}
                                            className="content-regular-text"
                                        >
                                            {`${this.state.dropped} will be dropped`}
                                        </span>
                                    )}
                                    <div style={{ flexGrow: 1 }} />
                                    <span
                                        className="content-regular-text"
                                        style={{
                                            color:
                                                dataScienceElementsStyle.primaryTextColor,
                                        }}
                                    >
                                        {"Drop right missing"}
                                    </span>
                                    <div
                                        style={{ marginLeft: "5px" }}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onMouseDown={(evt: any) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Switch
                                            onChange={() => {
                                                let mergeDataElement = {
                                                    ...this.props
                                                        .mergeDataElement,
                                                    dropMissingRight: !this
                                                        .props.mergeDataElement
                                                        .dropMissingRight,
                                                };
                                                this.props.onChange(
                                                    mergeDataElement
                                                );
                                                this.mergeData(
                                                    mergeDataElement,
                                                    true
                                                );
                                            }}
                                            checked={
                                                this.props.mergeDataElement
                                                    .dropMissingRight
                                            }
                                            width={40}
                                            height={20}
                                            offColor="#20293C"
                                            onColor="#20293C"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            offHandleColor="#70889E"
                                            onHandleColor="#1F8EFA"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.step === Step.Final) {
            let title = "";
            switch (this.state.mergeStatus) {
                case MergeStatus.NotStarted:
                    title = "Enter the name of the merged dataset";
                    break;
                case MergeStatus.MergingData:
                    title = "Merging Data...";
                    break;
                case MergeStatus.Success:
                    title = "Thank you for merging!";
                    break;
                case MergeStatus.Error:
                    title = this.state.mergeMessageError ?? "Unknown Error";
                    break;

                default:
                    // code...
                    break;
            }

            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        key={this.state.mergeStatus}
                        question={title}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <DataScopeCreatableSelectorView
                        value={
                            this.props.mergeDataElement.targetDataScope ??
                            undefined
                        }
                        maxHeight={this.props.height * 0.5}
                        onChange={(value) => {
                            let mergeDataElement = Object.assign(
                                {},
                                this.props.mergeDataElement
                            );
                            mergeDataElement.targetDataScope = value;
                            if (
                                Number.isNaN(
                                    mergeDataElement.targetDataScope.value
                                )
                            ) {
                                mergeDataElement.permissions = [];
                                DataScopes.isNameAvailable(
                                    mergeDataElement.targetDataScope.label
                                )
                                    .then((available) => {
                                        this.setState({
                                            nameAvailable: available,
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            nameAvailable: true,
                                        });
                                    });
                            } else {
                                getDataTablePermissionsApi(
                                    mergeDataElement.targetDataScope.value
                                )
                                    .then((permissions) => {
                                        let mergeDataElement = Object.assign(
                                            {},
                                            this.props.mergeDataElement
                                        );
                                        mergeDataElement.permissions = permissions;
                                        this.props.onChange(mergeDataElement);
                                    })
                                    .catch((_error) => {
                                        let mergeDataElement = Object.assign(
                                            {},
                                            this.props.mergeDataElement
                                        );
                                        mergeDataElement.permissions = [];
                                        this.props.onChange(mergeDataElement);
                                    });
                                this.setState({
                                    nameAvailable: true,
                                });
                            }
                            this.props.onChange(mergeDataElement);
                        }}
                        permissions={this.props.mergeDataElement.permissions}
                        onPermissionsChange={(newValue) => {
                            let mergeDataElement = Object.assign(
                                {},
                                this.props.mergeDataElement
                            );
                            mergeDataElement.permissions = newValue;
                            this.props.onChange(mergeDataElement);
                        }}
                        errorMessage={
                            // We shouldn't print the error message if
                            // nameAvailable is null
                            this.state.nameAvailable === false
                                ? "Error: this data set name is not available"
                                : undefined
                        }
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        }
    }
    buildBottomBar() {
        if (this.state.step < Step.Final)
            return this.state.mergeMessageError != null ? (
                <div
                    className="my-row"
                    style={{
                        alignSelf: "flex-end",
                        paddingRight: "109px",
                        marginTop: "10px",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "red",
                        }}
                    >
                        {this.state.mergeMessageError}
                    </span>
                </div>
            ) : null;
        if (this.state.step === Step.Final)
            return (
                <div
                    className="my-row"
                    style={{
                        alignSelf: "flex-end",
                        paddingRight: "109px",
                        marginTop: "10px",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            width: "200px",
                        }}
                        onClick={(evt: any) => {
                            evt.stopPropagation();
                            if (
                                this.state.mergeStatus !== MergeStatus.Success
                            ) {
                                this.mergeData(
                                    this.props.mergeDataElement,
                                    false
                                );
                            } else {
                                this.goToInitial();
                            }
                        }}
                        disabled={
                            !this.state.nameAvailable ||
                            this.state.mergeStatus === MergeStatus.MergingData
                        }
                    >
                        {this.state.mergeStatus === MergeStatus.Success
                            ? "FINISH"
                            : "MERGE DATA"}
                    </Button>
                </div>
            );
        return null;
    }
    buildContent() {
        return (
            <div
                className="my-row"
                style={{ height: "100%", width: "100%", paddingBottom: "38px" }}
            >
                {this.buildLeftBar()}
                <div className="flex-simple-column" style={{ width: "100%" }}>
                    <React.Fragment key={this.state.step}>
                        {this.buildInnerItem()}
                    </React.Fragment>
                    {this.buildBottomBar()}
                </div>
            </div>
        );
    }
    render() {
        return (
            <div
                ref={this.rootRef}
                className="dashboard-rect-canvas dashboard-rect-canvas-focus"
                tabIndex={0}
                style={{
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                }}
                onContextMenu={this.props.onContextMenu}
                onKeyDown={(evt) => {
                    if (evt.key === "ArrowDown") {
                        evt.stopPropagation();

                        this.stepDown();
                        evt.preventDefault();
                        return;
                    }
                    if (evt.key === "ArrowUp") {
                        evt.stopPropagation();

                        evt.preventDefault();
                        this.stepUp();
                        return;
                    }
                    if (evt.key === "Enter" && evt.shiftKey) {
                        evt.stopPropagation();

                        evt.preventDefault();
                        this.stepDown();
                        return;
                    }

                    let charCode = evt.key.charCodeAt(0) - "a".charCodeAt(0);
                    switch (this.state.step) {
                        case Step.SelectMergeType:
                            if (
                                charCode >= 0 &&
                                charCode <= mergeOptions.length - 1
                            ) {
                                evt.stopPropagation();
                                evt.preventDefault();
                                let mergeDataElement = {
                                    ...this.props.mergeDataElement,
                                    mergeType: mergeOptions[charCode],
                                };
                                this.props.onChange(mergeDataElement);
                            }
                            break;
                        default:
                            // code...
                            break;
                    }
                }}
            >
                <div
                    className="flex-column"
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                    {this.buildHeader()}

                    <TransitionGroup
                        style={{
                            height: "100%",
                            display: "flex",
                        }}
                    >
                        <CSSTransition
                            key={this.state.step}
                            timeout={500}
                            classNames={"journeywizard-".concat(
                                this.state.animationDirection || ""
                            )}
                        >
                            <div style={{ minWidth: "100%" }}>
                                {this.buildContent()}
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}
