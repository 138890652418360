import React, { Component } from "react";
import Draggable from "react-draggable";
import { observer } from "mobx-react";
import PinStore from "../../comments/PinStore";
import { CommentPin } from "../../comments/CanvasComments";
import CommentPopup from "../CommentPopup";
import PinInformationStore from "../../comments/PinInformationStore";
import styles from "./CommentPins.module.css";
import cx from "classnames";

interface Props {
    canvasId: number;
    moduleId: number;
    live: boolean;
    canWrite: boolean;
    scale: number;
}

interface PinProps extends Props {
    pin: CommentPin;
}

@observer
class PinUi extends Component<PinProps> {
    private drag: boolean;

    constructor(props: PinProps) {
        super(props);
        this.drag = false;
    }

    public render(): JSX.Element | null {
        let pin = this.props.pin;
        let expandedPinId = PinInformationStore.expandedPinId;
        return (
            <>
                <Draggable
                    disabled={this.props.live || !this.props.canWrite}
                    position={{
                        x: pin.x * this.props.scale,
                        y: pin.y * this.props.scale,
                    }}
                    onDrag={(_evt, _data) => {
                        this.drag = true;
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            let x = Math.max(data.x / this.props.scale, 0);
                            let y = Math.max(data.y / this.props.scale, 0);
                            PinStore(this.props.canvasId).editPin(pin.id, {
                                x: x,
                                y: y,
                            });
                        }
                    }}
                >
                    <div
                        className={cx(styles.root, "not-selectable-by-pointer")}
                    >
                        <div
                            className={styles.content}
                            style={{ transform: `scale(${this.props.scale})` }}
                            onClick={(e) => {
                                if (!this.drag) {
                                    PinInformationStore.setExpandedPinId(
                                        pin.id
                                    );
                                }
                                this.drag = false;
                            }}
                        >
                            <span className={styles.text}>{pin.id}</span>
                        </div>
                    </div>
                </Draggable>
                {expandedPinId === pin.id && (
                    <CommentPopup
                        onClose={() => {
                            PinInformationStore.setExpandedPinId(null);
                            if (PinInformationStore.newPinId === pin.id) {
                                PinStore(this.props.canvasId).deletePin(pin.id);
                            }
                            PinInformationStore.setNewPinId(null);
                        }}
                        canWrite={this.props.canWrite}
                        x={(pin.x + 50) * this.props.scale}
                        y={(pin.y + 50) * this.props.scale}
                        moduleId={this.props.moduleId}
                        canvasId={this.props.canvasId}
                        pinId={pin.id}
                    />
                )}
            </>
        );
    }
}

export default observer(function Pins(props: Props) {
    let pinUis: JSX.Element[] = [];
    for (let pin of PinStore(props.canvasId).pinsState) {
        pinUis.push(<PinUi key={pin.id} pin={pin} {...props} />);
    }
    return <>{pinUis}</>;
});