import React from "react";
import axios from "common/ServerConnection";
import { createCustomMessage } from "react-chatbot-kit";
import { AiCopilotFinding } from "common/Finding";
import { ChatbotState } from "./ChatbotTypes";

interface Props {
    setState: React.Dispatch<React.SetStateAction<ChatbotState>>;
    children: JSX.Element[];
}

// This function defines all of the actions that the chatbot can perform, and is
// passed to the MessageParser component.
const ActionProviderForFinding = (finding: AiCopilotFinding) => {
    return ({ setState, children }: Props) => {
        // This function is called by MessageParser.jsx. It's the main workhorse
        // of the chatbot that calls the backend endpoint.
        const handleMessage = (message: string) => {
            let requestJson = {
                data_table_idx: finding.config.selectedTable?.data_table_idx,
                message: message,
            };
            // Create a blank message and insert into messages list to render a blank message on user input
            let blankMessage = createCustomMessage("", "custom", {
                payload: {
                    message: "",
                },
            });
            setState((prev) => ({
                messages: [...prev.messages, blankMessage],
            }));
            
            axios
                .post<{
                    success: boolean;
                    error_msg: string;
                    message: string;
                }>("/api/e/llm_message_sql_agent", requestJson)
                .then((response) => {
                    let message = createCustomMessage(
                        response.data.message,
                        "custom",
                        {
                            payload: {
                                message: response.data.message,
                            },
                        }
                    );
                    setState((prev) => {
                        // blankMessage might not be the last message by this point so we check
                        
                        let index =
                            prev.messages.findIndex(
                                (m) => m.id === blankMessage.id
                            ) ?? prev.messages.length;
                        let messages = [...prev.messages];
                        messages[index] = message;
                        return {
                            messages: messages,
                        };
                    });
                });
        };

        return React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                actions: {
                    handleMessage,
                },
            });
        });
    };
};

export default ActionProviderForFinding;
