import React from "react";
import CompareVariablesChart from "common/graphics/v2/CompareVariablesChart";
import Finding from "common/Finding";

function MainComponent(props: {
    finding: Finding;
    selected: boolean;
    scale: number;
    onNewFinding?: (finding: Finding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
}) {
    let finding = props.finding;

    
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <CompareVariablesChart
                data={finding.content.data}
                selected={props.selected}
                scale={props.scale}
                varyByData={finding.content.varyByData}
                varyByIndices={finding.content.varyByIndices}
                additionalVaryByData={finding.content.additionalVaryByData}
                horizontalLines={finding.content.horizontalLines}
                groupNames={finding.content.groupNames}
                groupInfo={finding.content.groupInfo}
                config={finding.config}
                preview={props.preview}
                editable={props.editable}
                columnDragActive={props.columnDragActive}
                onChangeData={
                    props.onNewFinding
                        ? (data: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.content.data = data;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (config: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.config = config;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                manualTicks={true}
            />
        </div>
    );
}

export { MainComponent };
