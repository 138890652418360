import React, {useEffect, useState} from 'react';
import ShowTyping from "../components/ShowTyping"; 

import { observer } from 'mobx-react-lite';
import { store } from '../stores/AtomicStore';

import cx from "classnames";
import styles  from '../demobot.module.css';  


const CLV: React.FC = () => (
    <div className={styles.demobotAppContent}>
      <h2>Customer Lifetime Value</h2>
      <h3>Retention Strategies:</h3>
        <iframe 
          width="52%"
          height="550px"
          frameBorder={"0"}
          allowFullScreen={true}
          src="https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=Y9aLusfeTNKkL97a%2BA7pPQ">        
        </iframe>
    </div>
);

export default CLV;