import React from "react";
import { Notification } from "../../notifications/Notifications";
import styles from "./NotificationItem.module.css";
// import CurrentModulesStore from "common/CurrentModulesStore";
import { observer } from "mobx-react";
import { formatDate } from "common/utilities/FormatDate";
import NotificationDot from "common/NotificationIcon";
import { goToInternalLink } from "common/InternalLinksHelper";

interface Props {
	notification: Notification;
}

export default observer(function NotificationItem({ notification }: Props) {
	// let currentModule = CurrentModulesStore.getModule(notification.module_id);
	// // if (currentModule == null) return null;
	let hasNewComments = notification.is_new;
	return (
		<div
			style={{ position: "relative" }}
			onClick={() => {
				goToInternalLink(
					`canvas.html?current_module_id=${notification.module_id}&page_id=${notification.page_id}&canvas_id=${notification.canvas_id}&pin_id=${notification.pin_id}`
				);
			}}
		>
			{hasNewComments && (
				<NotificationDot
					hideBorder
					style={{
						top: 10,
						right: 10,
					}}
				/>
			)}
			<div className={styles.container}>
				<div className={styles.titleContainer}>
					<span>
						<span
							className={styles.mainTextStyle}
						>{`${notification.user}`}</span>
						<span className={styles.regularTextStyle}>
							{" mentioned you in "}
						</span>
						<span
							className={styles.mainTextStyle}
						>{`${notification.module_title}`}</span>
					</span>
				</div>
				<span className={styles.timeTextStyle}>
					{formatDate(notification.creation_time, "added")}
				</span>
			</div>
			<div className={styles.border} />
		</div>
	);
});
