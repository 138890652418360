import "common/styles/App.css";
import "./StoriesContent";
import React from "react";
import StoriesContent from "./StoriesContent";
import TabPage from "common/TabPage";
import { CSSTransition } from "react-transition-group";
import PagesBar from "common/insights_components/PagesBar";
import ExpandableTab from "common/insights_components/ExpandableTab";
import axios from "common/ServerConnection";
import { SocketIOInstance } from "common/ServerConnection";
import ShareLinkToast from "common/ShareLinkToast";
import {
    getStoriesApi,
    getStoryApi,
    deleteStoryApi,
    moveStoryApi,
    getAllStoryImagesApi,
} from "common/UserApi";
import ScaledPage from "common/ScaledPage";
import TabType from "common/TabType";
import PageType from "common/PageType";
import PopupType from "common/PopupType";
import Instrumentation from "common/Instrumentation";
import ls from "local-storage";
import PagesStore from "common/PagesStore";
import { Permission } from "common/Permissions";
import { goToInternalLink } from "common/InternalLinksHelper";
import { ReactComponent as MovieIcon } from "icons/left_nav_menu/movie.svg";

import styles from "./main.module.css";

function defaultState(pageId) {
    return {
        pageId: pageId,
        editedTab: "",
        pageTabs: [],
        storiesMap: {},
        sections: {},
        removingPopupShowed: false,
        removingTabId: undefined,
        editedTabId: undefined,
        showShareToast: false,
        lastLink: undefined,
    };
}

class MainComponent extends TabPage {
    constructor(props) {
        super(props);
        this.state = defaultState();
        this.lastEditNode = ls.get("lastEditNode");

        this.tabType = TabType.stories;
        this.handleStoriesChange = this.handleStoriesChange.bind(this);
        this.onTabOpen = this.onTabOpen.bind(this);
        this.onDropItem = this.onDropItem.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.fetchDataInner = this.fetchDataInner.bind(this);
        this.onStoryShare = this.onStoryShare.bind(this);
        this.onStoryOpen = this.onStoryOpen.bind(this);
        this.onStoryDelete = this.onStoryDelete.bind(this);
        this.onStoriesSwap = this.onStoriesSwap.bind(this);
        this.afterStoryUpdate = this.afterStoryUpdate.bind(this);
        this.subscribeToFeedbackUpdates = this.subscribeToFeedbackUpdates.bind(
            this
        );

        this.performance = null;
        this.mounted = false;
    }
    buildPageTab(title, tabId) {
        return (
            <ExpandableTab
                frozen={
                    PagesStore(PageType.Stories).permission(
                        this.state.pageId
                    ) === Permission.ReadOnly
                }
                acceptDrop={"story"}
                title={title}
                tabId={tabId}
                tabComponent={this}
            />
        );
    }
    subscribeToFeedbackUpdates() {
        SocketIOInstance?.on("new_story_feedbacks", (response) => {
            let cardId = response.data["card_id"];
            this.afterStoryUpdate(cardId);
        });
    }
    afterStoryUpdate(storyId) {
        getStoryApi(storyId).then((story) => {
            let stories = Array.from(this.state.storiesMap[story.tab_id] || []);
            let storiesMap = Object.assign({}, this.state.storiesMap);

            if (stories) {
                let index = stories.findIndex((element, index, array) => {
                    return String(element.id) === String(story.id);
                });
                if (index < 0) return;
                stories[index] = story;

                storiesMap[story.tab_id] = stories;
                this.setState({ storiesMap: storiesMap });
            }
        });
    }

    fetchData(tabId) {
        this.fetchDataInner(tabId, 0, 2, []);
    }
    handleStoriesChange(tabId) {
        let storiesMap = Object.assign({}, this.state.storiesMap);
        storiesMap[tabId] = [];

        this.setState({ storiesMap: storiesMap }, () => {
            this.fetchData(tabId);
        });
    }
    onTabOpen(tabId, force = false) {
        if (
            (this.state.sections[tabId] && !this.state.storiesMap[tabId]) ||
            force
        ) {
            this.fetchData(tabId);
        }
    }
    onDropItem(item, tabId) {
        let oldTabId = item.tab_id;
        moveStoryApi(item.id, undefined, tabId).then(() => {
            this.handleStoriesChange(oldTabId);
            this.handleStoriesChange(tabId);
            this.onExpandTab(tabId, true);
        });
    }
    onStoriesSwap(item, otherItem) {
        let tabId = item.tab_id;
        moveStoryApi(item.id, otherItem.id).then(() => {
            this.handleStoriesChange(tabId);
        });
    }
    onStoryShare(story) {
        let url = new URL(window.location.origin);
        url.pathname = "/shared_presentation.html";

        let storyMetaInfo = {
            creation_time: story.creation_time,
            user_data:
                story.user_data != null
                    ? {
                          first_name: story.user_data.first_name,
                          last_name: story.user_data.last_name,
                          icon_url: story.user_data.icon_url,
                      }
                    : undefined,
        };
        let storyJson = {
            title: story.title,
            cards: [],
        };
        story.insights.forEach((insight, index) => {
            let contents = insight.contents;
            // Add user data and creation time to first card
            if (index === 0) {
                contents = {
                    ...contents,
                    ...storyMetaInfo,
                };
            }
            storyJson.cards.push(JSON.stringify(contents));
        });
        let formData = new FormData();
        formData.append("metadata", JSON.stringify(storyJson));
        getAllStoryImagesApi(story)
            .then((images) => {
                for (let [i, image] of Object.entries(images)) {
                    i = String(i);
                    formData.append(i, image, i);
                }
                axios
                    .post("/api/story_add", formData, null)
                    .then((response) => {
                        if (response.data.success) {
                            url.searchParams.append(
                                "remote_storyid",
                                response.data.id
                            );
                            this.setState({
                                showShareToast: true,
                                lastLink: url.toString(),
                            });
                        } else {
                            console.log(response.data.error_msg);
                            this.setState({
                                showShareToast: true,
                                lastLink: undefined,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            showShareToast: true,
                            lastLink: undefined,
                        });
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    onStoryOpen(story) {
        if (this.lastEditNode) {
            ls.set("selectedPopup", {
                id: story.id,
                type: PopupType.Story,
                hash: String(story.id)
                    .concat("_")
                    .concat(String(PopupType.Story)),
            });
            goToInternalLink("/canvas.html");
            return;
        }
        goToInternalLink(`/presentation.html?storyid=${story.id}`);
    }
    onStoryDelete(story, tabId) {
        deleteStoryApi(story).then(() => {
            let storiesMap = Object.assign({}, this.state.storiesMap);
            let stories = Array.from(storiesMap[tabId]);

            stories = stories.filter((item) => item.id !== story.id);
            storiesMap[tabId] = stories;
            this.setState({ storiesMap: storiesMap });
        });
    }

    fetchDataInner(tabId, offset, limit, allStories) {
        getStoriesApi(tabId, offset, limit).then((stories) => {
            if (stories.length > 0) {
                allStories = allStories.concat(stories);
                let storiesMap = Object.assign({}, this.state.storiesMap);

                storiesMap[tabId] = allStories;

                this.setState({ storiesMap: storiesMap });
                this.fetchDataInner(tabId, offset + limit, limit, allStories);
            } else {
            }
        });
    }
    buildStoriesRow(tabId) {
        return (
            <div
                className="journey-column flex-simple-column"
                key={tabId}
                style={{
                    display: this.state.sections[tabId] ? "block" : "none",
                }}
            >
                <StoriesContent
                    onStoryDelete={(story, tabId) => {
                        this.performance = new Date();
                        this.onStoryDelete(story, tabId);
                    }}
                    onStoryShare={(story) => {
                        this.performance = new Date();
                        this.onStoryShare(story);
                    }}
                    onStoryOpen={(story) => {
                        this.performance = new Date();
                        this.onStoryOpen(story);
                    }}
                    onStoriesSwap={(story1, story2) => {
                        this.performance = new Date();
                        this.onStoriesSwap(story1, story2);
                    }}
                    permission={PagesStore(PageType.Stories).permission(
                        this.state.pageId
                    )}
                    tabId={tabId}
                    stories={this.state.storiesMap[tabId] || []}
                />
            </div>
        );
    }

    componentDidMount() {
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("Stories", timeMs);
        }
        this.subscribeToFeedbackUpdates();
    }

    componentWillUnmount() {}

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("Stories", timeMs);
        }
    }

    render() {
        if (!this.mounted) {
            this.performance = new Date();
            this.mounted = true;
        }
        return (
            <ScaledPage>
                <div className="content-wrapper element">
                    <section
                        className="content"
                        style={this.props.positionStyle}
                    >
                        <div style={{ marginTop: 20 }}>
                            <PagesBar
                                type={PageType.Stories}
                                frozen={false}
                                onPageChange={(pageId) => {
                                    this.setState(defaultState(pageId), () => {
                                        this.getPageTabs(pageId);
                                    });
                                }}
                            />
                        </div>
                        <div style={{ marginLeft: 30, marginRight: 20 }}>
                            {this.state.pageTabs.map((pageTab) => (
                                <div key={pageTab.id}>
                                    {this.buildPageTab(
                                        pageTab.title,
                                        pageTab.id
                                    )}
                                    <CSSTransition
                                        key={pageTab.id}
                                        timeout={500}
                                        in={this.state.sections[pageTab.id]}
                                        classNames={"journey-column"}
                                    >
                                        {this.buildStoriesRow(pageTab.id)}
                                    </CSSTransition>
                                </div>
                            ))}
                            {PagesStore(PageType.Stories).permission(
                                this.state.pageId
                            ) > Permission.ReadOnly
                                ? this.buildPageTab(this.state.editedTab)
                                : null}
                        </div>
                        {this.buildTabRemovePopup()}
                    </section>
                    {this.state.showShareToast ? (
                        <ShareLinkToast
                            value={this.state.lastLink}
                            onClosed={() => {
                                this.setState({
                                    showShareToast: false,
                                    lastLink: undefined,
                                });
                            }}
                        />
                    ) : null}
                </div>
            </ScaledPage>
        );
    }
}

export { MainComponent };
export let route = "/stories.html",
    section = "Stories",
    leftPanelItem = {
        section: "Stories",
        href: "stories.html",
        icon: <MovieIcon className={styles.icon} />,
    },
    requirePermission = "Stories";
