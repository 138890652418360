import { HistogramChartInner } from "common/graphics/HistogramChart";
import { colorList } from "common/graphics/LineColors";
import Portal from "common/Portal";
import StringUtils from "common/utilities/StringUtils";
import {
    defaultDate,
    formatTimestamp,
    strftime,
} from "common/utilities/TimeFormatUtils";
import Variables, { Variable } from "common/Variables";
import { useContext, useState } from "react";
import { Pie, PieChart } from "recharts";
import styles from "./DictionaryComponent.module.css";
import GlobalContext from "GlobalContext";
import { updateVariableDescriptionApi } from "common/MarketplaceApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";

interface Props {
    variable: Variable;
    dataTableIdx: number | string;
    position: {
        left: number;
        top: number;
    };
    onClose: () => void;
}

function customLabel(props: {
    name: string;
    percent: number;
    cx: number;
    cy: number;
}) {
    return `${props.name}: ${Math.round(props.percent * 100)}%`;
}

function getPieChart(summary: {
    count: number;
    pie: { [key: string]: number };
}) {
    let data = Object.keys(summary.pie).map((key, index) => ({
        name: key,
        value: summary.pie[key],
        fill: colorList[index % colorList.length],
    }));
    return (
        <PieChart width={250} height={200}>
            <Pie
                data={data}
                fontSize={8}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={50}
                label={customLabel as any}
            ></Pie>
        </PieChart>
    );
}

function getSummary(variable: Variable) {
    if (variable.summary == null) return null;
    if (
        variable.type === "int" ||
        variable.type === "float" ||
        variable.type === "datetime"
    ) {
        let summary = variable.summary as {
            max: number;
            min: number;
            hist: number[];
            bin_edges: number[];
            is_datetime: boolean;
        };
        return (
            <div className={styles.summaryContainer}>
                <div className={styles.title}>Summary of Variable:</div>
                <div className={styles.range}>{`The data range from ${
                    variable.type === "datetime"
                        ? formatTimestamp(summary.min, variable.format!)
                        : summary.min
                } to ${
                    variable.type === "datetime"
                        ? formatTimestamp(summary.max, variable.format!)
                        : summary.max
                }`}</div>
                <div className={styles.count}>Count</div>
                <div style={{ height: "100px" }}>
                    <HistogramChartInner
                        hideWatermark
                        data={{
                            "%hist": summary.hist as number[],
                            "%bin_edges": summary.bin_edges as number[],
                        }}
                        dateFormat={variable.format ?? undefined}
                        angle={-15}
                        chartMargin={{
                            left: 0,
                        }}
                        optionalStyles={{ paddingRight: 0 }}
                        chartColor={"#4980C3"}
                        unit={""}
                        variable={variable.name}
                    />
                </div>
            </div>
        );
    }
    if (variable.type === "str") {
        let summary = variable.summary as {
            count: number;
            pie?: { [key: string]: number };
        };
        return (
            <div className={styles.summaryContainer}>
                {summary.pie == null && (
                    <div className={styles.infoRow}>
                        <div className={styles.title}>Summary of Variable:</div>
                        <div className={styles.description}>
                            {`${summary.count} Unique Values`}
                        </div>
                    </div>
                )}
                {summary.pie != null && (
                    <>
                        <div className={styles.title}>Summary of Variable:</div>
                        <div style={{ marginTop: 10 }}>
                            {getPieChart(
                                summary as {
                                    count: number;
                                    pie: { [key: string]: number };
                                }
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

function getVariableTypeAndFormat(
    variable: Variable
): {
    type: string | undefined;
    format: string | undefined;
    level: string | undefined;
} {
    if (variable.panel === "regular" && variable.type !== "datetime") {
        if (variable.type === "str")
            return {
                type: "String",
                format: undefined,
                level: undefined,
            };
        if (variable.type === "int" || variable.type === "float") {
            return {
                type: "Number",
                format: "Number",
                level: undefined,
            };
        }
    }
    if (variable.panel === "geography") {
        return {
            type: "Geography",
            format: undefined,
            level: StringUtils.capitalize(variable.level!),
        };
    }
    if (variable.panel === "time" || variable.type === "datetime") {
        if (variable.type === "datetime")
            return {
                type: "Date",
                level: StringUtils.capitalize(variable.level!),
                format: strftime(variable.format!, defaultDate),
            };
        return {
            type: "Date",
            level: StringUtils.capitalize(variable.level!),
            format: undefined,
        };
    }
    return {
        type: undefined,
        format: undefined,
        level: undefined,
    };
}

function DictionaryComponent(props: Props) {
    let variableTypeAndFormat = getVariableTypeAndFormat(props.variable);
    let [statusPopup, setStatusPopup] = useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    const globalContext = useContext(GlobalContext);
    const updateVariableDescription = async (description: string) => {
        try {
            await updateVariableDescriptionApi(
                props.dataTableIdx,
                props.variable.index!,
                description
            );
            let variables = Variables(
                props.dataTableIdx,
                undefined,
                undefined,
                true
            );
            variables.updateDescription(props.variable.index!, description);
        } catch (error) {
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    };

    return (
        <Portal rootNode={document.body}>
            <div
                className={styles.container}
                style={{
                    ...props.position,
                    zIndex: 99999999,
                }}
                onMouseLeave={props.onClose}
            >
                <div className={styles.title} style={{ textAlign: "center" }}>
                    {props.variable.name}
                </div>
                {variableTypeAndFormat.type && (
                    <div className={styles.infoRow}>
                        <div className={styles.title}>Type:</div>
                        <div className={styles.description}>
                            {variableTypeAndFormat.type}
                        </div>
                    </div>
                )}
                {variableTypeAndFormat.format && (
                    <div className={styles.infoRow}>
                        <div className={styles.title}>Format:</div>
                        <div className={styles.description}>
                            {variableTypeAndFormat.format}
                        </div>
                    </div>
                )}
                {variableTypeAndFormat.level && (
                    <div className={styles.infoRow}>
                        <div className={styles.title}>Level:</div>
                        <div className={styles.description}>
                            {variableTypeAndFormat.level}
                        </div>
                    </div>
                )}

                <div className={styles.infoRow}>
                    <div className={styles.title}>Description:</div>
                    {"PublishToMarketPlace" in globalContext.permissions ? (
                        <textarea
                            placeholder="Description"
                            style={{
                                border: "none",
                                outline: "1px solid #CCC",
                                resize: "none",
                            }}
                            defaultValue={props.variable.description ?? ""}
                            onBlur={(evt) => {
                                if (
                                    evt.target.getAttribute("preventBlur") !==
                                    "true"
                                ) {
                                    updateVariableDescription(
                                        evt.currentTarget.value
                                    );
                                } else {
                                    evt.target.removeAttribute("preventBlur");
                                }
                            }}
                            onKeyDown={(evt) => {
                                if (evt.key === "Enter" && !evt.shiftKey) {
                                    evt.preventDefault();
                                    updateVariableDescription(
                                        evt.currentTarget.value
                                    );
                                    evt.currentTarget.setAttribute(
                                        "preventBlur",
                                        "true"
                                    );
                                    evt.currentTarget.blur();
                                }
                            }}
                            className={styles.description}
                        />
                    ) : (
                        <div
                            className={styles.description}
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {props.variable.description ?? ""}
                        </div>
                    )}
                </div>
                {getSummary(props.variable)}
            </div>
            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </Portal>
    );
}

export default DictionaryComponent;
