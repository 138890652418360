import "common/styles/App.css";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
	Footer,
	FinalStepBottomBar,
} from "../../common/JourneyFunctions";
import BaseJourney from "../../common/BaseJourney";

import CodeNotebookComponent from "common/CodeNotebookComponent";
import { mainStyle } from "common/MainStyle";

const lastStepNumber = 1;

class MainComponent extends BaseJourney {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			previousStep: 0,
			step: 0,
			animationDirection: "top",
		};
		this.back = this.back.bind(this);
		this.saveFinding = this.saveFinding.bind(this);
	}

	back() {
		this.setState({ animationDirection: "bottom" }, () => {
			this.setState({
				step: this.state.previousStep,
			});
		});
	}

	buildContent() {
		switch (this.state.step) {
			case 0:
				return this.buildStep0();
			case 1:
				return this.buildStep1();
			default:
				return <div />;
		}
	}
	saveFinding() {
		this.refs.codeNotebookComponent &&
			this.refs.codeNotebookComponent.saveFirstFinding();
	}

	componentDidMount() {}

	stepDown() {
		this.setState({ animationDirection: "top" }, () => {
			this.setState({
				previousStep: this.state.step,
				step: this.state.step + 1,
			});
		});
	}

	stepUp() {
		let step = this.state.step;

		if (step > 0)
			this.setState({ animationDirection: "bottom" }, () => {
				this.setState({
					step: step - 1,
				});
			});
	}

	getFooterTitle() {
		return "";
	}

	buildStep0() {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
				}}
			>
				<div className="flex-simple-column">
					<span
						className="exploration-big-title-span"
						style={{
							alignSelf: "center",
						}}
					>
						Execute python code
					</span>
					<div
						className="my-row"
						style={{
							marginTop: 10,
							alignSelf: "flex-end",
							alignItems: "center",
						}}
					>
						<span
							style={{
								cursor: "pointer",
								fontFamily: "Roboto",
								fontSize: "12px",
								lineHeight: "14px",
								color: mainStyle.getPropertyValue(
									"--exploration-secondary-text-color"
								),
								fontWeight: 700,
							}}
							onClick={() => {
								this.stepDown();
							}}
						>
							CLICK to continue
						</span>
						<img
							style={{ marginLeft: 10 }}
							alt=""
							src="/dist/img/data-exploration/chevron_transformed.png"
						/>
					</div>
				</div>
			</div>
		);
	}

	buildStep1() {
		return (
			<div
				className="element"
				style={{
					width: "100%",
					height: "30em",
					minHeight: "30em",
					overflowY: "auto",
				}}
				onKeyDown={(evt) => {
					evt.stopPropagation();
				}}
			>
				<CodeNotebookComponent
					showExamples
					onNewFinding={this.props.onNewFinding}
					tag="diyJourney"
					ref="codeNotebookComponent"
				/>
				<div>
					<div style={{ float: "right", marginRight: 100 }}>
						<FinalStepBottomBar onSaveFinding={this.saveFinding} />
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div
				className="dashboard-rect-journey-focus"
				tabIndex="0"
				style={{
					height: 520,
					overflow: "hidden",
					display: !this.props.collapsed ? "block" : "none",
				}}
				onClick={() => {
					if (this.state.step === 0) this.stepDown();
				}}
				onKeyDown={(evt) => {
					if (evt.key === "Escape") {
						this.props.onClose();
					}
					if (evt.key === "ArrowDown") {
						if (
							this.state.step > 0 &&
							this.state.step < lastStepNumber
						)
							this.stepDown();
						evt.preventDefault();
					}
					// if (evt.key === "ArrowLeft") {
					// 	if (this.state.step === lastStepNumber) {
					// 		this.back();
					// 	}
					// }
					// if (evt.key === "ArrowRight") {
					// 	this.showFinalStep();
					// }
					if (evt.key === "ArrowUp") {
						if (this.state.step < lastStepNumber) this.stepUp();
						evt.preventDefault();
					}
					if (evt.key === "p") {
						if (this.state.step === lastStepNumber) {
							this.saveFinding();
						}
					}

					// if (evt.key === "a") {
					// 	if (this.state.step === 1) {
					// 		this.selectVariable(
					// 			"aggregateVariable",
					// 			"aggregateVariables",
					// 			0
					// 		);
					// 	}
					// 	if (this.state.step === 2) {
					// 		this.selectVariable(
					// 			"targetVariable",
					// 			"targetVariables",
					// 			0
					// 		);
					// 	}
					// 	if (this.state.step === 3) {
					// 		this.selectVariable(
					// 			"additionalVariable",
					// 			"additionalVariables",
					// 			0
					// 		);
					// 	}
					// }
					// if (evt.key === "b") {
					// 	if (this.state.step === 1) {
					// 		this.selectVariable(
					// 			"aggregateVariable",
					// 			"aggregateVariables",
					// 			1
					// 		);
					// 	}
					// 	if (this.state.step === 2) {
					// 		this.selectVariable(
					// 			"targetVariable",
					// 			"targetVariables",
					// 			1
					// 		);
					// 	}
					// 	if (this.state.step === 3) {
					// 		this.selectVariable(
					// 			"additionalVariable",
					// 			"additionalVariables",
					// 			1
					// 		);
					// 	}
					// }
					// if (evt.key === "c") {
					// 	if (this.state.step === 2) {
					// 		this.selectVariable(
					// 			"targetVariable",
					// 			"targetVariables",
					// 			2
					// 		);
					// 	}
					// }
				}}
			>
				<div
					className="my-row"
					style={{ justifyContent: "space-between", height: "100%" }}
				>
					<div
						className="flex-column"
						style={{
							height: "100%",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						{this.buildHeader()}

						<TransitionGroup
							style={{
								minHeight: "inherit",
							}}
						>
							<CSSTransition
								style={{
									height: "100%",
								}}
								key={this.state.step}
								timeout={500}
								classNames={"journeywizard-".concat(
									this.state.animationDirection || ""
								)}
							>
								<div
									style={{
										height: "100%",
									}}
								>
									{this.buildContent()}
								</div>
							</CSSTransition>
						</TransitionGroup>

						{<Footer title={this.getFooterTitle()} />}
					</div>
					{/*this.buildRightBar()*/}
				</div>
			</div>
		);
	}
}

export { MainComponent };