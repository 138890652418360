import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props) {
        let { data, config } = props;
        let item = {
            type: "16",
            content: {
                data: data,
            },
            config: config,
        };
        return item;
    }
    static getData(config, findingOptions, moduleId) {
        let {
            dataScope,
            conditions,
            count,
            selectedVariableIndex,
            wordLevel,
            removeStopWords,
        } = config;

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let requestJson = {
            variable_index: selectedVariableIndex,
            table: [],
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            conditions: conditions,
            limit: count || 100,
            word_level: wordLevel,
            remove_stop_words: removeStopWords,
            module_id: moduleId,
        };
        return axios
            .post("/api/e/word_cloud", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = response.data.data;
                    let finalItem = Api.getFinding({
                        data: data,
                        config: config,
                    });
                    return Promise.resolve(finalItem);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
