import React, { Component } from "react";
import { observer } from "mobx-react";

import { SearchComponentOption } from "common/SearchComponent";
import { mainStyle } from "common/MainStyle";
import variables, { Variable } from "common/Variables";
import StringOption from "common/StringOption";
import ValueWithOperator from "common/ValueWithOperator";

interface Props {
	currentModuleId: string | number | undefined;
	dataScopeId: number | string;
	additionalParameterIndex: number;
	additionalOperators: StringOption[];
	additionalValues: SearchComponentOption[];
	title: string;
	onChange: (
		additionalOperators: StringOption[],
		additionalValues: SearchComponentOption[]
	) => void;
	hideBottomHint?: boolean;
	equalsOnly?: boolean;
}

@observer
class AdditionalParameterSelector extends Component<Props> {
	render() {
		let additionalParameterIndex: number = this.props
			.additionalParameterIndex;
		let multiselection: boolean = this.props.additionalValues.length === 2;
		const variableInfo: Variable = variables(this.props.dataScopeId, this.props.currentModuleId)
			.dataVariables[additionalParameterIndex];
		if (variableInfo == null) return null;
		const variableName: string = variableInfo.name;
		const numeric: boolean =
			variableInfo.type === "float" || variableInfo.type === "int";
		return (
			<div
				className="flex-simple-column"
				style={{ marginTop: 25, marginLeft: 100 }}
				onKeyDown={(evt) => {
					evt.stopPropagation();
				}}
			>
				<div className="my-row" style={{ alignItems: "center" }}>
					<span className="exploration-big-title-span">
						{this.props.title}
					</span>
					<ValueWithOperator
						dataScopeId={this.props.dataScopeId}
						operator={this.props.additionalOperators[0]}
						value={this.props.additionalValues[0]}
						searchKey={variableName}
						searchIndex={additionalParameterIndex}
						currentModuleId={this.props.currentModuleId}
						numeric={numeric}
						equalsOnly={this.props.equalsOnly}
						onOperatorChange={(newOperator) => {
							let additionalOperators: StringOption[] = Array.from(
								this.props.additionalOperators
							);
							additionalOperators[0] = newOperator;
							this.props.onChange(
								additionalOperators,
								this.props.additionalValues
							);
						}}
						onValueChange={(newValue) => {
							let additionalValues: SearchComponentOption[] = Array.from(
								this.props.additionalValues
							);
							additionalValues[0] = newValue;
							this.props.onChange(
								this.props.additionalOperators,
								additionalValues
							);
						}}
						style={{
							marginLeft: "1em",
						}}
					/>
					{multiselection ? (
						<>
							<span
								className="exploration-big-title-span"
								style={{
									marginLeft: 20,
								}}
							>
								and
							</span>
							<ValueWithOperator
								currentModuleId={this.props.currentModuleId}
								dataScopeId={this.props.dataScopeId}
								operator={this.props.additionalOperators[1]}
								value={this.props.additionalValues[1]}
								searchKey={variableName}
								searchIndex={additionalParameterIndex}
								numeric={numeric}
								equalsOnly={this.props.equalsOnly}
								onOperatorChange={(newOperator) => {
									let additionalOperators: StringOption[] = Array.from(
										this.props.additionalOperators
									);
									additionalOperators[1] = newOperator;
									this.props.onChange(
										additionalOperators,
										this.props.additionalValues
									);
								}}
								onValueChange={(newValue) => {
									let additionalValues: SearchComponentOption[] = Array.from(
										this.props.additionalValues
									);
									additionalValues[1] = newValue;
									this.props.onChange(
										this.props.additionalOperators,
										additionalValues
									);
								}}
								style={{
									marginLeft: "1em",
								}}
							/>
						</>
					) : null}
				</div>
				<div style={{ height: 250 }} />
				{(this.props.additionalValues[0] &&
					this.props.additionalValues[1] &&
					multiselection) ||
				(this.props.additionalValues[0] &&
					!multiselection &&
					!this.props.hideBottomHint) ? (
					<span
						style={{
							marginTop: 10,
							alignSelf: "flex-end",
							cursor: "pointer",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "14px",
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
							fontWeight: 700,
						}}
					>
						press [&darr;] Key to view results
					</span>
				) : null}
			</div>
		);
	}
}

export default AdditionalParameterSelector;
