import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="18"
			height="15"
			viewBox="0 0 18 15"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#657D95"
			{...props}
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.79114 14.3591L1 10.4389L7.93141 5.78967L16 9.79615L8.79114 14.3591Z"
				fill="#242E42"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.79114 14.3591L1 10.4389L7.93141 5.78967L16 9.79615L8.79114 14.3591Z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.79114 12.1091L1 8.18893L7.93141 3.53967L16 7.54615L8.79114 12.1091Z"
				fill="#242E42"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.79114 12.1091L1 8.18893L7.93141 3.53967L16 7.54615L8.79114 12.1091Z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.79114 9.56942L1 5.64926L7.93141 1L16 5.00647L8.79114 9.56942Z"
				fill="#242E42"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.79114 9.56942L1 5.64926L7.93141 1L16 5.00647L8.79114 9.56942Z"
			/>
		</svg>
	);
}