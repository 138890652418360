import React, { Component } from "react";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";

interface State {
	showCaptureIcon: boolean;
	x: number;
	y: number;
	width: number;
	height: number;
}

interface Props {
	width: number;
	height: number;
	zIndex: number;
	onClose: () => void;
	onCapture: (x: number, y: number, width: number, height: number) => void;
}

const windowSize = {
	width: 800,
	height: 350,
};

const overlayColor = "#333333AA";

export default class CaptureOverlay extends Component<Props, State> {
	oldWidth: number;
	oldHeight: number;
	rootRef: React.RefObject<HTMLDivElement>;
	constructor(props: Props) {
		super(props);
		this.state = {
			x: 50,
			y: 50,
			width: windowSize.width,
			height: windowSize.height,
			showCaptureIcon: true,
		};
		this.rootRef = React.createRef();
		this.oldWidth = 0;
		this.oldHeight = 0;
	}
	componentDidMount() {
		this.rootRef.current?.focus({
			preventScroll: true,
		});
	}
	render() {
		return (
			<div
				ref={this.rootRef}
				tabIndex={0}
				style={{
					outline: "none",
					position: "absolute",
					left: 0,
					top: 0,
					width: "100%",
					zIndex: this.props.zIndex,
					height: "100%",
					backgroundColor: "transparent",
				}}
				onKeyDown={(evt) => {
					evt.stopPropagation();
					if (evt.key === "Enter") {
						this.props.onCapture(
							this.state.x,
							this.state.y,
							this.state.width,
							this.state.height
						);
					}
				}}
				onMouseDown={(evt) => {
					evt.stopPropagation();
				}}
				onBlur={(evt) => {
					this.props.onClose();
				}}
			>
				<div
					style={{
						position: "absolute",
						left: 0,
						top: 0,
						width: this.state.x,
						height: this.state.y + this.state.height,
						backgroundColor: overlayColor,
					}}
				></div>
				<div
					style={{
						position: "absolute",
						left: this.state.x,
						top: 0,
						width: this.state.width,
						height: this.state.y,
						backgroundColor: overlayColor,
					}}
				></div>
				<div
					style={{
						position: "absolute",
						left: 0,
						top: this.state.y + this.state.height,
						width: "100%",
						height: `calc(100% - ${
							this.state.y + this.state.height
						}px`,
						backgroundColor: overlayColor,
					}}
				></div>
				<div
					style={{
						position: "absolute",
						left: this.state.x + this.state.width,
						top: 0,
						width: `calc(100% - ${
							this.state.x + this.state.width
						}px`,
						height: this.state.height + this.state.y,
						backgroundColor: overlayColor,
					}}
				></div>
				<Draggable
					onStart={(evt, data) => {}}
					onDrag={(evt, data) => {
						this.setState({
							x: Math.max(0, data.x),
							y: Math.max(0, data.y),
						});
					}}
					onStop={(evt, data) => {
						this.setState({
							x: Math.max(0, data.x),
							y: Math.max(0, data.y),
						});
					}}
					position={{ x: this.state.x, y: this.state.y }}
				>
					<Resizable
						enable={{
							top: true,
							right: true,
							bottom: true,
							left: true,
							topRight: true,
							bottomRight: true,
							bottomLeft: true,
							topLeft: true,
						}}
						onResizeStart={(evt) => {
							this.oldHeight = this.state.height;
							this.oldWidth = this.state.width;
							evt.stopPropagation();
						}}
						onResize={(e, direction, ref, d) => {
							let width = this.oldWidth + d.width;
							let height = this.oldHeight + d.height;
							//	width = Math.max(width, 100);
							//	height = Math.max(height, 100);
							this.setState({ width: width, height: height });
						}}
						size={{
							width: this.state.width,
							height: this.state.height,
						}}
						style={{ display: "flex" }}
					>
						{this.state.showCaptureIcon && (
							<>
								<div style={{ flexGrow: 1 }} />
								<div
									title="Capture Canvas"
									style={{
										paddingRight: "10px",
										paddingBottom: "10px",
										cursor: "pointer",
										alignSelf: "flex-end",
										backgroundColor: "transparent",
									}}
									onClick={() => {
										this.setState(
											{ showCaptureIcon: false },
											() => {
												this.props.onCapture(
													this.state.x,
													this.state.y,
													this.state.width,
													this.state.height
												);
											}
										);
									}}
								>
									<img
										alt=""
										src="/dist/img/canvas/capture_canvas.png"
										style={{ cursor: "pointer" }}
									/>
								</div>
							</>
						)}
					</Resizable>
				</Draggable>
			</div>
		);
	}
}