import React, { Component, CSSProperties } from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import { Element } from "react-scroll";
import { observer } from "mobx-react";
import "common/styles/App.css";
import { mainStyle } from "common/MainStyle";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import {
    ConditionsSelector,
    Condition,
    filterNonEmptyConditions,
} from "common/Conditions";
import Variables, { VariableOption } from "common/Variables";
import { getRawDataApi } from "common/DataApi";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { strftime } from "common/utilities/TimeFormatUtils";
import { advancedAggregationOptions as aggregationOptions } from "./AggregationOptions";
import StringOption from "common/StringOption";
import Switch from "react-switch";
import {
    DataTableInput,
    fromCanvasDataTableInputDetails,
    VariableInfo,
} from "./DataTableInputSelector";
import Tables, { TableOption } from "common/Tables";
import StringUtils from "common/utilities/StringUtils";
import { NodeLinkOption } from "common/Conditions";
import SharedBoxesStore from "./SharedBoxesStore";
import CanvasTreeStore from "./CanvasTreeStore";
import { getTargetValue, CanvasDataTableInputDetails } from "common/Canvas";
import CanvasIconsContainer from "./CanvasIconsContainer";
import { lightThemeStyle } from "common/LightThemeStyle";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import remoteModuleId from "common/remoteModuleId";
import EditInput from "./canvas_elements/NewDashboards/ChartsRibbon/DataSection/EditInput";
import GlobalInputs, { getGlobalInputValue } from "common/GlobalInputs";
import { styled } from "@mui/material";
import usePagination from "@mui/material/usePagination/usePagination";

const List = styled("ul")({
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
});

interface PaginationProps {
    total: number;
    limit: number;
    onPageChange: (offset: number) => void;
    onLimitChange: (offset: number | undefined) => void;
}

function Pagination(props: PaginationProps) {
    let { items } = usePagination({
        count: Math.ceil(props.total / props.limit),
        onChange: (event, page) => {
            props.onPageChange((page - 1) * props.limit);
        },
    });

    const options = [
        { value: 5, label: "5" },
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" },
        { value: 100, label: "100" },
    ];

    return (
        <div
            style={{
                borderTop: "1px solid #E2E2E2",
                // borderBottom: "1px solid #E2E2E2",
                width: "100%",
                minHeight: 60,
                height: 60,
                display: "flex",
                flexDirection: "row",
                position: "relative",
                alignItems: "center",
            }}
        >
            <p
                style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "40px",
                    marginBottom: 0,
                    color: lightThemeStyle.primaryTextColor,
                }}
            >
                Show entries
            </p>
            <Select
                defaultValue={options[0]}
                styles={{
                    placeholder: (base) => ({
                        ...base,
                        padding: "0px",
                    }),
                    container: (base) => ({
                        ...base,
                        marginLeft: "20px",
                        width: "85px",
                        padding: "5px",
                    }),
                }}
                options={options}
                onChange={(event) => props.onLimitChange(event?.value)}
            />
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    right: "20px",
                }}
            >
                <p
                    style={{
                        margin: "20px 30px",
                        alignItems: "center",
                        color: lightThemeStyle.primaryTextColor,
                    }}
                >
                    Showing {props.limit} of {props.total} entries
                </p>

                <nav
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <List>
                        {items.map(
                            ({ page, type, selected, ...item }, index) => {
                                let children = null;

                                if (
                                    type === "start-ellipsis" ||
                                    type === "end-ellipsis"
                                ) {
                                    children = "…";
                                } else if (type === "page") {
                                    children = (
                                        <button
                                            type="button"
                                            style={{
                                                marginRight: "5px",
                                                borderRadius: "5px",
                                                backgroundColor: selected
                                                    ? "#98A7B9"
                                                    : lightThemeStyle.backgroundColor,
                                                border: "1px solid #657D95",
                                                color: selected
                                                    ? "#ffffff"
                                                    : lightThemeStyle.contentColor,
                                            }}
                                            {...item}
                                        >
                                            {page}
                                        </button>
                                    );
                                } else {
                                    children = (
                                        <button
                                            style={{
                                                fontWeight: selected
                                                    ? "bold"
                                                    : undefined,
                                                marginRight: "5px",
                                                borderRadius: "5px",
                                                backgroundColor:
                                                    lightThemeStyle.backgroundColor,
                                                border: "1px solid #657D95",
                                            }}
                                            type="button"
                                            {...item}
                                        >
                                            {type === "previous"
                                                ? "Previous"
                                                : "Next"}
                                        </button>
                                    );
                                }

                                return <li key={index}>{children}</li>;
                            }
                        )}
                    </List>
                </nav>
            </div>
        </div>
    );
}
interface State {
    advancedOptionsExpanded: boolean;
    dataScope: DataScopeOption | undefined;
    table: TableOption | undefined;
    conditions: Condition[];
    variables: VariableInfo[] | undefined;
    currentVariable: VariableOption | null;
    currentAggregationOption: StringOption | undefined;
    rawData: { [key: string]: (string | number | null)[] } | null;
    rowId: number[];
    activateAllRows: boolean;
    alias: string;
    zeroIfNoData: boolean;
    percentile: number;
    correlationWith: VariableOption | null;
    tableOffset: number;
    tableLimit: number;
    elementsAmount: number;
    variableAlreadyExistError: boolean;
}

interface Props {
    // canvasTreeStore is a store that keeps all structures of current slide
    canvasTreeStore: CanvasTreeStore;
    // currentModuleId is current presentation id
    currentModuleId: number | undefined;
    // onReturnToCanvas called when we close section
    onReturnToCanvas: () => void;
    // lastConfiguration is last cloud storage input configuration
    lastConfiguration?: CanvasDataTableInputDetails;
    // addNewElement indicates if we add new cloud storage input or
    // edit existing
    addNewElement: boolean;
    // callback called when we click "Insert value" or "Cancel" button
    callback: (success: boolean, dataTableInput?: DataTableInput) => void;
    // dockSize is height of bottom dock
    dockSize: number;
    //to change dock size if there is no data yet
    handleDockSizeChange: any;
}

@observer
class MainComponent extends Component<Props, State> {
    dataVariablesReaction: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            advancedOptionsExpanded: false,
            alias: "Enter alias",
            activateAllRows: true,
            dataScope: undefined,
            variables: undefined,
            table: undefined,
            currentVariable: null,
            currentAggregationOption: undefined,
            conditions: ConditionsSelector.defaultValue,
            rawData: null,
            rowId: [],
            zeroIfNoData: false,
            percentile: 95,
            correlationWith: null,
            tableOffset: 0,
            tableLimit: 5,
            elementsAmount: 0,
            variableAlreadyExistError: false,
        };
        this.getData = this.getData.bind(this);
    }

    async componentDidMount() {
        if (this.props.lastConfiguration != null) {
            // Fill page according to last configuration
            try {
                let dataTableInput = (
                    await fromCanvasDataTableInputDetails(
                        [this.props.lastConfiguration],
                        this.props.currentModuleId
                    )
                )[0];

                let variableInfo = dataTableInput.variables[0];
                let currentVariable =
                    Variables(
                        dataTableInput.dataScope?.value,
                        this.props.currentModuleId ?? remoteModuleId
                    ).variableOptions.find(
                        (option) => option.value === variableInfo?.index
                    ) ?? null;
                let currentAggregationOption = aggregationOptions.find(
                    (option) => option.value === variableInfo?.aggregation
                );

                let alias = `${currentAggregationOption?.label} ${
                    currentVariable?.label ?? ""
                }`;

                let correlationWith: VariableOption | null = null;
                if (variableInfo.correlation_with_index != null) {
                    correlationWith =
                        Variables(
                            dataTableInput.dataScope?.value,
                            this.props.currentModuleId ?? remoteModuleId
                        ).variableOptions.find(
                            (option) =>
                                option.value ===
                                variableInfo?.correlation_with_index
                        ) ?? null;

                    if (correlationWith != null) {
                        alias += ` ${correlationWith.label}`;
                    }
                }
                this.setState(
                    {
                        conditions: dataTableInput.conditions,
                        variables: dataTableInput.variables,
                        activateAllRows: dataTableInput.conditions.length === 0,
                        dataScope: dataTableInput.dataScope ?? undefined,
                        table: dataTableInput.table ?? undefined,
                        currentAggregationOption: currentAggregationOption,
                        alias: this.props.addNewElement
                            ? StringUtils.normalizeString(alias).toUpperCase()
                            : variableInfo.alias,
                        currentVariable: currentVariable,
                        percentile: variableInfo.percentile ?? 95,
                        correlationWith: correlationWith,
                        zeroIfNoData: dataTableInput.zeroIfNoData ?? false,
                    },
                    () => {
                        this.getData();
                    }
                );
            } catch (e) {}
        } else {
            this.props.handleDockSizeChange(this.props.dockSize * 0.5);
        }

        //focus o first select element
        document.getElementById("select-data-scope")?.focus();
    }
    componentWillUnmount() {}

    public selectPage = (offset: number): void => {
        this.setState({
            tableOffset: offset,
        });
    };

    public changePageLimit = (limit: number | undefined): void => {
        if (limit) {
            this.setState({
                tableLimit: limit,
            });
        }
    };

    /*!
     * function getData retuns table data according to
     * selected options without any conditions
     */
    private getData(): void {
        let conditions = filterNonEmptyConditions(this.state.conditions);
        if (this.state.dataScope != null) {
            getRawDataApi(
                this.state.table ?? {
                    label: "",
                    value: [],
                    optimized: false,
                    data_table_idx: this.state.dataScope.value,
                },
                100,
                conditions,
                undefined,
                undefined,
                this.props.currentModuleId ?? remoteModuleId
            )
                .then((data) => {
                    this.setState({
                        rawData: data.currentLevels,
                        rowId: data.rowId,
                        elementsAmount: data.rowId.length,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            this.setState({
                rawData: null,
                rowId: [],
            });
        }
        document.getElementById("select-column")?.focus();
    }

    private renderDataTable(offset: number, limit: number): JSX.Element | null {
        if (
            this.state.rawData == null ||
            this.state.dataScope == null ||
            Variables(
                this.state.dataScope.value,
                this.props.currentModuleId ?? remoteModuleId
            ).dataVariables == null
        ) {
            return <div style={{ flex: 1 }} />;
        }
        let tableHeader: string[] = Variables(
            this.state.dataScope.value,
            this.props.currentModuleId ?? remoteModuleId
        ).variableNames;
        let variablesInfo = tableHeader.map((name) =>
            Variables(
                this.state.dataScope!.value,
                this.props.currentModuleId ?? remoteModuleId
            ).getVariableByName(name)
        );
        let tableContent: (string | number | null)[][] = [];

        let i = offset;
        for (i; i < offset + limit; ++i) {
            let row: (string | number | null)[] = [];
            for (let [j, varName] of tableHeader.entries()) {
                if (typeof this.state.rawData[varName] === "undefined") break;

                row.push(
                    variablesInfo[j]!.type === "datetime" &&
                        typeof this.state.rawData[varName][i] === "number"
                        ? strftime(
                              variablesInfo[j]!.format!,
                              new Date(
                                  (this.state.rawData[varName][i] as number) *
                                      1000
                              )
                          )
                        : this.state.rawData[varName][i] ||
                              (typeof this.state.rawData[varName][i] ===
                              "number"
                                  ? this.state.rawData[varName][i]
                                  : "-")
                );
            }
            tableContent.push(row);
        }

        return (
            <>
                <Pagination
                    total={this.state.elementsAmount}
                    limit={this.state.tableLimit}
                    onLimitChange={this.changePageLimit}
                    onPageChange={this.selectPage}
                />
                <div
                    style={{
                        borderBottom: "1px solid #E2E2E2",
                        borderTop: "1px solid #E2E2E2",
                        marginLeft: "17px",
                        marginRight: "17px",
                        display: "flex",
                        justifyContent: "center",
                        overflow: "hidden",
                    }}
                >
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            paddingRight: 10,
                            margin: "10px 0 10px 10px",
                            overflow: "auto",
                            backgroundColor: lightThemeStyle.backgroundColor,
                            borderRadius: "8px",
                        }}
                    >
                        <AdminTableWithFullFeatures
                            className="import-table"
                            tableName="Data"
                            highlightRows={undefined}
                            highlightCells={this.state.conditions
                                .filter(
                                    (condition) => condition.variable != null
                                )
                                .map((condition) => condition.variable!.label)}
                            highlightColumn={this.state.currentVariable?.label}
                            tableHeader={tableHeader}
                            tableContent={tableContent}
                            paging={false}
                        />
                    </Element>
                </div>
            </>
        );
    }

    private renderConditionsSelector(): JSX.Element {
        return (
            <div
                style={{
                    marginLeft: "40px",
                    marginTop: "30px",
                }}
            >
                <Switch
                    onChange={(checked) => {
                        this.setState({
                            conditions: ConditionsSelector.defaultValue,
                            activateAllRows: checked,
                        });
                    }}
                    checked={this.state.activateAllRows}
                    width={30}
                    height={15}
                    handleDiameter={13}
                    offColor={lightThemeStyle.switchOffColor}
                    onColor={lightThemeStyle.switchOnColor}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor={lightThemeStyle.switchOffHandleColor}
                    onHandleColor={lightThemeStyle.switchOnHandleColor}
                />
                <span
                    style={{
                        marginLeft: 10,
                        color: lightThemeStyle.primaryTextColor,
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        fontWeight: 700,
                    }}
                >
                    {"Activate All Rows"}
                </span>
                {!this.state.activateAllRows && (
                    <div
                        style={{
                            width: 450,
                        }}
                    >
                        <ConditionsSelector
                            currentModuleId={this.props.currentModuleId}
                            allowLinks
                            nodeLinkOptions={Array.from(
                                this.props.canvasTreeStore.canvasTreeState.values()
                            )
                                .map(
                                    (item) =>
                                        ({
                                            label: item.outerId,
                                            value: item.id,
                                            isCloneInput: false,
                                            target: getTargetValue(item),
                                        } as NodeLinkOption)
                                )
                                .concat(
                                    SharedBoxesStore.sharedBoxes(
                                        this.props.canvasTreeStore.canvasId!
                                    ).map((item) => ({
                                        label: item.label,
                                        value: item.value,
                                        isCloneInput: true,
                                        target: getTargetValue(item.item.box),
                                    }))
                                )
                                .concat(
                                    GlobalInputs.map((input) => ({
                                        label: input.label,
                                        value: input.value,
                                        isCloneInput: false,
                                        target: getGlobalInputValue(
                                            input.value,
                                            true
                                        ),
                                        isGlobalInput: true,
                                    }))
                                )}
                            key={this.state.dataScope?.value}
                            style={{
                                marginLeft: "39px",
                            }}
                            small
                            dataScopeId={this.state.dataScope?.value}
                            value={this.state.conditions}
                            title={""}
                            onChange={(conditions) => {
                                this.setState(
                                    {
                                        conditions: conditions,
                                    },
                                    this.getData
                                );
                            }}
                            allVariables={
                                Variables(
                                    this.state.dataScope?.value,
                                    this.props.currentModuleId ?? remoteModuleId
                                ).dataVariables
                            }
                            {...ConditionSelectorStyles}
                        />
                    </div>
                )}
            </div>
        );
    }

    private _onInsert = () => {
        let isDuplicateAlias = false;
        if (
            !this.state.alias ||
            this.state.dataScope == null ||
            this.state.currentVariable == null ||
            this.state.currentAggregationOption == null
        )
            return;
        const alias = StringUtils.normalizeString(
            this.state.alias
        ).toUpperCase();

        if (this.state.variables) {
            this.state.variables.forEach((variable) => {
                if (variable.alias === alias) isDuplicateAlias = true;
            });

            if (isDuplicateAlias) {
                this.setState({
                    variableAlreadyExistError: true,
                });
                return;
            }
        }

        let dataTableInput: DataTableInput = {
            dataScope: this.state.dataScope!,
            table:
                this.state.table ??
                Tables(
                    this.state.dataScope!.value,
                    this.props.currentModuleId ?? remoteModuleId
                ).tableToOption(),
            conditions: this.state.conditions,
            variables: [
                {
                    alias: alias,
                    index: this.state.currentVariable!.value,
                    aggregation: this.state.currentAggregationOption!.value,
                    percentile: this.state.percentile,
                    correlation_with_index:
                        this.state.correlationWith?.value ?? undefined,
                },
            ],
            zeroIfNoData: this.state.zeroIfNoData,
        };
        // Call callback with cloud storage input
        this.props.callback?.(true, dataTableInput);
        this.props.onReturnToCanvas();
    };

    public render(): JSX.Element {
        const dataScopes =
            this.props.currentModuleId == null
                ? DataScopes
                : DataScopesForModules(this.props.currentModuleId);
        let selectStyles = getCustomSelectStyleLight(14, false);
        let variableOptions: VariableOption[];
        let correlationVariableOptions: VariableOption[];
        if (this.state.currentAggregationOption?.value === "correlation") {
            variableOptions = Variables(
                this.state.dataScope?.value,
                this.props.currentModuleId ?? remoteModuleId
            ).variableOptions.filter(
                (item) => item.value !== this.state.correlationWith?.value
            );
            correlationVariableOptions = Variables(
                this.state.dataScope?.value,
                this.props.currentModuleId ?? remoteModuleId
            ).variableOptions.filter(
                (item) => item.value !== this.state.currentVariable?.value
            );
        } else {
            variableOptions = Variables(
                this.state.dataScope?.value,
                this.props.currentModuleId ?? remoteModuleId
            ).variableOptions;
            correlationVariableOptions = [];
        }

        return (
            <div
                onKeyDown={(evt) => {
                    switch (evt.key) {
                        case "Escape":
                            evt.stopPropagation();
                            evt.preventDefault();
                            // Call callback with success = false
                            this.props.callback?.(false);
                            this.props.onReturnToCanvas();
                            break;
                        default:
                            break;
                    }
                }}
                className="hide-scroll"
                style={{
                    backgroundColor: lightThemeStyle.backgroundColor,
                    height: this.props.dockSize,
                    paddingBottom: "41px",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        // position: "absolute",
                        margin: "0 auto",
                        backgroundColor: "#DFDFDF",
                        width: "6%",
                        height: "6px",
                        borderRadius: "0px 0px 3px 3px",
                    }}
                ></div>
                <div
                    className="flex-simple-column"
                    style={{
                        boxShadow: "none",
                        backgroundColor: lightThemeStyle.contentColor,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            backgroundColor:
                                lightThemeStyle.titleBackgroundColor,
                            display: "flex",
                            height: 48,
                            minHeight: 48,
                            borderBottom: "1px solid #E2E2E2",
                            alignItems: "center",
                            fontWeight: 700,
                        }}
                    >
                        <span
                            className="content-regular-text"
                            style={{
                                marginLeft: "24px",
                                fontSize: "18px",
                                color: lightThemeStyle.titleTextColor,
                            }}
                        >
                            CONNECT TO DATA
                        </span>
                    </div>
                    <div
                        className="my-row"
                        style={{
                            marginLeft: 39,
                            marginTop: 12,
                            alignItems: "flex-start",
                        }}
                    >
                        <div className="col">
                            <span
                                style={{
                                    color: "#333333B2",
                                    fontSize: "16px",
                                }}
                            >
                                Data set
                            </span>
                            <Select
                                data-cy="cloudstorage-select-1"
                                inputId="select-data-scope"
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"Select data set"}
                                maxMenuHeight={this.props.dockSize * 0.5}
                                styles={{
                                    valueContainer: (base) => ({
                                        ...base,
                                        paddingLeft: "20px",
                                    }),
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        width: "269px",
                                        marginTop: "8px",
                                    }),
                                    ...selectStyles,
                                }}
                                options={dataScopes.dataScopesOptions}
                                onChange={(newValue) => {
                                    if (!this.state.dataScope)
                                        this.props.handleDockSizeChange(
                                            window.innerHeight * 0.85
                                        );
                                    this.setState(
                                        {
                                            dataScope: newValue as DataScopeOption,
                                            table: Tables(
                                                (newValue as DataScopeOption)
                                                    .value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).tableToOption(),
                                            conditions:
                                                ConditionsSelector.defaultValue,
                                            currentVariable: null,
                                        },
                                        this.getData
                                    );
                                }}
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        {this.state.dataScope != null && (
                            <>
                                <div
                                    className="col"
                                    style={{
                                        marginLeft: "20px",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#333333B2",
                                        }}
                                    >
                                        Column
                                    </span>
                                    <Select
                                        inputId="select-column"
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={"Select column"}
                                        styles={{
                                            ...selectStyles,
                                            valueContainer: (base) => ({
                                                ...base,
                                                paddingLeft: "20px",
                                            }),
                                            container: (base) => ({
                                                ...base,
                                                height: "38px",
                                                width: "269px",
                                                marginTop: "10px",
                                            }),
                                        }}
                                        options={variableOptions}
                                        value={this.state.currentVariable}
                                        onChange={(newValue) => {
                                            this.setState((state) => {
                                                let newAlias = `${
                                                    state
                                                        .currentAggregationOption
                                                        ?.label ?? ""
                                                } ${
                                                    (newValue as VariableOption)
                                                        .label
                                                }`;
                                                if (
                                                    state
                                                        .currentAggregationOption
                                                        ?.value ===
                                                        "correlation" &&
                                                    state.correlationWith !=
                                                        null
                                                ) {
                                                    newAlias += ` ${state.correlationWith?.label}`;
                                                }
                                                return {
                                                    alias: StringUtils.normalizeString(
                                                        newAlias
                                                    ).toUpperCase(),
                                                    currentVariable: newValue as VariableOption,
                                                };
                                            });
                                            document
                                                .getElementById(
                                                    "select-function"
                                                )
                                                ?.focus();
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: mainStyle.getPropertyValue(
                                                    "--selectors-text-color"
                                                ),
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: "20px",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#333333B2",
                                        }}
                                    >
                                        Function
                                    </span>
                                    <Select
                                        inputId="select-function"
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder="Select function"
                                        styles={{
                                            ...selectStyles,
                                            valueContainer: (base) => ({
                                                ...base,
                                                paddingLeft: "20px",
                                            }),
                                            container: (base) => ({
                                                ...base,
                                                height: "38px",
                                                width: 269,
                                                marginTop: "10px",
                                            }),
                                        }}
                                        options={aggregationOptions}
                                        value={
                                            this.state.currentAggregationOption
                                        }
                                        onChange={(option) => {
                                            this.setState((state) => {
                                                let newAlias = `${
                                                    (option as StringOption)
                                                        .label
                                                } ${
                                                    state.currentVariable
                                                        ?.label ?? ""
                                                }`;
                                                if (
                                                    (option as StringOption)
                                                        .value ===
                                                        "correlation" &&
                                                    state.correlationWith !=
                                                        null
                                                ) {
                                                    newAlias += ` ${state.correlationWith?.label}`;
                                                }
                                                return {
                                                    alias: StringUtils.normalizeString(
                                                        newAlias
                                                    ).toUpperCase(),
                                                    currentAggregationOption: option as StringOption,
                                                };
                                            });
                                            document
                                                .getElementById("display-as")
                                                ?.focus();
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                                {this.state.currentAggregationOption?.value ===
                                    "percentile" && (
                                    <EditInput
                                        optionalStyles={{
                                            backgroundColor: mainStyle.getPropertyValue(
                                                "--selectors-background-color"
                                            ),
                                            marginLeft: 5,
                                            minHeight: 38,
                                            boxSizing: "border-box",
                                            borderRadius: "4px",
                                            borderColor: "#D1D1D1",
                                        }}
                                        optionalInputStyle={{
                                            textAlign: "center",
                                            width: "100%",
                                            color: mainStyle.getPropertyValue(
                                                "--selectors-text-color"
                                            ),
                                            fontSize: 14,
                                            fontFamily: "Roboto",
                                        }}
                                        value={this.state.percentile}
                                        showDeleteButton={false}
                                        onChange={(value) => {
                                            if (StringUtils.isNumber(value)) {
                                                value = Number(value);
                                                value = Math.max(
                                                    0,
                                                    Math.min(value, 100)
                                                );
                                                this.setState({
                                                    percentile: value,
                                                });
                                            }
                                        }}
                                    />
                                )}
                                {this.state.currentAggregationOption?.value ===
                                    "correlation" && (
                                    <>
                                        <span
                                            className="unselectable"
                                            style={{
                                                marginLeft: 30,
                                                color:
                                                    lightThemeStyle.primaryTextColor,
                                                fontSize: "14px",
                                                fontFamily: "Roboto",
                                                fontWeight: 700,
                                            }}
                                        >
                                            WITH
                                        </span>
                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={""}
                                            styles={{
                                                ...selectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    marginLeft: "30px",
                                                    height: "38px",
                                                    width: "135px",
                                                }),
                                            }}
                                            options={correlationVariableOptions}
                                            value={this.state.correlationWith}
                                            onChange={(newValue) => {
                                                this.setState((state) => ({
                                                    alias: StringUtils.normalizeString(
                                                        `${
                                                            state
                                                                .currentAggregationOption
                                                                ?.label ?? ""
                                                        } ${
                                                            state
                                                                .currentVariable
                                                                ?.label ?? ""
                                                        } ${
                                                            (newValue as VariableOption)
                                                                .label
                                                        }`
                                                    ).toUpperCase(),
                                                    correlationWith: newValue as VariableOption,
                                                }));
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: mainStyle.getPropertyValue(
                                                        "--selectors-text-color"
                                                    ),
                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </>
                                )}

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#333333B2",
                                        }}
                                    >
                                        Display as
                                    </span>
                                    <input
                                        id="display-as"
                                        onKeyDown={(evt) => {
                                            switch (evt.key) {
                                                case "Enter":
                                                    evt.stopPropagation();
                                                    evt.preventDefault();
                                                    this._onInsert();

                                                    break;
                                            }
                                        }}
                                        title="Use letters, digits or underscore"
                                        style={{
                                            ...(getCustomSelectStyleLight(
                                                14,
                                                false
                                            ).input!({}) as CSSProperties),
                                            backgroundColor: "#FFFFFF",
                                            textAlign: "left",
                                            paddingLeft: "20px",
                                            borderStyle: "solid",
                                            borderWidth: "1px",
                                            borderColor: !this.state
                                                .variableAlreadyExistError
                                                ? "#D1D1D1"
                                                : "#C20F0F",
                                            borderRadius: "5px",
                                            marginRight: "39px",
                                            width: "269px",
                                            height: "38px",
                                            outline: "none",
                                            textTransform: "uppercase",
                                            marginTop: "10px",
                                            marginLeft: 0,
                                        }}
                                        onChange={(evt) => {
                                            let value = evt.target.value.toUpperCase();
                                            this.setState({ alias: value });
                                            if (
                                                this.state
                                                    .variableAlreadyExistError
                                            ) {
                                                this.setState({
                                                    variableAlreadyExistError: false,
                                                });
                                            }
                                        }}
                                        value={this.state.alias}
                                    ></input>
                                    <span
                                        className="inputErrorMessage"
                                        style={{
                                            opacity: !this.state
                                                .variableAlreadyExistError
                                                ? 0
                                                : 1,
                                        }}
                                    >
                                        *The name is already exist
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                    {this.state.dataScope != null &&
                        this.renderConditionsSelector()}
                    <div
                        className="my-row"
                        style={{
                            cursor: "pointer",
                            marginTop: "20px",
                            marginLeft: "39px",
                            marginRight: "43px",
                            marginBottom: "10px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            maxWidth: 135,
                        }}
                        onClick={() => {
                            this.setState((state) => ({
                                advancedOptionsExpanded: !state.advancedOptionsExpanded,
                            }));
                        }}
                    >
                        {this.state.dataScope != null && (
                            <>
                                <span
                                    style={{
                                        color: this.state
                                            .advancedOptionsExpanded
                                            ? lightThemeStyle.secondaryTextColor
                                            : lightThemeStyle.primaryTextColor,
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                    }}
                                    className="unselectable"
                                >
                                    Advanced Options
                                </span>
                                <div
                                    className="unselectable"
                                    style={{
                                        cursor: "pointer",
                                        transform: this.state
                                            .advancedOptionsExpanded
                                            ? "none"
                                            : "rotate(90deg)",
                                        opacity: this.state
                                            .advancedOptionsExpanded
                                            ? 1
                                            : 0.5,
                                    }}
                                >
                                    <CanvasIconsContainer
                                        options={{
                                            fill: this.state
                                                .advancedOptionsExpanded
                                                ? lightThemeStyle.secondaryTextColor
                                                : lightThemeStyle.primaryTextColor,
                                        }}
                                        type={"Arrow"}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {this.state.advancedOptionsExpanded &&
                        this.state.dataScope && (
                            <div
                                className="my-row"
                                style={{
                                    marginLeft: 39,
                                    marginBottom: 20,
                                    alignItems: "center",
                                }}
                            >
                                <Select
                                    inputId="select-table"
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"SELECT TABLE"}
                                    styles={{
                                        ...selectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "269px",
                                            height: "38px",
                                        }),
                                    }}
                                    options={
                                        Tables(
                                            this.state.dataScope.value,
                                            this.props.currentModuleId ??
                                                remoteModuleId
                                        ).rawAndAggregateTableOptions
                                    }
                                    onChange={(newValue) => {
                                        this.setState(
                                            {
                                                table: newValue as TableOption,
                                                conditions:
                                                    ConditionsSelector.defaultValue,
                                                currentVariable: null,
                                            },
                                            this.getData
                                        );
                                    }}
                                    value={this.state.table}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                <div
                                    className="my-row"
                                    style={{
                                        marginLeft: 39,
                                        alignItems: "center",
                                    }}
                                >
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState({
                                                zeroIfNoData: checked,
                                            });
                                        }}
                                        checked={this.state.zeroIfNoData}
                                        width={24}
                                        height={10}
                                        handleDiameter={12}
                                        offColor={
                                            lightThemeStyle.switchOffColor
                                        }
                                        onColor={lightThemeStyle.switchOffColor}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor={
                                            lightThemeStyle.switchOffHandleColor
                                        }
                                        onHandleColor={
                                            lightThemeStyle.switchOnHandleColor
                                        }
                                    />
                                    <span
                                        style={{
                                            marginLeft: 10,
                                            color:
                                                lightThemeStyle.primaryTextColor,
                                            fontSize: "14px",
                                            fontFamily: "Roboto",
                                            fontWeight: 700,
                                        }}
                                    >
                                        If error, print 0
                                    </span>
                                </div>
                            </div>
                        )}
                    {this.renderDataTable(
                        this.state.tableOffset,
                        this.state.tableLimit
                    )}
                    <div style={{ flex: 1 }} />
                    <div
                        style={{
                            marginTop: "18px",
                            marginBottom: "18px",
                            marginRight: "105px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                fontSize: "12px",
                                width: "10em",
                                height: "3em",
                                fontWeight: 500,
                                paddingTop: 0,
                                paddingBottom: 0,
                                backgroundColor:
                                    lightThemeStyle.cancelButtonBackgroundColor,
                                color: lightThemeStyle.secondaryTextColor,
                                borderColor: "#E2E2E2",
                                borderRadius: "6px",
                            }}
                            onClick={() => {
                                // Call callback with success = false
                                this.props.callback?.(false);
                                this.props.onReturnToCanvas();
                            }}
                        >
                            Cancel
                        </Button>
                        {!this.state.alias ||
                        this.state.dataScope == null ||
                        this.state.currentVariable == null ||
                        this.state.currentAggregationOption == null ? (
                            <div
                                style={{ width: "156px", marginLeft: "21px" }}
                            ></div>
                        ) : (
                            <>
                                <Button
                                    type="button"
                                    className="btn btn-lg btn-primary my-primary"
                                    style={{
                                        fontSize: "12px",
                                        width: "13em",
                                        height: "3em",
                                        fontWeight: 500,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        marginLeft: "21px",
                                        borderRadius: "6px",
                                    }}
                                    disabled={
                                        !this.state.alias ||
                                        this.state.dataScope == null ||
                                        this.state.currentVariable == null ||
                                        this.state.currentAggregationOption ==
                                            null
                                    }
                                    onClick={this._onInsert}
                                >
                                    Insert Value
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export { MainComponent };
