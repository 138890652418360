import React from "react";
import Switch from "react-switch";
import { observer } from "mobx-react";
import cx from "classnames";

import {
    MapVariableOption,
    ColumnFormatType,
    TextAlign,
} from "common/Canvas";
import ColorPicker from "common/ColorPicker";
import { defaultMapColorOptions } from "modules/canvas_page/Constants";
import Select, { createFilter } from "react-select";

import styles from "./VariablesStyleConfig.module.css";
import FormatDropdown from "modules/canvas_page/FormatDropdown";
import { VariableOption } from "common/Variables";
import { getCustomSelectStyleLight } from "common/SelectStyles";

interface Props {
    isChoropleth?: boolean;
    variables: (MapVariableOption | null)[];
    onChange: (variableOptions: (MapVariableOption | null)[]) => void;
    variableOptions: VariableOption[];
}

interface TextAlignOption {
    label: TextAlign;
    value: TextAlign;
}

const ColorsSelectorView = observer(function ColorsSelectorView(props: Props) {
    let alignmentOptions = Object.values(TextAlign).map((value) => ({
        label: value,
        value: value,
    }));
    const selectStyles = getCustomSelectStyleLight(
        14,
        false
    );

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Variable</th>
                    <th>Font size</th>
                    <th>Align</th>
                    <th>Color</th>
                    {!props.isChoropleth && <th>Link text </th>}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.variables.map(
                    (value, index) =>
                        value != null && (
                            <tr key={index} className={styles.tableRow}>
                                <td className={styles.label}>
                                    {value.variable.label}
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className={styles.numberInput}
                                        onContextMenu={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        value={String(value.options.fontSize)}
                                        placeholder=""
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onChange={(e) => {
                                            let variables = Array.from(
                                                props.variables
                                            );
                                            variables[index] = {
                                                ...variables[index]!,
                                                options: {
                                                    ...variables[index]!
                                                        .options,
                                                    fontSize:
                                                        Number(
                                                            e.target.value
                                                        ) ??
                                                        defaultMapColorOptions.fontSize,
                                                },
                                            };
                                            props.onChange(variables);
                                        }}
                                    />
                                </td>
                                <td>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        styles={{
                                            ...selectStyles,
                                            control: (provided) => ({
                                                ...provided,
                                                borderRadius: 4,
                                                marginRight: 5,
                                            }),
                                            container: (base) => ({
                                                ...base,
                                                flexGrow: 1,
                                            }),
                                            indicatorSeparator: (provided) => ({
                                                ...provided,
                                                display: "none",
                                            }),
                                        }}
                                        options={alignmentOptions}
                                        value={alignmentOptions.find(
                                            (option) =>
                                                (value.options.textAlign ??
                                                    TextAlign.left) ===
                                                option.value
                                        )}
                                        onChange={(newValue) => {
                                            let variables = Array.from(
                                                props.variables
                                            );
                                            variables[index] = {
                                                ...variables[index]!,
                                                options: {
                                                    ...variables[index]!
                                                        .options,
                                                    textAlign: (
                                                        newValue as TextAlignOption
                                                    ).value,
                                                },
                                            };
                                            props.onChange(variables);
                                        }}
                                    />
                                </td>
                                <td>
                                    <ColorPicker
                                        tooltip="Font Color"
                                        enableAlpha
                                        value={value.options.fontColor}
                                        onChange={(newValue) => {
                                            let variables = Array.from(
                                                props.variables
                                            );
                                            variables[index] = {
                                                ...variables[index]!,
                                                options: {
                                                    ...variables[index]!
                                                        .options,
                                                    fontColor: newValue,
                                                },
                                            };
                                            props.onChange(variables);
                                        }}
                                        style={{
                                            alignSelf: "flex-end",
                                            marginLeft: "5px",
                                            borderRadius: "4px",
                                        }}
                                        inPopup
                                    />
                                </td>
                                {!props.isChoropleth && (
                                    <td>
                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            isClearable
                                            styles={{
                                                ...selectStyles,
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: 4,
                                                    marginRight: 5,
                                                }),
                                                container: (base) => ({
                                                    ...base,
                                                    flexGrow: 1,
                                                }),
                                                indicatorSeparator: (
                                                    provided
                                                ) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            options={props.variableOptions}
                                            value={props.variableOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    value.linkVariable?.value
                                            )}
                                            onChange={(newValue) => {
                                                let variables = Array.from(
                                                    props.variables
                                                );
                                                variables[index] = {
                                                    ...variables[index]!,
                                                    linkVariable: newValue as VariableOption,
                                                };
                                                props.onChange(variables);
                                            }}
                                        />
                                    </td>
                                )}
                                <td>
                                    <Switch
                                        onChange={(checked) => {
                                            let variables = Array.from(
                                                props.variables
                                            );
                                            variables[index] = {
                                                ...variables[index]!,
                                                options: {
                                                    ...variables[index]!
                                                        .options,
                                                    showTitle: checked,
                                                },
                                            };
                                            props.onChange(variables);
                                        }}
                                        checked={
                                            value.options.showTitle ?? true
                                        }
                                        width={26}
                                        height={13}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offColor="#C4C4C4"
                                        onColor="#3B82C9"
                                        offHandleColor="#7B7B7B"
                                        onHandleColor="#3B82C9"
                                        className={cx({
                                            [styles.switchOn]:
                                                value.options.showTitle ?? true,
                                        })}
                                    />
                                </td>
                                <td>
                                    <FormatDropdown
                                        topAppearance
                                        format={
                                            value.options.format ?? {
                                                type: ColumnFormatType.Text,
                                            }
                                        }
                                        customSpreadSheetOptions={[
                                            {
                                                label: "Text",
                                                value: ColumnFormatType.Text,
                                            },
                                            {
                                                label: "Number",
                                                value: ColumnFormatType.Number,
                                            },
                                        ]}
                                        onChangeFormat={(format) => {
                                            let variables = Array.from(
                                                props.variables
                                            );
                                            variables[index] = {
                                                ...variables[index]!,
                                                options: {
                                                    ...variables[index]!
                                                        .options,
                                                    format: format,
                                                },
                                            };
                                            props.onChange(variables);
                                        }}
                                    />
                                </td>
                            </tr>
                        )
                )}
            </tbody>
        </table>
    );
});

export default ColorsSelectorView;
