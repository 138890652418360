import React, { useEffect, useState } from "react";

import { AbstractPresentationModel } from "state/models/Presentation";
import "./TabContent.module.css";

import { Transition } from "react-transition-group";
import GridView from "./GridView";
import ListView from "./ListView";
import ViewMode from "../ViewMode";
import ReactTooltip from "react-tooltip";
import { Folder } from "common/FoldersApi";

interface Props {
    presentations: Array<AbstractPresentationModel>;
    folders?: Array<Folder>;
    currentFolder?: Folder;
    loading: boolean;
    showSkeleton?: boolean;
    deletePresentation: Function;
    clickPresentation: Function;
    renameFolder?: (folder: Folder) => void;
    deleteFolder?: (folder: Folder) => void;
    viewMode: ViewMode;
}

const defaultDropdownStyles = {
    transition: "opacity 500ms ease-out",
    opacity: 0,
    height: 0,
};

const dropdownTransitionStyles = {
    entering: { opacity: 1, height: 0 },
    entered: { opacity: 1, height: "auto" },
    exiting: { opacity: 0, height: 0, overflow: "hidden" },
    exited: { opacity: 0, height: 0, overflow: "hidden" },
    unmounted: { opacity: 0, height: 0 },
};

const visibleCount = 11;

function TabContent({
    presentations,
    folders,
    currentFolder,
    loading,
    showSkeleton,
    deletePresentation,
    clickPresentation,
    deleteFolder,
    renameFolder,
    viewMode,
}: Props) {
    useEffect(() => {
        ReactTooltip.rebuild();
    });
    const [viewAll, setViewAll] = useState(false);
    const visiblePresentation = viewAll
        ? presentations
        : presentations.slice(0, visibleCount);

    return (
        <>
            <Transition in={viewMode === ViewMode.Grid} timeout={500}>
                {(state) => (
                    <div
                        style={{
                            ...defaultDropdownStyles,
                            ...dropdownTransitionStyles[state],
                        }}
                    >
                        <GridView
                            presentations={visiblePresentation}
                            folders={folders}
                            currentFolder={currentFolder}
                            loading={loading}
                            showSkeleton={showSkeleton}
                            deleteFolder={deleteFolder}
                            renameFolder={renameFolder}
                            deletePresentation={deletePresentation}
                            clickPresentation={clickPresentation}
                            clickViewAll={() => setViewAll(true)}
                            showViewAll={
                                presentations.length >
                                visiblePresentation.length
                            }
                        />
                    </div>
                )}
            </Transition>
            <Transition in={viewMode === ViewMode.List} timeout={500}>
                {(state) => (
                    <div
                        style={{
                            ...defaultDropdownStyles,
                            ...dropdownTransitionStyles[state],
                        }}
                    >
                        <ListView
                            presentations={presentations}
                            loading={loading}
                            clickPresentation={clickPresentation}
                        />
                    </div>
                )}
            </Transition>
            <ReactTooltip
                id="grid-view-tooltip"
                effect="solid"
                backgroundColor="#464646"
            />
        </>
    );
}

export default TabContent;
