import { NumberFormat } from "common/Canvas";
import { formatLabel } from "../TimePatternsChart/TimeChartD3";

export function clipText(
    str: string,
    textWidth: number,
    maxWidth: number,
    postfix: string
) {
    let text = "";
    if (textWidth > maxWidth) {
        text = str;
        const newLength = Math.round(
            text.length * (1 - (textWidth - maxWidth) / textWidth)
        );
        text = text.substring(0, newLength).trim();
        if (text !== str) {
            text += postfix;
        }
    } else {
        text = str;
    }
    return text;
}

export function getTextWidth(text: string, font: string = "16px"): number {
    var container: HTMLElement | null = document.createElement("div");
    document.body.appendChild(container);

    container.style.fontSize = font;
    container.style.position = "absolute";
    container.style.left = "-1000px";
    container.style.top = "-1000px";
    container.textContent = text;

    const width = container.clientWidth;
    document.body.removeChild(container);
    container = null;

    return width;
}

export const getLongestYAxisValue = (
    axisValues: number[],
    intervalIsSet: boolean,
    chartType?: string,
    format?: NumberFormat
): string => {
    if (axisValues.length === 0) {
        return "";
    }
    let axisVal: number = 0;
    if (format || chartType === "lineplot") {
        axisVal = axisValues.reduce((a, b) => {
            let alength = formatLabel(format, a).length;
            let blength = formatLabel(format, b).length;
            return alength > blength ? a : b;
        });
    } else {
        axisVal = axisValues.reduce((a, b) => {
            let alength = String(a).length;
            let blength = String(b).length;
            return alength > blength ? a : b;
        });
    }
    let longestValue = String(axisVal);
    const valueAfterDot = String(axisVal).split(".")[1];
    const numberDecimalPortion = valueAfterDot?.length ?? 0;
    if (numberDecimalPortion) {
        const portion = valueAfterDot.length;
        if (intervalIsSet) longestValue = axisVal.toFixed(portion);
        if (numberDecimalPortion > 1) longestValue = axisVal.toFixed(portion);
    }

    if (format || chartType === "lineplot") {
        return formatLabel(format, Number(longestValue) ?? longestValue);
    }
    return longestValue;
};

export function calculateChartColorPickerPosition(
    event: MouseEvent | React.MouseEvent<any, MouseEvent>
) {
    let y = event.clientY;
    const documentHeight = document.body.clientHeight;
    const colorPickerBottomPoint = y + 365; // 365 - color picker height
    if (colorPickerBottomPoint > documentHeight) {
        y = y - (colorPickerBottomPoint - documentHeight);
    }

    return { y };
}

export const getLongestString = (strings: string[]): string => {
    return strings.reduce((a, b) => (a.length > b.length ? a : b));
};

export const getLongestStringIndex = (strings: string[]): number => {
    if (strings.length <= 1) {
        return 0;
    }
    let index = 0;
    for (let currIndex = 1; currIndex < strings.length; ++currIndex) {
        if (strings[currIndex].length > strings[index].length) {
            index = currIndex;
        }
    }
    return index;
};
