import React, { useMemo } from "react";
import Select from "react-select";
import UserInfo from "common/UserInfo";

interface Props {
  value: (string | null)[];
  options: UserInfo[];
  onChange: (value: (string | null)[]) => void;
}

function UserSelect({ value, options, onChange }: Props) {
  const userOptions = useMemo(() => options.map((user) => ({
    label: user.user_name,
    value: user.user_name,
  })), [options]);

  return (
    <Select
      isMulti
      placeholder="Send to users"
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "4px",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        option: (provided) => ({
          ...provided,
          background: "transparent",
          "&:active": {
            background: "transparent",
          }
        })
      }}
      options={userOptions}
      onChange={(newValue) => {
        onChange(newValue.map(v => v?.value ?? null));
      }}
      value={value?.map((u) => userOptions.find(uo => uo.value === u)) ?? []}
    />
  );
}

export default UserSelect;
