import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { LeadersLaggersFinding } from "common/Finding";
import { Condition } from "common/Conditions";
import Variables from "common/Variables";
import ViewOption from "common/graphics/ViewOption";
import { defaultFontFamily } from "modules/canvas_page/Constants";
import remoteModuleId from "common/remoteModuleId";

class Api {
    static getDefaultConfig(
        journeyName: string
    ): LeadersLaggersFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            count: 10,
            viewOption: ViewOption.Both,
            targetVariableSubTitle: "",
            shownVariableIndices: [],
            shownVariableFormats: [],
            shownVariableSubTitles: [],
            variableAliases: {},
            colorMapping: {
                Leaders: "#39F",
                Laggers: "#EE423D",
            },
            aliaseTextColor: {
                Leaders: "#000",
                Laggers: "#000",
            },
            fontStyle: {
                title: {
                    fontSize: 12,
                    color: "#3399ff",
                    fontFamily: defaultFontFamily.value,
                },
                text: {
                    fontSize: 12,
                    color: "#000",
                    fontFamily: defaultFontFamily.value,
                },
                aliase: {
                    fontSize: 10,
                },
            },
            showRankNumber: true,
            showLegend: true,
        };
    }

    static getPreviewFinding(journeyName: string): LeadersLaggersFinding {
        let item = {
            type: "leaderslaggers",
            content: {
                data: {
                    leaders: new Array(10).fill({ preview: "variable" }),
                    laggers: new Array(10).fill({ preview: "variable" }),
                    shownVariableIndices: [null],
                    showMap: false,
                },
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getFinding(
        props: {
            leaders?: { [key: number]: string | number | null }[];
            laggers?: { [key: number]: string | number | null }[];
            compareInfo?: LeadersLaggersFinding["content"]["data"]["compareInfo"];
            showMap: boolean;
            config: LeadersLaggersFinding["config"];
        },
        moduleId?: number
    ): LeadersLaggersFinding {
        let { leaders, laggers, compareInfo, showMap, config } = props;
        let dataTableIdx =
            config.dataScope == null ? 0 : config.dataScope.value;
        let units = Variables(
            dataTableIdx,
            moduleId ?? remoteModuleId
        ).getVariableByIndex(config.targetVariableIndex)?.unit;

        let item: LeadersLaggersFinding;
        if (compareInfo != null) {
            item = {
                type: "leaderslaggersi",
                content: {
                    data: {
                        geographyLevels: config.geographyLevels,
                        compareInfo: compareInfo,
                        units: units,
                        showMap: showMap,
                    },
                },
                config: config,
            };
        } else {
            item = {
                type: "leaderslaggers",
                content: {
                    data: {
                        geographyLevels: config.geographyLevels,
                        leaders: leaders,
                        laggers: laggers,
                        units: units,
                        showMap: showMap,
                        frozen: true,
                    },
                },
                config: config,
            };
        }
        return item;
    }

    static async getData(
        finding: LeadersLaggersFinding,
        _findingOptions: any,
        moduleId?: number
    ): Promise<LeadersLaggersFinding> {
        let {
            dataScope,
            targetVariableIndex,
            additionalParameterIndex,
            aggregateVariableIndex,
            additionalParameter,
            leftAdditionalOperator,
            rightAdditionalOperator,
            leftAdditionalValue,
            rightAdditionalValue,
            selectedTable,
            count,
            viewOption,
            sortBy,
            sortBySecondary,
            sortByAscDesc,
            sortByAscDescSecondary
        } = finding.config;
        const sortByVariableIdx = sortBy?.value;
        const sortByVariableIdx2 = sortBySecondary?.value;
        const sortByVariableAscDesc = sortByAscDesc?.value;
        const sortByVariableAscDesc2 = sortByAscDescSecondary?.value;

        let shownVariableIndices: number[] =
            finding.config.shownVariableIndices?.filter(
                (index: number | null) => index != null
            ) ?? [];

        if (
            sortByVariableIdx &&
            !shownVariableIndices.includes(targetVariableIndex)
        ) {
            shownVariableIndices.push(targetVariableIndex);
        }

        leftAdditionalOperator = leftAdditionalOperator ?? {
            label: "=",
            value: "=",
        };
        rightAdditionalOperator = rightAdditionalOperator ?? {
            label: "=",
            value: "=",
        };
        let conditions: Condition[] = finding.config.conditions;
        conditions = conditions?.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        let conditionsJson =
            conditions != null ? conditionsToJson(conditions) : undefined;
        let dataScopeValue =
            typeof dataScope === "undefined" ? 0 : dataScope.value;

        if (
            !additionalParameter ||
            !leftAdditionalValue ||
            !rightAdditionalValue
        ) {
            let requestJson = {
                measured_by_index: sortByVariableIdx ?? targetVariableIndex,
                map_group_index: aggregateVariableIndex,
                variable_indices: shownVariableIndices,
                count: count ?? 5,
                data_table_idx: dataScopeValue,
                table: selectedTable.value,
                condition_id: selectedTable.condition_id,
                conditions: conditionsJson,
                module_id: moduleId,
                view_option: viewOption,
                leaders_order_by_index: sortByVariableIdx,
                laggers_order_by_index: sortByVariableIdx,
                leaders_order_by_index_secondary: sortByVariableIdx2,
                laggers_order_by_index_secondary: sortByVariableIdx2,
                leaders_order_by_asc_desc: (sortBy?.type === 'str')? sortByVariableAscDesc : "desc",
                laggers_order_by_asc_desc: (sortBy?.type === 'str')? sortByVariableAscDesc : "asc",
                leaders_order_by_asc_desc_secondary: (sortBySecondary?.type === 'str')? sortByVariableAscDesc2 : "desc",
                laggers_order_by_asc_desc_secondary: (sortBySecondary?.type === 'str')? sortByVariableAscDesc2 : "asc",
            };
            return axios
                .post<{
                    success: boolean;
                    error_msg: string;
                    leaders: { [key: string]: string | number | null }[];
                    laggers: { [key: string]: string | number | null }[];
                    variable_indices: number[];
                    show_map: boolean;
                }>("/api/e/get_leaders_and_laggers", requestJson)
                .then((response) => {
                    if (response.data.success) {
                        return Promise.resolve(
                            Api.getFinding(
                                {
                                    leaders: response.data.leaders,
                                    laggers: response.data.laggers,
                                    showMap: response.data.show_map,
                                    config: finding.config,
                                },
                                moduleId
                            )
                        );
                    } else {
                        console.log(response.data.error_msg);
                        return Promise.reject(response.data.error_msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    return Promise.reject(error);
                });
        } else {
            let requestJson1 = {
                measured_by_index: sortBy?.value ?? targetVariableIndex,
                map_group_index: aggregateVariableIndex,
                variable_indices: shownVariableIndices,
                count: count ?? 5,
                view_option: viewOption,
                where: {
                    group_index: additionalParameterIndex,
                    operation: leftAdditionalOperator.value,
                    value: leftAdditionalValue.value,
                },
                data_table_idx:
                    typeof dataScope === "undefined" ? 0 : dataScope.value,
                table: selectedTable.value,
                condition_id: selectedTable.condition_id,
                conditions: conditionsJson,
                module_id: moduleId,
            };
            let requestJson2 = {
                measured_by_index: sortBy?.value ?? targetVariableIndex,
                map_group_index: aggregateVariableIndex,
                variable_indices: shownVariableIndices,
                count: count ?? 5,
                view_option: viewOption,
                data_table_idx:
                    typeof dataScope === "undefined" ? 0 : dataScope.value,
                where: {
                    group_index: additionalParameterIndex,
                    operation: rightAdditionalOperator.value,
                    value: rightAdditionalValue.value,
                },
                table: selectedTable.value,
                condition_id: selectedTable.condition_id,
                conditions: conditionsJson,
                module_id: moduleId,
            };
            return axios
                .all([
                    axios.post<{
                        success: boolean;
                        error_msg: string;
                        leaders: { [key: number]: string | number | null }[];
                        laggers: { [key: number]: string | number | null }[];
                        variable_indices: number[];
                        show_map: boolean;
                    }>("/api/e/get_leaders_and_laggers", requestJson1),
                    axios.post<{
                        success: boolean;
                        error_msg: string;
                        leaders: { [key: number]: string | number | null }[];
                        laggers: { [key: number]: string | number | null }[];
                        variable_indices: number[];
                        show_map: boolean;
                    }>("/api/e/get_leaders_and_laggers", requestJson2),
                ])
                .then(
                    axios.spread((res1, res2) => {
                        if (res1.data.success && res2.data.success) {
                            return Promise.resolve(
                                Api.getFinding(
                                    {
                                        config: finding.config,
                                        compareInfo: [
                                            {
                                                group: additionalParameter,
                                                operator:
                                                    leftAdditionalOperator.label,
                                                label:
                                                    leftAdditionalValue.label,
                                                leaders: res1.data.leaders,
                                                laggers: res1.data.laggers,
                                            },
                                            {
                                                group: additionalParameter,
                                                operator:
                                                    rightAdditionalOperator.label,
                                                label:
                                                    rightAdditionalValue.label,
                                                leaders: res2.data.leaders,
                                                laggers: res2.data.laggers,
                                            },
                                        ],
                                        showMap: res1.data.show_map,
                                    },
                                    moduleId
                                )
                            );
                        } else {
                            console.log(res1.data.error_msg);
                            console.log(res2.data.error_msg);
                            return Promise.reject(res1.data.error_msg);
                        }
                    })
                )
                .catch((error) => {
                    return Promise.reject(error);
                });
        }
    }
}

export { Api };
