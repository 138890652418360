import React, { Component } from "react";
import { observer } from "mobx-react";

import { SearchComponentOption } from "common/SearchComponent";
import { mainStyle } from "common/MainStyle";
import variables, { Variable } from "common/Variables";
import StringOption from "common/StringOption";
import ValueWithOperator from "common/ValueWithOperator";
import { Button } from "react-bootstrap";

interface Props {
	dataScopeId: number | string;
	currentModuleId: string | number | undefined;
	additionalParameterIndex: number;
	additionalOperators: StringOption[];
	additionalValues: (SearchComponentOption | null)[];
	title: string;
	onChange: (
		additionalOperators: StringOption[],
		additionalValues: (SearchComponentOption | null)[]
	) => void;
	hideBottomHint?: boolean;
	equalsOnly?: boolean;
}

@observer
class MultiAdditionalParameterSelector extends Component<Props> {
	render() {
		let additionalParameterIndex = this.props.additionalParameterIndex;
		let additionalValues = Array.from(this.props.additionalValues);
		const variableInfo: Variable = variables(this.props.dataScopeId, this.props.currentModuleId)
			.dataVariables[additionalParameterIndex];
		if (variableInfo == null)
			return null;
		const variableName: string = variableInfo.name;
		const numeric: boolean =
			variableInfo.type === "float" || variableInfo.type === "int";
		return (
			<div
				className="flex-simple-column"
				style={{ marginTop: 25, marginLeft: 100 }}
				onKeyDown={(evt) => {
					evt.stopPropagation();
				}}
			>
				<span className="exploration-big-title-span">{this.props.title}</span>
				{additionalValues.map((value, index) => (
					<div className="my-row" key={index}>
						<ValueWithOperator
							currentModuleId={this.props.currentModuleId}
							dataScopeId={this.props.dataScopeId}
							operator={this.props.additionalOperators[index]}
							value={value}
							searchKey={variableName}
							searchIndex={additionalParameterIndex}
							numeric={numeric}
							equalsOnly={this.props.equalsOnly}
							onOperatorChange={(newOperator) => {
								let additionalOperators: StringOption[] = Array.from(
									this.props.additionalOperators
								);
								additionalOperators[index] = newOperator;
								this.props.onChange(
									additionalOperators,
									this.props.additionalValues
								);
							}}
							onValueChange={(newValue) => {
								let additionalValues: (SearchComponentOption | null)[] = Array.from(
									this.props.additionalValues
								);
								additionalValues[index] = newValue;
								this.props.onChange(
									this.props.additionalOperators,
									additionalValues
								);
							}}
							style={{
								marginLeft: "1em",
								marginTop: "5px",
							}}
							clearable={true}
						/>

						<div
							className="flex-simple-column"
							style={{ marginLeft: 5, marginTop: 5 }}
						>
							<Button
								title={"Add value"}
								className="btn-small-like-select"
								style={{
									width: "19px",
									height: "19px",
								}}
								onClick={() => {
									let additionalValues = Array.from(
										this.props.additionalValues
									);

									additionalValues.splice(index + 1, 0, null);
									let additionalOperators = Array.from(
										this.props.additionalOperators
									);
									additionalOperators.splice(index + 1, 0, {
										label: "=",
										value: "=",
									});

									this.props.onChange(
										additionalOperators,
										additionalValues
									);
								}}
							>
								{"\uFF0B" /* plus */}
							</Button>
							<Button
								title={"Remove value"}
								className="btn-small-like-select"
								style={{
									width: "19px",
									height: "19px",
								}}
								onClick={() => {
									let additionalValues = Array.from(
										this.props.additionalValues
									);
									additionalValues.splice(index, 1);
									if (additionalValues.length === 0) {
										additionalValues.push(null);
									}
									let additionalOperators = Array.from(
										this.props.additionalOperators
									);
									additionalOperators.splice(index, 1);
									if (additionalOperators.length === 0) {
										additionalOperators.push({
											label: "=",
											value: "=",
										});
									}
									this.props.onChange(
										additionalOperators,
										additionalValues
									);
								}}
							>
								{"\uFF0D" /* minus */}
							</Button>
						</div>
					</div>
				))}
				<div style={{ height: 20 }} />
				{!this.props.hideBottomHint ? (
					<span
						style={{
							marginTop: 10,
							alignSelf: "flex-end",
							cursor: "pointer",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "14px",
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
							fontWeight: 700,
						}}
					>
						press [&darr;] Key to view results
					</span>
				) : null}
			</div>
		);
	}
}

export default MultiAdditionalParameterSelector;
