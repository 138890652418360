import { Canvas, CanvasSurvey } from "common/Canvas";
import styles from "./PostMessagePage.module.css"
import React from "react";
import { getCanvasesApi } from "common/CanvasUserApi";
import Select from "react-select";
import { ReactComponent as RemoveIcon } from "icons/canvas/close.svg";


interface PostMessagePageProps {
    canvasId: number;
    node: CanvasSurvey;
    currentModuleId?: number;
    onChange: (changes: Partial<CanvasSurvey>, commit?: boolean) => void;
}

const PostMessagePage: React.FC<PostMessagePageProps> = ({currentModuleId, node, canvasId, onChange}) => {

    const [ canvases, setCanvases ] = React.useState<Canvas[]>([]);
    const [ endPageSelect, setEndPageSelect ] = React.useState<boolean>(false);

    React.useEffect(() => {
        getCanvasesApi(currentModuleId)
        .then((canvases) => {
            setCanvases(canvases)
        })
        .catch((_error) => {});

        if(node.links?.length)
            setEndPageSelect(true);
    }, []);

    let options: ReadonlyArray<{
        label: string;
        value: number | null;
    }> = canvases 
            .filter((canvas) => canvas.id !== canvasId)
            .map((canvas) => {
                return {
                    value: canvas.id,
                    label: canvas.title
                };
            });

    return(
        <div className={styles.postMessageContainer}>
            <p className={styles.postMessageHeader}>Survey end screen</p>

            <p
                className={styles.add}
                onClick={() => {
                    setEndPageSelect(true);
                }}
            >
                + Add
            </p>

            {(endPageSelect) ?
                <div className={styles.selectWrapper}>
                    <p className={styles.subtitle}>Select slide</p>
                    <Select
                        isSearchable={false}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: "4px",
                                width: "140px",
                                minHeight: "24px",
                                maxHeight: "24px",
                            }),
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                            }),
                            indicatorsContainer: (provided) => ({
                                ...provided,
                                height: "20px",
                            }),
                            menu: (provided) => ({
                                ...provided,
                                maxWidth: 120,
                            }),
                            option: (provided) => ({
                                ...provided,
                                color: "black",
                                "&:hover": {
                                    backgroundColor: "#eaf0fa",
                                },
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                                ...theme.colors,
                                text: "black",
                                primary25: "#eaf0fa",
                            },
                        })}
                        options={[
                            ...options
                        ]}
                        onChange={(e) => {
                            onChange({
                                links: (e!.value) ? [e!.value] : []
                            });
                        }}
                        value={
                            (node.links?.length) ?
                                options.find(
                                    (x) => x.value === node.links![0]
                                ) : null
                        }
                    />
                    <RemoveIcon
                        onClick={(evt) => {
                            setEndPageSelect(false);
                            onChange({
                                links: []
                            });
                        }}
                        className={styles.removeQuestion}
                    />
                </div> : null
            }


        </div>
    )
}

export default PostMessagePage
