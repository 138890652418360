import React from "react";

const icons: { [id: string]: any } = {
    Boxes: require("./canvas_icons/boxes.jsx").MySvg,
    DataScience: require("./canvas_icons/data_science.jsx").MySvg,
    Inputs: require("./canvas_icons/inputs.jsx").MySvg,
    More: require("./canvas_icons/more.jsx").MySvg,
    Pointer: require("./canvas_icons/pointer.jsx").MySvg,
    Tables: require("./canvas_icons/tables.jsx").MySvg,
    Text: require("./canvas_icons/text.jsx").MySvg,
    Zoom: require("./canvas_icons/zoom.jsx").MySvg,
    Live: require("./canvas_icons/live.jsx").MySvg,
    ShowRibbon: require("./canvas_icons/show_ribbon.jsx").MySvg,
    Share: require("./canvas_icons/share.jsx").MySvg,
    Help: require("./canvas_icons/help.jsx").MySvg,
    Stop: require("./canvas_icons/stop.jsx").MySvg,
    FillColor: require("./canvas_icons/fill_color.jsx").MySvg,
    FontColor: require("./canvas_icons/font_color.jsx").MySvg,
    BorderColor: require("./canvas_icons/border_color.jsx").MySvg,
    BorderShadow: require("./canvas_icons/border_shadow.jsx").MySvg,
    FormatMenu: require("./canvas_icons/format_menu.jsx").MySvg,
    Sort: require("./canvas_icons/sort.jsx").MySvg,
    Arrow: require("./canvas_icons/arrow_icon.jsx").MySvg,
};

interface Props {
    type: keyof typeof icons;
    options: any;
}

class SectionIconsContainer extends React.Component<Props> {
    render() {
        let Component = icons[this.props.type];
        if (Component == null) return null;
        return <Component {...this.props.options} />;
    }
}

export default SectionIconsContainer;