import React from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import axios from "common/ServerConnection";
import tables from "common/Tables";
import dataScopes from "common/DataScopes";
import customSelectStyles from "common/SelectStyles";
import { Permission } from "common/Permissions";

class MainComponent extends BaseStepModule {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            dataScope: undefined,
            expanded: false,
        };
        this.dataScopeValue = this.dataScopeValue.bind(this);
    }
    dataScopeValue() {
        if (this.state.dataScope) return this.state.dataScope.value;
        return undefined;
    }

    updateOptimalAggregate() {
        let json = {
            simplified: false,
            data_table_idx: this.dataScopeValue(),
        };
        this.setStepState(StepState.running);
        axios
            .post("/api/update_optimal_aggregate", json, null)
            .then((response) => {
                if (response.data.success === false) {
                    this.setStepState(StepState.error, response.data.error_msg);
                } else {
                    this.setStepState(StepState.finished);
                    tables(this.dataScopeValue()).update();
                }
            })
            .catch((error) => {
                this.setStepState(
                    StepState.error,
                    error.response.status.toString()
                );
            });
    }

    renderUpdateOptimal() {
        if (!this.state.expanded) return null;
        else {
            return (
                <>
                    <div />
                    <div
                        style={{
                            gridColumnStart: 2,
                            gridColumnEnd: 4,
                            background: "#3f4b62",
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span className="content-regular-text">Select data</span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={""}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    marginLeft: "5px",
                                    width: "25em",
                                    height: "38px",
                                    marginRight: "5px",
                                }),
                            }}
                            options={dataScopes.dataScopesOptions.filter(
                                (option) => option.permissionType === Permission.ReadWrite
                            )}
                            onChange={(newValue) =>
                                this.setState({
                                    dataScope: newValue,
                                })
                            }
                            value={this.state.dataScope}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25: "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <span
                            className="content-regular-text"
                            style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                            }}
                        >
                            Update optimized table
                        </span>
                    </div>
                    <div />
                </>
            );
        }
    }

    render() {
        return (
            <>
                <div className="circle-letter">{this.props.index}</div>
                <Button
                    disabled={this.props.disabled}
                    className="btn-processing-tab"
                    style={{
                        height: "25px",
                    }}
                    onClick={() => {
                        this.setState((state) => ({
                            expanded: !state.expanded,
                        }));
                    }}
                >
                    <span className="content-regular-text">Update optimized table</span>
                    <span className="content-regular-text">
                        {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                    </span>
                </Button>
                {this.state.expanded ? (
                    <Button
                        className="btn btn-lg btn-primary my-primary"
                        onClick={() => this.updateOptimalAggregate()}
                        style={{
                            fontFamily: "Roboto",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "25px",
                            width: "100%",
                            minWidth: "110px",
                        }}
                    >
                        UPDATE
                    </Button>
                ) : (
                    <div style={{ minWidth: "110px" }} />
                )}
                <div>{this.state.expanded && this.renderStepState()}</div>
                {this.renderUpdateOptimal()}
            </>
        );
    }
}

export { MainComponent };
export let requirePermission = "Optimization";
