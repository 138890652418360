import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props) {
        let { data, config } = props;
        let item = {
            type: "19",
            content: {
                data: data,
            },
            config: config,
        };
        return item;
    }
    static prepateSentimentDistribution(data, showEmotions) {
        let result = {};
        if (!showEmotions)
            result = {
                neg: 0,
                neu: 0,
                pos: 0,
            //    compound: 0,
            };
        for (let item of data) {
            for (const [key, value] of Object.entries(item)) {
                if (!showEmotions && key === "compound") continue;
                result[key] = (result[key] ?? 0) + value;
            }
        }
        if (!showEmotions)
            return Object.keys(result).map((key) => ({
                name: key,
                value: result[key] / data.length,
            }));
        else
            return Object.keys(result)
                .filter((key) => result[key] > 0)
                .map((key) => ({
                    name: key,
                    value: result[key],
                }));
    }
    static getData(config, findingOptions, moduleId) {
        let {
            dataScope,
            conditions,
            selectedVariableIndex,
            showEmotions,
        } = config;

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let requestJson = {
            variable_index: selectedVariableIndex,
            table: [],
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            conditions: conditions,
            module_id: moduleId,
            show_emotions: showEmotions ?? false,
        };

        return axios
            .post("/api/e/sentiment_analysis", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = Api.prepateSentimentDistribution(
                        response.data.data,
                        showEmotions
                    );
                    let finalItem = Api.getFinding({
                        data: data,
                        config: config,
                    });
                    return Promise.resolve(finalItem);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
