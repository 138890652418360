import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="15"
			height="24"
			viewBox="0 0 15 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#657D95"
			{...props}
		>
			<path
				d="M5.75 19.625L7.5 21.375L9.25 19.625"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.25 5C12.2855 5 13.125 4.16053 13.125 3.125C13.125 2.08947 12.2855 1.25 11.25 1.25C10.2145 1.25 9.375 2.08947 9.375 3.125C9.375 4.16053 10.2145 5 11.25 5Z"
				fill="none"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.75 9.375C4.78553 9.375 5.625 8.53553 5.625 7.5C5.625 6.46447 4.78553 5.625 3.75 5.625C2.71447 5.625 1.875 6.46447 1.875 7.5C1.875 8.53553 2.71447 9.375 3.75 9.375Z"
				fill="none"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.25 13.75C12.2855 13.75 13.125 12.9105 13.125 11.875C13.125 10.8395 12.2855 10 11.25 10C10.2145 10 9.375 10.8395 9.375 11.875C9.375 12.9105 10.2145 13.75 11.25 13.75Z"
				fill="none"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.36865 8.44379L9.6374 10.9313"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.63115 4.06873L5.36865 6.55623"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}