import { observable, makeObservable } from "mobx";
import { CanvasElementOutput } from "common/Canvas";

/*!
 * InputType defines different types of inputs:
 * Regular is regular nodes;
 * Linked is shared nodes;
 * Global is global input;
 * Cloud is new cloud storage input (when we click "Cloud Storage");
 * CloudInput is already existing cloud storage input (options below "Cloud Storage")
 */
export enum InputType {
    Regular = 1,
    Linked = 2,
    Global = 3,
    Cloud = 4,
    CloudInput = 5,
}

export interface InputOption {
    type: InputType;
    label: string;
    value: number | number[];
    outputIndex?: number;
    canvasId?: number;
    outerId?: string;
}

class FormulaInformationStore {
    highlightItems: boolean;
    currentItem: InputOption | null;
    editItemId: number | null;
    editOutput: CanvasElementOutput | null;
    outputIndex: number | null;
    isFormatting: boolean;
    constructor() {
        makeObservable(this, {
            highlightItems: observable,
            currentItem: observable,
            isFormatting: observable,
            editItemId: observable,
            outputIndex: observable,
            editOutput: observable,
        });
        this.highlightItems = false;
        this.currentItem = null;
        this.isFormatting = false;
        this.editItemId = null;
        this.outputIndex = null;
        this.editOutput = null;
    }
    initDefault() {
        this.highlightItems = false;
        this.currentItem = null;
        this.isFormatting = false;
        this.editItemId = null;
        this.outputIndex = null;
        this.editOutput = null;
    }
}

export default new FormulaInformationStore();
