import axios from "common/ServerConnection";
import { UserTypeIndex } from "modules/settings_page/common/UserType";

export interface PremiumPlanResponse {
    id: number;
    name: string;
    description: string;
    payment_type: "monthly" | "yearly";
    features: string;
    price: number;
}

export interface ExtendedPremiumPlanResponse extends PremiumPlanResponse {
    unlisted: boolean;
}

export interface NewPremiumPlan {
    name: string;
    description: string;
    features: string[];
    payment_type: "monthly" | "yearly";
    price: number;
}

export interface PremiumPlan extends NewPremiumPlan {
    id: number;
    is_enterprise?: boolean;
}

export interface ExtendedPremiumPlan extends PremiumPlan {
    unlisted: boolean;
}

export interface NewPremiumPlanRequest {
    name: string;
    description: string;
    features: string;
    payment_type: "monthly" | "yearly";
    price: number;
}

export interface PremiumPlanRequest
    extends Omit<NewPremiumPlanRequest, "payment_type" | "price"> {
    plan_id: number;
}

function serializePremiumPlanRequest<
    T1 extends {
        features: string;
    },
    T2 extends {
        features: string[];
    }
>(premiumPlan: T2): T1 {
    let typedPremiumPlan = premiumPlan as any as T1;
    return {
        ...typedPremiumPlan,
        features: JSON.stringify(premiumPlan.features),
    };
}

function parsePremuimPlanResponse<
    T1 extends {
        features: string;
    },
    T2 extends {
        features: string[];
    }
>(premiumPlanResponse: T1): T2 {
    let typedPremiumPlanResponse = premiumPlanResponse as any as T2;
    let features = [];
    try {
        features = JSON.parse(premiumPlanResponse.features);
    } catch (error) {}
    return {
        ...typedPremiumPlanResponse,
        features: features,
    };
}

export function getPremiumPlans(): Promise<PremiumPlan[]> {
    return axios
        .post<{
            success: boolean;
            premium_plans: PremiumPlanResponse[];
            error_msg: string;
        }>("/api/premium_plans_list")
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve(
                    response.data.premium_plans.map((plan) =>
                        parsePremuimPlanResponse<
                            PremiumPlanResponse,
                            PremiumPlan
                        >(plan)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function getPremiumPlansAll(): Promise<ExtendedPremiumPlan[]> {
    return axios
        .post<{
            success: boolean;
            premium_plans: ExtendedPremiumPlanResponse[];
            error_msg: string;
        }>("/api/premium_plans_list_all")
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve(
                    response.data.premium_plans.map((plan) =>
                        parsePremuimPlanResponse<
                            ExtendedPremiumPlanResponse,
                            ExtendedPremiumPlan
                        >(plan)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function createPremiumPlan(plan: NewPremiumPlan): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            plan_id?: number;
        }>("/api/premium_plan_create", serializePremiumPlanRequest(plan))
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve(response.data.plan_id!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function editPremiumPlan(plan: PremiumPlanRequest): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/premium_plan_edit", plan)
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function listUnlistPremiumPlan(
    planId: number,
    unlisted: boolean
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/premium_plan_set_unlisted", {
            plan_id: planId,
            unlisted: unlisted,
        })
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export interface CurrentPlan {
    id: number | null;
    active: boolean;
    user_type?: UserTypeIndex;
}

export function getCurrentPremiumPlan(): Promise<CurrentPlan> {
    return axios
        .post<{
            success: boolean;
            plan_id: number | null;
            error_msg: string;
            active: boolean;
            user_type: UserTypeIndex;
        }>("/api/premium_plan_get_current")
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve({
                    id: response.data.plan_id,
                    active: response.data.active,
                    user_type: response.data.user_type,
                });
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function premiumPlanSubscribe(planId: number): Promise<string> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            redirect_url: string;
        }>("/api/premium_plan_subscribe", { plan_id: planId })
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve(response.data.redirect_url);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function premiumPlanUnsubscribe(): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            redirect_url: string;
        }>("/api/premium_plan_cancel_at_period_end")
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function sendEnterpriseNotification(): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/send_enterprise_notification_email")
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function confirmSession(sessionId: string): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/premium_plan_checkout_session_confirm", {
            session_id: sessionId,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
