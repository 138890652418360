import React from "react";
import { UserGroupOption } from "common/UserGroupsStore";
import Select, { createFilter } from "react-select";
import { customSelectStylesForSharePopup } from "common/SelectStyles";
import { Button } from "react-bootstrap";
import { mainStyle } from "common/MainStyle";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { UserIcon, GroupIcon } from "common/UserIcon";
import { AppGroupExtendedPermission } from "common/ModulesApi";

interface Props {
    options: UserGroupOption[];
    selectedUserGroups: AppGroupExtendedPermission[];
    selectedNewGroup: UserGroupOption | null;
    onChange: (change: { selectedNewGroup?: UserGroupOption | null }) => void;
    onAddUser: () => void;
    onDeleteUserGroup: (groupId: number) => void;
    buttonTitle: string;
    usersSectionTitle: string;
    cantRemoveEditors?: boolean;
}

const AppUserInviter = (props: Props) => {
    let [sharedWithExpanded, setSharedWithExpanded] = React.useState(false);

    let selectedUserGroups = sharedWithExpanded
        ? props.selectedUserGroups
        : props.selectedUserGroups.slice(0, 2);
    let options = props.options.filter(
        (option) =>
            props.selectedUserGroups.find(
                (group) => group.group_id === option.id
            ) == null
    );
    return (
        <div>
            <div
                className="my-row"
                style={{ marginTop: "17px", alignItems: "center" }}
            >
                <div
                    className="my-row"
                    style={{
                        width: "100%",
                        padding: "5px",
                        backgroundColor: "var(--selectors-background-color)",
                        borderRadius: 4,
                    }}
                >
                    <Select
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        placeholder={"Select user or group ..."}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        styles={{
                            ...customSelectStylesForSharePopup,
                            container: (base) => ({
                                ...base,
                                flexGrow: 1,
                                height: "32px",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={options}
                        value={props.selectedNewGroup}
                        onChange={(newValue) => {
                            props.onChange({
                                selectedNewGroup: newValue as UserGroupOption,
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                        })}
                    />
                </div>
                <Button
                    type="button"
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        marginLeft: "35px",
                        marginRight: "10px",
                        width: "112px",
                        height: "33px",
                        paddingTop: "6.5px",
                    }}
                    onClick={props.onAddUser}
                >
                    {props.buttonTitle}
                </Button>
            </div>
            <div className="flex-simple-column">
                <div
                    className="my-row"
                    style={{
                        marginTop: "13px",
                        marginLeft: "9px",
                        justifyContent: "space-between",
                    }}
                    onClick={() => {
                        setSharedWithExpanded(!sharedWithExpanded);
                    }}
                >
                    <span
                        style={{
                            color: "var(--popup-primary-text-color)",
                            fontSize: "14px",
                            fontWeight: 400,
                            fontFamily: "Roboto",
                        }}
                        className="unselectable"
                    >
                        {props.usersSectionTitle}
                    </span>
                    <div
                        className="unselectable"
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 15,
                            height: 15,
                        }}
                    >
                        <ChevronIcon
                            transform={
                                !sharedWithExpanded ? "rotate(90)" : "none"
                            }
                            stroke={mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            )}
                        />
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 14.25,
                        marginLeft: 25,
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 280px)",
                        gridRowGap: "10px",
                        gridColumnGap: "10px",
                    }}
                >
                    {selectedUserGroups.map((group, index) => (
                        <div
                            key={index}
                            className="my-row"
                            style={{ alignItems: "center" }}
                        >
                            {!group.personal && (
                                <>
                                    <GroupIcon
                                        fontSize={8}
                                        groupName={group.group_name}
                                    />
                                    <span
                                        style={{
                                            marginLeft: 10,
                                            width: "60px",
                                            color:
                                                "var(--popup-primary-text-color)",
                                            fontSize: "11px",
                                            fontWeight: 400,
                                            fontFamily: "Roboto",
                                        }}
                                        className="unselectable"
                                    >
                                        {group.group_name}
                                    </span>
                                </>
                            )}
                            {group.personal && (
                                <>
                                    <UserIcon
                                        width={25}
                                        height={25}
                                        fontSize={8}
                                        user={group.user_info!}
                                    />

                                    <span
                                        style={{
                                            marginLeft: 20,
                                            width: "60px",
                                            color:
                                                "var(--popup-primary-text-color)",
                                            fontSize: "11px",
                                            fontWeight: 400,
                                            fontFamily: "Roboto",
                                        }}
                                        className="unselectable"
                                    >
                                        {group.user_info
                                            ? group.user_info.first_name
                                                  .concat(" ")
                                                  .concat(
                                                      group.user_info.last_name
                                                  )
                                                  .trim() ||
                                              group.user_info.user_name
                                            : ""}
                                    </span>
                                </>
                            )}
                            {(!props.cantRemoveEditors || !group.can_edit) && (
                                <Button
                                    className="close"
                                    onClick={() => {
                                        props.onDeleteUserGroup(group.group_id);
                                    }}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AppUserInviter;
