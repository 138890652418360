import { ResizeDirection } from "re-resizable";

interface UnscaledSize {
    position: {
        desktop: {
            x: number;
            y: number;
        };
        mobile: {
            x: number;
            y: number;
        };
    };
    size: {
        desktop: {
            width: number;
            height: number;
        };
        mobile: {
            width: number;
            height: number;
        };
    };
}

export function getNewSizeWhenResize(
    unscaledSize: {
        x: number;
        y: number;
        width: number;
        height: number;
    },
    scale: number,
    direction: ResizeDirection,
    d: {
        width: number;
        height: number;
    },
    keepAspectRatio: boolean = false,
    outline: boolean = false
) {
    let oldHeight = unscaledSize.height * scale;
    let oldWidth = unscaledSize.width * scale;
    let width = unscaledSize.width * scale + d.width;
    let height = unscaledSize.height * scale + d.height;
    if (keepAspectRatio) {
        if (
            direction === "top" ||
            direction === "bottom" ||
            direction === "topRight" ||
            direction === "bottomRight" ||
            direction === "topLeft" ||
            direction === "bottomLeft"
        ) {
            let newWidth = oldWidth * (height / oldHeight);
            d.width = newWidth - oldWidth;
            width = newWidth;
        }
        if (direction === "left" || direction === "right") {
            let newHeight = oldHeight * (width / oldWidth);
            d.height = newHeight - oldHeight;
            height = newHeight;
        }
    }
    let x = unscaledSize.x * scale;
    let y = unscaledSize.y * scale;
    if (
        direction === "topLeft" ||
        direction === "top" ||
        direction === "left"
    ) {
        x = x - d.width;
        y = y - d.height;
    }
    if (direction === "bottomLeft") {
        x = x - d.width;
    }
    if (direction === "topRight") {
        y = y - d.height;
    }

    return { x, y, width, height };
}

export function changeElementWhenResize(
    unscaledSize: {
        x: number;
        y: number;
        width: number;
        height: number;
    },
    scale: number,
    direction: ResizeDirection,
    d: {
        width: number;
        height: number;
    },
    ref: HTMLElement,
    keepAspectRatio: boolean = false,
    outline: boolean = false
) {
    const { x, y, width, height } = getNewSizeWhenResize(
        unscaledSize,
        scale,
        direction,
        d,
        keepAspectRatio,
        outline
    );

    ref.style.width = `${width}px`;
    ref.style.height = `${height}px`;
    if (outline) ref.style.outline = "2px solid #39F";

    let parentDraggable = ref.closest("div.react-draggable") as HTMLElement;
    if (parentDraggable != null) {
        parentDraggable.style.transform = `
                                    translate(${x}px, ${y}px)`;
    }
}

export function moveableDirectionToResizeDirection(
    direction: number[]
): ResizeDirection {
    if (direction[0] === 0) {
        if (direction[1] === 1) {
            return "bottom";
        }
        if (direction[1] === -1) {
            return "top";
        }
    }
    if (direction[0] === 1) {
        if (direction[1] === 1) {
            return "bottomRight";
        }
        if (direction[1] === 0) {
            return "right";
        }
        if (direction[1] === -1) {
            return "topRight";
        }
    }
    if (direction[0] === -1) {
        if (direction[1] === 1) {
            return "bottomLeft";
        }
        if (direction[1] === 0) {
            return "left";
        }
        if (direction[1] === -1) {
            return "topLeft";
        }
    }
    return "topLeft";
}

export function changeElementWhenResize2(
    // TODO Refactor
    unscaledSize: UnscaledSize,
    scale: number,
    direction: ResizeDirection,
    d: {
        width: number;
        height: number;
    },
    ref: HTMLElement,
    canvasViewMode: keyof typeof unscaledSize.size = "desktop",
    keepAspectRatio: boolean = false,
    outline: boolean = false
) {
    let oldHeight = unscaledSize.size[canvasViewMode].height * scale;
    let oldWidth = unscaledSize.size[canvasViewMode].width * scale;
    let width = unscaledSize.size[canvasViewMode].width * scale + d.width;
    let height = unscaledSize.size[canvasViewMode].height * scale + d.height;
    if (keepAspectRatio) {
        if (
            direction === "top" ||
            direction === "bottom" ||
            direction === "topRight" ||
            direction === "bottomRight" ||
            direction === "topLeft" ||
            direction === "bottomLeft"
        ) {
            let newWidth = oldWidth * (height / oldHeight);
            d.width = newWidth - oldWidth;
            width = newWidth;
        }
        if (direction === "left" || direction === "right") {
            let newHeight = oldHeight * (width / oldWidth);
            d.height = newHeight - oldHeight;
            height = newHeight;
        }
    }
    let x = unscaledSize.position[canvasViewMode].x * scale;
    let y = unscaledSize.position[canvasViewMode].y * scale;
    if (
        direction === "topLeft" ||
        direction === "top" ||
        direction === "left"
    ) {
        x = x - d.width;
        y = y - d.height;
    }
    if (direction === "bottomLeft") {
        x = x - d.width;
    }
    if (direction === "topRight") {
        y = y - d.height;
    }
    ref.style.width = `${width}px`;
    ref.style.height = `${height}px`;
    if (outline) ref.style.outline = "2px solid #39F";

    let parentDraggable = ref.closest("div.react-draggable") as HTMLElement;
    if (parentDraggable != null) {
        parentDraggable.style.transform = `
                                    translate(${x}px, ${y}px)`;
    }
}

export function changeElementWhenResizeMoveable(
    // TODO Refactor
    unscaledSize: UnscaledSize,
    scale: number,
    direction: ResizeDirection,
    d: {
        width: number;
        height: number;
    },
    ref: HTMLElement,
    canvasViewMode: keyof typeof unscaledSize.size = "desktop",
    keepAspectRatio: boolean = false,
    outline: boolean = false
) {
    let oldHeight = unscaledSize.size[canvasViewMode].height * scale;
    let oldWidth = unscaledSize.size[canvasViewMode].width * scale;
    let width = unscaledSize.size[canvasViewMode].width * scale + d.width;
    let height = unscaledSize.size[canvasViewMode].height * scale + d.height;
    if (keepAspectRatio) {
        if (
            direction === "top" ||
            direction === "bottom" ||
            direction === "topRight" ||
            direction === "bottomRight" ||
            direction === "topLeft" ||
            direction === "bottomLeft"
        ) {
            let newWidth = oldWidth * (height / oldHeight);
            d.width = newWidth - oldWidth;
            width = newWidth;
        }
        if (direction === "left" || direction === "right") {
            let newHeight = oldHeight * (width / oldWidth);
            d.height = newHeight - oldHeight;
            height = newHeight;
        }
    }
    let x = unscaledSize.position[canvasViewMode].x * scale;
    let y = unscaledSize.position[canvasViewMode].y * scale;
    if (
        direction === "topLeft" ||
        direction === "top" ||
        direction === "left"
    ) {
        x = x - d.width;
        y = y - d.height;
    }
    if (direction === "bottomLeft") {
        x = x - d.width;
    }
    if (direction === "topRight") {
        y = y - d.height;
    }
    ref.style.width = `${width}px`;
    ref.style.height = `${height}px`;
    ref.style.left = `${Math.max(x, 0)}px`;
    ref.style.top = `${Math.max(y, 0)}px`;
    if (outline) ref.style.outline = "2px solid #39F";
}

export function getNewSizeAfterResize(
    unscaledSize: {
        x: number;
        y: number;
        width: number;
        height: number;
    },
    scale: number,
    direction: ResizeDirection,
    d: {
        width: number;
        height: number;
    },
    canvasViewMode: string = "desktop"
) {
    let width = unscaledSize.width + d.width / scale;
    let height = unscaledSize.height + d.height / scale;
    let x = unscaledSize.x;
    let y = unscaledSize.y;
    if (
        direction === "topLeft" ||
        direction === "top" ||
        direction === "left"
    ) {
        x = x - d.width / scale;
        y = y - d.height / scale;
    }
    if (direction === "bottomLeft") {
        x = x - d.width / scale;
    }
    if (direction === "topRight") {
        y = y - d.height / scale;
    }

    const nodeData = {
        x: Math.max(x, 0),
        y: Math.max(y, 0),
        width: width,
        height: height,
        size: {
            [canvasViewMode]: {
                width: width,
                height: height,
            },
        },
    };

    return nodeData;
}

export function getNewSizeAfterResize2(
    unscaledSize: UnscaledSize,
    scale: number,
    direction: ResizeDirection,
    d: {
        width: number;
        height: number;
    },
    canvasViewMode: keyof typeof unscaledSize.size = "desktop"
) {
    let width = unscaledSize.size[canvasViewMode].width + d.width / scale;
    let height = unscaledSize.size[canvasViewMode].height + d.height / scale;
    let x = unscaledSize.position[canvasViewMode].x;
    let y = unscaledSize.position[canvasViewMode].y;
    if (
        direction === "topLeft" ||
        direction === "top" ||
        direction === "left"
    ) {
        x = x - d.width / scale;
        y = y - d.height / scale;
    }
    if (direction === "bottomLeft") {
        x = x - d.width / scale;
    }
    if (direction === "topRight") {
        y = y - d.height / scale;
    }

    const nodeData = {
        nodePosition: {
            ...unscaledSize.position,
            [canvasViewMode]: {
                x,
                y,
            },
        },
        nodeSize: {
            ...unscaledSize.size,
            [canvasViewMode]: {
                width: width,
                height: height,
            },
        },
    };

    return nodeData;
}
