import { Parser as ExprParser } from "expr-eval";

var Parser = new ExprParser();

/*!
 * min function handles such expressions as
 * "max(1, 2, 3)" and returns min (1 in this case)
 */

Parser.functions.min = function (
    ...args: (number | string | null | undefined)[]
): number {
    let min: number | null = null;
    // for is faster that filter
    for (let arg of args) {
        if (typeof arg === "number") {
            if (min == null) min = arg;
            else min = Math.min(min, arg);
        }
    }
    return min ?? 0;
};

/*!
 * max function handles such expressions as
 * "max(1, 2, 3)" and returns max (3 in this case)
 */

Parser.functions.max = function (
    ...args: (number | string | null | undefined)[]
): number {
    let max: number | null = null;
    // for is faster that filter
    for (let arg of args) {
        if (typeof arg === "number") {
            if (max == null) max = arg;
            else max = Math.max(max, arg);
        }
    }
    return max ?? 0;
};

/*!
 * join function handles such expressions as
 * "join('S1', 'S2', 'S3')" and returns join of
 * strings (S1S2S3 in this case)
 */

Parser.functions.join = function (...args: string[]): string {
    return args.join("");
};

type ParserValue = string | number;

(Parser as any).binaryOps['=='] = (a: ParserValue, b: ParserValue) => {
    if (Array.isArray(b)) return b.includes(a);
    return a === b;
}

(Parser as any).binaryOps['!='] = (a: ParserValue, b: ParserValue) => {
    if (Array.isArray(b)) return !b.includes(a);
    return a === b;
}

export { Parser };
export type { Expression } from "expr-eval";
