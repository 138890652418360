import React, { Component } from "react";
import { CanvasRadioButtonsGroup, CanvasRadioButtonsGroupData, RadioButtonsGroupStyle } from "common/Canvas";
import styles from "./HTMLRadioButtonsGroup.module.css";
import cx from "classnames";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { getRadioButtonsGroupStyle } from "../themes/RadioButtonsGroupStyles";
import { getNodeSize } from "modules/canvas_page/canvas_elements/getNodeSize";

import {ReactComponent as CheckIcon} from "icons/check_icon.svg";
import { getRawDataApi } from "common/DataApi";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    rootDataTestId: string;
    node: CanvasRadioButtonsGroup;
    currentModuleId: number | undefined;
    scale: number;
    onMetricChanged: (metrics: string) => void;
}

interface State {
    chosenButton: number;
    groupData: CanvasRadioButtonsGroupData[];
}

function checkMultiline(
    textStyle: {
        text: string,
        fontFamily: string,
        fontSize: number,
        fontWeight: string
    },
    containerStyle: {
        width: number,
        height: number,
    },
): boolean {
    const { fontFamily, fontWeight, fontSize, text } = textStyle;
    let textElement = document.createElement("p");
    document.body.appendChild(textElement);
    textElement.style.font = fontFamily;
    textElement.style.fontWeight = fontWeight;
    textElement.style.fontSize = fontSize + "px";
    textElement.style.height = "auto";
    textElement.style.width = containerStyle.width + "px";
    textElement.style.position = "absolute";
    textElement.style.whiteSpace = "break-spaces";
    textElement.innerHTML = text;

    let height = Math.ceil(textElement.clientHeight) + 16;
    document.body.removeChild(textElement);
    if (height <= containerStyle.height) {
        return true;
    }
    return false;
}


class HTMLRadioButtonsGroup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            chosenButton: 0,
            groupData: this.props.node?.groupData ?? [],
        }
        this.onChoose = this.onChoose.bind(this);
    }

    componentDidMount(): void {
        const { node } = this.props;
        this.loadGroupData(node);
        if (node.chosenButton) {
            this.setState({
                chosenButton: node.chosenButton.idx
            })
        }
    }

    async loadGroupData(node: CanvasRadioButtonsGroup) {
        if (!node.columns) return;
        const columns = Object.keys(node.columns).map(col => (node.columns as any)[col]);
        
        let rawData = await getRawDataApi(
            {
                label: "",
                value: [],
                optimized: false,
                data_table_idx: node.dataScopeOption!.value,
            },
            10,
            undefined,
            columns,
            undefined,
            undefined,
            undefined,
            true
        );

        let labels: Array<string | number | null> = [];
        let icons: Array<string | null> = [];

        const labelsColumnName = node.columns?.label?.label;
        const iconsColumnName = node.columns?.icon?.label;

        let newGroupData: CanvasRadioButtonsGroupData[] = [];
        if (labelsColumnName) {
            labels = rawData.currentLevels[labelsColumnName];

            if (!labels) return;
            newGroupData = labels.map((value, idx) => ({
                ...(newGroupData?.[idx] ?? {}),
                idx,
                value: String(value!),
            }));
        }

        if (iconsColumnName) {
            icons = rawData.currentLevels[iconsColumnName] as (string | null)[];
            newGroupData = icons.map((thumbnail, idx) => ({
                ...(newGroupData?.[idx] ?? {}),
                idx,
                thumbnail,
            }));
        }

        this.setState({
            groupData: newGroupData,
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            prevProps.node?.columns !== this.props.node?.columns ||
            prevProps.node?.update_time !== this.props.node?.update_time
        ) {
            this.loadGroupData(this.props.node);
        }
    }

    private onChoose(btn: CanvasRadioButtonsGroupData): void {
        this.setState({
            chosenButton: btn.idx
        })
        this.props.canvasTreeStore.updateRadioButtonsGroupAction(
            this.props.node.id,
            {
                chosenButton: btn
            }
        );
        this.props.onMetricChanged(btn.value);
    }

    public render(): JSX.Element {
        const { groupData } = this.state;
        const {
            node,
            scale,
            canvasTreeStore,
        } = this.props;

        const { canvasViewMode } = canvasTreeStore;
        const buttonLabels: Array<string> = [];
        const radioButtonsGroupStyle = getRadioButtonsGroupStyle(
            this.state.groupData,
            node.radioButtonsGroupStyle,
        );
        const isIconChoiseTheme = node.radioButtonsGroupStyle === RadioButtonsGroupStyle.IconChoice;
        let originalNodeSize = {
            nodePosition: node.nodePosition,
            ...getNodeSize(node, canvasViewMode),
        };
        let nodeSize = {
            height: originalNodeSize.nodeSize[canvasViewMode].height,
            width: originalNodeSize.nodeSize[canvasViewMode].width,
        };
        return (
            <div
                data-test-id={`${this.props.rootDataTestId}-radioButtonsGroup`}
                className={cx(styles.radioButtonsGroup, "element")}
                style={radioButtonsGroupStyle.containerStyle}
                onKeyDown={(e) => {
                    if (canvasTreeStore.live) {
                        if (buttonLabels.includes(e.key.toUpperCase())) {
                            const labelIdx = buttonLabels.indexOf(e.key.toUpperCase());
                            if (labelIdx !== -1) {
                                this.onChoose(groupData![labelIdx]);
                            }
                        }
                    }
                }}
            >
                {groupData!.map((btn, idx) => {
                    const primaryColor = "#3399ff";
                    const isActive = btn.idx === this.state.chosenButton;
                    const radioButtonKeyLabelColor = node.keyLabelOptions?.fillColor ?? "#fff";
                    const checkIconDefaultColor = isIconChoiseTheme ? "#fff" : primaryColor;
                    const checkIconColor = node.keyLabelOptions?.fillColor ?? checkIconDefaultColor;
                    buttonLabels.push(String.fromCharCode(65 + idx));

                    const btnWidth = originalNodeSize.nodeSize[canvasViewMode].width - 40 // 40 - main container paddings
                    const btnHeight = ((nodeSize.height - 10) / groupData.length) * scale;

                    return (
                        <button
                            className={cx(styles.radioButton, isActive && styles.active)}
                            style={{
                                ...radioButtonsGroupStyle.buttonStyle,
                                height: isIconChoiseTheme ? "100%" : nodeSize.height - 10,
                                minHeight: 50,
                                minWidth: 160,
                                backgroundColor: node.fillColor ?? "rgba(4, 69, 175, 0.1)",
                                overflowY: "hidden",
                                borderColor: node.borderColor ?? primaryColor
                            }}
                            onClick={() => this.onChoose(btn)}
                        >

                            {(isIconChoiseTheme && btn.thumbnail) && (
                                <div className={styles.radioButtonIcon}>
                                    <img src={btn.thumbnail} alt="" />
                                </div>
                            )}

                            <div
                                style={{
                                    display: "grid",
                                    alignItems: "center",
                                    gridTemplateColumns: "30px calc(100% - 54px) 24px",
                                    fontSize: node.fontSize,
                                    color: node.fontColor ?? "#000",
                                    fontFamily: node.fontFamily ?? "Roboto",
                                }}
                            >
                                <div
                                    className={styles.radioButtonKeyLabel}
                                    style={{
                                        backgroundColor: radioButtonKeyLabelColor,
                                        borderColor: node.keyLabelOptions?.borderColor ?? primaryColor
                                    }}
                                >
                                    <span>{buttonLabels[idx]}</span>
                                </div>
                                <p
                                    style={{
                                        margin: "0 10px",
                                        overflow: "hidden",
                                        textAlign: "left",
                                        whiteSpace: checkMultiline(
                                            {
                                                text: btn?.value ?? "",
                                                fontFamily: "Roboto",
                                                fontSize: 14,
                                                fontWeight: "normal",
                                            },
                                            {
                                                width: btnWidth - 30 - 24 - 20, // 30 - label, 24 - icon, 20 - margins
                                                height: btnHeight - 16, // 16 - paddings
                                            },
                                        ) ? "break-spaces" : "nowrap",
                                    }}
                                >{btn?.value ?? ""}</p>
                            </div>
                            <div
                                    className={cx(styles.checkIcon, isIconChoiseTheme && styles.iconChoiseTheme)}
                                    style={{
                                        opacity: isActive ? 1 : 0,
                                        position: "absolute",
                                        right: 8,
                                        ...radioButtonsGroupStyle.checkIconStyle,
                                    }}
                                >
                                    <CheckIcon fill={checkIconColor} stroke={checkIconColor} />
                                </div>
                        </button>
                    )
                })}
            </div>
        );
    }
}

export default HTMLRadioButtonsGroup;
