import React, { useState, useEffect, CSSProperties } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { strftime } from "common/utilities/TimeFormatUtils";
import { UserType } from "common/UserInfo";

import TermsOfServiceModalContent from "./TermsOfServiceModalContent";
import { getTosText, getTosTextForCurrentUser } from "./api";
import styles from "./TermsOfServiceModal.module.css";
import cx from "classnames";

interface Props {
    show: boolean;
    confirm: React.MouseEventHandler;
    cancel: React.MouseEventHandler;
    // View TOS for specific user type (from the settings page)
    viewFor?: UserType;
    dialogClassName?: string;
    style?: CSSProperties;
}

function TermsOfServiceModal({ show, confirm, cancel, viewFor, dialogClassName, style }: Props) {
    const [accepted, setAccepted] = useState(false);
    const [tosState, setTosState] = useState<{
        tosText: string | null;
        lastUpdated: Date | null;
        loading: boolean;
    }>({
        tosText: null,
        lastUpdated: null,
        loading: true,
    });

    useEffect(() => {
        if (viewFor != null) {
            getTosText(viewFor).then(({ tosText, lastUpdated }) => {
                setTosState({
                    tosText: tosText,
                    lastUpdated: lastUpdated,
                    loading: false,
                });
            });
        } else {
            getTosTextForCurrentUser().then(({ tosText, lastUpdated }) => {
                setTosState({
                    tosText: tosText,
                    lastUpdated: lastUpdated,
                    loading: false,
                });
            });
        }
    }, [viewFor]);

    function toggleAccepted(e: React.ChangeEvent<HTMLInputElement>) {
        setAccepted(e.target.checked);
    }

    return (
        <BootstrapModal
            show={show}
            animation={false}
            backdropClassName={styles.backdrop}
            dialogClassName={cx(styles.dialog, dialogClassName)}
            contentClassName={styles.dialogContent}
            style={style}
        >
            <h2 className={styles.title}>Eisengard Terms of Use</h2>
            <span className={styles.lastUpdated}>
                Last Updated:{" "}
                {tosState.lastUpdated != null
                    ? strftime("%m/%d/%Y", tosState.lastUpdated)
                    : "07/05/2021"}
            </span>
            <BootstrapModal.Body className={styles.content}>
                <TermsOfServiceModalContent
                    tosText={tosState.loading ? "LOADING" : tosState.tosText}
                />
            </BootstrapModal.Body>
            {viewFor == null && !tosState.loading && (
                <div className={styles.actionButtonsContainer}>
                    <Form.Check
                        type="checkbox"
                        label="I have read this agreement"
                        className={styles.confirmCheckbox}
                        onChange={toggleAccepted}
                    />
                    <div style={{ flex: 1 }} />
                    <button
                        type="button"
                        onClick={cancel}
                        className={styles.buttonLight}
                    >
                        Disagree
                    </button>
                    <button
                        type="button"
                        onClick={confirm}
                        className={styles.button}
                        disabled={!accepted}
                    >
                        Agree
                    </button>
                </div>
            )}
            {viewFor != null && (
                <div className={styles.actionButtonsContainer}>
                    <div style={{ flex: 1 }} />
                    <button
                        type="button"
                        onClick={cancel}
                        className={styles.buttonLight}
                    >
                        Close
                    </button>
                </div>
            )}
        </BootstrapModal>
    );
}

export default TermsOfServiceModal;
