import React, { Component } from "react";
import { mainStyle } from "common/MainStyle";
import Tooltip from "rc-tooltip";
import "./rc-tooltip-htmlerror.css";

interface Props {
    scale: number;
    canvasTreeRequestError: string | null;
    canvasTreeParseError: string | null;
}

class ErrorWithTooltip extends Component<Props> {
    public render(): JSX.Element {
        return (
            <Tooltip
                prefixCls="rc-tooltip-htmlerror"
                placement="top"
                overlay={
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--content-primary-text-color"
                            ),
                            fontSize: 9.5 * this.props.scale,
                            fontFamily: "Roboto",
                        }}
                    >
                        {(this.props.canvasTreeRequestError != null
                            ? this.props.canvasTreeRequestError
                            : this.props.canvasTreeParseError) ?? ""}
                    </span>
                }
            >
                <div>
                    <img
                        width={12 * this.props.scale}
                        height={12 * this.props.scale}
                        alt=""
                        src="/dist/img/error.png"
                    />
                </div>
            </Tooltip>
        );
    }
}

export default ErrorWithTooltip;
