import "common/styles/App.css";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Switch from "react-switch";

import { reaction } from "mobx";

import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";

import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import BaseJourney from "../../common/BaseJourney";

import PredictiveAnalyticsType from "../../common/PredictiveAnalyticsType";
import RunHoldOutRegressionComponent from "../../common/RunHoldOutRegressionComponent";
import HoldOutPredictionBarChart from "common/graphics/HoldOutPredictionBarChart";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";

function StepFinal(props) {
	let {
		finding,
		onNewFinding,
		onShowRecipesPopup,
		onBack,
		onSaveFinding /*, step*/,
		loading,
	} = props;
	let ivNames = undefined;
	if (finding != null) {
		if (finding.content.regressionResult.iv_main_names != null)
			ivNames = [...finding.content.regressionResult.iv_main_names];
		if (finding.content.regressionResult.iv_other_names != null) {
			if (ivNames != null)
				ivNames = ivNames.concat(
					finding.content.regressionResult.iv_other_names
				);
			else ivNames = [...finding.content.regressionResult.iv_other_names];
		}
	}
	return (
		<>
			<div
				className="my-row"
				style={{
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
				}}
			>
				<div
					onClick={() => {
						onBack();
					}}
					style={{
						width: 100,
						height: "100%",
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						justifyContent: "center",
					}}
				>
					<img
						alt=""
						src="/dist/img/data-exploration/chevron_back.png"
					/>
				</div>
				{loading ? (
					<span className="exploration-big-title-span">
						{"LOADING"}
					</span>
				) : (
					finding && (
						<div
							className="flex-simple-column"
							style={{
								width: "calc(100% - 300px)",
								height: "100%",
							}}
						>
							<div
								style={{
									width: "100%",
									height: "500px",
								}}
							>
								<ErrorBoundary>
									<HoldOutPredictionBarChart
										dynamic
										regressionOptions={
											finding.config.regressionInfo[0]
										}
										defaultValue={
											finding.config.regressionInfo?.[3]
												?.defaultValue
										}
										decimals={
											finding.config.regressionInfo?.[3]
												?.decimals
										}
										userValues={finding.config.userValues}
										showDynamicOptions={
											finding.config.regressionInfo
												.length === 6
												? finding.config
														.regressionInfo[5]
												: undefined
										}
										dependentVariable={
											finding.config
												.selectedDependentVariable
										}
										showTrueValue={
											finding.config.showTrueValue
										}
										showLeverBars={
											finding.config.showLeverBars
										}
										data={
											finding.content.regressionResult
												.predictedBars
										}
										ivNames={ivNames}
										confidenceLevel={
											finding.config.confidenceLevel
										}
										showIntervals={
											finding.config.showIntervals
										}
										onNewUserValues={(userValues, diff) => {
											let newFinding = Object.assign(
												{},
												finding
											);
											for (let name of diff) {
												newFinding.config.regressionInfo[3].defaultValue = {
													...newFinding.config
														.regressionInfo[3]
														?.defaultValue,
													[name]: false,
												};
											}
											newFinding.config.userValues = userValues;
											if (props.onNewFinding) {
												props.onNewFinding(newFinding);
											}
										}}
										onShowIntervalsChange={(
											showIntervals,
											clustIndex
										) => {
											let newFinding = Object.assign(
												{},
												finding
											);
											newFinding.content.regressionResult.predictedBars[
												clustIndex
											].show_intervals = showIntervals;
											if (onNewFinding) {
												onNewFinding(newFinding);
											}
										}}
										onCenterItem={(
											clustIndex,
											chartInterval
										) => {
											let newFinding = Object.assign(
												{},
												finding
											);
											newFinding.content.regressionResult.predictedBars[
												clustIndex
											].chart_interval = chartInterval;
											if (onNewFinding) {
												onNewFinding(newFinding);
											}
										}}
										onConfidenceLevelChange={(
											confidenceLevel,
											clustIndex
										) => {
											let newFinding = Object.assign(
												{},
												finding
											);
											newFinding.content.regressionResult.predictedBars[
												clustIndex
											].confidence_level = confidenceLevel;

											if (onNewFinding) {
												onNewFinding(newFinding);
											}
										}}
										dataScopeId={
											finding.config.dataScope.value
										}
										onNewResult={(update, index) => {
											let newFinding = Object.assign(
												{},
												finding
											);
											newFinding.content.regressionResult.predictedBars[
												index
											] = {
												...newFinding.content
													.regressionResult
													.predictedBars[index],
												...update,
											};
											if (onNewFinding) {
												onNewFinding(newFinding);
											}
										}}
									/>
								</ErrorBoundary>
							</div>
							<FinalStepBottomBar
								onSaveFinding={onSaveFinding}
								onShowRecipesPopup={onShowRecipesPopup}
								topMargin={10}
							/>
						</div>
					)
				)}

				<div
					style={{
						width: 100,
						height: "100%",
					}}
				/>
			</div>
		</>
	);
}

const StepSimple = Object.freeze({
	initial: 0,
	selectDataScope: 1,
	selectTable: 2,
	selectConditions: 3,
	selectIndependent: 4,
	selectDependent: 5,
	selectOptional: 6,
	selectHoldOutOptions: 7,
	selectAdditionalParameter: 8,
	selectAdditionalValue: 9,
	final: 10,
});

class MainComponent extends BaseJourney {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			step: 0,
			loading: false,
			animationDirection: "top",
			regressionVariables: [],
			allVariables: [],
			allVariablesWithUnits: [],
			variablesWithUnits: [],
			selectedDependentVariable: undefined,
			selectedIndependentVariable: undefined,
			restIndependentVariables: [],
			finding: undefined,
			showRecipesPopup: false,
			regressionInfo: undefined,
			leftAdditionalValue: undefined,
			rightAdditionalValue: undefined,
			additionalParameters: [],
			additionalParameter: undefined,
			selectedTable: undefined,
			aggregateTableList: [],
			datasetFraction: 50,
			confidenceLevel: 95,
			showTrueValue: false,
			showLeverBars: true,
			showIntervals: true,
			cacheData: true,
			conditions: ConditionsSelector.defaultValue,
		};
		this.steps = StepSimple;
		this.back = this.back.bind(this);
		this.saveFinding = this.saveFinding.bind(this);
		this.initializeVariables = this.initializeVariables.bind(this);
		this.switchVariables = this.switchVariables.bind(this);
	}

	clearOptionalArgs() {
		this.setState({
			selectedIndependentVariable: null,
			selectedDependentVariable: null,
			interactionIndependentVariable: null,
			restIndependentVariables: [],
			additionalParameter: null,
			additionalParameterIndex: null,
			leftAdditionalOperator: null,
			leftAdditionalValue: null,
			rightAdditionalOperator: null,
			rightAdditionalValue: null,
			selectedTable: this.defaultTable(),
			conditions: ConditionsSelector.defaultValue,
			regressionInfo: null,
			userValues: null,
			dynamicOptions: {},
		});
	}
	getConfig() {
		let config = {
			version: configVersion,
			datasetFraction: this.state.datasetFraction ?? null,
			confidenceLevel: this.state.confidenceLevel ?? null,
			showTrueValue: this.state.showTrueValue ?? null,
			showLeverBars: this.state.showLeverBars ?? null,
			cacheData: this.state.cacheData ?? null,
			dataScope: this.state.dataScope ?? null,
			selectedDependentVariable:
				this.state.selectedDependentVariable ?? null,
			selectedIndependentVariable:
				this.state.selectedIndependentVariable ?? null,
			restIndependentVariables:
				this.state.restIndependentVariables ?? null,
			regressionInfo: this.state.regressionInfo ?? null,
			leftAdditionalOperator: this.state.leftAdditionalOperator ?? null,
			rightAdditionalOperator: this.state.rightAdditionalOperator ?? null,
			leftAdditionalValue: this.state.leftAdditionalValue ?? null,
			rightAdditionalValue: this.state.rightAdditionalValue ?? null,
			leftRangeValue: this.state.leftRangeValue ?? null,
			rightRangeValue: this.state.rightRangeValue ?? null,
			additionalParameter: this.state.additionalParameter ?? null,
			additionalParameters: this.state.additionalParameters ?? null,
			selectedTable: this.state.selectedTable ?? null,
			userValues: this.state.userValues ?? null,
			conditions: this.state.conditions ?? null,
			journeyName: this.props.journeyName,
			dynamic: this.props.addToCanvas,
			showIntervals: this.state.showIntervals ?? null,
		};
		if (this.props.addToCanvas) {
			config.dynamicOptions = this.prepareDynamicOptions(
				this.state.dynamicOptions,
				config
			);
		}
		return config;
	}
	onNewFinding(finding) {
		this.setState({
			finding: finding,
			userValues: finding?.config?.userValues,
		});
	}
	back() {
		this.setState({ animationDirection: "bottom" }, () => {
			this.setState((state) => ({
				step: state.previousStep,
				finding: undefined,
				leftAdditionalValueSelected: false,
				rightAdditionalValueSelected: false,
			}));
		});
	}
	showLegend() {
		for (var i in this.state.regressionInfo[4]) {
			if (this.state.regressionInfo[4][i]) {
				return true;
			}
		}
		return false;
	}
	buildContent() {
		switch (this.state.step) {
			case this.steps.initial:
				return this.buildInitial(
					"How do you want to examine your data?"
				);
			case this.steps.selectDataScope:
				return this.buildDataScopesSelector(true);
			case this.steps.selectTable:
				return this.buildTableSelector();
			case this.steps.selectConditions:
				return this.buildConditionsSelector();
			case this.steps.selectIndependent:
				return this.buildStepIndependentVariableSelector();
			case this.steps.selectDependent:
				return this.buildStepDependentVariableSelector();
			case this.steps.selectOptional:
				return this.buildStepOptionalSelector();
			case this.steps.selectHoldOutOptions:
				return this.buildStepHoldOutPredictionSelection();
			case this.steps.selectAdditionalParameter:
				return this.buildVariablesSelector(
					"by comparing the effect across",
					"additionalParameter",
					"additionalParameters",
					false,
					DynamicOptionType.additionalParameter,
					"additionalParameterIndex"
				);
			case this.steps.selectAdditionalValue:
				return this.buildAdditionalValueSelector(
					"and how they vary between",
					true
				);
			case this.steps.final:
				return (
					<StepFinal
						onNewFinding={this.onNewFinding}
						onShowRecipesPopup={this.showRecipesPopup}
						loading={this.state.loading}
						parent={this}
						onBack={this.back}
						onSaveFinding={this.saveFinding}
						finding={this.state.finding}
					/>
				);

			default:
				return <div />;
		}
	}

	buildLeftBar() {
		return super.buildLeftBar(this.state.step < this.steps.final);
	}
	getData() {
		let config = this.getConfig();
		this.setState({ loading: true });
		Api.getData(config, undefined, this.props.currentModuleId)
			.then((item) => {
				this.setState({
					finding: item,
					loading: false,
				});
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
			});
	}

	initializeVariables() {
		let scopeVariables = this.getVariables();
		let allVariablesWithUnits = scopeVariables.dataVariables;
		let regressorVariables = scopeVariables.numericVariables;
		this.setState({
			additionalParameters: [null].concat(
				scopeVariables.dataVariables.map((item) => item.name)
			),
			allVariables: allVariablesWithUnits.map((item) => item.name),
			allVariablesWithUnits: allVariablesWithUnits,
			regressionVariables: regressorVariables.map((item) => item.name),
			variablesWithUnits: regressorVariables,
		});
	}

	switchVariables() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
		this.initializeVariables();
		this.initializeVariablesReaction = reaction(
			() => this.getVariables().dataVariables,
			() => {
				this.initializeVariables();
			}
		);
	}

	componentDidMount() {
		this.getLastJourneyConfig();
	}
	componentWillUnmount() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
	}
	selectVariable(variableName, variablesName, index) {
		if (index >= this.state[variablesName].length) return;
		if (index < 0) return;
		let stateDiff = {
			[variableName]: this.state[variablesName][index],
		};
		if (this.state.step === this.steps.selectAdditionalParameter) {
			stateDiff.leftAdditionalValue = undefined;
			stateDiff.rightAdditionalValue = undefined;
		}
		if (this.state.step < this.steps.selectOptional) {
			stateDiff.regressionInfo = undefined;
			stateDiff.restIndependentVariables = [];
		}
		this.setState(stateDiff, () => {
			setTimeout(() => {
				this.stepDown();
			}, 100);
		});
	}

	getSubtitle() {
		let conditionsString = conditionsToString(
			Array.from(this.state.conditions)
		);
		if (
			this.state.step === this.steps.selectDataScope ||
			this.state.step === this.steps.selectTable
		)
			return "How do you want to examine your levers data?";

		if (
			this.state.step > this.steps.selectTable &&
			this.state.step < this.steps.selectOptional
		) {
			let subtitle = `For the data in ${
				this.state.dataScope ? this.state.dataScope.label : ""
			} ${this.state.selectedTable.label}`;
			if (this.state.step > this.steps.selectConditions) {
				if (conditionsString.length > 0)
					subtitle += ` and under the conditions ${conditionsString}`;
			}
			if (this.state.step > this.steps.selectIndependent)
				subtitle += ` I want to see the effect of an increase of ${
					this.state.selectedIndependentVariable || ""
				}`;
			return subtitle;
		}
		if (this.state.step > this.steps.selectDependent) {
			return (
				<>
					<span>For the data </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.dataScope
							? this.state.dataScope.label.concat(" ")
							: ""}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedTable.label}
					</span>
					{conditionsString.length > 0 && (
						<>
							<span> and under the conditions </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{conditionsString}
							</span>
						</>
					)}
					<span>
						{" I want to see the effect of an increase of "}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedIndependentVariable || ""}
					</span>
					<span>{" leading to change in "}</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedDependentVariable || ""}
					</span>
					{this.state.restIndependentVariables.length > 0 ? (
						<>
							<span>{" while controlling for "}</span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.restIndependentVariables.join(", ")}
							</span>
						</>
					) : null}
					{this.state.additionalParameter ? (
						<>
							<span>{" by comparing the effect across "}</span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.additionalParameter}
							</span>
							<span>{" and how they vary between "}</span>
							{this.state.leftAdditionalValue ? (
								<span
									style={{
										color: mainStyle.getPropertyValue(
											"--exploration-secondary-text-color"
										),
									}}
								>
									{this.state.leftAdditionalValue.label}
								</span>
							) : null}
							{this.state.leftAdditionalValue &&
							this.state.rightAdditionalValue ? (
								<span>{" and "}</span>
							) : null}
							{this.state.rightAdditionalValue ? (
								<span
									style={{
										color: mainStyle.getPropertyValue(
											"--exploration-secondary-text-color"
										),
									}}
								>
									{this.state.rightAdditionalValue.label}
								</span>
							) : null}
						</>
					) : null}
					{this.state.step === this.steps.final && <span>.</span>}
				</>
			);
		}
	}
	showFinalStep() {
		let regressionInfo = this.refs.runRegressionComponent
			? this.refs.runRegressionComponent.getRegressionInfo()
			: this.state.regressionInfo;
		if (!regressionInfo) return;
		this.setState({ animationDirection: "top" }, () => {
			this.setState(
				(state) => ({
					regressionInfo: regressionInfo,
					previousStep: state.step,
					step: this.steps.final,
				}),
				() => {
					this.getData();
					this.saveNewJourneyConfig();
				}
			);
		});
	}

	getFooterTitle() {
		switch (this.state.step) {
			case this.steps.initial:
				return "... For the data in...";
			case this.steps.selectTable:
				return "... and under the conditions...";
			case this.steps.selectConditions:
				return "... I want to see the effect of an increase of ...";
			case this.steps.selectIndependent:
				return "... leading to change in ...";
			case this.steps.selectDependent:
				return "";
			case this.steps.selectOptional:
				return "[optional]... by comparing the effect across ...";
			case this.steps.selectAdditionalParameter:
				return "... and how they vary between ...";
			default:
				return "";
		}
	}

	buildStepIndependentVariableSelector() {
		return this.buildCustomVariablesSelector(
			"I want to see the effect of an increase of",
			"selectedIndependentVariable",
			[undefined],
			"allVariables"
		);
	}

	buildStepDependentVariableSelector() {
		return this.buildCustomVariablesSelector(
			"leading to change in",
			"selectedDependentVariable",
			["selectedIndependentVariable"],
			"regressionVariables"
		);
	}

	buildStepHoldOutPredictionSelection() {
		return (
			<div
				className="my-row"
				style={{
					width: "100%",
				}}
			>
				{this.buildLeftBar()}
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<div
						className="flex-simple-column"
						style={{
							width: "100%",
							marginLeft: 20,
							marginRight: 20,
						}}
					>
						<div
							className="element"
							style={{
								width: "100%",
								height: "350px",
								overflowY: "auto",
							}}
						>
							<div
								className="my-row"
								style={{ alignItems: "center", marginTop: 20 }}
							>
								<span
									className="text-center"
									style={{
										color: mainStyle.getPropertyValue(
											"--exploration-primary-text-color"
										),
										fontFamily: "Arial",
										fontSize: "12px",
									}}
								>
									Enter dataset fraction
								</span>
								<input
									onKeyDown={(evt) => {
										evt.stopPropagation();
									}}
									step="1"
									min="1"
									max="100"
									style={{ marginLeft: 10, width: "50px" }}
									className="input-range"
									type="number"
									defaultValue={this.state.datasetFraction}
									placeholder={""}
									onChange={(evt) => {
										let number = evt.target.value;

										this.setState({
											datasetFraction: Math.max(
												1,
												Math.min(100, number)
											),
										});
									}}
								/>
							</div>
							<div
								className="my-row"
								style={{ alignItems: "center", marginTop: 20 }}
							>
								<span
									className="text-center"
									style={{
										color: mainStyle.getPropertyValue(
											"--exploration-primary-text-color"
										),
										fontFamily: "Arial",
										fontSize: "12px",
									}}
								>
									Enter confidence level
								</span>
								<input
									onKeyDown={(evt) => {
										evt.stopPropagation();
									}}
									step="1"
									min="1"
									max="100"
									style={{ marginLeft: 10, width: "50px" }}
									className="input-range"
									type="number"
									defaultValue={this.state.confidenceLevel}
									placeholder={""}
									onChange={(evt) => {
										let number = evt.target.value;

										this.setState({
											confidenceLevel: Math.max(
												1,
												Math.min(100, number)
											),
										});
									}}
								/>
							</div>
							<div
								className="my-row"
								style={{ alignItems: "center", marginTop: 20 }}
							>
								<span
									className="text-center"
									style={{
										color: mainStyle.getPropertyValue(
											"--exploration-primary-text-color"
										),
										fontFamily: "Arial",
										fontSize: "12px",
									}}
								>
									Show true value
								</span>
								<div
									style={{
										marginLeft: 10,
									}}
								>
									<Switch
										onChange={() => {
											this.setState((state) => ({
												showTrueValue: !state.showTrueValue,
											}));
										}}
										checked={this.state.showTrueValue}
										width={26}
										height={13}
										offColor="#20293C"
										onColor="#20293C"
										checkedIcon={false}
										uncheckedIcon={false}
										offHandleColor="#70889E"
										onHandleColor="#1F8EFA"
									/>
								</div>
							</div>
							<div
								className="my-row"
								style={{
									alignItems: "center",
									marginTop: 20,
								}}
							>
								<span
									className="text-center"
									style={{
										color: mainStyle.getPropertyValue(
											"--exploration-primary-text-color"
										),
										fontFamily: "Arial",
										fontSize: "12px",
									}}
								>
									Show lever bars
								</span>
								<div
									style={{
										marginLeft: 10,
									}}
								>
									<Switch
										onChange={() => {
											this.setState((state) => ({
												showLeverBars: !state.showLeverBars,
											}));
										}}
										checked={this.state.showLeverBars}
										width={26}
										height={13}
										offColor="#20293C"
										onColor="#20293C"
										checkedIcon={false}
										uncheckedIcon={false}
										offHandleColor="#70889E"
										onHandleColor="#1F8EFA"
									/>
								</div>
							</div>
							<div
								className="my-row"
								style={{
									alignItems: "center",
									marginTop: 20,
								}}
							>
								<span
									className="text-center"
									style={{
										color: mainStyle.getPropertyValue(
											"--exploration-primary-text-color"
										),
										fontFamily: "Arial",
										fontSize: "12px",
									}}
								>
									Cache data
								</span>
								<div
									style={{
										marginLeft: 10,
									}}
								>
									<Switch
										onChange={() => {
											this.setState((state) => ({
												cacheData: !state.cacheData,
											}));
										}}
										checked={this.state.cacheData}
										width={26}
										height={13}
										offColor="#20293C"
										onColor="#20293C"
										checkedIcon={false}
										uncheckedIcon={false}
										offHandleColor="#70889E"
										onHandleColor="#1F8EFA"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	buildStepOptionalSelector() {
		return (
			<div
				className="my-row"
				style={{
					width: "100%",
				}}
			>
				{this.buildLeftBar()}
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<div
						className="flex-simple-column"
						style={{
							width: "100%",
							marginLeft: 20,
							marginRight: 20,
						}}
					>
						<div
							className="element"
							style={{
								width: "100%",
								height: "350px",
								overflowY: "auto",
							}}
						>
							<RunHoldOutRegressionComponent
								showDynamicColumn
								ref="runRegressionComponent"
								type={PredictiveAnalyticsType.interaction}
								latestRegressionInfo={this.state.regressionInfo}
								allVariables={this.state.allVariablesWithUnits}
								regressionVariables={
									this.state.variablesWithUnits
								}
								dependentVariable={
									this.state.selectedDependentVariable
								}
								independentVariable={
									this.state.selectedIndependentVariable
								}
								onDependentVariableChanged={(
									dependentVariable
								) => {
									this.setState({
										selectedDependentVariable: dependentVariable,
									});
								}}
								onIndependentVariablesChanged={(
									independentVariables
								) => {
									this.setState({
										selectedIndependentVariable:
											independentVariables[0],
										restIndependentVariables: independentVariables.slice(
											1
										),
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	saveFinding() {
		if (!this.state.finding) return;
		let item = Api.getFinding(
			{
				regressionResult: this.state.finding.content.regressionResult,
				config: this.state.finding.config,
			},
			{
				type: "14",
			}
		);

		this.props.onNewFinding(item);
	}

	stepDown() {
		if (
			this.state.step === this.steps.selectTable &&
			!this.state.selectedTable
		) {
			return;
		}
		if (
			this.state.step === this.steps.selectAdditionalParameter &&
			!this.state.additionalParameter
		) {
			this.showFinalStep();
			return;
		}
		if (this.state.step === this.steps.selectAdditionalValue) {
			this.showFinalStep();
			return;
		}
		this.setState({ animationDirection: "top" }, () => {
			let regressionInfo = this.refs.runRegressionComponent
				? this.refs.runRegressionComponent.getRegressionInfo()
				: this.state.regressionInfo;
			this.setState({
				regressionInfo: regressionInfo,
				previousStep: this.state.step,
				step: this.state.step + 1,
			});
		});
	}

	stepUp() {
		if (this.state.step > this.steps.initial)
			this.setState({ animationDirection: "bottom" }, () => {
				this.setState(
					(state) => ({
						step: state.step - 1,
					}),
					() => {}
				);
			});
	}

	render() {
		return (
			<div
				className="dashboard-rect-journey-focus"
				tabIndex="0"
				style={{
					height: 700,
					overflow: "hidden",
					display: !this.props.collapsed ? "block" : "none",
				}}
				onClick={() => {
					if (this.state.step === 0) this.stepDown();
				}}
				onKeyDown={(evt) => {
					if (evt.key === "Escape") {
						this.props.onClose();
						return;
					}

					if (evt.key === "ArrowDown") {
						if (
							this.state.step > 0 &&
							this.state.step < this.steps.final
						)
							this.stepDown();
						evt.preventDefault();
						return;
					}
					if (evt.key === "ArrowUp") {
						if (this.state.step < this.steps.final) this.stepUp();
						evt.preventDefault();
						return;
					}
					if (evt.key === "E") {
						if (this.state.step === this.steps.selectOptional) {
							if (this.refs.runRegressionComponent) {
								this.refs.runRegressionComponent.changeEditModel();
							}
						}
					}

					if (evt.key === "p") {
						if (this.state.step === this.steps.final) {
							this.saveFinding();
						}
					}
					if (evt.key === "d") {
						if (this.state.step === this.steps.final) {
							evt.preventDefault();
							this.showRecipesPopup();
						}
					}
					if (evt.key === "ArrowLeft") {
						if (this.state.step === this.steps.final) {
							this.back();
						}
					}
					if (evt.key === "ArrowRight") {
						if (
							this.state.step > this.steps.initial &&
							this.state.step < this.steps.final
						) {
							this.showFinalStep();
						}
					}

					let charCode = evt.key.charCodeAt(0) - "a".charCodeAt(0);
					if (this.state.step === this.steps.selectIndependent) {
						if (
							charCode >= 0 &&
							charCode < this.state.regressionVariables.length
						) {
							this.selectVariable(
								"selectedIndependentVariable",
								"regressionVariables",
								charCode
							);
						}
					}
					if (this.state.step === this.steps.selectDependent) {
						let independentVariableIndex = this.state.regressionVariables.indexOf(
							this.state.selectedIndependentVariable
						);

						if (charCode === independentVariableIndex) return;
						if (
							charCode >= 0 &&
							charCode < this.state.regressionVariables.length
						) {
							this.selectVariable(
								"selectedDependentVariable",
								"regressionVariables",
								charCode
							);
						}
					}
					if (
						this.state.step === this.steps.selectAdditionalParameter
					) {
						this.selectVariable(
							"additionalParameter",
							"additionalParameters",
							charCode
						);
					}

					if (
						this.state.step === this.steps.selectAdditionalValue &&
						this.getVariables().isMonthVariable(
							this.state.additionalParameter
						)
					) {
						this.selectMonth(evt.key, true);
					}
					if (evt.key === "Enter") {
						if (
							this.state.step ===
								this.steps.selectAdditionalValue &&
							this.getVariables().isMonthVariable(
								this.state.additionalParameter
							) &&
							this.state.leftAdditionalValue &&
							!this.state.leftAdditionalValueSelected
						) {
							this.setState({
								leftAdditionalValueSelected: true,
							});
							return;
						}
						if (
							this.state.step ===
								this.steps.selectAdditionalValue &&
							this.getVariables().isMonthVariable(
								this.state.additionalParameter
							) &&
							this.state.leftAdditionalValueSelected &&
							this.state.rightAdditionalValue
						) {
							this.setState(
								{
									rightAdditionalValueSelected: true,
								},
								() => {
									this.showFinalStep();
								}
							);
							return;
						}
					}
				}}
			>
				<div
					className="my-row"
					style={{ justifyContent: "space-between", height: "100%" }}
				>
					<div
						className="flex-column"
						style={{
							height: "100%",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						{this.buildHeader()}
						<TransitionGroup
							style={{
								minHeight: "inherit",
							}}
						>
							<CSSTransition
								style={{
									height: "100%",
								}}
								key={this.state.step}
								timeout={500}
								classNames={"journeywizard-".concat(
									this.state.animationDirection || ""
								)}
							>
								<div
									style={{
										height: "100%",
									}}
								>
									{this.buildContent()}
								</div>
							</CSSTransition>
						</TransitionGroup>
						<Footer
							showArrow={
								this.state.step > this.steps.initial &&
								this.state.step < this.steps.final
							}
							title={this.getFooterTitle()}
						/>
					</div>
				</div>
				{this.buildAddToRecipesPopup()}
			</div>
		);
	}
}

export { MainComponent };