import React, { Component } from "react";
import Popup from "reactjs-popup";
import Switch from "react-switch";
import { mainStyle } from "common/MainStyle";
import OutsideAlerter from "common/OutsideAlerter";
import ColorPicker from "common/ColorPicker";
import Draggable from "react-draggable";
import { Button } from "react-bootstrap";
import { ColorOptions, GridColorOptions, MapColorOptions } from "common/Canvas";

interface Props {
    currentZoomLevel?: number;
    isOldMap?: boolean;
    isSpreadSheet?: boolean;
    options: ColorOptions | GridColorOptions | MapColorOptions;
    onClose: (
        apply: boolean,
        options?: ColorOptions | GridColorOptions | MapColorOptions | null
    ) => void;
}

interface State {
    left: number;
    top: number;
    options: ColorOptions | GridColorOptions | MapColorOptions;
}

class ColorOptionsPopup extends Component<Props, State> {
    drag: boolean = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            options: this.props.options,
        };
    }

    private buildInnerView(height: number): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    overflow: "visible",
                    paddingTop: "20px",
                    marginLeft: "38px",
                    height: height,
                }}
            >
                <div
                    className="my-row"
                    style={{
                        width: "200px",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Arial",
                            fontSize: "17px",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            width: "120px",
                        }}
                    >
                        {"Border shadow"}
                    </span>
                    <div style={{ marginLeft: 6 }}>
                        <Switch
                            onChange={(checked) => {
                                this.setState((state) => ({
                                    options: {
                                        ...state.options,
                                        borderShadow: checked,
                                    },
                                }));
                            }}
                            checked={this.state.options.borderShadow ?? false}
                            width={26}
                            height={13}
                            offColor="#20293C"
                            onColor="#20293C"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#70889E"
                            onHandleColor="#1F8EFA"
                        />
                    </div>
                </div>
                <div
                    className="my-row"
                    style={{
                        marginTop: "10px",
                        width: "200px",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Arial",
                            fontSize: "17px",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            width: "120px",
                        }}
                    >
                        {"Fill Color"}
                    </span>
                    <ColorPicker
                        enableAlpha
                        value={this.state.options.fillColor}
                        onChange={(newValue) => {
                            this.setState((state) => ({
                                options: {
                                    ...state.options,
                                    fillColor: newValue,
                                },
                            }));
                        }}
                        style={{
                            marginLeft: "6px",
                        }}
                    />
                </div>
                <div
                    className="my-row"
                    style={{
                        marginTop: "10px",
                        width: "200px",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Arial",
                            fontSize: "17px",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            width: "120px",
                        }}
                    >
                        {this.props.isSpreadSheet
                            ? "Divider Color"
                            : "Border Color"}
                    </span>
                    <ColorPicker
                        enableAlpha
                        value={this.state.options.borderColor}
                        onChange={(newValue) => {
                            this.setState((state) => ({
                                options: {
                                    ...state.options,
                                    borderColor: newValue,
                                },
                            }));
                        }}
                        style={{
                            marginLeft: "6px",
                        }}
                    />
                </div>
                {this.props.isSpreadSheet && (
                    <div
                        className="my-row"
                        style={{
                            marginTop: "10px",
                            width: "200px",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                fontFamily: "Arial",
                                fontSize: "17px",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                width: "120px",
                            }}
                        >
                            {"Text Color"}
                        </span>
                        <ColorPicker
                            enableAlpha
                            value={
                                (this.state.options as GridColorOptions)
                                    .textColor
                            }
                            onChange={(newValue) => {
                                this.setState((state) => ({
                                    options: {
                                        ...state.options,
                                        textColor: newValue,
                                    },
                                }));
                            }}
                            style={{
                                marginLeft: "6px",
                            }}
                        />
                    </div>
                )}
                {this.props.isOldMap && (
                    <div
                        className="my-row"
                        style={{
                            marginTop: "10px",
                            width: "200px",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                fontFamily: "Arial",
                                fontSize: "17px",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                width: "120px",
                            }}
                        >
                            {"Lock Map Area"}
                        </span>
                        <div style={{ marginLeft: 6 }}>
                            <Switch
                                onChange={(checked) => {
                                    this.setState((state) => ({
                                        options: {
                                            ...state.options,
                                            lockMap: checked,
                                        },
                                    }));
                                }}
                                checked={
                                    (this.state.options as MapColorOptions)
                                        .lockMap ?? false
                                }
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                    </div>
                )}
                {this.props.isOldMap && (
                    <div
                        className="my-row"
                        style={{
                            marginTop: "10px",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                fontFamily: "Arial",
                                fontSize: "17px",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                            }}
                        >
                            {"Prevent zooming above current level"}
                        </span>
                        <div style={{ marginLeft: 6 }}>
                            <Switch
                                onChange={(checked) => {
                                    this.setState((state) => ({
                                        options: {
                                            ...state.options,
                                            minZoomLevel: checked
                                                ? this.props.currentZoomLevel ??
                                                  null
                                                : null,
                                        },
                                    }));
                                }}
                                disabled={this.props.currentZoomLevel == null}
                                checked={
                                    (this.state.options as MapColorOptions)
                                        .minZoomLevel != null
                                }
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                    </div>
                )}
                {this.props.isOldMap && (
                    <div
                        className="my-row"
                        style={{
                            marginTop: "10px",
                            width: "200px",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                fontFamily: "Arial",
                                fontSize: "17px",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                width: "120px",
                            }}
                        >
                            {"Grayscale"}
                        </span>
                        <div style={{ marginLeft: 6 }}>
                            <Switch
                                onChange={(checked) => {
                                    this.setState((state) => ({
                                        options: {
                                            ...state.options,
                                            grayscale: checked,
                                        },
                                    }));
                                }}
                                checked={
                                    (this.state.options as MapColorOptions)
                                        .grayscale ?? false
                                }
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                    </div>
                )}
                <div style={{ flexGrow: 1 }} />
                <div
                    className="my-row"
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        marginRight: 20,
                        alignSelf: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose(true, null);
                        }}
                    >
                        DEFAULT
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose(false);
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose(true, this.state.options);
                        }}
                    >
                        DONE
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const height: number = 500;
        const width: number = 950;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                open={true}
                onClose={() => {
                    this.props.onClose(false);
                }}
                closeOnDocumentClick
            >
                <Draggable
                    cancel="input"
                    position={{
                        x: this.state.left,
                        y: this.state.top,
                    }}
                    onDrag={() => {
                        this.drag = true;
                    }}
                    onStop={(evt, data) => {
                        if (this.drag) {
                            this.drag = false;
                            this.setState({
                                left: data.x,
                                top: data.y,
                            });
                        }
                    }}
                >
                    <div
                        className="dashboard-rect element"
                        style={{
                            overflowX: "visible",
                            overflowY: "auto",
                            boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                            borderRadius: 0,
                            alignItems: "center",
                            cursor: "pointer",
                            height: height,
                            width: width,
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt: any) => {
                            evt.stopPropagation();
                        }}
                    >
                        <OutsideAlerter
                            onReject={() => {
                                this.props.onClose(false);
                            }}
                        >
                            {this.buildInnerView(height)}
                        </OutsideAlerter>
                    </div>
                </Draggable>
            </Popup>
        );
    }
}
export default ColorOptionsPopup;
