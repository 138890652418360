import axios from "./ServerConnection";
import { Permission } from "common/Permissions";

export async function addEmailInvitation(
    moduleId: number | string | undefined,
    entryId: number | undefined,
    permissionType: Permission | undefined,
    email: string
): Promise<string> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: string;
        }>("/api/email_invitation_add", {
            module_id: moduleId,
            entry_id: entryId,
            permission_type: permissionType,
            email: email,
        })
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                // amplitude.getInstance().logEvent("Shared presentation", {
                // 	type: "regular"
                // });
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function emailInvitationVerify(linkId: string): Promise<string> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            email: string;
        }>("/api/e/email_invitation_verify", {
            id: linkId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.email);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function removeEmailInvitation(
    linkId: string,
    moduleId: number | string | undefined,
    entryId: number | undefined
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/email_invitation_remove", {
            id: linkId,
            module_id: moduleId,
            entry_id: entryId,
        })
        .then((response) => {
            if (response.data.success) {
                // amplitude.getInstance().logEvent("Shared presentation", {
                // 	type: "regular"
                // });
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
