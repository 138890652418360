import React, { useState } from "react";
import { mainStyle } from "common/MainStyle";
import TextareaAutosize from "react-autosize-textarea";
import { TextQuestion } from "common/Canvas";
import AutosizeInput from "react-input-autosize";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";

interface Props {
    question: TextQuestion;
    maxHeight: number;
    live: boolean;
    frozen: boolean;
    onChange: (question: TextQuestion) => void;
    onNext: (question: TextQuestion) => void;
    hideLineBreak?: boolean;
    hideTextUnder?: boolean;
    singleLine?: boolean;
    isPassword?: boolean;
}

export default function TextQuestionView(props: Props) {
    let [answer, setAnswer] = useState(props.question.answer);
    let hideLineBreak = props.hideLineBreak ?? false;
    let isPassword = props.isPassword ?? false;
    let singleLine = props.singleLine ?? false;
    let hideTextUnder = props.hideTextUnder ?? false;
    return (
        <div
            className="flex-simple-column"
            style={{ maxHeight: props.maxHeight, marginLeft: "90px" }}
        >
            {isPassword || singleLine ? (
                <AutosizeInput
                    type={isPassword ? "password" : undefined}
                    autoFocus
                    className="element"
                    placeholder={props.live ? "Type your response" : ""}
                    disabled={!props.live}
                    inputStyle={{
                        fontSize: mainStyle.getPropertyValue(
                            "--primary-path-title-size"
                        ),
                        border: "none",
                        resize: "none",
                        outline: "none",
                        backgroundColor: "transparent",
                        fontFamily: "Roboto",
                        color: dataScienceElementsStyle.secondaryTextColor,
                    }}
                    value={answer}
                    onMouseDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    onChange={(evt) => {
                        // We have to read name before setState, because evt.target.value might be undefined during setState
                        let value = evt.currentTarget.value;
                        setAnswer(value);
                    }}
                    onBlur={(evt) => {
                        if (evt.target.getAttribute("preventBlur") !== "true") {
                            let newQuestion = Object.assign({}, props.question);
                            newQuestion.answer = answer;
                            props.onChange(newQuestion);
                        } else {
                            evt.target.removeAttribute("preventBlur");
                        }
                    }}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                        if (evt.key === "Enter" && evt.shiftKey) {
                            evt.preventDefault();
                            evt.currentTarget.setAttribute(
                                "preventBlur",
                                "true"
                            );
                            let newQuestion = Object.assign({}, props.question);
                            newQuestion.answer = answer;
                            if (props.live) props.onNext(newQuestion);
                        }
                    }}
                />
            ) : (
                <TextareaAutosize
                    autoFocus
                    className="element"
                    placeholder={props.live ? "Type your response" : ""}
                    disabled={!props.live}
                    style={{
                        fontSize: mainStyle.getPropertyValue(
                            "--primary-path-title-size"
                        ),
                        border: "none",
                        resize: "none",
                        outline: "none",
                        backgroundColor: "transparent",
                        fontFamily: "Roboto",
                        color: dataScienceElementsStyle.secondaryTextColor,
                    }}
                    value={answer}
                    onMouseDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    onChange={(evt) => {
                        // We have to read name before setState, because evt.target.value might be undefined during setState
                        let value = evt.currentTarget.value;
                        setAnswer(value);
                    }}
                    onBlur={(evt) => {
                        if (evt.target.getAttribute("preventBlur") !== "true") {
                            let newQuestion = Object.assign({}, props.question);
                            newQuestion.answer = answer;
                            props.onChange(newQuestion);
                        } else {
                            evt.target.removeAttribute("preventBlur");
                        }
                    }}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                        if (evt.key === "Enter" && evt.shiftKey) {
                            evt.preventDefault();
                            evt.currentTarget.setAttribute(
                                "preventBlur",
                                "true"
                            );
                            let newQuestion = Object.assign({}, props.question);
                            newQuestion.answer = answer;
                            if (props.live) props.onNext(newQuestion);
                        }
                    }}
                />
            )}
            <div
                style={{
                    height: "2px",
                    backgroundColor: dataScienceElementsStyle.secondaryTextColor,
                }}
            ></div>
            {props.live && (
                <>
                    {!hideLineBreak && !singleLine && !hideTextUnder && (
                        <span
                            style={{
                                marginTop: 10,
                                alignSelf: "flex-end",
                                cursor: "pointer",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: dataScienceElementsStyle.secondaryTextColor,
                            }}
                        >
                            [ ENTER ] to make line break
                        </span>
                    )}
                    {!props.hideTextUnder && (
                        <span
                            style={{
                                marginTop: 10,
                                alignSelf: "flex-end",
                                cursor: "pointer",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: dataScienceElementsStyle.secondaryTextColor,
                            }}
                        >
                            [ SHIFT ] + [ ENTER ] for next question
                        </span>
                    )}
                </>
            )}
        </div>
    );
}