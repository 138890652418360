import React, { useState } from "react";

import Finding, { LeadersLaggersLogosColumnData } from "common/Finding";
import { MDBTable } from "mdbreact";
import { MDBTableHead } from "mdbreact";
import { MDBTableBody } from "mdbreact";
import ColorPicker from "common/ColorPicker";
import styles from "./LeaderboardSection.module.css";

interface Props {
  finding: Finding
  onChange: (finding: Finding, updateData?: boolean) => void;
}

function LogosEditor(props: Props) {
	const [showAllLogos, setShowAllLogos] = useState(false);
	const logosColumnData: LeadersLaggersLogosColumnData[] = props.finding.config.logosColumnData;
	const columns = ["", "Color", "Text Color", "Abbrevation"]
    return (
		<>
			{logosColumnData && (
				<>
					<p
						style={{
							margin: "10px 0 30px 7px",
						}}
					>
						Logo
					</p>
					<div
						className={styles.logosEditorContainer}
						style={{
							overflow: showAllLogos ? "auto" : "hidden",
						}}
					>
						<MDBTable
							autoWidth
							className="legend-table"
							style={{ emptyCells: "show", marginBottom: 0, width: "100%" }}
						>
							<MDBTableHead>
								<tr>
									{columns.map((column, idx) => {
										return (
											<th
												key={idx}
												style={{ textAlign: "center", color: "#000", fontWeight: 400 }}
											>
												<p>
													{column}
												</p>
											</th>
										)
									})}
								</tr>
							</MDBTableHead>

							<MDBTableBody className={styles.logosEditorContainer}>
								{logosColumnData?.map((logo, idx) => {
										return (
											<tr
												key={idx}
											>
												<td>
													{logo.label}
												</td>
												<td>
													<div className={styles.logoInfoItem}>
														<ColorPicker
															inPopup
															enableAlpha
															value={logo.background ?? "white"}
															onChange={(value) => {
																const newFinding = {
																	...props.finding,
																}
																newFinding.config.logosColumnData[idx].background = value;
																props.onChange(newFinding, true);
															}}
														/>
													</div>
												</td>
												<td>
													<div className={styles.logoInfoItem}>
														<ColorPicker
															inPopup
															enableAlpha
															value={logo.color ?? "white"}
															onChange={(value) => {
																const newFinding = {
																	...props.finding,
																}
																newFinding.config.logosColumnData[idx].color = value;
																props.onChange(newFinding, true);
															}}
														/>
													</div>
												</td>
												<td>
													<div className={styles.logoInfoItem}>
														<input
															type="text"
															className={styles.logoInput}
															placeholder={logo.textLogo ?? "A"}
															maxLength={1}
															onChange={(e) => {
																e.preventDefault();
																const newFinding = {
																	...props.finding,
																}
																newFinding.config.logosColumnData[idx].textLogo = e.target.value;
																props.onChange(newFinding, true);
															}}
														/>
													</div>
												</td>
											</tr>
										)
									})}
							</MDBTableBody>
						</MDBTable>
					</div>

					{logosColumnData?.length > 3 && (
						<div className={styles.showMoreBtnContainer}>
							<button
								className="btn btn-primary my-primary"
								onClick={() => {
									setShowAllLogos(!showAllLogos);
								}}
							>{showAllLogos ? "Hide" : "Show more"}</button>
						</div>
					)}
				</>
			)}
        </>
    );
}

export default LogosEditor;
