import React, { useState, CSSProperties } from "react";
import { useDrop } from "react-dnd";
import { CorrelogramFinding, formatValues } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./CorrelogramSection.module.css";
import { Button } from "react-bootstrap";
import EditInput from "../EditInput";
import cx from "classnames";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import MagicWand from "../MagicWand";
import { DynamicOptionType } from "common/DynamicOptions";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import _ from "lodash";

interface Props {
    columnDragActive: boolean;
    finding: CorrelogramFinding;
    onChange: (finding: CorrelogramFinding, updateData?: boolean) => void;
    onClearEditing: () => void;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
}

interface DropProps {
    index: number;
    onDrop: (
        index: number,
        variableName: string,
        variableIndex: number,
        variableType: string
    ) => void;
    optionalClassName?: string;
    optionalStyles?: CSSProperties;
}

const DropArea: React.FC<DropProps> = (props) => {
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                    variableType: string;
                };
            },
            monitor
        ) {
            props.onDrop(
                props.index,
                otherItem.content.variableName,
                otherItem.content.variableIndex,
                otherItem.content.variableType
            );
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    return (
        <div
            className={props.optionalClassName}
            style={{
                backgroundColor: collected.hover ? "#F4FBF5" : undefined,
                ...props.optionalStyles,
            }}
            ref={drop}
        >
            {props.children}
        </div>
    );
};

function DataTable(props: {
    columnDragActive: boolean;
    finding: CorrelogramFinding;
    onChange: (finding: CorrelogramFinding, updateData?: boolean) => void;
    setErrorMessage: (value: { message: string; status: PopupStatus }) => void;
    linkVariable: (
        index: number,
        variableName: string,
        variableIndex: number,
        variableType: string
    ) => void;
}) {
    const { linkVariable } = props;
    let datasetIsConnected = props.finding.config.dataScope != null;
    let elements: JSX.Element[] = [];
    if (datasetIsConnected) {
        for (let i = 0; i < props.finding.content.data.length; ++i) {
            const value = props.finding.content.data[i]?.value
            elements.push(
                <React.Fragment key={i}>
                    <DropArea
                        optionalClassName={cx(styles.dropArea, {
                            [styles.highlightDropArea]: props.columnDragActive,
                        })}
                        onDrop={linkVariable}
                        index={i}
                    >
                        <EditInput
                            showDeleteButton={true}
                            value={props.finding.content.data[i]?.name}
                            onChange={(value) => {
                                let finding: CorrelogramFinding = {
                                    ...props.finding!,
                                    content: {
                                        ...props.finding!.content,
                                        data: props.finding!.content.data.map(
                                            (item) => ({
                                                ...item,
                                                value: [...item.value],
                                            })
                                        ),
                                    },
                                };
                                finding.content.data[i].name = String(value);
                                props.onChange(finding);
                            }}
                            onDelete={() => {
                                let finding: CorrelogramFinding = {
                                    ...props.finding!,
                                    content: {
                                        ...props.finding!.content,
                                        data: [...props.finding!.content.data],
                                    },
                                };
                                finding.content.data.splice(i, 1);
                                props.onChange(finding, true);
                            }}
                        />
                    </DropArea>
                    <EditInput
                        showDeleteButton={false}
                        value={
                            props.finding.content.data[i]
                                .variableIndex == null
                                ? String(value)
                                : formatValues(value)
                        }
                        onChange={(value) => {
                            let finding: CorrelogramFinding = {
                                ...props.finding!,
                                content: {
                                    ...props.finding!.content,
                                    data: props.finding!.content.data.map(
                                        (item) => ({
                                            ...item,
                                            value: [...item.value],
                                        })
                                    ),
                                },
                            };
                            finding.content.data[i].value = String(value).split(",").map(val => Number(val));
                            props.onChange(finding);
                        }}
                    />
                </React.Fragment>
            );
        }
    } else {
        for (let i = 0; i < props.finding.content.data.length; ++i) {
            for (let j = 0; j < props.finding.content.data.length; ++j) {
                if (i === j) {
                    elements.push(
                        <EditInput
                            showDeleteButton={true}
                            value={props.finding.content.data[i].name}
                            onChange={(value) => {
                                let finding: CorrelogramFinding = {
                                    ...props.finding!,
                                    content: {
                                        ...props.finding!.content,
                                        data: props.finding!.content.data.map(
                                            (item) => ({
                                                ...item,
                                                value: [...item.value],
                                            })
                                        ),
                                    },
                                };
                                finding.content.data[i].name = String(value);
                                props.onChange(finding);
                            }}
                            onDelete={() => {
                                let finding: CorrelogramFinding = {
                                    ...props.finding!,
                                    content: {
                                        ...props.finding!.content,
                                        data: [...props.finding!.content.data],
                                    },
                                };
                                finding.content.data.splice(i, 1);
                                props.onChange(finding);
                            }}
                        />
                    );
                } else if (i > j) {
                    elements.push(
                        <EditInput
                            showDeleteButton={false}
                            disabled={false}
                            value={props.finding.content.data[i].value[j]}
                            onChange={(value) => {
                                let finding: CorrelogramFinding = {
                                    ...props.finding!,
                                    content: {
                                        ...props.finding!.content,
                                        data: props.finding!.content.data.map(
                                            (item) => ({
                                                ...item,
                                                value: [...item.value],
                                            })
                                        ),
                                    },
                                };
                                finding.content.data[i].value[j] = Number(
                                    value
                                );
                                props.onChange(finding);
                            }}
                        />
                    );
                } else {
                    elements.push(<div className={styles.blankCell} />);
                }
            }
        }
    }
    if (datasetIsConnected) {
        return (
            <>
                {elements}
            </>
        )
    }
    return (
        <div className="flex-simple-column" style={{ alignItems: "center" }}>
            <div
                className={styles.dataContainer}
                style={{
                    gridTemplateColumns: datasetIsConnected
                        ? "repeat(1, 150px)"
                        : `repeat(${props.finding.content.data.length}, 75px)`,
                }}
            >
                {elements}
            </div>
        </div>
    );
}

export default function CorrelogramSection(props: Props) {
    const [errorMessage, setErrorMessage] = useState<{
        message: string;
        status: PopupStatus;
    }>();
    let datasetIsConnected = props.finding.config.dataScope != null;
    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number,
        variableType: string
    ) => {
        if (variableType === "float" || variableType === "int") {
            let newData = _.cloneDeep(props.finding.content.data)
            newData[index] = {
                name: variableName,
                value: [],
                originalName: variableName,
                variableIndex: variableIndex,
            };
            props.onChange(
                {
                    ...props.finding,
                    content: {
                        ...props.finding.content,
                        data: newData,
                    },
                },
                true
            );
        } else {
            setErrorMessage({
                message: `${variableName} is not numeric`,
                status: PopupStatus.Error,
            });
        }
    };

    return (
        <div>
            <div className="my-row">
                <MagicWand
                    minCount={2}
                    maxCount={2}
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    allowMultiple={true}
                    title={"Variables"}
                    optionName="data"
                    canvasTreeStore={props.canvasTreeStore}
                    type={DynamicOptionType.DataVariable}
                />
                {datasetIsConnected && (
                    <div className={styles.dataContainer}>
                        <div className={commonStyles.header}>Variables</div>
                        <div className={commonStyles.header}>Values</div>
                        <DataTable
                            finding={props.finding}
                            onChange={props.onChange}
                            columnDragActive={props.columnDragActive}
                            setErrorMessage={setErrorMessage}
                            linkVariable={linkVariable}
                        />
                    </div>
                )}
                {!datasetIsConnected && (
                    <>
                    <DataTable
                        finding={props.finding}
                        onChange={props.onChange}
                        columnDragActive={props.columnDragActive}
                        setErrorMessage={setErrorMessage}
                        linkVariable={linkVariable}
                    />
                    </>
                )}
            </div>
            {datasetIsConnected && (
                <div style={{ marginLeft: 26 }}>
                    <DropArea
                        optionalClassName={cx(styles.dropArea, {
                            [styles.highlightDropArea]: props.columnDragActive,
                        })}
                        onDrop={linkVariable}
                        index={props.finding.content.data.length}
                        optionalStyles={{ width: "151px" }}
                    >
                        <EditInput
                            showDeleteButton={false}
                            value={props.columnDragActive ? "\uFF0B Add Item" : ""}
                            onChange={() => {}}
                            disabled={true}
                            optionalStyles={{ border: "none" }}
                        />
                    </DropArea>
                </div>
            )}
            {props.finding.config.dataScope == null && (
                <Button
                    style={{ marginTop: 10 }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let finding: CorrelogramFinding = {
                            ...props.finding!,
                            content: {
                                ...props.finding.content,
                                data: [...props.finding.content.data],
                            },
                        };
                        let index = props.finding.content.data.length;
                        finding.content.data.push({
                            name: `var${index + 1}`,
                            value: new Array(index).fill(0),
                        });
                        props.onChange(finding);
                    }}
                >
                    {"\uFF0B Add Item"}
                </Button>
            )}
            {errorMessage != null && (
                <StatusPopup
                    onClose={() => {
                        setErrorMessage(undefined);
                    }}
                    status={errorMessage.status}
                    message={errorMessage.message}
                />
            )}
        </div>
    );
}
