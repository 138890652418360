import React, { Component } from "react";
import Switch from "common/switch/Switch";
import { CanvasToggle } from "common/Canvas";

interface Props {
    rootDataTestId: string;
    node: CanvasToggle;
    scale: number;
    frozen: boolean;
    onMetricChanged: (metrics: string) => void;
    width: number;
    height: number;
    fontColor: string;
    fontSize: number;
}

class HTMLToggle extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onToggle = this.onToggle.bind(this);
    }

    private onToggle(checked: boolean): void {
        this.props.onMetricChanged(checked ? "1" : "0");
    }

    public render(): JSX.Element {
        const { node } = this.props;
        const switcherBackground = node.colorOptions?.background ?? "#20293C";
        const uncheckedHandlerColor = node.colorOptions?.handle?.unchecked ?? "#70889E";
        const checkedHandlerColor = node.colorOptions?.handle?.checked ?? "#1F8EFA";
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "none",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <span
                        className="content-regular-text unselectable"
                        style={{
                            fontSize: this.props.fontSize * this.props.scale,
                            fontFamily: "Roboto",
                            color: this.props.fontColor,
                            textAlign: "center",
                        }}
                    >
                        &nbsp;
                    </span>
                    <div style={{ flex: 1 }} />
                    <span
                        className="content-regular-text unselectable"
                        style={{
                            fontSize: this.props.fontSize * this.props.scale,
                            fontFamily: "Roboto",
                            color: this.props.fontColor,
                            textAlign: "center",
                        }}
                    >
                        &nbsp;
                    </span>
                </div>
                <Switch
                    dataTestId={`${this.props.rootDataTestId}-handle`}
                    className="cancel-drag"
                    onChange={this.onToggle}
                    checked={node.value === 1}
                    width={Math.ceil(this.props.width - 20 * this.props.scale)}
                    height={Math.ceil(20 * this.props.scale)}
                    offColor={switcherBackground}
                    onColor={switcherBackground}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor={uncheckedHandlerColor}
                    onHandleColor={checkedHandlerColor}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <span
                        className="content-regular-text unselectable"
                        style={{
                            fontSize: this.props.fontSize * this.props.scale,
                            fontFamily: "Roboto",
                            color: this.props.fontColor,
                            textAlign: "center",
                            marginLeft: 5 * this.props.scale,
                        }}
                    >
                        {node.hideLabels ? " " : "0"}
                    </span>
                    <div style={{ flex: 1 }} />
                    <span
                        className="content-regular-text unselectable"
                        style={{
                            fontSize: this.props.fontSize * this.props.scale,
                            fontFamily: "Roboto",
                            color: this.props.fontColor,
                            textAlign: "center",
                            marginRight: 5 * this.props.scale,
                        }}
                    >
                        {node.hideLabels ? " " : "1"}
                    </span>
                </div>
            </div>
        );
    }
}

export default HTMLToggle;
