import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import {
    CanvasElement,
    CanvasProgressElement,
    CanvasSlider,
} from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import TabTitle from "common/TabTitle";
import { GlobalContextContents } from "GlobalContext";
import { DataTableInput } from "modules/canvas_page/DataTableInputSelector";
import Settings from "./Settings";
import Data from "./Data";

import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";

import styles from "./Slider.module.css";

interface Props {
    canvasId: number;
    node: CanvasSlider;
    nodeLinkOptions: NodeLinkOption[];
    shared: boolean;
    currentModuleId?: number;
    dataTableInput: DataTableInput[];
    globalContext: GlobalContextContents;
    onChange: (
        changes: Partial<CanvasProgressElement>,
        commit?: boolean
    ) => void;
    onChangeDataTableInput: (newValue: DataTableInput[]) => void;
    onChangeShared: (shared: boolean) => void;
    onClose: () => void;
}

function Slider({
    node,
    nodeLinkOptions,
    canvasId,
    currentModuleId,
    dataTableInput,
    globalContext,
    onChangeShared,
    shared,
    onChange,
    onChangeDataTableInput,
    onClose,
}: Props) {
    const [selectedTab, setSelectedTab] = useState("settings");

    function selectTab(tabKey: string | null) {
        if (tabKey == null) return;
        setSelectedTab(tabKey);
    }

    return (
        <div>
            <div className={styles.editMenuHeader}>
                <h6 className={styles.editMenuHeaderTitle}>Advanced Options</h6>
                <button
                    type="button"
                    onClick={onClose}
                    className={styles.editMenuCloseButton}
                >
                    <CloseIcon />
                </button>
            </div>
            <Tabs
                id="advanced-menu-slider-tabs"
                activeKey={selectedTab}
                onSelect={selectTab}
                className={styles.tabMenu}
            >
                <Tab
                    eventKey="settings"
                    title={
                        <TabTitle
                            title="Settings"
                            selected={selectedTab === "settings"}
                            className={styles.tabTitle}
                        />
                    }
                    className={styles.tab}
                >
                    <Settings
                        shared={shared}
                        globalContext={globalContext}
                        onChangeShared={onChangeShared}
                        node={node}
                        onChange={
                            onChange as (
                                changes: Partial<CanvasElement>,
                                commit?: boolean
                            ) => void
                        }
                    />
                </Tab>
                <Tab
                    eventKey="data"
                    title={
                        <TabTitle
                            title="Data"
                            selected={selectedTab === "data"}
                            className={styles.tabTitle}
                        />
                    }
                    className={styles.tab}
                >
                    <Data
                        canvasId={canvasId}
                        node={node}
                        currentModuleId={currentModuleId}
                        nodeLinkOptions={nodeLinkOptions}
                        dataTableInput={dataTableInput}
                        onChange={onChange}
                        onChangeDataTableInput={onChangeDataTableInput}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}

export default Slider;
