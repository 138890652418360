import React from 'react';

import cx from "classnames";
import styles  from '../demobot.module.css';  


const Challenger: React.FC = () => (
    <div className={styles.demobotAppContent}>
      <h2>The Challenger Sale</h2>
      <iframe 
        width="52%"
        height="550px"
        frameBorder={"0"}
        allowFullScreen={true}
        src="https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=YiOyPP86RHSs0zWWz2kDfA">        
      </iframe>
    </div>
);

export default Challenger;