import React, { useEffect, useState } from "react";
import { createFilter, MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { observer } from "mobx-react";

import customSelectStyles, {
    getCustomSelectStyleForDataSection,
    leftAlignAndScrollBarCSS,
    Styles,
} from "common/SelectStyles";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { Permission } from "common/Permissions";
import { GroupExtendedPermission } from "common/GroupPermissions";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import { customSelectStylesForSharePopupNoBorder } from "common/SelectStyles";
import { basePlacements } from "@popperjs/core";
import { mainStyle } from "./MainStyle";
import { BrowserCodeReader } from "@zxing/library";


interface Props {
    value: DataScopeOption | undefined;
    onChange: (value: DataScopeOption) => void;
    maxHeight?: number;
    dataHubMode?: boolean;
    permissions: (GroupExtendedPermission | null)[];
    onPermissionsChange: (value: (GroupExtendedPermission | null)[]) => void;
    errorMessage?: string;
    currentModuleId?: number;
    hidePermissions?: boolean;
}


const DataScopeCreatableSelectorView = observer((props: Props) => {
    const [newOption, setNewOption] = useState<DataScopeOption | null>(null);
    const [selectedOption, setselectedOption] = useState<DataScopeOption | MultiValue<DataScopeOption> | null>(null);
    // const [datasetName, setdatasetName] = useState<DataScopeOption | null>(null)
    const [datasetName, setdatasetName] = useState<any>(null)

    let dataScopes =
        props.currentModuleId != null
            ? DataScopesForModules(props.currentModuleId)
            : DataScopes;
    let dataHubStyles: Styles | undefined = props.dataHubMode
        ? {
            ...getCustomSelectStyleForDataSection(14, false),
            ...leftAlignAndScrollBarCSS,
            container: (base) => ({
                ...base,
                width: "100%",
                height: "40px",
            }),
            control: (base) => ({
                ...base,
                // boxShadow:'none',
                boxSizing: "border-box",
                borderRadius: "4px",
                borderColor: "#D1D1D1",
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            option: (base, state) => ({
                ...base,
                textAlign: 'center',
                backgroundColor: state.isSelected ? "#d3d3d3" : "white",
                borderRadius: state.isSelected ? 6 : 0,
                "&:hover": {
                    backgroundColor: "#d3d3d3",
                    borderRadius: 6,
                }
            }),
            placeholder: (base) => ({
                ...base,
                paddingLeft: 5
            }),
            singleValue: (base, state) => ({
                ...base,
                textAlign: 'left',
                paddingLeft: 5,
            }),
            input: (base, state) => ({
                ...base,
                marginLeft: 6,
                //  this css is added to prevent overflow
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                boxSizing: "border-box",
            }),
            menu: (base, state) => ({
                ...base,
                display: dataScopes.dataScopesOptions.filter(item => item.label.includes(datasetName)).length > 0 ? "block" : "none",
                borderRadius: 6,
                overflow:'auto'
            })
        }
        : undefined;

    return (
        <div
            className="flex-simple-column"
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
                marginLeft: props.dataHubMode ? "0px" : "90px",
                width: props.dataHubMode ? "100%" : "80%",
            }}
            onKeyDown={(evt) => {
                evt.stopPropagation();
            }}
            onMouseDown={(evt: any) => {
                evt.stopPropagation();
            }}
        >
            <CreatableSelect
                formatCreateLabel={(inputValue) => undefined}
                noOptionsMessage={() => null}
                menuPortalTarget={document.body}
                menuShouldBlockScroll={true}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                filterOption={createFilter({
                    ignoreAccents: false,
                })}
                placeholder={
                    props.dataHubMode
                        ? "Select/create dataset"
                        : "Select dataset from list, or enter new dataset name"
                }
                menuPosition={'fixed'}
                styles={
                    props.dataHubMode
                        ? dataHubStyles
                        :
                        {
                            ...customSelectStylesForSharePopupNoBorder,
                            ...leftAlignAndScrollBarCSS,
                            container: (base) => ({
                                ...base,
                                width: "100%",
                                height: "40px",
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999, }),
                            option: (base) => ({
                                ...base,
                                textAlign: 'center',
                                "&:hover": {
                                    backgroundColor: "#d3d3d3",
                                    borderRadius: 6,
                                },
                            }),
                            placeholder: () => ({
                                paddingLeft: 5
                            }),
                            input: (base) => ({
                                ...base,
                                marginLeft: 6
                            }),
                        }
                }
                onCreateOption={(option: string) => {
                    let newOption = {
                        label: option,
                        value: NaN,
                    } as DataScopeOption;
                    setNewOption(newOption);
                    setselectedOption(newOption)
                    props.onChange(newOption as DataScopeOption);
                }}

                onInputChange={(e) => {
                    setdatasetName(e)
                }}

                onBlur={(e) => {
                    let newOptions = {
                        label: e.target.value,
                        value: NaN,
                    } as DataScopeOption;
                    setNewOption(newOptions);
                    e.target.value !== "" ?
                        props.onChange(newOptions as DataScopeOption)
                        : (selectedOption && props.onChange(selectedOption as DataScopeOption));
                }}

                options={dataScopes.dataScopesOptions
                    .filter(
                        (option) =>
                            option.permissionType === Permission.ReadWrite
                    )
                    .concat(newOption == null ? [] : [newOption])
                }

                onChange={(newValue) => {
                    setselectedOption(newValue)
                    props.onChange(newValue as DataScopeOption);
                }}

                value={props.value}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        text: "white",
                        primary25: "var(--selectors-background-hover-color)",
                    },
                })}
            />




            {!props.dataHubMode && (
                <div
                    style={{
                        height: "2px",
                        backgroundColor:
                            dataScienceElementsStyle.secondaryTextColor,
                    }}
                />
            )}
            {!props.dataHubMode && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: "35px",
                        marginTop: "2px",
                        marginBottom: "2px",
                        fontFamily: "Roboto",
                        color: dataScienceElementsStyle.primaryTextColor,
                    }}
                >
                    {props.errorMessage != null && props.errorMessage !== "" && (
                        <img
                            src="/dist/img/error.png"
                            alt=""
                            style={{
                                marginRight: 5,
                            }}
                        />
                    )}
                    {props.errorMessage}
                </div>
            )}
            {/* {!props.hidePermissions && (
                <div className="select-permissions">
                    <GroupsPermissionsSelector
                        questionnaireStyle={!props.dataHubMode}
                        selectedUserGroups={props.permissions}
                        onChange={props.onPermissionsChange}
                    />
                </div>
            )} */}
        </div>
    );
});

export default DataScopeCreatableSelectorView;