import "common/styles/App.css";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import ViewMode from "common/home_components/PresentationTabs/ViewMode";
import TabContent from "common/home_components/PresentationTabs/TabContent";
import Select, { createFilter } from "react-select";
import GlobalContext from "GlobalContext";
import { AbstractPresentationModel } from "state/models/Presentation";
import cx from "classnames";
import stringSimilarity from "string-similarity";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import Tables from "common/Tables";
import Variables, { Variable } from "common/Variables";
import { getRawDataApi } from "common/DataApi";
import { strftime } from "common/utilities/TimeFormatUtils";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import DataScopes, { DataScope } from "common/DataScopes";
import styles from "./MarketplaceComponent.module.css";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { publishToMarketPlace, removeMarketplace } from "common/MarketplaceApi";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import DictionaryComponent from "../DictionaryComponent";
import { ReactComponent as InfoIcon } from "icons/marketplace/info.svg";
import { SocketIOInstance } from "common/ServerConnection";
import { stringSessionId } from "common/SessionId";
import CurrentUser from "common/CurrentUser";

interface Props {
    canvasTreeStore?: CanvasTreeStore;
    onReturnToCanvas?: () => void;
    onClose?: () => void;
    onLoad?: (datasetId: number | string) => void;
}

const similarity = (
    presentation: AbstractPresentationModel,
    searchText: string,
    keywords: string[]
) => {
    // search criteria
    //title
    let metrics = 0;
    if (presentation.title && searchText) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                presentation.title.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.4;
    }
    if ((presentation.keywords ?? []).length > 0 && keywords.length > 0) {
        let a = new Set(presentation.keywords ?? []);
        let b = new Set(keywords);
        let intersect = new Set([...a].filter((i) => b.has(i)));
        metrics += intersect.size * 0.2;
    }
    //subtitle
    if (presentation.description && searchText) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                presentation.description.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.2;
    }

    return metrics;
};

const searchMarketplace = (
    presentations: AbstractPresentationModel[],
    searchText: string,
    keywords: string[]
) => {
    return presentations
        .map((pres) => ({
            presentation: pres,
            similarity: similarity(pres, searchText, keywords),
        }))
        .filter((pres) => pres.similarity > 0.01)
        .sort((p1, p2) => {
            if (p1.similarity < p2.similarity) return 1;
            if (p1.similarity > p2.similarity) return -1;
            return 0;
        })
        .map((pres) => pres.presentation);
};

interface DataScopeWithOption extends DataScope {
    label: string;
    value: number | string;
}

const PublishComponent = observer(function PublishComponent() {
    const [
        selectedDataset,
        setSelectedDataset,
    ] = useState<DataScopeWithOption | null>(null);
    const [newKeyword, setNewKeyword] = useState<string>("");
    const [uploading, setUploading] = useState<boolean>(false);
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);
    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    return (
        <div className={styles.datasetsContainer}>
            <div className={styles.datasetSelector}>
                <Select
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Select data set"}
                    styles={{
                        ...getCustomSelectStyleForDataSection(14, false),
                        container: (base) => ({
                            ...base,
                            width: "295px",
                            height: "35px",
                        }),
                    }}
                    options={DataScopes.dataScopes.map((option) => ({
                        ...option,
                        label: option.name,
                        value: option.id,
                    }))}
                    onChange={(newValue) => {
                        if (
                            (newValue as DataScopeWithOption).marketplaceInfo ==
                            null
                        )
                            newValue = {
                                ...(newValue as DataScopeWithOption),
                                marketplaceInfo: {
                                    logo: "",
                                    keywords: [],
                                    description: "",
                                },
                            };
                        setSelectedDataset(newValue as DataScopeWithOption);
                    }}
                    value={selectedDataset}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            <div className={styles.textItem} style={{ marginTop: "32px" }}>
                Description
            </div>
            <textarea
                placeholder="Description"
                disabled={!selectedDataset}
                style={{ resize: "none", width: 295, height: 115 }}
                value={selectedDataset?.marketplaceInfo?.description ?? ""}
                onChange={(evt) => {
                    let value = evt.target.value;
                    setSelectedDataset((selectedDataset) => ({
                        ...selectedDataset!,
                        marketplaceInfo: {
                            ...selectedDataset!.marketplaceInfo!,
                            description: value,
                        },
                    }));
                }}
                className={styles.searchInput}
            />
            <div className={styles.textItem} style={{ marginTop: "32px" }}>
                Keywords
            </div>
            <div className={styles.keywordInputContainer}>
                <input
                    placeholder="Keywords"
                    style={{ resize: "none", width: 295, height: 32 }}
                    value={newKeyword}
                    disabled={!selectedDataset}
                    onChange={(evt) => {
                        let value = evt.target.value;
                        setNewKeyword(value);
                    }}
                    className={styles.searchInput}
                />
                <button
                    disabled={newKeyword.length === 0}
                    title={"Type to add keyword"}
                    className="btn-small-like-select"
                    style={{
                        marginLeft: -25,
                        alignSelf: "center",
                        width: "19px",
                        height: "19px",
                    }}
                    onClick={() => {
                        setSelectedDataset((selectedDataset) => ({
                            ...selectedDataset!,
                            marketplaceInfo: {
                                ...selectedDataset!.marketplaceInfo!,
                                keywords: [
                                    ...selectedDataset!.marketplaceInfo!
                                        .keywords,
                                    newKeyword,
                                ],
                            },
                        }));
                        setNewKeyword("");
                    }}
                >
                    {"\uFF0B" /* plus */}
                </button>
            </div>
            <div className={styles.keywordsContainer}>
                {selectedDataset?.marketplaceInfo?.keywords.map(
                    (keyword, keywordIndex) => (
                        <div
                            className="my-row"
                            key={keywordIndex}
                            onMouseEnter={() => {
                                setHoverIndex(keywordIndex);
                            }}
                            onMouseLeave={() => {
                                setHoverIndex(null);
                            }}
                        >
                            <div
                                className={cx(
                                    styles.textItem,
                                    styles.keywordItem
                                )}
                            >
                                {keyword}
                            </div>
                            {hoverIndex === keywordIndex && (
                                <div
                                    className="flex-simple-column"
                                    style={{ marginLeft: 2 }}
                                >
                                    <button
                                        title={"Remove keyword"}
                                        className="btn-small-like-select"
                                        style={{
                                            width: "19px",
                                            height: "19px",
                                        }}
                                        onClick={() => {
                                            setSelectedDataset(
                                                (selectedDataset) => {
                                                    let keywords = [
                                                        ...selectedDataset!
                                                            .marketplaceInfo!
                                                            .keywords,
                                                    ];
                                                    keywords.splice(
                                                        hoverIndex!,
                                                        1
                                                    );
                                                    return {
                                                        ...selectedDataset!,
                                                        marketplaceInfo: {
                                                            ...selectedDataset!
                                                                .marketplaceInfo!,
                                                            keywords: keywords,
                                                        },
                                                    };
                                                }
                                            );
                                        }}
                                    >
                                        {"\uFF0D" /* minus */}
                                    </button>
                                </div>
                            )}
                        </div>
                    )
                )}
            </div>
            <div className={styles.textItem} style={{ marginTop: "32px" }}>
                Logo
            </div>
            <input
                placeholder="Logo"
                disabled={!selectedDataset}
                style={{ resize: "none", width: 295, height: 32 }}
                value={selectedDataset?.marketplaceInfo?.logo ?? ""}
                onChange={(evt) => {
                    let value = evt.target.value;
                    setSelectedDataset((selectedDataset) => ({
                        ...selectedDataset!,
                        marketplaceInfo: {
                            ...selectedDataset!.marketplaceInfo!,
                            logo: value,
                        },
                    }));
                }}
                className={styles.searchInput}
            />
            <div
                className={styles.bottomPopupBar}
                style={{ justifyContent: "flex-start" }}
            >
                {selectedDataset?.isMarketPlace && (
                    <button
                        disabled={!selectedDataset || uploading}
                        className={cx(styles.backButton, "unselectable")}
                        type="button"
                        onClick={() => {
                            setUploading(true);
                            setStatusPopup({
                                status: PopupStatus.Success,
                                message: "Unpublishing...",
                            });
                            removeMarketplace(selectedDataset)
                                .then(() => {
                                    setUploading(false);
                                    setStatusPopup({
                                        status: PopupStatus.Success,
                                        message: "Unpublished",
                                    });
                                    setSelectedDataset(null);
                                    DataScopes.update();
                                })
                                .catch((error) => {
                                    setUploading(false);
                                    setStatusPopup({
                                        status: PopupStatus.Error,
                                        message: String(error),
                                    });
                                });
                        }}
                    >
                        {"Unpublish"}
                    </button>
                )}
                <button
                    disabled={!selectedDataset || uploading}
                    className={cx(styles.loadButton, "unselectable")}
                    style={{
                        marginLeft: "12px",
                    }}
                    type="button"
                    onClick={() => {
                        setUploading(true);
                        setStatusPopup({
                            status: PopupStatus.Success,
                            message: "Publishing...",
                        });
                        publishToMarketPlace(selectedDataset!)
                            .then(() => {
                                setUploading(false);
                                setStatusPopup({
                                    status: PopupStatus.Success,
                                    message: "Published",
                                });
                                DataScopes.update();
                            })
                            .catch((error) => {
                                setUploading(false);
                                setStatusPopup({
                                    status: PopupStatus.Error,
                                    message: String(error),
                                });
                            });
                    }}
                >
                    {selectedDataset?.isMarketPlace ? "Update" : "Publish"}
                </button>
            </div>
            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </div>
    );
});

const MarketplaceComponent = observer(function MarketplaceComponent(
    props: Props
) {
    const globalContext = useContext(GlobalContext);
    let [searchText, setSearchText] = React.useState<string>("");
    let [publishIsActive, setPublishIsActive] = React.useState<boolean>(false);
    let [infoTextHovered, setInfoTextHovered] = React.useState<boolean>(false);
    let [hoverVariable, setHoverVariable] = React.useState<
        | {
              variable: Variable;
              position: {
                  left: number;
                  top: number;
              };
          }
        | undefined
    >(undefined);
    let [
        selectedDataset,
        setSelectedDataset,
    ] = React.useState<AbstractPresentationModel | null>(null);
    let [previewInfo, setPreviewInfo] = React.useState<{
        tableHeader: string[];
        tablePreview: any[];
        allCount: number;
    } | null>(null);
    let [selectedKeywords, setSelectedKeywords] = React.useState<string[]>([]);

    useEffect(() => {
        SocketIOInstance?.on(
            "dataset_changes",
            (content: {
                data: {
                    user_name?: string;
                    data_table_idx: string | number;
                    operation: string;
                    update_id?: string;
                    description?: string;
                    variable_index?: number;
                };
            }) => {
                let data = content.data;
                const updateId = data.update_id;
                const sessionId = stringSessionId();
                if (
                    updateId != null &&
                    sessionId != null &&
                    data.user_name?.toLowerCase() ===
                        CurrentUser.infoState?.user_name?.toLowerCase() &&
                    updateId === sessionId
                )
                    return;

                if (data.operation === "update_variable_description") {
                    let variables = Variables(
                        data.data_table_idx,
                        undefined,
                        undefined,
                        true
                    );
                    variables.updateDescription(
                        data.variable_index!,
                        data.description!
                    );
                }
            }
        );
    }, []);
    useEffect(() => {
        if (!selectedDataset) {
            setPreviewInfo(null);
            return;
        }

        async function getData() {
            let variables = Variables(
                selectedDataset!.id,
                undefined,
                undefined,
                true
            );
            if (!variables.initialized)
                await Variables(selectedDataset!.id).update();
            const table = Tables(selectedDataset!.id).tableToOption();

            const tableHeader = Variables(selectedDataset!.id).variableNames;

            const variablesInfo = tableHeader.map((name) =>
                Variables(selectedDataset!.id).getVariableByName(name)
            );
            if (!selectedDataset) return;

            try {
                const data = await getRawDataApi(
                    table ?? {
                        label: "",
                        value: [],
                        optimized: false,
                        data_table_idx: selectedDataset!.id,
                    },
                    10,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    true
                );
                let allCount = data.count!;
                let tableContent: (string | number | null)[][] = [];
                let rowCount = data.rowId.length;
                for (let i = 0; i < rowCount + 1; ++i) {
                    let row: (string | number | null)[] = [];
                    for (let [j, varName] of tableHeader.entries()) {
                        if (i === rowCount) {
                            if (j === 0)
                                row.push(`+${allCount - rowCount} rows`);
                            else row.push("...");
                            continue;
                        }

                        if (typeof data.currentLevels[varName] === "undefined")
                            break;

                        row.push(
                            variablesInfo[j]!.type === "datetime" &&
                                typeof data.currentLevels[varName][i] ===
                                    "number"
                                ? strftime(
                                      variablesInfo[j]!.format!,
                                      new Date(
                                          (data.currentLevels[varName][
                                              i
                                          ] as number) * 1000
                                      )
                                  )
                                : data.currentLevels[varName][i] ||
                                      (typeof data.currentLevels[varName][i] ===
                                      "number"
                                          ? data.currentLevels[varName][i]
                                          : "-")
                        );
                    }
                    tableContent.push(row);
                }
                setPreviewInfo({
                    allCount: allCount,
                    tableHeader: tableHeader,
                    tablePreview: tableContent,
                });
                if (
                    !variables.initializedWithSummary &&
                    !variables.loadingSummaryState
                )
                    Variables(selectedDataset!.id).update(undefined, true);
            } catch (err) {
            } finally {
            }
        }

        getData();
    }, [selectedDataset]);
    let variables =
        selectedDataset == null
            ? null
            : Variables(selectedDataset!.id, undefined, undefined, true);
    return (
        <div className={styles.container}>
            <div className={styles.topContainer}>
                <div className={styles.topLabel}>DATA MARKETPLACE</div>
                <div style={{ width: 18 }} />
                {"PublishToMarketPlace" in globalContext.permissions && (
                    <button
                        className={cx(styles.publishButton, "unselectable")}
                        type="button"
                        onClick={() => {
                            setPublishIsActive(
                                (publishIsActive) => !publishIsActive
                            );
                        }}
                    >
                        {!publishIsActive ? "Publish" : "Back"}
                    </button>
                )}
            </div>
            {publishIsActive && <PublishComponent />}
            {!publishIsActive && (
                <>
                    <div className={styles.searchContainer}>
                        <input
                            placeholder="Search for data"
                            type="text"
                            value={searchText}
                            onChange={(evt) => {
                                let value = evt.target.value;
                                setSearchText(value);
                            }}
                            className={styles.searchInput}
                        />
                        {DataScopes.allKeywords.map(
                            (keyword: string, index: number) => (
                                <button
                                    key={index}
                                    style={{
                                        marginLeft: 18,
                                        width: 86,
                                        height: 37,
                                    }}
                                    className={cx(
                                        selectedKeywords.includes(keyword)
                                            ? styles.loadButton
                                            : styles.publishButton,
                                        "unselectable"
                                    )}
                                    type="button"
                                    onClick={() => {
                                        setSelectedKeywords(
                                            (selectedKeywords) => {
                                                if (
                                                    selectedKeywords.includes(
                                                        keyword
                                                    )
                                                ) {
                                                    return selectedKeywords.filter(
                                                        (item) =>
                                                            item !== keyword
                                                    );
                                                }
                                                return [
                                                    ...selectedKeywords,
                                                    keyword,
                                                ];
                                            }
                                        );
                                    }}
                                >
                                    {keyword}
                                </button>
                            )
                        )}
                    </div>
                    <TabContent
                        clickPresentation={(id: number) => {
                            setSelectedDataset(
                                DataScopes.marketplaceDataScopes.find(
                                    (item) => item.id === id
                                ) ?? null
                            );
                        }}
                        presentations={
                            !searchText && selectedKeywords.length === 0
                                ? DataScopes.marketplaceDataScopes
                                : searchMarketplace(
                                      DataScopes.marketplaceDataScopes,
                                      searchText,
                                      selectedKeywords
                                  )
                        }
                        loading={false}
                        deletePresentation={(id: number) => {}}
                        viewMode={ViewMode.Grid}
                    />
                    {props.canvasTreeStore && (
                        <div
                            className={styles.bottomPopupBar}
                            style={{ marginRight: "76px" }}
                        >
                            <button
                                className={cx(
                                    styles.cancelButton,
                                    "my-outline",
                                    "unselectable"
                                )}
                                type="button"
                                onClick={() => {
                                    props.onClose?.();
                                }}
                            >
                                {"Cancel"}
                            </button>
                        </div>
                    )}
                    {selectedDataset && (
                        <div className={styles.popupBackground}>
                            <div className={styles.popupContainer}>
                                <div
                                    className="flex-simple-column"
                                    style={{
                                        width: "50%",
                                        height: "70%",
                                    }}
                                >
                                    <div className={styles.previewContainer}>
                                        <div className={styles.previewTitle}>
                                            {selectedDataset.title}
                                        </div>
                                        <div
                                            className={
                                                styles.previewDescription
                                            }
                                        >
                                            {selectedDataset.description}
                                        </div>

                                        {previewInfo != null && (
                                            <>
                                                <div
                                                    className={
                                                        styles.datasetInfo
                                                    }
                                                >
                                                    {`This dataset has ${
                                                        previewInfo!.tableHeader
                                                            .length
                                                    } columns and ${
                                                        previewInfo!.allCount
                                                    } rows`}
                                                </div>
                                                <div
                                                    className={
                                                        styles.tableContainer
                                                    }
                                                    style={{ overflow: "auto" }}
                                                >
                                                    <AdminTableWithFullFeatures
                                                        headerRenderer={(
                                                            item,
                                                            index
                                                        ) => {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            "relative",
                                                                    }}
                                                                    onMouseEnter={(
                                                                        evt
                                                                    ) => {
                                                                        let boundingRect = evt.currentTarget.getBoundingClientRect();
                                                                        if (
                                                                            !variables?.initializedWithSummary
                                                                        )
                                                                            return;
                                                                        let variable = Variables(
                                                                            selectedDataset!
                                                                                .id
                                                                        ).getVariableByName(
                                                                            item
                                                                        )!;
                                                                        setHoverVariable(
                                                                            {
                                                                                variable: variable,
                                                                                position: {
                                                                                    left:
                                                                                        boundingRect.left -
                                                                                        8,
                                                                                    top:
                                                                                        boundingRect.top -
                                                                                        8,
                                                                                },
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    {item}
                                                                    {hoverVariable !=
                                                                        null &&
                                                                        hoverVariable
                                                                            .variable
                                                                            .name ===
                                                                            item && (
                                                                            <DictionaryComponent
                                                                                dataTableIdx={
                                                                                    selectedDataset!
                                                                                        .id
                                                                                }
                                                                                onClose={() => {
                                                                                    setHoverVariable(
                                                                                        undefined
                                                                                    );
                                                                                }}
                                                                                variable={
                                                                                    hoverVariable.variable
                                                                                }
                                                                                position={
                                                                                    hoverVariable.position
                                                                                }
                                                                            ></DictionaryComponent>
                                                                        )}
                                                                </div>
                                                            );
                                                        }}
                                                        tableName="Marketplacetable"
                                                        className="import-table"
                                                        paging={false}
                                                        small={false}
                                                        tableHeader={
                                                            previewInfo.tableHeader
                                                        }
                                                        tableContent={
                                                            previewInfo.tablePreview
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className={styles.bottomPopupBar}>
                                            {variables?.initializedWithSummary ? (
                                                <div
                                                    className={
                                                        styles.bottomVariableInfoBar
                                                    }
                                                    onMouseEnter={() => {
                                                        setInfoTextHovered(
                                                            true
                                                        );
                                                    }}
                                                    onMouseLeave={() => {
                                                        setInfoTextHovered(
                                                            false
                                                        );
                                                    }}
                                                >
                                                    <InfoIcon />
                                                    <div
                                                        style={{
                                                            position:
                                                                "relative",
                                                            height: "100%",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {!infoTextHovered ? (
                                                            <div
                                                                style={{
                                                                    marginLeft: 8.25,
                                                                }}
                                                            >
                                                                What does each
                                                                variables mean?
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles.bottomVariableInfoHover
                                                                }
                                                                style={{
                                                                    zIndex: 1,
                                                                    width: 300,
                                                                    padding:
                                                                        "8px 13px 8px 13px",
                                                                    position:
                                                                        "absolute",
                                                                    marginLeft: 8.25,
                                                                }}
                                                            >
                                                                Hover over a
                                                                variable's name
                                                                to find more
                                                                information
                                                                about it.
                                                            </div>
                                                        )}{" "}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        styles.bottomVariableInfoBar
                                                    }
                                                >
                                                    Loading summary...
                                                </div>
                                            )}
                                            <div style={{ flexGrow: 1 }} />
                                            <button
                                                className={cx(
                                                    styles.backButton,
                                                    "unselectable"
                                                )}
                                                type="button"
                                                onClick={() => {
                                                    setSelectedDataset(null);
                                                }}
                                            >
                                                {"Back"}
                                            </button>
                                            {props.canvasTreeStore && (
                                                <button
                                                    className={cx(
                                                        styles.loadButton,
                                                        "unselectable"
                                                    )}
                                                    style={{
                                                        marginLeft: "12px",
                                                    }}
                                                    type="button"
                                                    onClick={() => {
                                                        if (
                                                            props.onLoad == null
                                                        ) {
                                                            let spreadsheetId = props.canvasTreeStore!.addSpreadSheetGridAction(
                                                                3,
                                                                3,
                                                                false,
                                                                true,
                                                                true,
                                                                {}
                                                            );
                                                            if (
                                                                spreadsheetId !=
                                                                null
                                                            ) {
                                                                props
                                                                    .canvasTreeStore!.readDataIntoSpreadSheet(
                                                                        spreadsheetId!,
                                                                        selectedDataset!
                                                                            .id,
                                                                        null,
                                                                        10,
                                                                        true, // Set to true as we pull data when click on stream button
                                                                        false,
                                                                        [],
                                                                        undefined,
                                                                        false
                                                                    )
                                                                    .then(
                                                                        () => {
                                                                            props.onReturnToCanvas?.();
                                                                        }
                                                                    )
                                                                    .catch(
                                                                        (
                                                                            error
                                                                        ) => {
                                                                            console.log(
                                                                                error
                                                                            );
                                                                        }
                                                                    );
                                                            } else {
                                                                props.onReturnToCanvas?.();
                                                            }
                                                        } else {
                                                            props.onLoad?.(
                                                                selectedDataset!
                                                                    .id
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {"Load"}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

export default MarketplaceComponent;
