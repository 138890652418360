import React, { Component } from "react";
import PropTypes from "prop-types";
import openlink from "../../../images/openlink.svg";
import "./styles.css";

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINK"
        );
    }, callback);
}

function getLinkComponent(config) {
    const showOpenOptionOnHover = config.showOpenOptionOnHover;
    const onOpenInternalLink = config.onOpenInternalLink;
    return class Link extends Component {
        static propTypes = {
            entityKey: PropTypes.string.isRequired,
            children: PropTypes.array,
            contentState: PropTypes.object,
        };

        state = {
            showPopOver: false,
        };

        openLink = (evt) => {
            const { entityKey, contentState } = this.props;
            const { external, internalLink, inPopup } = contentState
                .getEntity(entityKey)
                .getData();

            if (external === true || external == null) {
                return;
            } else if (external === false) {
                if (internalLink != null)
                    onOpenInternalLink(internalLink, inPopup);
            }
        };

        toggleShowPopOver = () => {
            const showPopOver = !this.state.showPopOver;
            this.setState({
                showPopOver,
            });
        };

        render() {
            const { children, entityKey, contentState } = this.props;
            const { url, targetOption } = contentState
                .getEntity(entityKey)
                .getData();
            const { showPopOver } = this.state;
            return (
                <span
                    className="rdw-link-decorator-wrapper"
                    onMouseEnter={this.toggleShowPopOver}
                    onMouseLeave={this.toggleShowPopOver}
                >
                    <a href={url} target={targetOption} onClick={this.openLink}>
                        {children}
                    </a>
                    {showPopOver && showOpenOptionOnHover ? (
                        <img
                            src={openlink}
                            alt=""
                            onClick={this.openLink}
                            className="rdw-link-decorator-icon"
                        />
                    ) : undefined}
                </span>
            );
        }
    };
}

const defaultExport = (config) => ({
    strategy: findLinkEntities,
    component: getLinkComponent(config),
});

export default defaultExport;
