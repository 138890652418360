import React from "react";
import Joyride, { CallBackProps as JoyrideCallbackProps, EVENTS } from "react-joyride";

interface Props {
	run: boolean;
	onFinish: Function;
};

const LABELS = {
	back: 'Back',
	close: 'Close',
	last: 'Finish',
	next: 'Next',
	open: 'Start Tour',
	skip: 'Skip'
};

function WelcomeTour({ run, onFinish }: Props) {
	function onUpdateTourState(state: JoyrideCallbackProps) {
		if (state.type === EVENTS.TOUR_END) {
			onFinish();
		}
	}

	return (
		<Joyride
			steps={[
				{
					target: "body",
					placement: "center",
					hideCloseButton: true,
					disableBeacon: true,
					content: "Do you want to do the app tour?",
					locale: {
						next: "Yes"
					}
				},
				{
					target: ".tour-step-1",
					hideCloseButton: true,
					hideBackButton: true,
					content: "This is my awesome feature!",
				},
				{
					target: ".tour-step-2",
					hideCloseButton: true,
					content: "This another awesome feature!",
				},
			]}
			run={run}
			locale={LABELS}
			continuous
			disableOverlayClose
			showSkipButton
			callback={onUpdateTourState}
		/>
	);
}

export default WelcomeTour;
