import React from "react";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";

// interface QBOptions {
//     company_id: string;
// }

export default class QUICKBOOKS extends React.Component<APIComponentProps, {}> {

    render() {
        return (
          <div
                className="flex-simple-column"
                style={this.props.containerStyle}
          >
            <BaseAPIComponent {...this.props} />
              <div className="flex-simple-column" style={{ marginTop: "23px" }}>
                  <span className="APILabel">Company ID</span>
                  <input
                      style={{
                          marginTop: 4,
                          width: 300,
                      }}
                      className="textInput"
                      value={this.props.options?.company_id ?? ""}
                      onChange={(ev2) => {
                          let company_id_val = ev2.target.value;
                          let options = { ...this.props.options };
                          this.props.onOptionsChange({
                              ...options,
                              company_id: company_id_val,
                          });
                      }}
                  >
                  </input>
              </div>
          </div>
        );
    }
}

export { QUICKBOOKS as MainComponent };
