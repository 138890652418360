import { observable, makeObservable, runInAction } from "mobx";
import { BaseCommentPin, CanvasComment, CommentPin } from "./CanvasComments";
import {
	addPinApi,
	getPinsApi,
	editPinApi,
	getCommentsApi,
	deleteCommentApi,
	addCommentApi,
	editCommentApi,
	deletePinApi,
} from "./CanvasCommentsApi";
import Cookies from "universal-cookie";
class PinStoreInner {
	private canvasId: number | null;
	private updateId: string;
	public initialized: boolean = false;
	@observable public pinsState: CommentPin[] = [];
	@observable public commentsState: { [key: number]: CanvasComment[] } = {};
	constructor(canvasId: number) {
		makeObservable(this);
		this.canvasId = canvasId;
		this.updateId = new Cookies().get("instrumentation_session_id");
		this._sortPins = this._sortPins.bind(this);
		this.update();
	}
	update() {
		this.getPins();
	}

	public async addPin(commentPin: BaseCommentPin): Promise<number | null> {
		if (this.canvasId == null) return null;
		try {
			let pinId = await addPinApi(
				commentPin,
				this.canvasId,
				this.updateId
			);
			let pinsState = Array.from(this.pinsState);
			pinsState.push({
				...commentPin,
				id: pinId,
			});

			runInAction(() => {
				this.pinsState = pinsState;
			});
			return pinId;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
	public async editPin(pinId: number, changes: Partial<BaseCommentPin>) {
		if (this.canvasId == null) return;
		try {
			let pinsState = Array.from(this.pinsState);
			let index = pinsState.findIndex((pin) => pin.id === pinId);
			if (index >= 0) {
				pinsState[index] = {
					...pinsState[index],
					...changes,
				};
				runInAction(() => {
					this.pinsState = pinsState;
				});
			}
			await editPinApi(pinId, changes, this.updateId);
		} catch (error) {
			console.log(error);
		}
	}
	public commentsCount(pinId: number): number {
		return this.commentsState[pinId]?.length ?? 0;
	}
	public sortedPins() {
		return this.pinsState.slice().sort(this._sortPins);
	}
	private _sortPins(a: CommentPin, b: CommentPin) {
		let firstAComment = this.commentsState[a.id]?.[0];
		let firstBComment = this.commentsState[b.id]?.[0];
		if (firstAComment == null) return 1;
		if (firstBComment == null) return -1;
		return firstBComment.creation_time - firstAComment.creation_time;
	}

	public comments(pinId: number) {
		return this.commentsState[pinId]?.slice().reverse() ?? [];
	}
	public async deletePin(pinId: number) {
		if (this.canvasId == null) return;
		try {
			await deletePinApi(pinId, this.updateId);

			let pinsState = Array.from(this.pinsState).filter(
				(pin) => pin.id !== pinId
			);

			runInAction(() => {
				this.pinsState = pinsState;
			});
		} catch (error) {
			console.log(error);
		}
	}
	public async updatePins() {
		if (this.canvasId == null) return;
		try {
			let pins = await getPinsApi(this.canvasId);
			runInAction(() => {
				this.pinsState = pins;
			});
		} catch (error) {
			console.log(error);
		}
	}
	public getPin(pinId: number) {
		return this.pinsState.find((item) => item.id === pinId);
	}
	public async updateComments(pinId: number, skipMarkAsRead: boolean = true) {
		if (this.canvasId == null) return;
		try {
			let commentsState = { ...this.commentsState };
			let commentsByPin = await getCommentsApi(pinId, skipMarkAsRead);
			commentsState[pinId] = commentsByPin;
			runInAction(() => {
				this.commentsState = commentsState;
			});
		} catch (error) {
			console.log(error);
		}
	}
	public async getPins() {
		if (this.canvasId == null) return;
		try {
			let pins = await getPinsApi(this.canvasId);
			runInAction(() => {
				this.pinsState = pins;
			});
			let commentsState = { ...this.commentsState };
			for (let pin of pins) {
				let commentsByPin = await getCommentsApi(pin.id, true);
				commentsState[pin.id] = commentsByPin;
			}
			runInAction(() => {
				this.commentsState = commentsState;
			});
		} catch (error) {
			console.log(error);
		}
	}
	public async deleteComment(pinId: number, commentId: number) {
		try {
			await deleteCommentApi(commentId, this.updateId);
			let commentsState = { ...this.commentsState };
			commentsState[pinId] = commentsState[pinId]?.filter(
				(comment) => comment.id !== commentId
			);

			if (commentsState[pinId].length === 0) {
				this.commentsState = commentsState;
				await this.deletePin(pinId);
			} else {
				runInAction(() => {
					this.commentsState = commentsState;
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	public async addComment(pinId: number, comment: string, mentions: string[]) {
		try {
			await addCommentApi(pinId, comment, mentions, this.updateId);
			let commentsState = { ...this.commentsState };
			let commentsByPin = await getCommentsApi(pinId, true);
			commentsState[pinId] = commentsByPin;
			runInAction(() => {
				this.commentsState = commentsState;
			});
		} catch (error) {
			console.log(error);
		}
	}
	public async editComment(
		pinId: number,
		commentId: number,
		comment: string,
		mentions: string[],
	) {
		try {
			await editCommentApi(commentId, comment, mentions, this.updateId);
			let commentsState = { ...this.commentsState };
			let commentsByPin = await getCommentsApi(pinId, true);
			commentsState[pinId] = commentsByPin;
			runInAction(() => {
				this.commentsState = commentsState;
			});
		} catch (error) {
			console.log(error);
		}
	}
}

let pinStoreMap: {
	[key in number | string]: PinStoreInner;
} = {};

export default function PinStores(canvasId: number): PinStoreInner {
	if (!(canvasId in pinStoreMap)) {
		pinStoreMap[canvasId] = new PinStoreInner(canvasId);
	}
	return pinStoreMap[canvasId];
}