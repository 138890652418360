import moment from "moment";
import CurrentUser from "common/CurrentUser";
import {
    DateFormat,
    ColumnFormat,
    SpreadSheetColumnType,
} from "common/Canvas";
import PopupData from "./PopupData";

export interface GlobalInput {
	label: string;
	value: GlobalInputType;
}

export enum GlobalInputType {
	UserName = 1,
	CurrentDate = 2,
	CurrentTime = 3,
	PopupId = 4
}

const GlobalInputs: ReadonlyArray<GlobalInput> = [
	{ label: "USER_NAME", value: GlobalInputType.UserName },
	{ label: "CURRENT_DATE", value: GlobalInputType.CurrentDate },
	{ label: "CURRENT_TIME", value: GlobalInputType.CurrentTime },
	{ label: "POPUP_ID", value: GlobalInputType.PopupId },

];

export function getGlobalInputFormat(type: GlobalInputType): ColumnFormat {
	switch (type) {
		case GlobalInputType.CurrentDate:
			return {
				type: SpreadSheetColumnType.Date,
				format: "%Y-%m-%d"
			} as DateFormat;
		case GlobalInputType.CurrentTime:
			return {
				type: SpreadSheetColumnType.Date,
				format: "%I:%M %p"
			} as DateFormat;
		case GlobalInputType.UserName:
			return {
				type: SpreadSheetColumnType.Text
			} as ColumnFormat;
		case GlobalInputType.PopupId:
			return {
				type: SpreadSheetColumnType.Text
			} as ColumnFormat;
	}
}

export function getGlobalInputValue(
	type: GlobalInputType,
	raw: boolean = false
) {
	switch (type) {
		case GlobalInputType.CurrentDate:
			let timestamp = moment().utc().toDate().getTime() / 1000;
			//timestamp -= timestamp % (24 * 60 * 60); 
			return raw
				? timestamp
				: moment(timestamp * 1000).format("YYYY-MM-DD");
		case GlobalInputType.CurrentTime:
			return raw
				? moment().utc().toDate().getTime() / 1000
				: moment().format("hh:mm A");
		case GlobalInputType.UserName:
			return CurrentUser.info?.user_name ?? "";
		case GlobalInputType.PopupId:
			return PopupData.data?.id.trim() ?? "";
	}
}

export const GlobalInputsMap: {
	[key: string]: string;
} = GlobalInputs.reduce((a, x) => ({ ...a, [x.value]: x.label }), {});

export default GlobalInputs;