import React from "react";
import Select, { createFilter } from "react-select";
import {
    dataScienceSelectStyles,
    getCustomSelectStyleForDataSection,
    Styles,
} from "common/SelectStyles";
import { DataScopeOption } from "common/DataScopes";
import { observer } from "mobx-react";
import Variables, { VariableOption } from "common/Variables";
import { Button } from "react-bootstrap";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    dataHubMode?: boolean;
    dataScope: DataScopeOption | null;
    variables: (VariableOption | null)[];
    exclude?: (VariableOption | null)[];
    onChange: (variableOptions: (VariableOption | null)[]) => void;
    maxHeight?: number;
    currentModuleId?: number;
}

const VariablesSelectorView = observer(function VariablesSelectorView(
    props: Props
) {
    let values = props.variables.map((item) => item?.value ?? null);
    let exclude = (props.exclude ?? []).map((item) => item?.value ?? null);

    let variableOptions =
        props.dataScope != null
            ? Variables(
                  props.dataScope.value,
                  props.currentModuleId ?? remoteModuleId
              ).variableOptions.filter(
                  (item) =>
                      !values.includes(item.value) &&
                      !exclude.includes(item.value)
              )
            : [];

    let dataHubStyles: Styles | undefined = props.dataHubMode
        ? {
              ...getCustomSelectStyleForDataSection(14, false),
              container: (base) => ({
                  ...base,
                  width: 270,
                  height: "35px",
              }),
          }
        : undefined;

    return (
        <div
            className="flex-simple-column"
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
                marginLeft: props.dataHubMode ? "0px" : "90px",
            }}
        >
            {props.variables.map((value, index) => (
                <React.Fragment key={index}>
                    <div
                        className="my-row"
                        style={{
                            marginTop: props.dataHubMode && index > 0 ? 10 : 0,
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                    >
                        <Select
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select variable"}
                            styles={
                                props.dataHubMode
                                    ? dataHubStyles
                                    : {
                                          ...dataScienceSelectStyles,
                                          container: (base) => ({
                                              ...base,
                                              width: "100%",
                                              height: "35px",
                                          }),
                                          menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 51,
                                          }),
                                      }
                            }
                            options={variableOptions}
                            onChange={(newValue) => {
                                let variables = Array.from(props.variables);
                                variables[index] = newValue as VariableOption;
                                props.onChange(variables);
                            }}
                            value={value}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        {!props.dataHubMode && <div style={{ flexGrow: 1 }} />}
                        <div
                            className="flex-simple-column"
                            style={{ marginLeft: 5 }}
                        >
                            <Button
                                className={"btn-small-questionnaire"}
                                title={"Add variable"}
                                style={{
                                    width: "19px",
                                    height: "19px",
                                    border: props.dataHubMode
                                        ? "none"
                                        : undefined,
                                }}
                                onClick={() => {
                                    let variables = Array.from(props.variables);
                                    variables.push(null);
                                    props.onChange(variables);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                            {props.variables.length > 1 && (
                                <Button
                                    className={"btn-small-questionnaire"}
                                    title={"Remove variable"}
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                        border: props.dataHubMode
                                            ? "none"
                                            : undefined,
                                    }}
                                    onClick={() => {
                                        let variables = Array.from(
                                            props.variables
                                        );
                                        variables.splice(index, 1);
                                        props.onChange(variables);
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            )}
                        </div>
                    </div>
                    {!props.dataHubMode && (
                        <div
                            style={{
                                height: "2px",
                                backgroundColor:
                                    dataScienceElementsStyle.secondaryTextColor,
                                marginBottom: "20px",
                            }}
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
});
export default VariablesSelectorView;
