import { mainStyle } from "common/MainStyle";

export const TooltipStyles = (
    tooltipColor?: string,
    tooltipFontSize?: number
) => {
    return {
        contentStyle: {
            backgroundColor:
                tooltipColor ??
                mainStyle.getPropertyValue("--charts-tooltip-background-color"),
            boxShadow: "1px 1px 8px #CCCCCC",
        },
        labelStyle: {
            fontSize: tooltipFontSize ?? 14,
            color: mainStyle.getPropertyValue("--charts-tooltip-title-color"),
        },
        itemStyle: {
            fontSize: tooltipFontSize ?? 14,
            //  color: mainStyle.getPropertyValue("--charts-tooltip-title-color"),
        },
    };
};
