import { CHECKABLE_LIST_ITEM } from 'draft-js-checkable-list-item';

// The function will return block inline styles using block level meta-data
export default function blockStyleFn(block) {
  if (block.getType() === CHECKABLE_LIST_ITEM) {
    return CHECKABLE_LIST_ITEM
  }
  const blockAlignment = block.getData() && block.getData().get('text-align');
  if (blockAlignment) {
    return `rdw-${blockAlignment}-aligned-block`;
  }
  return '';
}
