import React from "react";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import styles from "./ChartSelector.module.css";
import buttonStyles from "./DropdownButton.module.css";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { ReactComponent as CheckMarkIcon } from "icons/canvas/exploration/check_mark.svg";
import cx from "classnames";
import ChartIcon from "../ChartIcon";

function MenuItem(props: {
    onClick?: () => void;
    className?: string;
    icon: JSX.Element;
    name: string;
    showArrow?: boolean;
    showCheckmark?: boolean;
}) {
    return (
        <div
            className={cx(styles.item, props.className)}
            onClick={props.onClick}
        >
            {props.icon}
            <div className={styles.text}>{props.name}</div>
            <div style={{ flexGrow: 1 }}></div>
            {props.showArrow && <ChevronIcon stroke={"#333333CC"} />}
            {props.showCheckmark && <CheckMarkIcon stroke={"#333333CC"} />}
        </div>
    );
}

export default function ChartSelector(props: {
    selectedChart?: {
        title: string;
        name: string;
    };
    charts: {
        title: string;
        name: string;
    }[];
    onClick: (chartName: string) => void;
}) {
    let [opened, setOpened] = React.useState<boolean>(false);
    return (
        <BootstrapDropdown
            className={styles.root}
            show={opened}
            onToggle={() => {
                setOpened(!opened);
            }}
        >
            <BootstrapDropdown.Toggle className={buttonStyles.root}>
                <MenuItem
                    icon={<ChartIcon name={props.selectedChart?.name ?? ""} />}
                    name={props.selectedChart?.title ?? ""}
                    showArrow
                />
            </BootstrapDropdown.Toggle>
            <BootstrapDropdown.Menu className={styles.menu}>
                {props.charts.map((chart, index) => {
                    let selected = props.selectedChart?.name === chart.name;
                    return (
                        <MenuItem
                            key={index}
                            className={
                                selected
                                    ? cx(
                                          styles.listItem,
                                          styles.listItemSelected
                                      )
                                    : styles.listItem
                            }
                            icon={<ChartIcon name={chart.name} />}
                            onClick={() => {
                                props.onClick(chart.name);
								setOpened(false);
                            }}
                            name={chart.title}
                            showArrow={false}
                            showCheckmark={selected}
                        />
                    );
                })}
            </BootstrapDropdown.Menu>
        </BootstrapDropdown>
    );
}
