import React from "react";
import Select from "react-select";

import { MapFinding } from "common/Finding";
import { MapDataVariableValue } from "common/Canvas";
import remoteModuleId from "common/remoteModuleId";
import Variables, { VariableOption } from "common/Variables";
import LocationSelectorView from "modules/canvas_page/question_views/RevampedLocationSelectorView";

import styles from "./HeatmapConfig.module.css";

interface Props {
    mapFinding: MapFinding;
    onChange: (finding: MapFinding, update?: boolean) => void;
    currentModuleId?: number;
}

function HeatmapConfig({ mapFinding, onChange, currentModuleId }: Props) {
    return (
        <div className={styles.root}>
            <LocationSelectorView
                dataScope={mapFinding.config.dataScope}
                usesCoordinates={mapFinding.config.usesCoordinates}
                dataVariableOption={mapFinding.config.dataVariableOption}
                country={mapFinding.config.location?.country}
                state={mapFinding.config.location?.state}
                city={mapFinding.config.location?.city}
                address={mapFinding.config.location?.address}
                zipcode={mapFinding.config.location?.zipcode}
                latitude={mapFinding.config.coordinates?.latitude}
                longitude={mapFinding.config.coordinates?.longitude}
                maxHeight={100}
                onChange={(locationChanges, coordinateChanges) => {
                    let newFinding = {
                        ...mapFinding,
                        config: {
                            ...mapFinding.config,
                        },
                    };

                    if (locationChanges != null) {
                        newFinding.config.location = {
                            ...newFinding.config.location,
                            ...locationChanges,
                        };
                        newFinding.config.coordinates = undefined;
                    }
                    if (coordinateChanges != null) {
                        newFinding.config.coordinates = {
                            ...newFinding.config.coordinates,
                            ...coordinateChanges,
                        };
                        newFinding.config.location = undefined;
                    }
                    onChange(newFinding, true);
                }}
                onToggle={(newOption) => {
                    const newFinding = {
                        ...mapFinding,
                        config: {
                            ...mapFinding.config,
                            usesCoordinates:
                                newOption.value ===
                                MapDataVariableValue.LatitudeLongitude,
                            dataVariableOption: newOption,
                            location: undefined,
                            coordinates: undefined,
                        },
                    };
                    onChange(newFinding, true);
                }}
                currentModuleId={currentModuleId}
            />
            <Select
                isClearable
                placeholder="Select metric"
                styles={{
                    container: (provided) => ({
                        ...provided,
                        minWidth: 60,
                        marginTop: 10,
                    }),
                    control: (provided) => ({
                        ...provided,
                        minHeight: 25,
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                    }),
                    menu: (base) => ({
                        ...base,
                        zIndex: 100000000,
                    }),
                }}
                options={
                    Variables(
                        mapFinding.config.dataScope?.value,
                        currentModuleId ?? remoteModuleId
                    ).variableOptions
                }
                onChange={(newValue) => {
                    const newFinding = {
                        ...mapFinding,
                        config: {
                            ...mapFinding.config,
                            heatMap: newValue as VariableOption | null,
                        },
                    };
                    onChange(newFinding, true);
                }}
                value={mapFinding.config.heatMap}
            />
        </div>
    );
}

export default HeatmapConfig;
