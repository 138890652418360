import Variables from "common/Variables";
import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props) {
        let { data, config } = props;
        let item = {
            type: "12",
            content: {
                data: data,
            },
            config: config,
        };
        return item;
    }
    static getData(config, findingOptions, moduleId) {
        let {
            dataScope,
            selectedTargetVariables,
            selectedTargetVariablesIndices,
            conditions,
            operationVariable,
            selectedTable,
            additionalParameter,
            additionalParameterIndex,
            leftAdditionalOperator,
            rightAdditionalOperator,
            leftAdditionalValue,
            rightAdditionalValue,
            targetValues,
            columnByVariableIndex,
        } = config;

        if (targetValues != null) {
            targetValues = targetValues
                .filter((value) => value?.value != null)
                .map((value) => value.value);
        }

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        if (targetValues != null) {
            for (let [index, targetValue] of targetValues.entries()) {
                conditions.push({
                    operation: {
                        value: "=",
                    },
                    leftBracket:
                        index === 0
                            ? {
                                  value: "(",
                              }
                            : null,
                    rightBracket:
                        index === targetValues.length - 1
                            ? {
                                  value: ")",
                              }
                            : null,
                    logical: { value: index === 0 ? "and" : "or" },
                    value: { value: targetValue },
                    variable: { value: selectedTargetVariablesIndices[0] },
                });
            }
        }
        conditions = conditionsToJson(conditions);
        let requestJson = {
            column_by: columnByVariableIndex,
            group_operation: operationVariable,
            variable_indices: selectedTargetVariablesIndices,
            table: selectedTable.value,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            condition_id: selectedTable.condition_id,
            conditions: conditions,
            module_id: moduleId,
        };
        if (
            additionalParameter &&
            (leftAdditionalValue || rightAdditionalValue)
        ) {
            requestJson.where = {
                group_index: additionalParameterIndex,
                expressions: [],
            };
            if (leftAdditionalValue) {
                requestJson.where.expressions.push({
                    operation: leftAdditionalOperator?.value ?? "=",
                    value: leftAdditionalValue.value,
                });
            }
            if (rightAdditionalValue) {
                requestJson.where.expressions.push({
                    operation: rightAdditionalOperator?.value ?? "=",
                    value: rightAdditionalValue.value,
                });
            }
        }
        return axios
            .post("/api/e/compare_variables", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let dataComparison = response.data.compare_data[0];
                    if (
                        operationVariable !== "count_column" &&
                        operationVariable !== "mean_column" &&
                        operationVariable !== "sum_column"
                    ) {
                        dataComparison.forEach((item) => {
                            item.values = selectedTargetVariablesIndices.map(
                                (variableIndex, index) => ({
                                    name: selectedTargetVariables[index],
                                    value: item.values[variableIndex],
                                    units: Variables(
                                        requestJson.data_table_idx,
                                        moduleId,
                                    ).dataVariables[variableIndex]?.unit,
                                })
                            );
                        });
                    } else {
                        let allKeys = [];

                        dataComparison.forEach((item, index) => {
                            let keys = Object.keys(item.values);
                            allKeys = allKeys.concat(keys);
                        });
                        dataComparison.forEach((item) => {
                            allKeys = [...new Set(allKeys)];
                            item.values = allKeys.map((key) => ({
                                name: key,
                                value: item.values[key] ?? 0,
                                units: "",
                            }));
                        });
                    }
                    let finalItem = Api.getFinding({
                        data: dataComparison,
                        config: config,
                    });
                    return Promise.resolve(finalItem);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
