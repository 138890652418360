import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Instrumentation from "common/Instrumentation";
import PasswordStrengthMessage from "common/PasswordStrength";
import Alert from "./Alert";
import axios from "./ServerConnection";
import sections from "sections.json";
import { goToInternalLink } from "common/InternalLinksHelper";

enum UpdateStatus {
    success = 1,
    loading = 2,
    error = 3,
    notUploaded = 4,
}

interface Props {
    onClose: () => void;
    mandatory?: boolean;
    section?: keyof typeof sections;
    userName?: string;
    reset?: boolean;
}

interface State {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    status: UpdateStatus;
    errorMessage: string;
}

class PasswordChangeForm extends Component<Props, State> {
    private performance: Date | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            status: UpdateStatus.notUploaded,
            errorMessage: "",
        };

        this.performance = null;
    }

    componentDidUpdate(): void {
        if (this.props.section != null && this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction(this.props.section, timeMs);
        }
    }

    private updatePassword(): void {
        let localPerformance: Date = new Date();
        this.setState({
            status: UpdateStatus.loading,
            errorMessage: "",
        });
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/change_user_password", {
                password: this.state.newPassword,
                old_password: this.state.oldPassword,
            })
            .then((response) => {
                if (response.data.success) {
                    if (this.props.section != null) {
                        Instrumentation.addInteraction(
                            this.props.section,
                            new Date().getTime() - localPerformance.getTime()
                        ).then(() => {
                            Instrumentation.close().then(() => {
                                goToInternalLink("/logout.html");
                            });
                        });
                    } else {
                        Instrumentation.close().then(() => {
                            goToInternalLink("/logout.html");
                        });
                    }
                } else {
                    this.performance = localPerformance;
                    console.log(response.data.error_msg);
                    this.setState({
                        status: UpdateStatus.error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                this.performance = localPerformance;
                console.log(error);
                this.setState({
                    status: UpdateStatus.error,
                });
            });
    }

    private resetPassword(): void {
        if (this.props.userName == null) return;

        let localPerformance: Date = new Date();
        this.setState({
            status: UpdateStatus.loading,
            errorMessage: "",
        });
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/reset_user_password", {
                password: this.state.newPassword,
                user_name: this.props.userName,
            })
            .then((response) => {
                this.performance = localPerformance;
                if (response.data.success) {
                    this.setState({
                        status: UpdateStatus.success,
                    });
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        status: UpdateStatus.error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                this.performance = localPerformance;
                console.log(error);
                this.setState({
                    status: UpdateStatus.error,
                });
            });
    }

    private buildAlert(): JSX.Element {
        let alert: JSX.Element = <div style={{ height: 72 }} />;
        if (this.state.status === UpdateStatus.success) {
            alert = (
                <Alert
                    text="Successully updated."
                    className="alert alert-success alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.error) {
            alert = (
                <Alert
                    text={"Update error: ".concat(this.state.errorMessage)}
                    className="alert alert-danger alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.loading) {
            alert = (
                <Alert
                    text={"Updating settings"}
                    className="alert alert-warning alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        }
        return alert;
    }

    render(): JSX.Element {
        return (
            <div
                className="center-container"
                style={{
                    overflow: "visible",
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    className="flex-simple-column"
                    style={{
                        overflow: "visible",
                        marginTop: 20,
                        height: "100%",
                        width: "90%",
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {this.props.mandatory && (
                        <span className="regular-text">
                            Your password has expired
                        </span>
                    )}
                    {!this.props.reset && (
                        <div>
                            <span style={{display: 'inline-block', fontSize: 16, width: 192}}>Enter old password: </span>
                            <input
                                type={"password"}
                                placeholder={"Old password"}
                                className="like-select"
                                style={{
                                    marginTop: "10px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        oldPassword: e.target.value,
                                    });
                                }}
                                value={this.state.oldPassword}
                            />
                        </div>
                    )}
                    <div>
                        <span style={{display: 'inline-block', fontSize: 16, width: 192}}>Enter new password: </span>
                        <input
                            type={"password"}
                            placeholder={"New password"}
                            className="like-select"
                            style={{
                                marginTop: "10px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                            }}
                            onChange={(e) => {
                                let newValue: string = e.target.value;
                                this.setState({
                                    newPassword: newValue,
                                });
                            }}
                            value={this.state.newPassword}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginTop: 10,
                            color: '#333',
                            width: 369
                        }}
                    >
                        <div style={{width: 192}}/>
                        <PasswordStrengthMessage
                            password={this.state.newPassword}
                            style={{
                                width: "100%",
                            }}
                            leftTextStyle={{
                                flex: 1,
                                display: 'none'
                            }}
                            rightTextStyle={{
                                width: "6em",
                                textAlign: "right",
                            }}
                        />
                    </div>
                    <div>
                        <span style={{display: 'inline-block', fontSize: 16, width: 192}}>Confirm new password: </span>
                        <input
                            type={"password"}
                            placeholder={"Confirmation"}
                            className="like-select"
                            style={{
                                marginTop: "10px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                            }}
                            onChange={(e) => {
                                let newValue: string = e.target.value;
                                this.setState({
                                    confirmPassword: newValue,
                                });
                            }}
                            value={this.state.confirmPassword}
                        />
                    </div>
                    <div style={{ marginTop: "10px" }}>{this.buildAlert()}</div>
                    <div
                        className="my-row"
                        style={{
                            marginTop: 0,
                            alignSelf: "flex-end",
                            alignItems: "center",
                            marginBottom: 20,
                        }}
                    >
                        {!this.props.mandatory && (
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: 10,
                                    width: "112px",
                                    fontSize: 14,
                                    color: '#3b82c9',
                                    border: '1px solid #e0e0e0',
                                    backgroundColor: 'white'
                                }}
                                onClick={() => {
                                    this.props.onClose();
                                }}
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            type="button"
                            disabled={
                                (!this.state.oldPassword &&
                                    !this.props.reset) ||
                                !this.state.newPassword ||
                                !this.state.confirmPassword
                            }
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginLeft: 10,
                                width: "112px",
                                backgroundColor: '#3b82c9',
                                outline: "#3b82c9",
                                fontSize: 14,
                            }}
                            onClick={() => {
                                if (
                                    this.state.confirmPassword !==
                                    this.state.newPassword
                                ) {
                                    this.performance = new Date();
                                    this.setState({
                                        status: UpdateStatus.error,
                                        errorMessage: "Passwords didn't match",
                                    });
                                    return;
                                }
                                if (this.props.reset) this.resetPassword();
                                else this.updatePassword();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordChangeForm;
