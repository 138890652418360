import React from "react";

import { CanvasElement, CanvasTextBox } from "common/Canvas";

import styles from "./OutputsConfig.module.css";
import OutputsSelector from "modules/canvas_page/OutputsSelector";

interface Props {
    node: CanvasElement | CanvasTextBox;
    onChange: (changes: Partial<CanvasElement>, commit?: boolean) => void;
}

function OutputsConfig({ node, onChange }: Props) {
    return (
        <div className={styles.root}>
            <h6 className={styles.title}>Outputs</h6>
            <div className={styles.inputDropdown}>
                <OutputsSelector value={node} onChange={onChange} />
            </div>
        </div>
    );
}

export default OutputsConfig;
