import React, { Component } from "react";
import moment from "moment";
import cx from "classnames";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import { AppModuleProps } from "AppModule";
import ScaledPage from "common/ScaledPage";
import { CustomerInfo, listCustomersApi } from "./api";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import Avatar from "common/Avatar";
import User from "state/models/User";
import StripeConnect from "./StripeConnect";
import { strftime } from "common/utilities/TimeFormatUtils";
import styles from "./styles.module.css";

const paymentTypeLabel: Readonly<{ [key: string]: string }> = {
    none: "None",
    one_time: "One Time",
    monthly: "Monthly",
    yearly: "Yearly",
};

const typeLabel: Readonly<{ [key: string]: string }> = {
    app: "App",
    kit: "Kit",
};

interface State {
    status: PopupStatus | null;
    message: string;
    customers: CustomerInfo[];
    loading: boolean;
}

class MainComponent extends Component<AppModuleProps, State> {
    constructor(props: AppModuleProps) {
        super(props);
        this.state = {
            status: null,
            message: "",
            customers: [],
            loading: true,
        };
        this.renderContents = this.renderContents.bind(this);
        this.listCustomers = this.listCustomers.bind(this);
    }

    public componentDidMount(): void {
        this.listCustomers();
    }

    private listCustomers(): void {
        listCustomersApi()
            .then((customers) => {
                this.setState({ customers: customers, loading: false });
            })
            .catch((message) => {
                console.log(message);
                this.setState({
                    status: PopupStatus.Error,
                    message: message.toString(),
                    loading: false,
                });
            });
    }

    private renderCustomers(): JSX.Element {
        if (this.state.loading) {
            return <span className={styles.noCustomersText}>LOADING</span>;
        }
        if (this.state.customers.length === 0) {
            return (
                <span className={styles.noCustomersText}>NO CUSTOMERS</span>
            );
        }
        return (
            <div className={styles.customerTable}>
                <div className={styles.customerHeaderRow}>
                    <span />
                    <span className={styles.customerHeaderText}>Username</span>
                    <span className={styles.customerHeaderText}>
                        First Name
                    </span>
                    <span className={styles.customerHeaderText}>Last Name</span>
                    <span className={styles.customerHeaderText}>Type</span>
                    <span className={styles.customerHeaderText}>
                        App/Kit Subscribed
                    </span>
                    <span className={styles.customerHeaderText}>Price</span>
                    <span className={styles.customerHeaderText}>
                        Payment Period
                    </span>
                    <span className={styles.customerHeaderText}>
                        Date Subscribed
                    </span>
                    <span className={styles.customerHeaderText}>
                        Months Subscribed
                    </span>
                    <span className={styles.customerHeaderText}>
                        Total Revenue
                    </span>
                    <span className={styles.customerHeaderText}>
                        Unsubscribed On
                    </span>
                </div>
                {this.state.customers.map((customer, index) => (
                    <div className={styles.customerRow} key={index}>
                        <Avatar
                            className={styles.avatar}
                            image={customer.user_info.icon_url}
                            user={new User(customer.user_info)}
                        />
                        <span className="regular-text">
                            {customer.user_info.user_name}
                        </span>
                        <span className="regular-text">
                            {customer.user_info.first_name}
                        </span>
                        <span className="regular-text">
                            {customer.user_info.last_name}
                        </span>
                        <span className="regular-text">
                            {typeLabel[customer.item_info.type]}
                        </span>
                        <span className="regular-text">
                            {customer.item_info.title}
                        </span>
                        <span className="regular-text">
                            {customer.item_info.paywall?.price != null
                                ? `$${customer.item_info.paywall.price}`
                                : "Free"}
                        </span>
                        <span className="regular-text">
                            {
                                paymentTypeLabel[
                                    customer.item_info.paywall?.payment_type ??
                                        "none"
                                ]
                            }
                        </span>
                        <span className="regular-text">
                            {customer.subscription_info.creation_time != null
                                ? strftime(
                                      "%m/%d/%y",
                                      customer.subscription_info.creation_time,
                                      true
                                  )
                                : "-"}
                        </span>
                        <span className="regular-text">
                            {customer.subscription_info.creation_time != null
                                ? moment().diff(
                                      customer.subscription_info.creation_time,
                                      "months"
                                  ) + 1
                                : "-"}
                        </span>
                        <span className="regular-text">
                            {`$${customer.subscription_info.total_revenue}`}
                        </span>
                        <span className="regular-text">
                            {customer.subscription_info.ended_at != null
                                ? strftime(
                                      "%m/%d/%y",
                                      customer.subscription_info.ended_at,
                                      true
                                  )
                                : "-"}
                        </span>
                    </div>
                ))}
            </div>
        );
    }

    private renderContents(_globalContext: GlobalContextContents): JSX.Element {
        return (
            <ScaledPage>
                <div
                    className={cx(
                        "content-wrapper",
                        "hide-scroll",
                        styles.contentWrapper
                    )}
                >
                    <section
                        className={cx("content", styles.contentSection)}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            ...this.props.positionStyle,
                        }}
                    >
                        <span className={styles.headerText}>
                            Stripe Account
                        </span>
                        <div className={styles.stripeConnectContainer}>
                            <StripeConnect />
                        </div>
                        <span className={styles.headerText}>
                            Your Subcribed Customers
                        </span>
                        {this.renderCustomers()}
                        {this.state.status != null && (
                            <StatusPopup
                                status={this.state.status}
                                message={this.state.message}
                                onClose={() => {
                                    this.setState({
                                        status: null,
                                        message: "",
                                    });
                                }}
                            />
                        )}
                    </section>
                </div>
            </ScaledPage>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export { MainComponent };
export let route = "/subscriptions.html";
