import React, { Component, CSSProperties } from "react";
import styles from "./MessagePopup.module.css";
import OutsideAlerter from "common/OutsideAlerter";
import cx from "classnames";

interface Props {
    title: any;
    danger?: boolean;
    style?: CSSProperties;
    acceptButtonTitle?: string;
    rejectButtonTitle?: string;
    message: any;
    onAccept?: (arg?: { fields?: Object }) => void;
    onReject?: () => void;
    width?: string | number;
    height?: string | number;
    fields?: Array<string>;
    crossIcon?: "false";
    rejactButton?: "false";
    disabled?: any;
    small?: boolean;
    updateBtn?: boolean;
    onReplace?: () => void;
    disableReplace?: boolean;
    className?: string
}

interface Fields {
    [key: string]: string;
}

interface State {
    fields?: Fields;
}

class MessagePopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            fields: undefined,
        };
    }

    private handleChange(item: string, event: React.ChangeEvent<HTMLInputElement>) {
        const { fields } = this.state;
        if (fields) {
            fields[item] = event.target.value;
            this.setState(fields);
        } else {
            const newFields: Fields = {}
            newFields[item] = event.target.value;
            this.setState({ fields: newFields });
        }
    }

    private buildInnerView(): JSX.Element {
        return (
            <div
                className={`flex-simple-column ${styles.messagePopupContainer}`}
            >
                <div className={styles.header} >
                    {this.props.title}
                    {
                        // !this.props.crossIcon &&
                        (
                            <div
                                className={styles.crossIconContainer}
                                onClick={() => {
                                    this.props.onReject!();
                                }}
                            >
                                {/* {
                                    <CrossIcon className={this.props.small ? styles.crossIconSmall : styles.crossIcon} color='black' />
                                } */}
                            </div>
                        )}
                </div>
                <div className={`${styles.content} scrollBar`}>
                    <p style={{ paddingInline: 20 }}>{this.props.message}</p>
                </div>
                {this.props.fields && this.props.fields.map((item, index) => (
                    <div className="input-group" style={{ paddingBottom: 20 }}>
                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }} >
                            <input
                                text-transform="lowercase"
                                type="text"
                                name={item}
                                id={item}
                                placeholder={item}
                                className="input"
                                onChange={this.handleChange.bind(this, item)}
                            />
                        </span>
                    </div>
                ))}
                {
                    (!this.props.rejactButton||this.props.onAccept) &&
                    <div className="popupbuttonrow">
                        {!this.props.rejactButton && (
                            <button
                                type="button"
                                className={"cancelBtn"}
                                style={{ color: 'var(--acceptBtn-color)', }}
                                onClick={() => {
                                    this.props.onReject!();
                                }}
                            >
                                {this.props.rejectButtonTitle ?? "Cancel"}
                            </button>
                        )}
                        {this.props.updateBtn && (
                            <button
                                type="button"
                                className={cx(
                                    "acceptBtn",
                                )}
                                style={{
                                    backgroundColor: this.props.danger ? "var(--danger-color)" : "",
                                }}
                                onClick={() => {
                                    this.props.onReplace!();
                                }}
                                disabled={this.props.disableReplace}
                            // disabled={(this.props.disabled !== undefined && this.props.disabled === null) || this.props.disabled}
                            >
                                Replace
                            </button>
                        )}
                        {this.props.onAccept && (
                            <button
                                disabled={(this.props.disabled !== undefined && this.props.disabled === null) || this.props.disabled}
                                type="button"
                                className={cx(
                                    "acceptBtn",
                                    this.props.danger && styles.acceptButtonDanger
                                )}
                                style={{
                                    marginLeft: 10,
                                    backgroundColor: this.props.danger ? "var(--danger-color)" : "",
                                    paddingBlock: !this.props.rejactButton ? 0 : 8
                                }}
                                onClick={() => {
                                    if (this.props.fields) {
                                        if (this.state.fields) {
                                            if (Object.keys(this.state.fields).length === this.props.fields.length) {
                                                if (this.props.onAccept) {
                                                    this.props.onAccept(this.state.fields)
                                                }
                                            } else {
                                                alert("Please key in all the required attributes");
                                            }
                                        } else {
                                            alert("Please key in all the required attributes");
                                        }
                                    } else {
                                        if (this.props.onAccept) this.props.onAccept();
                                    }
                                }}
                            >
                                {this.props.acceptButtonTitle ?? "Ok"}
                            </button>
                        )}
                    </div>
                }
            </div>
        );
    }

    render() {
        return (
            <OutsideAlerter
                onReject={() => {
                    if (this.props.onReject != null) {
                        this.props.onReject();
                    }
                }}
            >
                <div
                    className={`${styles.container} ${this.props.className}`}
                    onClick={(evt) => {
                        evt.stopPropagation();
                    }}
                    style={{
                        height: this.props.height ? this.props.height : "auto",
                        width: this.props.width ? this.props.width : "100%",
                    }}
                >
                    {this.buildInnerView()}
                </div>
            </OutsideAlerter>

        );
    }
}

export default MessagePopup;
