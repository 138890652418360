import React, { Component } from "react";
import { DualRing } from "react-spinners-css";
import Popup from "reactjs-popup";

import "./GraphElementStyle.css";
import { GraphElement, GraphNode, ColorOptions } from "common/Canvas";
import { goToInternalLink } from "common/InternalLinksHelper";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import { PopupStatus } from "common/StatusPopup";
import HamburgerMenu from "./canvas_elements/MapElements/HamburgerMenu";
import NetworkManual from "common/NetworkManual";

interface Props {
    // called when we click on context menu
    onContextMenu: (evt: React.MouseEvent) => void;

    // data layer element from Canvas structure
    graphElement: GraphElement;

    // unique identifier of data layer element
    graphElementId: string;

    // height of element (unscaled)
    height: number;

    // if true then we in live mode
    live: boolean;

    // if true than we cannot edit the graph
    frozen: boolean;

    // scale of presentation
    scale: number;

    // indicates if we are seeing graph from shared link
    sharedPolicy: CanvasSharedPolicy;

    // onChange callback
    onChange: (graphElement: Partial<GraphElement>) => void;
    // onDelete callback
    onDelete: () => void;
    // id of current presentation
    currentModuleId: number | undefined;
    // track performance callback
    onTrackNewPerformance: (element: string) => void;
    // htmlElementsRootRef: HTMLDivElement | undefined;
    hovered: boolean;
    loading: boolean;

    toggleOpenEditMenu: (graphId: string) => void;
}

enum OperationStatus {
    NotStarted = 1,
    Running = 2,
    Success = 3,
    Error = 4,
}

interface State {
    updating: boolean;
    operationErrorMessage: string | null;
    operationStatus: OperationStatus;
    hoverInfo: {
        index: number;
        x: number;
        y: number;
    } | null;
    colorVariableValues: (string | number | null)[];
    colorVariableValueToIndex: Map<string | number | null, number>;
    popupStatus: PopupStatus | undefined;
    message: string | undefined;
    hamburgerMenuOpened: boolean;
    nodePopupForNodeId: number | null;
}

export default class GraphElementView extends Component<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement>;
    private hamburgerMenuRef: React.RefObject<HTMLDivElement>;
    // Type declaration for GraphView is incomplete, so we have to use any
    private graphViewRef: React.RefObject<any>;

    constructor(props: Props) {
        super(props);
        this.state = {
            updating: false,
            operationErrorMessage: null,
            operationStatus: OperationStatus.NotStarted,
            hoverInfo: null,
            colorVariableValues: [],
            colorVariableValueToIndex: new Map(),
            popupStatus: undefined,
            message: undefined,
            hamburgerMenuOpened: false,
            nodePopupForNodeId: null,
        };
        this.rootRef = React.createRef();
        this.hamburgerMenuRef = React.createRef();
        this.graphViewRef = React.createRef();

        this.onCreateNode = this.onCreateNode.bind(this);
        // this.onCreateEdge = this.onCreateEdge.bind(this);
        this.onNodeClick = this.onNodeClick.bind(this);
    }

    private onCreateNode(x: number, y: number): void {
        let zoomLevel: number =
            this.graphViewRef.current.state?.viewTransform?.k ?? 1;
        this.props.onChange({
            nodes: {
                ...this.props.graphElement.nodes,
                [this.props.graphElement.nextNodeId]: {
                    id: this.props.graphElement.nextNodeId,
                    title: "",
                    x:
                        x -
                        (this.props.graphElement.x * this.props.scale) /
                            zoomLevel,
                    y:
                        y -
                        (this.props.graphElement.y * this.props.scale) /
                            zoomLevel,
                    type: "defaultNode",
                },
            },
            nextNodeId: this.props.graphElement.nextNodeId + 1,
        });
    }

    // private onCreateEdge(sourceNode: INode, targetNode: INode): void {
    //     this.props.onChange({
    //         edges: {
    //             ...this.props.graphElement.edges,
    //             [this.props.graphElement.nextEdgeId]: {
    //                 source: sourceNode.id,
    //                 target: targetNode.id,
    //                 type: "defaultEdge",
    //             },
    //         },
    //         nextEdgeId: this.props.graphElement.nextEdgeId + 1,
    //     });
    // }

    private onNodeClick(nodeId: number) {
        let node: GraphNode = this.props.graphElement.nodes[nodeId];
        if (node.metrics != null && node.metrics.length !== 0) {
            this.setState({
                nodePopupForNodeId: node.id,
            });
        }
    }

    /*!
     * function buildInnerItem renders main view of component
     */
    private buildInnerItem(): JSX.Element | null {
        const graphViewScale = 1.0;
        return (
            <div>
                <div
                    style={{
                        width: `calc((100% - 20px) / ${this.props.scale} * ${graphViewScale})`,
                        height: `calc((100% - 20px)  / ${this.props.scale} * ${graphViewScale})`,
                        // If position is not absolute, then scaleY
                        // would consider the parent's height, which
                        // is NOT the desired behavior
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        transform: `scale(${
                            this.props.scale / graphViewScale
                        })`,
                        transformOrigin: "top left",
                    }}
                >
                    {this.props.loading && (
                        <div
                            style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                zIndex: 2,
                                backgroundColor: "rgba(0, 0, 0, .2)",
                            }}
                        >
                            <DualRing
                                color="#323232"
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            />
                        </div>
                    )}
                    <div
                        className="element-graph"
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            zIndex: 1,
                        }}
                    >
                        <NetworkManual
                            ref={this.graphViewRef}
                            nodes={this.props.graphElement.nodes}
                            edges={this.props.graphElement.edges}
                            onNodeClick={this.onNodeClick}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private buildContent(): JSX.Element {
        return (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <div style={{ width: "100%" }}>{this.buildInnerItem()}</div>
            </div>
        );
    }

    public render(): JSX.Element {
        let colorOptions: ColorOptions = this.props.graphElement
            .colorOptions ?? {
            borderShadow: false,
            fillColor: dataScienceElementsStyle.contentColor,
            borderColor: dataScienceElementsStyle.borderColor,
        };
        return (
            <>
                <div
                    ref={this.rootRef}
                    className="dashboard-rect-canvas dashboard-rect-canvas-focus"
                    tabIndex={0}
                    style={{
                        boxShadow: colorOptions.borderShadow
                            ? "0 6px 13px 0 rgba(21, 33, 56, 0.53)"
                            : "none",
                        backgroundColor: colorOptions.fillColor,
                        border: colorOptions.borderColor
                            ? `1px solid ${colorOptions.borderColor}`
                            : undefined,
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                    }}
                    onContextMenu={this.props.onContextMenu}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        {this.buildContent()}
                    </div>
                    {this.state.nodePopupForNodeId != null && (
                        <Popup
                            arrow={true}
                            contentStyle={{
                                maxHeight: "100vh",
                                border: "none",
                                backgroundColor: "transparent",
                            }}
                            open={true}
                            onClose={() => {
                                this.setState({
                                    nodePopupForNodeId: null,
                                });
                            }}
                        >
                            <div
                                className="dashboard-rect element"
                                style={{
                                    overflowX: "visible",
                                    overflowY: "auto",
                                    boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                                    borderRadius: 0,
                                    alignItems: "center",
                                    cursor: "pointer",
                                    // minHeight: 600,
                                    maxHeight: "100vh",
                                    // width: 600,
                                    padding: 15,
                                }}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onMouseDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                            >
                                <span
                                    className="regular-text"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    {Object.entries(
                                        this.props.graphElement.nodes[
                                            this.state.nodePopupForNodeId.toString()
                                        ].metrics ?? {}
                                    )
                                        .map(
                                            ([key, value]) => `${key}: ${value}`
                                        )
                                        .join("\n")}
                                </span>
                            </div>
                        </Popup>
                    )}
                    {!this.props.live &&
                        !this.props.frozen &&
                        (this.props.hovered ||
                            this.state.hamburgerMenuOpened) && (
                            <div
                                ref={this.hamburgerMenuRef}
                                style={{
                                    position: "absolute",
                                    width: 15 * this.props.scale,
                                    height: "100%",
                                    top: 0,
                                    right: -15 * this.props.scale,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <HamburgerMenu
                                    onToggle={(show) => {
                                        this.setState({
                                            hamburgerMenuOpened: show,
                                        });
                                    }}
                                    sharedPolicy={this.props.sharedPolicy}
                                    scale={this.props.scale}
                                    elementId={this.props.graphElementId}
                                    onDelete={this.props.onDelete}
                                    onEdit={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.NotShared
                                        ) {
                                            this.props.toggleOpenEditMenu(
                                                this.props.graphElementId
                                            );
                                            this.setState({
                                                hamburgerMenuOpened: false,
                                            });
                                        }
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.SharedSlideUnAuth
                                        )
                                            goToInternalLink("/");
                                    }}
                                />
                            </div>
                        )}
                </div>
            </>
        );
    }
}
