import React from "react";
import CreatableSelect from "react-select/creatable";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";
import { lightThemeStyle } from "common/LightThemeStyle";
import StringOption from "common/StringOption";
import { getCustomSelectStyle, Styles } from "common/SelectStyles";

interface State {
    inputHeadersList: KeyValue[];
    inputBodyParamsList: KeyValue[];
    requestMethod: string;
}
interface KeyValue {
    key: string;
    value: string;
}

const endpointOptions: ReadonlyArray<StringOption> = [
    {
        label: "Balance Transactions",
        value: "https://api.stripe.com/v1/balance_transactions",
    },
    {
        label: "Customers",
        value: "https://api.stripe.com/v1/customers",
    },
    {
        label: "Charges",
        value: "https://api.stripe.com/v1/charges",
    },
];

const endpointToOption: Readonly<{
    [key: string]: StringOption;
}> = Object.fromEntries(
    endpointOptions.map((option) => [option.value, option])
);

export default class STRIPE extends React.Component<APIComponentProps, State> {
    constructor(props: APIComponentProps) {
        super(props);
        this.state = {
            inputHeadersList: [{ key: "", value: "" }],
            inputBodyParamsList: [{ key: "", value: "" }],
            requestMethod: "GET",
        };
    }

    componentDidMount() {
        this.setState({
            inputHeadersList: [],
            inputBodyParamsList: [],
            requestMethod: "GET",
        });

        if (this.props.options == null) {
            this.props.onOptionsChange({
                requestMethod: "GET",
            });
        }
    }

    render() {
        // const handleMethodChange = (e: { target: { value: string } }) => {
        //     let options = { ...this.props.options };
        //     this.props.onOptionsChange({
        //         ...options,
        //         requestMethod: e.target.value,
        //     });
        //     if (e.target.value === "GET") {
        //         const bodyParamsDiv = document.getElementById(
        //             "inputBodyParams"
        //         );
        //         bodyParamsDiv?.setAttribute("style", "display:none");
        //     }
        //     if (e.target.value === "POST") {
        //         const bodyParamsDiv = document.getElementById(
        //             "inputBodyParams"
        //         );
        //         bodyParamsDiv?.removeAttribute("style");
        //     }
        // };

        // const handleInputHeaderChange = (
        //     e: { target: { name: string; value: string } },
        //     index: number
        // ) => {
        //     const { name, value } = e.target;
        //     const list = [...this.state.inputHeadersList];
        //     if (name === "headerName") list[index].key = value;
        //     if (name === "headerValue") list[index].value = value;
        //     this.setState({ inputHeadersList: list });
        //     let options = { ...this.props.options };
        //     this.props.onOptionsChange({
        //         ...options,
        //         headers: list,
        //     });
        // };

        // const handleInputBodyParamChange = (
        //     e: { target: { name: string; value: string } },
        //     index: number
        // ) => {
        //     const { name, value } = e.target;
        //     const list = [...this.state.inputBodyParamsList];
        //     if (name === "bodyParamName") list[index].key = value;
        //     if (name === "bodyParamValue") list[index].value = value;
        //     this.setState({ inputBodyParamsList: list });
        //     let options = { ...this.props.options };
        //     this.props.onOptionsChange({
        //         ...options,
        //         bodyParams: list,
        //     });
        // };

        // handle click event of the Remove header button
        // const handleRemoveHeaderClick = (index: number) => {
        //     const list = [...this.state.inputHeadersList];
        //     list.splice(index, 1);
        //     this.setState({ inputHeadersList: list });
        // };

        // handle click event of the Add header button
        // const handleAddHeaderClick = () => {
        //     this.setState({
        //         inputHeadersList: [
        //             ...this.state.inputHeadersList,
        //             { key: "", value: "" },
        //         ],
        //     });
        // };

        // handle click event of the Add body param button
        // const handleAddBodyParamClick = () => {
        //     this.setState({
        //         inputBodyParamsList: [
        //             ...this.state.inputBodyParamsList,
        //             { key: "", value: "" },
        //         ],
        //     });
        // };

        // handle click event of the Remove body param button
        // const handleRemoveBodyParamClick = (index: number) => {
        //     const list = [...this.state.inputBodyParamsList];
        //     list.splice(index, 1);
        //     this.setState({ inputBodyParamsList: list });
        // };

        let contentStyle: React.CSSProperties = {
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 400,
        };

        let titleStyle = {
            ...contentStyle,
            color: lightThemeStyle.secondaryTextColor,
        };

        // let smallInputStyle = {
        //     ...contentStyle,
        //     fontSize: 12,
        //     marginTop: 10,
        //     width: "120px",
        //     borderColor: "transparent",
        //     padding: "5px 5px",
        // };
        //
        // let btnStyle: React.CSSProperties = {
        //     fontFamily: "Roboto",
        //     fontSize: 12,
        //     color: lightThemeStyle.titleTextColor,
        //     backgroundColor: lightThemeStyle.switchOnHandleColor,
        //     width: 120,
        //     margin: 10,
        //     borderColor: "transparent",
        // };

        const customSelectStyles: Styles = getCustomSelectStyle(14);

        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
                <div
                    className="flex-simple-column"
                    style={{ marginTop: "23px" }}
                ></div>
                <span style={titleStyle}>Endpoint</span>
                <CreatableSelect
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({ ...base, width: 300 }),
                    }}
                    value={
                        this.props.options?.endpoint != null
                            ? endpointToOption[this.props.options.endpoint] ?? {
                                  label: this.props.options.endpoint,
                                  value: this.props.options.endpoint,
                              }
                            : null
                    }
                    onChange={(option) => {
                        this.props.onOptionsChange({
                            ...this.props.options,
                            endpoint: (option as StringOption).value,
                        });
                    }}
                    onCreateOption={(value) => {
                        this.props.onOptionsChange({
                            ...this.props.options,
                            endpoint: value,
                        });
                    }}
                    formatCreateLabel={(value) => `Select "${value}"`}
                    options={endpointOptions}
                    placeholder="Select or enter Stripe endpoint here"
                />
                <div
                    className="flex-simple-column"
                    style={{ marginTop: "10px" }}
                ></div>
                <span style={titleStyle}>Secret key</span>
                <input
                    className="like-select"
                    style={{
                        width: 300,
                        paddingLeft: 10,
                        paddingRight: 10,
                        boxSizing: "border-box",
                        borderRadius: "4px",
                        borderColor: "#D1D1D1",
                    }}
                    placeholder="Enter Stripe API secret key here"
                    value={this.props.options?.secret_key ?? ""}
                    onChange={(evt) => {
                        let value = evt.target.value;
                        this.props.onOptionsChange({
                            ...this.props.options,
                            secret_key: value,
                        });
                    }}
                />
            </div>
        );
    }
}

export { STRIPE as MainComponent };
