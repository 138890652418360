import React from "react";
import { ItemMetadata, PageBarInfo } from "common/Canvas";
import { SharedModule } from "common/Module";
import { observer } from "mobx-react";
import CanvasPageBarHorizontal from "../CanvasPageBarHorizontal";
import CanvasPageBarVertical from "../CanvasPageBarVertical";
import { CanvasViewMode } from "../CanvasTreeStore";

export interface  ExtendedPagesBarProps extends PagesBarProps {
    pageBarInfo: PageBarInfo;
}

interface PagesBarProps {
    viewMode: CanvasViewMode;
    pageBarInfo?: PageBarInfo;
    currentPageId: number;
    currentModuleId?: number;
    sharedModule?: SharedModule;
    frozen: boolean;
    live: boolean;
    collapsed: boolean;
    scale: number;
    onMoveGroupSelection: (
        deltaX: number,
        deltaY: number,
        metadata: ItemMetadata,
        skipHistory?: boolean
    ) => void;
    onCurrentPageChange: (pageId: number | string | undefined) => void;
    onResize: () => void;
    layerRect: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    rootRef?: HTMLDivElement | null;
}

const PageBar = (props: PagesBarProps) => {
    let pageBarInfo: PageBarInfo | undefined = props.pageBarInfo;
    if (pageBarInfo == null) return null;
    let accordionFormat = props.viewMode === "mobile"
    ? pageBarInfo?.accordionFormat?.mobile
    : pageBarInfo?.accordionFormat?.desktop;
    if (accordionFormat)
        return <CanvasPageBarVertical {...props} pageBarInfo={pageBarInfo} />;
    return <CanvasPageBarHorizontal {...props} pageBarInfo={pageBarInfo} />;
};

export default observer(PageBar);
