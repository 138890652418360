//import React from "react";
import { createCustomMessage } from "react-chatbot-kit";

// Custom Messages
import CustomBotMessage from "./CustomBotMessage";
import CustomUserMessage from "./CustomUserMessage";
import CustomFirstMessage from "./CustomFirstMessage";
import { CustomMessageProps } from "./ChatbotTypes";

// Color picker from side bar
import { AiCopilotFinding, defaultAiCopilotBannerBgColor } from "common/Finding";

interface Props {
    finding: AiCopilotFinding;
}

// Custom Widgets
const configForFinding = (finding: AiCopilotFinding) => ({
    botName: "LearningBot",
    initialMessages: [
        createCustomMessage("first message", "customFirstMessage", {}),
    ],
    /*customStyles: {
        botMessageBox: {
            backgroundColor: "#376B7E",
        },
        chatButton: {
            backgroundColor: "#376B7E",
        },
    },*/
    customMessages: {
        custom: (props: CustomMessageProps) => <CustomBotMessage {...props} finding={finding} />,
        customFirstMessage: (props: CustomMessageProps) => (
            <CustomFirstMessage {...props} finding={finding} />
        ),
    },
    customComponents: {
        // Overrides .react-chatbot-kit-header colors settings
        header: () => <div style={{
                backgroundColor: finding.config.bannerBgColor ??  defaultAiCopilotBannerBgColor,  
                borderRadius: "0px 0px 0px 0px;",
                height: "58px",
                padding: 0,
            }}> </div>, 
        userChatMessage: CustomUserMessage,
        userAvatar: () => <></>, // CustomUserMessage renders the avatar by itself
    },
    widgets: [],
});

export default configForFinding;
