import React, { Component, CSSProperties } from "react";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import { ShapeElement, ShapeType, getDefaultShapeColor } from "common/Canvas";

interface Props {
    onContextMenu: (evt: React.MouseEvent) => void;
    shapeElement: ShapeElement;
    onChange: (shapeElement: Partial<ShapeElement>) => void;
    shapeElementId: string;
    width: number;
    height: number;
    live: boolean;
    frozen: boolean;
    scale: number;
    sharedPolicy: CanvasSharedPolicy;
    onDelete: () => void;
    currentModuleId: number | undefined;
    onTrackNewPerformance: (element: string) => void;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    resizedHeight?: number;
}

export default class ShapeElementView extends Component<Props> {
    private rootRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.rootRef = React.createRef();
    }

    private getShapeStyle(): CSSProperties {
        switch (this.props.shapeElement.shapeType) {
            case ShapeType.Circle:
                return {
                    borderRadius: "50%",
                };
            case ShapeType.Triangle:
                return {
                    backgroundColor: undefined,
                    borderLeftWidth: this.props.width / 2,
                    borderLeftStyle: "solid",
                    borderLeftColor: "transparent",
                    borderRightWidth: this.props.width / 2,
                    borderRightStyle: "solid",
                    borderRightColor: "transparent",
                    borderBottomWidth: this.props.height,
                    borderBottomStyle: "solid",
                    borderBottomColor: this.getFillColor(),
                };
            default:
                return {};
        }
    }

    private getFillColor(): string {
        return (
            this.props.shapeElement.shapeStyle?.fillColor ??
            getDefaultShapeColor(this.props.shapeElement.shapeType)
        );
    }

    private getBorderColor(): string {
        return (
            this.props.shapeElement.shapeStyle?.borderColor ??
            this.getFillColor()
        );
    }

    public render(): JSX.Element {
        let resizedHeight: number =
            this.props.resizedHeight ?? this.props.height;
        let borderWidth = this.props.shapeElement.shapeStyle?.borderWidth ?? 0;
        let borderRadius = this.props.shapeElement.shapeStyle?.borderRadius;
        let fillColor = this.getFillColor();
        let borderColor = this.getBorderColor();
        return (
            <div
                ref={this.rootRef}
                className="shape-canvas-focus"
                tabIndex={0}
                style={{
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                }}
                onContextMenu={this.props.onContextMenu}
                onClick={this.props.onClick}
            >
                {this.props.shapeElement.shapeType === ShapeType.Arrow && (
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                height: "33%",
                                background: fillColor,
                                borderColor: borderColor,
                                borderTopWidth: borderWidth,
                                borderBottomWidth: borderWidth,
                                borderLeftWidth: borderWidth,
                                borderRightWidth: 0,
                                borderStyle: "solid",
                                borderTopLeftRadius: borderRadius,
                                borderBottomLeftRadius: borderRadius,
                            }}
                        />
                        <div
                            style={{
                                position: "relative",
                                width: "0px",
                                height: "0px",
                                borderRadius: borderRadius,
                                borderTopWidth: Math.ceil(resizedHeight / 2),
                                borderTopStyle: "solid",
                                borderTopColor: "transparent",
                                borderBottomWidth: Math.ceil(resizedHeight / 2),
                                borderBottomStyle: "solid",
                                borderBottomColor: "transparent",
                                borderLeftWidth: Math.ceil(resizedHeight),
                                borderLeftStyle: "solid",
                                borderLeftColor:
                                    borderWidth === 0 ? fillColor : borderColor,
                                // This prevents a thin gap in the
                                // middle of the triangle
                                backgroundClip: "text",
                            }}
                        >
                            {borderWidth !== 0 && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: -Math.ceil(resizedHeight / 2),
                                        left: -Math.ceil(resizedHeight),
                                        width: Math.ceil(resizedHeight),
                                        height: Math.ceil(resizedHeight),
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: borderWidth,
                                            height: "33%",
                                            backgroundColor: borderColor,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRightWidth: borderWidth,
                                                borderRightStyle: "solid",
                                                borderRightColor: fillColor,
                                                height: `calc(100% - ${Number(
                                                    borderWidth
                                                )}px * 2)`,
                                                backgroundColor: fillColor,
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            borderRadius: borderRadius,
                                            borderTopWidth: Math.ceil(
                                                resizedHeight / 2 -
                                                    borderWidth * 1.5
                                            ),
                                            borderTopStyle: "solid",
                                            borderTopColor: "transparent",
                                            borderBottomWidth: Math.ceil(
                                                resizedHeight / 2 -
                                                    borderWidth * 1.5
                                            ),
                                            borderBottomStyle: "solid",
                                            borderBottomColor: "transparent",
                                            borderLeftWidth: Math.ceil(
                                                resizedHeight - borderWidth * 3
                                            ),
                                            borderLeftStyle: "solid",
                                            borderLeftColor: fillColor,
                                            // This prevents a thin gap in the
                                            // middle of the triangle
                                            backgroundClip: "text",
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {this.props.shapeElement.shapeType !== ShapeType.Arrow && (
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            width: "100%",
                            background: fillColor,
                            borderColor: borderColor,
                            borderStyle: "solid",
                            borderWidth: borderWidth,
                            borderRadius: borderRadius,
                            ...this.getShapeStyle(),
                        }}
                    />
                )}
            </div>
        );
    }
}
