import React, { Component } from "react";
import customSelectStyles from "./SelectStyles";
import { createFilter } from "react-select";
import { addKpiStreamApi } from "./UserApi";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { mainStyle } from "./MainStyle";
import OutsideAlerter from "./OutsideAlerter";
import PagesStore from "common/PagesStore";
import { observer } from "mobx-react";
import UserGroupsSelector from "common/UserGroupsSelector";
import Alert from "./Alert";
import PageType from "common/PageType";

const AddKpiPopup = observer(
	class AddKpiPopup extends Component {
		constructor(props) {
			super(props);
			this.state = {
				title: this.props.contents.title || "",
				selectedPage: null,
				errorMessage: undefined,
				selectedUserGroups: [],
			};
			this.inputRef = React.createRef();
		}

		componentDidMount() {
			if (this.inputRef.current) this.inputRef.current.focus();
		}
		componentWillUnmount() {}

		buildInnerView() {
			return (
				<div
					className="center-container"
					style={{
						overflow: "visible",
						height: "100%",
						width: "100%",
					}}
				>
					<div
						className="flex-simple-column"
						style={{
							overflow: "visible",
							marginTop: 20,
							height: "100%",
							width: "90%",
						}}
					>
						<input
							ref={this.inputRef}
							type="text"
							className="like-tab"
							placeholder="Enter title"
							style={{
								width: "100%",
								alignSelf: "center",
							}}
							value={this.state.title}
							onChange={(evt) => {
								let title = evt.target.value;
								this.setState({ title: title });
							}}
						/>
						<span
							style={{
								marginTop: 20,
								fontFamily: "Roboto",
								fontSize: mainStyle.getPropertyValue(
									"--path-header-size"
								),
								color: mainStyle.getPropertyValue(
									"--popup-primary-text-color"
								),
								fontWeight: 300,
							}}
						>
							{"Select Page"}
						</span>
						<div
							style={{
								width: "100%",
								marginTop: 20,
								overflow: "visible",
							}}
						>
							<Select
								filterOption={createFilter({
									ignoreAccents: false,
								})}
								placeholder={"Select page"}
								styles={{
									...customSelectStyles,
									container: (base) => ({
										...base,
										height: "38px",
									}),
								}}
								options={
									PagesStore(PageType.KPIStreams)
										.pageToWriteOptions
								}
								value={this.state.selectedPage}
								onChange={(newValue) => {
									this.setState({
										selectedPage: newValue,
									});
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										text: "white",
										primary25: "var(--selectors-background-hover-color)",
									},
								})}
							/>
						</div>

						<span
							style={{
								marginTop: 20,
								fontFamily: "Roboto",
								fontSize: mainStyle.getPropertyValue(
									"--path-header-size"
								),
								color: mainStyle.getPropertyValue(
									"--popup-primary-text-color"
								),
								fontWeight: 300,
							}}
						>
							{"Select User Groups"}
						</span>
						<UserGroupsSelector
							selectedUserGroups={this.state.selectedUserGroups}
							onChange={(selectedUserGroups) => {
								this.setState({
									selectedUserGroups: selectedUserGroups,
								});
							}}
						/>
						<div
							className="my-row"
							style={{
								marginTop: 20,
								marginBottom: 20,
								alignSelf: "flex-end",
								alignItems: "center",
							}}
						>
							<Button
								type="button"
								disabled={!this.state.selectedPage}
								className="btn btn-sm btn-primary my-primary"
								style={{
									marginLeft: 10,
									width: "112px",
								}}
								onClick={() => {
									this.props.beforeSave();
									let contents = {
										finding: this.props.contents.finding,
										imageSrc: this.props.contents.imageSrc,
										title: this.state.title,
										noInsight: true,
										noRecommendation: true,
									};
									addKpiStreamApi(
										contents,
										this.state.selectedPage.value,
										this.state.selectedUserGroups
									)
										.then((id) => {
											this.props.onClose();
										})
										.catch((error) => {
											this.setState({
												errorMessage: error,
											});
										});
								}}
							>
								SAVE
							</Button>
							<Button
								type="button"
								className="btn btn-sm btn-primary my-primary"
								style={{
									marginLeft: 10,
									width: "112px",
								}}
								onClick={() => {
									this.props.onClose();
								}}
							>
								CLOSE
							</Button>
						</div>
						{this.state.errorMessage && (
							<Alert
								text={"Error: ".concat(this.state.errorMessage)}
								className="alert alert-danger alert-dismissible"
								onClosed={() =>
									this.setState({
										errorMessage: undefined,
									})
								}
							/>
						)}
					</div>
				</div>
			);
		}

		render() {
			let height = 240;
			let width = 450;
			return (
				<OutsideAlerter
					onReject={() => {
						this.props.onClose();
					}}
				>
					<div
						className="dashboard-rect"
						style={{
							overflow: "visible",
							position: "absolute",
							border: "1px solid black",
							top: (window.innerHeight - height) / 2,
							alignItems: "center",
							cursor: "pointer",
							left: (window.innerWidth - width) / 2,
							minHeight: height,
							width: width,
							zIndex: "100000000",
						}}
					>
						{this.buildInnerView()}
					</div>
				</OutsideAlerter>
			);
		}
	}
);
export default AddKpiPopup;