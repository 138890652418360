import {
    observable,
    computed,
    makeObservable,
    action,
    toJS,
    runInAction,
} from "mobx";
import axios from "./ServerConnection";
import { Permission } from "./Permissions";
import { SocketIOInstance } from "common/ServerConnection";
import {
    AbstractPresentationModel,
    PresentationType,
} from "state/models/Presentation";
import { PreviewHeader } from "modules/data_hub_page/MergeSection";
import { formatTimestamp, strftime } from "./utilities/TimeFormatUtils";
import Variables from "./Variables";
import { getRawDataApi } from "./DataApi";
import Tables, { TableOption } from "./Tables";
// import { PreviewHeader } from 'common/DragAndDrop';

export interface DataScope {
    id: number | string;
    name: string;
    permissionType: Permission;
    isMarketPlace?: boolean;
    marketplaceInfo?: {
        keywords: string[];
        description: string;
        logo: string;
    };
    userInfo?: {
        userName: string;
        firstName: string;
        lastName: string;
    };
    owner?: string;
    lastupdated?: string;
    uploaded?: string;
    rawuploaded?: number;
    rawlastupdated?: number;
}

export interface DataScopeOption {
    label: string;
    value: number | string;
    permissionType: Permission;
    owner?: string;
    lastupdated?: string;
    uploaded?: string;
    rawuploaded?: number;
    rawlastupdated?: number;
}

export interface DataScopeOptionWithNull {
    label: string;
    value: number | string | null;
    permissionType: Permission;
    owner?: string;
    lastupdated?: string;
    uploaded?: string;
}

export interface Preview {
    headers: PreviewHeader[] | null;
    body: (string | number | null)[][] | null;
}

class DataScopes {
    @observable public dataScopesState: DataScope[] = [];
    @observable public dataScopesTrash: DataScope[] = [];
    @observable public allKeywords: string[] = [];
    @observable public previewState: Preview = { headers: null, body: null };
    @observable public columnValuesState: string[] = [""];
    @observable public previewRowCount: number = 0;
    public lastFetchCount: number = 0;
    private changeRooms: Set<string | number> = new Set();

    constructor() {
        makeObservable(this);
        this.update();
    }

    public joinChangesRoom(
        dataScopeIds: (number | string)[],
        moduleId?: number | string
    ) {
        dataScopeIds = dataScopeIds.filter(
            (dataScopeId) => !this.changeRooms.has(dataScopeId)
        );
        if (dataScopeIds.length === 0) {
            return;
        }
        SocketIOInstance?.emit("dataset_changes_join", {
            rooms: dataScopeIds,
            module_id: moduleId,
        });
        for (let dataScopeId of dataScopeIds) {
            this.changeRooms.add(dataScopeId);
        }
    }

    @computed public get dataScopes(): DataScope[] {
        return this.dataScopesState;
    }
    @computed public get getpreviewRowCount(): number {
        return this.previewRowCount;
    }

    @computed public get preview(): Preview {
        return toJS(this.previewState);
    }

    @computed public get columnvalues(): string[] {
        return toJS(this.columnValuesState);
    }

    @computed public get dataScopesValues(): (number | string)[] {
        return this.dataScopesState.map((item) => item.id);
    }

    @computed public get dataScopesOptions(): DataScopeOption[] {
        return this.dataScopesState.map((item) => ({
            label: item.name,
            value: item.id,
            permissionType: item.permissionType,
            owner: item?.owner,
            lastupdated: item?.lastupdated,
            uploaded: item?.uploaded,
        }));
    }
    @computed public get dataScopesTrashOptions(): DataScopeOption[] {
        return this.dataScopesTrash.map((item) => ({
            label: item.name,
            value: item.id,
            permissionType: item.permissionType,
            owner: item?.owner,
            lastupdated: item?.lastupdated,
            uploaded: item?.uploaded,
        }));
    }
    @computed public get marketplaceDataScopes(): AbstractPresentationModel[] {
        return this.dataScopesState
            .filter((dataScope) => dataScope.isMarketPlace)
            .map((dataScope) => ({
                id: dataScope.id,
                isCreateNewTemplate: () => {
                    return false;
                },
                isKit: () => {
                    return false;
                },
                isShared: false,
                isTutorial: () => {
                    return false;
                },
                title: dataScope.name,
                description: dataScope.marketplaceInfo?.description,
                keywords: dataScope.marketplaceInfo?.keywords,
                thumbnail: dataScope?.marketplaceInfo?.logo,
                type: PresentationType.MarketPlace,
                owner: dataScope.userInfo!,
                lastUpdated: dataScope.lastupdated ?? "",
                uploaded: dataScope.uploaded ?? "",
            }));
    }

    public async isNameAvailable(name: string): Promise<boolean> {
        return axios
            .post<{
                success: boolean;
                error_msg: string;
                available?: boolean;
            }>("/api/is_data_table_name_available", { name: name })
            .then((response) => {
                if (
                    response.data.success === false ||
                    response.data.available == null
                ) {
                    return Promise.reject(response.data.error_msg);
                } else {
                    return Promise.resolve(response.data.available);
                }
            });
    }

    @action.bound
    private assignDataScopes(dataScopes: DataScope[]) {
        this.dataScopesState = dataScopes;
        this.allKeywords = Array.from(
            new Set(
                this.dataScopesState
                    .filter((dataScope) => dataScope.isMarketPlace)
                    .map(
                        (dataScope) => dataScope.marketplaceInfo!.keywords ?? []
                    )
                    .flat()
            )
        );
        this.joinChangesRoom(
            this.dataScopesState.map((dataScope) => dataScope.id)
        );
    }
    @action.bound
    private assignDataScopesTrash(dataScopes: DataScope[]) {
        this.dataScopesTrash = dataScopes;
        this.allKeywords = Array.from(
            new Set(
                this.dataScopesTrash
                    .filter((dataScope) => dataScope.isMarketPlace)
                    .map(
                        (dataScope) => dataScope.marketplaceInfo!.keywords ?? []
                    )
                    .flat()
            )
        );
        this.joinChangesRoom(
            this.dataScopesTrash.map((dataScope) => dataScope.id)
        );
    }

    public async getDataSetPreview(
        id: string | number,
        limit?: number,
        tableOption?: TableOption
    ) {
        let variables = Variables(id, undefined, undefined, true);
        if (!variables.initialized) await Variables(id).update();
        const table = tableOption ?? Tables(id).tableToOption();

        const data = await getRawDataApi(
            table ?? {
                label: "",
                value: [],
                optimized: false,
                data_table_idx: id,
            },
            // 10,
            limit ? limit : 10,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true
        );
        let allCount = data.count!;
        this.lastFetchCount = allCount;
        let tableContent: (string | number | null)[][] = [];
        let rowCount = data.rowId.length;

        let tableHeader = Object.keys(data.currentLevels);
        const variablesInfo = tableHeader.map((name) =>
            Variables(id).getVariableByName(name)
        );

        for (let i = 0; i < rowCount + 1; ++i) {
            let row: (string | number | null)[] = [];
            for (let [j, varName] of tableHeader.entries()) {
                if (i === rowCount) {
                    if (j === 0) row.push(`+${allCount - rowCount} rows`);
                    else row.push("...");
                    continue;
                }

                if (typeof data.currentLevels[varName] === "undefined")
                    continue;

                row.push(
                    variablesInfo[j]!.type === "datetime" &&
                        typeof data.currentLevels[varName][i] === "number"
                        ? strftime(
                              variablesInfo[j]!.format!,
                              new Date(
                                  (data.currentLevels[varName][i] as number) *
                                      1000
                              )
                          )
                        : data.currentLevels[varName][i] ||
                              (typeof data.currentLevels[varName][i] ===
                              "number"
                                  ? data.currentLevels[varName][i]
                                  : "-")
                );
            }
            tableContent.push(row);
        }

        return {
            headers: variablesInfo.map((variable) => ({
                id: variable!.index!,
                name: variable!.name,
                type: variable!.type,
            })),
            body: tableContent,
            allCount: allCount,
        };
    }

    public async DatasetPreview(
        id: number | string,
        limit?: number,
        tableOption?: TableOption
    ) {
        try {
            // let result = tab === "Reshpae" ? await this.getDataSetPreview(id, ) : await this.getDataSetPreview(id);
            let result = await this.getDataSetPreview(id, limit, tableOption);
            runInAction(() => {
                this.previewState = {
                    headers: result.headers,
                    body: result.body,
                };
                this.previewRowCount = result.allCount;
            });
        } catch (err) {}
    }

    @action.bound
    public async columnValues(
        variableIndex: Number | string,
        datasetId: number
    ) {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                items?: string[];
            }>("/api/e/autocomplete", {
                variable_index: variableIndex,
                starts_with: "",
                data_table_idx: datasetId,
                filter_missing: false,
            })
            .then((response) => {
                if (response.data.success && response.data.items != null) {
                    this.columnValuesState = response.data.items.map((item) =>
                        item?.toString()
                    );
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    public async update(): Promise<void> {
        return axios
            .post<{
                success: boolean;
                error_msg: string;
                data_tables?: {
                    [id: string]: {
                        name: string;
                        permission_type: Permission;
                        is_marketplace: boolean;
                        user: string;
                        marketplace_info?: {
                            logo: string;
                            keywords: string[];
                            description: string;
                        };
                        user_info?: {
                            first_name: string;
                            last_name: string;
                        };
                        owner: string;
                        lastupdated: number;
                        uploaded: number;
                    };
                };
            }>("/api/get_data_table_indices_and_names", null)
            .then((response) => {
                if (
                    response.data.success === false ||
                    response.data.data_tables == null
                ) {
                    console.log(response.data.error_msg);
                } else {
                    let dataTables = response.data.data_tables;
                    let dataScopesState: DataScope[] = Object.keys(
                        dataTables
                    ).map((key: string) => {
                        let id: number | string = parseInt(key);
                        if (isNaN(id)) id = key;
                        return {
                            id: id,
                            name: dataTables[key].name,
                            permissionType: dataTables[key].permission_type,
                            marketplaceInfo: dataTables[key].marketplace_info,
                            isMarketPlace: dataTables[key].is_marketplace,
                            userInfo: {
                                userName: dataTables[key].user,
                                firstName:
                                    dataTables[key].user_info?.first_name ?? "",
                                lastName:
                                    dataTables[key].user_info?.last_name ?? "",
                            },
                            owner: dataTables[key].user,
                            rawlastupdated: dataTables[key]?.lastupdated ?? 0,
                            rawuploaded: dataTables[key]?.uploaded ?? 0,
                            lastupdated: dataTables[key]?.lastupdated
                                ? formatTimestamp(
                                      dataTables[key]?.lastupdated,
                                      "%Y/%m/%d"
                                  )
                                : "",
                            uploaded: dataTables[key]?.uploaded
                                ? formatTimestamp(
                                      dataTables[key]?.uploaded,
                                      "%Y/%m/%d"
                                  )
                                : "",
                        };
                    });
                    this.assignDataScopes(dataScopesState);
                }
            })
            .catch((error) => {
                if (!error.response) return;
                // We should not print the error if the user is unauthorized
                if (
                    error.response.status !== 401 &&
                    error.response.status !== 403
                ) {
                    console.log(error);
                }
            });
    }

    public async getTrashData(): Promise<void> {
        return axios
            .post<{
                success: boolean;
                error_msg: string;
                data_tables?: {
                    [id: string]: {
                        name: string;
                        permission_type: Permission;
                        is_marketplace: boolean;
                        user: string;
                        marketplace_info?: {
                            logo: string;
                            keywords: string[];
                            description: string;
                        };
                        user_info?: {
                            first_name: string;
                            last_name: string;
                        };
                        owner: string;
                        lastupdated: number;
                        uploaded: number;
                    };
                };
            }>("/api/get_in_trash_data_table_indices_and_names", null)
            .then((response) => {
                if (
                    response.data.success === false ||
                    response.data.data_tables == null
                ) {
                    console.log(response.data.error_msg);
                } else {
                    let dataTables = response.data.data_tables;
                    let dataScopesState: DataScope[] = Object.keys(
                        dataTables
                    ).map((key: string) => {
                        let id: number | string = parseInt(key);
                        if (isNaN(id)) id = key;
                        return {
                            id: id,
                            name: dataTables[key].name,
                            permissionType: dataTables[key].permission_type,
                            marketplaceInfo: dataTables[key].marketplace_info,
                            isMarketPlace: dataTables[key].is_marketplace,
                            userInfo: {
                                userName: dataTables[key].user,
                                firstName:
                                    dataTables[key].user_info?.first_name ?? "",
                                lastName:
                                    dataTables[key].user_info?.last_name ?? "",
                            },
                            owner: dataTables[key].user,
                            rawlastupdated: dataTables[key]?.lastupdated ?? 0,
                            rawuploaded: dataTables[key]?.uploaded ?? 0,
                            lastupdated: dataTables[key]?.lastupdated
                                ? formatTimestamp(
                                      dataTables[key]?.lastupdated,
                                      "%Y/%m/%d"
                                  )
                                : "",
                            uploaded: dataTables[key]?.uploaded
                                ? formatTimestamp(
                                      dataTables[key]?.uploaded,
                                      "%Y/%m/%d"
                                  )
                                : "",
                        };
                    });
                    this.assignDataScopesTrash(dataScopesState);
                }
            })
            .catch((error) => {
                if (!error.response) return;
                // We should not print the error if the user is unauthorized
                if (
                    error.response.status !== 401 &&
                    error.response.status !== 403
                ) {
                    console.log(error);
                }
            });
    }
}

export default new DataScopes();
