import React from "react";
import { Button } from "react-bootstrap";
import { mainStyle } from "common/MainStyle";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import styles from "./SelectConfig.module.css";
import { SurveyQuestion } from "common/Canvas";

interface SelectConfigProps {
    question: SurveyQuestion;
    onChange: (questions: SurveyQuestion) => void;
}

const SelectConfig: React.FC<SelectConfigProps> = ({ question, onChange }) => {
    const _toFunction = <T,>(
        value: T | ((rowIndex: number) => T) | null | undefined,
        defaultValue: T
    ): ((rowIndex: number) => T) => {
        if (value == null) {
            return () => defaultValue;
        } else if (typeof value !== "function") {
            return () => value;
        } else {
            return value as (rowIndex: number) => T;
        }
    };

    const rowStyle = _toFunction<React.CSSProperties>(
        ConditionSelectorStyles.rowStyle,
        {}
    );
    const buttonsStyle = _toFunction<React.CSSProperties>(
        ConditionSelectorStyles.buttonsStyle,
        {}
    );

    return (
        <>
            {question.options.map((option, index) => {
                return (
                    <div
                        className={"my-row"}
                        key="myKey"
                        style={{
                            marginTop: index > 0 ? 5 : 0,
                            backgroundColor: mainStyle.getPropertyValue(
                                "--filters-background-color"
                            ),
                            ...rowStyle(index),
                        }}
                    >
                        <input
                            value={option.value}
                            className={styles.input}
                            placeholder="Write response"
                            onChange={(e) => {
                                let newQuestion = {
                                    ...question,
                                    options: [...question.options],
                                };
                                newQuestion.options[index] = {
                                    ...newQuestion.options[index],
                                    value: e.target.value,
                                };
                                onChange(newQuestion);
                            }}
                        />
                        {
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 5, marginTop: 3 }}
                            >
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        backgroundColor: mainStyle.getPropertyValue(
                                            "--filters-background-color"
                                        ),
                                        color: mainStyle.getPropertyValue(
                                            "--filters-text-color"
                                        ),
                                        width: "19px",
                                        height: "19px",
                                        ...buttonsStyle(index),
                                    }}
                                    onClick={() => {
                                        let newQuestion = {
                                            ...question,
                                            options: [
                                                ...question.options,
                                                {
                                                    value: "",
                                                },
                                            ],
                                        };
                                        onChange(newQuestion);
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        backgroundColor: mainStyle.getPropertyValue(
                                            "--filters-background-color"
                                        ),
                                        color: mainStyle.getPropertyValue(
                                            "--filters-text-color"
                                        ),
                                        width: "19px",
                                        height: "19px",
                                        ...buttonsStyle(index),
                                    }}
                                    onClick={() => {
                                        if (question.options.length > 1) {
                                            let newQuestion = {
                                                ...question,
                                                options: [...question.options],
                                            };
                                            newQuestion.options.splice(
                                                index,
                                                1
                                            );
                                            onChange(newQuestion);
                                        }
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        }
                    </div>
                );
            })}
        </>
    );
};

export default SelectConfig;
