import React, { Component } from "react";
import PinItem from "./PinItem";
import { observer } from "mobx-react";
import { sheetRibbonWidth } from "../../Constants";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import Canvases from "common/Canvases";
import PinStore from "../../comments/PinStore";
import styles from "./CommentRibbon.module.css";

interface Props {
	onItemClick: (pageId: number, canvasId: number, pinId: number) => void;
}

@observer
export default class CommentRibbon extends Component<Props> {
	render() {
		return (
			<div
				className={styles.container}
				style={{
					width: sheetRibbonWidth,
				}}
			>
				{PagesStore(PageType.Canvases).pages.map((page) => {
					let canvases = Canvases(page.id).canvases;
					return canvases.map((canvas) => {
						let pins = PinStore(canvas.id).sortedPins();
						if (pins.length === 0) return null;

						return (
							<React.Fragment key={canvas.id}>
								<span className={styles.slideNameStyle}>
									{canvas.title}
								</span>
								{pins.map((pin) => (
									<PinItem
										key={pin.id}
										onClick={() => {
											this.props.onItemClick(
												page.id,
												canvas.id,
												pin.id
											);
										}}
										moduleId={page.module_id!}
										canvasId={canvas.id}
										pin={pin}
									/>
								))}
							</React.Fragment>
						);
					});
				})}
			</div>
		);
	}
}