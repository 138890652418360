import React from "react";
import { WordCloudFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./WordCloudSection.module.css";
import cx from "classnames";
import EditInput from "../EditInput";
import { Button } from "react-bootstrap";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import ReactTooltip from "react-tooltip";
import { toInteger } from "lodash";

interface Props {
    finding: WordCloudFinding;
    onChange: (finding: WordCloudFinding, updateData?: boolean) => void;
}

export const LISTING_LIMIT = 10;

export default function WordCloudSection(props: Props) {
    let data: {
        text: string;
        value: number;
    }[] = props.finding.content.data;
    let currentEditVariableIndex: number | undefined = undefined;
    let datasetIsConnected = props.finding.config.dataScope != null;

    const [excludedWords, setExcludedWords] = React.useState<string>(
        props.finding.config.excludedWords ?? ""
    );
    const [minimumWordCount, setMinimumWordCount] = React.useState<number>(
        props.finding.config.minimumWordCount ?? 1
    );

    const _getWordsList = () => {
        let topWords = [];
        let sortedWords = data.sort((a, b) => (a.value > b.value)? -1 : 1);
        for(let index = 0; index < LISTING_LIMIT; index++) {
            let item = sortedWords[index];
            if(item) {
                topWords.push(
                    <React.Fragment key={index}>
                        <EditInput
                            showDeleteButton={true}
                            disabled={false}
                            optionalClassName={
                                index === currentEditVariableIndex
                                    ? commonStyles.highlightVariable
                                    : undefined
                            }
                            value={item.text}
                            onChange={(value) => {
                                let finding = {
                                    ...props.finding!,
                                };
                                finding.content.data[index].text =
                                    value as string;

                                props.onChange(finding);
                            }}
                            onDelete={() => {
                                let finding = {
                                    ...props.finding!,
                                };
                                finding.content.data.splice(index, 1);

                                props.onChange(finding);
                            }}
                        />
                        <EditInput
                            showDeleteButton={false}
                            optionalClassName={
                                index === currentEditVariableIndex
                                    ? commonStyles.highlightValue
                                    : undefined
                            }
                            onChange={(value) => {
                                let finding = {
                                    ...props.finding!,
                                };
                                finding.content.data[index].value =
                                    Number(value);
                                props.onChange(finding);
                            }}
                            value={item.value}
                        />
                    </React.Fragment>
                )
            }
        }
        return topWords
    }

    return (
        <div>
            {datasetIsConnected && (
                <div
                    className={commonStyles.optionContainer}
                    style={{ marginTop: "10px" }}
                >
                    <div className="pretty p-default" contentEditable={false}>
                        <input
                            type="checkbox"
                            checked={props.finding.config?.wordLevel ?? true}
                            onChange={() => {
                                let newFinding = { ...props.finding };
                                newFinding.config.wordLevel = !(
                                    newFinding.config?.wordLevel ?? true
                                );
                                props.onChange(newFinding, true);
                            }}
                        />
                        <div className="state p-primary">
                            <label className={commonStyles.optionCheckboxName}>
                                Word level
                            </label>
                        </div>
                    </div>
                </div>
            )}
            {datasetIsConnected && (
                <div
                    className={commonStyles.optionContainer}
                    style={{ 
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div
                        className="pretty p-default"
                        contentEditable={false}
                    >
                        <input
                            type="checkbox"
                            checked={
                                props.finding.config?.removeStopWords ?? false
                            }
                            onChange={() => {
                                let newFinding = { ...props.finding };
                                newFinding.config.removeStopWords = !(
                                    newFinding.config?.removeStopWords ?? false
                                );
                                props.onChange(newFinding, true);
                            }}
                        />
                        <div className="state p-primary">
                            <label className={commonStyles.optionCheckboxName}>
                                Remove stop words
                            </label>
                        </div>
                    </div>
                    <div style={{
                        paddingBottom:"3px"
                    }}>
                        <img
                            data-tip={
                                "Remove common English words such as: the, a, an, because..."
                            }
                            data-for="stopWordsDisclaimer"
                            src="/dist/img/data-exploration/question.svg"
                            alt=""
                            style={{
                                height:21,
                                marginLeft: 0,
                            }}
                        />
                        <ReactTooltip
                            effect="solid"
                            id="stopWordsDisclaimer"
                            multiline={true}
                        />
                    </div>
                </div>
                
            )}
            <div className={styles.dataContainer} style={{ marginTop: "10px" }}>
                <div className={commonStyles.header}>Word</div>
                <div className={commonStyles.header}>Weight</div>
                {
                    _getWordsList()
                }
            </div>
            <Button
                style={{ marginTop: 10 }}
                className={cx(
                    "btn btn-sm btn-primary my-primary",
                    commonStyles.addItemButton
                )}
                onClick={() => {
                    let finding = { ...props.finding! };
                    if(finding.content.data?.length < LISTING_LIMIT) {
                        finding.content.data.push({
                            text: "word",
                            value: 0,
                        });
                        props.onChange(finding);
                    }
                }}
            >
                {"\uFF0B Add Item"}
            </Button>
            <div style={{
                marginTop: '30px'
            }}>
                <span
                    className={styles.thinOptionName}
                    style={{ marginTop: 30 }}
                >
                    Excluded words
                </span>
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                        marginTop: "5px",
                    }}
                >
                    <input
                        className={styles.input}
                        placeholder="Example: Eisengard, is, awesome"
                        onChange={(e) => {
                            setExcludedWords(e.target.value)
                        }}
                        onBlur={() => {
                            let newFinding = { ...props.finding };
                            newFinding.config.excludedWords = excludedWords;
                            props.onChange(newFinding, true);
                        }}
                        value={excludedWords}
                    />
                </div>
            </div>
            <div style={{
                marginTop: '30px'
            }}>
                <span
                    className={styles.thinOptionName}
                    style={{ marginTop: 30 }}
                >
                    Minimum word count
                </span>
            
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                        marginTop: "5px",
                    }}
                >
                    <input
                        className={styles.input}
                        onChange={(e) => {
                            setMinimumWordCount(toInteger(e.target.value));
                        }}
                        onBlur={() => {
                            let newFinding = { ...props.finding };
                            newFinding.config.minimumWordCount = minimumWordCount ?? 1;
                            props.onChange(newFinding, true);
                        }}
                        value={minimumWordCount}
                    />
                </div>
            </div>
        </div>
    );
}
