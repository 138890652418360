import React from "react";
import Select from "react-select";

import { FlagType, MapFinding } from "common/Finding";
import commonStyles from "../../StylingSection.module.css";
import styles from "../MapSection.module.css";
import ColorPicker from "common/ColorPicker";
import MapVariablesSelectorView from "modules/canvas_page/question_views/RevampedMapVariablesSelectorView";
import { getCustomSelectStyleLight } from "common/SelectStyles";


interface Props {
    mapFinding: MapFinding;
    onChange: (config: MapFinding["config"], update?: boolean) => void;
    currentModuleId?: number;
}

function FlagsConfig({ mapFinding, onChange, currentModuleId }: Props) {
    let variables = mapFinding.config?.flags?.flagData ?? [null];
    if (!Array.isArray(variables)) variables = [null];
    const flagTypeOptions = [
        {
            label: "Pins",
            value: FlagType.Pins,
        },
        {
            label: "Classic Flag",
            value: FlagType.Classic,
        },
        {
            label: "Marker Flag",
            value: FlagType.Marker,
        },
    ]
    return (
        <div className={styles.colorMenu}>
            <div
                className="flex-simple-column"
                style={{
                    marginTop: "15px",
                }}
            >
                <span className={commonStyles.switchLabel} style={{ margin: "0 0 5px 0" }}>
                    Type
                </span>
                <Select
                    placeholder=""
                    styles={{
                        ...getCustomSelectStyleLight(
                            14,
                            false
                        ),
                        container: (provided) => ({
                            ...provided,
                            minWidth: 60,
                        }),
                        control: (provided) => ({
                            ...provided,
                            minHeight: 25,
                        }),
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                        }),
                    }}
                    options={flagTypeOptions}
                    onChange={(newValue) => {
                        onChange(
                            {
                                ...mapFinding.config,
                                flags: {
                                    ...(mapFinding.config?.flags ?? {}),
                                    type: newValue,
                                }
                            },
                            true
                        );
                    }}
                    value={
                        mapFinding.config?.flags?.type ?? flagTypeOptions[0]
                    }
                />
            </div>
            <div
                className="flex-simple-column"
                style={{
                    marginTop: "15px",
                }}
            >
                <span className={commonStyles.switchLabel} style={{ margin: "0 0 5px 0" }}>
                    Flags data
                </span>
                <MapVariablesSelectorView
                    dataScope={mapFinding.config.dataScope!}
                    variables={variables}
                    onChange={(newOptions) => {
                        onChange(
                            {
                                ...mapFinding.config,
                                flags: {
                                    ...(mapFinding.config?.flags ?? {}),
                                    flagData: newOptions,
                                }
                            },
                            true
                        );
                    }}
                    currentModuleId={currentModuleId}
                />
            </div>
            <div className={styles.stylesMenuOption}>
                <span className={commonStyles.switchLabel}>Flag color</span>
                <ColorPicker
                    enableAlpha
                    value={
                        mapFinding.config.flags?.flagColor ?? "#000"
                    }
                    onChange={(color) => {
                        onChange(
                            {
                                ...mapFinding.config,
                                flags: {
                                    ...(mapFinding.config?.flags ?? {}),
                                    flagColor: color,
                                }
                            },
                            true
                        );
                    }}
                    style={{
                        marginLeft: "8px",
                        borderRadius: "4px",
                        width: "32px",
                        height: "32px",
                    }}
                    inPopup
                />
            </div>
            <div className={styles.stylesMenuOption}>
                <span className={commonStyles.switchLabel}>Text color</span>
                <ColorPicker
                    enableAlpha
                    value={
                        mapFinding.config.flags?.textColor ?? "#000"
                    }
                    onChange={(color) => {
                        onChange(
                            {
                                ...mapFinding.config,
                                flags: {
                                    ...(mapFinding.config?.flags ?? {}),
                                    textColor: color,
                                }
                            },
                            true
                        );
                    }}
                    style={{
                        marginLeft: "8px",
                        borderRadius: "4px",
                        width: "32px",
                        height: "32px",
                    }}
                    inPopup
                />
            </div>
            <div className={styles.stylesMenuOption}>
                <span className={commonStyles.switchLabel}>Text background color</span>
                <ColorPicker
                    enableAlpha
                    value={
                        mapFinding.config.flags?.textBackgroundColor ?? "#fff"
                    }
                    onChange={(color) => {
                        onChange(
                            {
                                ...mapFinding.config,
                                flags: {
                                    ...(mapFinding.config?.flags ?? {}),
                                    textBackgroundColor: color,
                                }
                            },
                            true
                        );
                    }}
                    style={{
                        marginLeft: "8px",
                        borderRadius: "4px",
                        width: "32px",
                        height: "32px",
                    }}
                    inPopup
                />
            </div>
        </div>
    );
}

export default FlagsConfig;
