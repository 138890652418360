import React from "react";
import styles from "./StylingSection.module.css";

function TextInput(props: {
    value: string;
    containerStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    onChange: (value: string) => void;
}) {
    let [value, setValue] = React.useState(props.value);

    return (
        <div className="my-row" style={props.containerStyle}>
            <input
                style={props.style}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (evt.key === "Enter") {
                        props.onChange(value);
                    }
                }}
                className={styles.textInput}
                value={value}
                onChange={(evt) => {
                    let newValue = evt.target.value;
                    setValue(newValue);
                }}
                onBlur={() => {
                    props.onChange(value);
                }}
            />
        </div>
    );
}

export default TextInput;
