import React from "react";
import OutcomesBlockV3 from "common/graphics/OutcomesBlockV3";
import LeversChartsComponent from "common/graphics/LeversChartsComponent";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";

function MainComponent(props) {
	let finding = props.finding;

	return (
		<div
			className="flex-simple-column"
			style={{
				width: "100%",
				height: mobileBreakpoint()
					? finding.content.clust.length * 300 + 50
					: "100%",
			}}
		>
			<div style={{ width: "100%", height: "100%" }}>
				<LeversChartsComponent
					barSize={finding.config.barSize}
					leftBarKey="current"
					rightBarKey="projected"
					clustNames={finding.content.clustNames}
					clustIndex={finding.content.clustIndex}
					clust={finding.content.clust}
				/>
			</div>
			{finding.content.outcome && (
				<div style={{ width: "100%" }}>
					<OutcomesBlockV3
						item={finding.content.outcome}
						leftTitle="Current"
						rightTitle="Projected"
						leftKey="current"
						rightKey="projected"
					/>
				</div>
			)}
		</div>
	);
}

export { MainComponent };