import React, { Component } from "react";
import styles from "./LinkOptionsPopup.module.css";
import Popup from "reactjs-popup";
import { observer } from "mobx-react";
import {
    isSubmitButton,
    isBox,
    isTextBox,
    CanvasElement,
    CanvasTextBox,
    CanvasSubmitButton,
    SharedLinkScrollOptions,
} from "common/Canvas";
import LinksSelector from "../../LinksSelector";
import CanvasTreeStore from "../../CanvasTreeStore";
import { mainStyle } from "common/MainStyle";
import Switch from "react-switch";
import { Button } from "react-bootstrap";
import LastNodeOptionsStorage from "../../LastNodeOptionsStorage";
import OutsideAlerter from "common/OutsideAlerter";
import _ from "lodash";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";

interface Props {
    asModalPopup?: boolean;
    x: number;
    explicitExternal?: boolean;
    explicitExternalLink?: string;
    explicitInPopup?: boolean;
    node: CanvasTextBox | CanvasElement | CanvasSubmitButton;
    currentModuleId: number | undefined;
    canvasTreeStore: CanvasTreeStore;
    onNodeSave?: (
        node: CanvasTextBox | CanvasElement | CanvasSubmitButton,
        explicitExternal?: boolean,
        explicitExternalLink?: string,
        explicitInPopup?: boolean
    ) => void;
    onClose: () => void;
}
interface State {
    linksChanged: boolean;
    explicitExternalLink?: string;
    explicitInPopup?: boolean;
    node: CanvasTextBox | CanvasElement | CanvasSubmitButton;
}

const firstColumnWidth = 60;

@observer
export default class LinkOptionsPopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            linksChanged: false,
            node: _.cloneDeep(this.props.node),
            explicitInPopup: this.props.explicitInPopup,
            explicitExternalLink: this.props.explicitExternalLink,
        };
    }
    private commitChanges(): boolean {
        if (isSubmitButton(this.state.node)) {
            LastNodeOptionsStorage.setLastTextLink(
                this.state.node.isTextLink ?? false
            );
        }
        if (this.props.onNodeSave != null) {
            this.props.onNodeSave(
                this.state.node,
                this.props.explicitExternal,
                this.state.explicitExternalLink,
                this.state.explicitInPopup
            );
        } else
            this.props.canvasTreeStore.updateNodeAction<
                CanvasTextBox | CanvasElement | CanvasSubmitButton,
                keyof (CanvasTextBox | CanvasElement | CanvasSubmitButton)
            >(this.state.node.id, this.state.node);

        return true;
    }
    buildInnerView() {
        return (
            <div
                className={styles.container}
                style={{
                    left: this.props.x,
                }}
            >
                {isSubmitButton(this.state.node) && (
                    <div
                        className="my-row"
                        style={{
                            alignItems: "center",
                            marginTop: "10px",
                        }}
                    >
                        <span
                            style={{
                                fontFamily: "Arial",
                                fontSize: "17px",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                width: "80px",
                            }}
                        >
                            {"Button"}
                        </span>
                        <div style={{ marginLeft: 6 }}>
                            <Switch
                                onChange={() => {
                                    this.setState((state) => ({
                                        node: {
                                            ...state.node,
                                            fontColor: !(
                                                (state.node as CanvasSubmitButton)
                                                    .isTextLink ?? false
                                            )
                                                ? "#39F"
                                                : null,
                                            fillColor: !(
                                                (state.node as CanvasSubmitButton)
                                                    .isTextLink ?? false
                                            )
                                                ? "transparent"
                                                : null,
                                            isTextLink: !(
                                                (state.node as CanvasSubmitButton)
                                                    .isTextLink ?? false
                                            ),
                                        },
                                    }));
                                }}
                                checked={this.state.node.isTextLink ?? false}
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#1F8EFA"
                                onHandleColor="#1F8EFA"
                            />
                            <span
                                style={{
                                    marginLeft: "20px",
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: "80px",
                                }}
                            >
                                {"Text"}
                            </span>
                        </div>
                    </div>
                )}
                {(isBox(this.state.node) || isSubmitButton(this.state.node)) &&
                    !this.state.node.external && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "18px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: firstColumnWidth,
                                }}
                            >
                                {"Popup"}
                            </span>
                            <LinksSelector
                                linkPopups
                                maxLinksCount={3}
                                currentModuleId={this.props.currentModuleId}
                                canWrite={true}
                                value={this.state.node}
                                onChange={(changes) => {
                                    this.setState((state) => ({
                                        node: {
                                            ...state.node,
                                            ...changes,
                                        },
                                        linksChanged: true,
                                    }));
                                }}
                                style={{
                                    flex: 1,
                                }}
                            />
                        </div>
                    )}
                {(((isBox(this.state.node) ||
                    isSubmitButton(this.state.node)) &&
                    this.state.node.external) ||
                    (isTextBox(this.state.node) &&
                        this.props.explicitExternal)) && (
                    <div
                        className="my-row"
                        style={{
                            alignItems: "center",
                            marginTop: "18px",
                        }}
                    >
                        <span
                            style={{
                                fontFamily: "Arial",
                                fontSize: "17px",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                width: firstColumnWidth,
                            }}
                        >
                            {"Link"}
                        </span>
                        <input
                            type="text"
                            className="like-select"
                            placeholder="Link"
                            style={{
                                flex: 1,
                                marginLeft: 12,
                                alignSelf: "center",
                            }}
                            value={
                                !isTextBox(this.state.node)
                                    ? this.state.node.externalLink
                                    : this.state.explicitExternalLink
                            }
                            onChange={(evt) => {
                                const value: string = evt.target.value;
                                if (isTextBox(this.state.node)) {
                                    this.setState({
                                        explicitExternalLink: value,
                                    });
                                } else
                                    this.setState((state) => ({
                                        node: {
                                            ...state.node,
                                            externalLink: value,
                                        },
                                    }));
                            }}
                        />
                    </div>
                )}
                {!this.props.canvasTreeStore.isTemplate &&
                    isTextBox(this.state.node) &&
                    !this.props.explicitExternal && (
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                            }}
                        >
                            <Switch
                                onChange={() => {
                                    this.setState((state) => ({
                                        explicitInPopup: !(
                                            state.explicitInPopup ?? false
                                        ),
                                    }));
                                }}
                                checked={this.state.explicitInPopup ?? false}
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                            <span
                                style={{
                                    marginLeft: "20px",
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: "80px",
                                }}
                            >
                                {"In Popup"}
                            </span>
                        </div>
                    )}
                {!this.props.canvasTreeStore.isTemplate &&
                    (((isBox(this.state.node) ||
                        isSubmitButton(this.state.node)) &&
                        !this.state.node.external) ||
                        (isTextBox(this.state.node) &&
                            !this.props.explicitExternal)) && (
                        <>
                            {isSubmitButton(this.state.node) && (
                                <div
                                    className="my-row"
                                    style={{ marginTop: "18px" }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "Arial",
                                            fontSize: "17px",
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                            width: firstColumnWidth,
                                        }}
                                    >
                                        {"Slide transition"}
                                    </span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                flex: 1,
                                                marginLeft: "26px",
                                                height: "38px",
                                            }),
                                            placeholder: (base) => ({
                                                ...base,
                                                color: "#869AAC",
                                            }),
                                        }}
                                        options={SharedLinkScrollOptions}
                                        value={
                                            this.state.node
                                                .sharedLinkScrollOption == null
                                                ? SharedLinkScrollOptions[0]
                                                : SharedLinkScrollOptions.find(
                                                      (option) =>
                                                          option.value ===
                                                          (this.state
                                                              .node as CanvasSubmitButton)
                                                              .sharedLinkScrollOption
                                                  )
                                        }
                                        onChange={(newValue) => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    sharedLinkScrollOption: (newValue as typeof SharedLinkScrollOptions[number])
                                                        .value,
                                                },
                                                linksChanged: true,
                                            }));
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            )}
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    marginTop: "18px",
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        width: firstColumnWidth,
                                    }}
                                >
                                    {"Links"}
                                </span>
                                <LinksSelector
                                    maxLinksCount={
                                        isTextBox(this.state.node) ||
                                        isSubmitButton(this.state.node)
                                            ? 1
                                            : 3
                                    }
                                    currentModuleId={this.props.currentModuleId}
                                    canWrite={true}
                                    value={this.state.node}
                                    onChange={(changes) => {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                ...changes,
                                            },
                                            linksChanged: true,
                                        }));
                                    }}
                                    style={{
                                        flex: 1,
                                    }}
                                />
                            </div>
                        </>
                    )}
                <div
                    className="my-row"
                    style={{
                        marginTop: 20,
                        alignSelf: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.commitChanges();
                            this.props.onClose();
                        }}
                    >
                        DONE
                    </Button>
                </div>
            </div>
        );
    }
    render() {
        let innerView = this.buildInnerView();
        let asModalPopup = this.props.asModalPopup ?? true;

        return asModalPopup ? (
            <Popup
                overlayStyle={{ zIndex: 1000 }}
                arrow={true}
                contentStyle={{ margin: 0 }}
                open={true}
                closeOnDocumentClick={true}
                onClose={this.props.onClose}
            >
                {innerView}
            </Popup>
        ) : (
            <OutsideAlerter onReject={this.props.onClose}>
                {innerView}
            </OutsideAlerter>
        );
    }
}
