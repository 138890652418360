import React from "react";
import { lightThemeStyle } from "common/LightThemeStyle";
import { BaseAPIComponentProps } from "../APIComponentProps";

export default function BaseAPIComponent(props: BaseAPIComponentProps) {
	let contentStyle: React.CSSProperties = {
		fontFamily: "Roboto",
		fontSize: 16,
		fontWeight: 400,
		color: lightThemeStyle.primaryTextColor,
	};
	return (
		<div className="flex-simple-column">
			<div className="my-row">
				<img
					style={{
						maxWidth: "150px",
						maxHeight: "150px",
						objectFit: "contain"
					}}
					src={props.endpoint.api_icon}
					alt=""
				></img>
				<div
					className="flex-simple-column"
					style={{
						marginLeft: 45,
						height: "100%",
					}}
				>
					<div className="my-row">
						<span className="APILabel" style={{ width: 50 }}>Name</span>
						<span style={{ ...contentStyle, marginLeft: 45 }}>
							{props.endpoint.name}
						</span>
					</div>
					{/*
					<div style={{ flex: 0.5 }}></div>
					<div className="my-row">
						<span style={{ ...titleStyle, width: 50 }}>Status</span>
						<span style={{ ...contentStyle, marginLeft: 45 }}>
							Not connected
						</span>
					</div>
					*/}
					<div className="flex-simple-column" style={{ marginTop: "23px" }}>
						<span className="APILabel">Description</span>
						<span style={{ ...contentStyle, marginTop: 4 }}>
							{props.endpoint.description}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
