import axios from "common/ServerConnection";

export function stripeAccountConnect(): void {
    axios
        .get<{
            success: string;
            error_msg: string;
            redirect_url?: string;
        }>("/_api/stripe_account_connect")
        .then((response) => {
            if (response.data.success) {
                window.location.href = response.data.redirect_url!;
            } else {
                console.log(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(String(error));
        });
}

export async function stripeAccountConfirm(): Promise<boolean> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            confirmed?: boolean;
        }>("/api/stripe_account_confirm")
        .then((response) => {
            if (response.data.success) {
                return response.data.confirmed ?? false;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function stripeAccountCheckConnected(): Promise<boolean> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            connected?: boolean;
        }>("/api/stripe_account_check_connected")
        .then((response) => {
            if (response.data.success) {
                return response.data.connected ?? false;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}
