import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import Dropzone from "react-dropzone";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import TextareaAutosize from "react-autosize-textarea";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SpecialTabId, getSpecialTabIdIndex } from "common/Module";
import { mainStyle } from "common/MainStyle";
import Alert from "common/Alert";
import cx from "classnames";
import {
    addOrEditAppApi,
    getAppApi,
    addSelfSignupLinkToAppApi,
    removeSelfSignupLinkFromAppApi,
    getModuleUserDataSetsApi,
    AppGroupExtendedPermission,
    SelfSignupInfo,
} from "common/ModulesApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import DataSetsSelector, {
    DataSet,
    AccessType,
} from "common/canvas/DataSetsSelector";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import ProductParametersInput, {
    PaymentType,
} from "common/mystripe_components/ProductParametersInput";
import CurrentModulesStore from "common/CurrentModulesStore";
import linkIdToUrl, {
    selfSignupLinkIdToUrl,
    shortLinkToUrl,
} from "common/utilities/linkIdToUrl";
import { addEmailInvitation } from "common/EmailInvitation";
import DataScopes from "common/DataScopes";
import AppUserInviter from "common/canvas/AppUserInviter";
import UserGroupsStore, { UserGroupOption } from "common/UserGroupsStore";
import LinkCustomizePopup from "./LinkCustomizePopup";
import { Permission } from "common/Permissions";
import styles from "./AppCreator.module.css";
import NumericOption from "common/NumericOption";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { getCategoriesApi } from "common/CategoriesApi";

interface Props {
    moduleId: number;
    onCancel: () => void;
    rootRef?: React.Ref<HTMLElement>;
}

enum UploadStatus {
    NotStarted = 1,
    Started = 2,
    Success = 3,
    Error = 4,
}

interface State {
    hoverKeywordIndex: number | undefined;
    moduleTitle: string;
    moduleThumbnail: string;
    pwaTitle: string;
    pwaLogo: string | undefined;
    moduleDescription: string;
    moduleTitleIsEditing: boolean;
    moduleDescriptionIsEditing: boolean;
    moduleKeywords: string[];
    newKeyword: string;
    message: string;
    uploadStatus: UploadStatus;
    inviteStatus: PopupStatus | undefined;
    inviteMessage: string;
    selectedDataSet: DataSet | null;
    selectedDataSets: DataSet[];
    selectedNewGroup: UserGroupOption | null;
    selectedUserGroups: AppGroupExtendedPermission[];
    usePermissions: boolean;
    appExists: boolean;
    shareLink: string | null;
    linkId: string | null;
    shortLink: string | null;
    newShortLink: string;

    // This is needed to add in a Paywall
    addPayWall: boolean;
    price: string;
    name: string;
    client: string;
    logo: string;
    paymentType: PaymentType;
    editableByCollaborators: boolean;
    newUserInput: string;
    // Put the template into 'Create New' tab -- which is featured on the front page
    intoCreateNew: boolean;
    selfSignupLinkId: string | null;
    selfSignupInfo: SelfSignupInfo | null;
    showSelfSignupInfoPopup: boolean;
    dataSetOptions: DataSet[];
    allowAccessToAllDataSets: boolean;

    categories: NumericOption[];
    categoryId?: number;
}

@observer
class AppCreator extends Component<Props, State> {
    private performance: Date | null;
    private currentModuleReaction: any = null;
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedNewGroup: null,
            selectedUserGroups: [],
            usePermissions: false,
            linkId: null,
            shortLink: null,
            newShortLink: "",
            intoCreateNew: false,
            hoverKeywordIndex: undefined,
            uploadStatus: UploadStatus.NotStarted,
            message: "",
            moduleTitle: "",
            moduleThumbnail: "",
            pwaTitle: "",
            pwaLogo: undefined,
            moduleDescription: "",
            moduleTitleIsEditing: false,
            moduleDescriptionIsEditing: false,
            newKeyword: "",
            moduleKeywords: [],
            selectedDataSet: null,
            selectedDataSets: [],
            addPayWall: false,
            price: "",
            name: "",
            paymentType: "one_time",
            client: "",
            logo: "",
            appExists: false,
            shareLink: null,
            newUserInput: "",
            editableByCollaborators: true,
            inviteStatus: undefined,
            inviteMessage: "",
            selfSignupLinkId: null,
            selfSignupInfo: null,
            showSelfSignupInfoPopup: false,
            dataSetOptions: [],
            allowAccessToAllDataSets: true,
            categories: [],
        };
        this.performance = null;

        this.buildInnerView = this.buildInnerView.bind(this);
        this.inviteUser = this.inviteUser.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deleteUserGroup = this.deleteUserGroup.bind(this);
    }
    private async inviteUser(): Promise<void> {
        if (this.state.newUserInput && this.state.linkId != null) {
            try {
                await addEmailInvitation(
                    this.state.linkId,
                    undefined,
                    undefined,
                    this.state.newUserInput
                );
                this.setState((_state) => ({
                    newUserInput: "",
                    inviteMessage: `Invite sent`,
                    inviteStatus: PopupStatus.Success,
                }));
            } catch (error) {
                this.setState({
                    inviteStatus: PopupStatus.Error,
                    inviteMessage: String(error),
                });
                return;
            }
        }
    }
    addUser() {
        this.setState((state) => {
            if (state.selectedNewGroup == null) return null;
            let selectedUserGroups = state.selectedUserGroups;
            selectedUserGroups.push({
                group_name: state.selectedNewGroup.value,
                group_id: state.selectedNewGroup.id,
                can_edit: true,
                personal: state.selectedNewGroup.personal ?? false,
                user_info: state.selectedNewGroup.userInfo,
            });
            return {
                selectedNewGroup: null,
                selectedUserGroups: selectedUserGroups,
            };
        });
    }
    deleteUserGroup(groupId: number) {
        this.setState((state) => {
            let selectedUserGroups = state.selectedUserGroups.filter(
                (group) => group.group_id !== groupId
            );
            return {
                selectedUserGroups: selectedUserGroups,
            };
        });
    }
    private async getCategories() {
        try {
            let categories = await getCategoriesApi();
            this.setState({categories: categories.map(category=>({
                label: category.title,
                value: category.id
            }))});
        } catch (error) {
            console.log(String(error));
        }
    }
    public componentDidMount(): void {

        UserGroupsStore.init();
        this.getCategories();
        this.currentModuleReaction = reaction(
            () => CurrentModulesStore.modulesState,
            () => {
                let currentModule = CurrentModulesStore.getModule(
                    this.props.moduleId
                );
                this.setState({
                    editableByCollaborators:
                        currentModule?.shared_link != null
                            ? currentModule.shared_link
                                  .editable_by_collaborators
                            : false,
                });
            }
        );
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId);
        getModuleUserDataSetsApi(this.props.moduleId, false)
            .then((dataSets) => {
                this.setState(
                    {
                        dataSetOptions: dataSets.map((dataSet) => ({
                            label: dataSet.data_table_name,
                            value: dataSet.data_table_idx,
                            accessType:
                                dataSet.permission_type === Permission.ReadWrite
                                    ? AccessType.CanEdit
                                    : dataSet.allow_uploading
                                    ? AccessType.CanSubmit
                                    : AccessType.CanRead,
                        })),
                    },
                    () => {
                        getAppApi(this.props.moduleId)
                            .then((data) => {
                                if (data != null) {
                                    this.setState((state) => {
                                        let availableDataSets = new Set(
                                            state.dataSetOptions.map(
                                                (dataSet) => dataSet.value
                                            )
                                        );
                                        return {
                                            appExists: true,
                                            intoCreateNew:
                                                data.special_tab_id ===
                                                getSpecialTabIdIndex(
                                                    SpecialTabId.CreateNew
                                                ),
                                            moduleTitle: data.title,
                                            moduleThumbnail:
                                                data.thumbnail ?? "",
                                            pwaLogo: data.logo,
                                            moduleDescription: data.description,
                                            moduleKeywords: data.keywords,
                                            allowAccessToAllDataSets:
                                                data.allow_access_to_all_data_sets,
                                            selectedDataSets:
                                                data.allow_access_to_all_data_sets
                                                    ? state.dataSetOptions.map(
                                                          (dataSet) => ({
                                                              ...dataSet,
                                                              accessType:
                                                                  dataSet.accessType ===
                                                                  AccessType.CanRead
                                                                      ? AccessType.CanRead
                                                                      : AccessType.CanSubmit,
                                                          })
                                                      )
                                                    : data.dataSets
                                                          .filter((dataSet) =>
                                                              availableDataSets.has(
                                                                  dataSet.data_table_idx
                                                              )
                                                          )
                                                          .map((dataSet) => ({
                                                              label: dataSet.data_table_name,
                                                              value: dataSet.data_table_idx,
                                                              accessType:
                                                                  dataSet.copy_on_load
                                                                      ? AccessType.CreateLocalCopy
                                                                      : dataSet.allow_uploading
                                                                      ? AccessType.CanSubmit
                                                                      : AccessType.CanRead,
                                                          })),
                                            addPayWall: data.paywall != null,
                                            price:
                                                data.paywall?.price.toString() ??
                                                "",
                                            logo: data.paywall?.logo ?? "",
                                            name: data.paywall?.title ?? "",
                                            client: data.paywall?.client ?? "",
                                            shareLink: linkIdToUrl(
                                                true,
                                                data.link_id
                                            ),
                                            linkId: data.link_id,
                                            categoryId: data.category?.id,
                                            paymentType:
                                                data.paywall?.payment_type ??
                                                "one_time",
                                            editableByCollaborators:
                                                currentModule?.shared_link !=
                                                null
                                                    ? currentModule.shared_link
                                                          .editable_by_collaborators
                                                    : false,
                                            selfSignupLinkId:
                                                data.self_signup_link_id ??
                                                null,
                                            selfSignupInfo:
                                                data.self_signup_link_id != null
                                                    ? {
                                                          logo:
                                                              data
                                                                  .self_signup_info
                                                                  ?.logo ??
                                                              null,
                                                          options:
                                                              data
                                                                  .self_signup_info
                                                                  ?.options !=
                                                              null
                                                                  ? JSON.parse(
                                                                        data
                                                                            .self_signup_info
                                                                            ?.options
                                                                    )
                                                                  : null,
                                                      }
                                                    : null,
                                            shortLink: data.short_link ?? null,
                                            newShortLink: data.short_link ?? "",
                                            usePermissions:
                                                data.use_permissions,
                                            selectedUserGroups:
                                                data.permissions ?? [],
                                        };
                                    });
                                } else {
                                    this.setState((state) => ({
                                        allowAccessToAllDataSets: true,
                                        selectedDataSets:
                                            state.dataSetOptions.map(
                                                (dataSet) => ({
                                                    ...dataSet,
                                                    accessType:
                                                        dataSet.accessType ===
                                                        AccessType.CanRead
                                                            ? AccessType.CanRead
                                                            : AccessType.CanSubmit,
                                                })
                                            ),
                                    }));
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                );
            })
            .catch((error) => {
                if (error?.response?.status !== 401 && error.response.status !== 403) {
                    console.log(error);
                }
            });
    }
    componentWillUnmount() {
        if (this.currentModuleReaction) this.currentModuleReaction();
    }
    private buildInnerView(globalContext: GlobalContextContents): JSX.Element {
        let options = UserGroupsStore.userGroupsOptionsForSharing(true);
        return (
            <div
                className="flex-simple-column"
                style={{
                    width: "100%",
                }}
            >
                <span
                    style={{
                        marginTop: 20,
                        color: mainStyle.getPropertyValue(
                            "--popup-primary-text-color"
                        ),
                        fontSize: "17px",
                        fontWeight: 400,
                        fontFamily: "Roboto",
                    }}
                    className="unselectable"
                >
                    Create a new app
                </span>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{ marginTop: 20, width: "calc(50% - 26px)" }}
                    >
                        <span
                            style={{
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Title
                        </span>
                        <input
                            className="like-select module-template"
                            style={{ marginTop: 9, marginLeft: 21 }}
                            placeholder="Give the template a name"
                            value={this.state.moduleTitle}
                            onChange={(evt) => {
                                let value = (evt.target as any)?.value;
                                this.setState({ moduleTitle: value });
                            }}
                        />
                        <span
                            style={{
                                marginTop: 15,
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Keywords
                        </span>
                        <div
                            className="my-row"
                            style={{ marginLeft: 21, marginTop: 9 }}
                        >
                            <input
                                style={{ width: "100%" }}
                                className="like-select module-template"
                                placeholder="Type to add keyword"
                                onChange={(e) => {
                                    const value: string = e.target.value;
                                    this.setState({ newKeyword: value });
                                }}
                                value={this.state.newKeyword}
                            />
                            <Button
                                disabled={this.state.newKeyword.length === 0}
                                title={"Type to add keyword"}
                                className="btn-small-like-select"
                                style={{
                                    marginLeft: -25,
                                    alignSelf: "center",
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let moduleKeywords = [
                                        ...this.state.moduleKeywords,
                                    ];
                                    moduleKeywords.push(this.state.newKeyword);
                                    this.setState({
                                        moduleKeywords: moduleKeywords,
                                        newKeyword: "",
                                    });
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                        </div>
                        <div
                            style={{
                                marginTop: 17,
                                marginLeft: 63,
                                display: "grid",
                                rowGap: "8px",
                                columnGap: "30px",
                                gridTemplateColumns: "repeat(3, 100px)",
                            }}
                        >
                            {this.state.moduleKeywords.map(
                                (keyword, keywordIndex) => (
                                    <div
                                        className="my-row"
                                        key={keywordIndex}
                                        onMouseEnter={() => {
                                            this.setState({
                                                hoverKeywordIndex: keywordIndex,
                                            });
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({
                                                hoverKeywordIndex: undefined,
                                            });
                                        }}
                                    >
                                        <span
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "77px",
                                                marginLeft: 2,
                                                color: mainStyle.getPropertyValue(
                                                    "--popup-primary-text-color"
                                                ),
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                fontFamily: "Roboto",
                                            }}
                                            className="unselectable"
                                        >
                                            {keyword}
                                        </span>
                                        {this.state.hoverKeywordIndex ===
                                            keywordIndex && (
                                            <div
                                                className="flex-simple-column"
                                                style={{ marginLeft: 2 }}
                                            >
                                                <Button
                                                    title={"Remove keyword"}
                                                    className="btn-small-like-select"
                                                    style={{
                                                        width: "19px",
                                                        height: "19px",
                                                    }}
                                                    onClick={() =>
                                                        this.setState(
                                                            (state) => {
                                                                let keywords =
                                                                    Array.from(
                                                                        state.moduleKeywords
                                                                    );
                                                                keywords.splice(
                                                                    keywordIndex,
                                                                    1
                                                                );
                                                                return {
                                                                    moduleKeywords:
                                                                        keywords,
                                                                };
                                                            }
                                                        )
                                                    }
                                                >
                                                    {"\uFF0D" /* minus */}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                        <span
                            style={{
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Thumbnail
                        </span>
                        <input
                            className="like-select module-template"
                            style={{ marginTop: 9, marginLeft: 21 }}
                            placeholder="Thumbnail"
                            value={this.state.moduleThumbnail}
                            onChange={(evt) => {
                                let value = (evt.target as any)?.value;
                                this.setState({ moduleThumbnail: value });
                            }}
                        />
                        <div
                            className="my-row"
                            style={{
                                marginTop: 20,
                                marginLeft: 16,
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    marginRight: 30,
                                    color: "var(--popup-primary-text-color)",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Editable by collaborators
                            </span>
                            <div style={{ marginLeft: 19 }}>
                                <Switch
                                    onChange={(checked) => {
                                        this.setState({
                                            editableByCollaborators: checked,
                                        });
                                    }}
                                    checked={this.state.editableByCollaborators}
                                    width={40}
                                    height={20}
                                    offColor="#CCC"
                                    onColor="#CCC"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="flex-simple-column"
                        style={{
                            marginLeft: 53,
                            marginTop: 20,
                            width: "calc(50% - 26px)",
                        }}
                    >
                        <span
                            style={{
                                marginLeft: 2,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Description
                        </span>
                        <TextareaAutosize
                            className="insights-text-area-popup module-template"
                            placeholder="Elaborate on your template"
                            value={this.state.moduleDescription}
                            style={{
                                paddingLeft: 11,
                                paddingTop: 11,
                                paddingBottom: 11,
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: "Roboto",
                                backgroundColor: mainStyle.getPropertyValue(
                                    "--selectors-background-color"
                                ),
                                minHeight: 181,
                                marginTop: 9,
                                marginLeft: 0,
                                marginRight: 0,
                                marginBottom: 0,
                            }}
                            onChange={(evt) => {
                                let value = (evt.target as any)?.value;
                                this.setState({ moduleDescription: value });
                            }}
                        />
                        <div
                            className="manifest-data flex-simple-column"
                            style={{ width: "calc(50% - 26px)" }}
                        >
                            {/* <span
                            className={cx("unselectable", styles.appCreatorLable)}
                        >
                            App title
                        </span>
                        <input
                            className="like-select module-template"
                            style={{ marginTop: 9, marginLeft: 21 }}
                            placeholder="Give the app title"
                            value={this.state.moduleTitle}
                            onChange={(evt) => {
                                let value = (evt.target as any)?.value;
                                this.setState({ pwaTitle: value });
                            }}
                        /> */}
                            <span
                                className={cx(
                                    "unselectable",
                                    styles.appCreatorLable
                                )}
                                style={{ marginTop: 9, marginLeft: 2 }}
                            >
                                App icon
                            </span>
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    if (acceptedFiles.length > 0) {
                                        const file = acceptedFiles[0];
                                        const { type } = file;
                                        if (
                                            !type.includes("image") ||
                                            type.includes("gif")
                                        ) {
                                            this.setState({
                                                uploadStatus:
                                                    UploadStatus.Error,
                                                message: String(
                                                    "The file should be image type"
                                                ),
                                            });
                                            return;
                                        }
                                        if (file.size > 20480) {
                                            this.setState({
                                                uploadStatus:
                                                    UploadStatus.Error,
                                                message: String(
                                                    "The file is too big, it should be less then 20kb"
                                                ),
                                            });
                                            return;
                                        }

                                        const reader = new FileReader();

                                        reader.onload = () => {
                                            const binaryStr =
                                                typeof reader.result ===
                                                "string"
                                                    ? reader.result.toString()
                                                    : "";
                                            this.setState({
                                                pwaLogo: binaryStr,
                                            });
                                        };

                                        reader.readAsDataURL(acceptedFiles[0]);
                                    }
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section
                                        className={styles.appCreatorDropzone}
                                    >
                                        <div
                                            {...getRootProps()}
                                            className={
                                                styles.appCreatorPWAIconContainer
                                            }
                                        >
                                            <input {...getInputProps()} />
                                            {!this.state.pwaLogo && (
                                                <p>Select File to Upload</p>
                                            )}
                                            <img
                                                src={this.state.pwaLogo}
                                                alt=""
                                            />
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        marginTop: "13px",
                        height: "1px",
                        backgroundColor: "#CCC",
                        width: "100%",
                    }}
                />
                <div
                    className="link-and-manifest-data-wrapper my-row"
                    style={{ marginTop: 20 }}
                >
                    <div className="link-data">
                        <span
                            style={{
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Permissions
                        </span>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                }}
                                className="unselectable"
                            >
                                Anyone with a link has access
                            </span>
                            <Switch
                                onChange={(checked) => {
                                    this.setState({
                                        usePermissions: checked,
                                    });
                                }}
                                checked={this.state.usePermissions}
                                width={40}
                                height={20}
                                offColor="#CCC"
                                onColor="#CCC"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                }}
                                className="unselectable"
                            >
                                Allow someone to invite and delete users
                            </span>
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                                display:
                                    "ImportModulesToCreateNew" in
                                    globalContext.permissions
                                        ? "flex"
                                        : "none",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                }}
                                className="unselectable"
                            >
                                Feature on Front Page
                            </span>
                            <Switch
                                onChange={(checked) => {
                                    this.setState({
                                        intoCreateNew: checked,
                                    });
                                }}
                                checked={this.state.intoCreateNew}
                                width={40}
                                height={20}
                                offColor="#CCC"
                                onColor="#CCC"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                                display:
                                    "ManageCategories" in
                                    globalContext.permissions
                                        ? "flex"
                                        : "none",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                }}
                                className="unselectable"
                            >
                                Assign category
                            </span>
                            <Select
                                isDisabled={!this.state.intoCreateNew}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                isClearable
                                placeholder=""
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginTop: 5,
                                        marginLeft: 23,
                                        height: "38px",
                                        width: "16em",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={this.state.categories}
                                value={
                                    this.state.categories.find(category => category.value === this.state.categoryId) ?? null
                                }
                                onChange={async (option) => {
                                    this.setState({categoryId: (option as NumericOption | null)?.value ?? undefined})

                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                                menuPortalTarget={document.body}
                            />
                        </div>
                        {this.state.appExists && !this.state.usePermissions && (
                            <>
                                <div
                                    className="my-row"
                                    style={{
                                        marginTop: 10,
                                        marginLeft: 16,
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            marginRight: 30,
                                            color: "var(--popup-primary-text-color)",
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            fontFamily: "Roboto",
                                        }}
                                        className="unselectable"
                                    >
                                        Invite user
                                    </span>
                                    <input
                                        value={this.state.newUserInput}
                                        className="like-select module-template"
                                        type="email"
                                        onChange={(evt) => {
                                            let value = evt.target.value;
                                            this.setState({
                                                newUserInput: value,
                                            });
                                        }}
                                    />
                                    <Button
                                        type="button"
                                        disabled={
                                            !this.state.newUserInput ||
                                            this.state.linkId == null
                                        }
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{
                                            marginLeft: "35px",
                                            marginRight: "10px",
                                            width: "112px",
                                            height: "33px",
                                            paddingTop: "6.5px",
                                        }}
                                        onClick={this.inviteUser}
                                    >
                                        INVITE USER
                                    </Button>
                                </div>
                                <div
                                    className="my-row"
                                    style={{
                                        marginTop: 10,
                                        marginLeft: 16,
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            marginRight: 5,
                                            color: "var(--popup-primary-text-color)",
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            fontFamily: "Roboto",
                                            width: "17em",
                                        }}
                                        className="unselectable"
                                    >
                                        Permanent self-served signup link:
                                    </span>
                                    {this.state.selfSignupLinkId != null ? (
                                        <>
                                            <CopyToClipboard
                                                text={selfSignupLinkIdToUrl(
                                                    this.state.selfSignupLinkId
                                                )}
                                                options={{
                                                    format: "text/plain",
                                                }}
                                                onCopy={() => {
                                                    this.setState({
                                                        uploadStatus:
                                                            UploadStatus.Success,
                                                        message:
                                                            "Self-Served Signup Link Copied to Clipboard",
                                                    });
                                                }}
                                            >
                                                <div
                                                    className="my-row"
                                                    style={{
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        src="/dist/img/canvas/share_module_link.png"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            color: mainStyle.getPropertyValue(
                                                                "--popup-secondary-text-color"
                                                            ),
                                                            fontSize: "12px",
                                                            fontWeight: 500,
                                                            fontFamily:
                                                                "Roboto",
                                                        }}
                                                        className="unselectable"
                                                    >
                                                        COPY
                                                    </span>
                                                </div>
                                            </CopyToClipboard>
                                            <Button
                                                type="button"
                                                className="btn btn-sm btn-primary my-primary"
                                                style={{
                                                    marginLeft: "30px",
                                                    width: "112px",
                                                    height: "33px",
                                                    paddingTop: "6.5px",
                                                }}
                                                onClick={() => {
                                                    removeSelfSignupLinkFromAppApi(
                                                        this.state.linkId!
                                                    )
                                                        .then(() => {
                                                            this.setState({
                                                                selfSignupLinkId:
                                                                    null,
                                                            });
                                                        })
                                                        .catch((error) => {
                                                            this.setState({
                                                                inviteStatus:
                                                                    PopupStatus.Error,
                                                                inviteMessage:
                                                                    String(
                                                                        error
                                                                    ),
                                                            });
                                                        });
                                                }}
                                            >
                                                REMOVE
                                            </Button>
                                            <Button
                                                type="button"
                                                className="btn btn-sm btn-primary my-primary"
                                                style={{
                                                    marginLeft: "30px",
                                                    width: "112px",
                                                    height: "33px",
                                                    paddingTop: "6.5px",
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        showSelfSignupInfoPopup:
                                                            true,
                                                    });
                                                }}
                                            >
                                                CUSTOMIZE
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            type="button"
                                            className="btn btn-sm btn-primary my-primary"
                                            style={{
                                                width: "112px",
                                                height: "33px",
                                                paddingTop: "6.5px",
                                                background:
                                                    "linear-gradient(94.78deg, rgb(26, 96, 179) 0%, rgb(19, 82, 155) 100%)",
                                            }}
                                            onClick={() => {
                                                addSelfSignupLinkToAppApi(
                                                    this.state.linkId!,
                                                    undefined,
                                                    undefined
                                                )
                                                    .then(
                                                        (selfSignupLinkId) => {
                                                            this.setState({
                                                                selfSignupLinkId:
                                                                    selfSignupLinkId,
                                                            });
                                                        }
                                                    )
                                                    .catch((error) => {
                                                        this.setState({
                                                            inviteStatus:
                                                                PopupStatus.Error,
                                                            inviteMessage:
                                                                String(error),
                                                        });
                                                    });
                                            }}
                                        >
                                            Create
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}
                        {this.state.appExists && this.state.usePermissions && (
                            <AppUserInviter
                                usersSectionTitle={"USERS WITH PERMISSION"}
                                buttonTitle={"ALLOW USER"}
                                options={options}
                                selectedUserGroups={
                                    this.state.selectedUserGroups
                                }
                                onAddUser={this.addUser}
                                onDeleteUserGroup={this.deleteUserGroup}
                                onChange={(changes) => {
                                    this.setState(changes as State);
                                }}
                                selectedNewGroup={this.state.selectedNewGroup}
                            />
                        )}
                    </div>
                </div>
                <div
                    style={{
                        marginTop: "13px",
                        height: "1px",
                        backgroundColor: "#CCC",
                        width: "100%",
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            marginTop: 20,
                            width: "calc(50% - 26px)",
                        }}
                    >
                        <span
                            style={{
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Paywall Settings
                        </span>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                    verticalAlign: "middle",
                                }}
                                className="unselectable"
                            >
                                Add Paywall
                            </span>
                            <Switch
                                onChange={(checked) => {
                                    this.setState({ addPayWall: checked });
                                }}
                                checked={this.state.addPayWall}
                                width={40}
                                height={20}
                                offColor="#CCC"
                                onColor="#CCC"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 15,
                                alignItems: "center",
                                display: this.state.addPayWall
                                    ? "flex"
                                    : "none",
                            }}
                        >
                            <ProductParametersInput
                                client={this.state.client}
                                name={this.state.name}
                                paymentType={this.state.paymentType}
                                price={this.state.price}
                                logo={this.state.logo}
                                onChange={(changes) => {
                                    this.setState(
                                        changes as Pick<State, keyof State>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className="flex-simple-column"
                        style={{
                            marginTop: 20,
                            width: "calc(50% - 26px)",
                        }}
                    >
                        <span
                            style={{
                                marginTop: 0,
                                marginLeft: 53,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Data Set Access Settings
                        </span>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: 53,
                                marginTop: 5,
                                marginBottom: 5,
                            }}
                        >
                            <span
                                style={{
                                    marginRight: 30,
                                    color: "var(--popup-primary-text-color)",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Include all data sets for link
                            </span>
                            <div style={{ marginLeft: 19 }}>
                                <Switch
                                    onChange={(checked) => {
                                        this.setState((state) => {
                                            if (checked) {
                                                return {
                                                    allowAccessToAllDataSets:
                                                        checked,
                                                    selectedDataSet: null,
                                                    selectedDataSets:
                                                        state.dataSetOptions.map(
                                                            (dataSet) => ({
                                                                ...dataSet,
                                                                accessType:
                                                                    dataSet.accessType ===
                                                                    AccessType.CanRead
                                                                        ? AccessType.CanRead
                                                                        : AccessType.CanSubmit,
                                                            })
                                                        ),
                                                };
                                            } else {
                                                return {
                                                    allowAccessToAllDataSets:
                                                        checked,
                                                    selectedDataSet:
                                                        state.selectedDataSet,
                                                    selectedDataSets:
                                                        state.selectedDataSets,
                                                };
                                            }
                                        });
                                    }}
                                    checked={
                                        this.state.allowAccessToAllDataSets
                                    }
                                    width={40}
                                    height={20}
                                    offColor="#CCC"
                                    onColor="#CCC"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                        {!this.state.allowAccessToAllDataSets && (
                            <DataSetsSelector
                                sharedByYouTitle="SHARED IN THIS PRESENTATION"
                                createLocalCopyShown={true}
                                rowDirection={true}
                                menuPortalEnabled={true}
                                className="unselectable"
                                style={{
                                    marginLeft: 53,
                                    fontSize: "10px",
                                }}
                                onSelectedDataSetsChanged={(
                                    selectedDataSets: DataSet[]
                                ) => {
                                    this.setState({
                                        selectedDataSets: selectedDataSets,
                                    });
                                }}
                                onSelectedDataSetChanged={(
                                    selectedDataSet: DataSet | null
                                ) => {
                                    this.setState({
                                        selectedDataSet: selectedDataSet,
                                    });
                                }}
                                selectedDataSets={this.state.selectedDataSets}
                                selectedDataSet={this.state.selectedDataSet}
                                dataSetOptions={this.state.dataSetOptions}
                            />
                        )}
                    </div>
                </div>

                {this.state.appExists && this.state.shareLink != null && (
                    <>
                        <div
                            style={{
                                marginTop: "13px",
                                height: "1px",
                                backgroundColor: "#CCC",
                                width: "100%",
                            }}
                        />
                        <div
                            className="my-row"
                            style={{
                                marginTop: "13px",
                                marginLeft: "9px",
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 2,
                                    marginRight: 15,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                App created.
                            </span>
                            <span
                                style={{
                                    marginLeft: 2,
                                    marginRight: 5,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Link:
                            </span>
                            <CopyToClipboard
                                text={this.state.shareLink ?? ""}
                                options={{
                                    format: "text/plain",
                                }}
                                onCopy={() => {
                                    this.setState({
                                        uploadStatus: UploadStatus.Success,
                                        message: "App Link Copied to Clipboard",
                                    });
                                }}
                            >
                                <div
                                    className="my-row"
                                    style={{ alignItems: "center" }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/canvas/share_module_link.png"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <span
                                        style={{
                                            marginLeft: 5,
                                            color: mainStyle.getPropertyValue(
                                                "--popup-secondary-text-color"
                                            ),
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            fontFamily: "Roboto",
                                        }}
                                        className="unselectable"
                                    >
                                        {"COPY"}
                                    </span>
                                </div>
                            </CopyToClipboard>
                            <span
                                style={{
                                    marginLeft: 25,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Short Link: /app/
                            </span>
                            <input
                                style={{
                                    width: 200,
                                    marginRight: 5,
                                    minHeight: 1,
                                }}
                                value={this.state.newShortLink}
                                className="like-select module-template"
                                onChange={(evt) => {
                                    let value = evt.target.value;
                                    if (/^([A-Za-z0-9_-])*$/.test(value)) {
                                        this.setState({
                                            newShortLink: value,
                                        });
                                    }
                                }}
                            />
                            {this.state.shortLink != null &&
                                this.state.shortLink !== "" && (
                                    <CopyToClipboard
                                        text={shortLinkToUrl(
                                            this.state.shortLink
                                        )}
                                        options={{
                                            format: "text/plain",
                                        }}
                                        onCopy={() => {
                                            this.setState({
                                                uploadStatus:
                                                    UploadStatus.Success,
                                                message:
                                                    "Short Link Copied to Clipboard",
                                            });
                                        }}
                                    >
                                        <div
                                            className="my-row"
                                            style={{ alignItems: "center" }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/canvas/share_module_link.png"
                                                style={{ cursor: "pointer" }}
                                            />
                                            <span
                                                style={{
                                                    marginLeft: 5,
                                                    color: mainStyle.getPropertyValue(
                                                        "--popup-secondary-text-color"
                                                    ),
                                                    fontSize: "12px",
                                                    fontWeight: 500,
                                                    fontFamily: "Roboto",
                                                }}
                                                className="unselectable"
                                            >
                                                {"COPY"}
                                            </span>
                                        </div>
                                    </CopyToClipboard>
                                )}
                        </div>
                    </>
                )}
                <div
                    style={{
                        marginTop: "13px",
                        height: "1px",
                        backgroundColor: "#CCC",
                        width: "100%",
                    }}
                />
                <div
                    className="my-row"
                    style={{
                        marginTop: "15px",
                        marginBottom: "24px",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    {this.state.uploadStatus === UploadStatus.Started && (
                        <Alert
                            text={"Creating..."}
                            className="alert alert-warning alert-dismissible"
                            onClosed={() => {
                                this.setState({
                                    uploadStatus: UploadStatus.NotStarted,
                                    message: "",
                                });
                            }}
                            style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                marginTop: "10px",
                                marginBottom: "10px",
                                minHeight: "25px",
                            }}
                        />
                    )}
                    {/* this.state.appExists && (
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginLeft: "27px",
                                backgroundColor: "transparent",
                                border: "none",
                                width: "90px",
                            }}
                            onClick={() => {
                                this.props.onCancel();
                            }}
                        >
                            REMOVE APP
                        </Button>
                    ) */}
                    <Button
                        type="button"
                        className="btn btn-sm my-outline"
                        style={{
                            color: "#3b82c9",
                            marginLeft: "27px",
                            marginRight: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                            width: "70px",
                        }}
                        onClick={() => {
                            this.props.onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        disabled={
                            !this.state.moduleTitle ||
                            (this.state.addPayWall &&
                                (!this.state.name ||
                                    Number(this.state.price) === 0)) ||
                            this.state.uploadStatus === UploadStatus.Started
                        }
                        style={{
                            width: "112px",
                            background:
                                "linear-gradient(94.78deg, rgb(26, 96, 179) 0%, rgb(19, 82, 155) 100%)",
                        }}
                        className="btn btn-sm btn-primary my-primary"
                        onClick={async () => {
                            this.setState({
                                uploadStatus: UploadStatus.Started,
                            });

                            let dataSets = this.state.selectedDataSets.map(
                                (dataSet) => ({
                                    data_table_idx: dataSet.value,
                                    allow_uploading:
                                        dataSet.accessType ===
                                        AccessType.CanSubmit,
                                    copy_on_load:
                                        dataSet.accessType ===
                                        AccessType.CreateLocalCopy,
                                })
                            );
                            if (this.state.selectedDataSet != null) {
                                dataSets.push({
                                    data_table_idx:
                                        this.state.selectedDataSet.value,
                                    allow_uploading:
                                        this.state.selectedDataSet
                                            .accessType ===
                                        AccessType.CanSubmit,
                                    copy_on_load:
                                        this.state.selectedDataSet
                                            .accessType ===
                                        AccessType.CreateLocalCopy,
                                });
                            }

                            addOrEditAppApi(
                                this.props.moduleId,
                                this.state.moduleTitle,
                                this.state.moduleThumbnail,
                                this.state.moduleDescription,
                                this.state.moduleKeywords,
                                dataSets,
                                this.state.editableByCollaborators,
                                this.state.intoCreateNew
                                    ? getSpecialTabIdIndex(
                                          SpecialTabId.CreateNew
                                      )
                                    : undefined,
                                this.state.pwaLogo,
                                this.state.addPayWall
                                    ? {
                                          title: this.state.name,
                                          client: this.state.client,
                                          description: "",
                                          payment_type: this.state.paymentType,
                                          price: Number(this.state.price),
                                          logo: this.state.logo,
                                      }
                                    : undefined,
                                this.state.newShortLink !== ""
                                    ? this.state.newShortLink
                                    : undefined,
                                this.state.usePermissions,
                                this.state.selectedUserGroups.map((group) => ({
                                    group_id: group.group_id,
                                    can_edit: true,
                                })),
                                this.state.allowAccessToAllDataSets,
                                this.state.categoryId
                            )
                                .then((linkId) => {
                                    CurrentModulesStore.updateCurrentModules(
                                        this.props.moduleId
                                    );
                                    DataScopes.update();
                                    this.setState((state) => ({
                                        appExists: true,
                                        shareLink: linkIdToUrl(true, linkId),
                                        linkId: linkId,
                                        uploadStatus: UploadStatus.Success,
                                        message: "App is created successfully",
                                        shortLink:
                                            state.newShortLink !== ""
                                                ? state.newShortLink
                                                : null,
                                    }));
                                })
                                .catch((error) => {
                                    this.setState({
                                        uploadStatus: UploadStatus.Error,
                                        message: String(error),
                                    });
                                });
                        }}
                    >
                        {this.state.appExists ? "Edit" : "Create"}
                    </Button>
                </div>
                {this.state.uploadStatus === UploadStatus.Error && (
                    <StatusPopup
                        status={PopupStatus.Error}
                        message={this.state.message}
                        onClose={() => {
                            this.setState({
                                uploadStatus: UploadStatus.NotStarted,
                                message: "",
                            });
                        }}
                    />
                )}
                {this.state.uploadStatus === UploadStatus.Success && (
                    <StatusPopup
                        status={PopupStatus.Success}
                        message={this.state.message}
                        onClose={() => {
                            this.setState({
                                uploadStatus: UploadStatus.NotStarted,
                                message: "",
                            });
                        }}
                    />
                )}
                {this.state.inviteStatus != null && (
                    <StatusPopup
                        onClose={() => {
                            this.setState({
                                inviteStatus: undefined,
                                inviteMessage: "",
                            });
                        }}
                        status={this.state.inviteStatus}
                        message={this.state.inviteMessage ?? ""}
                    />
                )}
                {this.state.showSelfSignupInfoPopup && (
                    <LinkCustomizePopup
                        selfSignupInfo={this.state.selfSignupInfo}
                        onApply={(selfSignupInfo: SelfSignupInfo) => {
                            addSelfSignupLinkToAppApi(
                                this.state.linkId!,
                                JSON.stringify(selfSignupInfo.options),
                                selfSignupInfo.logo
                            )
                                .then((selfSignupLinkId) => {
                                    this.setState({
                                        showSelfSignupInfoPopup: false,
                                        selfSignupInfo: selfSignupInfo,
                                        selfSignupLinkId: selfSignupLinkId,
                                        inviteStatus: PopupStatus.Success,
                                        inviteMessage: "Options applied",
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        inviteStatus: PopupStatus.Error,
                                        inviteMessage: String(error),
                                    });
                                });
                        }}
                        onClose={() => {
                            this.setState({ showSelfSignupInfoPopup: false });
                        }}
                    />
                )}
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.ObserverConsumer>
                {this.buildInnerView}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export default AppCreator;
