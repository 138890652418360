import cx from "classnames";
import { formatValue } from "common/utilities/FormatValue";
import { ColumnIndex } from "../../GuidanceWizardDataSet";
import styles from "./RankChart.module.css";

interface Props {
    data: { [key: string]: string | number | null }[];
}

function RankChart(props: Props): JSX.Element {
    return (
        <div className={cx(styles.container, "element")}>
            <div className={styles.headerRow}>
                <span className={styles.headerCell}>Opportunity ($)</span>
                <span className={styles.headerCell}>Customer</span>
                <span className={styles.headerCell}>
                    Revenue - Current Period
                </span>
                <span className={styles.headerCell}>Revenue - Last Period</span>
            </div>
            {props.data.map((row, index) => (
                <div key={index} className={styles.row}>
                    <span className={styles.cell}>
                        {`$${formatValue(
                            row[ColumnIndex.Opportunity],
                            false,
                            1
                        ).join("")}`}
                    </span>
                    <span className={styles.cell}>
                        {row[ColumnIndex.Customer]}
                    </span>
                    <span className={styles.cell}>
                        {formatValue(
                            row[ColumnIndex.CustomerRevenueCurrentPeriod],
                            false,
                            1
                        ).join("")}
                    </span>
                    <span className={styles.cell}>
                        {formatValue(
                            row[ColumnIndex.CustomerRevenueLastPeriod],
                            false,
                            1
                        ).join("")}
                    </span>
                </div>
            ))}
        </div>
    );
}

export default RankChart;
