import processUrl from "common/processUrl";
import {
  CSSProperties,
} from "react";

/*!
 * TooltipVideoComponent defines tooltip item with video url
 *
 */

export function TooltipVideoComponent({
  src,
  width,
  height,
  iframeTitle,
  style,
}: {
  src: string;
  width: number | string;
  height: number | string;
  iframeTitle: string;
  style?: CSSProperties;
}) {
  let urlString = processUrl(src);
  if (urlString != null) {
      return (
          <iframe
              draggable={false}
              title={iframeTitle}
              frameBorder="0"
              allow=""
              allowFullScreen
              width={width}
              height={height}
              src={urlString}
              style={style}
          />
      );
  } else {
      return (
          <video controls={true} width={width} height={height} style={style}>
              <source src={src} />
          </video>
      );
  }
}
