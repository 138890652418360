import Variables from "common/Variables";
import { LeversBenchmarkingFinding } from "common/Finding";
import { conditionsToJson } from "common/Conditions";
import { configVersionV2 } from "common/PathConfigVersion";
import axios from "common/ServerConnection";
import { Condition } from "common/Conditions";
import { updateOriginalNames } from "../../common/Utils";

const compareVariablesDictionary: { [key: string]: string } = {
    best: "max",
    worst: "min",
    average: "avg",
};

class Api {
    static getDefaultConfig(
        journeyName: string
    ): LeversBenchmarkingFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            barSize: 80,
            barGap: 0,
            barCategoryGap: 100,
            linesCount: 4,
            showXAxisName: true,
            showYAxisName: false,
            axesLinesColor: "#E9E9E9",
            groupOperation: "best",
            compareOperation: "worst",
        };
    }

    static getPreviewFinding(journeyName: string): LeversBenchmarkingFinding {
        let item: LeversBenchmarkingFinding = {
            type: "leversbenchmarking",
            content: {
                groupValue: 30,
                compareValue: 25,
                aggregateVariable: "Aggregate Variable",
                groupOperation: "best",
                compareOperation: "worst",
                data: [
                    {
                        name: "Variable 1",
                        units: "",
                        value: [12, 10],
                    },
                    {
                        name: "Variable 2",
                        units: "",
                        value: [8, 6],
                    },
                    {
                        name: "Variable 3",
                        units: "",
                        value: [5, 3],
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getFinding(props: {
        groupValue: string | number;
        compareValue: string | number;
        aggregateVariable: string;
        data: LeversBenchmarkingFinding["content"]["data"];
        groupOperation: "best" | "worst" | "average";
        compareOperation: "best" | "worst" | "average";
        config: LeversBenchmarkingFinding["config"];
    }): LeversBenchmarkingFinding {
        let {
            aggregateVariable,
            data,
            groupOperation,
            compareOperation,
            groupValue,
            compareValue,
            config,
        } = props;

        let item: LeversBenchmarkingFinding = {
            type: "leversbenchmarking",
            content: {
                aggregateVariable: aggregateVariable,
                data: data,
                groupOperation: groupOperation,
                compareOperation: compareOperation,
                groupValue: groupValue,
                compareValue: compareValue,
            },
            config: config,
        };
        return item;
    }

    static async getData(
        finding: LeversBenchmarkingFinding,
        _findingOptions: any,
        moduleId?: number
    ): Promise<LeversBenchmarkingFinding> {
        let {
            dataScope,
            aggregateVariable,
            aggregateVariableIndex,
            compareOperation,
            targetVariable,
            targetVariableIndex,
            selectedTable,
            groupOperation,
            sideBySideVariableIndex,
            additionalValues,
            additionalOperators,
        } = finding.config;
        let variables = finding.content.data.filter(
            (item) => item.variableIndex != null
        );
        if (variables.length === 0) {
            return Promise.resolve(finding);
        }
        let dataTableIdx = dataScope == null ? 0 : dataScope.value; //let compareOperation = compareVariablesDictionary[compareVariable];
        let conditions: Condition[] = finding.config.conditions;
        conditions = conditions?.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        let conditionsJson =
            conditions != null ? conditionsToJson(conditions) : undefined;
        let requestJson: any = {
            data_table_idx: dataTableIdx,
            group_index: aggregateVariableIndex,
            group_measured_by_index: targetVariableIndex,
            compare_to: compareVariablesDictionary[compareOperation],
            group_operation: compareVariablesDictionary[groupOperation],
            variable_indices: variables.map(
                (variable) => variable.variableIndex
            ),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            conditions: conditionsJson,
            module_id: moduleId,
        };

        if (
            sideBySideVariableIndex != null &&
            (additionalValues?.length ?? 0) > 0
        ) {
            requestJson.where = {
                group_index: sideBySideVariableIndex,
                operation: additionalOperators?.[0]?.value ?? "=",
                value: additionalValues[0]?.value,
            };
        }

        return axios
            .post<{
                success: boolean;
                error_msg: string;
                group_name: { [key: string]: number };
                compare_to: { [key: string]: number };
            }>("/api/e/get_data_comparison", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newConfig = { ...finding.config };
                    let data = Array.from(finding.content.data);

                    // Check wherther variables were renamed
                    updateOriginalNames(data, dataScope.value, moduleId);
                    const dataVariables = Variables(dataScope.value, moduleId)
                        .dataVariables;
                    if (targetVariableIndex < dataVariables.length) {
                        targetVariable =
                            dataVariables[targetVariableIndex].name;
                        newConfig.targetVariable =
                            dataVariables[targetVariableIndex].name;
                    }
                    if (aggregateVariableIndex < dataVariables.length) {
                        aggregateVariable =
                            dataVariables[aggregateVariableIndex].name;
                        newConfig.aggregateVariable =
                            dataVariables[aggregateVariableIndex].name;
                    }

                    for (let index in data) {
                        let item = data[index];
                        if (
                            item.variableIndex != null &&
                            item.originalName !== targetVariable
                        ) {
                            data[index] = {
                                ...item,
                                name: item.originalName!,
                                [groupOperation]:
                                    response.data["group_name"][
                                        item.originalName!
                                    ] || 0,
                                [compareOperation]:
                                    response.data["compare_to"][
                                        item.originalName!
                                    ] || 0,
                                units: Variables(
                                    dataTableIdx,
                                    moduleId
                                ).getUnits(item.originalName!),
                                value: [
                                    response.data["group_name"][
                                        item.originalName!
                                    ] || 0,
                                    response.data["compare_to"][
                                        item.originalName!
                                    ] || 0,
                                ],
                            };
                        }
                    }
                    let groupValue =
                        response.data["group_name"][aggregateVariable] || "";
                    let compareValue =
                        response.data["compare_to"][aggregateVariable] || "";

                    return Promise.resolve(
                        Api.getFinding({
                            aggregateVariable: aggregateVariable,
                            data: data,
                            groupOperation: groupOperation,
                            compareOperation: compareOperation,
                            groupValue: groupValue,
                            compareValue: compareValue,
                            config: newConfig,
                        })
                    );
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
