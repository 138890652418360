import React, { Component } from "react";
import { observer } from "mobx-react";
import { CanvasDashboard, CanvasDropdownSelector, DropdownStyle } from "common/Canvas";
import SearchComponent from "common/SearchComponent";
import {
    applyValue,
    DynamicOption,
    getOptions,
    getValue,
} from "common/DynamicOptions";
import Select from "react-select";
import { customSelectStylesLight, customSelectStylesRefine } from "common/SelectStyles";
import { DynamicOptionType } from "common/DynamicOptions";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { selectAllOptions } from "common/SearchComponentOptionWithMeta";
import { ConditionsSelector } from "common/Conditions";
import Variables from "common/Variables";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import remoteModuleId from "common/remoteModuleId";
import SelectStyled1 from "components/SelectStyled1";

interface Props {
    dynamicOption: DynamicOption;
    dashboard: CanvasDashboard;
    rootDataTestId: string;
    canvasTreeStore: CanvasTreeStore;
    canWrite: boolean;
    currentModuleId?: number;
    theme?: DropdownStyle;
    node: CanvasDropdownSelector;
}

@observer
export default class DropdownSelector extends Component<Props> {
    public render(): JSX.Element | null {
        let finding = this.props.dashboard.finding;
        if (finding == null) return null;
        let config = finding.config;
        let dataScope = config.dataScope;
        let options = getOptions(
            this.props.dynamicOption,
            this.props.dashboard,
            this.props.currentModuleId
        );

        const SelectCommp = this.props.theme === DropdownStyle.Refine ? SelectStyled1 : Select;

        return (
            <div
                data-test-id={`${this.props.rootDataTestId}-datasetSelector`}
                className="flex-simple-column"
                style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "10px",
                    width: "100%",
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                {this.props.dynamicOption.type === DynamicOptionType.Filter ? (
                    <ConditionsSelector
                        style={{ marginTop: 0, alignItems: "center" }}
                        key={dataScope?.value}
                        currentModuleId={this.props.currentModuleId}
                        small
                        dataScopeId={dataScope?.value}
                        value={config?.conditions}
                        title={""}
                        onChange={(value) => {
                            applyValue(
                                this.props.canvasTreeStore,
                                this.props.dynamicOption,
                                this.props.dashboard,
                                value
                            );
                        }}
                        allVariables={
                            Variables(
                                dataScope?.value,
                                this.props.currentModuleId ?? remoteModuleId
                            ).dataVariables
                        }
                        {...ConditionSelectorStyles}
                    />
                ) : this.props.dynamicOption.type ===
                  DynamicOptionType.SideBySideValue ? (
                    <SearchComponent
                        rootDataTestId={this.props.rootDataTestId}
                        isMulti={this.props.dynamicOption.allowMultiple}
                        isDisabled={!this.props.canWrite}
                        clearable
                        dataScopeId={dataScope?.value}
                        customStyles={{
                            //...customSelectStylesSmall,
                            ...(this.props.theme === DropdownStyle.Refine ? customSelectStylesRefine : customSelectStylesLight),
                            container: (provided, _state) => ({
                                ...provided,
                                marginLeft: 0,
                            }),
                            clearIndicator: (provided, _state) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingRight: 0,
                                transformOrigin: "right center",
                                transform: "scale(0.5)",
                            }),
                            dropdownIndicator: (provided, _state) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                transformOrigin: "right center",
                                transform: "scale(0.5)",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 998,
                            }),
                        }}
                        initialValue={getValue(
                            this.props.dynamicOption,
                            this.props.dashboard
                        )}
                        searchIndex={config.sideBySideVariableIndex}
                        onOptionSelected={(selectedValue) => {

                        }}
                        menuPortalTarget={document.body}
                        currentModuleId={this.props.currentModuleId}
                    />
                ) : this.props.dynamicOption.type ===
                DynamicOptionType.CenterMapByValue ? (
                  <SearchComponent
                      rootDataTestId={this.props.rootDataTestId}
                      isMulti={this.props.dynamicOption.allowMultiple}
                      isDisabled={!this.props.canWrite}
                      clearable
                      dataScopeId={dataScope?.value}
                      customStyles={{
                          //...customSelectStylesSmall,
                          ...(this.props.theme === DropdownStyle.Refine ? customSelectStylesRefine : customSelectStylesLight),
                          container: (provided, _state) => ({
                              ...provided,
                              marginLeft: 0,
                          }),
                          clearIndicator: (provided, _state) => ({
                              ...provided,
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingRight: 0,
                              transformOrigin: "right center",
                              transform: "scale(0.5)",
                          }),
                          dropdownIndicator: (provided, _state) => ({
                              ...provided,
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              transformOrigin: "right center",
                              transform: "scale(0.5)",
                          }),
                          menuPortal: (base) => ({
                              ...base,
                              zIndex: 998,
                          }),
                      }}
                      initialValue={getValue(
                          this.props.dynamicOption,
                          this.props.dashboard
                      )}
                      searchIndex={config.centerVariableIndex}
                      onOptionSelected={(selectedValue) => {
                        applyValue(
                            this.props.canvasTreeStore,
                            this.props.dynamicOption,
                            this.props.dashboard,
                            selectedValue
                        );

                        return;
                      }}
                      menuPortalTarget={document.body}
                      currentModuleId={this.props.currentModuleId}
                  /> ) : (
                    <SelectCommp
                        isDisabled={!this.props.canWrite}
                        // filterOption={createFilter({
                        //     ignoreAccents: false,
                        // })}
                        isMulti={this.props.dynamicOption.allowMultiple}
                        placeholder={""}
                        styles={{
                            //...customSelectStylesSmall,
                            ...(this.props.theme === DropdownStyle.Refine ? customSelectStylesRefine : customSelectStylesLight),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 998,
                            }),
                            singleValue: (base) => ({
                                ...base,
                                textAlign: "left",
                            }),
                        }}
                        options={
                            this.props.dynamicOption.allowMultiple
                                ? [selectAllOptions[1]].concat(options as any)
                                : options
                        }
                        onChange={(newValue) => {
                            if (Array.isArray(newValue)) {
                                if (
                                    newValue.find(
                                        (option) =>
                                            option.meta?.selectAll === false
                                    )
                                ) {
                                    applyValue(
                                        this.props.canvasTreeStore,
                                        this.props.dynamicOption,
                                        this.props.dashboard,
                                        null
                                    );

                                    return;
                                }
                            }
                            applyValue(
                                this.props.canvasTreeStore,
                                this.props.dynamicOption,
                                this.props.dashboard,
                                newValue
                            );
                        }}
                        value={getValue(
                            this.props.dynamicOption,
                            this.props.dashboard
                        )}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25: "#1F8EFA",
                            },
                        })}
                        menuPortalTarget={document.body}
                        fillColor={this.props.node.fillColor}
                        fontColor={this.props.node.fontColor}
                        borderColor={this.props.node.borderColor}
                        fontSize={this.props.node.fontSize}
                    />
                )}
            </div>
        );
    }
}
