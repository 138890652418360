import React from "react";
import Switch from "react-switch";
import cx from "classnames";
import { NetworkFinding } from "common/Finding";
import styles from "../StylingSection.module.css";
import mapConfigStyles from "./NetworkOverMapSection.module.css";
import { defaultBaseEdgeThickness } from "common/Network";
import Input from "../Input";
import StringUtils from "common/utilities/StringUtils";
import ColorConfig from "../NetworkSection/ColorConfig";
import Variables, { VariableOption } from "common/Variables";
import Select from "react-select";
import { observer } from "mobx-react";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { CanvasDropdownSelector, isDropdownSelector } from "common/Canvas";
import { DynamicOptionType } from "common/DynamicOptions";

interface Props {
    showCenteringOption?: boolean;
    dashboardId: string;
    finding: NetworkFinding;
    canvasTreeStore: CanvasTreeStore;
    onClearEditing: () => void;
    onChange: (config: NetworkFinding["config"], updateData?: boolean) => void;
    currentModuleId?: number;
    currentZoomLevel?: number; // currently unused
}

function NetworkOverMapSection(props: Props) {
    let variableOptions: VariableOption[] = [];
    if (props.finding.config.dataScope != null) {
        variableOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
    }
    if (props.finding.config.dataScope != null) {
        variableOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
    }
    return (
        <>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Edge color</span>
                <div />
            </div>
            <ColorConfig
                type="edges"
                config={props.finding.config}
                onChange={props.onChange}
                currentModuleId={props.currentModuleId}
            />
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Base edge thickness</span>
                <Input
                    value={
                        props.finding.config.baseEdgeThickness ??
                        defaultBaseEdgeThickness
                    }
                    onChange={(value) => {
                        let newConfig = {
                            ...props.finding.config,
                        };
                        if (StringUtils.isNumber(value)) {
                            newConfig.baseEdgeThickness = Number(value);
                        } else {
                            newConfig.baseEdgeThickness = null;
                        }
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Node color</span>
                <div />
            </div>
            <ColorConfig
                type="nodes"
                config={props.finding.config}
                onChange={props.onChange}
                currentModuleId={props.currentModuleId}
            />
            {!props.finding.config.isAdditional && (
                <div className={mapConfigStyles.stylesMenu}>
                    <div className={mapConfigStyles.stylesMenuOption}>
                        <span className={mapConfigStyles.switchLabel}>
                            Lock Map Area
                        </span>
                        <Switch
                            onChange={(checked) => {
                                let newConfig: NetworkFinding["config"] = {
                                    ...props.finding.config,
                                    mapConfig: {
                                        ...props.finding.config.mapConfig,
                                    },
                                };

                                if (
                                    props.finding.config.mapConfig?.colorOptions
                                ) {
                                    newConfig.mapConfig.colorOptions = {
                                        ...props.finding.config.mapConfig
                                            .colorOptions,
                                    };
                                } else {
                                    newConfig.mapConfig.colorOptions = {
                                        borderShadow: false,
                                        fillColor: "",
                                        borderColor: "",
                                    };
                                }

                                newConfig.mapConfig.colorOptions.lockMap =
                                    checked;

                                props.onChange(newConfig);
                            }}
                            checked={
                                props.finding.config.mapConfig?.colorOptions
                                    ?.lockMap ?? false
                            }
                            width={26}
                            height={13}
                            offColor="#C4C4C4"
                            onColor="#3B82C9"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#7B7B7B"
                            onHandleColor="#3B82C9"
                            className={cx({
                                [mapConfigStyles.switchOn]:
                                    props.finding.config.mapConfig?.colorOptions
                                        ?.lockMap,
                            })}
                        />
                    </div>
                    <div className={mapConfigStyles.stylesMenuOption}>
                        <span className={mapConfigStyles.switchLabel}>
                            Prevent zooming above current level
                        </span>
                        <Switch
                            onChange={(checked) => {
                                let newConfig: NetworkFinding["config"] = {
                                    ...props.finding.config,
                                    mapConfig: {
                                        ...props.finding.config.mapConfig,
                                    },
                                };

                                if (
                                    props.finding.config.mapConfig?.colorOptions
                                ) {
                                    newConfig.mapConfig.colorOptions = {
                                        ...props.finding.config.mapConfig
                                            .colorOptions,
                                    };
                                } else {
                                    newConfig.mapConfig.colorOptions = {
                                        borderShadow: false,
                                        fillColor: "",
                                        borderColor: "",
                                    };
                                }

                                newConfig.mapConfig.colorOptions.minZoomLevel =
                                    checked ? props.currentZoomLevel : null;

                                props.onChange(newConfig);
                            }}
                            disabled={props.currentZoomLevel == null}
                            checked={
                                !!props.finding.config.mapConfig?.colorOptions
                                    ?.minZoomLevel
                            }
                            width={26}
                            height={13}
                            offColor="#C4C4C4"
                            onColor="#3B82C9"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#7B7B7B"
                            onHandleColor="#3B82C9"
                            className={cx({
                                [mapConfigStyles.switchOn]:
                                    props.finding.config.mapConfig?.colorOptions
                                        ?.minZoomLevel,
                            })}
                        />
                    </div>
                    {props.showCenteringOption && (
                        <div
                            className={mapConfigStyles.stylesMenuOption}
                            style={{ flexDirection: "column" }}
                        >
                            <span className={mapConfigStyles.switchLabel}>
                                Allow users to choose center location on map by
                                variable
                            </span>
                            <Select
                                isClearable
                                placeholder="Select center variable"
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: 60,
                                        marginTop: 10,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: 25,
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={variableOptions}
                                onChange={(newValue) => {
                                    let referenceNode:
                                        | CanvasDropdownSelector
                                        | undefined = undefined;
                                    props.canvasTreeStore.canvasTreeState.forEach(
                                        (node) => {
                                            if (
                                                isDropdownSelector(node) &&
                                                node.dynamicOption
                                                    ?.dashboardId ===
                                                    props.dashboardId
                                            ) {
                                                referenceNode = node;
                                            }
                                        }
                                    );
                                    let active = referenceNode != null;
                                    let centerVariableIndex = (
                                        newValue as VariableOption | null
                                    )?.value;
                                    let centerVariable = (
                                        newValue as VariableOption | null
                                    )?.label;
                                    if (active && centerVariableIndex == null) {
                                        props.onClearEditing();
                                        props.canvasTreeStore.deleteNodeAction(
                                            referenceNode!.id
                                        );
                                    } else if (!active) {
                                        props.canvasTreeStore.addDropdownSelectorInputAction(
                                            {
                                                dynamicOption: {
                                                    key: "centerVariableValue",
                                                    dashboardId:
                                                        props.dashboardId,
                                                    title: "Center map",
                                                    type: DynamicOptionType.CenterMapByValue,
                                                    allowMultiple: false,
                                                },
                                            }
                                        );
                                    }
                                    props.onChange({
                                        ...props.finding.config,
                                        centerVariableIndex:
                                            centerVariableIndex ?? null,
                                        centerVariableValue: null,
                                        centerVariable: centerVariable ?? null,
                                    }, true);
                                }}
                                value={
                                    variableOptions.find(
                                        (option) =>
                                            option.value ===
                                            props.finding.config
                                                .centerVariableIndex
                                    ) ?? null
                                }
                            />
                        </div>
                    )}
                    <div className={mapConfigStyles.stylesMenuOption}>
                        <span className={mapConfigStyles.switchLabel}>
                            Grayscale
                        </span>
                        <Switch
                            onChange={(checked) => {
                                let newConfig: NetworkFinding["config"] = {
                                    ...props.finding.config,
                                    mapConfig: {
                                        ...props.finding.config.mapConfig,
                                    },
                                };

                                if (
                                    props.finding.config.mapConfig?.colorOptions
                                ) {
                                    newConfig.mapConfig.colorOptions = {
                                        ...props.finding.config.mapConfig
                                            .colorOptions,
                                    };
                                } else {
                                    newConfig.mapConfig.colorOptions = {
                                        borderShadow: false,
                                        fillColor: "",
                                        borderColor: "",
                                    };
                                }

                                newConfig.mapConfig.colorOptions.grayscale =
                                    checked;

                                props.onChange(newConfig);
                            }}
                            checked={
                                props.finding.config.mapConfig?.colorOptions
                                    ?.grayscale ?? false
                            }
                            width={26}
                            height={13}
                            offColor="#C4C4C4"
                            onColor="#3B82C9"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#7B7B7B"
                            onHandleColor="#3B82C9"
                            className={cx({
                                [mapConfigStyles.switchOn]:
                                    props.finding.config.mapConfig?.colorOptions
                                        ?.grayscale,
                            })}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default observer(NetworkOverMapSection);
