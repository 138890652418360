import React from "react";
import {
    CanvasSurvey,
    SurveyQuestionType,
    SurveyDropdownOption,
    SurveyQuestion,
    isRuleSurveyQuestion,
    CanvasElement,
    CanvasSubmitButton,
    InnerCanvasChanges,
} from "common/Canvas";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import styles from "./SurveyComponent.module.css";
import Slider from "rc-slider/lib/Slider";
import Select from "react-select";
import cx from "classnames";
import { Button } from "react-bootstrap";
import { toInteger } from "lodash";
import { insertRows } from "common/DataApi";
import { renderHandle } from "./utils";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import Portal from "common/Portal";
import { evaluateRuleConditions } from "common/Survey/RuleCondition";
import moment from "moment";
import remoteModuleId from "common/remoteModuleId";
import { surveyElementDefaultHeight } from "modules/canvas_page/Constants";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import Variables from "common/Variables";

interface SurveyComponentProps {
    canvasTreeStore: CanvasTreeStore;
    node: CanvasSurvey;
    live: boolean;
    currentModuleId: number | undefined;
    rootDataTestId: string;
    onExpandCard: (
        node: CanvasElement | CanvasSubmitButton | CanvasSurvey
    ) => void;
}

interface FormElement {
    value: string | number | undefined;
}

interface FormValues {
    [value: string]: FormElement;
}

function surveyQuestionToValue(
    question: SurveyQuestion,
    questions: SurveyQuestion[],
    outputVariableType: string,
    form: FormValues
): string | number | null {
    if (isRuleSurveyQuestion(question)) {
        if (question.linkedToId != null) {
            let linkedQuestion = questions.find(
                (q) => q.id === question.linkedToId
            );
            let linkedValue: string | number;
            if (linkedQuestion?.type === SurveyQuestionType.Slider) {
                if (!form[question.linkedToId]?.value) {
                    linkedValue = parseInt(linkedQuestion.min ?? "0");
                } else {
                    linkedValue = form[question.linkedToId].value!;
                }
            } else {
                linkedValue = form[question.linkedToId]?.value ?? "";
            }
            for (
                let ruleIndex = 0;
                ruleIndex < question.rules.length;
                ++ruleIndex
            ) {
                const rule = question.rules[ruleIndex];

                let evaluatedRuleConditions: boolean;
                try {
                    evaluatedRuleConditions = evaluateRuleConditions(
                        rule.ruleConditions.filter(
                            (condition) =>
                                condition.operation != null &&
                                condition.value != null
                        ),
                        linkedValue
                    );
                } catch (error) {
                    throw new Error(`Error in rule #${ruleIndex}: ${error}`);
                }
                if (evaluatedRuleConditions) {
                    if (outputVariableType === "float") {
                        if (rule.value === "") {
                            return null;
                        } else {
                            return Number(rule.value);
                        }
                    } else {
                        return rule.value;
                    }
                }
            }
        }
        return null;
    } else {
        if (
            question.type === SurveyQuestionType.Slider &&
            !form[question.id]?.value
        )
            return parseInt(question.min ?? "0");
        if (
            question.required &&
            form[question.id]?.value !== 0 &&
            !form[question.id]?.value
        )
            throw new Error(`Please fill all required questions`);
        if (question.type !== SurveyQuestionType.Slider) {
            if (form[question.id]?.value == null) {
                return null;
            } else {
                return form[question.id]?.value + "";
            }
        }
        return form[question.id]?.value ?? null;
    }
}

const SurveyComponent: React.FC<SurveyComponentProps> = ({
    node,
    canvasTreeStore,
    currentModuleId,
    onExpandCard,
}) => {
    const rootRef = React.useRef<HTMLDivElement>(null);

    const [form, setForm] = React.useState<FormValues>({});
    const [selectCompIsOpened, setSelectCompIsOpened] = React.useState<boolean>(
        false
    );
    const [toggle, setToggle] = React.useState<boolean>(false);

    const [streamStatus, setStreamStatus] = React.useState<PopupStatus | null>(
        null
    );
    const [streamMessage, setStreamMessage] = React.useState<string | null>(
        null
    );

    React.useEffect(() => {
        if (!rootRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (rootRef.current?.scrollHeight) {
                let newScaleY =
                    rootRef.current?.scrollHeight / surveyElementDefaultHeight;
                // Do not save changes if nothing changed
                if (
                    newScaleY !== node.shapeOptions?.desktop.scaleY ||
                    newScaleY !== node.shapeOptions?.mobile.scaleY
                ) {
                    let shapeOptions = {
                        desktop: {
                            scaleX: 1,
                            scaleY: newScaleY,
                        },
                        mobile: {
                            scaleX: 1,
                            scaleY: newScaleY,
                        },
                    };
                    // We use propagatedChanges to manually call
                    // saveChangesAction with skipHistory=true to avoid creating
                    // a history entry here, which would cause this bug:
                    // https://eisengardai.atlassian.net/browse/EIS-661?focusedCommentId=13908
                    let propagatedChanges: InnerCanvasChanges = {};
                    canvasTreeStore.updateNodeAction(
                        node.id,
                        {
                            shapeOptions: shapeOptions,
                        },
                        undefined,
                        undefined,
                        undefined,
                        propagatedChanges
                    );
                    canvasTreeStore.saveChangesAction(
                        propagatedChanges,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true // skipHistory
                    );
                }
            }
        });
        resizeObserver.observe(rootRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [node, canvasTreeStore]);

    React.useEffect(() => {
        setForm((form) => {
            let answers: FormValues = {};

            for (let i = 0; i < node.questions.length; i++) {
                answers[node.questions[i].id] = {
                    value:
                        form[node.questions[i].id]?.value ??
                        (node.questions[i].type === SurveyQuestionType.Slider
                            ? Number(node.questions[i].min)
                            : ""),
                };
            }

            return answers;
        });
    }, [node]);

    const _submitSurvey = async () => {
        if (node.backendOutput.tableOption != null) {
            await Variables(
                node.backendOutput.tableOption.data_table_idx,
                remoteModuleId ?? currentModuleId
            ).update();

            let row: { [key: string]: string | number | null } = {};
            row["Timestamp"] = moment().seconds(0).unix();
            for (
                let questionIndex = 0;
                questionIndex < node.questions.length;
                ++questionIndex
            ) {
                let question = node.questions[questionIndex];
                try {
                    row[
                        question.columnName ?? question.question
                    ] = surveyQuestionToValue(
                        question,
                        node.questions,
                        Variables(
                            node.backendOutput.tableOption.data_table_idx,
                            remoteModuleId ?? currentModuleId
                        ).dataVariables[questionIndex + 1]?.type ?? "str",
                        form
                    );
                } catch (error) {
                    setStreamStatus(PopupStatus.Error);
                    setStreamMessage(error.message.toString());
                    return;
                }
            }

            insertRows(
                node.backendOutput.tableOption,
                [row],
                remoteModuleId ?? currentModuleId
            ).then(
                (res) => {
                    setStreamStatus(PopupStatus.Success);
                    setStreamMessage("Form submitted");
                    setForm({});
                },
                (err) => {
                    setStreamStatus(PopupStatus.Error);
                    setStreamMessage(`Form error ${err}`);
                }
            );
        }

        if (node.links) {
            onExpandCard(node);
        }
    };

    const handleCloseMenuOnScroll = () => {
        // forcing select menu re-rendering on scroll
        if (selectCompIsOpened) {
            setToggle(!toggle);
        }
        // as expected do not close the menu on scroll
        return false;
    };

    return (
        <div
            style={{
                width: "100%",
                position: "relative",
            }}
        >
            <div
                ref={rootRef}
                id={`survey-${node.backendOutput?.dataScopeOption?.label}`}
                style={{
                    width: `calc(100% / ${canvasTreeStore.scale})`,
                    height: `calc(100% / ${canvasTreeStore.scale})`,
                    transformOrigin: "left top",
                    transform: `scale(${canvasTreeStore.scale})`,
                    display: "flex",
                    flexDirection: "column",
                    padding: 20,
                    paddingTop: 0,
                    position: "relative",
                }}
            >
                {node.questions.map((question: SurveyQuestion) => {
                    return (
                        <>
                            {!isRuleSurveyQuestion(question) && (
                                <p
                                    className={styles.QuestionHeader}
                                    id={`survey-${node.backendOutput?.dataScopeOption?.label}-header-${question.id}`}
                                >
                                    <span
                                        style={{
                                            position: "absolute",
                                            left: -10,
                                        }}
                                    >
                                        {question.required ? "*" : null}
                                    </span>
                                    {question.question}
                                </p>
                            )}
                            {question.type === SurveyQuestionType.Slider && (
                                <div
                                    style={{
                                        paddingTop: 13,
                                        height: 30,
                                    }}
                                >
                                    <div className={styles.sliderWrapper}>
                                        <Slider
                                            handle={(props) => {
                                                props.style = {
                                                    backgroundColor: "#3474B2",
                                                    borderColor: "#ffffff",
                                                    height: 15,
                                                    width: 15,
                                                };
                                                return renderHandle(props);
                                            }}
                                            min={toInteger(question.min) ?? 0}
                                            max={toInteger(question.max) ?? 100}
                                            step={1}
                                            onChange={(value: number) => {
                                                setForm((form) => ({
                                                    ...form,
                                                    [question.id]: {
                                                        value: value,
                                                    },
                                                }));
                                            }}
                                            value={toInteger(
                                                form[question.id]?.value
                                            )}
                                        />
                                    </div>
                                    <div className={styles.sliderLegend}>
                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: 10,
                                            }}
                                        >
                                            {question.min}
                                        </p>
                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: 10,
                                                justifySelf: "end",
                                            }}
                                        >
                                            {question.max}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {question.type === SurveyQuestionType.Dropdown && (
                                <Select
                                    onMenuOpen={() => {
                                        setSelectCompIsOpened(true);
                                    }}
                                    onMenuClose={() => {
                                        setSelectCompIsOpened(false);
                                    }}
                                    closeMenuOnScroll={handleCloseMenuOnScroll.bind(
                                        this
                                    )}
                                    isSearchable={
                                        mobileBreakpoint() ? false : true
                                    }
                                    menuPortalTarget={document.body}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            (evt.key === "Enter" ||
                                                evt.key === "Escape") &&
                                            !evt.shiftKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            minHeight: 30,
                                            maxHeight: 30,
                                            fontFamily:
                                                "Montserrat, Manrope, Roboto",
                                        }),
                                        valueContainer: (provided) => ({
                                            ...provided,
                                            fontSize: 12,
                                            minHeight: 30,
                                            maxHeight: 30,
                                            paddingTop: 0,
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: "4px",
                                            minHeight: 30,
                                            maxHeight: 30,
                                            padding: 0,
                                        }),
                                        input: (provided) => ({
                                            ...provided,
                                        }),
                                        indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                        }),

                                        indicatorsContainer: (provided) => ({
                                            ...provided,
                                            minHeight: 30,
                                            maxHeight: 30,
                                        }),
                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            "& svg": {
                                                width: 15,
                                                height: 15,
                                            },
                                        }),
                                        option: (provided) => ({
                                            ...provided,
                                            color: "black",
                                            backgroundColor: "white",
                                            "&:hover": {
                                                backgroundColor: "#eaf0fa",
                                            },
                                        }),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "#eaf0fa",
                                        },
                                    })}
                                    options={question.options.map(
                                        (opt: SurveyDropdownOption) => ({
                                            label: opt.value,
                                            value: opt.value,
                                        })
                                    )}
                                    onChange={(opt) => {
                                        setForm((form) => ({
                                            ...form,
                                            [question.id]: {
                                                value: opt?.value,
                                            },
                                        }));
                                    }}
                                    value={{
                                        label: form[question.id]?.value,
                                        value: form[question.id]?.value,
                                    }}
                                />
                            )}
                            {question.type === SurveyQuestionType.Text && (
                                <textarea
                                    placeholder="Type here..."
                                    className={styles.inputText}
                                    style={{
                                        paddingLeft: 9,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        height: 30,
                                        lineHeight: "1em",
                                        fontSize: 12,
                                        fontFamily:
                                            "Montserrat, Manrope, Roboto",
                                    }}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            (evt.key === "Enter" ||
                                                evt.key === "Escape") &&
                                            !evt.shiftKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setForm((form) => ({
                                            ...form,
                                            [question.id]: {
                                                value: e.target.value,
                                            },
                                        }));
                                    }}
                                    value={form[question.id]?.value ?? ""}
                                />
                            )}
                            {question.type === SurveyQuestionType.LongText && (
                                <textarea
                                    placeholder="Type here..."
                                    className={cx(
                                        styles.inputLongText,
                                        "element"
                                    )}
                                    style={{
                                        paddingLeft: 9,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        height: 90,
                                        lineHeight: "1em",
                                        fontSize: 12,
                                        fontFamily:
                                            "Montserrat, Manrope, Roboto",
                                    }}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            (evt.key === "Enter" ||
                                                evt.key === "Escape") &&
                                            !evt.shiftKey
                                        ) {
                                            evt.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setForm((form) => ({
                                            ...form,
                                            [question.id]: {
                                                value: e.target.value,
                                            },
                                        }));
                                    }}
                                    value={form[question.id]?.value ?? ""}
                                />
                            )}
                        </>
                    );
                })}
                <div className={styles.btnWrapper}>
                    <Button
                        className="my-primary"
                        style={{
                            marginTop: 60,
                            height: 40,
                            width: 120,
                            fontSize: 14,
                            fontFamily: "Montserrat, Manrope, Roboto",
                        }}
                        onClick={_submitSurvey}
                    >
                        Submit
                    </Button>
                </div>
            </div>
            {streamStatus && (
                <Portal rootNode={document.body}>
                    <StatusPopup
                        closeAfter={3000}
                        status={streamStatus!}
                        message={streamMessage!}
                        onClose={() => {
                            setStreamStatus(null);
                            setStreamMessage(null);
                        }}
                    />
                </Portal>
            )}
        </div>
    );
};

export default SurveyComponent;
