import React from "react";
import Accordion from "react-bootstrap/Accordion";
import cx from "classnames";
import styles from "./StylingSection.module.css";
import commonStyles from "../ChartsRibbon.module.css";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import Switch from "react-switch";
import "rc-slider/assets/index.css";
import ColorPicker from "common/ColorPicker";
import Finding, {
    isBarChart,
    isScatterPlot,
    isBubble,
    isDensity2d,
    isCorrelogram,
    isHeatmap,
    isHistogram,
    isDensityPlot,
    isBoxPlot,
    isViolinPlot,
    isRidgeline,
    isPieChart,
    isTreemap,
    isDendrogram,
    isRadar,
    isWordCloud,
    isSentimentAnalysis,
    isFunnelPlot,
    isLeadersLaggers,
    isLinePlot,
    isGanttChart,
    isLeversBenchmarking,
    isKanbanBoard,
    isNetwork,
    isHoldOutPredictionChart,
    isLeverOutcomeChart,
    isMap,
    isNetworkOverMap,
    isAiCopilot,
    defaultDendrogramEdgeColor,
    defaultDendrogramNodeRadius,
    defaultBubbleD3MinRadius,
    defaultBubbleD3MaxRadius,
    defaultDensity2dBinSize,
    defaultDensity2dMinColor,
    defaultDensity2dMaxColor,
    defaultDensity2dType,
    Density2dTypeOption,
    density2dTypeOptions,
    defaultHeatmapD3MinColor,
    defaultHeatmapD3MaxColor,
    defaultDensityChartColor,
    defaultDensityStrokeColor,
    defaultBoxPlotChartColor,
    defaultBoxPlotStrokeColor,
    defaultViolinPlotChartColor,
    defaultViolinPlotStrokeColor,
    defaultRidgelineChartColor,
    defaultRidgelineStrokeColor,
    MapFinding,
    NetworkFinding,
    lineTypeOptions,
    LineType,
    LineTypeOption,
    areaTypeOptions,
    AreaType,
    AreaTypeOption,
    dateTimeIntervalOptions,
    DateTimeIntervalOption,
} from "common/Finding";
import ColorExpressionsSelector, {
    defaultExpressions,
} from "modules/canvas_page/ColorExpressionsSelector";
import StringUtils from "common/utilities/StringUtils";
import { mainStyle } from "common/MainStyle";
import switchStyleProps from "../SwitchStyleProps";
import { colorList } from "common/graphics/LineColors";
import Select, { createFilter } from "react-select";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import { Options, BarChartThemeOption } from "common/graphics/ThemeSelector";
import { getDefaultColorsByTheme } from "common/graphics/BarChartTheme";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { getTargetValue } from "common/Canvas";
import SharedBoxesStore from "modules/canvas_page/SharedBoxesStore";
import { observer } from "mobx-react";
import StylingMenuOption from "./StylingMenuOption";
import Input from "./Input";
import SliderInput from "./SliderInput";
import NetworkSection from "./NetworkSection";
import WordCloudSection from "./WordCloudSection";
import MapSection from "./MapSection";
import NetworkOverMapSection from "./NetworkOverMapSection";
import CorrelogramSection from "./CorrelogramSection";
import AiCopilotSection from "./AiCopilotSection";
import { ganttPreviewColors } from "common/graphics/v2/ganttPreviewColors";
import { previewColors } from "common/graphics/v2/previewColors";
import HorizontalLinesStylingOption from "./HorizontalLinesStylingOption";
import LeaderboardSection from "./LeaderboardSection";
import LogosEditor from "./LeaderboardSection/LogosEditor";
import Variables from "common/Variables";
import LinePlotSection from "./LinePlotSection";
import { defaultFontFamily, fontFamilies } from "modules/canvas_page/Constants";
import StringOption from "common/StringOption";
import { dateTimeFormatOptionsForLevels } from "common/utilities/TimeFormatUtils";
// import LinePlotTooltipConfig from "./LinePlotSection/TooltipConfig";

interface Props {
    currentModuleId?: number;
    canvasTreeStore: CanvasTreeStore;
    finding: Finding;
    onChange: (finding: Finding, updateData?: boolean) => void;
    onClearEditing: () => void;
    onAddHorizontalLines: (finding: Finding, updateData?: boolean) => void;
    dashboardId: string;
}

function StylingSection(props: Props) {
    // let customSelectStylesSmall = getSmallSelectStyle(10);

    const onChangeConfig = (
        config: Finding["config"],
        updateData?: boolean
    ) => {
        let newFinding: Finding = {
            ...props.finding,
            config: config,
        };
        props.onChange(newFinding, updateData);
    };
    let options = Array.isArray(props.finding.content?.data)
        ? props.finding.content?.data?.map(
              (item: { name: string }, index: number) => ({
                  label: item.name,
                  value: index,
              })
          )
        : [];
    let xAxisType = "";
    let yAxisType = "";

    if (isLinePlot(props.finding) && options) {
        Variables(
            props.finding?.config.dataScope?.value,
            props.currentModuleId
        ).variableOptions.forEach((option) => {
            if (option?.label) {
                if (option.label === options[0].label) {
                    xAxisType = option.type;
                }
                // We can put the same variable to the x and y axis, so
                // we shouldn't put an "else" here
                if (option.label === options[1].label) {
                    yAxisType = option.type;
                }
            }
        });
    }

    const buildStatusExpressionsSelector = () => {
        return (
            isBarChart(props.finding) && (
                <div
                    className={styles.optionContainer}
                    style={{ flexDirection: "column", alignItems: "start" }}
                >
                    <span
                        className={styles.optionName}
                        style={{ marginBottom: "5px" }}
                    >
                        Theme
                    </span>
                    <ColorExpressionsSelector
                        variables={options}
                        expressionContainerStyle={{ marginLeft: 0 }}
                        nodeLinkOptions={Array.from(
                            props.canvasTreeStore.canvasTreeState.values()
                        )
                            .map((item) => ({
                                label: item.outerId,
                                value: item.id,
                                isCloneInput: false,
                                target: getTargetValue(item),
                            }))
                            .concat(
                                SharedBoxesStore.sharedBoxes(
                                    props.canvasTreeStore.canvasId as number
                                ).map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                    isCloneInput: true,
                                    target: getTargetValue(item.item.box),
                                }))
                            )}
                        selectStyles={{
                            ...selectStyles,
                            container: (base) => {
                                return {
                                    ...base,
                                    minWidth: "80px",
                                    marginLeft: "5px",
                                    height: "38px",
                                };
                            },
                        }}
                        upDownButtonsClass={commonStyles.upDownButtons}
                        spanStyle={{
                            fontFamily: "Roboto",
                            color: "#333333",
                            fontSize: "16px",
                        }}
                        statusExpressions={
                            props.finding.config?.statusExpressions ??
                            defaultExpressions
                        }
                        onChange={(expressions) => {
                            let newConfig = {
                                ...config,
                                statusExpressions: expressions,
                            };
                            onChangeConfig(newConfig);
                        }}
                    />
                </div>
            )
        );
    };

    let [stylingOptionSelected, setStylingOptionSelected] = React.useState(
        StylingMenuOption.None
    );
    let config = props.finding.config as Finding["config"];
    let content = props.finding.content as Finding["content"];
    const varyByData = content.varyByData;
    const { sideBySideVariableIndex } = config;
    let variables = content.data;

    if (sideBySideVariableIndex != null) {
        variables = varyByData;
    }

    let selectStyles = getCustomSelectStyleForDataSection(14, false);

    return (
        <Accordion
            activeKey={String(stylingOptionSelected)}
            onSelect={(event: any) => {
                setStylingOptionSelected(Number(event));
            }}
        >
            {!isFunnelPlot(props.finding) &&
                !isHoldOutPredictionChart(props.finding) &&
                !isPieChart(props.finding) &&
                !isTreemap(props.finding) &&
                !isDendrogram(props.finding) &&
                !isRadar(props.finding) &&
                !isWordCloud(props.finding) &&
                !isLeadersLaggers(props.finding) &&
                !isKanbanBoard(props.finding) &&
                !isNetwork(props.finding) &&
                !isMap(props.finding) &&
                !isNetworkOverMap(props.finding) &&
                !isCorrelogram(props.finding) &&
                !isAiCopilot(props.finding) && 
                (
                    <>
                        {!isLeverOutcomeChart(props.finding) && (
                            <Accordion.Toggle
                                eventKey={String(StylingMenuOption.Xaxis)}
                                className={commonStyles.editMenuAdvancedOption}
                                >
                                X axis
                                <ChevronIcon
                                    className={cx(
                                        commonStyles.editMenuAdvancedOptionIcon,
                                        {
                                            [commonStyles.chevronOpen]:
                                                stylingOptionSelected ===
                                                StylingMenuOption.Xaxis,
                                        }
                                    )}
                                />
                            </Accordion.Toggle>
                        )}

                       
                        <Accordion.Collapse
                            eventKey={String(StylingMenuOption.Xaxis)}
                        >
                            <div className={styles.sectionContainer}>
                                <div className={styles.optionContainer}>
                                    <span className={styles.optionName}>
                                        X axis labels
                                    </span>
                                    <Switch
                                        checked={config.showXAxisName ?? true}
                                        {...switchStyleProps}
                                        className={
                                            config.showXAxisName
                                                ? "react-switch-on"
                                                : "react-switch-off"
                                        }
                                        onChange={() => {
                                            let checked = !(
                                                config.showXAxisName ?? true
                                            );
                                            let newConfig = {
                                                ...config,
                                                showXAxisName: checked,
                                            };
                                            onChangeConfig(newConfig);
                                        }}
                                    />
                                </div>
                                {isLinePlot(props.finding) && (
                                    <LinePlotSection
                                        finding={props.finding}
                                        axis="xAxis"
                                        axisType={xAxisType}
                                        onChangeConfig={onChangeConfig}
                                    />
                                )}

                                {isGanttChart(props.finding) && (
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            Y axis labels
                                        </span>
                                        <Switch
                                            checked={
                                                config.showYAxisName ?? true
                                            }
                                            {...switchStyleProps}
                                            className={
                                                config.showYAxisName
                                                    ? "react-switch-on"
                                                    : "react-switch-off"
                                            }
                                            onChange={() => {
                                                let checked = !(
                                                    config.showYAxisName ?? true
                                                );
                                                let newConfig = {
                                                    ...config,
                                                    showYAxisName: checked,
                                                };
                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                )}
                                {(isScatterPlot(props.finding) ||
                                    isBubble(props.finding) ||
                                    isRidgeline(props.finding) ||
                                    (isLinePlot(props.finding) &&
                                        xAxisType !== "datetime") ||
                                    isDensity2d(props.finding)) && (
                                    <>
                                        <div className={styles.optionContainer}>
                                            <span className={styles.optionName}>
                                                X-range
                                            </span>
                                            <Input
                                                value={config.minXRange ?? ""}
                                                onChange={(
                                                    value: string | number
                                                ) => {
                                                    let newConfig = {
                                                        ...config,
                                                    };
                                                    if (
                                                        StringUtils.isNumber(
                                                            value
                                                        )
                                                    ) {
                                                        newConfig.minXRange = Number(
                                                            value
                                                        );
                                                    } else {
                                                        newConfig.minXRange = null;
                                                    }

                                                    onChangeConfig(newConfig);
                                                }}
                                            />
                                            <span
                                                className={styles.defaultText}
                                                style={{
                                                    marginLeft: 5,
                                                    marginRight: 5,
                                                }}
                                            >
                                                to
                                            </span>
                                            <Input
                                                value={config.maxXRange ?? ""}
                                                onChange={(
                                                    value: string | number
                                                ) => {
                                                    let newConfig = {
                                                        ...config,
                                                    };
                                                    if (
                                                        StringUtils.isNumber(
                                                            value
                                                        )
                                                    ) {
                                                        newConfig.maxXRange = Number(
                                                            value
                                                        );
                                                    } else {
                                                        newConfig.maxXRange = null;
                                                    }

                                                    onChangeConfig(newConfig);
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                                {(isScatterPlot(props.finding) ||
                                    isBubble(props.finding) ||
                                    isDensity2d(props.finding) ||
                                    isDensityPlot(props.finding) ||
                                    (isLinePlot(props.finding) &&
                                        (xAxisType === "int" ||
                                            xAxisType === "float" ||
                                            xAxisType === "datetime")) ||
                                    isRidgeline(props.finding) ||
                                    isHistogram(props.finding)) && (
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            X interval
                                        </span>
                                        <Input
                                            value={
                                                config.ticksAndLabels?.x
                                                    ?.interval ?? null
                                            }
                                            onChange={(
                                                value: string | number
                                            ) => {
                                                let newConfig = {
                                                    ...config,
                                                };
                                                if (
                                                    StringUtils.isNumber(value)
                                                ) {
                                                    newConfig.ticksAndLabels = {
                                                        ...(config?.ticksAndLabels ??
                                                            {}),
                                                        x: {
                                                            ...(config
                                                                ?.ticksAndLabels
                                                                ?.x ?? {}),
                                                            interval: Number(
                                                                value
                                                            ),
                                                        },
                                                    };
                                                } else if (value === "") {
                                                    newConfig.ticksAndLabels = {
                                                        ...(config?.ticksAndLabels ??
                                                            {}),
                                                        x: {
                                                            ...(config
                                                                ?.ticksAndLabels
                                                                ?.x ?? {}),
                                                            interval: null,
                                                        },
                                                    };
                                                }

                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                )}
                                {isLinePlot(props.finding) &&
                                    xAxisType === "datetime" && (
                                        <div className={styles.optionContainer}>
                                            <span className={styles.optionName}>
                                                Interval unit
                                            </span>
                                            <Select
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                styles={{
                                                    ...selectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        width: "150px",
                                                        height: "38px",
                                                    }),
                                                }}
                                                options={
                                                    dateTimeIntervalOptions
                                                }
                                                value={
                                                    dateTimeIntervalOptions.find(
                                                        (option) =>
                                                            option.value ===
                                                            (config
                                                                .ticksAndLabels
                                                                ?.x
                                                                ?.intervalUnit ??
                                                                "day")
                                                    )!
                                                }
                                                onChange={(newValue) => {
                                                    let value = (newValue as DateTimeIntervalOption)
                                                        .value;
                                                    let newConfig = {
                                                        ...config,
                                                    };
                                                    newConfig.ticksAndLabels = {
                                                        ...(config?.ticksAndLabels ??
                                                            {}),
                                                        x: {
                                                            ...(config
                                                                ?.ticksAndLabels
                                                                ?.x ?? {}),
                                                            intervalUnit: value,
                                                        },
                                                    };
                                                    newConfig.formatAxises = {
                                                        ...props.finding.config
                                                            .formatAxises,
                                                        xAxisDate:
                                                            dateTimeFormatOptionsForLevels[
                                                                value
                                                            ][0],
                                                    };
                                                    onChangeConfig(
                                                        newConfig,
                                                        true
                                                    );
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </div>
                                    )}
                            </div>
                        </Accordion.Collapse>
                        {!isGanttChart(props.finding) &&
                            !isNetwork(props.finding) &&
                            !isMap(props.finding) &&
                            !isHoldOutPredictionChart(props.finding) &&
                            !isNetworkOverMap(props.finding) &&
                            !isCorrelogram(props.finding) &&
                            !isLeverOutcomeChart(props.finding) &&
                            !isAiCopilot(props.finding) && (
                                <Accordion.Toggle
                                    eventKey={String(StylingMenuOption.Yaxis)}
                                    className={
                                        commonStyles.editMenuAdvancedOption
                                    }
                                >
                                    Y axis
                                    <ChevronIcon
                                        className={cx(
                                            commonStyles.editMenuAdvancedOptionIcon,
                                            {
                                                [commonStyles.chevronOpen]:
                                                    stylingOptionSelected ===
                                                    StylingMenuOption.Yaxis,
                                            }
                                        )}
                                    />
                                </Accordion.Toggle>
                            )}
                    </>
                )}
            <Accordion.Collapse eventKey={String(StylingMenuOption.Yaxis)}>
                <div className={styles.sectionContainer}>                  
                    {!isLeverOutcomeChart(props.finding) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Y axis name</span>
                            <Switch
                                checked={config.showYAxisName ?? false}
                                {...switchStyleProps}
                                className={
                                    config.showYAxisName
                                        ? "react-switch-on"
                                        : "react-switch-off"
                                }
                                onChange={() => {
                                    let checked = !(config.showYAxisName ?? false);
                                    let newConfig = {
                                        ...config,
                                        showYAxisName: checked,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}

                    {isLinePlot(props.finding) && (
                        <LinePlotSection
                            finding={props.finding}
                            axis="yAxis"
                            axisType={yAxisType}
                            onChangeConfig={onChangeConfig}
                        />
                    )}
                    {!isRidgeline(props.finding) && 
                    !isLeverOutcomeChart(props.finding) &&
                    !isHeatmap(props.finding) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Y-range</span>
                            <Input
                                value={config.minYRange ?? ""}
                                onChange={(value: string | number) => {
                                    let newConfig = {
                                        ...config,
                                    };
                                    if (StringUtils.isNumber(value)) {
                                        newConfig.minYRange = Number(value);
                                    } else {
                                        newConfig.minYRange = null;
                                    }

                                    onChangeConfig(newConfig);
                                }}
                            />
                            <span
                                className={styles.defaultText}
                                style={{ marginLeft: 5, marginRight: 5 }}
                            >
                                to
                            </span>
                            <Input
                                value={config.maxYRange ?? ""}
                                onChange={(value: string | number) => {
                                    let newConfig = {
                                        ...config,
                                    };
                                    if (StringUtils.isNumber(value)) {
                                        newConfig.maxYRange = Number(value);
                                    } else {
                                        newConfig.maxYRange = null;
                                    }

                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {(isScatterPlot(props.finding) ||
                        isBubble(props.finding) ||
                        isDensity2d(props.finding) ||
                        isHistogram(props.finding) ||
                        isBarChart(props.finding) ||
                        (isLinePlot(props.finding) &&
                            (yAxisType === "int" || yAxisType === "float")) ||
                        isDensityPlot(props.finding) ||
                        isBoxPlot(props.finding) ||
                        isViolinPlot(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>
                                Y interval
                            </span>
                            <Input
                                value={
                                    config.ticksAndLabels?.y?.interval ?? null
                                }
                                onChange={(value: string | number) => {
                                    let newConfig = {
                                        ...config,
                                    };
                                    if (StringUtils.isNumber(value)) {
                                        newConfig.ticksAndLabels = {
                                            ...(config?.ticksAndLabels ?? {}),
                                            y: {
                                                ...(config?.ticksAndLabels?.y ??
                                                    {}),
                                                interval: Number(value),
                                            },
                                        };
                                    } else if (value === "") {
                                        newConfig.ticksAndLabels = {
                                            ...(config?.ticksAndLabels ?? {}),
                                            y: {
                                                ...(config?.ticksAndLabels?.y ??
                                                    {}),
                                                interval: null,
                                            },
                                        };
                                    }

                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                </div>
            </Accordion.Collapse>
            {isGanttChart(props.finding) && (
                <>
                    <Accordion.Toggle
                        eventKey={String(StylingMenuOption.VariablesColor)}
                        className={commonStyles.editMenuAdvancedOption}
                    >
                        Variables Color
                        <ChevronIcon
                            className={cx(
                                commonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [commonStyles.chevronOpen]:
                                        stylingOptionSelected ===
                                        StylingMenuOption.VariablesColor,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(StylingMenuOption.VariablesColor)}
                    >
                        <div className={styles.sectionContainer}>
                            {props.finding.content.data[0].value.map(
                                (item, index) => (
                                    <div
                                        className={styles.optionContainer}
                                        key={index}
                                    >
                                        <span className={styles.optionName}>
                                            {item}
                                        </span>
                                        <ColorPicker
                                            enableAlpha
                                            tooltip={`${item} color`}
                                            value={
                                                config?.nameColorMapping?.[
                                                    index
                                                ] ??
                                                (props.finding.content.data[0]
                                                    .variableIndex == null
                                                    ? ganttPreviewColors[
                                                          index %
                                                              ganttPreviewColors.length
                                                      ]
                                                    : colorList[
                                                          index %
                                                              colorList.length
                                                      ])
                                            }
                                            onChange={(newValue) => {
                                                let newConfig = {
                                                    ...config,
                                                    nameColorMapping: {
                                                        ...config.nameColorMapping,
                                                        [index]: newValue,
                                                    },
                                                };
                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </Accordion.Collapse>
                </>
            )}
            {(isPieChart(props.finding) ||
                isTreemap(props.finding) ||
                isRadar(props.finding) ||
                isDendrogram(props.finding)) && (
                <>
                    <Accordion.Toggle
                        eventKey={String(StylingMenuOption.VariablesColor)}
                        className={commonStyles.editMenuAdvancedOption}
                    >
                        Variables Color
                        <ChevronIcon
                            className={cx(
                                commonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [commonStyles.chevronOpen]:
                                        stylingOptionSelected ===
                                        StylingMenuOption.VariablesColor,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(StylingMenuOption.VariablesColor)}
                    >
                        <div className={styles.sectionContainer}>
                            {(props.finding.content.groupNames ?? [""]).map(
                                (groupName, groupIndex) => (
                                    <React.Fragment key={groupIndex}>
                                        {groupName && (
                                            <div
                                                className={
                                                    styles.optionContainer
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles.optionName
                                                    }
                                                >
                                                    {groupName}
                                                </span>
                                            </div>
                                        )}
                                        {variables.map(
                                            (
                                                item: Finding["content"]["data"],
                                                index: number
                                            ) => (
                                                <div
                                                    className={
                                                        styles.optionContainer
                                                    }
                                                    key={index}
                                                >
                                                    <span
                                                        className={
                                                            styles.optionName
                                                        }
                                                    >
                                                        {item.name}
                                                    </span>
                                                    <ColorPicker
                                                        inPopup
                                                        openInClickPosition
                                                        topAppearance
                                                        enableAlpha
                                                        tooltip={`${item.name} color`}
                                                        value={
                                                            config
                                                                .nameColorMapping?.[
                                                                item.originalName ??
                                                                    groupIndex *
                                                                        props
                                                                            .finding
                                                                            .content
                                                                            .data
                                                                            .length +
                                                                        index
                                                            ] ??
                                                            (item.variableIndex ==
                                                            null
                                                                ? previewColors[
                                                                      ((groupIndex *
                                                                          props
                                                                              .finding
                                                                              .content
                                                                              .data
                                                                              .length +
                                                                          index) %
                                                                          previewColors.length) %
                                                                          previewColors.length
                                                                  ]
                                                                : colorList[
                                                                      (groupIndex *
                                                                          props
                                                                              .finding
                                                                              .content
                                                                              .data
                                                                              .length +
                                                                          index) %
                                                                          colorList.length
                                                                  ])
                                                        }
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            let newConfig = {
                                                                ...config,
                                                                nameColorMapping: {
                                                                    ...config.nameColorMapping,
                                                                    [item.originalName ??
                                                                    groupIndex *
                                                                        props
                                                                            .finding
                                                                            .content
                                                                            .data
                                                                            .length +
                                                                        index]: newValue,
                                                                },
                                                            };
                                                            onChangeConfig(
                                                                newConfig
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </React.Fragment>
                                )
                            )}
                        </div>
                    </Accordion.Collapse>
                </>
            )}
            {!isKanbanBoard(props.finding) &&
                !isWordCloud(props.finding) &&
                !isNetwork(props.finding) &&
                !isMap(props.finding) &&
                !isNetworkOverMap(props.finding) &&
                !isCorrelogram(props.finding) &&
                !isLeadersLaggers(props.finding) &&
                !isLeverOutcomeChart(props.finding) &&
                !isAiCopilot(props.finding) && (
                    <Accordion.Toggle
                        eventKey={String(StylingMenuOption.Chart)}
                        className={commonStyles.editMenuAdvancedOption}
                    >
                        Chart
                        <ChevronIcon
                            className={cx(
                                commonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [commonStyles.chevronOpen]:
                                        stylingOptionSelected ===
                                        StylingMenuOption.Chart,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                )}
            <Accordion.Collapse eventKey={isLeverOutcomeChart(props.finding)? "0" : String(StylingMenuOption.Chart)}>
                <div className={styles.sectionContainer}>
                    {/* (isBarChart(props.finding) ||
                        isLinePlot(props.finding) ||
                        isScatterPlot(props.finding) ||
                        isBubble(props.finding) ||
                        isDensity2d(props.finding)) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>d3</span>
                                <Switch
                                    checked={config.d3 ?? false}
                                    {...switchStyleProps}
                                    onChange={() => {
                                        let checked = !(config.d3 ?? false);
                                        let newConfig = {
                                            ...config,
                                            d3: checked,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    ) */}
                    {isLinePlot(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Line Type
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    styles={{
                                        ...selectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "150px",
                                            height: "38px",
                                        }),
                                    }}
                                    options={lineTypeOptions}
                                    value={
                                        lineTypeOptions.find(
                                            (option) =>
                                                option.value ===
                                                (config.lineType ??
                                                    LineType.Solid)
                                        )!
                                    }
                                    onChange={(newValue) => {
                                        let value = (newValue as LineTypeOption)
                                            .value;
                                        if (config?.lineType !== value) {
                                            let newConfig = {
                                                ...config,
                                                lineType: value,
                                            };
                                            onChangeConfig(newConfig);
                                        }
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Area Type
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    styles={{
                                        ...selectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "150px",
                                            height: "38px",
                                        }),
                                    }}
                                    options={areaTypeOptions}
                                    value={
                                        areaTypeOptions.find(
                                            (option) =>
                                                option.value ===
                                                (!config.isArea
                                                    ? AreaType.None
                                                    : config.gradientArea
                                                    ? AreaType.Gradient
                                                    : AreaType.Fill)
                                        )!
                                    }
                                    onChange={(newValue) => {
                                        let value = (newValue as AreaTypeOption)
                                            .value;
                                        let isArea = value !== AreaType.None;
                                        let gradientArea =
                                            value === AreaType.Gradient;
                                        if (
                                            config?.isArea !== isArea ||
                                            config.gradientArea !== gradientArea
                                        ) {
                                            let newConfig = {
                                                ...config,
                                                isArea: isArea,
                                                gradientArea: gradientArea,
                                            };
                                            onChangeConfig(newConfig);
                                        }
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            {config.isArea && (
                                <div className={styles.optionContainer}>
                                    <span className={styles.optionName}>
                                        Stacked
                                    </span>
                                    <Switch
                                        checked={config.stacked ?? false}
                                        {...switchStyleProps}
                                        className={
                                            config.stacked
                                                ? "react-switch-on"
                                                : "react-switch-off"
                                        }
                                        onChange={() => {
                                            let checked = !(
                                                config.stacked ?? false
                                            );
                                            let newConfig = {
                                                ...config,
                                                stacked: checked,
                                            };
                                            onChangeConfig(newConfig);
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {isBarChart(props.finding) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Stacked</span>
                            <Switch
                                checked={config.stacked ?? false}
                                {...switchStyleProps}
                                className={
                                    config.stacked
                                        ? "react-switch-on"
                                        : "react-switch-off"
                                }
                                onChange={() => {
                                    let checked = !(config.stacked ?? false);
                                    let newConfig = {
                                        ...config,
                                        stacked: checked,
                                        showAsDots:
                                            config.showAsDots && !checked,
                                        circular: config.circular && !checked,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}

                    {isBarChart(props.finding) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Circular</span>
                            <Switch
                                checked={config.circular ?? false}
                                {...switchStyleProps}
                                className={
                                    config.circular
                                        ? "react-switch-on"
                                        : "react-switch-off"
                                }
                                onChange={() => {
                                    let checked = !(config.circular ?? false);
                                    let newConfig = {
                                        ...config,
                                        circular: checked,
                                        showAsDots:
                                            config.showAsDots && !checked,
                                        stacked: config.stacked && !checked,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {isBarChart(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Show as dot
                                </span>
                                <Switch
                                    checked={config.showAsDots ?? false}
                                    {...switchStyleProps}
                                    className={
                                        config.showAsDots
                                            ? "react-switch-on"
                                            : "react-switch-off"
                                    }
                                    onChange={() => {
                                        let checked = !(
                                            config.showAsDots ?? false
                                        );
                                        let newConfig = {
                                            ...config,
                                            showAsDots: checked,
                                            stacked: config.stacked && !checked,
                                            circular:
                                                config.circular && !checked,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            {config.showAsDots && (
                                <>
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            Line color
                                        </span>
                                        <ColorPicker
                                            inPopup
                                            openInClickPosition
                                            enableAlpha
                                            tooltip="Line color"
                                            value={
                                                config?.lineColor ??
                                                (props.finding.content.data[0]
                                                    .variableIndex == null
                                                    ? "#d1d1d1"
                                                    : colorList[1])
                                            }
                                            onChange={(newValue) => {
                                                let newConfig = {
                                                    ...config,
                                                    lineColor: newValue,
                                                };
                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            Dot radius
                                        </span>
                                        <Input
                                            value={config?.dotRadius ?? 5}
                                            onChange={(value) => {
                                                let newConfig = {
                                                    ...config,
                                                };
                                                if (
                                                    StringUtils.isNumber(value)
                                                ) {
                                                    newConfig.dotRadius = Number(
                                                        value
                                                    );
                                                } else {
                                                    newConfig.dotRadius = null;
                                                }
                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            Dash line
                                        </span>
                                        <Switch
                                            checked={config.dashLine ?? false}
                                            {...switchStyleProps}
                                            className={
                                                config.dashLine
                                                    ? "react-switch-on"
                                                    : "react-switch-off"
                                            }
                                            onChange={() => {
                                                let checked = !(
                                                    config.dashLine ?? true
                                                );
                                                let newConfig = {
                                                    ...config,
                                                    dashLine: checked,
                                                };
                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Sort Y Axis
                                </span>
                                <Switch
                                    checked={config.sortYAxis ?? false}
                                    {...switchStyleProps}
                                    className={
                                        config.sortYAxis
                                            ? "react-switch-on"
                                            : "react-switch-off"
                                    }
                                    onChange={() => {
                                        let checked = !(
                                            config.sortYAxis ?? false
                                        );

                                        onChangeConfig({
                                            ...config,
                                            sortYAxis: checked,
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <span className={styles.optionName}>
                                    Sort values as
                                </span>
                                <div className={styles.optionContainer}>
                                    <button
                                        className={cx(
                                            styles.optionButton,
                                            config.yAxisSortType ===
                                                "ascending" &&
                                                styles.optionButtonActive
                                        )}
                                        onClick={() => {
                                            onChangeConfig({
                                                ...config,
                                                yAxisSortType: "ascending",
                                            });
                                        }}
                                    >
                                        Ascending
                                    </button>
                                    <button
                                        className={cx(
                                            styles.optionButton,
                                            config.yAxisSortType ===
                                                "descending" &&
                                                styles.optionButtonActive
                                        )}
                                        onClick={() => {
                                            onChangeConfig({
                                                ...config,
                                                yAxisSortType: "descending",
                                            });
                                        }}
                                    >
                                        Descending
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    {isBarChart(props.finding) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Flip</span>
                            <Switch
                                checked={config.flip ?? false}
                                {...switchStyleProps}
                                className={
                                    config.flip
                                        ? "react-switch-on"
                                        : "react-switch-off"
                                }
                                onChange={() => {
                                    let checked = !(config.flip ?? false);
                                    let newConfig = {
                                        ...config,
                                        flip: checked,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {(isBarChart(props.finding) ||
                        isLeversBenchmarking(props.finding) ||
                        isSentimentAnalysis(props.finding)) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Column width
                                </span>
                                <SliderInput
                                    minValue={0}
                                    maxValue={200}
                                    value={config?.barSize}
                                    onChange={(value: number) => {
                                        let newConfig = {
                                            ...config,
                                            barSize: value,
                                        };

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Gap category width
                                </span>
                                <SliderInput
                                    minValue={0}
                                    maxValue={200}
                                    value={config?.barCategoryGap ?? 100}
                                    onChange={(value: number) => {
                                        let newConfig = {
                                            ...config,
                                            barCategoryGap: value,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Gap width
                                </span>
                                <SliderInput
                                    minValue={0}
                                    maxValue={200}
                                    value={config?.barGap ?? 0}
                                    onChange={(value: number) => {
                                        let newConfig = {
                                            ...config,
                                            barGap: value,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {(isLeversBenchmarking(props.finding) ||
                        isSentimentAnalysis(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>
                                Chart lines
                            </span>
                            <Input
                                value={config?.linesCount}
                                onChange={(value) => {
                                    let newConfig = {
                                        ...config,
                                    };
                                    if (StringUtils.isNumber(value)) {
                                        newConfig.linesCount = Number(value);
                                    } else {
                                        newConfig.linesCount = null;
                                    }
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {isBarChart(props.finding) && (
                        <HorizontalLinesStylingOption
                            finding={props.finding}
                            currentModuleId={props.currentModuleId}
                            onAddHorizontalLines={props.onAddHorizontalLines}
                        />
                    )}
                    {isLinePlot(props.finding) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>
                                Dot radius
                            </span>
                            <Input
                                value={config?.dotRadius ?? 4}
                                onChange={(value) => {
                                    let newConfig = {
                                        ...config,
                                    };
                                    if (StringUtils.isNumber(value)) {
                                        newConfig.dotRadius = Number(value);
                                    } else {
                                        newConfig.dotRadius = null;
                                    }
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {isPieChart(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Show totals
                                </span>
                                <Switch
                                    checked={config.showTotals ?? true}
                                    className={
                                        config.showTotals
                                            ? "react-switch-on"
                                            : "react-switch-off"
                                    }
                                    {...switchStyleProps}
                                    onChange={() => {
                                        let checked = !(
                                            config.showTotals ?? true
                                        );
                                        let newConfig = {
                                            ...config,
                                            showTotals: checked,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {(isPieChart(props.finding) ||
                        isTreemap(props.finding) ||
                        isRadar(props.finding) ||
                        isDendrogram(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>
                                Variable labels
                            </span>
                            <Switch
                                checked={config.showVariableLabels ?? false}
                                {...switchStyleProps}
                                className={
                                    config.showVariableLabels
                                        ? "react-switch-on"
                                        : "react-switch-off"
                                }
                                onChange={() => {
                                    let checked = !(
                                        config.showVariableLabels ?? false
                                    );
                                    let newConfig = {
                                        ...config,
                                        showVariableLabels: checked,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {isTreemap(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Show label in boxes
                                </span>
                                <div style={{ marginRight: 20 }}></div>
                                <Switch
                                    checked={config.showLabelInBoxes ?? false}
                                    {...switchStyleProps}
                                    className={
                                        config.showLabelInBoxes
                                            ? "react-switch-on"
                                            : "react-switch-off"
                                    }
                                    onChange={() => {
                                        let checked = !(
                                            config.showLabelInBoxes ?? false
                                        );
                                        let newConfig = {
                                            ...config,
                                            showLabelInBoxes: checked,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            {config.showLabelInBoxes && (
                                <div className={styles.optionContainer}>
                                    <span className={styles.optionName}>
                                        Title size
                                    </span>
                                    <Input
                                        value={
                                            config.titleSize ??
                                            parseInt(
                                                mainStyle.getPropertyValue(
                                                    "--treemap-title-size"
                                                )
                                            )
                                        }
                                        onChange={(value: string | number) => {
                                            let newConfig = {
                                                ...config,
                                            };
                                            if (StringUtils.isNumber(value)) {
                                                newConfig.titleSize = Number(
                                                    value
                                                );
                                            } else {
                                                newConfig.titleSize = null;
                                            }

                                            onChangeConfig(newConfig);
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {(isBarChart(props.finding) ||
                        isLeversBenchmarking(props.finding) ||
                        isLinePlot(props.finding) ||
                        isHoldOutPredictionChart(props.finding) ||
                        isPieChart(props.finding) ||
                        isTreemap(props.finding) ||
                        isRadar(props.finding) ||
                        isDendrogram(props.finding)) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Show legend
                                </span>
                                <Switch
                                    checked={config.showLegend ?? true}
                                    {...switchStyleProps}
                                    className={
                                        config.showLegend ?? true
                                            ? "react-switch-on"
                                            : "react-switch-off"
                                    }
                                    onChange={() => {
                                        let checked = !(
                                            config.showLegend ?? true
                                        );
                                        let newConfig = {
                                            ...config,
                                            showLegend: checked,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            {(config.showLegend ?? true) && (
                                <div className={styles.optionContainer}>
                                    <span className={styles.optionName}>
                                        Legend size
                                    </span>
                                    <Input
                                        value={
                                            config.legendSize ??
                                            parseInt(
                                                mainStyle.getPropertyValue(
                                                    "--graphs-legends-size"
                                                )
                                            )
                                        }
                                        onChange={(value: string | number) => {
                                            let newConfig = {
                                                ...config,
                                            };
                                            if (StringUtils.isNumber(value)) {
                                                newConfig.legendSize = Number(
                                                    value
                                                );
                                            } else {
                                                newConfig.legendSize = null;
                                            }

                                            onChangeConfig(newConfig);
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {isTreemap(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Label size
                                </span>
                                <Input
                                    value={
                                        config.labelSize ??
                                        parseInt(
                                            mainStyle.getPropertyValue(
                                                "--treemap-label-size"
                                            )
                                        )
                                    }
                                    onChange={(value: string | number) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.labelSize = Number(value);
                                        } else {
                                            newConfig.labelSize = null;
                                        }

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {(isHoldOutPredictionChart(props.finding) ||
                        isLeverOutcomeChart(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>
                                Show model
                            </span>
                            <Switch
                                checked={config.showModel ?? true}
                                {...switchStyleProps}
                                className={
                                    config.showModel ?? true
                                        ? "react-switch-on"
                                        : "react-switch-off"
                                }
                                onChange={() => {
                                    let checked = !(config.showModel ?? true);
                                    let newConfig = {
                                        ...config,
                                        showModel: checked,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {isPieChart(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Show percentage
                                </span>
                                <Switch
                                    checked={config.showLabels ?? true}
                                    {...switchStyleProps}
                                    className={
                                        config.percentage
                                            ? "react-switch-on"
                                            : "react-switch-off"
                                    }
                                    onChange={() => {
                                        let checked = !(
                                            config.showLabels ?? true
                                        );
                                        let newConfig = {
                                            ...config,
                                            showLabels: checked,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <span className={styles.optionName}>
                                    Format values as
                                </span>
                                <div className={styles.optionContainer}>
                                    <button
                                        className={cx(
                                            styles.optionButton,
                                            config.labelValuesType ===
                                                "percentages" &&
                                                styles.optionButtonActive
                                        )}
                                        onClick={() => {
                                            onChangeConfig({
                                                ...config,
                                                labelValuesType: "percentages",
                                            });
                                        }}
                                    >
                                        Percentages
                                    </button>
                                    <button
                                        className={cx(
                                            styles.optionButton,
                                            config.labelValuesType ===
                                                "numbers" &&
                                                styles.optionButtonActive
                                        )}
                                        onClick={() => {
                                            onChangeConfig({
                                                ...config,
                                                labelValuesType: "numbers",
                                            });
                                        }}
                                    >
                                        Numbers
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    {isRadar(props.finding) && (
                        <div style={{ marginTop: 20 }}>
                            <span className={styles.optionName}>
                                Radar type
                            </span>
                            <div className={styles.optionContainer}>
                                <button
                                    className={cx(
                                        styles.optionButton,
                                        !config.linear &&
                                            styles.optionButtonActive
                                    )}
                                    onClick={() => {
                                        onChangeConfig({
                                            ...config,
                                            linear: false,
                                        });
                                    }}
                                >
                                    Cardinal
                                </button>
                                <button
                                    className={cx(
                                        styles.optionButton,
                                        config.linear &&
                                            styles.optionButtonActive
                                    )}
                                    onClick={() => {
                                        onChangeConfig({
                                            ...config,
                                            linear: true,
                                        });
                                    }}
                                >
                                    Linear
                                </button>
                            </div>
                        </div>
                    )}
                    {isPieChart(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Inner radius (%)
                                </span>
                                <Input
                                    value={config?.innerRadius}
                                    onChange={(value) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.innerRadius = Number(
                                                value
                                            );
                                        } else {
                                            newConfig.innerRadius = null;
                                        }
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Outer radius (%)
                                </span>
                                <Input
                                    value={config?.outerRadius}
                                    onChange={(value) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.outerRadius = Number(
                                                value
                                            );
                                        } else {
                                            newConfig.outerRadius = null;
                                        }
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {isDendrogram(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Node radius
                                </span>
                                <Input
                                    value={
                                        config?.nodeRadius ??
                                        defaultDendrogramNodeRadius
                                    }
                                    onChange={(value) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.nodeRadius = Number(
                                                value
                                            );
                                        } else {
                                            newConfig.nodeRadius = null;
                                        }
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Edge color
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip="Edge color"
                                    value={
                                        config?.edgeColor ??
                                        defaultDendrogramEdgeColor
                                    }
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            edgeColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {isDensity2d(props.finding) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Type</span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "150px",
                                        height: "38px",
                                    }),
                                }}
                                options={density2dTypeOptions}
                                value={
                                    density2dTypeOptions.find(
                                        (option) =>
                                            option.value ===
                                            (config.density2dType ??
                                                defaultDensity2dType)
                                    )!
                                }
                                onChange={(newValue) => {
                                    let value = (newValue as Density2dTypeOption)
                                        .value;
                                    if (config?.density2dType !== value) {
                                        let newConfig = {
                                            ...config,
                                            density2dType: value,
                                        };
                                        onChangeConfig(newConfig);
                                    }
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                    )}
                    {(isScatterPlot(props.finding) ||
                        isBubble(props.finding) ||
                        isDensity2d(props.finding) ||
                        isHistogram(props.finding) ||
                        isDensityPlot(props.finding) ||
                        isBoxPlot(props.finding) ||
                        isViolinPlot(props.finding) ||
                        isRidgeline(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Show grid</span>
                            <Switch
                                checked={config.showGrid ?? false}
                                {...switchStyleProps}
                                className={
                                    config.showGrid
                                        ? "react-switch-on"
                                        : "react-switch-off"
                                }
                                onChange={() => {
                                    let checked = !(config.showGrid ?? false);
                                    let newConfig = {
                                        ...config,
                                        showGrid: checked,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {(isScatterPlot(props.finding) ||
                        isBubble(props.finding)) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    {isScatterPlot(props.finding)
                                        ? "Scatter color"
                                        : "Bubble color"}
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip={
                                        isScatterPlot(props.finding)
                                            ? "Scatter color"
                                            : "Bubble color"
                                    }
                                    value={config?.chartColor ?? colorList[1]}
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            chartColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Trendline color
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip="Trendline color"
                                    value={
                                        config?.trendlineColor ?? colorList[0]
                                    }
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            trendlineColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {(isHistogram(props.finding) ||
                        isDensityPlot(props.finding) ||
                        isBoxPlot(props.finding) ||
                        isViolinPlot(props.finding) ||
                        isRidgeline(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>
                                Chart color
                            </span>
                            <ColorPicker
                                inPopup
                                openInClickPosition
                                enableAlpha
                                tooltip="Chart color"
                                value={
                                    config?.chartColor ??
                                    (isDensityPlot(props.finding)
                                        ? defaultDensityChartColor
                                        : isBoxPlot(props.finding)
                                        ? defaultBoxPlotChartColor
                                        : isViolinPlot(props.finding)
                                        ? defaultViolinPlotChartColor
                                        : isRidgeline(props.finding)
                                        ? defaultRidgelineChartColor
                                        : colorList[1])
                                }
                                onChange={(newValue) => {
                                    let newConfig = {
                                        ...config,
                                        chartColor: newValue,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {(isDensityPlot(props.finding) ||
                        isBoxPlot(props.finding) ||
                        isViolinPlot(props.finding) ||
                        isRidgeline(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>
                                Stroke color
                            </span>
                            <ColorPicker
                                inPopup
                                openInClickPosition
                                enableAlpha
                                tooltip="Stroke color"
                                value={
                                    config?.strokeColor ??
                                    (isDensityPlot(props.finding)
                                        ? defaultDensityStrokeColor
                                        : isBoxPlot(props.finding)
                                        ? defaultBoxPlotStrokeColor
                                        : isRidgeline(props.finding)
                                        ? defaultRidgelineStrokeColor
                                        : defaultViolinPlotStrokeColor)
                                }
                                onChange={(newValue) => {
                                    let newConfig = {
                                        ...config,
                                        strokeColor: newValue,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {!isFunnelPlot(props.finding) &&
                        !isLeadersLaggers(props.finding) && (
                            <>
                                {!isLinePlot(props.finding) && (
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            Labels color
                                        </span>
                                        <ColorPicker
                                            inPopup
                                            openInClickPosition
                                            enableAlpha
                                            tooltip="Ticks and labels color"
                                            value={
                                                config?.labelsColor ??
                                                mainStyle.getPropertyValue(
                                                    "--graphs-axes-text-color"
                                                )
                                            }
                                            onChange={(newValue) => {
                                                let newConfig = {
                                                    ...config,
                                                    labelsColor: newValue,
                                                };
                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                )}
                                <div className={styles.optionContainer}>
                                    <span className={styles.optionName}>
                                        Ticks color
                                    </span>
                                    <ColorPicker
                                        inPopup
                                        openInClickPosition
                                        enableAlpha
                                        tooltip="Ticks and labels color"
                                        value={
                                            config?.ticksColor ??
                                            mainStyle.getPropertyValue(
                                                "--graphs-axes-text-color"
                                            )
                                        }
                                        onChange={(newValue) => {
                                            let newConfig = {
                                                ...config,
                                                ticksColor: newValue,
                                            };
                                            onChangeConfig(newConfig);
                                        }}
                                    />
                                </div>
                                {(isGanttChart(props.finding) ||
                                    isLeversBenchmarking(props.finding) ||
                                    isLeverOutcomeChart(props.finding) ||
                                    isHoldOutPredictionChart(props.finding) ||
                                    isSentimentAnalysis(props.finding) ||
                                    isHeatmap(props.finding)) && (
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            Ticks/labels size
                                        </span>
                                        <Input
                                            value={
                                                config.ticksSize ??
                                                parseInt(
                                                    mainStyle.getPropertyValue(
                                                        "--graphs-legends-size"
                                                    )
                                                )
                                            }
                                            onChange={(
                                                value: string | number
                                            ) => {
                                                let newConfig = {
                                                    ...config,
                                                };
                                                if (
                                                    StringUtils.isNumber(value)
                                                ) {
                                                    newConfig.ticksSize = Number(
                                                        value
                                                    );
                                                } else {
                                                    newConfig.ticksSize = null;
                                                }

                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                )}
                                {(isBarChart(props.finding) ||
                                    isLinePlot(props.finding) ||
                                    isScatterPlot(props.finding) ||
                                    isBubble(props.finding) ||
                                    isDensity2d(props.finding) ||
                                    isHistogram(props.finding) ||
                                    isDensityPlot(props.finding) ||
                                    isBoxPlot(props.finding) ||
                                    isViolinPlot(props.finding) ||
                                    isRidgeline(props.finding)) && (
                                    <>
                                        <div className={styles.optionContainer}>
                                            <span className={styles.optionName}>
                                                X ticks size
                                            </span>
                                            <Input
                                                value={
                                                    (config.ticksAndLabels?.x
                                                        ?.size ||
                                                        config.ticksSize) ??
                                                    parseInt(
                                                        mainStyle.getPropertyValue(
                                                            "--graphs-legends-size"
                                                        )
                                                    )
                                                }
                                                onChange={(
                                                    value: string | number
                                                ) => {
                                                    let newConfig = {
                                                        ...config,
                                                    };
                                                    if (
                                                        StringUtils.isNumber(
                                                            value
                                                        )
                                                    ) {
                                                        newConfig.ticksAndLabels = {
                                                            ...config.ticksAndLabels,
                                                            x: {
                                                                ...(config
                                                                    .ticksAndLabels
                                                                    ?.x ?? {}),
                                                                size: Number(
                                                                    value
                                                                ),
                                                            },
                                                        };
                                                    }

                                                    onChangeConfig(newConfig);
                                                }}
                                            />
                                        </div>
                                        <div className={styles.optionContainer}>
                                            <span className={styles.optionName}>
                                                Y ticks size
                                            </span>
                                            <Input
                                                value={
                                                    (config.ticksAndLabels?.y
                                                        ?.size ||
                                                        config.ticksSize) ??
                                                    parseInt(
                                                        mainStyle.getPropertyValue(
                                                            "--graphs-legends-size"
                                                        )
                                                    )
                                                }
                                                onChange={(
                                                    value: string | number
                                                ) => {
                                                    let newConfig = {
                                                        ...config,
                                                    };
                                                    if (
                                                        StringUtils.isNumber(
                                                            value
                                                        )
                                                    ) {
                                                        newConfig.ticksAndLabels = {
                                                            ...config.ticksAndLabels,
                                                            y: {
                                                                ...(config
                                                                    .ticksAndLabels
                                                                    ?.y ?? {}),
                                                                size: Number(
                                                                    value
                                                                ),
                                                            },
                                                        };
                                                    }

                                                    onChangeConfig(newConfig);
                                                }}
                                            />
                                        </div>
                                        {!isScatterPlot(props.finding) &&
                                            !isBubble(props.finding) &&
                                            !isDensity2d(props.finding) &&
                                            !isHistogram(props.finding) &&
                                            !isDensityPlot(props.finding) &&
                                            !isBoxPlot(props.finding) &&
                                            !isViolinPlot(props.finding) &&
                                            !isRidgeline(props.finding) &&
                                            !isLinePlot(props.finding) && (
                                                <div
                                                    className={
                                                        styles.optionContainer
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            styles.optionName
                                                        }
                                                    >
                                                        Labels size
                                                    </span>
                                                    <Input
                                                        value={
                                                            (config
                                                                .ticksAndLabels
                                                                ?.labels
                                                                ?.size ||
                                                                config.ticksSize) ??
                                                            parseInt(
                                                                mainStyle.getPropertyValue(
                                                                    "--graphs-legends-size"
                                                                )
                                                            )
                                                        }
                                                        onChange={(
                                                            value:
                                                                | string
                                                                | number
                                                        ) => {
                                                            let newConfig = {
                                                                ...config,
                                                            };
                                                            if (
                                                                StringUtils.isNumber(
                                                                    value
                                                                )
                                                            ) {
                                                                newConfig.ticksAndLabels = {
                                                                    ...config.ticksAndLabels,
                                                                    labels: {
                                                                        ...(config
                                                                            .ticksAndLabels
                                                                            ?.labels ??
                                                                            {}),
                                                                        size: Number(
                                                                            value
                                                                        ),
                                                                    },
                                                                };
                                                            }

                                                            onChangeConfig(
                                                                newConfig
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}
                                    </>
                                )}
                            </>
                        )}
                    {isFunnelPlot(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Labels color
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip="Labels color"
                                    value={
                                        config?.labelsColor ??
                                        mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        )
                                    }
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            labelsColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Values color
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip="Values color"
                                    value={
                                        config?.valuesColor ??
                                        mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        )
                                    }
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            valuesColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Funnel color
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip="Funnel color"
                                    value={config?.funnelColor}
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            funnelColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Labels size
                                </span>
                                <SliderInput
                                    minValue={5}
                                    maxValue={50}
                                    value={config?.labelsFontSize}
                                    onChange={(value: number) => {
                                        let newConfig = {
                                            ...config,
                                            labelsFontSize: value,
                                        };

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Values size
                                </span>
                                <SliderInput
                                    minValue={5}
                                    maxValue={50}
                                    value={config?.valuesFontSize}
                                    onChange={(value: number) => {
                                        let newConfig = {
                                            ...config,
                                            valuesFontSize: value,
                                        };

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {!isFunnelPlot(props.finding) &&
                        !isLeadersLaggers(props.finding) &&
                        !isPieChart(props.finding) &&
                        !isTreemap(props.finding) &&
                        !isRadar(props.finding) &&
                        !isDendrogram(props.finding) && (
                            <>
                                <div className={styles.optionContainer}>
                                    <span className={styles.optionName}>
                                        Axes lines color
                                    </span>
                                    <ColorPicker
                                        inPopup
                                        openInClickPosition
                                        enableAlpha
                                        tooltip="Axes lines color"
                                        value={
                                            config?.axesLinesColor ??
                                            mainStyle.getPropertyValue(
                                                "--graphs-stroke-color"
                                            )
                                        }
                                        onChange={(newValue) => {
                                            let newConfig = {
                                                ...config,
                                                axesLinesColor: newValue,
                                            };
                                            onChangeConfig(newConfig);
                                        }}
                                    />
                                </div>
                                {!isHoldOutPredictionChart(props.finding) && (
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            Axes names color
                                        </span>
                                        <ColorPicker
                                            inPopup
                                            openInClickPosition
                                            enableAlpha
                                            tooltip="Axes names color"
                                            value={
                                                config?.axesNamesColor ??
                                                "#333333"
                                            }
                                            onChange={(newValue) => {
                                                let newConfig = {
                                                    ...config,
                                                    axesNamesColor: newValue,
                                                };
                                                onChangeConfig(newConfig);
                                            }}
                                        />
                                    </div>
                                )}
                                {isLinePlot(props.finding) && (
                                    <div className={styles.optionContainer}>
                                        <span className={styles.optionName}>
                                            Axes names font
                                        </span>
                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={""}
                                            styles={{
                                                ...selectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    width: "100%",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    fontFamily:
                                                        state.data.value,
                                                    textAlign: "center",
                                                }),
                                                singleValue: (base, state) => ({
                                                    ...selectStyles.singleValue?.(
                                                        base,
                                                        state
                                                    ),
                                                    fontFamily:
                                                        state.data.value,
                                                }),
                                            }}
                                            options={fontFamilies.map(
                                                (family) => {
                                                    return {
                                                        label: family,
                                                        value: family,
                                                    };
                                                }
                                            )}
                                            onChange={(newValue) => {
                                                const newConfig = {
                                                    ...config,
                                                    axesNamesFontFamily: (newValue as StringOption)
                                                        .value,
                                                };
                                                onChangeConfig(newConfig, true);
                                            }}
                                            value={{
                                                label:
                                                    config.axesNamesFontFamily ??
                                                    defaultFontFamily.label,
                                                value:
                                                    config.axesNamesFontFamily ??
                                                    defaultFontFamily.value,
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    {(isBarChart(props.finding) ||
                        isLeversBenchmarking(props.finding) ||
                        isSentimentAnalysis(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>
                                Background color
                            </span>
                            <ColorPicker
                                inPopup
                                openInClickPosition
                                enableAlpha
                                tooltip="Background color"
                                value={
                                    config?.baseBackgroundColor ??
                                    getDefaultColorsByTheme(
                                        config?.chartTheme ?? null
                                    ).baseBackgroundColor
                                }
                                onChange={(newValue) => {
                                    let newConfig = {
                                        ...config,
                                        baseBackgroundColor: newValue,
                                    };
                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {(isBarChart(props.finding) ||
                        isLeversBenchmarking(props.finding) ||
                        isSentimentAnalysis(props.finding)) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Theme</span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "150px",
                                        height: "38px",
                                    }),
                                }}
                                options={Options}
                                value={Options.find(
                                    (option) =>
                                        option.value === config?.chartTheme
                                )}
                                onChange={(newValue) => {
                                    let value = (newValue as BarChartThemeOption)
                                        .value;
                                    let oldTheme = config?.chartTheme ?? null;

                                    if (oldTheme !== value) {
                                        const defaultColorByTheme = getDefaultColorsByTheme(
                                            value
                                        );
                                        props.canvasTreeStore.updateDashboardAction(
                                            props.dashboardId,
                                            {
                                                colorOptions: {
                                                    borderShadow: false,
                                                    fillColor: "#fff",
                                                    borderColor: "#fff",
                                                    borderRadius: 0,
                                                },
                                                finding: {
                                                    ...props.finding,
                                                    config: {
                                                        ...props.finding.config,
                                                        chartTheme: value,
                                                        axesNamesColor:
                                                            defaultColorByTheme.axesColor,
                                                        axesLinesColor:
                                                            defaultColorByTheme.gridColor,
                                                        ticksColor:
                                                            defaultColorByTheme.axesColor,
                                                        labelsColor: mainStyle.getPropertyValue(
                                                            "--graphs-axes-text-color"
                                                        ),
                                                        nameColorMapping: null,
                                                        baseBackgroundColor: null,
                                                    },
                                                },
                                            }
                                        );
                                    }
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                    )}
                    {buildStatusExpressionsSelector()}
                    {!isLeadersLaggers(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Tooltip font size
                                </span>
                                <Input
                                    value={config.tooltipFontSize ?? 14}
                                    onChange={(value: string | number) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.tooltipFontSize = Number(
                                                value
                                            );
                                        } else {
                                            newConfig.tooltipFontSize = null;
                                        }

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Tooltip color
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip="Tooltip color"
                                    value={
                                        config?.tooltipColor ??
                                        mainStyle.getPropertyValue(
                                            "--charts-tooltip-background-color"
                                        )
                                    }
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            tooltipColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {isBubble(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Min radius
                                </span>
                                <Input
                                    value={
                                        config.minRadius ??
                                        defaultBubbleD3MinRadius
                                    }
                                    onChange={(value: string | number) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.minRadius = Number(value);
                                        } else {
                                            newConfig.minRadius = null;
                                        }

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Max radius
                                </span>
                                <Input
                                    value={
                                        config.maxRadius ??
                                        defaultBubbleD3MaxRadius
                                    }
                                    onChange={(value: string | number) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.maxRadius = Number(value);
                                        } else {
                                            newConfig.maxRadius = null;
                                        }

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {isDensity2d(props.finding) && (
                        <div className={styles.optionContainer}>
                            <span className={styles.optionName}>Bin size</span>
                            <Input
                                value={
                                    config.binSize ?? defaultDensity2dBinSize
                                }
                                onChange={(value: string | number) => {
                                    let newConfig = {
                                        ...config,
                                    };
                                    if (StringUtils.isNumber(value)) {
                                        newConfig.binSize = Number(value);
                                    } else {
                                        newConfig.binSize = null;
                                    }

                                    onChangeConfig(newConfig);
                                }}
                            />
                        </div>
                    )}
                    {(isHeatmap(props.finding) ||
                        isDensity2d(props.finding)) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Min color
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip="Min color"
                                    value={
                                        config?.minColor ??
                                        (isDensity2d(props.finding)
                                            ? defaultDensity2dMinColor
                                            : defaultHeatmapD3MinColor)
                                    }
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            minColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Max color
                                </span>
                                <ColorPicker
                                    inPopup
                                    openInClickPosition
                                    enableAlpha
                                    tooltip="Max color"
                                    value={
                                        config?.maxColor ??
                                        (isDensity2d(props.finding)
                                            ? defaultDensity2dMaxColor
                                            : defaultHeatmapD3MaxColor)
                                    }
                                    onChange={(newValue) => {
                                        let newConfig = {
                                            ...config,
                                            maxColor: newValue,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Min value
                                </span>
                                <Input
                                    value={config.minValue ?? ""}
                                    onChange={(value: string | number) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.minValue = Number(value);
                                        } else {
                                            newConfig.minValue = null;
                                        }

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.optionContainer}>
                                <span className={styles.optionName}>
                                    Max value
                                </span>
                                <Input
                                    value={config.maxValue ?? ""}
                                    onChange={(value: string | number) => {
                                        let newConfig = {
                                            ...config,
                                        };
                                        if (StringUtils.isNumber(value)) {
                                            newConfig.maxValue = Number(value);
                                        } else {
                                            newConfig.maxValue = null;
                                        }

                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {isGanttChart(props.finding) && (
                        <>
                            <div className={styles.optionContainer}>
                                <span
                                    className={styles.optionName}
                                    style={{ marginRight: 10 }}
                                >
                                    Turn current date line on
                                </span>
                                <Switch
                                    checked={
                                        config.showCurrentDateLine ?? false
                                    }
                                    {...switchStyleProps}
                                    className={
                                        config.showCurrentDateLine
                                            ? "react-switch-on"
                                            : "react-switch-off"
                                    }
                                    onChange={() => {
                                        let checked = !(
                                            config.showCurrentDateLine ?? false
                                        );
                                        let newConfig = {
                                            ...config,
                                            showCurrentDateLine: checked,
                                        };
                                        onChangeConfig(newConfig);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Accordion.Collapse>

            {isLeadersLaggers(props.finding) && (
                <>
                    <LeaderboardSection
                        finding={props.finding}
                        onChange={onChangeConfig}
                        currentModuleId={props.currentModuleId}
                    />
                    <LogosEditor
                        finding={props.finding}
                        onChange={props.onChange}
                    />
                </>
            )}

            {isWordCloud(props.finding) && (
                <WordCloudSection
                    finding={props.finding}
                    onChange={onChangeConfig}
                />
            )}

            {isCorrelogram(props.finding) && (
                <CorrelogramSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    finding={props.finding}
                    onChange={onChangeConfig}
                    currentModuleId={props.currentModuleId}
                />
            )}

            {isAiCopilot(props.finding) && (
                <AiCopilotSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    finding={props.finding}
                    onChange={onChangeConfig}
                    currentModuleId={props.currentModuleId}
                />
            )}

            {isNetwork(props.finding) && (
                <NetworkSection
                    finding={props.finding}
                    onChange={onChangeConfig}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {(isMap(props.finding) || isNetworkOverMap(props.finding)) && (
                <>
                    {isMap(props.finding) && (
                        <MapSection
                            showTableOption
                            onClearEditing={props.onClearEditing}
                            showCenteringOption
                            finding={props.finding}
                            onChange={onChangeConfig}
                            currentModuleId={props.currentModuleId}
                            canvasTreeStore={props.canvasTreeStore}
                            dashboardId={props.dashboardId}
                            currentZoomLevel={props.finding.config.zoom}
                        />
                    )}
                    {isNetworkOverMap(props.finding) && (
                        <NetworkOverMapSection
                            onClearEditing={props.onClearEditing}
                            dashboardId={props.dashboardId}
                            canvasTreeStore={props.canvasTreeStore}
                            finding={props.finding}
                            onChange={onChangeConfig}
                            currentModuleId={props.currentModuleId}
                            currentZoomLevel={props.finding.config.zoom}
                        />
                    )}
                    {props.finding.additionalMapsFindings?.map(
                        (finding, index) => {
                            const onChange = (
                                newConfig: Finding["config"],
                                updateData?: boolean
                            ) => {
                                let newFinding: MapFinding | NetworkFinding = {
                                    ...props.finding,
                                    additionalMapsFindings: [
                                        ...((props.finding as
                                            | MapFinding
                                            | NetworkFinding)
                                            .additionalMapsFindings ?? []),
                                    ],
                                };
                                newFinding.additionalMapsFindings![index] = {
                                    ...newFinding.additionalMapsFindings![
                                        index
                                    ],
                                    config: newConfig,
                                };
                                props.onChange(newFinding, updateData);
                            };
                            return (
                                <>
                                    <div
                                        className={
                                            styles.additionalLayerConfigTitle
                                        }
                                    >
                                        Layer {index + 2}
                                    </div>
                                    {isMap(finding) ? (
                                        <MapSection
                                            onClearEditing={
                                                props.onClearEditing
                                            }
                                            finding={finding}
                                            onChange={onChange}
                                            currentModuleId={
                                                props.currentModuleId
                                            }
                                            dashboardId={props.dashboardId}
                                            canvasTreeStore={
                                                props.canvasTreeStore
                                            }
                                        />
                                    ) : (
                                        <NetworkOverMapSection
                                            onClearEditing={
                                                props.onClearEditing
                                            }
                                            dashboardId={props.dashboardId}
                                            finding={finding}
                                            canvasTreeStore={
                                                props.canvasTreeStore
                                            }
                                            onChange={onChange}
                                            currentModuleId={
                                                props.currentModuleId
                                            }
                                        />
                                    )}
                                </>
                            );
                        }
                    )}
                </>
            )}
            {/* Do not remove this code. It's for a future task
                isLinePlot(props.finding) && (
                <>
                    <Accordion.Toggle
                        eventKey={String(StylingMenuOption.Tooltip)}
                        className={commonStyles.editMenuAdvancedOption}
                    >
                        Tooltip
                        <ChevronIcon
                            className={cx(
                                commonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [commonStyles.chevronOpen]:
                                        stylingOptionSelected ===
                                        StylingMenuOption.Tooltip,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(StylingMenuOption.Tooltip)}
                    >
                        <LinePlotTooltipConfig
                            finding={props.finding}
                            dashboardId={props.dashboardId}
                            onChange={onChangeConfig}
                            currentModuleId={props.currentModuleId}
                        />
                    </Accordion.Collapse>
                </>
            )*/}
        </Accordion>
    );
}

export default observer(StylingSection);
