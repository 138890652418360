import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import { defaultGanttChartFilter, GanttChartFinding } from "common/Finding";
import { configVersionV2 } from "common/PathConfigVersion";
import axios from "common/ServerConnection";
import { mainStyle } from "common/MainStyle";
import { updateOriginalNames } from "../../common/Utils";
import moment from "moment";

class Api {
    static getDefaultConfig(journeyName: string) {
        return {
            showXAxisName: true,
            labelsColor: "#333333",
            journeyName: journeyName,
            version: configVersionV2,
            count: 5,
            tooltipColor: mainStyle.getPropertyValue(
                "--charts-tooltip-background-color"
            ),
            filter: defaultGanttChartFilter,
        };
    }

    static getPreviewFinding(journeyName: string): GanttChartFinding {
        let currentTime = parseInt(moment.utc(moment()).format("X"));
        let item = {
            type: "ganttchart",
            content: {
                data: [
                    {
                        name: "Variable",
                        value: [
                            "Variable 1",
                            "Variable 2",
                            "Variable 3",
                            "Variable 4",
                        ],
                    },
                    {
                        name: "Start",
                        value: [
                            currentTime - 120 * 60 * 60,
                            currentTime - 48 * 60 * 60,
                            currentTime - 36 * 60 * 60,
                            currentTime - 24 * 60 * 60,
                        ],
                    },
                    {
                        name: "End",
                        value: [
                            currentTime - 96 * 60 * 60,
                            currentTime - 72 * 60 * 60,
                            currentTime - 24 * 60 * 60,
                            currentTime + 24 * 60 * 60,
                        ],
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getData(
        finding: GanttChartFinding,
        findingOptions: any,
        moduleId?: number
    ): Promise<GanttChartFinding> {
        let {
            dataScope,
            selectedTable,
            conditions,
            count,
            colorVariable,
        } = finding.config;

        conditions = filterNonEmptyConditions(conditions ?? []);
        let variableIndex = finding.content.data[0]?.variableIndex;
        let leftTimeIndex = finding.content.data[1]?.variableIndex;
        let rightTimeIndex = finding.content.data[2]?.variableIndex;

        if (
            variableIndex == null ||
            leftTimeIndex == null ||
            rightTimeIndex == null
        ) {
            return Promise.resolve(finding);
        }
        let variableIndices = [variableIndex, leftTimeIndex, rightTimeIndex];
        if (colorVariable != null) variableIndices.push(colorVariable.value);
        let requestJson = {
            variable_indices: variableIndices,
            table: selectedTable.value,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            conditions: conditionsToJson(conditions),
            limit: count,
            module_id: moduleId,
        };
        return axios
            .post<{
                current_levels?: { [key: string]: (number | string)[] };
                success: boolean;
                error_msg: string;
            }>("/api/e/get_raw_data", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = response.data.current_levels!;
                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: [...finding.content.data],
                        },
                    };

                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );

                    let originalVariableName =
                        newFinding.content.data[0]?.originalName;
                    let originalLeftTimeVariableName =
                        newFinding.content.data[1]?.originalName;
                    let originalRightTimeVariableName =
                        newFinding.content.data[2]?.originalName;

                    newFinding.content.data[0] = {
                        ...newFinding.content.data[0],
                        value: data[originalVariableName!],
                    };
                    newFinding.content.data[1] = {
                        ...newFinding.content.data[1],
                        value: data[originalLeftTimeVariableName!],
                    };
                    newFinding.content.data[2] = {
                        ...newFinding.content.data[2],
                        value: data[originalRightTimeVariableName!],
                    };

                    if (colorVariable != null) {
                        newFinding.content.colorBy = data[
                            colorVariable.label
                        ]?.map((item) => {
                            if (typeof item === "string") return item.trim();
                            return item;
                        });
                    }
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
