import React from "react";
import Select from "react-select";

import { MapElement } from "common/Canvas";
import remoteModuleId from "common/remoteModuleId";
import Variables, { VariableOption } from "common/Variables";

import styles from "./HeatmapConfig.module.css";

interface Props {
    mapElement: MapElement;
    onChange: (value: Partial<MapElement>) => void;
    currentModuleId?: number;
}

function HeatmapConfig({ mapElement, onChange, currentModuleId }: Props) {
    return (
        <div className={styles.root}>
            <Select
                isClearable
                placeholder="Select variable"
                styles={{
                    container: (provided) => ({
                        ...provided,
                        minWidth: 60,
                    }),
                    control: (provided) => ({
                        ...provided,
                        minHeight: 25,
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                    }),
                }}
                options={
                    Variables(
                        mapElement?.dataScope?.value,
                        currentModuleId ?? remoteModuleId
                    ).variableOptions
                }
                onChange={(newValue) => {
                    onChange({
                        heatMap: newValue as VariableOption | null,
                    });
                }}
                value={mapElement.heatMap}
            />
        </div>
    );
}

export default HeatmapConfig;
