import React, { Component, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import Select, { createFilter, StylesConfig, GroupBase } from "react-select";
import { customFilterStyles } from "common/SelectStyles";
import { VariableOption } from "common/Variables";
import StringOption from "common/StringOption";
import { mainStyle } from "common/MainStyle";
import { RuleCondition } from "./RuleCondition";

type NullableOption =
    | StringOption
    | {
          label: string;
          value: null;
      };

const comparisonOperations: ReadonlyArray<StringOption> = [
    {
        label: ">",
        value: ">",
    },
    {
        label: "<",
        value: "<",
    },
    {
        label: ">=",
        value: ">=",
    },
    {
        label: "<=",
        value: "<=",
    },
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

const logicalOperations: ReadonlyArray<StringOption> = [
    {
        label: "OR",
        value: "or",
    },
    {
        label: "AND",
        value: "and",
    },
];

export function filterNonEmptyConditions(conditions: RuleCondition[]) {
    return conditions.filter(
        (condition) => condition.operation != null && condition.value != null
    );
}

export function isEmptyConditions(conditions?: RuleCondition[]) {
    let filteredConditions: RuleCondition[] | undefined = undefined;
    if (conditions != null) {
        filteredConditions = conditions.filter(
            (condition) =>
                condition.operation != null && condition.value != null
        );
        return filteredConditions.length === 0;
    }
    return true;
}

interface Props {
    single?: boolean;
    dataScopeId: number | string | undefined;
    currentModuleId?: number;
    onChange: (conditions: RuleCondition[]) => void;
    title: string;
    value?: RuleCondition[];
    style?: CSSProperties;
    small?: boolean;
    titleStyle?: CSSProperties;
    rowStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    rowStyleWithoutPlusMinus?:
        | CSSProperties
        | ((rowIndex: number) => CSSProperties);
    inputStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    selectStyle?: StylesConfig | ((rowIndex: number) => StylesConfig);
    auxiliarySelectorsStyle?:
        | StylesConfig
        | ((rowIndex: number) => StylesConfig);
    buttonsStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    rowWithoutPlusMinusClassName?: string;
    variable: VariableOption | null;
}

interface FocusProps {
    onNext: () => void;
    onPrevious: () => void;
    children: JSX.Element;
}

function FocusIterable(props: FocusProps) {
    return React.cloneElement(React.Children.only(props.children), {
        onKeyDown: (evt: any) => {
            evt.stopPropagation();
            let value = (evt.target as any).value;
            if (evt.key === "Enter") {
                props.onNext();
            }
            if (evt.key === "Backspace" && value.length === 0) {
                props.onPrevious();
            }
        },
    });
}

interface State {
    emptyFocused: Set<number>;
}

class RuleConditionsSelector extends Component<Props, State> {
    selectGrid: {
        [key: string]: {
            [key: string]: any;
        };
    };
    public static get defaultValue() {
        return [
            {
                variable: null,
                operation: null,
                value: null,
                logical: logicalOperations[0],
                leftBracket: null,
                rightBracket: null,
            },
        ];
    }

    constructor(props: Props) {
        super(props);
        this.selectGrid = {};
        this.state = {
            emptyFocused: new Set(),
        };
    }
    private deleteCondition(index: number) {
        this.setState((state) => {
            state.emptyFocused.delete(index);
            return state;
        });
        let lastIndex = (this.props.value ?? []).length - 1;
        this.changeConditions((conditions) => {
            let newConditions = Array.from(conditions);
            newConditions.splice(index, 1);
            if (newConditions.length === 0)
                newConditions.push({
                    operation: null,
                    value: null,
                    logical: logicalOperations[0],
                    leftBracket: null,
                    rightBracket: null,
                });
            return newConditions;
        });
        setTimeout(() => {
            delete this.selectGrid[lastIndex];
        }, 0);
    }

    private addCondition(index: number) {
        this.changeConditions((conditions) => {
            let newConditions = Array.from(conditions);
            newConditions.splice(index + 1, 0, {
                operation: null,
                value: null,
                leftBracket: null,
                rightBracket: null,
                logical: logicalOperations[0],
            });
            return newConditions;
        });
    }

    private changeConditions(
        func: (conditions: RuleCondition[]) => RuleCondition[]
    ) {
        const conditions: RuleCondition[] = func(this.props.value ?? []);
        this.props.onChange(conditions);
    }

    private selectNextFocusItem(index: number, key: string): void {
        let keys = Object.keys(this.selectGrid[index] ?? {}).sort();
        let currentSubIndex = keys.findIndex((value) => value === key);
        if (currentSubIndex === -1) return;
        if (currentSubIndex < keys.length - 1) {
            this.selectGrid[index][keys[currentSubIndex + 1]]?.focus();
        } else {
            if (index < Object.keys(this.selectGrid).length - 1) {
                let newIndex = index + 1;
                let keys = Object.keys(this.selectGrid[newIndex]).sort();
                this.selectGrid[newIndex][keys[0]]?.focus();
            } else if (this.props.single) {
                this.addCondition(index);
                setTimeout(() => {
                    this.selectNextFocusItem(index, key);
                }, 0);
            }
        }
    }
    private showPlaceholder(condition: RuleCondition, index: number) {
        return (
            condition.operation == null && !this.state.emptyFocused.has(index)
        );
    }

    private selectPreviousFocusItem(index: number, key: string): void {
        let keys = Object.keys(this.selectGrid[index] ?? {}).sort();
        let currentSubIndex = keys.findIndex((value) => value === key);
        if (currentSubIndex === -1) return;
        if (currentSubIndex > 0) {
            this.selectGrid[index][keys[currentSubIndex - 1]]?.focus();
        } else {
            if (index > 0) {
                setTimeout(() => {
                    this.deleteCondition(index);
                    let newIndex = index - 1;
                    let keys = Object.keys(this.selectGrid[newIndex]).sort();
                    this.selectGrid[newIndex][keys[keys.length - 1]]?.focus();
                }, 0);
            }
        }
    }

    private toFunction<T>(
        value: T | ((rowIndex: number) => T) | undefined | null,
        defaultValue: T
    ): (rowIndex: number) => T {
        if (value == null) {
            return () => defaultValue;
        } else if (typeof value !== "function") {
            return () => value;
        } else {
            return value as (rowIndex: number) => T;
        }
    }

    public render(): JSX.Element {
        const leftBrackets: NullableOption[] = [
            {
                label: "(",
                value: "(",
            },
            {
                label: "\u200B",
                value: null,
            },
        ];
        const rightBrackets: NullableOption[] = [
            {
                label: ")",
                value: ")",
            },
            {
                label: "\u200B",
                value: null,
            },
        ];

        const conditions: RuleCondition[] =
            this.props.value ?? RuleConditionsSelector.defaultValue;

        const small: boolean = this.props.small ?? false;

        const rowStyle = this.toFunction(this.props.rowStyle, {});
        const rowStyleWithoutPlusMinus = this.toFunction(
            this.props.rowStyleWithoutPlusMinus,
            {}
        );
        const inputStyle = this.toFunction(this.props.inputStyle, {});
        const selectStyle = this.toFunction(this.props.selectStyle, {});
        const buttonsStyle = this.toFunction(this.props.buttonsStyle, {});
        const auxiliarySelectorsStyle = this.toFunction(
            this.props.auxiliarySelectorsStyle,
            {}
        );

        return (
            <div
                className={"flex-simple-column"}
                tabIndex={0}
                style={{
                    marginTop: small ? 5 : 25,
                    marginLeft: small ? undefined : 100,
                    minHeight: small ? undefined : 300,
                    ...this.props.style,
                }}
                onKeyDown={(evt) => {
                    if (
                        evt.key === "ArrowDown" ||
                        evt.key === "ArrowUp" ||
                        evt.key === "ArrowRight"
                    )
                        evt.stopPropagation();
                }}
            >
                {!small && (
                    <span
                        className="exploration-big-title-span"
                        style={this.props.titleStyle}
                    >
                        {this.props.title}
                    </span>
                )}

                {conditions.map((condition: RuleCondition, index: number) => {
                    return (
                        <div
                            className={"my-row"}
                            key={index}
                            style={{
                                marginTop: index > 0 ? 5 : 0,
                                backgroundColor: mainStyle.getPropertyValue(
                                    "--filters-background-color"
                                ),
                                ...rowStyle(index),
                                // height:38
                            }}
                        >
                            {this.showPlaceholder(condition, index) ? (
                                <div
                                    className={cx(
                                        "my-row",
                                        this.props.rowWithoutPlusMinusClassName
                                    )}
                                    key={`${index}_withoutPlusMinus`}
                                    onClick={() => {
                                        this.setState((state) => {
                                            state.emptyFocused.add(index);
                                            return state;
                                        });
                                    }}
                                    style={{
                                        ...rowStyleWithoutPlusMinus(index),
                                        flex: 1,
                                        color: "#666666",
                                        padding: "10px",
                                        fontFamily: "Roboto",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        height: 30,
                                        alignItems: "center",
                                    }}
                                >
                                    {"Conditions"}
                                </div>
                            ) : (
                                <div
                                    className={cx(
                                        "my-row",
                                        this.props.rowWithoutPlusMinusClassName
                                    )}
                                    key={`${index}_withoutPlusMinus`}
                                    style={{
                                        ...rowStyleWithoutPlusMinus(index),
                                        overflow: "hidden",
                                    }}
                                >
                                    {index > 0 && (
                                        <div
                                            style={{
                                                width: 50,
                                            }}
                                        >
                                            <FocusIterable
                                                onNext={() => {
                                                    this.selectNextFocusItem(
                                                        index,
                                                        "0"
                                                    );
                                                }}
                                                onPrevious={() => {
                                                    this.selectPreviousFocusItem(
                                                        index,
                                                        "0"
                                                    );
                                                }}
                                            >
                                                <Select
                                                    isClearable={true}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    ref={(ref) => {
                                                        this.selectGrid[
                                                            index
                                                        ] = {
                                                            ...this.selectGrid[
                                                                index
                                                            ],
                                                            "0": ref,
                                                        };
                                                    }}
                                                    placeholder={"Logical"}
                                                    styles={
                                                        {
                                                            ...customFilterStyles,
                                                            container: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                height: "38px",
                                                            }),
                                                            ...selectStyle(
                                                                index
                                                            ),
                                                            ...auxiliarySelectorsStyle(
                                                                index
                                                            ),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 100000000,
                                                            }),
                                                        } as StylesConfig<
                                                            StringOption,
                                                            false,
                                                            GroupBase<
                                                                StringOption
                                                            >
                                                        >
                                                    }
                                                    options={logicalOperations}
                                                    value={condition.logical}
                                                    onChange={(newValue) => {
                                                        this.changeConditions(
                                                            (conditions) => {
                                                                let newConditions: RuleCondition[] = Array.from(
                                                                    conditions
                                                                );
                                                                newConditions[
                                                                    index
                                                                ] = {
                                                                    ...newConditions[
                                                                        index
                                                                    ],
                                                                    logical: (newValue ??
                                                                        null) as StringOption | null,
                                                                };
                                                                return newConditions;
                                                            }
                                                        );
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            </FocusIterable>
                                        </div>
                                    )}
                                    {small && index === 0 && this.props.title && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span
                                                className="regular-text"
                                                style={{
                                                    width: "50px",
                                                    textAlign: "center",
                                                    ...this.props.titleStyle,
                                                }}
                                            >
                                                {this.props.title}
                                            </span>
                                        </div>
                                    )}
                                    {((small && !this.props.title) || !small) &&
                                        index === 0 &&
                                        this.props.value &&
                                        this.props.value.length > 1 && (
                                            <div
                                                style={{
                                                    width: "50px",
                                                    ...this.props.titleStyle,
                                                }}
                                            ></div>
                                        )}

                                    <div style={{ width: 60, marginLeft: 1 }}>
                                        <FocusIterable
                                            onNext={() => {
                                                this.selectNextFocusItem(
                                                    index,
                                                    "4"
                                                );
                                            }}
                                            onPrevious={() => {
                                                this.selectPreviousFocusItem(
                                                    index,
                                                    "4"
                                                );
                                            }}
                                        >
                                            <Select
                                                isClearable={true}
                                                menuPlacement="auto"
                                                menuPortalTarget={document.body}
                                                ref={(ref) => {
                                                    this.selectGrid[index] = {
                                                        ...this.selectGrid[
                                                            index
                                                        ],
                                                        "4": ref,
                                                    };
                                                }}
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Operation"}
                                                styles={
                                                    {
                                                        ...customFilterStyles,
                                                        container: (base) => ({
                                                            ...base,
                                                            height: "38px",
                                                        }),
                                                        ...selectStyle(index),
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 100000000,
                                                            minWidth: 60,
                                                        }),
                                                    } as StylesConfig<
                                                        StringOption,
                                                        false,
                                                        GroupBase<StringOption>
                                                    >
                                                }
                                                options={comparisonOperations}
                                                onChange={(newValue) => {
                                                    this.changeConditions(
                                                        (conditions) => {
                                                            let newConditions = Array.from(
                                                                conditions
                                                            );
                                                            newConditions[
                                                                index
                                                            ] = {
                                                                ...newConditions[
                                                                    index
                                                                ],
                                                                operation: (newValue ??
                                                                    null) as StringOption | null,
                                                            };
                                                            return newConditions;
                                                        }
                                                    );
                                                }}
                                                value={condition.operation}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </FocusIterable>
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: 1,
                                            width: 100,
                                            flex: 1,
                                        }}
                                    >
                                        {condition.operation != null && (
                                            <FocusIterable
                                                onNext={() => {
                                                    this.selectNextFocusItem(
                                                        index,
                                                        "6"
                                                    );
                                                }}
                                                onPrevious={() => {
                                                    this.selectPreviousFocusItem(
                                                        index,
                                                        "6"
                                                    );
                                                }}
                                            >
                                                <input
                                                    onMouseDown={(evt) => {
                                                        evt.stopPropagation();
                                                    }}
                                                    className="like-select"
                                                    ref={(ref) => {
                                                        this.selectGrid[
                                                            index
                                                        ] = {
                                                            ...this.selectGrid[
                                                                index
                                                            ],
                                                            "6": ref,
                                                        };
                                                    }}
                                                    style={{
                                                        backgroundColor: mainStyle.getPropertyValue(
                                                            "--filters-item-color"
                                                        ),
                                                        color: mainStyle.getPropertyValue(
                                                            "--filters-text-color"
                                                        ),
                                                        borderRadius: 0,
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                        height: "26px",
                                                        fontFamily: "monospace",
                                                        textAlign: "left",
                                                        flex: 1,
                                                        margin: 3,
                                                        ...inputStyle(index),
                                                    }}
                                                    placeholder=""
                                                    onKeyDown={(e) => {
                                                        e.stopPropagation();
                                                        if (e.key === "Enter") {
                                                            const value: string =
                                                                e.currentTarget
                                                                    .value;
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        value: value,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const value: string =
                                                            e.target.value;
                                                        this.changeConditions(
                                                            (conditions) => {
                                                                let newConditions = Array.from(
                                                                    conditions
                                                                );
                                                                newConditions[
                                                                    index
                                                                ] = {
                                                                    ...newConditions[
                                                                        index
                                                                    ],
                                                                    value: value,
                                                                };
                                                                return newConditions;
                                                            }
                                                        );
                                                    }}
                                                    defaultValue={
                                                        condition.value != null
                                                            ? String(
                                                                  condition.value
                                                              )
                                                            : ""
                                                    }
                                                />
                                            </FocusIterable>
                                        )}
                                    </div>
                                </div>
                            )}
                            {!this.props.single && (
                                <div
                                    className="flex-simple-column"
                                    style={{ marginLeft: 5, marginTop: 3 }}
                                >
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            backgroundColor: mainStyle.getPropertyValue(
                                                "--filters-background-color"
                                            ),
                                            color: mainStyle.getPropertyValue(
                                                "--filters-text-color"
                                            ),
                                            width: "19px",
                                            height: "19px",
                                            ...buttonsStyle(index),
                                        }}
                                        onClick={() => {
                                            this.addCondition(index);
                                        }}
                                    >
                                        {"\uFF0B" /* plus */}
                                    </Button>
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            backgroundColor: mainStyle.getPropertyValue(
                                                "--filters-background-color"
                                            ),
                                            color: mainStyle.getPropertyValue(
                                                "--filters-text-color"
                                            ),
                                            width: "19px",
                                            height: "19px",
                                            ...buttonsStyle(index),
                                        }}
                                        onClick={() => {
                                            this.deleteCondition(index);
                                        }}
                                    >
                                        {"\uFF0D" /* minus */}
                                    </Button>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default RuleConditionsSelector;
